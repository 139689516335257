import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
	selector: 'sp-error-message',
	templateUrl: './sp-error-message.component.html',
	styleUrls: ['./sp-error-message.component.scss'],
})
export class SpErrorMessageComponent {
	@Input() public message: string;
	@Input() public retryBtn: string;
	@Input() public ShowErrorImg: boolean = false;
	@Input() public showButton: boolean = true;
	@Output() navigate: EventEmitter<void> = new EventEmitter<void>();
	@Input() errorIconSrc: string = '';
	@Input() iconId: string = '';
	constructor() {}

	action(): void {
		this.navigate.emit();
	}
}
