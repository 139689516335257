import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import moment from 'moment';
import { ConfigurationService } from '../../core/services/configuration.service';
import { ProductConfigurationFilterType } from '../enums/product-configuration-filter-type.enum';
import { ProductConfigurationID } from '../enums/product-configuration-id.enum';
import { ProductConfiguration } from '../models/product-configuration.model';

@Injectable()
export class ProductConfigurationService {
	products: ProductConfiguration[];

	constructor(public configurationService: ConfigurationService, public translate: TranslateService) {}

	/**
	 * Method that determines the feature visibility
	 * @param productID {ProductConfigurationID} Feature identifier
	 * @returns {boolean} feature are visibility or not
	 */
	public shouldShowFeature(productID: ProductConfigurationID): boolean {
		const products = this.configurationService.configuration.productConfiguration;
		const feature = products.find(
			(product: ProductConfiguration) => product.featureID.toLowerCase() === productID.toLowerCase()
		);

		return feature && feature.web ? this.validateFeature(feature) : false;
	}

	/**
	 * Method that validates the feature
	 * @param feature {ProductConfiguration} Product configuration from settings
	 */
	private validateFeature(feature: ProductConfiguration): boolean {
		const filterType = feature ? feature.filterType : 'undefinded';
		switch (this.getFilterType(filterType)) {
			case ProductConfigurationFilterType.date:
				return this.isValidDate(feature);
			case ProductConfigurationFilterType.version:
				return this.isValidVersion(feature);
			case ProductConfigurationFilterType.dateRange:
				return this.isValidDateRange(feature);
			case ProductConfigurationFilterType.status:
				return this.isValidStatus(feature);
			case ProductConfigurationFilterType.undefined:
				return false;
		}
	}

	/**
	 * Method for get the ProductConfigurationFilterType enum
	 * @param filterType {string} Filter type
	 */
	private getFilterType(filterType: string): ProductConfigurationFilterType {
		switch (filterType.toLowerCase()) {
			case 'date':
				return ProductConfigurationFilterType.date;
			case 'date_range':
				return ProductConfigurationFilterType.dateRange;
			case 'version':
				return ProductConfigurationFilterType.version;
			case 'status':
				return ProductConfigurationFilterType.status;
			default:
				return ProductConfigurationFilterType.undefined;
		}
	}

	/**
	 * Method that validates the feature date range
	 * @param feature {ProductConfiguration} the feature to date validation
	 * @return {boolean}
	 */
	private isValidDate(feature: ProductConfiguration): boolean {
		const featureDateString = feature.filterValue;
		const featureOperator = feature.operator;
		const currentDateString = this.getDateStringWithoutHHMMss(new Date());
		const currentDate = this.getDateFromString(currentDateString);
		const featureDate = this.getDateFromString(featureDateString);

		switch (featureOperator) {
			case '<':
				return moment(currentDate).isBefore(featureDate);
			case '<=':
				return moment(currentDate).isSameOrBefore(featureDate);
			case '=':
				return moment(currentDate).isSame(featureDate);
			case '>=':
				return moment(currentDate).isSameOrAfter(featureDate);
			case '>':
				return moment(currentDate).isAfter(featureDate);
			default:
				return false;
		}
	}

	/**
	 * Method that validates the dates format and range
	 * @param feature {ProductConfiguration} feature to check date range
	 * @return {boolean}
	 */
	private isValidDateRange(feature: ProductConfiguration): boolean {
		const featureDateRange = feature.filterValue;
		const currentDateString = this.getDateStringWithoutHHMMss(new Date());
		const currentDate = this.getDateFromString(currentDateString);
		const featureDatesArray = featureDateRange.split('-');

		if (featureDatesArray.length > 1) {
			const firstDate = this.getDateFromString(featureDatesArray[0]);
			const secondDate = this.getDateFromString(featureDatesArray[1]);
			return moment(currentDate).isSameOrAfter(firstDate) && moment(currentDate).isSameOrBefore(secondDate);
		} else {
			return false;
		}
	}

	/**
	 * Method that validates the version
	 * @param feature {ProductConfiguration} feature to check version
	 * @return {boolean}
	 */
	private isValidVersion(feature: ProductConfiguration): boolean {
		const featureVersionString = feature.filterValue;
		const featureOperator = feature.operator;
		const currentVersion: number = this.getNumberFromVersion(
			this.translate.instant('v10.commercial.fullVersionPortalEP')
		);
		const featureVersion = this.getNumberFromVersion(featureVersionString);

		if (featureVersion && featureOperator) {
			switch (featureOperator) {
				case '<':
					return currentVersion < featureVersion;
				case '<=':
					return currentVersion <= featureVersion;
				case '=':
					return currentVersion === featureVersion;
				case '>=':
					return currentVersion >= featureVersion;
				case '>':
					return currentVersion > featureVersion;
				default:
					return false;
			}
		} else {
			return false;
		}
	}

	/**
	 * Method that validates the status
	 * @param feature {ProductConfiguration} feature to check status
	 * @return {boolean}
	 */
	private isValidStatus(feature: ProductConfiguration): boolean {
		return feature.filterValue.toLowerCase() === 'ACT'.toLowerCase();
	}

	/**
	 * Method that returns a Date object from date in string format
	 * @param dateString {string} feature to check status
	 * @return {Date} date
	 */
	private getDateFromString(dateString: string) {
		return new Date(moment(dateString, 'YYYYMMDD').format());
	}

	/**
	 * Methos that parse string version to number
	 * @param version {string} version in string format
	 */
	private getNumberFromVersion(version: string): number {
		const versionArray = version.split('.');
		const major = versionArray[0] === '0' ? '' : versionArray[0];
		const minor = versionArray[1] ? versionArray[1] : 0;
		const patch = versionArray[2] ? versionArray[2] : 0;

		return +`${major}${minor}${patch}`;
	}

	/**
	 * Method that returns a date string without HH mm ss
	 * @param current {Date} date to format
	 * @return {string} date string without HH mm ss
	 */
	private getDateStringWithoutHHMMss(current) {
		const MM = current.getMonth() + 1 < 10 ? `0${current.getMonth() + 1}` : `${current.getMonth() + 1}`;
		const DD = current.getDate() < 10 ? `0${current.getDate()}` : `${current.getDate()}`;
		return `${current.getFullYear()}${MM}${DD}`;
	}
}
