import { AfterViewChecked, Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';
import { Breakpoints } from '@mva10/mva10-angular';
import { AppService } from 'src/app/app.service';
import { ResizeService } from 'src/app/mva10/shared/services/resize.service';
import { OverLayContent } from '../../models/overlayContent.model';

@Component({
	selector: 'sp-full-overlay',
	templateUrl: './full-overlay.component.html',
	styleUrls: ['./full-overlay.component.scss'],
})
export class FullOverlayComponent implements OnInit, AfterViewChecked {
	@Input() overLayContent!: OverLayContent;
	@Input() fixedImageHeight: boolean = false;
	@Output() closeAction: EventEmitter<any> = new EventEmitter<any>();
	@Output() btnAction: EventEmitter<any> = new EventEmitter<any>();
	@ViewChild('pageContent') pageContent: ElementRef;
	@ViewChild('footer') footer: ElementRef;
	@ViewChild('imageContainer') imageContainer: ElementRef;

	BREAKPOINTS: typeof Breakpoints = Breakpoints;
	breakpoint: Breakpoints;
	remainingHeight: number;
	pageHeight: string = '0px';
	isMobileScrollable: boolean;
	overlaySubtitleContent: SafeHtml;
	constructor(private appService: AppService, private resizeService: ResizeService, private sanitizer: DomSanitizer) {}

	ngOnInit(): void {
		this.breakpoint = this.resizeService.breakpoint;
		this.overlaySubtitleContent = this.sanitizer.bypassSecurityTrustHtml(this.overLayContent?.subtitleContentSafeHTML);
	}

	close(): void {
		this.closeAction.emit();
	}
	btnClick(): void {
		this.btnAction.emit();
	}

	ngAfterViewChecked(): void {
		setTimeout(() => {
			this.remainingHeight = 0;
			const pageHeight: number =
				this.pageContent?.nativeElement?.offsetHeight +
				this.footer?.nativeElement?.offsetHeight +
				this.imageContainer?.nativeElement?.offsetHeight;
			this.remainingHeight = window.innerHeight - this.appService.bannerHeight - pageHeight;
			this.isMobileScrollable = this.remainingHeight < 0 ? true : false;
			this.pageHeight = pageHeight + 'px';
		}, 0);
	}
}
