import { CommitmentModel } from './commitments.model';
import { DiscountModel } from './discount.model';
import { ProductModel } from './product-composition.model';
import { RecoEvaluatedOffers } from './reco-evaluated-offers.model';

export class OfferModel {
	codReco: string;
	codTax: string;
	hasAgent: boolean;
	id: string;
	offerNetAmount: string;
	offerTotalAmount: string;
	typeReco: string;
	product?: ProductModel;
	discounts: DiscountModel[];
	commitments: CommitmentModel[];
	evaluatedOffers?: RecoEvaluatedOffers;
	codJourney?: string;
	dateIniOffer?: string;
	dateEndOffer?: string;
}
