import { Component, Input, OnInit } from '@angular/core';
import { Breakpoints } from '@mva10/mva10-angular';
import { TranslateService } from '@ngx-translate/core';
import { AppService } from 'src/app/app.service';
import { BillingService } from 'src/app/billing/billing.service';
import { NewTaggingHelperService } from 'src/app/core/services/new-tagging.helper.service';
import { FOLDER_FINANCED_DEVICES, PAYMENTS_TRACKING } from 'src/app/shared/constants/archetype/paymentsTracking';
import { PaymentJourny } from 'src/app/shared/enums/payment-journy.enum';
import { NewTaggingJsonModel } from 'src/app/shared/models/new-tagging-json-model';
import { NewTaggingStateListModel } from 'src/app/shared/models/new-tagging-state-list.model';
import { CrossVariables } from 'src/app/shared/models/tagging.model';
import { coin } from '../../../shared/constants/defines';
import { FinancedDevicesPaymentService } from '../../services/financed-devices-payment.service';

@Component({
	selector: 'sp-financed-devices-cancellation',
	templateUrl: './financed-devices-cancellation.component.html',
	styleUrls: ['./financed-devices-cancellation.component.scss'],
})
export class FinancedDevicesCancellationComponent implements OnInit {
	@Input() amountToPay: string = '';
	public coin: string = coin;
	public isTabletView: boolean = false;
	constructor(
		public appService: AppService,
		private translate: TranslateService,
		private newTagging: NewTaggingHelperService,
		private billingService: BillingService,
		private financedDeviceService: FinancedDevicesPaymentService
	) {}

	ngOnInit(): void {
		this.newTagging.getJsonTagging(FOLDER_FINANCED_DEVICES).subscribe((data: NewTaggingJsonModel) => {
			const state: NewTaggingStateListModel =
				data.page.stateList[PAYMENTS_TRACKING.FINANCED_DEVICES.STATES.ACTIVE_FINANCING_PAYMENT_METHOD];
			state.journey.journey_category = CrossVariables.client_typology;
			state.custom_map['product_brand'] = this.billingService.selectedFundedDevice?.brand;
			state.custom_map['product_model'] = this.billingService.selectedFundedDevice?.model;
			this.newTagging.state(state);
		});
		this.isTabletView = window.innerWidth >= Breakpoints.TABLET;
	}

	openPaymentModule(): void {
		this.translate.get('v10.payment.itemsList.financedDevices.es.conceptValue').subscribe((concept) => {
			this.financedDeviceService.openPaymentModulePostPaidFinancedDevices(
				this.amountToPay.toString(),
				PaymentJourny.financedDevicesCancellation,
				concept
			);
		});
	}
}
