import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, throwError } from 'rxjs';
import { catchError, finalize, tap } from 'rxjs/operators';
import { NewTaggingHelperService } from '../../../app/core/services/new-tagging.helper.service';
import { ConfigurationService } from '../../core/services/configuration.service';
import { VERIFYEMAIL } from '../constants/defines';
import { AppAvailabilityCheckService } from './app-availability-check.service';

@Injectable()
export class AppAvailabilityCheckInterceptor implements HttpInterceptor {
	private shouldShowUnavailabilityModal: boolean = false;
	constructor(
		private appAvailabilityCheckService: AppAvailabilityCheckService,
		private configService: ConfigurationService,
		private _TaggingHelperService: NewTaggingHelperService
	) {}
	intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
		this.shouldShowUnavailabilityModal = false;
		return next.handle(req).pipe(
			tap((event) => {
				if (event instanceof HttpResponse) {
					this.shouldShowUnavailabilityModal = this.configService.configuration?.isAppUnavailable;
				}
			}),
			catchError((error) => {
				this.shouldShowUnavailabilityModal = error?.error?.ecode?.toString() === VERIFYEMAIL.errorAppUnavilability;
				if (this.shouldShowUnavailabilityModal) {
					this._TaggingHelperService.creatErrorItem(error);
				}
				return throwError(error);
			}),
			finalize(() => {
				if (this.shouldShowUnavailabilityModal) {
					this.appAvailabilityCheckService.onAppUnavailable.emit();
				}
			})
		);
	}
}
