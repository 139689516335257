import { Component } from '@angular/core';
import { CardImageSelectorModel } from '@mva10/mva10-angular';
import { NewTaggingHelperService } from '../../../core/services/new-tagging.helper.service';
import { TaggingHelperService } from '../../../core/services/tagging.helper.service';
import { LeftMenuWs10Service } from '../../../mva10/shared/services/left-menu-ws10.service';
import { MyAccountService } from '../../../my-account/my-account.service';
import { DashboardService } from '../../services/dashboard.service';
import { SidemenuConfigService } from '../../services/sidemenu-config.service';

@Component({
	selector: 'sp-submenu-account',
	templateUrl: './submenu-account.component.html',
	styleUrls: ['./submenu-account.component.scss'],
})
export class SubmenuAccountComponent {
	constructor(
		public myAccountService: MyAccountService,
		private leftMenuService: LeftMenuWs10Service,
		public menuService: SidemenuConfigService,
		private taggingHelper: TaggingHelperService,
		public dashboardService: DashboardService,
		private newTaggingHelperService: NewTaggingHelperService
	) {}

	myAccountSelectorCardClicked(clickedCard: CardImageSelectorModel): void {
		this.leftMenuService.closeSubmenu();
		setTimeout(() => {
			// On menu close
			this.menuService.onCarouselCardClicked(clickedCard.id);
		}, 500);
	}
}
