import { Component, EventEmitter, OnDestroy, OnInit, Output } from '@angular/core';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { Subscription } from 'rxjs';
import { config } from '../../../config/pages-config';
import { AppService } from '../../app.service';
import { NewTaggingHelperService } from '../../core/services/new-tagging.helper.service';
import { FOLDER_MY_PAYMENTS, PAYMENTS_TRACKING } from '../../shared/constants/archetype/paymentsTracking';
import { coin } from '../../shared/constants/defines';
import { FinancedDevicesTabs } from '../../shared/enums/financed-deviced-tabs.enum';
import { FinancedPaymentStatus } from '../../shared/enums/financedPaymentStatus.enum';
import { CustomerAccount } from '../../shared/models/customer-account.model';
import { FinancedDevicesFilterModel } from '../../shared/models/financed-devices-filter-model';
import { FundedDevice } from '../../shared/models/funded-device.model';
import { Mva10FilterComponent } from '../../shared/models/mva10-fllter-component';
import { NewTaggingJsonModel } from '../../shared/models/new-tagging-json-model';
import { FundedDevicesService } from '../../shared/services/funded-devices.service';
import { BillingService } from '../billing.service';

@Component({
	selector: 'sp-financed-devices',
	templateUrl: './financed-devices.component.html',
	styleUrls: ['./financed-devices.component.scss'],
})
export class FinancedDevicesComponent implements OnInit, OnDestroy {
	/** Should be emitted with true if API is succeeded, or false if failed */
	@Output() apiStatus: EventEmitter<boolean> = new EventEmitter<boolean>();
	public coin: string = coin;
	loading: boolean = false;
	financedSelectBadgeData: FinancedDevicesFilterModel[];
	inProgressFinancedDevices: FundedDevice[] = [];
	completedFinancedDevices: FundedDevice[] = [];
	shownFinancedDevices: FundedDevice[] = [];
	selectedTab: FinancedDevicesTabs;

	subscriptions: Subscription = new Subscription();

	constructor(
		public translate: TranslateService,
		public appService: AppService,
		private fundedDevicesService: FundedDevicesService,
		public billingService: BillingService,
		private route: Router,
		private newTagging: NewTaggingHelperService
	) {}

	ngOnInit(): void {
		this.initDevices();
		this.subscriptions.add(
			this.billingService.selectedSiteChanged.subscribe((selectedSite: CustomerAccount) => {
				this.initDevices(selectedSite.id);
			})
		);
	}

	public initDevices(selectedSiteID?: string): void {
		this.loading = true;
		const siteID: string = selectedSiteID ? selectedSiteID : this.billingService.selectedSiteId;
		this.billingService.fundedDevices = [];
		this.subscriptions.add(
			this.fundedDevicesService.GetfinancedDevicesBySiteId(siteID, true).subscribe(
				(fundedDevices: FundedDevice[]) => {
					this.billingService.fundedDevices = fundedDevices;
					this.mapFundedDevices();
					this.apiStatus.emit(true);
					this.loading = false;
					this.getWcs();
					this.shownFinancedDevices =
						this.selectedTab === this.finanedDevicesTabs.inProgress
							? this.inProgressFinancedDevices
							: this.completedFinancedDevices;
				},
				(err) => {
					this.apiStatus.emit(false);
					this.loading = false;
				}
			)
		);
	}

	getWcs(): void {
		this.subscriptions.add(
			this.translate.get('v10.payment.itemsList.financedDevices').subscribe((data) => {
				this.financedSelectBadgeData = [
					{
						id: this.finanedDevicesTabs.inProgress,
						checked: this.billingService.selectedFinancedDeviceTab
							? this.billingService.selectedFinancedDeviceTab === this.finanedDevicesTabs.inProgress
							: true,
						text: data.filterInprogress,
					},
					{
						id: this.finanedDevicesTabs.completed,
						checked: this.billingService.selectedFinancedDeviceTab
							? this.billingService.selectedFinancedDeviceTab === this.finanedDevicesTabs.completed
							: false,
						text: data.filterCompleted,
					},
				];
				this.selectedTab = this.financedSelectBadgeData.find((tab) => tab.checked === true).id;
				this.billingService.selectedFinancedDeviceTab = null;
			})
		);
	}

	navigateToFundedDevicesDetails(selectedDevice: FundedDevice): void {
		this.billingService.selectedFinancedDeviceTab = this.selectedTab;
		this.billingService.selectedFundedDevice = selectedDevice;
		this.route.navigate([config.billing.fundedDeviceDetails.route]);
	}

	public get financedPaymentStatus(): typeof FinancedPaymentStatus {
		return FinancedPaymentStatus;
	}

	public get finanedDevicesTabs(): typeof FinancedDevicesTabs {
		return FinancedDevicesTabs;
	}
	mapFundedDevices(): void {
		this.inProgressFinancedDevices = [];
		this.completedFinancedDevices = [];
		this.sortFinancedDevicesByDate(this.billingService.fundedDevices).forEach((device) => {
			device.paymentStatus.toLowerCase() === this.financedPaymentStatus.Ready.toLowerCase()
				? this.inProgressFinancedDevices.push(device)
				: this.completedFinancedDevices.push(device);
		});
	}

	sortFinancedDevicesByDate(financedDevices: FundedDevice[]): FundedDevice[] {
		// Sort funded devices by date, newer first if have same date device with less pending Amount should be shown at the top
		financedDevices.sort((currentDevice, nextDevice) => {
			if (currentDevice.purchaseDate > nextDevice.purchaseDate) {
				return -1;
			} else if (currentDevice.purchaseDate < nextDevice.purchaseDate) {
				return 1;
			} else {
				return this.sortFinancedDevicesByPendingAmount(currentDevice, nextDevice);
			}
		});
		return financedDevices;
	}

	sortFinancedDevicesByPendingAmount(currentDevice: FundedDevice, nextDevice: FundedDevice): number {
		if (currentDevice.totalPendingAm < nextDevice.totalPendingAm) {
			return -1;
		} else {
			return currentDevice.totalPendingAm > nextDevice.totalPendingAm ? 1 : 0;
		}
	}

	onTabClick(filterId: FinancedDevicesTabs, filterItem: Mva10FilterComponent): void {
		if (this.selectedTab !== filterId) {
			this.financedSelectBadgeData.forEach((item) => {
				item.checked = item.id === filterId ? true : false;
			});
			if (filterId === this.finanedDevicesTabs.inProgress) {
				this.shownFinancedDevices = this.inProgressFinancedDevices;
			} else if (filterId === this.finanedDevicesTabs.completed) {
				this.shownFinancedDevices = this.completedFinancedDevices;
				this.newTagging.getJsonTagging(FOLDER_MY_PAYMENTS).subscribe((data: NewTaggingJsonModel) => {
					this.newTagging.interaction(
						data.eventList[PAYMENTS_TRACKING.MY_PAYMENT.INTERACTIONS.CLICK_ON_FINANCED_COMPLETED_FILTER],
						data.page
					);
				});
			}
			this.selectedTab = filterId;
		} else {
			filterItem.checked = true;
		}
	}

	ngOnDestroy(): void {
		this.subscriptions.unsubscribe();
	}
}
