import { TranslateService } from '@ngx-translate/core';
import { CustomStyle, PaymentFullTrayModel } from '../../../shared/models/payment';
import { overlayTitleSelector, overlayCenterTitleStyle } from '../../../shared/constants/defines';
import { PaymentPages } from '../../../shared/enums/payment-pages.enum';
import { PaymentWalletService } from '../../../shared/services/payment-wallet.service';
import { DelegateCreationService } from '../delegate-creation.service';
import { PaymentNavigationService } from '../payment-navigation.service';
import { PaymentTrayConfigInterface } from './payment-tray-config-interface';
import { PaymentTrayConfigPrePaid } from './payment-tray-config-prePaid';

export class PaymentTrayConfigSaveCreditCard extends PaymentTrayConfigPrePaid implements PaymentTrayConfigInterface {
	// this component is treated like an ovrelay we remove tem from history after navigating away from them
	// not to show them to the user accidentaly when he navigate back
	compnentToBeRemovedFromHistoryAfternavigation: PaymentPages[] = [
		PaymentPages.cancel,
		PaymentPages.loading,
		PaymentPages.deleteConfiramtion,
	];
	/** variable to be used for getting a tray config method based on component name */
	trayConfigurations: any = {
		[PaymentPages.thirdPartyContainer]: this.getIframeCompTrayConfig.bind(this),
		[PaymentPages.ko]: this.getKoCompTrayConfig.bind(this),
		[PaymentPages.cancel]: this.getCancelCompTrayConfig.bind(this),
		[PaymentPages.ok]: this.getOkCompTrayConfig.bind(this),
		[PaymentPages.saveCreditCard]: this.getSaveCreditCardCompTrayConfig.bind(this),
	};
	constructor(
		public paymentNavService: PaymentNavigationService,
		public delegateService: DelegateCreationService,
		public translate: TranslateService,
		public paymentWalletService: PaymentWalletService
	) {
		super(paymentNavService, delegateService, translate, paymentWalletService);
	}
	/**
	 * @param compName the page we need it's tray configuration
	 * function should return PaymentFullTrayModel containing
	 * the proper tray configuration
	 */
	getCurrentTrayConfig(compName: PaymentPages): PaymentFullTrayModel {
		let config: PaymentFullTrayModel;
		/** if compName is exist in trayConfigurations then invoke its method to get tray configuration */
		if (this.trayConfigurations[compName]) {
			config = this.trayConfigurations[compName]();
		} else {
			config = this.getIframeCompTrayConfig();
		}
		return config;
	}

	/**
	 * function return PaymentFullTrayModel
	 * the configration of the iframe screen configration
	 */
	getIframeCompTrayConfig(): PaymentFullTrayModel {
		const paymentFullTrayModel: PaymentFullTrayModel = this.getInitalConfigModel();
		this.translate.get(this.paymentNavService.getWcsPath(PaymentPages.thirdPartyContainer)).subscribe((data) => {
			paymentFullTrayModel.MVA10Config.title = data.title;
		});
		paymentFullTrayModel.paymentCustomConfig.closeBtnAction = () => {
			this.paymentNavService.startCancelPayment();
		};
		paymentFullTrayModel.paymentCustomConfig.initComponantOnBack = false;
		paymentFullTrayModel.paymentCustomConfig.customStyles =
			paymentFullTrayModel.paymentCustomConfig.customStyles.concat(
				new CustomStyle(overlayTitleSelector, overlayCenterTitleStyle)
			);
		return paymentFullTrayModel;
	}

	/**
	 * function return PaymentFullTrayModel
	 * the configration of the ko screen configration
	 */
	getKoCompTrayConfig(): PaymentFullTrayModel {
		const paymentFullTrayModel: PaymentFullTrayModel = this.getInitalConfigModel();
		paymentFullTrayModel.MVA10Config.title = this.delegateService.paymentErrorConfig?.currentErrorTitle;
		paymentFullTrayModel.paymentCustomConfig.closeBtnAction = () => {
			this.paymentNavService.closePayment();
		};
		paymentFullTrayModel.paymentCustomConfig.customStyles =
			paymentFullTrayModel.paymentCustomConfig.customStyles.concat(
				new CustomStyle(overlayTitleSelector, overlayCenterTitleStyle)
			);
		return paymentFullTrayModel;
	}

	/**
	 * function return PaymentFullTrayModel
	 * the configration of the cancel screen configration
	 */
	getCancelCompTrayConfig(): PaymentFullTrayModel {
		const paymentFullTrayModel: PaymentFullTrayModel = this.getInitalConfigModel();
		this.translate.get(this.paymentNavService.getWcsPath(PaymentPages.cancel)).subscribe((data) => {
			paymentFullTrayModel.MVA10Config.title = data.title;
		});
		paymentFullTrayModel.paymentCustomConfig.closeBtnAction = this.paymentNavService.backWithoutAnimation.bind(
			this.paymentNavService
		);
		paymentFullTrayModel.paymentCustomConfig.customStyles =
			paymentFullTrayModel.paymentCustomConfig.customStyles.concat(
				new CustomStyle(overlayTitleSelector, overlayCenterTitleStyle)
			);
		return paymentFullTrayModel;
	}

	/**
	 * function return PaymentFullTrayModel
	 * the configration of the ok screen configration
	 */
	getOkCompTrayConfig(): PaymentFullTrayModel {
		const paymentFullTrayModel: PaymentFullTrayModel = this.getInitalConfigModel();
		this.translate.get(this.paymentNavService.getWcsPath(PaymentPages.ok)).subscribe((data) => {
			paymentFullTrayModel.MVA10Config.title = data.title;
		});
		paymentFullTrayModel.paymentCustomConfig.closeBtnAction = () => {
			this.delegateService.paymentSuccessConfig.closeBtnAction();
		};
		paymentFullTrayModel.paymentCustomConfig.customStyles =
			paymentFullTrayModel.paymentCustomConfig.customStyles.concat(
				new CustomStyle(overlayTitleSelector, overlayCenterTitleStyle)
			);
		return paymentFullTrayModel;
	}
	/**
	 * function return PaymentFullTrayModel
	 * the configration of the ok screen configration
	 */
	getSaveCreditCardCompTrayConfig(): PaymentFullTrayModel {
		const paymentFullTrayModel: PaymentFullTrayModel = this.getInitalConfigModel();
		this.translate.get('v10.payment.messagesList.saveCreditCard.es.entryScreen').subscribe((data) => {
			paymentFullTrayModel.MVA10Config.title = data.title;
		});
		paymentFullTrayModel.paymentCustomConfig.closeBtnAction = () => {
			this.delegateService.paymentSuccessConfig.closeBtnAction();
		};
		paymentFullTrayModel.paymentCustomConfig.customStyles =
			paymentFullTrayModel.paymentCustomConfig.customStyles.concat(
				new CustomStyle(overlayTitleSelector, overlayCenterTitleStyle)
			);
		return paymentFullTrayModel;
	}
}
