import { Result } from './buy-sim-saveOffer.model';

export class IdValidationModel {
	message: string;
	resultValidation: boolean;
}

export class IdValidationResponse {
	result: Result;
	data: IdValidationModel;
}
