import { Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { SubscriptionService } from '../../../core/services/subscription.service';
import { config } from './../../../../config/pages-config';
import { AppService } from './../../../app.service';
import { UserSettingToken } from './../../enums/user-setting-token.enum';
import { UserSettingsService } from './../../services/user-settings.service';

@Component({
	selector: 'sp-entry-points-management',
	templateUrl: './entry-points-management.component.html',
	styleUrls: ['./entry-points-management.component.scss'],
})
export class EntryPointsManagementComponent implements OnInit {
	@Input() entryPoints;
	@Input() imageUrl: string;

	public button: string;
	public title: string;
	public icon: string;

	constructor(
		private translate: TranslateService,
		private userSettingsService: UserSettingsService,
		public appService: AppService,
		public services: SubscriptionService,
		private router: Router
	) {}

	ngOnInit() {
		this.orderEntryPoints();
		this.loadWCS();
	}

	loadWCS() {
		this.translate.get('v10.commercial.handsetRenewal').subscribe((data) => {
			this.button = data.button;
			this.title = data.title;
			this.icon = data.banner.images.icon;
		});
	}

	orderEntryPoints() {
		if (this.entryPoints && this.entryPoints.entryPoints) {
			this.entryPoints = this.entryPoints.entryPoints;
		}
	}

	public navigateToMobiles() {
		this.userSettingsService
			.getUserSettingsToken(
				UserSettingToken.DxlAuth,
				this.services.customerData.customerAccountsId,
				this.services.customerData.currentService.id,
				false
			)
			.subscribe(() => this.router.navigate([config.CommercialMobile.route]));
	}
}
