import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { BehaviorSubject, Observable, throwError } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { NewTaggingHelperService } from '../../core/services/new-tagging.helper.service';
import { StorageService } from '../../core/services/storage.service';
import { SubscriptionService } from '../../core/services/subscription.service';
import { TaggingHelperService } from '../../core/services/tagging.helper.service';
import { LOCAL_STORAGE_KEYS, commercialMobile, insurance, newTaggingClicks, pay } from '../constants/defines';
import { API_URLS } from '../constants/routes-config';
import { ClientTypology } from '../enums/clientTopology.enum';
import { ProductConfigurationID } from '../enums/product-configuration-id.enum';
import { CommercialDetail } from '../models/commercial-detail.model';
import {
	Capacity,
	Characteristics,
	Color,
	CommercialTerminalDetail,
	CommercialTerminalDetailList,
	Items,
	ListaIconos,
	MainCharacteristics,
	Promotion,
} from '../models/commercial-terminal-detail.model';
import {
	CommercialFinancingOption,
	CommercialPayment,
	CommercialRefinancingOption,
} from '../models/commercial-terminal-financing.model';
import { CommercialTerminal } from '../models/commercial-terminal.model';
import { UtilitiesService } from '../utils/utilities.service';
import { CommercialMobileService } from './commercial-mobile.service';
import { MicroFlowsService } from './micro-flows.service';
import { ProductConfigurationService } from './product-configuration.service';

@Injectable()
export class CommercialMobileDetailService {
	private terminalDetail: CommercialTerminalDetail = null;

	private listaIconos: ListaIconos[];
	private listaIconosSubject = new BehaviorSubject([]);
	public listaIconos$ = this.listaIconosSubject.asObservable();

	public productTagg: string;
	public isME: boolean;
	public isYU: boolean;

	private showFinalPayment: boolean = false;
	public availableCharacteristics: Array<string>;
	public selectedCharacteristics: Array<object>;
	public clientType: string;
	public nameDeviceDetail: string;
	private isMicroResegmented: boolean;
	public analyticsCategoryType: string;

	public category: string = this.tagging
		.getUserType(this.storageService.userProfile.customerType, ClientTypology)
		.toLowerCase();
	public subcategory: string =
		this.subscriptionService.customerData.SiteType === pay.postpaid_check ? pay.postpaid : pay.prepaid;

	constructor(
		private http: HttpClient,
		private storageService: StorageService,
		private productConfiguration: ProductConfigurationService,
		private commercialMobileService: CommercialMobileService,
		private translate: TranslateService,
		private microFlowService: MicroFlowsService,
		private readonly utilities: UtilitiesService,
		private newTaggingHelper: NewTaggingHelperService,
		private tagging: TaggingHelperService,
		private subscriptionService: SubscriptionService
	) {
		this.isMicroResegmented = this.utilities.isConsumerAndHasMicro();
	}

	public getTerminalsDetail(
		clientId: string,
		sap: string,
		msisdn: string,
		cartItemId: string,
		group: string,
		offerId: string,
		paymentType: string,
		terminalType?: string,
		promoCode?: string
	): Observable<CommercialDetail | CommercialTerminalDetail> {
		let url = API_URLS.CommercialMobiles.getTerminaldetail;
		const isMeOrMicroResegmented: boolean = this.isME || this.isMicroResegmented;
		if (group) {
			url += commercialMobile.group.replace('{group}', group);
		}
		if (offerId) {
			url += commercialMobile.offerId.replace('{offerId}', offerId);
		}
		if (paymentType) {
			url += commercialMobile.paymentType.replace('{paymentType}', paymentType);
		}
		if (promoCode) {
			url += commercialMobile.promoCode.replace('{promoCode}', promoCode);
		}
		if (terminalType) {
			url = url.replace('{terminalType}', terminalType);
		} else {
			url = url.replace('{terminalType}', '3');
		}
		url = url
			.replace(
				'{clientType}',
				isMeOrMicroResegmented ? commercialMobile.clientTypeMicro.toString() : commercialMobile.clientType.toString()
			)
			.replace(
				'{shopType}',
				isMeOrMicroResegmented
					? commercialMobile.shopTypeMicro.toString()
					: this.isYU
					? commercialMobile.shopTypeYu.toString()
					: commercialMobile.shopType.toString()
			)
			.replace('{clientId}', clientId)
			.replace('{sap}', sap)
			.replace('{msisdn}', msisdn)
			.replace('{profile}', commercialMobile.profile)
			.replace('{cartItemId}', cartItemId)
			.replace('{version}', this.translate.instant('v10.commercial.fullVersionPortalEP'));
		let headers = new HttpHeaders();
		headers = headers.append('Content-Type', 'application/json');
		headers = headers.append(
			'Authorization',
			'Bearer ' + this.storageService.getLocalStorage(LOCAL_STORAGE_KEYS.ACCESS_TOKEN)
		);
		const options = {
			headers: headers,
			auth_ticket: this.storageService.getLocalStorage(LOCAL_STORAGE_KEYS.JWT),
			NTOL_TXID: this.microFlowService.cartId,
			withCredentials: true,
		};

		return this.http.get(url, options).pipe(
			map((res: any) => {
				this.commercialMobileService.taxType = res.taxType;
				this.terminalDetail = res;
				if (!res.error) {
					this.terminalDetailLogic(res);
					this.clientType = res.tipoCliente ? res.tipoCliente.toLocaleLowerCase() : '';
				}
				return this.terminalDetail;
			}),
			catchError((err) => {
				return throwError(err);
			})
		);
	}

	private terminalDetailLogic(terminal: CommercialTerminal) {
		this.showFinalPayment = this.productConfiguration.shouldShowFeature(ProductConfigurationID.showFinalPayment);
		this.terminalDetail = new CommercialTerminalDetail();
		this.terminalDetail.terminalDetailList = new Array<CommercialTerminalDetailList>();
		this.listaIconos = new Array<ListaIconos>();
		this.terminalDetail.nombre = terminal.nombre || null;
		this.terminalDetail.id = terminal.id || 0;
		this.terminalDetail.type = terminal.type || null;
		// jsonpath refactor: params read as item['xxx'] are missing in model
		this.terminalDetail.taxType = terminal['taxType'] || null;
		this.terminalDetail.taxValue = terminal['taxValue'] || null;
		this.terminalDetalLogicPrepare(terminal);
	}

	public terminalDetalLogicPrepare(terminal: any): void {
		terminal.listTerminals.map((term) => {
			let terminalDetailCommercial: CommercialTerminalDetailList = new CommercialTerminalDetailList();
			terminalDetailCommercial.capacidad = new Capacity();
			terminalDetailCommercial.color = new Color();
			const iconos = new ListaIconos();
			terminalDetailCommercial = this.terminalDetailLogicData1(terminalDetailCommercial, term);
			terminalDetailCommercial = this.terminalDetailLogicData2(terminalDetailCommercial, term);
			terminalDetailCommercial = this.terminalDetailLogicData3(terminalDetailCommercial, term);
			terminalDetailCommercial = this.terminalDetailLocigFinanciation(terminalDetailCommercial, term);

			if (term.listaIconos) {
				iconos.imagenIcono = term.listaIconos.imagenIcono || null;
				iconos.textoIcono = term.listaIconos.textoIcono || null;
				this.listaIconos?.push(iconos);
			}

			terminalDetailCommercial.listaPromos = new Array<Promotion>();
			this.terminalDetailPromos(terminalDetailCommercial, term);
			terminalDetailCommercial.itemStock = term.itemStock || null;
			terminalDetailCommercial.ecoRatingImage = term.ecoRatingImage;
			terminalDetailCommercial.ecoRatingText = term.ecoRatingText;
			return terminalDetailCommercial;
		});
	}

	public terminalDetailPromos(terminalDetailCommercial: CommercialTerminalDetailList, term: any): void {
		term.listaPromos?.forEach((promo: Promotion) => {
			let promos: Promotion = new Promotion();
			promos.added = promo.added || null;
			// jsonpath refactor: added 'any' type due to missing model (wrong value assign?)
			promos.type = (promo.added as any) || null;
			promos.id = promo.id || 0;
			promos.internalName = promo.internalName || null;
			promos.publicName = promo.publicName || null;
			promos.startDate = promo.startDate || null;
			promos.endDate = promo.endDate || null;
			promos.stateType = promo.stateType || null;
			promos.flagRegional = promo.flagRegional || null;
			// jsonpath refactor: params read as item['xxx'] are missing in model
			promos.manualProvision = promo['manualprovision'] || null;
			promos.discountType = promo.discountType || null;
			promos.codigoOferta = promo['codigoOfertaCruzada'] || null;
			promos.evuluationOrder = promo['evaluationOrder'] || 0;
			promos = this.promotionData(promos, promo);
			terminalDetailCommercial.listaPromos?.push(promos);
		}, true);
	}

	public promotionData(promos: Promotion, promo: Promotion): Promotion {
		promos.shortText = promo.shortText || null;
		// jsonpath refactor: params read as item['xxx'] are missing in model
		promos.image = promo['urlFileImage'] || null;
		promos.longText = promo.longText || null;
		promos.llbbText = promo.llbbText || null;
		promos.llbbUrl = promo.llbbUrl || null;
		promos.listIcon = promo.listIcon || null;
		promos.detailIcon = promo.detailIcon || null;
		promos.urlEmbeddedVideo = promo.urlEmbeddedVideo;
		promos.urlImagePreviewVideo = promo.urlImagePreviewVideo;
		promos.promotionTitle = promo.promotionTitle;
		promos.detailPromotion = promo['detailPromotionText'];
		promos.cardColor = promo.cardColor;
		return promos;
	}

	public terminalDetailLocigFinanciation(
		terminalDetailCommercial: CommercialTerminalDetailList,
		term: any
	): CommercialTerminalDetailList {
		const financingOptions: CommercialFinancingOption[] = term.multiFinancingDevice?.financingOptions || [];
		if (financingOptions?.length) {
			terminalDetailCommercial.financingOptions = [];
			financingOptions?.forEach((option: CommercialFinancingOption) => {
				let financingOption: CommercialFinancingOption = new CommercialFinancingOption();
				financingOption.numMeses = option.numMeses || 0;
				financingOption.flagDefaultMonthly = option.flagDefaultMonthly || false;
				financingOption = this.terminalDetailPayments(option, financingOption);
				if (financingOption?.payments?.length) {
					terminalDetailCommercial.financingOptions?.push(financingOption);
				}
			});
		}
		if (terminalDetailCommercial?.financingOptions?.length) {
			this.terminalDetail.terminalDetailList?.push(terminalDetailCommercial);
		}
		return terminalDetailCommercial;
	}

	public terminalDetailPayments(
		option: CommercialFinancingOption,
		financingOption: CommercialFinancingOption
	): CommercialFinancingOption {
		const payments: CommercialPayment[] = option.payments || [];
		if (payments?.length) {
			financingOption.payments = [];
			payments?.forEach((item: CommercialPayment) => {
				let payment: CommercialPayment = new CommercialPayment();
				payment = this.paymentData1(payment, item);
				payment = this.paymentData2(payment, item);
				// jsonpath refactor: params read as item['xxx'] are missing in model
				const optionsRefinancingFp: CommercialRefinancingOption[] = item['finalPayment']?.optionsRefinancingFp || [];
				if (optionsRefinancingFp?.length) {
					payment.optionsRefinancingFp = [];
					optionsRefinancingFp?.forEach((option) => {
						const refinancingOption = new CommercialRefinancingOption();
						refinancingOption.numberMonths = option['finalPaymentNumberMonths'] || 0;
						refinancingOption.monthlyFeeTaxes = option['finalPaymentMonthlyFeeTaxes'] || 0;
						refinancingOption.monthlyFee = option['finalPaymentMonthlyFee'] || 0;
						payment.optionsRefinancingFp?.push(refinancingOption);
					});
				}
				if (!payment.flagFinalPayment || (this.showFinalPayment && payment.flagFinalPayment)) {
					financingOption.payments?.push(payment);
				}
			});
		}
		return financingOption;
	}

	public paymentData1(payment: CommercialPayment, item: CommercialPayment): CommercialPayment {
		payment.availableBuyNow = item.availableBuyNow || false;
		payment.idPrecio = item.idPrecio || 0;
		payment.pagoAlContadoConImpuesto = item.pagoAlContadoConImpuesto || 0;
		payment.pagoAlContadoSinImpuesto = item.pagoAlContadoSinImpuesto || 0;
		payment.cuotaMensualConImpuesto = item.cuotaMensualConImpuesto || 0;
		payment.cuotaMensualSinImpuesto = item.cuotaMensualSinImpuesto || 0;
		payment.pagoInicialConImpuesto = item.pagoInicialConImpuesto || 0;
		payment.pagoInicialSinImpuesto = item.pagoInicialSinImpuesto || 0;
		payment.crossedOfferOriginal = item.crossedOfferOriginal || '';
		payment.offerType = item.offerType || '';
		payment.offerOrigin = item.offerOrigin;
		return payment;
	}

	public paymentData2(payment: CommercialPayment, item: CommercialPayment): CommercialPayment {
		payment.codPromo = item.codPromo;
		payment.canonConImpuesto = item.canonConImpuesto || 0;
		payment.canonSinImpuesto = item.canonSinImpuesto || 0;
		payment.paymentType = item.paymentType || null;
		payment.flagDefaultPayment = item.flagDefaultPayment || false;
		payment.flagPaymentOptionFp = item.flagPaymentOptionFp || false;
		payment.flagFinalPayment = item['finalPayment']?.flagFinalPayment || false;
		// jsonpath refactor: params read as item['xxx'] are missing in model
		payment.flagOptionsRefinancingFp = item['finalPayment']?.flagOptionsRefinancingFp || false;
		payment.deviceFinalPaymentTaxes = item['finalPayment']?.deviceFinalPaymentTaxes || false;
		payment.deviceFinalPayment = item['finalPayment']?.deviceFinalPayment || false;
		payment.permanenceDuration = item.permanenceDuration ?? null;
		payment.permanencePenalty = item.permanencePenalty ?? null;
		payment.permanencePenaltyTaxes = item.permanencePenaltyTaxes ?? null;
		payment.pvpTotal = item.pvpTotal || null;
		payment.pvpTotalTaxes = item.pvpTotalTaxes || null;
		return payment;
	}

	public terminalDetailLogicData1(
		terminalDetailCommercial: CommercialTerminalDetailList,
		term: any
	): CommercialTerminalDetailList {
		terminalDetailCommercial.ordenGrupo = term.ordenGrupo || 0;
		terminalDetailCommercial.idTerminal = term.idTerminal || 0;
		terminalDetailCommercial.idGrupo = term.idGrupo || 0;
		terminalDetailCommercial.sap = term.sap || 0;
		terminalDetailCommercial.color.name = term.color || null;
		terminalDetailCommercial.color.code = term.codigoColor || null;
		terminalDetailCommercial.modelo = term.modelo || null;
		terminalDetailCommercial.capacidad.capacidadValorUnidad = term.capacidad || null;
		terminalDetailCommercial.capacidad.unidad = term.capacidadValorUnidad?.unidad || null;
		terminalDetailCommercial.capacidad.valor = term.capacidadValorUnidad?.valor || null;
		terminalDetailCommercial.categoryType = term.categoryType || null;
		terminalDetailCommercial.idTipoTerminal = term.idTipoTerminal || null;
		terminalDetailCommercial.imagenFrontalMovil = term.imagenFrontalMovil || null;
		terminalDetailCommercial.textoImagenFrontalMovil = term.textoImagenFrontalMovil || null;
		return terminalDetailCommercial;
	}

	public terminalDetailLogicData2(
		terminalDetailCommercial: CommercialTerminalDetailList,
		term: any
	): CommercialTerminalDetailList {
		terminalDetailCommercial.imagenTraseraMovil = term.imagenTraseraMovil || null;
		terminalDetailCommercial.textoImagenTraseraMovil = term.textoImagenTraseraMovil || null;
		terminalDetailCommercial.imagenLateralMovil = term.imagenLateralMovil || null;
		terminalDetailCommercial.textoImagenLateralMovil = term.textoImagenLateralMovil || null;
		terminalDetailCommercial.imagenMedidasMovil = term.imagenMedidasMovil || null;
		terminalDetailCommercial.textoImagenMedidasMovil = term.textoImagenMedidasMovil || null;
		terminalDetailCommercial.imagenFrontalTablet = term.imagenFrontalMovil || null;
		terminalDetailCommercial.textoImagenFrontalTablet = term.textoImagenFrontalMovil || null;
		terminalDetailCommercial.imagenTraseraTablet = term.imagenTraseraMovil || null;
		terminalDetailCommercial.textoImagenTraseraTablet = term.textoImagenTraseraMovil || null;
		terminalDetailCommercial.imagenLateralTablet = term.imagenLateralMovil || null;
		terminalDetailCommercial.textoImagenLateralTablet = term.textoImagenLateralMovil || null;
		terminalDetailCommercial.imagenMedidasTablet = term.imagenMedidasMovil || null;

		return terminalDetailCommercial;
	}

	public terminalDetailLogicData3(
		terminalDetailCommercial: CommercialTerminalDetailList,
		term: any
	): CommercialTerminalDetailList {
		terminalDetailCommercial.textoImagenMedidasTablet = term.textoImagenMedidasMovil || null;
		terminalDetailCommercial.so = term.so || null;
		terminalDetailCommercial.imagenSo = term.imagenSo || null;
		terminalDetailCommercial.marca = term.marca || null;
		terminalDetailCommercial.idMarca = term.idMarca || null;
		terminalDetailCommercial.limitado = term.limitado || null;
		terminalDetailCommercial.selected = term.selected || null;
		terminalDetailCommercial.insuranceByDefault = this.getInsuranceByDefault(term);
		terminalDetailCommercial.descripcion = term.descripcion || null;
		terminalDetailCommercial.characteristics = term.characteristics || null;
		terminalDetailCommercial.promotedCanon = term.promotedCanon;
		terminalDetailCommercial.analyticsCategoryType = term.analyticsCategoryType;
		terminalDetailCommercial.isPrioritary = term.isPrioritary;
		terminalDetailCommercial.techDeviceType = term.techDeviceType;
		if (terminalDetailCommercial.characteristics) {
			terminalDetailCommercial.characteristics = this.getCharacteristics(
				terminalDetailCommercial.characteristics,
				term
			);
		}
		terminalDetailCommercial.mainCharacteristics = term.mainCharacteristics || null;
		if (terminalDetailCommercial.mainCharacteristics) {
			terminalDetailCommercial.mainCharacteristics = this.getMainCharacteristics(
				terminalDetailCommercial.mainCharacteristics,
				term
			);
		}
		return terminalDetailCommercial;
	}

	public getTerminalDetailsLoaded() {
		return this.terminalDetail;
	}

	public setSelected(selected: number, state: boolean) {
		this.terminalDetail.terminalDetailList[selected].selected = state;
	}

	private getCharacteristics(characToStore, characToGet) {
		characToStore = new Array<Characteristics>();
		const charact: Characteristics[] = characToGet.characteristics || [];
		charact.map((ch) => {
			const charct = new Characteristics();
			charct.section = ch.section || null;
			charct.icon = ch.icon || null;
			charct.items = ch.items || null;
			if (charct.items) {
				const items = new Array<Items>();
				const item = charct.items || null;
				item.map((it) => {
					const itm = new Items();
					itm.name = it.name || null;
					itm.value = it.value || null;
					items.push(itm);
				});
				charct.items = items;
			}
			characToStore.push(charct);
		});
		return characToStore;
	}

	private getMainCharacteristics(
		characToStore: MainCharacteristics[],
		characToGet: CommercialTerminalDetailList
	): MainCharacteristics[] {
		characToStore = new Array<MainCharacteristics>();
		const charact: MainCharacteristics[] = characToGet.mainCharacteristics || [];
		charact.map((ch) => {
			const charct: MainCharacteristics = new MainCharacteristics();
			charct.code = ch.code || null;
			charct.desc = ch.desc || null;
			charct.order = ch.order || null;
			charct.sortMethod = ch.sortMethod || null;
			charct.sortType = ch.sortType || null;
			charct.icon = ch.icon || null;
			charct.value = ch.value || null;
			charct.extendedValue = ch.extendedValue || null;
			charct.standardValue = ch.standardValue || null;
			charct.unit = ch.unit || null;
			this.translate.get('v10.commercial.mfdevices.mainCharacteristics').subscribe((data) => {
				charct.title = data[charct.code] ? data[charct.code] : data.default;
			});
			characToStore.push(charct);
		});
		return characToStore;
	}

	public getTaxValue() {
		return this.terminalDetail.taxValue;
	}
	public getTaxType() {
		return this.terminalDetail.taxType.toLocaleUpperCase();
	}

	public setMainCharacteristics(charact: MainCharacteristics[]): void {
		this.availableCharacteristics = new Array();
		this.order(charact);
		charact.map((res) => {
			if (!this.availableCharacteristics.includes(res.desc)) {
				this.availableCharacteristics.push(res.desc);
			}
		});
	}

	private order(mainChar: MainCharacteristics[]): void {
		mainChar.sort((achar: MainCharacteristics, bchar: MainCharacteristics) => {
			return achar.order - bchar.order;
		});
	}

	private getInsuranceByDefault(term: CommercialTerminalDetailList): boolean {
		return term.insuranceByDefault || false;
	}

	public clickToggleInsuranceTag(text: string, protect: boolean): void {
		this.newTaggingHelper.getJsonTagging('insurance/detail-insurance').subscribe((data) => {
			data.eventList['click_toggle'].event.event_label = data.eventList['click_toggle'].event.event_label.replace(
				'{0}',
				text
			);
			data.eventList['click_toggle'].event.event_context = !protect ? insurance.protected : insurance.notProtected;
			data.eventList['click_toggle'].journey.journey_category = this.category;
			data.eventList['click_toggle'].journey.journey_subcategory = this.subcategory;
			this.newTaggingHelper.interaction(data.eventList['click_toggle'], data.page);
		});
	}

	public clickLinkInsuranceTag(checked: boolean, text: string, insurance: string): void {
		this.newTaggingHelper.getJsonTagging('insurance/detail-insurance').subscribe((data) => {
			const link: string = checked ? newTaggingClicks.click_link2 : newTaggingClicks.click_link1;
			if (checked) {
				data.eventList[link].event.event_context = insurance;
			}
			data.eventList[link].event.event_label = data.eventList[link].event.event_label.replace('{0}', text);
			data.eventList[link].custom_map['&&products'] = this.productTagg;
			data.eventList[link].journey.journey_category = this.category;
			data.eventList[link].journey.journey_subcategory = this.subcategory;
			this.newTaggingHelper.interaction(data.eventList[link], data.page);
		});
	}

	public tagStateInsurance(): void {
		this.newTaggingHelper.getJsonTagging('insurance/vodafone-care').subscribe((data) => {
			data.page.custom_map['&&products'] = this.productTagg;
			data.page.journey.journey_category = this.category;
			data.page.journey.journey_subcategory = this.subcategory;
			this.newTaggingHelper.state(data.page);
		});
	}
}
