import { BenefitStatus } from '../enums/benefit-status.enum';
import { PlanType } from '../enums/plan-type.enum';
import { RoamingMessageType } from '../enums/roamingMessageType.enum';
import { ServiceType } from '../enums/serviceType.enum';
import { TariffStatus } from '../enums/tariffStatus.enum';
import { Address } from './Address.model';
import { DecoEquipment } from './decoEquipment.model';
import { Equipment } from './equipment.model';
import { SecureNet, SecureNetFamily } from './secure-net.model';
import { SuperWifi } from './super-wifi.model';
import { Devices } from './tariff-list-response.model';

export class Tariff {
	public Name: string;
	public Email: string;
	public Id: string;
	public Code: string; // The primary key of the tariff
	public serviceType: ServiceType;
	public Status: TariffStatus;
	public statusSBA: string;
	public Fee: string; // oop sprint 3
	public Unit: string; // oop sprint 3
	public RoamingMessage: string; // The roaming message if the tariff plan is compatible with roaming
	public RoamingMessageType: RoamingMessageType;
	public Yu: boolean; // A flag that indicates whether the tariff plan is youth compatible or not to display message depending on this
	public Xs: boolean; // A flag that indicates whether the tariff plan is Xs
	public Address?: Address;
	public ShortDescription: string;
	public LongDescription: string;
	public DownloadSpeed: string;
	public DownloadSpeedUnit: string;
	public UploadSpeed: string;
	public UploadSpeedUnit: string;
	public RouterName: string;
	public RouterSerialNumber: string;
	public RouterType?: string;
	// jsonpath refactor: changed from string to boolean
	public HasPendingChannel: boolean; // The flag indicates if the TV has a pending channel to be added
	public NumberOfChannels: string; // Number of the channels this TV service has
	public OnlineStatus: string; // The online TV status of the TV service
	public Channels: string; // List of channels TV Service has
	public group: string;
	public lastStatus: String;
	public segments: string[];
	public benefitStatus?: BenefitStatus;
	public planType?: PlanType;
	public topupPeriodicity: string;
	public benefitDescription: string;
	public benefitExpiryDate?: string;
	public benefitRenewalDate?: string;
	public hasBenifits?: boolean;
	public SuperOffer?: string;
	public isTotalMobility?: boolean;
	public superWifi?: SuperWifi;
	public secureNet?: SecureNet;
	public secureNetFamily?: SecureNetFamily;
	public mercury?: boolean;
	public equipment?: Equipment;
	public decoEquipment?: DecoEquipment[];
	public vouchers?: Voucher[];
	public isPrepaidCommunity?: boolean;
	public promotions?: TariffPromotion[];
	public isElegible?: boolean;
	public smartPay?: boolean;
	// jsonpath refactor: changed to newer Devices type
	public devices?: Devices;
	public isFacilUser?: boolean;
}

export class Voucher {
	public type: string;
	public status: string;
	public serviceId?: string;
	public voucher?: string;
	public endDate?: string;
}

export class TariffPromotion {
	public activationDate: Date;
	public deactivationDate: Date;
	public code: string;
	public fee: number;
	public name: string;
	public status: string;
}
