<div [ngClass]="{ 'display-service': !isErrorConnection, 'no-display-service': isErrorConnection }">
	<div
		*ngIf="
			((spinnerCompany && !companyError && companyId) || customerAccounts.length == 0) &&
			!customerAccountService.isError
		"
		class="spinner-service-selector"
	>
		<div class="spinnerContainer col-xs-12">
			<svg class="spinner" width="65px" height="65px" viewBox="0 0 66 66" xmlns="http://www.w3.org/2000/svg">
				<circle class="path" fill="none" stroke-width="6" stroke-linecap="round" cx="33" cy="33" r="30"></circle>
			</svg>
		</div>
		<div class="spinnerText" *ngIf="customerAccounts.length !== 0">
			{{ loadingCIFText }}
		</div>
	</div>
	<div *ngIf="companyError" class="InfoMessage">
		<div class="iconText">
			<span class="icon icon-error-circle position-style"> </span>
			<div class="text-info">
				<p class="text-line-info">
					{{ companyErrorMsg }}
				</p>
				<p class="textEmail">
					{{ largCompanyUrl }}
				</p>
			</div>
		</div>
	</div>
	<div class="accordion-side-menu service-dropdown" *ngIf="sites.length > 1 && !spinnerCompany && !companyError">
		<accordion [closeOthers]="true">
			<form novalidate [formGroup]="form">
				<accordion-group
					*ngFor="let site of sites; let i = index"
					(click)="clickEventF(site.id)"
					[id]="site.id"
					#group
					[isOpen]="site.open"
				>
					<div accordion-heading>
						<div class="item-content dropdown">
							<span class="item-element col-xs-8">
								<p class="formattedaddres">{{ site.formattedAddress }}</p>
								<!-- <p class="addres1"> {{site.addressLineOne}} </p>
                                <p class="addres2"> {{site.addressLineTwo}} </p> -->
							</span>
							<span
								class="dropdown-arrow"
								[ngClass]="{ 'icon-chevron-up': group?.isOpen, 'icon-chevron-down': !group?.isOpen }"
							>
							</span>
						</div>
					</div>

					<div>
						<div *ngIf="showError" class="warningMessage">
							<div class="iconText">
								<span class="icon icon-warning position-style"> </span>
								<div class="textWarning">
									<p class="textLine1">{{ warningSiteMessage }}</p>
								</div>
								<button id="btn_retry" class="redButton" (click)="retryButton(site.id)">{{ warningButtonText }}</button>
							</div>
						</div>
						<div *ngIf="spinner && !showError" class="spinner-container">
							<div class="spinnerContainer col-xs-12">
								<svg class="spinner" width="65px" height="65px" viewBox="0 0 66 66" xmlns="http://www.w3.org/2000/svg">
									<circle
										class="path"
										fill="none"
										stroke-width="6"
										stroke-linecap="round"
										cx="33"
										cy="33"
										r="30"
									></circle>
								</svg>
							</div>

							<div class="spinnerSiteText">
								{{ loadingSiteText }}
							</div>
						</div>
						<accordion [closeOthers]="true" *ngIf="vodafoneOne?.length > 0">
							<div class="acc-arrow-alignment">
								<accordion-group
									#one
									[isOpen]="true"
									class="vodafone-one-accoridion"
									[ngClass]="{ 'accordion-collapse': !one?.isOpen }"
								>
									<div accordion-heading>
										<div class="item-content dropdown nasted">
											<span class="icon icon-convergente-1 solid accordion-icon"> </span>
											<span class="item-element"> {{ vodafoneOneName }} </span>
											<span
												class="dropdown-arrow"
												[ngClass]="{ 'icon-chevron-up': one?.isOpen, 'icon-chevron-down': !one?.isOpen }"
											>
											</span>
										</div>
									</div>

									<div>
										<sp-radio-button-advanced
											id="SpAccordionRadioButtonComp_radio_companyNameField_2"
											[newClass]="false"
											id="rdo_vodafoneOne"
											[one]="true"
											*ngIf="group?.isOpen"
											[defaultCheckedValue]="subscriptionService.customerData.currentService?.id"
											formControlName="companyNameField"
											[dataSource]="vodafoneOne"
											[groupName]="'service'"
											(radioClick)="radioClick($event)"
										>
										</sp-radio-button-advanced>
									</div>
								</accordion-group>
							</div>
						</accordion>
						<sp-radio-button-advanced
							id="SpAccordionRadioButtonComp_radio_companyNameField_1"
							[newClass]="false"
							id="rdo_companyNameField"
							*ngIf="items?.length && group?.isOpen && !spinnerCompany && !showError"
							[defaultCheckedValue]="subscriptionService.customerData.currentService?.id"
							formControlName="companyNameField"
							[dataSource]="items"
							[groupName]="'service'"
							(radioClick)="radioClick($event)"
						>
						</sp-radio-button-advanced>
					</div>
				</accordion-group>
			</form>
		</accordion>
		<accordion *ngIf="hasExternalServices">
			<div accordion-heading>
				<div class="item-content dropdown" (click)="siebleRedirect()">
					<span class="icon icon-location col-xs-2"> </span>
					<span class="item-element col-xs-8">
						<p class="formattedaddres">{{ 'common.itemsList.manageOthers.body' | translate }}</p>
					</span>
					<span class="icon icon-chevron-right col-xs-1"> </span>
				</div>
			</div>
		</accordion>
	</div>
	<form
		novalidate
		[formGroup]="form"
		*ngIf="((sites.length == 1 && items) || (!sites.length && items)) && !spinnerCompany && !tariffService.isError"
	>
		<div *ngIf="spinner && !showError">
			<div class="spinnerContainer col-xs-12">
				<svg class="spinner" width="65px" height="65px" viewBox="0 0 66 66" xmlns="http://www.w3.org/2000/svg">
					<circle class="path" fill="none" stroke-width="6" stroke-linecap="round" cx="33" cy="33" r="30"></circle>
				</svg>
			</div>

			<div class="spinnerSiteText">
				{{ loadingSiteText }}
			</div>
		</div>

		<div class="accordion-side-menu service-dropdown" *ngIf="vodafoneOne.length > 0">
			<accordion [closeOthers]="true">
				<div class="acc-arrow-alignment">
					<accordion-group
						#one
						[isOpen]="true"
						class="vodafone-one-accoridion"
						[ngClass]="{ 'accordion-collapse': !one?.isOpen }"
					>
						<div accordion-heading>
							<div class="item-content dropdown">
								<span class="icon icon-convergente-1 solid accordion-icon"> </span>
								<span class="item-element"> {{ vodafoneOneName }} </span>
								<span
									class="dropdown-arrow"
									[ngClass]="{ 'icon-chevron-up': one?.isOpen, 'icon-chevron-down': !one?.isOpen }"
								>
								</span>
							</div>
						</div>

						<div>
							<sp-radio-button-advanced
								id="SpAccordionRadioButtonComp_radio_companyNameField_4"
								[newClass]="false"
								id="rdo_subscriptionService"
								[one]="true"
								[defaultCheckedValue]="subscriptionService.customerData.currentService?.id"
								formControlName="companyNameField"
								[dataSource]="vodafoneOne"
								[groupName]="'service'"
								(radioClick)="radioClick($event)"
							>
							</sp-radio-button-advanced>
						</div>
					</accordion-group>
				</div>
			</accordion>
		</div>

		<sp-radio-button-advanced
			[companyTab]="companyId"
			id="SpAccordionRadioButtonComp_radio_companyNameField_3"
			[newClass]="false"
			id="btn_service"
			*ngIf="items.length && !spinner"
			[defaultCheckedValue]="subscriptionService.customerData.currentService?.id"
			formControlName="companyNameField"
			[dataSource]="items"
			[groupName]="'service'"
			(radioClick)="radioClick($event)"
			class="up-vodafone-one"
		>
		</sp-radio-button-advanced>
	</form>
</div>
