<div class="chat-pass row change-padding cont-height">
	<div class="col-xs-12">
		<div class="reamin-usage row change-padding">
			<div
				class="remain col-xs-12">

				<span class="bundle-name-lines">
					<mva10-c-icon
					[size]="5"
					[iconId]="'icon-call'"
					class="icon"
					></mva10-c-icon>
					<ng-container *ngIf="Bundle.TotalConsumption === 0 && Bundle.ConsumedConsumption === null">
						<span *ngIf="!Bundle.title"> {{ Bundle.Name }}&nbsp;</span>
						<span *ngIf="Bundle.title"> {{ Bundle.title }}&nbsp;</span>
						<span *ngIf="Bundle.title"> {{ Bundle.titleLimit }} {{ Bundle.titleRate }} </span>
					</ng-container>
					<ng-container *ngIf="Bundle.TotalConsumption !== null && Bundle.ConsumedConsumption !== null">
						<mva10-c-data-chart
						[description]="Bundle.Name"
						[valueText]="valueText"
						[max]="100"
						[min]="0"
						[value]="Bundle.RemainingConsumptionPercentage | dot">

						</mva10-c-data-chart>
				</ng-container>
				</span>
				<p class="call-price active-unlimated">
					<span
						*ngIf="parent.tariffService.Tariff.isPrepaidCommunity && parent.isBenifitsActive && parent.isPrePaid"
						class="active-package"
					>
						{{ 'v10.productsServices.prepaidCommunity.benefits.unlimitedMinutes' | translate }}
					</span>
				</p>
			</div>
			<div class="col-xs-5 usage" *ngIf="Bundle.IsUnLimited">
				<span class="package"> + {{ 'productsServices.prepaidPlan.itemsList.planEstablish.body' | translate }} </span>
			</div>
		</div>
		<div class="call-info" *ngIf="Bundle.PayPerUse && Bundle.CallSetup">
			<!-- pay per use b true w mintues -->
			<p class="call-price">
				<span class="package"> + {{ 'productsServices.prepaidPlan.itemsList.planEstablish.body' | translate }} </span>
			</p>
		</div>
	</div>
</div>
