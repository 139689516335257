import { DecoGuideType, NebaGuideType, NebaTvGuideType, RouterGuideType } from '../enums/guide-types.enum';
import { OrderFlow } from '../enums/order-flow.enum';
import { StatePedido } from '../enums/order-type.enum';

export interface PortabilidadRechazada {
	tipoRechazoPortabilidad?: number;
	codigoRechazo?: string;
	tipoDoc?: string;
	numDoc?: string;
	tipoLinea?: string;
	numPortar?: string;
	nombre?: string;
	apellido1?: string;
	apellido2?: string;
	iccid?: string;
	nationality?: string;
	type?: string;
}

export class Scoring {
	necesitaDocumentosScoring: number;
	documentos: string[];
	importeFianza: string;
	documentsStatus?: DocumentStatus[];
	constructor() {
		this.documentos = [];
		this.documentsStatus = [];
	}
}

export interface DocumentStatus {
	showFileTypeError?: boolean;
	showFileSizeError?: boolean;
	docType: string;
	status: string;
	selectedFile?: FileList;
	b64?: String | ArrayBuffer;
}

export interface Paso {
	pasoNombre: string;
	pasoOrden: string;
}

export class DetallePedidoResponse {
	errorCode: number;
	error: string;
	tipo: number;
	descripcion: string;
	paso: string;
	activarRouter: RouterGuideType;
	instalarEquipos: DecoGuideType;
	guiaAutoiNeba?: NebaGuideType;
	guiaAutoiNebaTv?: NebaTvGuideType;
	guiaOneConecta: number;
	cambiarEquipos: number;
	portabilidadRechazada?: PortabilidadRechazada;
	scoring?: Scoring;
	pasos: Paso[];
	mensaje: string;
	mensajeOL: string;
	fechaPrimeraHorquilla: string;
	fechaSegundaHorquilla: string;
	mensajePF: string;
	mensajePM: string;
	mensajeEE: string;
	mensajeSuperior?: string;
	formulario: string;
	fechaCierre: string;
	fechaPlanificacion?: number;
	fechaCancelacion?: number;
	fechaHistoricoOL?: number;
	otsHijas: DetallePedidoResponse[];
	incluidoPedido: string[];
	permanencias: string[];
	cargos?: unknown;
	citaPlanificada: boolean;
	cambioCita: number;
	esOrdenNeba?: boolean;
	devolucionesEquipos?: DevolucionEquipos[];
	mensajeFijo?: string;
	urlVPT?: string;
	fijoTemporal?: string;
	/**
	 * Last OL order status
	 */
	estadool?: string;
	constructor() {
		this.portabilidadRechazada = null;
		this.scoring = new Scoring();
		this.pasos = [];
		this.otsHijas = [];
		this.devolucionesEquipos = [];
	}
	cancelarCanje?: number;
}

export interface DevolucionEquipos {
	numEquipo: string;
	estado: string;
	codDevolucion: number;
	textoDescripcion: string;
	fechaLimite: number;
}

export class DetallePedidoExtended extends DetallePedidoResponse {
	orderFlow?: OrderFlow;
	/**
	 * If true, current flow is allowed to show AUTOI card in order detail.
	 * Allowed flows are defined in 'autoiEnabledFlows' WCS config.
	 */
	hasAutoInstallationCard?: boolean;
	/**
	 * If true, current order flow is able to show option to change AUTEC appointment.
	 * Based on 'canChangeFromAutoiToAutecEnabledFlows' WCS config.
	 */
	canChangeFromAutoiToAutecAppointment?: boolean;
	numeroPedido?: string;
	isDetail?: boolean;
	mostrarMensajes?: boolean;
	warningMessage?: string;
	statePedido?: StatePedido;
}
