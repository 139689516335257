import { Component, OnDestroy, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { ERRORCODES, Result } from '../../shared/constants/defines';
import { IframeStatus } from '../../shared/enums/iframe-status.enum';
import { PaymentErrorCategory } from '../../shared/enums/payment-error-category.enum';
import { PaymentJourneyDetail } from '../../shared/enums/payment-journey-detail.enum';
import { PaymentPages } from '../../shared/enums/payment-pages.enum';
import { IframeWCS, ThirdPartyIframePayload } from '../../shared/models/payment';
import { PaymentErrorService } from '../services/payment-error.service';
import { PaymentIframeService } from '../services/payment-iframe.service';
import { PaymentNavigationService } from '../services/payment-navigation.service';
import { PaymentSuccessService } from '../services/payment-success.service';
import { PaymentTaggingService } from '../services/payment-tagging.service';

@Component({
	selector: 'sp-payment-iframe-container',
	templateUrl: './payment-iframe-container.component.html',
})
export class PaymentIframeContainerComponent implements OnInit, OnDestroy {
	/* payment tranaction id */
	transactionId: string = '';
	/* timer between every interaction user do between any iframe page */
	interactionsTimer: number = 50;
	/* globel timer for the whole payment journey */
	globalTimer: string = '300';
	/* the third party url will be used */
	thirdPartyUrl: string = '';
	/* object that have the wcs keys and values  */
	wcsKeys: IframeWCS = new IframeWCS();
	/* boolean set to true when user cancel his transaction
  used to block the timeout patch request
   */
	isIframeCanceled = false;
	isPatchRequestFired = false;
	constructor(
		private paymentIframeService: PaymentIframeService,
		private paymentErrorService: PaymentErrorService,
		private paymentSuccessService: PaymentSuccessService,
		private payNavSer: PaymentNavigationService,
		private translate: TranslateService,
		private paymentTaggingService: PaymentTaggingService
	) {}

	ngOnInit() {
		this.payNavSer.finalStatus = null;
		this.firePageTagging();
		this.getWCSKeys();
		this.payNavSer.customCancelOverlaySecondBtnAction = this.cancelTranction.bind(this);
		this.getnetplusUrl();
	}
	/**
	 * function used to get net plus (the third party) iframe url
	 * and global and interactions timer
	 */
	getnetplusUrl(): void {
		this.paymentIframeService.getPayment3rdPartyUrl(this.payNavSer.isPayingEnergyDebt).subscribe(
			(res) => {
				this.thirdPartyUrl = res.iframeURL;
				this.interactionsTimer = res.timer;
				this.globalTimer = res.globalTimeout || '300';
				this.transactionId = res.iframeURL.split('numOpOrigen=')[1].split('&')[0];
				this.payNavSer.thirdPartyURLParams.transactionId = this.transactionId;
			},
			(error) => {
				this.paymentErrorService.handleErrorByErrorCode(error.error ? error.error.ecode : '');
			}
		);
	}

	/**
	 *
	 * @param payload object that carry netplus status and tranaction and netplus parameters
	 * and other iframe url param  and error if we have the mandatory one is status and tranction id
	 * @param isCancelPatchRequest boolean to indicate that the caller is the function to cancel the
	 * tranaction
	 * this function is to update the back end with the current status
	 * and based of some condtion it decide which page it will push in the payment journey
	 */
	patchThirdPartyPaymentStatus(payload: ThirdPartyIframePayload, isCancelPatchRequest?: boolean): void {
		// block  timeout patch if cancel patch is fired
		if (!this.isPatchRequestFired) {
			this.isPatchRequestFired = true;
			// to check if cancel screen is opened and we need to wait
			// or we are not opening cancel and need loading
			this.showFullLoading(isCancelPatchRequest);
			payload.transactionId = this.transactionId;
			/* used for tagging */
			this.payNavSer.thirdPartyURLParams.errorCode = payload.error ? payload.error.errorCode : '';
			this.payNavSer.thirdPartyURLParams.errorDesc = payload.error ? payload.error.errorDescription : '';
			this.payNavSer.thirdPartyURLParams.transactionDate = payload.netPlusParams
				? payload.netPlusParams.transactionDate
				: '';
			this.payNavSer.thirdPartyURLParams.cardMask = payload.netPlusParams ? payload.netPlusParams.cardMask : '';
			this.payNavSer.thirdPartyURLParams.cardToken = payload.netPlusParams ? payload.netPlusParams.cardToken : '';
			this.payNavSer.thirdPartyURLParams.expiryDate = payload.netPlusParams ? payload.netPlusParams.cardExpiry : '';
			this.paymentIframeService.patchThirdPartyPaymentStatus(payload, this.payNavSer.isPayingEnergyDebt).subscribe(
				(res) => {
					if (this.payNavSer.isPaymentStarted) {
						this.onPatchThirdPartyPaymentStatusSuccess(res, isCancelPatchRequest);
					}
				},
				(error) => {
					const errorCode: string = error?.error?.ecode ?? '';
					if (this.payNavSer.isPaymentStarted) {
						this.onPatchError(isCancelPatchRequest, errorCode);
					}
				}
			);
		}
	}

	/**
	 * @param isCancelPatchRequest boolean to indicate that the caller is the function to cancel the
	 * tranaction
	 * based on the booleans inside this function decide to or not to show full loading page
	 * if the transaction isn't cancelled
	 * if the transaction is cancelled but this journey needs to wait for feedback
	 */
	showFullLoading(isCancelPatchRequest: boolean): void {
		if (
			!this.isIframeCanceled ||
			(this.isIframeCanceled && isCancelPatchRequest && this.payNavSer.paymentJourneyParams.isHandleWaiting)
		) {
			this.payNavSer.openPaymentComponent(PaymentPages.loading);
		}
	}

	/**
	 * @param res dxl res object
	 * @param isCancelPatchRequest boolean to indicate that the caller is the function to cancel the
	 * tranaction
	 * handle the success resoponse
	 */
	onPatchThirdPartyPaymentStatusSuccess(res: any, isCancelPatchRequest: boolean): void {
		// handle all cases if ifrmae is not cancel
		// if iframe cancel only handle response of cancel transaction
		if (
			!this.isIframeCanceled ||
			(this.isIframeCanceled && isCancelPatchRequest && this.payNavSer.paymentJourneyParams.isHandleWaiting)
		) {
			switch (res.status ? res.status.toLowerCase() : '') {
				case IframeStatus.complete:
					this.onPaymentProcessSuccess(res.backendStatus, res.refundCategory);
					break;
				case IframeStatus.canceled:
					this.paymentErrorService.handleErrorCategory(
						this.payNavSer.paymentJourneyParams.isHandleWaiting ? res.refundCategory : ''
					);
					break;
				case IframeStatus.failed:
					this.paymentErrorService.handleErrorCategory(res.category);
					break;
				case IframeStatus.timeout:
					this.paymentErrorService.handleErrorCategory(
						this.payNavSer.paymentJourneyParams.isHandleWaiting ? res.refundCategory : PaymentErrorCategory.TimeOut
					);
					break;
				default:
					this.paymentErrorService.handleErrorCategory();
					break;
			}
		}
	}

	/**
	 * @param backendStatus  mortirolo system Status
	 * @param refundCategory if the payment didn't pass we refundCategory
	 * for some journeies
	 * function used to handle dxl status complete
	 * and decide based on the resoponse which screen will be shown
	 */
	onPaymentProcessSuccess(backendStatus: string, refundCategory?: any): void {
		if (!this.payNavSer.paymentJourneyParams.isHandleWaiting) {
			this.paymentSuccessService.handleSuccessPayment();
		} else if (backendStatus) {
			if (backendStatus.toLowerCase() === Result.OK.toLowerCase()) {
				this.paymentSuccessService.handleSuccessPayment();
			} else {
				// back end status ko or none
				this.paymentErrorService.handleErrorCategory(
					refundCategory ? refundCategory : PaymentErrorCategory.mortiroloKO108
				);
			}
		} else {
			// we are waiting for backend status and it's null
			this.paymentErrorService.handleErrorCategory();
		}
	}

	/**
	 * @param isCancelPatchRequest boolean to indicate that the caller is the function to cancel the
	 * tranaction
	 */
	onPatchError(isCancelPatchRequest: boolean, errorCode: string): void {
		if (errorCode === ERRORCODES.PROMOTION_NOT_APPLIED) {
			this.paymentErrorService.handleErrorCategory(PaymentErrorCategory.validPromoCodeButPromotionNotApplied);
		} else if (
			!this.isIframeCanceled ||
			(this.isIframeCanceled && isCancelPatchRequest && this.payNavSer.paymentJourneyParams.isHandleWaiting)
		) {
			this.paymentErrorService.handleErrorCategory();
		}
	}

	/**
	 * handle when user click to cancel his payment transction
	 * decide if tray is closed or not
	 * and call patchThirdPartyPaymentStatus to inform the dxl with the new status
	 */
	cancelTranction(): void {
		if (!this.isPatchRequestFired) {
			const payload: ThirdPartyIframePayload = new ThirdPartyIframePayload();
			payload.status = IframeStatus.canceled;
			this.payNavSer.finalStatus = IframeStatus.canceled;
			this.isIframeCanceled = true;
			this.patchThirdPartyPaymentStatus(payload, true);
			if (!this.payNavSer.paymentJourneyParams.isHandleWaiting) {
				this.payNavSer.defaultKoClose();
			}
		}
	}

	/**
	 * fill wcsKeys object from wcs
	 */
	getWCSKeys(): void {
		this.translate.get(this.payNavSer.getWcsPath(PaymentPages.thirdPartyContainer)).subscribe((data) => {
			// first line concept values
			this.wcsKeys.concept = data.concept;
			this.wcsKeys.concepto = this.payNavSer.paymentJourneyParams.concepto;
			this.wcsKeys.conceptImg = data.conceptIcon;
			// second line amount value
			this.wcsKeys.amount = data.amount;
			this.wcsKeys.amountValue = this.payNavSer.roundAmountToString(
				this.payNavSer.paymentJourneyParams.amount ? this.payNavSer.paymentJourneyParams.amount.toString() : ''
			);
			this.wcsKeys.amountImg = data.amountIcon;
			// footer text and images
			this.wcsKeys.footerVisaImg = data.visa;
			this.wcsKeys.footerMastercardImg = data.masterCard;
			// still need it from WCS
			this.wcsKeys.footerText = data.securedSSL;
		});
	}

	/**
	 * send tagging
	 */
	firePageTagging(): void {
		this.paymentTaggingService.sendTaggingForPostPaidJourney(PaymentJourneyDetail.thirdPartyIframe);
	}
	ngOnDestroy(): void {
		this.payNavSer.customCancelOverlaySecondBtnAction = null;
	}
}
