import { AddressStatus } from '../enums/addressStatus.enum';
export class Address {
	buildingNo?: string;
	level?: string;
	street?: string;
	town?: string;
	country?: string;
	postcode?: string;
	streetType?: string;
	door?: string;
	formattedAddress?: string;
	line1?: string;
	line2?: string;
	status?: AddressStatus;
	// jsonpath refactor: added buildingNumber and county to match old model
	buildingNumber?: string;
	county?: string;
}
