import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { SpTextComponent } from './sp-text.component';

@NgModule({
	declarations: [SpTextComponent],
	exports: [SpTextComponent],
	imports: [CommonModule, FormsModule, ReactiveFormsModule],
})
export class SpTextModule {}
