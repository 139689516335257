<div
	*ngIf="showChevron && topupService.showCardSelectionSection"
	class="card-selection-container"
	[ngStyle]="{ 'margin-top': marginTop + 'px' }"
>
	<p class="card-selection-title">{{ 'payment.itemsList.rechargeSelector.body' | translate }}</p>
	<div class="card-selection-chevron" (click)="navigateToWalletScreen()">
		<img [src]="selectedCardImage" alt="" class="card-img" />
		<p class="card-mask">{{ selectedCardNumber }}</p>
		<img [src]="appService.getImgFullPath('https://assets-es-pprd.dxlpreprod.local.vodafone.es/assets/images/ic-chevron-right-red@2x.png')" alt="" class="chevron-img" />
	</div>
</div>
