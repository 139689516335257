import { ChangeDetectorRef, Component, ElementRef, OnDestroy, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { OverlayModel } from '@mva10/mva10-angular';
import { TranslateService } from '@ngx-translate/core';
import { Observable, Subscription, forkJoin } from 'rxjs';
import { finalize, map } from 'rxjs/operators';
import { CommonService } from 'src/app/core/services/common.service';
import { config } from '../../../config/pages-config';
import { tagging } from '../../../config/tagging-config';
import { AppService } from '../../app.service';
import { ConfigurationService } from '../../core/services/configuration.service';
import { StorageService } from '../../core/services/storage.service';
import { SubscriptionService } from '../../core/services/subscription.service';
import { TaggingHelperService } from '../../core/services/tagging.helper.service';
import { Validation, closedTicket48horasAlert, v10FaultManagement } from '../../shared/constants/defines';
import { InteractionsId } from '../../shared/enums/interactionsId.enum';
import { ServiceType } from '../../shared/enums/serviceType.enum';
import { SiteStatus } from '../../shared/enums/siteStatus.enum';
import { TicketStatus, TicketTemplateCode } from '../../shared/enums/ticket-status.enum';
import { FaultManagementFaq } from '../../shared/models/FaultManagementFaq';
import { CustomerAccount } from '../../shared/models/customer-account.model';
import { OverlayDataModel } from '../../shared/models/overlayData.model';
import { ScheduledJobModal } from '../../shared/models/service-avalability.model';
import { CardServiceImageSelectorModel, ServiceModel } from '../../shared/models/service.model';
import { TicketModel } from '../../shared/models/ticket.model';
import { CompanyService } from '../../shared/services/company.service';
import { CustomerAccountService } from '../../shared/services/customer-account.service';
import { UtilitiesService } from '../../shared/utils/utilities.service';
import { FaultManagementService } from '../fault-management.service';

@Component({
	selector: 'sp-mva10-landing-page',
	templateUrl: './mva10-landing-page.component.html',
	styleUrls: ['./mva10-landing-page.component.scss'],
})
export class Mva10LandingPageComponent implements OnInit, OnDestroy {
	static someUserSites: boolean = false;
	userSites$: Observable<CustomerAccount[]>;
	ticketStatus = [];
	tickets: TicketModel[] = [];
	showPageHeader: boolean = true;
	pageTitle: string = '';
	closeOverlaySubscrition: any;
	generalLoading: Boolean = true;
	showTickets: Boolean = false;
	showNetworkErrorOverlay = false;
	headernewtorkErrorData = new OverlayModel();
	backdropHeaderData = new OverlayModel();
	confirmHeaderData = new OverlayModel();
	networkErrorUiData = { title: '', description: '', icon: '', button1Text: '' };
	backdropErrorUiData = { title: '', description: '', icon: '', buttonText: '' };
	landingOverlayUiData: OverlayDataModel = { title: '', description: '', icon: '', button1Text: '', button2Text: '' };
	onGoingOrderOverlayData: OverlayDataModel = {
		title: '',
		description: '',
		icon: '',
		button1Text: '',
		button2Text: '',
	};
	openOrderOverlayData: OverlayDataModel = { title: '', description: '', icon: '', button1Text: '', button2Text: '' };
	recentFaultOverlayData: OverlayDataModel = { title: '', description: '', icon: '', button1Text: '', button2Text: '' };
	generalBonitaError = { title: '', description: '', icon: '', button1Text: '', button2Text: '' };
	confirmReOpenCloseData = { title: '', button1Text: '', button2Text: '' };
	changeTicketStatusErr = { title: '', description: '', icon: '', buttonText: '' };
	serviceSelectorLoaded: boolean;
	showBackDrop: boolean = false;
	isOngoingOrder: boolean;
	closedServices: string[] = [];
	servicesSelectorError: boolean = false;
	opendTicketIndex = -1;
	currentTicketData: TicketModel;
	showConfirmError: boolean = false;
	showReopenCloseOverlay: boolean = false;
	showGeneralError: boolean = false;
	generalBreakDown: boolean = false;
	generalTobiBreakDown: boolean = false;
	backBtnSubjectSub: Subscription;
	multiFunctionalAlertWCSDescription: string = '';
	multiFunctionalAlertWCSButton: string = '';
	openScheduledJobOverlay: boolean = false;
	msisdn: string = '';
	userName: string;
	scheduledJobValueDXL: string = '';
	closeNetworkOverlay = () => {};
	public closedticket48horasAlert: Record<string, string> = closedTicket48horasAlert;
	faqs: FaultManagementFaq[] = [];
	constructor(
		public fMService: FaultManagementService,
		private appService: AppService,
		private faultManagementService: FaultManagementService,
		private router: Router,
		public myElement: ElementRef,
		private translate: TranslateService,
		private taggingHelper: TaggingHelperService,
		private companyService: CompanyService,
		private customerAccounts: CustomerAccountService,
		private changeDetector: ChangeDetectorRef,
		private subscriptionService: SubscriptionService,
		private configurationService: ConfigurationService,
		private utilitiesService: UtilitiesService,
		private storageService: StorageService,
		private commonService: CommonService
	) {}

	ngOnInit() {
		this.handleOverrideBackBtn();
		this.setLandingPageTitleHeader();
		this.configurationService.configuration.chatEnabled = false;
		this.userSites$ = this.fMService.userSites$;
		this.getDataFromWCS();
		if (this.fMService.selectedSiteId) {
			this.getServiceAvialityTickets(true);
		} else {
			this.getUserSite();
		}
		this.getUserName();
		this.translate.get('v10.faultManagement.faqs').subscribe((faqObj: Record<string, FaultManagementFaq>) => {
			for (let key in faqObj) {
				const faq: FaultManagementFaq = faqObj[key];
				this.faqs.push(faq);
			}
		});
	}

	sendInteractionsHandlerFaq(faq: FaultManagementFaq, faqNum: number): void {
		this.fMService
			.setInteractionForMonitoring(
				this.fMService.selectedSiteId,
				`${InteractionsId.FAQLink}${faqNum}/${faq.title
					.split(' ')
					.filter((word: string) => word.length > 2)
					.map((wf: string) =>
						wf
							.substring(0, 3)
							.normalize(Validation.auxTagging2)
							.replace(/[\u0300-\u036f]/g, '')
							.toUpperCase()
					)
					.join('_')}`,
				'',
				''
			)
			.subscribe();
	}

	setLandingPageTitleHeader(): void {
		if (this.tickets.length > 1) {
			this.pageTitle = this.translate
				.instant('v10.faultManagement.contentList.ticketLandingPage.title2')
				.replace('{0}', this.utilitiesService.capitalizeText(this.storageService.userProfile.firstName));
		} else {
			this.pageTitle = this.translate
				.instant('v10.faultManagement.contentList.ticketLandingPage.title1')
				.replace('{0}', this.utilitiesService.capitalizeText(this.storageService.userProfile.firstName));
		}
	}

	handleOverrideBackBtn(): void {
		this.commonService.overrideBackHandling = true;
		this.backBtnSubjectSub = this.commonService.backBtnSubject.subscribe(() => {
			this.commonService.overrideBackHandling = false;
			if (this.showTickets) {
				this.getServices();
			} else {
				this.router.navigate([config.dashboard.route]);
			}
		});
	}
	getServiceAvialityTickets(enableRedirect: boolean): void {
		this.generalLoading = true;
		this.appService.showFullAppLoader = false;
		forkJoin([
			this.fMService.getServiceAvailability(this.fMService.selectedSiteId),
			this.fMService.getTickets(this.fMService.selectedSiteId),
		]).subscribe(
			([serviceavailability, tickets]) => {
				if (serviceavailability?.hasScheduledJob) {
					const wcskeyText: string = this.translate.instant(
						`v10.faultManagement.contentList.scheduleJob.${this.fMService.serviceAvailability?.scheduledJobTicket.replace(
							/-/g,
							'_'
						)}.text`
					);
					if (wcskeyText.indexOf(v10FaultManagement) === 0) {
						this.multiFunctionalAlertWCSButton = this.translate.instant(
							'v10.faultManagement.contentList.scheduleJob.button'
						);
						this.multiFunctionalAlertWCSDescription = this.translate.instant(
							'v10.faultManagement.contentList.scheduleJob.text'
						);
					} else {
						this.multiFunctionalAlertWCSDescription = wcskeyText;
						this.multiFunctionalAlertWCSButton = this.translate.instant(
							`v10.faultManagement.contentList.scheduleJob.${this.fMService.serviceAvailability?.scheduledJobTicket.replace(
								/-/g,
								'_'
							)}.button`
						);
					}
				}
				this.fMService.addCloseRopenBtnsToTickets();
				this.tickets = this.fMService.tickets;
				this.setLandingPageTitleHeader();
				if (enableRedirect) {
					this.showTickets = true;
					this.showPageHeader = true;
					this.checkForGeneralBreakDown();
					this.setTicketsSpecificVariablesTagging();
					if (this.tickets.length === 1) {
						this.toggleTicketDetails(true, 0);
					}
					if (this.tickets.length === 0 && !this.generalBreakDown) {
						this.getServices();
					}
				} else {
					this.getServices();
				}
				this.generalLoading = false;
			},
			(error) => {
				this.handle_BackEndError();
			}
		);
	}
	getDataFromWCS() {
		const chatImageUrl: string = this.translate.instant(
			'v10.faultManagement.messagesList.overlay.recentFaultOverlay.png.chatImage'
		);
		this.translate.get('faultManagement').subscribe((data) => {
			this.ticketStatus.push(
				data.itemsList.openBreakdown.body,
				data.itemsList.inDiagnosis.body,
				data.itemsList.inSolution.body,
				data.itemsList.troubleShooting.body
			);
			this.appService.appHeaderTitle = data.title;
			// wcs for US 302 back drop
			this.backdropHeaderData.title = data.title;
			this.backdropErrorUiData.title = data.messagesList.servicesListErr.servicesListErr_title;
			this.backdropErrorUiData.description = data.messagesList.servicesListErr.servicesListErr_description;
			this.backdropErrorUiData.icon = this.appService.getImgFullPath(
				data.messagesList.servicesListErr.servicesListErr_icon.url
			);
			this.backdropErrorUiData.buttonText = data.messagesList.servicesListErr.servicesListErr_button1.text;
			// wcs for ongoing order overlay
			this.onGoingOrderOverlayData.title = data.messagesList.openOrderMessage.title;
			this.onGoingOrderOverlayData.description = data.messagesList.openOrderMessage.description;
			this.onGoingOrderOverlayData.icon = this.appService.getImgFullPath(data.messagesList.openOrderMessage.icon.url);
			this.onGoingOrderOverlayData.button1Text = data.messagesList.openOrderMessage.button1.text;
			this.onGoingOrderOverlayData.button2Text = data.messagesList.openOrderMessage.button2.text;
			// wcs for open  order overlay
			this.openOrderOverlayData.title = data.messagesList.openTicketMessage.title;
			this.openOrderOverlayData.description = data.messagesList.openTicketMessage.description;
			this.openOrderOverlayData.icon = this.appService.getImgFullPath(data.messagesList.openTicketMessage.icon.url);
			this.openOrderOverlayData.button1Text = data.messagesList.openTicketMessage.button1.text;
			this.openOrderOverlayData.button2Text = data.messagesList.openTicketMessage.button2.text;
			// wcs for changeTicketStatusErr
			this.changeTicketStatusErr.title = data.messagesList.chaneTicketStatusErr.title;
			this.changeTicketStatusErr.description = data.messagesList.chaneTicketStatusErr.description;
			this.changeTicketStatusErr.buttonText = data.messagesList.chaneTicketStatusErr.button1.text;
			this.changeTicketStatusErr.icon = data.messagesList.chaneTicketStatusErr.icon.url;
			// wcs for generalBonitaError
			this.generalBonitaError.title = data.messagesList.newFaultGeneralErrorMsg.newFaultGeneralErrorMsg_title;
			this.generalBonitaError.description =
				data.messagesList.newFaultGeneralErrorMsg.newFaultGeneralErrorMsg_description;
			this.generalBonitaError.icon = this.appService.getImgFullPath(
				data.messagesList.newFaultGeneralErrorMsg.newFaultGeneralErrorMsg_icon.url
			);
			this.generalBonitaError.button1Text =
				data.messagesList.newFaultGeneralErrorMsg.newFaultGeneralErrorMsg_button1.text;
			// wcs for recent fault overlay
			this.recentFaultOverlayData.title = data.messagesList.recentFaultOverlay.title.replace(
				'{0}',
				this.utilitiesService.capitalizeText(this.storageService.userProfile.firstName)
			);
			this.recentFaultOverlayData.description = data.messagesList.recentFaultOverlay.description;
			this.recentFaultOverlayData.button1Text = data.messagesList.recentFaultOverlay.button1.text;
			this.recentFaultOverlayData.button2Text = data.messagesList.recentFaultOverlay.button2.text;
			this.recentFaultOverlayData.icon = this.appService.getImgFullPath(chatImageUrl);
		});
	}

	handle_BackEndError() {
		this.closeNetworkOverlay = this.trayCloseAndNavigationToDashboard.bind(this);
		this.translate.get('faultManagement.messagesList.faultManagementLandingErr').subscribe((data) => {
			this.headernewtorkErrorData.title = '';
			this.networkErrorUiData.title = data.faultManagementLandingErr_title;
			this.networkErrorUiData.description = '';
			this.networkErrorUiData.icon = this.appService.getImgFullPath(data.faultManagementLandingErr_icon.url);
			this.networkErrorUiData.button1Text = data.faultManagementLandingErr_button1.text;
		});
		setTimeout(() => {
			this.showNetworkErrorOverlay = true;
			this.showPageHeader = false;
			this.generalLoading = false;
			this.appService.openBubbleStopScroll = true;
		}, 500);
		this.taggingHelper.view(tagging.landingBackEndError);
	}

	trayCloseAndNavigationToDashboard() {
		this.appService.openBubbleStopScroll = false;
		this.appService.showFullAppLoader = true;
		this.router.navigate([config.dashboard.route]);
		this.showNetworkErrorOverlay = false;
		this.taggingHelper.track(
			tagging.faultManagement.failLoadingAveria.eventName,
			tagging.faultManagement.failLoadingAveria.data.event_name,
			tagging.landingBackEndError
		);
	}
	getTickets() {
		this.generalLoading = true;
		this.fMService.getTickets(this.fMService.selectedSiteId).subscribe(
			(data: any) => {
				this.fMService.addCloseRopenBtnsToTickets();
				this.tickets = this.fMService.tickets;
				this.setLandingPageTitleHeader();
				this.setTicketsSpecificVariablesTagging();
				this.taggingHelper.view(tagging.averiaLandingPage, this.fMService.specificAveriaVariables);
				if (this.tickets.length === 1) {
					this.toggleTicketDetails(true, 0);
				}
				if (this.tickets.length === 0) {
					this.getServices();
				}
				this.generalLoading = false;
			},
			(err) => {
				this.handle_BackEndError();
			}
		);
	}

	closeGeneralBreakDownOverlay() {
		this.generalLoading = true;
		this.showNetworkErrorOverlay = false;
		this.generalBreakDown = false;
		this.appService.openBubbleStopScroll = false;
		this.msisdn = this.subscriptionService.customerData.currentService.id;
		this.fMService
			.postServiceAvailability(
				this.fMService.selectedSiteId,
				this.msisdn,
				this.fMService.serviceAvailability.massiveTicketType,
				this.fMService.serviceAvailability.massiveTicketId
			)
			.pipe(finalize(() => this.getTickets()))
			.subscribe();
		this.taggingHelper.track(
			tagging.faultManagement.generalBreakDownClose.eventName,
			tagging.faultManagement.generalBreakDownClose.data,
			tagging.generalBreakDown
		);
	}
	checkForGeneralBreakDown() {
		const hasMassiveTicket: boolean = this.fMService.serviceAvailability.hasMassiveTicket;
		const hasMassiveTicketTobi: boolean = this.fMService.serviceAvailability.hasMassiveTobiTicket;
		const massiveTicketTobiText: string = this.fMService.serviceAvailability.massiveTobiTicketText;
		this.generalBreakDown =
			(hasMassiveTicket || hasMassiveTicketTobi) &&
			!this.fMService.tickets.find(
				(ticket) => ticket.status && ticket.status.toLowerCase() === TicketStatus.Open && ticket.isGeneralBreakDown
			);

		this.generalTobiBreakDown = hasMassiveTicketTobi;

		if (this.generalBreakDown) {
			this.translate.get('faultManagement').subscribe((data) => {
				this.headernewtorkErrorData.title = data.title;
				this.networkErrorUiData.title = data.messagesList.generalBreakdownOverlay.generalBreakdownOverlay_title;
				this.networkErrorUiData.description = hasMassiveTicketTobi
					? massiveTicketTobiText
					: data.messagesList.generalBreakdownOverlay.generalBreakdownOverlay_description;
				this.networkErrorUiData.icon = this.appService.getImgFullPath(
					data.messagesList.generalBreakdownOverlay.generalBreakdownOverlay_icon.url
				);
				this.networkErrorUiData.button1Text =
					data.messagesList.generalBreakdownOverlay.generalBreakdownOverlay_button1.text;
				this.showNetworkErrorOverlay = true;
				this.appService.openBubbleStopScroll = true;
				this.closeNetworkOverlay = this.closeGeneralBreakDownOverlay.bind(this);
				this.taggingHelper.view(tagging.generalBreakDown);
			});
		}
	}

	getServices() {
		this.serviceSelectorLoaded = false;
		this.servicesSelectorError = false;
		this.generalLoading = true;
		this.showTickets = false;
		this.subscriptionService.GetCustomerServices(this.fMService.selectedSiteId, true).subscribe(
			(resp: ServiceModel[]) => {
				this.fMService.setServiceCategory([
					...resp,
					{
						type: ServiceType.Others,
						id: this.faultManagementService.selectedSiteId,
					},
				]);
				this.fMService.getOpenTicketForCategries();
				this.fMService.dimedServices();
				this.fMService.getClosedTicketForCategriesAndOpenChat();
				this.fMService.closedServices();
				this.fMService.mapServicesToSelectorModel();
				this.serviceSelectorLoaded = true;
				this.generalLoading = false;
				this.commonService.overrideBackHandling = true;

				const servicesClosedLessThan48Hrs: ServiceModel[] = this.fMService.services.filter(
					(service: ServiceModel) => service.hasBlueLabelClosedTicket48Hrs
				);
				const hasClosedTicketLessThan48: boolean = this.fMService.tickets.some((ticket: TicketModel) => {
					return ticket.status === TicketStatus.Closed && ticket.averia && !ticket.isGeneralBreakDown;
				});
				const allHaveWarrantyTickets: boolean = this.fMService.tickets.every(
					(ticket: TicketModel) =>
						ticket.template?.templateCode?.includes(TicketTemplateCode.GARANTIA) &&
						ticket.status === TicketStatus.Closed
				);
				if (servicesClosedLessThan48Hrs.length === 0 && !hasClosedTicketLessThan48) return;

				if (servicesClosedLessThan48Hrs.length === 0 && hasClosedTicketLessThan48 && !allHaveWarrantyTickets) {
					this.closedServices.push('');
					return;
				}

				servicesClosedLessThan48Hrs.forEach((serviceClosed: ServiceModel) => {
					const hasOpenTicketSameService: boolean = this.fMService.tickets.some(
						(ticket: TicketModel) =>
							serviceClosed && ticket.serviceId === serviceClosed.id && ticket.status === TicketStatus.Open
					);

					if (hasOpenTicketSameService) {
						this.fMService.updateServiceSelectorModel(serviceClosed, false, true, false);
					} else {
						if (!allHaveWarrantyTickets) {
							this.closedServices.push(serviceClosed.id);
						}
						const hasOtherServiceTypeOpenTicket: boolean = this.fMService.services.some(
							(service: ServiceModel) =>
								serviceClosed &&
								service.serviceCategory === serviceClosed.serviceCategory &&
								service.hasBlueLabelOpenTicket
						);
						if (hasOtherServiceTypeOpenTicket) {
							this.fMService.updateServiceSelectorModel(serviceClosed, false, false, true);
						}
					}
				});
			},
			(error) => {
				this.serviceSelectorLoaded = true;
				this.servicesSelectorError = true;
				this.generalLoading = false;
			}
		);
		this.taggingHelper.view(tagging.bonitaSelectService);
	}
	selectorCardServiceClicked(service: CardServiceImageSelectorModel): void {
		if (service.disabled) return;
		const openTicketBlueLabelText: string = this.translate.instant('faultManagement.itemsList.openTicketAlert2.body');
		const index: number = this.fMService.services.findIndex((fmservice) => {
			return fmservice.id === service.id;
		});
		if (service.blueLabel) {
			if (service.blueLabel === openTicketBlueLabelText) {
				this.handleGoToTicket();
			} else {
				if (service?.goToTicket) {
					this.handleGoToTicket();
					return;
				}
				if (this.backBtnSubjectSub) {
					this.backBtnSubjectSub.unsubscribe();
				}
				this.landingOverlayUiData = this.recentFaultOverlayData;
				this.fMService.selectedService = this.fMService.services[index];
				this.fMService.showFullOverlay = true;
				this.changeDetector.detectChanges();
				this.taggingHelper.view(tagging.serviceWithClosedOrder);
			}
			return;
		}
		this.commonService.overrideBackHandling = false;
		this.isOngoingOrder = false;
		this.fMService.selectedService = this.fMService.services[index];

		if (this.fMService.services[index]?.hasBlueLabelOpenTicket) {
			this.landingOverlayUiData = this.openOrderOverlayData;
			this.fMService.showFullOverlay = true;
			this.changeDetector.detectChanges();
			this.toggleTicketDetails(true, -1);
			this.taggingHelper.view(tagging.serviceWithOpenTicket);
		} else {
			this.router.navigate([config.FaultManagement.OpenNewTicket.route]);
			this.taggingHelper.track(
				tagging.faultManagement.selectServiceToOpenTicket.eventName.replace('{0}', this.fMService.selectedService.name),
				{
					event_name: tagging.faultManagement.selectServiceToOpenTicket.data.event_name.replace(
						'{0}',
						this.fMService.selectedService.name
					),
				},
				tagging.bonitaSelectService
			);
		}
	}
	fullOverlayFirstBtnClick(isOngoingOder: boolean) {
		this.fMService.showFullOverlay = !this.fMService.showFullOverlay;
		if (this.fMService.serviceAvailability?.hasScheduledJob) {
			this.getServiceAvialityTickets(false);
		} else if (isOngoingOder) {
			this.router.navigate([config.login.orderMangment.route]);
			this.taggingHelper.track(
				tagging.faultManagement.checkOngoingOrder.eventName,
				tagging.faultManagement.checkOngoingOrder.data,
				tagging.serviceWithOpenOrder
			);
		} else {
			const index = this.tickets.findIndex((ticket: TicketModel, index) => {
				return ticket.ticketId === this.fMService.selectedService.openTicketId;
			});
			this.toggleTicketDetails(true, index);
			this.taggingHelper.track(
				tagging.faultManagement.checkOpenedTicket.eventName,
				tagging.faultManagement.checkOpenedTicket.data,
				tagging.serviceWithOpenTicket
			);
		}
	}
	toggleTicketDetails(value, index) {
		if (this.opendTicketIndex >= 0) {
			this.tickets[this.opendTicketIndex].showTicketDetail = false;
		}
		if (value && index >= 0) {
			this.tickets[index].showTicketDetail = value;
			setTimeout(() => {
				this.myElement.nativeElement.ownerDocument.getElementById(`box${index}`).scrollIntoView({
					behavior: 'smooth',
					block: 'end',
					inline: 'nearest',
				});
			}, 250);
		}
		this.opendTicketIndex = index;
	}
	showConfirmbackDrop(ticketData: TicketModel) {
		this.showReopenCloseOverlay = true;
		this.appService.openBubbleStopScroll = true;
		this.currentTicketData = ticketData;
		this.translate.get('faultManagement').subscribe((data) => {
			if (this.currentTicketData.status === TicketStatus.Open) {
				this.confirmHeaderData.title = data.messagesList.closeTicketConfirmationMsg.title;
				this.confirmReOpenCloseData.title = data.messagesList.closeTicketConfirmationMsg.description;
				this.confirmReOpenCloseData.button1Text = data.messagesList.closeTicketConfirmationMsg.button1.text;
				this.confirmReOpenCloseData.button2Text = data.messagesList.closeTicketConfirmationMsg.button2.text;
				this.closeTicketpreConfirmTagging();
			} else {
				this.confirmHeaderData.title = data.messagesList.reOpenTicketConfirmationMsg.title;
				this.confirmReOpenCloseData.title = data.messagesList.reOpenTicketConfirmationMsg.description;
				this.confirmReOpenCloseData.button1Text = data.messagesList.reOpenTicketConfirmationMsg.button1.text;
				this.confirmReOpenCloseData.button2Text = data.messagesList.reOpenTicketConfirmationMsg.button2.text;
			}
		});
	}
	closeReopenCloseOverlay() {
		this.showReopenCloseOverlay = false;
		this.appService.openBubbleStopScroll = false;
		if (this.showConfirmError) {
			setTimeout(() => {
				this.showConfirmError = false;
			}, 500);
			this.getTickets();
		}
	}
	changeTicketStatus() {
		this.showReopenCloseOverlay = false;
		this.appService.openBubbleStopScroll = false;
		this.generalLoading = true;
		let newStatus: string;
		newStatus = this.currentTicketData.status === TicketStatus.Open ? TicketStatus.Closed : TicketStatus.Open;
		this.fMService.updateStatus(this.currentTicketData.ticketId, newStatus).subscribe(
			() => {
				this.changStatusSuccessTagging();
				this.getTickets();
			},
			(error) => {
				this.showReopenCloseOverlay = true;
				this.appService.openBubbleStopScroll = true;
				this.showConfirmError = true;
				this.generalLoading = false;
				this.confirmHeaderData.title = '';
				this.changeDetector.detectChanges();
				this.changeStatusErrorTagging(error);
			}
		);
	}
	closeTicketpreConfirmTagging() {
		tagging.faultManagement.closeOpenFault.preconfirmacion.data.journey_subcategory =
			tagging.staticVariables.journeySubCategory.replace('<ticket-type>', this.currentTicketData.ticketType);
		tagging.faultManagement.closeOpenFault.preconfirmacion.data.journey_options = this.currentTicketData.title;
		this.taggingHelper.track(
			tagging.faultManagement.closeOpenFault.preconfirmacion.eventName,
			tagging.faultManagement.closeOpenFault.preconfirmacion.data
		);
	}
	changStatusSuccessTagging() {
		if (this.currentTicketData.status === TicketStatus.Open) {
			tagging.faultManagement.closeOpenFault.success.data.journey_subcategory =
				tagging.staticVariables.journeySubCategory.replace('<ticket-type>', this.currentTicketData.ticketType);
			tagging.faultManagement.closeOpenFault.success.data.journey_options = this.currentTicketData.title;
			this.taggingHelper.track(
				tagging.faultManagement.closeOpenFault.success.eventName,
				tagging.faultManagement.closeOpenFault.success.data
			);
		} else {
			tagging.faultManagement.reOpenClosedFault.success.data.journey_subcategory =
				tagging.staticVariables.journeySubCategory.replace('<ticket-type>', this.currentTicketData.ticketType);
			tagging.faultManagement.reOpenClosedFault.success.data.journey_options = this.currentTicketData.title;
			this.taggingHelper.track(
				tagging.faultManagement.reOpenClosedFault.success.eventName,
				tagging.faultManagement.reOpenClosedFault.success.data
			);
		}
	}
	changeStatusErrorTagging(error) {
		if (this.currentTicketData.status === TicketStatus.Open) {
			tagging.faultManagement.closeOpenFault.error.data.journey_subcategory =
				tagging.staticVariables.journeySubCategory.replace('<ticket-type>', this.currentTicketData.ticketType);
			tagging.faultManagement.closeOpenFault.error.data.journey_options = this.currentTicketData.title;
			tagging.faultManagement.closeOpenFault.error.data.journey_error_category =
				this.taggingHelper.setErrorTypeValue(error);
			if (error.error) {
				tagging.faultManagement.closeOpenFault.error.data.journey_error_code = error.error.ecode
					? error.error.ecode
					: ' ';
				tagging.faultManagement.closeOpenFault.error.data.journey_error_description = error.error.description
					? error.error.description
					: '';
			}
			this.taggingHelper.track(
				tagging.faultManagement.closeOpenFault.error.eventName,
				tagging.faultManagement.closeOpenFault.error.data
			);
		} else {
			tagging.faultManagement.reOpenClosedFault.error.data.journey_subcategory =
				tagging.staticVariables.journeySubCategory.replace('<ticket-type>', this.currentTicketData.ticketType);
			tagging.faultManagement.reOpenClosedFault.error.data.journey_options = this.currentTicketData.title;
			tagging.faultManagement.reOpenClosedFault.error.data.journey_error_category =
				this.taggingHelper.setErrorTypeValue(error);
			if (error.error) {
				tagging.faultManagement.reOpenClosedFault.error.data.journey_error_code = error.error.ecode
					? error.error.ecode
					: ' ';
				tagging.faultManagement.reOpenClosedFault.error.data.journey_error_description = error.error.description
					? error.error.description
					: '';
			}
			this.taggingHelper.track(
				tagging.faultManagement.reOpenClosedFault.error.eventName,
				tagging.faultManagement.reOpenClosedFault.error.data
			);
		}
	}
	getUserSite() {
		this.generalLoading = true;
		this.appService.showFullAppLoader = false;
		const holderId = this.companyService.selectedCompanyId;
		this.customerAccounts
			.GetCustomerAccounts(holderId)
			.pipe(map((data: any) => data.items))
			.subscribe(
				(sites: CustomerAccount[]) => {
					if (sites && sites.length === 1) {
						this.fMService.selectedSiteId = sites[0].id;
						this.getServiceAvialityTickets(true);
					} else {
						const filterdSites = sites.filter((site) => {
							return (
								site.status.toLowerCase() === SiteStatus.Active.toLowerCase() ||
								site.status.toLowerCase() === SiteStatus.Activo.toLowerCase() ||
								site.status.toLowerCase() === SiteStatus.Pend_de_Cambio.toLowerCase()
							);
						});
						if (filterdSites && filterdSites.length > 1) {
							this.fMService.setUserSites(filterdSites);
							Mva10LandingPageComponent.someUserSites = true;
							this.showPageHeader = false;
							this.generalLoading = false;
							this.taggingHelper.view(tagging.bonitaSiteSelector);
						}
						this.fMService.selectedSiteId = filterdSites[0].id;
						this.getServiceAvialityTickets(true);
					}
				},
				(error) => {
					this.landingOverlayUiData = this.generalBonitaError;
					this.showGeneralError = true;
					this.fMService.showFullOverlay = true;
				}
			);
	}

	getUserName(): void {
		const user: string = this.utilitiesService.capitalizeText(this.storageService.userProfile.firstName) || null;

		this.translate.get('faultManagement.itemsList.listOfServices.body').subscribe(
			(data: string) => {
				user
					? (this.userName = data.replace('{0}', user + ','))
					: (this.userName = data.replace('{0}', '').split(' ')[0] + ', ' + data.substr(8));
			},
			(error) => {
				console.error(error);
			}
		);
	}

	selectSite(usersiteId: string): void {
		if (this.fMService.selectedSiteId === usersiteId) return;
		this.closedServices = [];
		this.fMService.selectedSiteId = usersiteId;
		this.showPageHeader = true;
		this.getServiceAvialityTickets(true);
	}
	openNewTicketBtnTagging() {
		this.taggingHelper.track(
			tagging.faultManagement.openNewTicketBtnClicked.eventName,
			tagging.faultManagement.openNewTicketBtnClicked.data
		);
	}
	setTicketsSpecificVariablesTagging() {
		this.fMService.specificAveriaVariables = {
			issue_type: '',
			issue_information: '',
		};
		if (this.tickets && this.tickets.length > 0) {
			this.tickets.forEach((ticket, i) => {
				this.fMService.specificAveriaVariables.issue_type +=
					ticket.ticketName + (i === this.tickets.length - 1 ? '' : ',');
				this.fMService.specificAveriaVariables.issue_information +=
					ticket.ticketId +
					':' +
					this.fMService.ticketStatus(ticket.status) +
					':' +
					ticket.ticketName +
					(i === this.tickets.length - 1 ? '' : ',');
			});
			this.fMService.faultManagementGetTicketsLoading = true;
		}
	}
	handleGoToTicket(): void {
		if (this.backBtnSubjectSub) {
			this.backBtnSubjectSub.unsubscribe();
		}
		this.handleOverrideBackBtn();
		this.showTickets = true;
	}

	openScheduledJobOverlayHandler(): void {
		this.backBtnSubjectSub.unsubscribe();
		const scheduledJobwcskey: string | ScheduledJobModal = this.translate.instant(
			`v10.faultManagement.contentList.scheduleJob.${this.fMService.serviceAvailability?.scheduledJobTicket.replace(
				/-/g,
				'_'
			)}.modal`
		);
		if ((scheduledJobwcskey as ScheduledJobModal).icon) {
			this.landingOverlayUiData.button1Text = (scheduledJobwcskey as ScheduledJobModal).button;
			this.landingOverlayUiData.button2Text = (scheduledJobwcskey as ScheduledJobModal).button2;
			this.landingOverlayUiData.description = (scheduledJobwcskey as ScheduledJobModal).text
				.replace(
					'{0}',
					new Date(this.fMService.serviceAvailability.scheduledJobStartDateTime).toLocaleDateString('en-GB')
				)
				.replace(
					'{1}',
					new Date(this.fMService.serviceAvailability.scheduledJobEndDateTime).toLocaleDateString('en-GB')
				)
				.replace('{2}', this.fMService.serviceAvailability.scheduledJobTicketText);
			this.scheduledJobValueDXL = (scheduledJobwcskey as ScheduledJobModal).valueDXL;
			this.landingOverlayUiData.icon = this.appService.getImgFullPath((scheduledJobwcskey as ScheduledJobModal).icon);
		} else {
			const defaultJobwcskey: ScheduledJobModal = this.translate.instant(
				`v10.faultManagement.contentList.scheduleJob.modal`
			);
			this.landingOverlayUiData.button1Text = defaultJobwcskey.button;
			this.landingOverlayUiData.description = defaultJobwcskey.text
				?.replace(
					'{0}',
					new Date(this.fMService.serviceAvailability.scheduledJobStartDateTime).toLocaleDateString('en-GB')
				)
				?.replace(
					'{1}',
					new Date(this.fMService.serviceAvailability.scheduledJobEndDateTime).toLocaleDateString('en-GB')
				)
				?.replace('{2}', this.fMService.serviceAvailability.scheduledJobTicketText);
			this.landingOverlayUiData.icon = this.appService.getImgFullPath(defaultJobwcskey.icon);
		}
		this.fMService.showFullOverlay = true;
	}

	openDetailedTobiMassiveOverlay(): void {
		this.translate
			.get('v10.faultManagement.contentList.dynamicLanding.general_breakdown_without_date.modalMasivas')
			.subscribe((data) => {
				this.landingOverlayUiData.button1Text = data.button;
				this.landingOverlayUiData.description =
					this.fMService.serviceAvailability.massiveTicketText ||
					this.fMService.serviceAvailability.massiveTobiTicketText;
				this.landingOverlayUiData.title = data.title.replace(
					'{0}',
					this.utilitiesService.capitalizeText(this.storageService.userProfile.firstName)
				);
				this.landingOverlayUiData.icon = this.appService.getImgFullPath(data.icon);
				this.fMService.showFullOverlay = true;
			});
	}

	closeDetailedTobiMassiveOverlay(): void {
		this.landingOverlayUiData = { title: '', description: '', icon: '', button1Text: '', button2Text: '' };
		this.fMService.showFullOverlay = false;
	}

	ngOnDestroy() {
		this.fMService.specificAveriaVariables = {
			issue_type: '',
			issue_information: '',
		};
		this.configurationService.configuration.chatEnabled = true;
		this.appService.openBubbleStopScroll = false;
		this.appService.closeNeedHelpBubble.next();
		if (this.backBtnSubjectSub) {
			this.backBtnSubjectSub.unsubscribe();
		}
	}
}
