import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from '../../../environments/environment';
import { StorageService } from '../../core/services/storage.service';
import { CONFIG, NIFPREFIX, adaraValues, getUserDeptHeader } from '../constants/defines';
import { API_URLS } from '../constants/routes-config';
import { PaymentJourneyIds } from '../enums/payment-journey-ids.enum';
import {
	AnonymousCustomerRequestBody,
	AnonymousOTPValiadtionRequestBody,
	AnonymousPaymentCustomer,
	ThirdPartyIframeRequestBodyHeader,
	ThirdPartyIframeUrlRequest,
	ThirdPartyPatchRequestBodyHeader,
} from '../models/payment';
import { TopupPatchModel } from '../models/top-up-patch.model';

@Injectable()
export class PaymentDataService {
	constructor(public http: HttpClient, private storageService: StorageService) {}
	/**
	 *
	 * @param data function payload contain request body and url parameter
	 * function to get the payment third party url
	 */
	requestNetplusIframeUrlForMva10Topup(
		data: ThirdPartyIframeRequestBodyHeader,
		isPayingEnergyDebt?: boolean
	): Observable<any> {
		const url: string = API_URLS.Bill.paymentProcessURL
			.replace('{customerAccountId}', data.urlSiteId)
			.replace('{subscriptionId}', data.urlServiceId);
		let headers: HttpHeaders = new HttpHeaders();
		if (data.isAnonymous) {
			headers = headers
				.append('Content-Type', 'application/json')
				.append('Authorization', 'Basic ' + btoa(CONFIG.CLIENT_ID));
		} else {
			headers = headers.append('Content-Type', 'application/json');
		}
		return this.http.post(
			!isPayingEnergyDebt ? url : this.setEnergyDebtRequestUrl(),
			!isPayingEnergyDebt ? data.requestBody : this.setEnergyDebtRequestBody(data),
			{ headers }
		);
	}

	/**
	 *
	 * @param data function payload contain request body and url parameter
	 * function to patch the payment iframe status
	 */
	updatePaymentStatus(data: ThirdPartyPatchRequestBodyHeader, isPayingEnergyDebt?: boolean): Observable<any> {
		const url: string = API_URLS.Bill.paymentProcessURL
			.replace('{customerAccountId}', data.urlSiteId)
			.replace('{subscriptionId}', data.urlServiceId);
		let headers: HttpHeaders = new HttpHeaders();
		if (data.isAnonymous) {
			headers = headers
				.append('Content-Type', 'application/json-patch+json')
				.append('Authorization', 'Basic ' + btoa(CONFIG.CLIENT_ID));
		} else {
			headers = headers.append('Content-Type', 'application/json-patch+json');
		}
		return this.http.patch<TopupPatchModel>(
			!isPayingEnergyDebt ? url : this.setEnergyDebtRequestUrl(),
			!isPayingEnergyDebt ? data.requestBody : this.setEnergyDebtRequestBody(data),
			{ headers }
		);
	}
	/**
	 *
	 * @param loginHint function payload contain MSISDN and nif or nie
	 */
	validateCustomer(loginHint: string): Observable<any> {
		const url: string = API_URLS.Login.OTP_AUTHORIZE;
		let headers: HttpHeaders = new HttpHeaders();
		headers = headers.append('Content-Type', 'application/json');
		const requestBody: AnonymousCustomerRequestBody = new AnonymousCustomerRequestBody();
		requestBody.login_hint = loginHint;
		requestBody.client_id = environment.anonymousPaymentClientId;
		return this.http.post(url, requestBody, { headers });
	}
	/**
	 *
	 * @param otpCode function payload contain msidn and otp
	 */
	validateOTP(otpCode: string): Observable<any> {
		const url: string = API_URLS.Login.OTP_TOKEN;
		let headers: HttpHeaders = new HttpHeaders();
		headers = headers.append('Content-Type', 'application/json').append('otpVersion', 'mivodafone');
		const requestBody: AnonymousOTPValiadtionRequestBody = new AnonymousOTPValiadtionRequestBody();
		requestBody.client_id = environment.anonymousPaymentClientId;
		requestBody.code = btoa(otpCode);
		return this.http.post(url, requestBody, { headers });
	}
	/**
	 * Returns funced devices linked to a msisdn
	 * @param customer AnonymousPaymentCustomer is the model
	 * containing the data about the user needs to pay
	 */
	public getUserDept(customer: AnonymousPaymentCustomer): Observable<any> {
		const url: string = API_URLS.CustomerDebt.GetUserDebt.replace(
			'{document}',
			this.storageService.userProfile?.document?.type + '-' + customer?.documentId
		).replace('{siteId}', customer?.customerMSIDN);
		let headers: HttpHeaders = new HttpHeaders();
		headers = headers.append('Content-Type', 'application/json');
		headers = headers.append(getUserDeptHeader.name, getUserDeptHeader.value);
		return this.http.get(url, { headers });
	}

	public getUserDeptNoOTP(customer: AnonymousPaymentCustomer): Observable<any> {
		const url: string = API_URLS.CustomerDebt.GetUserDebtNoOTP.replace('{id}', customer?.customerAccountNumber).replace(
			'{document}',
			NIFPREFIX + customer?.documentId
		);
		let headers: HttpHeaders = new HttpHeaders();
		headers = headers.append('Content-Type', 'application/json');
		headers = headers.append(getUserDeptHeader.name, getUserDeptHeader.value);
		headers = headers.append('Authorization', 'Basic ' + btoa(CONFIG.CLIENT_ID));

		return this.http.get(url, { headers });
	}

	setEnergyDebtRequestBody(data: ThirdPartyIframeRequestBodyHeader): ThirdPartyIframeUrlRequest {
		const energySiteId: string = this.storageService.userProfile.sites.find(
			(site) => site.marketType === adaraValues.CLIENT_ADARA
		)?.id;
		data.requestBody.payer.siteId = energySiteId;
		data.requestBody.payer.serviceId = energySiteId;
		data.requestBody.buyer.siteId = energySiteId;
		data.requestBody.buyer.serviceId = energySiteId;
		data.requestBody.journeyId = PaymentJourneyIds.energyDebt;
		return data.requestBody;
	}

	setEnergyDebtRequestUrl(): string {
		const energySiteId: string = this.storageService.userProfile.sites.find(
			(site) => site.marketType === adaraValues.CLIENT_ADARA
		)?.id;
		return API_URLS.Bill.paymentProcessURL
			.replace('{customerAccountId}', energySiteId)
			.replace('{subscriptionId}', energySiteId);
	}
}
