import { CommercialDetail } from './commercial-detail.model';
import { CommercialInsurance } from './commercial-insurance.model';
import { CommercialPrice } from './commercial-price.model';
import { NodeItemIdServiceModel } from './private-offer-list-cart.model';

export class CommercialService {
	public catalogElementType: string;
	public code: string;
	public id?: number;
	public itemId: string;
	public tax: number;
	public productHierarchyType?: string;
	public price?: CommercialPrice;
	public detail?: CommercialDetail;
	public services?: CommercialService[];
	public tipoCliente?: string;
	public insurance?: CommercialInsurance;
	public nodeItemId?: NodeItemIdServiceModel;
}
