import { Injectable } from '@angular/core';
import { CookieService } from 'ngx-cookie';
import { environment } from '../../environments/environment';
import { CookieHelper } from './../shared/utils/cookie-helper';

@Injectable()
export class TealiumUtagService {
	script_src = '';

	// Typically set 'noview' flag (no first page automatic view event) to true for Single Page Apps (SPAs)
	constructor(private cookieHelper: CookieHelper, private _cookieService: CookieService) {
		(<any>window).utag_cfg_ovrd = { noview: true };
		(<any>window).utag_data = {};
		this.setConfig({ environment: environment.taggingEnv });
	}

	// Generic script loader with callback
	getScript(src: string, callback: Function) {
		const d = document;
		const o = { callback: callback || function () {} };
		let s, t;

		if (typeof src === 'undefined') {
			return;
		}
		s = this.checkScriptExists();
		if (!s) {
			s = d.createElement('script');
			s.language = 'javascript';
			s.type = 'text/javascript';
			s.async = 1;
			s.charset = 'utf-8';
			s.src = src;
		}
		if (typeof o.callback === 'function') {
			if (d.addEventListener) {
				s.addEventListener(
					'load',
					function () {
						setTimeout(function () {
							o.callback();
						}, 500);
					},
					false
				);
			} else {
				// old IE support
				s.onreadystatechange = function () {
					if (this.readyState === 'complete' || this.readyState === 'loaded') {
						this.onreadystatechange = null;
						setTimeout(function () {
							o.callback();
						}, 500);
					}
				};
			}
		}
		t = d.getElementsByTagName('script')[0];
		t.parentNode.insertBefore(s, t);
	}
	checkScriptExists() {
		const scripts = document.getElementsByTagName('script');
		for (let i = 0; i < scripts.length; i++) {
			if (scripts[i].getAttribute('src') === this.script_src) {
				return scripts[i];
			}
		}
		return null;
	}
	// Config settings used to build the path to the utag.js file
	setConfig(config: { environment: string }) {
		if (config.environment !== undefined) {
			this.script_src = 'https://tags.tiqcdn.com/utag/vodafone/es-mivoweb/' + config.environment + '/utag.js';
		}
	}

	// Data layer is optional set of key/value pairs
	track(tealium_event: string, dataObj?: any) {
		// this function is overwriting the refrance for data which will be send to SMAPI
		// so we have to create new object
		const data = Object.create(dataObj);
		if (this.script_src === '') {
			return;
		}
		if ((<any>window).utag === undefined) {
			this.getScript(this.script_src, function () {
				if ((<any>window).sendLink) {
					(<any>window).sendLink(data);
				}
				(<any>window).utag.track(tealium_event, data);
				// const a = '' + location.hostname;
				// const b = a.split('.');
				// const c = (/\.co\.|\.com\.|\.org\.|\.edu\.|\.net\.|\.asn\.|\...\.jp$/.test(a)) ? 3 : 2;
				// const domain = b.splice(b.length - c, c).join('.');
				// document.cookie = 'utag_main=;expires=Thu, 01 Jan 1970 00:00:01 GMT;path=/;domain=.' + domain;
			});
		} else {
			if ((<any>window).sendLink) {
				(<any>window).sendLink(data);
			}
			(<any>window).utag.track(tealium_event, data);
		}
	}

	view(data?: any) {
		// this.track('view', data);
		if ((<any>window).utag === undefined) {
			this.getScript(this.script_src, function () {
				if ((<any>window).sendView) {
					(<any>window).sendView(data);
				}
			});
		} else {
			if ((<any>window).sendView) {
				(<any>window).sendView(data);
			}
		}
	}

	link(data?: any) {
		// this.track('link', data);
		if ((<any>window).sendLink) {
			(<any>window).sendLink(data);
		}
	}
}
