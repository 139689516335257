import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { BehaviorSubject, Observable, of } from 'rxjs';
import { map } from 'rxjs/operators';
import { tagging } from '../../../config/tagging-config';
import { NewTaggingHelperService } from '../../core/services/new-tagging.helper.service';
import { StorageService } from '../../core/services/storage.service';
import { SubscriptionService } from '../../core/services/subscription.service';
import { TaggingHelperService } from '../../core/services/tagging.helper.service';
import {
	BundleType,
	LOCAL_STORAGE_KEYS,
	ON19,
	newTaggingClicks,
	pay,
	serviceBalanceType,
	superOffers,
	tagJourneyStates,
} from '../constants/defines';
import { DelightBundleStatus } from '../enums/DelightBundleStatus.enum';
import { ServiceCases } from '../enums/ServiceCases.enum';
import { BundleStatus } from '../enums/bundleStatus.enum';
import { BundlesGroups } from '../enums/bundlesGroups';
import { BuyOrRemove } from '../enums/buyOrRemove.enum';
import { ClientTypology } from '../enums/clientTopology.enum';
import { ProductConfigurationID } from '../enums/product-configuration-id.enum';
import { ServiceType } from '../enums/serviceType.enum';
import { Dashboard } from '../models/dashboard.model';
import { Error } from '../models/error.model';
import { eventCategory } from '../models/new-tagging-Interaction.model';
import { NewTaggingJsonModel } from '../models/new-tagging-json-model';
import { PageTaggingModel } from '../models/new-tagging-page.model';
import { ArrayItemsServiceBalanceModel, ServiceBalanceModel } from '../models/service-balance.model';
import { EntrypointVariables, TaggingViewModel } from '../models/tagging.model';
import { Voucher } from '../models/tariff.model';
import { UtilitiesService } from '../utils/utilities.service';
import { ProductConfigurationService } from './product-configuration.service';
import { ProductService } from './product.service';
import { ServiceBalanceWs10Service } from './service-balance-ws10.service';

@Injectable()
export class DelightPromotionService {
	public delightBundleStatus: DelightBundleStatus;
	public delightBundleStatusSubject: BehaviorSubject<DelightBundleStatus>;
	public delightBundleStatus$: Observable<DelightBundleStatus>;
	public entrypointVariables: EntrypointVariables;

	private delightCode: string;
	private bundle: Dashboard;

	/** Delight */
	public delightEnabled: boolean = false;
	public delightBundleEnabled: boolean = false;
	/** Delight */

	/** Tagging */
	public category: string = this.tagging
		.getUserType(this.storageService.userProfile.customerType, ClientTypology)
		?.toLowerCase();
	public subcategory: string =
		this.subscriptionService.customerData?.SiteType === pay.postpaid_check ? pay.postpaid : pay.prepaid;
	public confirmation: boolean = false;
	/** Tagging */

	constructor(
		private productConfigurationService: ProductConfigurationService,
		private productService: ProductService,
		private serviceBalanceWS10: ServiceBalanceWs10Service,
		private subscription: SubscriptionService,
		private translate: TranslateService,
		private utilities: UtilitiesService,
		private tagging: TaggingHelperService,
		private newTagging: NewTaggingHelperService,
		public subscriptionService: SubscriptionService,
		private storageService: StorageService
	) {
		this.delightBundleStatusSubject = new BehaviorSubject(this.delightBundleStatus);
		this.delightBundleStatus$ = this.delightBundleStatusSubject.asObservable();
	}

	private setCurrentServiceType(isUnlimited?: boolean): ServiceCases {
		return this.isNotAllowedService()
			? ServiceCases.notAllowed
			: isUnlimited
			? ServiceCases.hasUnlimitedData
			: ServiceCases.hasLimitedData;
	}

	public setDelightBundleStatus(isUnlimited?: boolean): void {
		if (isUnlimited === undefined) {
			this.serviceBalanceWS10.getServiceBalance(BundleType.inPlan, true).subscribe(
				(result: ArrayItemsServiceBalanceModel) => {
					isUnlimited = this.hasDataUnlimited(result);
					this.checkDelightBundleStatus(isUnlimited);
				},
				(error) => {
					isUnlimited = false;
					this.checkDelightBundleStatus(isUnlimited);
				}
			);
		} else {
			this.checkDelightBundleStatus(isUnlimited);
		}
	}

	private checkDelightBundleStatus(isUnlimited: boolean): void {
		const currentServiceCase: ServiceCases = this.setCurrentServiceType(isUnlimited);
		if (currentServiceCase === ServiceCases.notAllowed) {
			this.delightBundleStatus = DelightBundleStatus.none;
			this.setDelightBundleEnabled();
		} else if (currentServiceCase === ServiceCases.hasUnlimitedData) {
			this.hasBundleActive().subscribe((res) => {
				this.delightBundleStatus = res ? DelightBundleStatus.bundleActive : DelightBundleStatus.none;
				this.setDelightBundleEnabled();
			});
		} else if (currentServiceCase === ServiceCases.hasLimitedData) {
			this.delightBundleStatus = this.hasBundlePending()
				? DelightBundleStatus.bundlePending
				: this.limitedPromoAvailable()
				? DelightBundleStatus.bundleInactive
				: DelightBundleStatus.none;
			this.setDelightBundleEnabled();
		}
		this.delightBundleStatusSubject.next(this.delightBundleStatus);
	}

	public hasDataUnlimited(arrayItemsServiceBalance: ArrayItemsServiceBalanceModel): boolean {
		const unlimitedDataBundle: ServiceBalanceModel =
			arrayItemsServiceBalance &&
			arrayItemsServiceBalance.items.length &&
			arrayItemsServiceBalance.items.find(
				(bundle) =>
					bundle.group.toLowerCase() === BundlesGroups.data.toLowerCase() && bundle.allowance.unlimited === true
			);
		return unlimitedDataBundle ? true : false;
	}

	public setDelightCode(customCode: string): void {
		this.delightCode = customCode;
	}

	public getDelightCode(): string {
		let code: string;
		this.translate.get('v10.delight.default.code').subscribe((defaultCode) => (code = defaultCode));
		return this.delightCode ? this.delightCode : code;
	}

	private hasBundleActive(): Observable<boolean> {
		const codeBondsUnlimited: string[] = this.translate.instant('v10.delight.default.code');
		return this.serviceBalanceWS10.getServiceBalance(BundleType.inPlan, false).pipe(
			map(
				(servicesBalance: ServiceBalanceModel) => {
					this.bundle = servicesBalance['items'].find((elem) => codeBondsUnlimited.includes(elem.code.split('_')[0]));
					this.delightCode = this.bundle ? this.bundle.code.split('_')[0] : '';
					return this.bundle ? true : false;
				},
				(error) => {
					return false;
				}
			)
		);
	}

	private hasBundlePending(): boolean {
		const requestValue = this.storageService.getLocalStorage(this.requestInOrderKey());
		if (requestValue) {
			this.delightCode = requestValue.code;
			const daysAgo: number = this.utilities.getDayDiffBetween2Dates(requestValue.date, new Date());
			const MAXWAITINGDAYS = 80;
			if (daysAgo < MAXWAITINGDAYS) {
				return true;
			} else {
				this.removeRequestIsInOrderOnStorage();
				return false;
			}
		} else {
			return false;
		}
	}

	private limitedPromoAvailable(): boolean {
		return this.productConfigurationService.shouldShowFeature(ProductConfigurationID.delightLimited);
	}

	private isNotAllowedService(): boolean {
		return (
			this.subscription.customerData.currentService.type.toLowerCase() !== ServiceType.Postpaid.toLowerCase() ||
			!ON19.on19.includes(this.subscription.superOffer)
		);
	}

	public purchaseBundle(productId?: string): Observable<any> {
		const msisdn = this.subscription.customerData.currentService.id;
		const siteId = this.subscription.customerData.customerAccountsId;
		const id = `${productId ? productId : this.getDelightCode()}_${msisdn}_${siteId}`;
		return this.productService.BuyOrRemoveBundle(id, BuyOrRemove.Active);
	}

	private requestInOrderKey(): string {
		return `${LOCAL_STORAGE_KEYS.SPECIALPROMOTION}_${this.subscription.customerData.currentService.id}`;
	}

	public saveRequestIsInOrderOnStorage() {
		const key = this.requestInOrderKey();
		this.storageService.setLocalStorage(key, new Date());
	}

	private removeRequestIsInOrderOnStorage() {
		const key = this.requestInOrderKey();
		this.storageService.removeFromLocalStorage(key);
	}

	public getExpirationDate(): string {
		return this.bundle['validityPeriod']['toDate'] ? this.bundle['validityPeriod']['toDate'].toString() : '';
	}

	public tagView(
		action: string,
		pageCase: string,
		journeyCase?: string,
		journeyType?: string,
		completeCase?: string,
		errorData?: Error
	) {
		const trackPage = this.getTrackPage(pageCase, completeCase, errorData);
		const trackJourney = this.getTrackJourney(false, action, journeyCase, journeyType, errorData);
		const entrypointdata = Object.assign(trackJourney, this.getEntrypointData());

		this.tagging.view(trackPage, entrypointdata);
	}

	public tagAction(
		action: string,
		ctaName: string,
		pageCase?: string,
		journeyCase?: string,
		journeyType?: string,
		completeCase?: string
	) {
		const trackPage: TaggingViewModel =
			pageCase === tagging.campaignPromotions.flipCard.iteraction.title
				? Object.assign({}, tagging.campaignPromotions.flipCard.data)
				: this.getTrackPage(pageCase, completeCase);
		const trackJourney = this.getTrackJourney(true, action, journeyCase, journeyType);
		const trackData = Object.assign(trackPage, trackJourney);
		const entrypointdata = Object.assign(trackData, this.getEntrypointData());
		const eventdata = Object.assign(entrypointdata, tagging.campaignPromotions.events);
		eventdata.event_name = tagging.campaignPromotions.cta_names.generic.replace('{text}', ctaName);

		this.tagging.track(ctaName, eventdata);
	}

	private getTrackPage(pageCase: string, completeCase = '', errorData?: Error) {
		const trackPage = {};
		if (pageCase) {
			const taggingObject = tagging.campaignPromotions[pageCase];
			Object.keys(taggingObject).map(
				(key) =>
					(trackPage[key] = taggingObject[key]
						.replace('<code>', this.getDelightCode().toLowerCase())
						.replace('<completeCase>', completeCase))
			);
			if (errorData) {
				trackPage['error_category'] = errorData.title;
				trackPage['error_description'] = errorData.description;
				trackPage['error_type'] = errorData.errorCode;
			}
		}
		return trackPage;
	}

	private getTrackJourney(
		event: boolean,
		action: string,
		journeyCase?: string,
		journeyType?: string,
		errorData?: Error
	) {
		const trackJourney = {};
		if (journeyCase) {
			const taggingObject = tagging.campaignPromotions[journeyCase];
			if (event) {
				delete taggingObject.journey_name;
			}
			Object.keys(taggingObject).map(
				(key) =>
					(trackJourney[key] = taggingObject[key]
						.replace('<action>', action)
						.replace('<type>', journeyType)
						.replace('<code>', this.getDelightCode().toLowerCase()))
			);
			if (errorData) {
				trackJourney['journey_error_category'] = errorData.title;
				trackJourney['journey_error_description'] = errorData.description;
				trackJourney['journey_error_type'] = errorData.errorCode;
			}
		}
		return trackJourney;
	}

	private getEntrypointData() {
		let entrypointdata: any;
		if (this.entrypointVariables) {
			entrypointdata = tagging.campaignPromotions.entrypointdata;
			entrypointdata.entrypoint_section = this.entrypointVariables.section;
			entrypointdata.entrypoint_location = this.entrypointVariables.location;
			entrypointdata.entrypoint_position = this.entrypointVariables.position;
			entrypointdata.entrypoint_title = this.entrypointVariables.title;
			entrypointdata.journey_name = this.entrypointVariables.journeyname;
			entrypointdata.experience_name = this.entrypointVariables.experiencename;
			entrypointdata['&&events'] = this.entrypointVariables.events;
		}
		return entrypointdata || {};
	}

	public isON19(): boolean {
		return superOffers.includes(this.subscription.superOffer);
	}

	/**
	 * checkDelight if (delightEnabled) && (sb-inPlan-Group loaded) && (A || B)
	 * A. !hasDataUnlimited
	 * B. hasDataUnlimited && (sb-inPlan loaded)
	 */
	checkDelight(isUnlimited: boolean): void {
		const contentRequest: Observable<any> =
			isUnlimited && !this.serviceBalanceWS10.bundlesInPlan[this.subscription.customerData.currentService.id]
				? this.serviceBalanceWS10.getServiceBalance(serviceBalanceType.inPlan, false)
				: of({});
		contentRequest.subscribe(() => {
			this.setDelightBundleStatus(isUnlimited);
		});
	}

	setDelightBundleEnabled(): void {
		this.delightBundleEnabled =
			this.delightBundleStatus === DelightBundleStatus.bundleInactive ||
			this.delightBundleStatus === DelightBundleStatus.bundlePending ||
			this.delightBundleStatus === DelightBundleStatus.bundleActive;
	}

	public tagLandingView(delightCode: string, selectedVoucher?: Voucher): void {
		this.newTagging.getJsonTagging('delight/delight').subscribe((data: NewTaggingJsonModel) => {
			data.page.page_detail = data.page.page_detail.replace('$0', delightCode.toLowerCase());
			data.page.page_subcategory_level[0] = data.page.page_subcategory_level[0].replace(
				'$0',
				delightCode.toLowerCase()
			);
			data.page.journey.journey_category = this.category;
			data.page.journey.journey_subcategory = this.subcategory;
			data.page.journey.journey_name = data.page.journey.journey_name.replace('$0', delightCode.toLowerCase());
			data.page.journey.journey_detail = data.page.journey.journey_detail.replace('$0', delightCode.toLowerCase());
			if (selectedVoucher) {
				data.page.journey.journey_process = this.getJourneyState(selectedVoucher.status.toLowerCase());
			}
			this.newTagging.state(data.page);
		});
	}

	public tagSuccess(delightCode: string, selectedVoucher: Voucher): void {
		this.newTagging.getJsonTagging('delight/delight').subscribe((data: NewTaggingJsonModel) => {
			data.page.stateList['state_success'].page_subcategory_level[0] = data.page.stateList[
				'state_success'
			].page_subcategory_level[0].replace('$0', delightCode.toLowerCase());
			data.page.stateList['state_success'].journey.journey_category = this.category;
			data.page.stateList['state_success'].journey.journey_subcategory = this.subcategory;
			data.page.stateList['state_success'].journey.journey_name = data.page.stateList[
				'state_success'
			].journey.journey_name.replace('$0', delightCode.toLowerCase());
			data.page.stateList['state_success'].journey.journey_detail = data.page.stateList[
				'state_success'
			].journey.journey_detail.replace('$0', delightCode.toLowerCase());
			if (selectedVoucher) {
				data.page.stateList['state_success'].journey.journey_process = this.getJourneyState(
					selectedVoucher.status.toLowerCase()
				);
			}
			this.newTagging.state(data.page.stateList['state_success']);
		});
	}

	public tagClick(
		delightCode: string,
		button: string,
		clickControl: string,
		selectedVoucher?: Voucher,
		type?: boolean
	): void {
		this.newTagging.getJsonTagging('delight/delight').subscribe((data: NewTaggingJsonModel) => {
			let page: PageTaggingModel;
			page = this.confirmation ? data.page.stateList['state_success'] : data.page;
			page.page_detail = page.page_detail.replace('$0', delightCode.toLowerCase());
			page.page_subcategory_level[0] = page.page_subcategory_level[0].replace('$0', delightCode.toLowerCase());
			if (selectedVoucher) {
				data.eventList[clickControl].journey.journey_process = this.getJourneyState(
					selectedVoucher.status.toLowerCase()
				);
			}
			data.eventList[clickControl].event.event_context =
				button === newTaggingClicks.share ? newTaggingClicks.share : delightCode.toLowerCase();
			data.eventList[clickControl].event.event_label = data.eventList[clickControl].event.event_label.replace(
				'$0',
				button
			);
			data.eventList[clickControl].event.event_category = type ? eventCategory.cross : eventCategory.boton;
			data.eventList[clickControl].journey.journey_name = data.eventList[clickControl].journey.journey_name.replace(
				'$0',
				delightCode.toLowerCase()
			);
			data.eventList[clickControl].journey.journey_category = this.category;
			data.eventList[clickControl].journey.journey_subcategory = this.subcategory;
			data.eventList[clickControl].journey.journey_name = data.eventList[clickControl].journey.journey_name.replace(
				'$0',
				delightCode.toLowerCase()
			);
			data.eventList[clickControl].journey.journey_detail = data.eventList[clickControl].journey.journey_detail.replace(
				'$0',
				delightCode.toLowerCase()
			);
			this.newTagging.interaction(data.eventList[clickControl], page);
		});
	}

	public getJourneyState(voucherStatus: string): string {
		let status: string = '';
		switch (voucherStatus) {
			case BundleStatus.Inactive.toLowerCase():
				status = tagJourneyStates.inactive.toLowerCase();
				break;
			case BundleStatus.PendingActivation.toLowerCase():
				status = tagJourneyStates.pending.toLowerCase();
				break;
			case BundleStatus.Active.toLowerCase():
				status = tagJourneyStates.active.toLowerCase();
				break;
			case BundleStatus.Finished.toLowerCase():
				status = tagJourneyStates.endOfPromo.toLowerCase();
				break;
			case BundleStatus.Success.toLowerCase():
				status = tagJourneyStates.success.toLowerCase();
				break;
		}
		return status;
	}
}
