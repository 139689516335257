import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { config } from '../../../../../../config/pages-config';
import { CommonService } from '../../../../../core/services/common.service';
import { LocationService } from '../../../../../core/services/location.service';
import { SubscriptionService } from '../../../../../core/services/subscription.service';
import { assessorRateusParams } from '../../../../constants/defines';

@Component({
	selector: 'sp-satisfaction-survey',
	templateUrl: './satisfaction-survey.component.html',
	styleUrls: ['./satisfaction-survey.component.scss'],
})
export class SatisfactionSurveyComponent implements OnInit {
	@Output() xButtonClicked = new EventEmitter();
	SURVEYURL;
	headerText: string;
	whiteOverlayDisplay = false;
	bubbleHelpContentDisplay = true;
	lottieConfig: Object;
	startY: number = 0;
	bubbleAnimation = false;
	lottieDispalyNone = false;
	enlEncuesta;
	anim: any;
	showBubbleOverlay: boolean = false;
	constructor(
		private translate: TranslateService,
		private router: Router,
		private subscription: SubscriptionService,
		public commonService: CommonService,
		private locationService: LocationService
	) {}

	ngOnInit() {
		this.SURVEYURL = this.urlEncuesta();
		this.setHeaderText();
	}

	setHeaderText() {
		let result = '';
		this.translate.get('common').subscribe((data) => {
			result = data.itemsList.bubbleTab2.body;
		});
		this.headerText = result;
	}

	myAccountBackButton() {
		if (!document.referrer || document.referrer === '') {
			this.router.navigate([config.dashboard.route]);
		} else {
			this.locationService.back();
		}
	}
	urlEncuesta() {
		const actId = this.subscription.customerData.currentService.siteId;
		const actData = new Date();
		const IsoData = actData.toISOString();
		let txtEncuesta;
		this.translate.get('v10').subscribe((v10) => {
			txtEncuesta = v10.dashboard.overlay_assessor.rateus_lnk;
		});
		const clientId = assessorRateusParams.clientId;
		const timed = assessorRateusParams.timed;
		txtEncuesta = txtEncuesta.replace(clientId, 'clientId=' + actId).replace(timed, 'timemed=' + IsoData);

		return txtEncuesta;
	}
}
