<vfac-overlay-full-screen
	[overlayFullScreenData]="overlayFullScreenData"
	[show]="show"
	(primaryBtnClicked)="clickPrimaryBtnExitTxOverlay()"
	(secundaryBtnClicked)="clickSecundaryBtnExitTxOverlay()"
	(closeBtnClicked)="clickCloseOverlayFullScreen()"
>
	<img
		id="sharedExitOverlayFullScreenComponentTobiImage"
		class="overlay-full-screen--img"
		[src]="'v10.common.closeOverlay.tobi.image' | imgFullPath"
		alt="img"
	/>
</vfac-overlay-full-screen>
<sp-bubble-help-content (xButtonClicked)="hideContent()" [url]="''" *ngIf="openContentTobi"> </sp-bubble-help-content>
