import { JourneyTaggingModel } from './journey-tagging.model';
import { NewTaggingJsonModel } from './new-tagging-json-model';

export class InteractionTaggingModel {
	site_section?: string;
	page_subcategory_level?: string;
	page_detail?: string;
	page_typology?: string;
	journey?: JourneyTaggingModel;
	event: EventModel;
	custom_map?: Record<string, string>;
}

export class JsonFilesModel {
	fileName: string;
	jsonDataObject: NewTaggingJsonModel;
}

export class EventModel {
	event_category: eventCategory;
	event_context: string;
	event_label: string;
	event_name?: string;
}
export enum eventCategory {
	ctc = 'ctc',
	link = 'link',
	boton = 'boton',
	cross = 'aspa',
	selector = 'selector',
	nudge = 'nudge',
	acordeon = 'acordeon',
	visualizacion = 'visualizacion',
	carrusel = 'carrusel',
	evento = 'evento',
	errorT = 'error tecnico',
	errorF = 'error funcional'
}
