<div class="selector-body" *ngIf="loading || (billingService.fundedDevices && billingService.fundedDevices.length > 0)">
	<p class="site-seletor-desc" id="financedDevicesTitle">
		{{ 'v10.payment.itemsList.financedDevices.title' | translate }}
	</p>
	<div *ngIf="!loading">
		<div class="financed-devices-filter">
			<mva10-c-filter
				#filterItem
				*ngFor="let filter of financedSelectBadgeData"
				[idInput]="filter.id"
				[nameInput]="filter.text"
				[value]="filter.id"
				[iconVisible]="false"
				[selected]="filter.checked"
				[size]="2"
				[type]="'text'"
				[appearance]="'primary'"
				(mva10Change)="onTabClick(filter.id, filterItem)"
			>
				{{ filter.text }}
			</mva10-c-filter>
		</div>
		<div class="financed-devices-container" *ngIf="shownFinancedDevices.length > 0; else emptyDevices">
			<mva10-c-card *ngFor="let device of shownFinancedDevices; let index = index">
				<div class="site-container" (click)="navigateToFundedDevicesDetails(device)">
					<div class="site-container__left_block">
						<div class="site-container__elment_left">
							<svg-icon
								id="financedDevicesDeviceIcon"
								class="svg-icon"
								[src]="appService.getImgFullPath('https://assets-es-pprd.dxlpreprod.local.vodafone.es/assets/icons-svg/mobile.svg')"
							>
							</svg-icon>
						</div>
						<div class="site-container__elment_mid">
							<p id="financedDevicesDeviceModel" class="site-container__address">
								{{ device.model ? device.model : ('v10.productsServices.fundedDevices.model.default' | translate) }}
							</p>
							<p id="financedDevicesDeviceStatus" class="site-container__id">
								<span
									id="financedDevicesDevicePendingStatus"
									*ngIf="device.paymentStatus === financedPaymentStatus.Ready"
								>
									{{ 'v10.payment.itemsList.financedDevices.remainToPaid' | translate }}
								</span>
								<ng-container
									*ngIf="
										device.isCompletedRegularFunded && device.hasFinalPayment;
										then deferredFunded;
										else regularFunded
									"
								>
								</ng-container>
								<ng-template #regularFunded>
									<span
										id="financedDevicesDevicePendingAmountRegular"
										*ngIf="device.paymentStatus === financedPaymentStatus.Ready"
										class="site-container__amount"
									>
										{{ device.totalPendingAm | currency : 'EUR' : 'symbol' | sliceCurrencyRoundZero }}{{ coin }}
									</span>
								</ng-template>
								<ng-template #deferredFunded>
									<span
										id="financedDevicesDevicePendingAmountDeferred"
										*ngIf="device.paymentStatus === financedPaymentStatus.Ready"
										class="site-container__amount"
									>
										{{ device.totalPendingAm | currency : 'EUR' : 'symbol' | sliceCurrencyRoundZero }}{{ coin }}
									</span>
								</ng-template>
							</p>
						</div>
					</div>
					<div class="site-container__elment_right">
						<svg-icon
							id="financedDevicesDeviceChevronIcon"
							class="svg-icon"
							[src]="appService.getImgFullPath('https://assets-es-pprd.dxlpreprod.local.vodafone.es/assets/icons-svg/ic-chevron-right-red.svg')"
						>
						</svg-icon>
					</div>
				</div>
			</mva10-c-card>
		</div>
		<ng-template #emptyDevices>
			<mva10-c-card class="empty-list">
				<img
					id="financedDevicesEmptyIcon"
					alt="no financed devices"
					[src]="appService.getImgFullPath('v10.payment.itemsList.financedDevices.bundelsIcon' | translate)"
				/>
				<p id="financedDevicesEmptyCompleted" *ngIf="selectedTab === finanedDevicesTabs.completed">
					{{ 'v10.payment.itemsList.financedDevices.notCompletedFincacingTxt' | translate }}
				</p>
				<p id="financedDevicesEmptyInProgress" *ngIf="selectedTab === finanedDevicesTabs.inProgress">
					{{ 'v10.payment.itemsList.financedDevices.noInProgress' | translate }}
				</p>
			</mva10-c-card>
		</ng-template>
	</div>
	<div *ngIf="loading" ngClass="loading-section">
		<div class="skeleton"></div>
		<div class="skeleton"></div>
	</div>
</div>
