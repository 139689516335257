import { Component, EventEmitter, Input, OnDestroy, Output, SimpleChanges } from '@angular/core';
import { Router } from '@angular/router';
import { OverlayFullScreenModel } from '@mva10/mva10-angular';
import { TranslateService } from '@ngx-translate/core';
import { config } from '../../../../config/pages-config';
import { tagging } from '../../../../config/tagging-config';
import { AppService } from '../../../app.service';
import { CommonService } from '../../../core/services/common.service';
import { StorageService } from '../../../core/services/storage.service';
import { SubscriptionService } from '../../../core/services/subscription.service';
import { TaggingHelperService } from '../../../core/services/tagging.helper.service';
import * as constants from '../../constants/defines';
import { ClientTypology } from '../../enums/clientTopology.enum';
import { NeedBubbleHelpTabs } from '../../enums/need-bubble-help-tabs.enum';
import { UserSettingToken } from '../../enums/user-setting-token.enum';
import { TaggingViewModel } from '../../models/tagging.model';
import { CommercialConfirmationClose } from '../../services/commercial-confirmation-close.service';
import { UserSettingsService } from '../../services/user-settings.service';
import { BubbleHelpService } from '../bubble-help/bubble-help.service';

@Component({
	selector: 'sp-exit-overlay-full-screen',
	templateUrl: './exit-overlay-full-screen.component.html',
	styleUrls: ['./exit-overlay-full-screen.component.scss'],
})
export class ExitOverlayFullScreenComponent implements OnDestroy {
	@Input() show: boolean;
	@Input() journeyName: string = constants.undefinedType;
	@Output() closeExitModal: EventEmitter<boolean> = new EventEmitter<boolean>();

	public overlayFullScreenData: OverlayFullScreenModel;
	public openContentTobi: boolean = false;
	private journeyCategory: string;
	private journeySubcategory: string;

	private customLink: string;
	private contextData: TaggingViewModel;

	constructor(
		private router: Router,
		private appService: AppService,
		public commonService: CommonService,
		private commercialConfirmationClose: CommercialConfirmationClose,
		public bubbleHelpService: BubbleHelpService,
		private translateService: TranslateService,
		private userSettingsService: UserSettingsService,
		private subscriptionService: SubscriptionService,
		private taggingHelperService: TaggingHelperService,
		private readonly storageService: StorageService
	) {
		this.journeyCategory = this.taggingHelperService.getUserType(
			this.storageService.userProfile.customerType,
			ClientTypology
		);
		this.journeySubcategory = this.subscriptionService.customerData.currentService.name;
	}

	public ngOnInit(): void {
		this.appService.helpBubbleSelectedTab = NeedBubbleHelpTabs.chat;
		this.commonService.showChatInBubbleHelp = true;
		this.generateDataTobi();
	}
	public clickPrimaryBtnExitTxOverlay(): void {
		this.openContentTobi = true;
		this.bubbleHelpService.exitJourney = this.translateService.instant('v10.common.closeOverlay.tobi.context');
		this.setClickTagging(true);
	}
	public clickSecundaryBtnExitTxOverlay(): void {
		this.setClickTagging();
		this.router.navigate([config.TiendaV10.route]);
		this.openContentTobi = false;
	}
	public clickCloseOverlayFullScreen(): void {
		if (!this.openContentTobi) {
			this.show = false;
			this.closeExitModal.emit(false);
			this.commercialConfirmationClose.closeConfirmation(false);
		}
	}
	public generateDataTobi(): void {
		this.overlayFullScreenData = new OverlayFullScreenModel();
		this.translateService.get('v10.common.closeOverlay.tobi').subscribe((data) => {
			this.overlayFullScreenData.titleOverlay = data.title;
			this.overlayFullScreenData.textOverlay = data.subtitle;
			this.overlayFullScreenData.primaryBtnText = data.btnContinue;
			this.overlayFullScreenData.secundaryBtnText = data.btnExit;
			this.overlayFullScreenData.closeBtn = true;
			this.overlayFullScreenData.largeButtons = true;
			this.overlayFullScreenData.horizontalBtn = true;
			this.overlayFullScreenData.stylesSecundaryBtn = constants.tertiaryStyle;
			this.overlayFullScreenData.stylesPrimaryBtn = constants.vfesButtonPrimary;
		});
	}
	public hideContent(): void {
		this.openContentTobi = false;
		this.refreshTokens();
		this.clickCloseOverlayFullScreen();
	}
	private refreshTokens(): void {
		this.userSettingsService
			.getUserSettingsToken(
				UserSettingToken.DxlAuth,
				this.subscriptionService.customerData?.customerAccountsId,
				this.subscriptionService.customerData?.currentService?.id,
				false
			)
			.subscribe();
	}

	private setViewTagging(): void {
		// set data config
		const data: TaggingViewModel = { ...tagging.exitOverlay.data };
		data.page_subcategory_level_2 = this.journeyName;
		data.navigation_level_3 = data.page_subcategory_level_2;
		data.page_name = `${data.navigation_level_1}:${data.navigation_level_2}:${data.navigation_level_3}:${data.navigation_level_4}`;
		// set journey config
		const journey: TaggingViewModel = { ...tagging.exitOverlay.journey };
		journey.journey_name = this.journeyName;
		journey.journey_step = journey.journey_step.replace('{0}', journey.journey_name);
		journey.journey_category = this.journeyCategory;
		journey.journey_subcategory = this.journeySubcategory;
		// set variables trackView
		this.customLink = data.page_name;
		this.contextData = { ...data, ...journey };
		this.taggingHelperService.view(this.customLink, this.contextData);
	}
	private setClickTagging(_isPrimary: boolean = false): void {
		// set events config
		let events: TaggingViewModel = { ...tagging.exitOverlay.event };
		let auxEvents: TaggingViewModel = _isPrimary
			? { ...tagging.exitOverlay.eventPrimary }
			: { ...tagging.exitOverlay.eventSecundary };
		events = { ...events, ...auxEvents };
		const eventLabel: string = _isPrimary
			? this.overlayFullScreenData.primaryBtnText
			: this.overlayFullScreenData.secundaryBtnText;
		events.event_label = events.event_label.replace('{0}', eventLabel?.toLocaleLowerCase());
		// set variables trackView
		this.customLink = `${this.customLink}:${events.event_name}`;
		this.contextData = { ...this.contextData, ...events };
		this.taggingHelperService.track(this.customLink, this.contextData);
	}
	ngOnChanges(changes: SimpleChanges): void {
		if (changes['show']) {
			this.show = changes['show'].currentValue;
			if (this.show) {
				this.setViewTagging();
			}
		}
	}
	ngOnDestroy(): void {
		this.bubbleHelpService.exitJourney = null;
	}
}
