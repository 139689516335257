export enum ProductConfigurationID {
	mercury = 'mercury',
	onlineTvParentalPin = 'online-tv-parental-pin',
	delightConf = 'delight-conf',
	delightUnlimited = 'delight-unlimited',
	delightLimited = 'delight-limited',
	tvContractDeco = 'tv-contract-deco',
	tvContractGen3 = 'tv-contract-gen3',
	tvMigrationGen3 = 'tv-migration-gen3',
	tvContractNoDeco = 'tv-contract-no-deco',
	tvChangeToDeco = 'tv-change-to-deco',
	tvChangeToNoDeco = 'tv-change-to-no-deco',
	showFinalPayment = 'show-final-payment',
	showInternetSpeed = 'show-internet-speed',
	modifyFinancing = 'modify-financing',
	deferFinalPayment = 'defer-final-payment',
	delightTvConf = 'delight-tv-conf',
	delightTvSurvey = 'delight-tv-survey',
	delightTvPys = 'delight-tv-pys',
	mtp2x1 = 'mtp-2x1',
	socialDiscount = 'social-discount-conf',
	billingTariffUpdate = 'billing-tariff-update',
	billingTariffUpdatePrinc = 'billing-tariff-update-principal',
	tryVoucher = 'try-conf',
	showInsurance = 'terminal-detail-insurance',
	deliveryConfirmation = 'delivery-confirmation',
	newShipping = 'new-logistic-shipping',
	entertainmentNetflixCell = 'entertainment-netflix-cell',
}
