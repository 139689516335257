import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { BehaviorSubject, Observable, throwError } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { MainCharacteristics } from 'src/app/shared/models/commercial-terminal-list.model';
import { OneProfNexus } from 'src/app/shared/models/oneProfesionalData.model';
import { tagging } from '../../../config/tagging-config';
import { NewTaggingHelperService } from '../../core/services/new-tagging.helper.service';
import { StorageService } from '../../core/services/storage.service';
import { SubscriptionService } from '../../core/services/subscription.service';
import { TaggingHelperService } from '../../core/services/tagging.helper.service';
import { ClientTypology } from '../../shared/enums/clientTopology.enum';
import {
	CommercialJourneys,
	LOCAL_STORAGE_KEYS,
	commercialMobile,
	errTagging,
	pay,
	secondResidences,
	stringOfTrue,
} from '../constants/defines';
import { API_URLS } from '../constants/routes-config';
import { AdditionalBoxModel } from '../models/additionalBox.model';
import { HandsetsBox, OpServices, PromotionsBox } from '../models/boxes.model';
import { IOfferViewModel } from '../models/commercial-offer.model';
import { CommercialService } from '../models/commercial-service.model';
import { CommercialShoppingCart } from '../models/commercial-shopping-cart.model';
import { EncryptedLines } from '../models/encrypted-lines.model';
import { DeleteInsuranceModel } from '../models/insurance.model';
import { NewTaggingJsonModel } from '../models/new-tagging-json-model';
import { PrivateOfferListCartModel } from '../models/private-offer-list-cart.model';
import { ProductsNexusModel } from '../models/products-nexus.model';
import { CommercialMobileDetailService } from './commercial-mobile-detail.service';
import { CommercialMobileService } from './commercial-mobile.service';
import { EncryptService } from './encrypt.service';
import { MicroFlowsService } from './micro-flows.service';

@Injectable()
export class NexusService {
	public products: ProductsNexusModel[] = [];
	private productsSubject: BehaviorSubject<any> = new BehaviorSubject(this.products);
	public products$: Observable<any> = this.productsSubject.asObservable();

	public origin: string = '';
	public noProducts: boolean;
	promotedCanon: any;
	canon: any;
	commercialshoppingCartItems: CommercialShoppingCart;
	public offerId: any;
	public packageId: number;
	public clientType: string;
	public registerType: any;
	public precioConKeeps: number;
	public offert: any;
	public precioSinKeep: number;
	public isME: boolean;
	public taxType: string;
	public encryptMsisdn: string;
	public analiticsEncryptMsisdn: string;
	public productTagg: string = '';
	public msisdnEncryted: EncryptedLines[] = new Array<EncryptedLines>();
	public additionalProducts: boolean = false;
	public show: boolean = false;
	public returnFromNexus: boolean = false;
	public shopType: string;
	public systemReferenceID: string;
	public productsInstallation: string;
	public oneProfProductSelected: AdditionalBoxModel;
	public customerAccountsId: string;
	public offerViewModel: IOfferViewModel;
	public oneProfOver: boolean = false;
	public showSpinner: boolean = false;
	public privateOfferList: Array<PrivateOfferListCartModel> = null;

	public itemIndex: number;

	private category: string = this.tagging
		.getUserType(this.storageService.userProfile.customerType, ClientTypology)
		.toLowerCase();
	private subcategory: string =
		this.subscriptionService.customerData.SiteType === pay.postpaid_check ? pay.postpaid : pay.prepaid;

	constructor(
		public http: HttpClient,
		private storageService: StorageService,
		private translate: TranslateService,
		public tagging: TaggingHelperService,
		private microFlowService: MicroFlowsService,
		public detailService: CommercialMobileDetailService,
		private newTagging: NewTaggingHelperService,
		private commercialMobile: CommercialMobileService,
		private encryptService: EncryptService,
		private subscriptionService: SubscriptionService
	) {}

	public getProducts(): Observable<any> {
		let url: string = API_URLS.ProductsCommercial;
		url = url.replace('{version}', this.translate.instant('v10.commercial.fullVersionPortalEP'));
		const headers: HttpHeaders = new HttpHeaders();
		const options: any = {
			headers: headers,
			NTOL_TXID: this.microFlowService.cartId,
			auth_ticket: this.storageService.getLocalStorage(LOCAL_STORAGE_KEYS.JWT),
			withCredentials: true,
		};
		this.microFlowService.showArrowBack = true;
		return this.http.get(url, options).pipe(
			map((res: any) => {
				return this.productsLogic(res);
			}),
			catchError((err) => {
				return throwError(err);
			})
		);
	}

	private productsLogic(products: any): Array<ProductsNexusModel> {
		this.products = new Array<ProductsNexusModel>();
		const productsServices: any = products.products || null;
		if (productsServices) {
			productsServices.map((prod) => {
				const product: ProductsNexusModel = new ProductsNexusModel();
				product.name = prod.name || null;
				product.code = prod.code || null;
				product.nmOrden = prod.nmOrden || null;
				product.category = prod.category || null;
				product.productId = prod.productId || null;
				product.serviceInstanceList = prod.serviceInstanceList || null;
				this.products.push(product);
			});
			return this.products;
		}
	}

	public deleteProducts(itemId: string): Observable<any> {
		const url: string = API_URLS.DeleteProduct;
		const headers: HttpHeaders = new HttpHeaders();
		const options: any = {
			headers: headers,
			auth_ticket: this.storageService.getLocalStorage(LOCAL_STORAGE_KEYS.JWT),
			NTOL_TXID: this.microFlowService.cartId,
			withCredentials: true,
		};
		const params: any = { itemid: itemId };

		return this.http.post(url, params, options).pipe(
			map((res: any) => {
				return res;
			}),
			catchError((err) => {
				return throwError(err);
			})
		);
	}

	public errTagging(error: any, subcategory2?: string): void {
		const pageError: any = Object.assign({}, tagging.nexusCommercial.error.page);
		const dataError: any = Object.assign({}, tagging.nexusCommercial.error.data);
		subcategory2 ? (pageError.page_subcategory_level_2 = subcategory2) : (pageError.page_subcategory_level_2 = '');
		let code: number;
		if (error.error && error.error.ecode) {
			code = error.error.ecode;
		} else {
			code = error.status;
		}
		if (error.url && error.url.indexOf(errTagging.tienda) > -1) {
			dataError.error_category = `${errTagging.tienda}_${error.status}`;
		} else {
			dataError.error_category = `${errTagging.dxl}_${error.status}`;
		}
		dataError.error_description = error.error.message;
		dataError.error_code = code;
		this.tagging.view(pageError, dataError);
	}
	public encryptnewMsisdn(msisdn?: string): Observable<string[]> {
		if (msisdn) {
			return this.encryptService.encrypt([msisdn]).pipe(
				map((res: string[]) => {
					if (res.length > 0) {
						return res;
					}
				})
			);
		}
	}

	public configProduct(cart: CommercialShoppingCart, selectOffer?: string, allProduct?: boolean): void {
		let associatedLine: string = '';
		cart.cartItems.forEach((service) => {
			service.services.forEach((serv) => {
				if (serv.detail.associatedLine) {
					associatedLine = this.msisdnEncryted.find(
						(line) => line.msisdn === serv.detail.associatedLine
					).encryptedMsisdn;
				}
			});
			service.services.forEach((serv) => {
				switch (serv.catalogElementType.toLocaleLowerCase()) {
					case commercialMobile.device:
						if (serv.detail.serviceItemTypeName.toLocaleLowerCase() === commercialMobile.mobile) {
							this.addProductToProductTagg(serv, associatedLine, selectOffer);
						} else if (allProduct) {
							this.addProductToProductTagg(serv, associatedLine, selectOffer);
						}
						break;
					case commercialMobile.package:
						if (
							(serv.detail.serviceItemType === 0 &&
								serv.detail.serviceItemTypeName.toLocaleLowerCase() === commercialMobile.mobilepackage &&
								(serv.detail.registerType === CommercialJourneys.registerTypeNew ||
									serv.detail.registerType === CommercialJourneys.registerTypePortability)) ||
							this.checkFibreType(serv)
						) {
							this.addProductToProductTagg(serv, null, selectOffer);
						}
						break;
				}
			});
		});
	}

	private viewTypePackage(products: string, service: CommercialService, selectOffer: string): string {
		let _products: string;
		if (service.detail.serviceItemTypeName.toLocaleLowerCase() === commercialMobile.mobilepackage) {
			_products = this.addProductToProductTaggPackage(products, service, selectOffer);
		} else if (this.checkFibreTypeNexusService(service)) {
			_products = this.addProductToProductTaggPackageFiber(products, service, selectOffer);
		}
		if (
			service.services?.some(
				(service: CommercialService) => service.detail.serviceItemType === CommercialJourneys.OneProfessionalService
			)
		) {
			_products = this.addOneProProductTag(products);
		}

		return _products;
	}
	public addProductToProductTagg(
		service: CommercialService,
		associatedLine?: string,
		selectOffer?: string,
		isPegaDeviceOfferType?: string
	): void {
		let products: string;
		if (this.productTagg.length === 0) {
			products = '';
		} else {
			products = this.productTagg + CommercialJourneys.nexusCommercial.evar.coma;
			this.productTagg = '';
		}
		switch (service.catalogElementType.toLocaleLowerCase()) {
			case commercialMobile.package:
				products = this.viewTypePackage(products, service, selectOffer);
				break;
			case commercialMobile.device:
				if (
					service.detail.categoryType?.toLowerCase() === secondResidences.categoryType.toLowerCase() &&
					service.detail.rateType === Number(secondResidences.socialRateType)
				) {
					products = tagging.productAndServices.secondResidences.selectionPage.data['&&products'].replace(
						'<category>',
						this.detailService.nameDeviceDetail
					);
					break;
				}
				products = this.addProductToProductTaggDevice(
					products,
					service,
					associatedLine,
					selectOffer,
					isPegaDeviceOfferType
				);
				break;
		}
		this.productTagg = products;
	}

	private addInsuranceProductTag(products: string, service: CommercialService, associatedLine: string): string {
		const productsTag: string =
			CommercialJourneys.nexusCommercial.evar.coma +
			tagging.CommercialJourneys.care.productsTemplate
				.replace(
					'<product_name>',
					service.insurance.productName
						?.normalize('NFD')
						.replace(/[\u0300-\u036f]/g, '')
						.toLowerCase() || ''
				)
				.replace('<msisdn>', associatedLine)
				.replace('<model>', service.detail.name)
				.replace('<brand>', service.detail.brand);
		products += productsTag;
		return products;
	}

	private addOneProProductTag(products: string): string {
		let opProds: string = products.slice(0, products.indexOf(tagging.nexusCommercial.overlay.varPos) + 9);
		opProds += `${CommercialJourneys.nexusCommercial.evar.coma}${tagging.nexusCommercial.overlay.taggingOpTemplate}`;
		opProds += products.slice(products.indexOf(tagging.nexusCommercial.overlay.varPos) + 9);
		return opProds;
	}

	private getPresaleTag(service: CommercialService): string {
		const existsServiceDetailSaleType: boolean = service.detail != null && service.detail.saleType != null;

		if (existsServiceDetailSaleType) {
			const condition1: boolean =
				service.detail.saleType.toString() === CommercialJourneys.nexusCommercial.evar239_states.case_1;
			const condition2: boolean =
				service.detail.saleType.toString() !== CommercialJourneys.nexusCommercial.evar239_states.case_1;

			if (condition1) {
				return CommercialJourneys.nexusCommercial.evar239_states.presale_stock;
			}

			const accelerator: boolean =
				this.translate.instant('v10.commercial.mfdevices.terminalSetup.stock.accelerator').toString().toLowerCase() ===
				stringOfTrue;

			if (condition2 && accelerator) {
				return CommercialJourneys.nexusCommercial.evar239_states.accelerator;
			}
		}

		return CommercialJourneys.nexusCommercial.evar239_states.available;
	}

	private getPromosTagg(service: CommercialService): string {
		let product: string = '';
		if (service.detail?.promotions) {
			service.detail?.promotions.forEach((promo) => {
				if (promo?.publicName) {
					product += promo?.publicName?.toLowerCase();
				}
				if (promo !== service.detail?.promotions[service.detail?.promotions.length - 1]) {
					product += CommercialJourneys.nexusCommercial.evar.underscore;
				}
			});
		}
		return product;
	}

	public numberOfLines(cart: CommercialShoppingCart): number {
		let numberOfLines: number = 0;
		cart.cartItems.forEach((service) => {
			service.services.forEach((serv) => {
				if (serv.detail?.associatedLine) {
					numberOfLines++;
				}
			});
		});
		return numberOfLines;
	}

	getDescriptionCharacteristicsForDevices(mainCharacteristics: MainCharacteristics[]): string {
		let description: string = '';

		if (mainCharacteristics != null && mainCharacteristics.length > 0) {
			mainCharacteristics.sort(
				(firstCharacteristic: MainCharacteristics, secondCharacteristic: MainCharacteristics) => {
					return firstCharacteristic.order > secondCharacteristic.order ? 1 : -1;
				}
			);
			for (let i = 0; i < mainCharacteristics.length; i++) {
				description += `${mainCharacteristics[i].value}${
					mainCharacteristics[i].unit !== null ? mainCharacteristics[i].unit : ''
				} `;
				if (i === 1) {
					break;
				}
			}
			description = description.trimEnd();
		}

		return description;
	}

	private addProductToProductTaggPackage(products: string, service: CommercialService, selectOffer: string): string {
		products += CommercialJourneys.nexusCommercial.evar.linea + CommercialJourneys.nexusCommercial.evar.semicolon;
		products += service.detail?.name?.toLowerCase() + CommercialJourneys.nexusCommercial.evar.separator;
		products += CommercialJourneys.nexusCommercial.evar.evar180;
		products +=
			service.detail?.registerTypeName?.toLowerCase() === CommercialJourneys.nexusCommercial.evar.alta_nueva
				? CommercialJourneys.nexusCommercial.evar.alta_nueva
				: CommercialJourneys.nexusCommercial.evar.portabilidad;
		products += CommercialJourneys.nexusCommercial.evar.evar181 + this.storageService.serviceAES256;
		products += CommercialJourneys.nexusCommercial.evar.evar182 + service.code?.toLowerCase();
		products += CommercialJourneys.nexusCommercial.evar.evar184 + this.getPromosTagg(service);
		products += CommercialJourneys.nexusCommercial.evar.evar186 + CommercialJourneys.nexusCommercial.evar.evar187;
		products +=
			service.productHierarchyType?.toLocaleLowerCase() === commercialMobile.productHierarchyType.toLowerCase()
				? CommercialJourneys.nexusCommercial.evar.principal
				: CommercialJourneys.nexusCommercial.evar.addons;
		products += CommercialJourneys.nexusCommercial.evar.evar188;
		products +=
			service.productHierarchyType?.toLowerCase() === commercialMobile.productHierarchyType
				? CommercialJourneys.nexusCommercial.evar.p
				: CommercialJourneys.nexusCommercial.evar.s;
		products += CommercialJourneys.nexusCommercial.evar.evar190;
		products +=
			CommercialJourneys.nexusCommercial.evar.evar207 +
			(service.detail?.promotions ? service.detail?.promotions[0].descriptionCRM : '');
		products +=
			CommercialJourneys.nexusCommercial.evar.evar213 +
			CommercialJourneys.nexusCommercial.evar.evar214 +
			CommercialJourneys.nexusCommercial.evar.evar215 +
			CommercialJourneys.nexusCommercial.evar.evar138;
		products += CommercialJourneys.nexusCommercial.evar.evar239 + this.getPresaleTag(service);
		if (this.microFlowService.laEntryPoint?.offerVFDescriptor) {
			products +=
				selectOffer?.toLowerCase() === service.code?.toLowerCase()
					? CommercialJourneys.nexusCommercial.evar.evar200 + 'pega'
					: CommercialJourneys.nexusCommercial.evar.evar200 + 'publica';
		} else {
			products +=
				selectOffer?.toLowerCase() === service.code?.toLowerCase()
					? CommercialJourneys.nexusCommercial.evar.evar200 + 'pega'
					: CommercialJourneys.nexusCommercial.evar.evar200 + 'publica';
		}
		return products;
	}

	private addProductToProductTaggDevice(
		products: string,
		service: CommercialService,
		associatedLine: string,
		selectOffer: string,
		isPegaDeviceOfferType?: string
	): string {
		products += `${this.detailService.analyticsCategoryType}${CommercialJourneys.nexusCommercial.evar.semicolon}`;
		products +=
			`${service.detail.brand} ${service.detail.name} ${this.getDescriptionCharacteristicsForDevices(
				service.detail.mainCharacteristics
			)}`.toLowerCase() + CommercialJourneys.nexusCommercial.evar.separator;
		products += CommercialJourneys.nexusCommercial.evar.evar180;
		products +=
			service.detail?.registerTypeName?.toLowerCase() === CommercialJourneys.nexusCommercial.evar.alta_nueva
				? CommercialJourneys.nexusCommercial.evar.alta_nueva
				: CommercialJourneys.nexusCommercial.evar.portabilidad;
		products += CommercialJourneys.nexusCommercial.evar.evar181 + associatedLine;
		products += CommercialJourneys.nexusCommercial.evar.evar182 + service.detail?.sap?.toLowerCase();
		products += CommercialJourneys.nexusCommercial.evar.evar184 + this.getPromosTagg(service);
		products += CommercialJourneys.nexusCommercial.evar.evar186 + CommercialJourneys.nexusCommercial.evar.evar187;
		products +=
			service.productHierarchyType?.toLocaleLowerCase() === commercialMobile.productHierarchyType?.toLowerCase()
				? CommercialJourneys.nexusCommercial.evar.principal
				: CommercialJourneys.nexusCommercial.evar.addons;
		products += CommercialJourneys.nexusCommercial.evar.evar188;
		products +=
			service.productHierarchyType?.toLowerCase() === commercialMobile.productHierarchyType
				? CommercialJourneys.nexusCommercial.evar.p
				: CommercialJourneys.nexusCommercial.evar.s;
		products += CommercialJourneys.nexusCommercial.evar.evar190;
		products += service.detail?.months
			? CommercialJourneys.nexusCommercial.evar.financedPayment.replace('{0}', service.detail?.months.toString())
			: CommercialJourneys.nexusCommercial.evar.paymentCash;
		products += `${commercialMobile.Tagg.evar.evar200}${isPegaDeviceOfferType}`;
		products +=
			CommercialJourneys.nexusCommercial.evar.evar207 +
			CommercialJourneys.nexusCommercial.evar.evar213 +
			service.detail?.color?.toLowerCase();
		products += CommercialJourneys.nexusCommercial.evar.evar214 + service.detail?.name?.toLowerCase();
		products += CommercialJourneys.nexusCommercial.evar.evar215 + service.detail?.capacity?.toLowerCase();
		products += CommercialJourneys.nexusCommercial.evar.evar138 + service.detail?.brand.toLowerCase();
		products += CommercialJourneys.nexusCommercial.evar.evar239;
		products += this.getPresaleTag(service);
		if (service.detail.tipoCliente) {
			products += commercialMobile.Tagg.evar.evar244 + service.detail.tipoCliente.toLocaleLowerCase();
		}
		if (this.microFlowService.laEntryPoint?.offerVFDescriptor) {
			products += CommercialJourneys.nexusCommercial.evar.evar200 + selectOffer;
		}
		if (service.detail.crossedOfferId && service.detail.offerType) {
			products +=
				CommercialJourneys.nexusCommercial.evar.evar68 +
				CommercialJourneys.nexusCommercial.evar.pco +
				service.detail.crossedOfferId
					.toLowerCase()
					.normalize('NFD')
					.replace(/[\u0300-\u036f]/g, '');
			products +=
				CommercialJourneys.nexusCommercial.evar.pto +
				service.detail.offerType
					.toLowerCase()
					.normalize('NFD')
					.replace(/[\u0300-\u036f]/g, '');
			if (this.privateOfferList) {
				this.privateOfferList.forEach((offer: PrivateOfferListCartModel) => {
					if (service.nodeItemId.id === offer.nodeItemId) {
						products +=
							CommercialJourneys.nexusCommercial.evar.ptc +
							offer.techDeviceType
								.toLowerCase()
								.normalize('NFD')
								.replace(/[\u0300-\u036f]/g, '');
					}
				});
			}
		}
		if (service.insurance) {
			products = this.addInsuranceProductTag(products, service, associatedLine);
		}
		return products;
	}

	private getTypeRegister(services: CommercialService[]): string {
		const _found: CommercialService = services?.find(
			(element: CommercialService) =>
				element.detail.serviceItemTypeName.toLocaleLowerCase() === commercialMobile.landlineservice.toLocaleLowerCase()
		);
		return _found?.detail.registerType === 0
			? CommercialJourneys.nexusCommercial.evar.alta_nueva
			: CommercialJourneys.nexusCommercial.evar.portabilidad;
	}

	private addProductToProductTaggPackageFiber(
		products: string,
		service: CommercialService,
		selectOffer: string
	): string {
		products += CommercialJourneys.nexusCommercial.evar.fibra + CommercialJourneys.nexusCommercial.evar.semicolon;
		products += service.detail?.name?.toLocaleLowerCase() + CommercialJourneys.nexusCommercial.evar.separator;
		products += CommercialJourneys.nexusCommercial.evar.evar180 + this.getTypeRegister(service.services);
		products += CommercialJourneys.nexusCommercial.evar.evar181 + this.storageService.serviceAES256;
		products += CommercialJourneys.nexusCommercial.evar.evar182 + service.id;
		products += CommercialJourneys.nexusCommercial.evar.evar184 + this.getPromosTagg(service);
		products += CommercialJourneys.nexusCommercial.evar.evar186 + CommercialJourneys.nexusCommercial.evar.evar187;
		products +=
			service.productHierarchyType?.toLocaleLowerCase() === commercialMobile.productHierarchyType.toLocaleLowerCase()
				? CommercialJourneys.nexusCommercial.evar.principal
				: CommercialJourneys.nexusCommercial.evar.addons;
		products += CommercialJourneys.nexusCommercial.evar.evar188;
		products +=
			service.productHierarchyType?.toLocaleLowerCase() === commercialMobile.productHierarchyType.toLocaleLowerCase()
				? CommercialJourneys.nexusCommercial.evar.p
				: CommercialJourneys.nexusCommercial.evar.s;
		products += CommercialJourneys.nexusCommercial.evar.evar207;
		products += service.detail?.promotions ? service.detail?.promotions[0].descriptionCRM : '';
		products += CommercialJourneys.nexusCommercial.evar.evar214 + service.detail?.name?.toLocaleLowerCase();
		this.productsInstallation = products;
		return products;
	}

	public getOneProducts(idProduct: string): Observable<OneProfNexus> {
		let url: string = API_URLS.ProductOneProfCommercial;
		this.customerAccountsId = this.microFlowService.subscriptionData?.customerData?.customerAccountsId ?? '';
		url = url.replace('{idGrupoSeleccionado}', idProduct).replace('{customerAccountId}', this.customerAccountsId);
		let headersOP: HttpHeaders = new HttpHeaders();
		headersOP = headersOP.append('Content-Type', 'application/json');
		headersOP = headersOP.append('auth_ticket', this.storageService.getLocalStorage(LOCAL_STORAGE_KEYS.JWT));
		headersOP = headersOP.append(
			'Authorization',
			'Bearer ' + this.storageService.getLocalStorage(LOCAL_STORAGE_KEYS.ACCESS_TOKEN)
		);
		const options: Record<string, HttpHeaders | string | number | boolean> = {
			headers: headersOP,
			withCredentials: true,
		};
		return this.http.get(url, options).pipe(
			map((res: OneProfNexus) => {
				return res;
			})
		);
	}

	public addOneProfessionalToCart(): Observable<CommercialShoppingCart> {
		const url: string = API_URLS.Checkout.postShoppingCartItem;
		const headers: HttpHeaders = new HttpHeaders();
		const lastItem: number = this.oneProfProductSelected.serviceInstanceList.length - 1;
		const options: Record<string, HttpHeaders | string | number | boolean> = {
			headers: headers,
			auth_ticket: this.storageService.getLocalStorage(LOCAL_STORAGE_KEYS.JWT),
			NTOL_TXID: this.microFlowService.cartId,
			withCredentials: true,
		};
		const params: Record<string, string | number> = {
			itemid: this.oneProfProductSelected.serviceInstanceList[lastItem].parentItemId,
			idService: this.oneProfProductSelected.productId,
			serviceType: CommercialJourneys.OneProfessionalService,
			purchasePoint: CommercialJourneys.OneProfPurchasePoint,
		};
		return this.http.post(url, params, options).pipe(
			map((res: CommercialShoppingCart) => {
				return res;
			}),
			catchError((err) => {
				return throwError(err);
			})
		);
	}

	public addOpServices(serv: CommercialService, handsetsBox: Array<HandsetsBox>, feeText: string): void {
		serv.services.forEach((service: CommercialService) => {
			if (service.detail.serviceItemType === CommercialJourneys.OneProfessionalService) {
				const opServiceBox: OpServices = new OpServices();
				feeText = this.translate.instant(`v10.commercial.checkout.nexus.onePro.${service.code}.summaryFeeText`);
				opServiceBox.image = this.translate.instant(`v10.commercial.checkout.nexus.onePro.${service.code}.image`);
				opServiceBox.name = service.detail.name;
				opServiceBox.price =
					`${service.price.installmentTaxes} ` +
					this.translate.instant(`v10.commercial.checkout.nexus.onePro.${service.code}.currency`);
				if (!handsetsBox[parseInt(service.itemId[0], 0)].opServices) {
					handsetsBox[parseInt(service.itemId[0], 0)].opServices = new Array<OpServices>();
				}
				handsetsBox[parseInt(service.itemId[0], 0)].opServices.push(opServiceBox);
				const opService: PromotionsBox = new PromotionsBox();
				opService.title = this.translate.instant(`v10.commercial.checkout.nexus.onePro.${service.code}.detailDesc`);
				opService.icon = this.translate.instant(`v10.commercial.checkout.nexus.onePro.${service.code}.detailIcon`);
				handsetsBox[parseInt(service.itemId[0], 0)].promotions.splice(
					handsetsBox[parseInt(service.itemId[0], 0)].opServices.length,
					0,
					opService
				);
			}
		});
	}

	public deleteInsurance(itemid: string, modalidadSeguro: string): Observable<CommercialShoppingCart> {
		const url: string = API_URLS.Insurance.deleteInsurance;
		const headers: HttpHeaders = new HttpHeaders();
		const options: { headers: HttpHeaders; NTOL_TXID: string; auth_ticket: string; withCredentials: boolean } = {
			headers: headers,
			NTOL_TXID: this.microFlowService.cartId,
			auth_ticket: this.storageService.getLocalStorage(LOCAL_STORAGE_KEYS.JWT),
			withCredentials: true,
		};
		const params: DeleteInsuranceModel = {
			itemid: itemid,
			modalidadSeguro: modalidadSeguro,
		};
		return this.http.post(url, params, options).pipe(
			map((res: CommercialShoppingCart) => {
				this.commercialMobile.hasInsuranceOnCart = res.cartItems.some((item) =>
					item.services.some((serv) => serv.insurance)
				);
				return res;
			}),
			catchError((err) => {
				return throwError(err);
			})
		);
	}

	public tagClickRemoveInsurance(): void {
		this.newTagging.getJsonTagging('insurance/remove-insurance').subscribe((data: NewTaggingJsonModel) => {
			data.eventList['click_continue']['custom_map']['&&products'] = this.productTagg;
			data.eventList['click_continue'].journey.journey_category = this.category;
			data.eventList['click_continue'].journey.journey_subcategory = this.subcategory;
			this.newTagging.interaction(data.eventList['click_continue'], data.page);
		});
	}

	public tagModalRemoveInsuranceState(type: string): void {
		this.newTagging.getJsonTagging('insurance/modal-remove-insurance').subscribe((data: NewTaggingJsonModel) => {
			data.page['custom_map']['&&products'] = this.productTagg;
			data.page.page_subcategory_level[2] = type;
			data.page.journey.journey_category = this.category;
			data.page.journey.journey_subcategory = this.subcategory;
			this.newTagging.state(data.page);
		});
	}

	public tagClickModalRemoveInsurance(type: string, button: string): void {
		this.newTagging.getJsonTagging('insurance/modal-remove-insurance').subscribe((data: NewTaggingJsonModel) => {
			data.page.page_subcategory_level[2] = type;
			data.eventList['click_continue']['custom_map']['&&products'] = this.productTagg;
			data.eventList['click_continue'].journey.journey_category = this.category;
			data.eventList['click_continue'].journey.journey_subcategory = this.subcategory;
			data.eventList['click_continue'].event.event_label = data.eventList['click_continue'].event.event_label.replace(
				'$0',
				button
			);
			this.newTagging.interaction(data.eventList['click_continue'], data.page);
		});
	}

	public checkFibreType(serv: CommercialService): boolean {
		return (
			(serv.detail.serviceItemType === CommercialJourneys.serviceItemTypeUpsell ||
				serv.detail.serviceItemType === CommercialJourneys.serviceItemTypeUpsellTV ||
				serv.detail.serviceItemType === CommercialJourneys.serviceItemTypeOnePlus ||
				serv.detail.serviceItemType === CommercialJourneys.serviceItemTypeConecta ||
				serv.detail.serviceItemType === CommercialJourneys.serviceItemTypeConectaTelevision) &&
			(serv.detail.serviceItemTypeName.toLocaleLowerCase() === commercialMobile.onepackage ||
				serv.detail.serviceItemTypeName.toLocaleLowerCase() === commercialMobile.onetelevisionpackage ||
				serv.detail.serviceItemTypeName.toLocaleLowerCase() === commercialMobile.onepluspackage ||
				serv.detail.serviceItemTypeName.toLocaleLowerCase() === commercialMobile.conectapackage ||
				serv.detail.serviceItemTypeName.toLocaleLowerCase() === commercialMobile.conectatelevisionpackage) &&
			(serv.detail.registerType === CommercialJourneys.registerTypeNew ||
				serv.detail.registerType === CommercialJourneys.registerTypeNewUpsell)
		);
	}

	public checkFibreTypeNexusService(service: CommercialService): boolean {
		return (
			service.detail.serviceItemTypeName.toLocaleLowerCase() === commercialMobile.onepackage ||
			service.detail.serviceItemTypeName.toLocaleLowerCase() === commercialMobile.onetelevisionpackage ||
			service.detail.serviceItemTypeName.toLocaleLowerCase() === commercialMobile.onepluspackage ||
			service.detail.serviceItemTypeName.toLocaleLowerCase() === commercialMobile.conectapackage ||
			service.detail.serviceItemTypeName.toLocaleLowerCase() === commercialMobile.conectatelevisionpackage
		);
	}
}
