import { Component, Input } from '@angular/core';
import { NewTaggingJsonModel } from 'src/app/shared/models/new-tagging-json-model';
import { CommonService } from '../../../core/services/common.service';
import { NewTaggingHelperService } from '../../../core/services/new-tagging.helper.service';
import { TaggingHelperService } from '../../../core/services/tagging.helper.service';
import { UpgradeProfileService } from '../../../upgrade-profile/upgrade-profile.service';
import { MyAccountSections } from '../../enums/my-account-sections.enum';
import { TaggingViewModel } from '../../models/tagging.model';
import { SidemenuConfigService } from '../../services/sidemenu-config.service';
import { SpSideMenuSubItem } from '../sp-sidemenu/sp-sidemenu.interface';
import { tagging } from './../../../../config/tagging-config';

@Component({
	selector: 'sp-accordion',
	templateUrl: './sp-accordion.component.html',
	styleUrls: ['./sp-accordion.component.scss'],
})
export class SpAccordionComponent {
	@Input() public subItems: SpSideMenuSubItem[];
	@Input() public itemText: string;
	@Input() public iconClassName: string;
	@Input() public rightText: string;

	constructor(
		private commonService: CommonService,
		private upgradeService: UpgradeProfileService,
		private menuService: SidemenuConfigService,
		private tagging: TaggingHelperService,
		private newTaggingHelperService: NewTaggingHelperService
	) {}

	clickEventF(item: SpSideMenuSubItem) {
		this.hide();
		if (item.redirectTo) {
			if (!item.locked) {
				if (
					[MyAccountSections.myData].map((section: MyAccountSections): string => String(section)).includes(item.name)
				) {
					this.newTaggingHelperService.getJsonTagging('side-menu/side-menu').subscribe((data: NewTaggingJsonModel) => {
						this.newTaggingHelperService.interaction(
							data.eventList.tap_menu_my_data,
							data.page.stateList['dashboard_menu']
						);
					});
				} else {
					const eventName: string = tagging.sidemenu.itemClicked.childItem.data.event_name
						.replace('{0}', this.itemText)
						.replace('{1}', item.itemText);
					const data: TaggingViewModel = {
						event_name: eventName,
					};
					this.tagging.track(eventName, data);
				}
				this.menuService.sidemenuItemClick(item);
			} else {
				this.upgradeService.targetRoute = item.redirectTo;
				this.upgradeService.startScenario = true;
				return;
			}
		}
	}

	hide() {
		this.commonService.showMenu = false;
	}
}
