import { CapacitiesType } from '../enums/capacitiesType.enum';
import { CommercialFinancingOption } from './commercial-terminal-financing.model';

export class CommercialTerminalDetail {
	public nombre?: string;
	public id?: number;
	public type?: string;
	public taxType?: string;
	public taxValue?: number;
	public terminalDetailList?: CommercialTerminalDetailList[];
	public ecode?: string;
	public message?: string;
	public error?: string;
}

export class CommercialTerminalDetailList {
	public ordenGrupo: number;
	public idTerminal: number;
	public idTipoTerminal: number;
	public idGrupo?: number;
	public sap: string;
	public color: Color;
	public capacidad: Capacity;
	public modelo: string;
	public isPrioritary?: string;
	public descripcion?: string;
	public imagenFrontalMovil: string;
	public textoImagenFrontalMovil: string;
	public imagenTraseraMovil: string;
	public textoImagenTraseraMovil: string;
	public imagenLateralMovil: string;
	public textoImagenLateralMovil: string;
	public imagenMedidasMovil: string;
	public textoImagenMedidasMovil: string;
	public so?: string;
	public imagenSo?: string;
	public marca: string;
	public idMarca?: number;
	public limitado?: string;
	public selected: boolean;
	public characteristics: Characteristics[];
	public listaPromos: Promotion[];
	public itemStock: ItemStock;
	public financingOptions: CommercialFinancingOption[];
	public imagenFrontalTablet: string;
	public textoImagenFrontalTablet: string;
	public imagenTraseraTablet: string;
	public textoImagenTraseraTablet: string;
	public imagenLateralTablet: string;
	public textoImagenLateralTablet: string;
	public imagenMedidasTablet: string;
	public textoImagenMedidasTablet: string;
	public mainCharacteristics: MainCharacteristics[];
	public categoryType: string;
	public ecoRatingImage: string;
	public ecoRatingText: string;
	public promotedCanon?: boolean;
	public analyticsCategoryType: string;
	public techDeviceType?: string;
	public insuranceByDefault?: boolean;
}

export class Capacity {
	public capacidadValorUnidad: string;
	public valor: string;
	public unidad: string;

	static equal(obj1: Capacity, obj2: Capacity): boolean {
		return (
			obj1.capacidadValorUnidad.toLowerCase() === obj2.capacidadValorUnidad.toLowerCase() &&
			obj1.valor.toLowerCase() === obj2.valor.toLowerCase() &&
			obj1.unidad.toLowerCase() === obj2.unidad.toLowerCase()
		);
	}

	static lower(obj1: Capacity, obj2: Capacity): boolean {
		const capacitiesArray = Object.keys(CapacitiesType).map((key) => key.toLocaleLowerCase());
		if (capacitiesArray.indexOf(obj1.unidad.toLowerCase()) < capacitiesArray.indexOf(obj2.unidad.toLowerCase())) {
			return true;
		} else if (
			capacitiesArray.indexOf(obj1.unidad.toLowerCase()) > capacitiesArray.indexOf(obj2.unidad.toLowerCase())
		) {
			return false;
		} else {
			return Number(obj1.valor) < Number(obj2.valor);
		}
	}
}

export class Color {
	public name: string;
	public code: string;

	static equal(obj1: Color, obj2: Color): boolean {
		return obj1.name.toLowerCase() === obj2.name.toLowerCase() && obj1.code.toLowerCase() === obj2.code.toLowerCase();
	}
}
export class ListaIconos {
	public textoIcono?: string;
	public imagenIcono?: string;
}

export class Promotion {
	public added?: boolean;
	public type?: string;
	public id?: number;
	public internalName?: string;
	public publicName?: string;
	public startDate?: Date;
	public endDate?: Date;
	public stateType?: string;
	public flagRegional?: boolean;
	public manualProvision?: boolean;
	public discountType?: string;
	public codigoOferta?: string;
	public evuluationOrder?: number;
	public shortText?: string;
	public image?: string;
	public longText?: string;
	public llbbText?: string;
	public llbbUrl?: string;
	public listIcon?: string;
	public detailIcon?: string;
	public urlEmbeddedVideo?: string;
	public urlImagePreviewVideo?: string;
	public promotionTitle?: string;
	public detailPromotion?: string;
	public cardColor?: string;
}

export class Characteristics {
	public section: string;
	public icon: string;
	public items: Items[];
}

export class Items {
	public name: string;
	public value: string;
}

export class MainCharacteristics {
	code: string;
	desc: string;
	order: number;
	sortMethod: string;
	sortType: string;
	icon: string;
	value: string | number;
	extendedValue?: string;
	standardValue?: string;
	unit: string;
	checked?: boolean;
	childCharacteristics?: MainCharacteristics[];
	title: string;
}

export class FamilySort {
	public asc: string;
	public desc: string;
	public alphabetical: string;
	public number: string;
	public value: string;
	public color: string;
	public defaultIcon: string;
	public standardValue: string;
}

export class ItemStock {
	public visible: boolean;
	public presale: boolean;
	public unavailable?: boolean;
	public avaliableStockText?: string;
	public stock: number;
	public ventaSinStock: boolean;
	public presaleFrontText: string;
	public stockFicticio: number;
	public superaUmbralFicticio: boolean;
	public unavailableFicticio: boolean;
	public notification?: boolean;
	public accelerator?: boolean;
}

export class TypeErrorDetail {
	public page_name?: string;
	public page_section?: string;
	public page_subcategory_level_1?: string;
	public page_screen?: string;
	public ecode?: string | number;
	public journey_type?: string;
	public error_path?: string;
	public journey_name?: string;
	public journey_detail?: string;
	public journey_step?: string;
	public error_origin?: string;
	public error_list?: string;
	public error_code?: string;
	public error_type?: string;
	public error_location?: string;
	public error_category?: string;
	public error_description?: string;
}
