import { cachingRegex } from '../constants/defines';
import { CachedResponse } from '../models/cached-response';

export class CacheService {
	static cacheTable: Array<CachedResponse> = new Array<CachedResponse>();
	static enableCaching: Boolean = true;
	static serveCached(url, config) {
		// SUBJECT TO REFACTOR
		// This needs to be moved to a centralized place as it's not
		// the responsibility of this function to filter expired requests
		CacheService.cacheTable = CacheService.cacheTable.filter((response) => {
			// filter the cache table from expired responses
			return !response.expiryDate || response.expiryDate > new Date();
		});
		if (config) {
			const cachedObject = CacheService.cacheTable.find((cachedResponse) => {
				return url === cachedResponse.identifier;
			});
			return cachedObject;
		}
	}
	static buildCacheRegex(api): RegExp {
		// replace url parameters with a wild a card
		const regEx = new RegExp(cachingRegex, 'g');
		const urlRegex = api.replace(regEx, '[^/]*') + '(\\?.*)?$';
		return new RegExp(urlRegex);
	}
	static evict(api) {
		CacheService.cacheTable = CacheService.cacheTable.filter((response) => {
			return response.api !== api;
		});
	}
	static clearCache() {
		CacheService.cacheTable = new Array<CachedResponse>();
	}
}
