import { TranslateService } from '@ngx-translate/core';
import { CustomStyle, PaymentFullTrayModel } from '../../../shared/models/payment';
import { overlayTitlecustomStyle, overlayTitleSelector } from '../../../shared/constants/defines';
import { PaymentJourny } from '../../../shared/enums/payment-journy.enum';
import { PaymentPages } from '../../../shared/enums/payment-pages.enum';
import { PaymentWalletService } from '../../../shared/services/payment-wallet.service';
import { DelegateCreationService } from '../delegate-creation.service';
import { PaymentNavigationService } from '../payment-navigation.service';
import { PaymentTrayConfigDefault } from './payment-tray-config-default';
import { PaymentTrayConfigInterface } from './payment-tray-config-interface';

export class PaymentTrayConfigPostPaid extends PaymentTrayConfigDefault implements PaymentTrayConfigInterface {
	// this component is treated like an ovrelay we remove tem from history after navigating away from them
	// not to show them to the user accidentaly when he navigate back
	compnentToBeRemovedFromHistoryAfternavigation: PaymentPages[] = [
		PaymentPages.cancel,
		PaymentPages.loading,
		PaymentPages.deleteConfiramtion,
	];
	/** variable to be used for getting a tray config method based on component name */
	trayConfigurations: any = {
		[PaymentPages.thirdPartyContainer]: this.getIframeCompTrayConfig.bind(this),
		[PaymentPages.ok]: this.getOkCompTrayConfig.bind(this),
		[PaymentPages.ko]: this.getKoCompTrayConfig.bind(this),
		[PaymentPages.cancel]: this.getCancelCompTrayConfig.bind(this),
		[PaymentPages.wallet]: this.getWalletCompTrayConfig.bind(this),
		[PaymentPages.loading]: this.getLoadingCompTrayConfig.bind(this),
		[PaymentPages.ticket]: this.getTicketCompTrayConfig.bind(this),
		[PaymentPages.deleteConfiramtion]: this.getDeleteCardCompTrayConfig.bind(this),
		[PaymentPages.dept]: this.getDeptCompTrayConfig.bind(this),
		[PaymentPages.partialDept]: this.getDeptPartialCompTrayConfig.bind(this),
		[PaymentPages.partialCashDesk]: this.getCashDeskPartialCompTrayConfig.bind(this),
		[PaymentPages.inadvance]: this.getInadvanceCompTrayConfig.bind(this),
		[PaymentPages.cashDesk]: this.getCashDeskCompTrayConfig.bind(this),
		[PaymentPages.otherPaymentMethods]: this.getOtherPaymentMethodsCompTrayConfig.bind(this),
	};
	constructor(
		public paymentNavService: PaymentNavigationService,
		public delegateService: DelegateCreationService,
		public translate: TranslateService,
		public paymentWalletService: PaymentWalletService
	) {
		super(paymentNavService, delegateService, translate, paymentWalletService);
	}
	/**
	 * @param compName the page we need it's tray configuration
	 * function should return PaymentFullTrayModel containing
	 * the proper tray configuration
	 */
	getCurrentTrayConfig(compName: PaymentPages): PaymentFullTrayModel {
		let config: PaymentFullTrayModel;
		/** if compName is exist in trayConfigurations then invoke its method to get tray configuration */
		if (this.trayConfigurations[compName]) {
			config = this.trayConfigurations[compName]();
		} else {
			config = this.getIframeCompTrayConfig();
		}
		return config;
	}
	/**
	 * function return PaymentFullTrayModel
	 * the configuration of the inadvance payment start screen configuration
	 */
	getInadvanceCompTrayConfig(): PaymentFullTrayModel {
		const paymentFullTrayModel: PaymentFullTrayModel = this.getInitalConfigModel();
		this.translate.get('payment.messagesList.paymentInAdvance.title').subscribe((data) => {
			paymentFullTrayModel.MVA10Config.title = data;
		});
		paymentFullTrayModel.paymentCustomConfig.closeBtnAction = () => {
			this.paymentNavService.showCancelPayment();
		};
		paymentFullTrayModel.paymentCustomConfig.initComponantOnBack = false;
		paymentFullTrayModel.paymentCustomConfig.customStyles.push(
			new CustomStyle(overlayTitleSelector, overlayTitlecustomStyle)
		);
		return paymentFullTrayModel;
	}
	/**
	 * function return PaymentFullTrayModel
	 * the configuration of the cash desk payment start screen configuration
	 */
	getCashDeskCompTrayConfig(): PaymentFullTrayModel {
		const paymentFullTrayModel: PaymentFullTrayModel = this.getInitalConfigModel();
		this.translate.get('payment.messagesList.cashDeskPayment.title').subscribe((data) => {
			paymentFullTrayModel.MVA10Config.title = data;
		});
		paymentFullTrayModel.paymentCustomConfig.closeBtnAction = () => {
			this.paymentNavService.showCancelPayment();
		};
		paymentFullTrayModel.MVA10Config.showBackButton = true;
		paymentFullTrayModel.paymentCustomConfig.initComponantOnBack = false;
		paymentFullTrayModel.paymentCustomConfig.customStyles.push(
			new CustomStyle(overlayTitleSelector, overlayTitlecustomStyle)
		);
		return paymentFullTrayModel;
	}
	/**
	 * function return PaymentFullTrayModel
	 * the configuration of the dept payment start screen configuration
	 */
	getDeptCompTrayConfig(): PaymentFullTrayModel {
		const paymentFullTrayModel: PaymentFullTrayModel = this.getInitalConfigModel();
		this.translate.get('payment.messagesList.pendingPayment.title').subscribe((data) => {
			paymentFullTrayModel.MVA10Config.title = data;
		});
		paymentFullTrayModel.paymentCustomConfig.closeBtnAction = () => {
			this.paymentNavService.showCancelPayment();
		};
		paymentFullTrayModel.MVA10Config.showBackButton = false;
		paymentFullTrayModel.paymentCustomConfig.initComponantOnBack = false;
		paymentFullTrayModel.paymentCustomConfig.customStyles.push(
			new CustomStyle(overlayTitleSelector, overlayTitlecustomStyle)
		);
		return paymentFullTrayModel;
	}
	/**
	 * function return PaymentFullTrayModel
	 * the configuration of the dept payment start screen configuration
	 */
	getDeptPartialCompTrayConfig(): PaymentFullTrayModel {
		const paymentFullTrayModel: PaymentFullTrayModel = this.getInitalConfigModel();
		this.translate.get('payment.messagesList.pendingPayment.title').subscribe((data) => {
			paymentFullTrayModel.MVA10Config.title = data;
		});
		paymentFullTrayModel.paymentCustomConfig.closeBtnAction = () => {
			this.paymentNavService.showCancelPayment();
		};
		paymentFullTrayModel.paymentCustomConfig.BackBtnAction = () => {
			this.paymentNavService.WCSJourneyKey = PaymentJourny.postDeptTotal;
		};
		paymentFullTrayModel.MVA10Config.showBackButton = true;
		paymentFullTrayModel.paymentCustomConfig.initComponantOnBack = false;
		paymentFullTrayModel.paymentCustomConfig.customStyles.push(
			new CustomStyle(overlayTitleSelector, overlayTitlecustomStyle)
		);
		return paymentFullTrayModel;
	}
	/**
	 * function return PaymentFullTrayModel
	 * the configuration of the dept payment start screen configuration
	 */
	getCashDeskPartialCompTrayConfig(): PaymentFullTrayModel {
		const paymentFullTrayModel: PaymentFullTrayModel = this.getInitalConfigModel();
		this.translate.get('payment.messagesList.cashDeskPayment.title').subscribe((data) => {
			paymentFullTrayModel.MVA10Config.title = data;
		});
		paymentFullTrayModel.paymentCustomConfig.closeBtnAction = () => {
			this.paymentNavService.showCancelPayment();
		};
		paymentFullTrayModel.paymentCustomConfig.BackBtnAction = () => {
			this.paymentNavService.WCSJourneyKey = PaymentJourny.postcashDeskTotal;
		};
		paymentFullTrayModel.MVA10Config.showBackButton = true;
		paymentFullTrayModel.paymentCustomConfig.initComponantOnBack = false;
		paymentFullTrayModel.paymentCustomConfig.customStyles.push(
			new CustomStyle(overlayTitleSelector, overlayTitlecustomStyle)
		);
		return paymentFullTrayModel;
	}
	/**
	 * function return PaymentFullTrayModel
	 * the configuration of the other payment methods screen configuration
	 */
	getOtherPaymentMethodsCompTrayConfig(): PaymentFullTrayModel {
		const paymentFullTrayModel: PaymentFullTrayModel = this.getInitalConfigModel();
		this.translate.get('payment.messagesList.pendingPayment.title').subscribe((data) => {
			paymentFullTrayModel.MVA10Config.title = data;
		});
		paymentFullTrayModel.paymentCustomConfig.customStyles.push(
			new CustomStyle(overlayTitleSelector, overlayTitlecustomStyle)
		);
		return paymentFullTrayModel;
	}
}
