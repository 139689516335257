import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Observable, throwError } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { config } from '../../../config/pages-config';
import { tagging } from '../../../config/tagging-config';
import { AppService } from '../../app.service';
import { EverythingOkStatus } from '../../shared/constants/defines';
import { API_URLS } from '../../shared/constants/routes-config';
import { GreetingWarningType } from '../../shared/enums/greeting-warning.enum';
import { EverythingOkModel } from '../../shared/models/everything-ok.model';
import { GreetingWarningModel } from '../../shared/models/greetingWarning.model';
import { TaggingHelperService } from './tagging.helper.service';

@Injectable()
export class EverythingOkService {
	public everythingOkStatus: EverythingOkModel;
	public greetingWarningData: GreetingWarningModel;

	constructor(
		private http: HttpClient,
		private tagging: TaggingHelperService,
		private router: Router,
		private appService: AppService
	) {}

	public getEverythingOk(customerId: string): Observable<EverythingOkModel> {
		const url = API_URLS.EverythingOk.replace('{0}', customerId);

		const headers = new HttpHeaders();
		const options = {
			headers: headers,
		};
		return this.http.get(url, options).pipe(
			map((data: any) => {
				return this.mapEverythingOk(data, customerId);
			}),
			catchError((err) => {
				return throwError(err);
			})
		);
	}

	private mapEverythingOk(data: any, customerId: string): EverythingOkModel {
		this.everythingOkStatus = new EverythingOkModel();
		this.everythingOkStatus.customerId = customerId;

		this.everythingOkStatus.issues = data?.characteristic?.filter(
			(characteristic) => characteristic.name === EverythingOkStatus.issuesName
		)[0]?.value;
		this.everythingOkStatus.debtAmount = data?.bucket?.bucketBalance[0]?.remainingValue.amount;

		return this.everythingOkStatus;
	}

	public eioWarningButtonClicked(type: GreetingWarningType = this.greetingWarningData.type): void {
		if (type === GreetingWarningType.fault) {
			this.tagging.track(tagging.dashboard.greeting.fault.click.eventName, tagging.dashboard.greeting.fault.click.data);
			this.router.navigate([config.FaultManagement.LandingPage.route]);
		} else {
			this.tagging.track(tagging.dashboard.greeting.debt.click.eventName, tagging.dashboard.greeting.debt.click.data);
			this.appService.showVodaFullLoader = true;
			this.router.navigate([config.billing.billPayment.route]);
		}
	}
}
