import { ReflectedPaymentType } from '../enums/reflected-payment-type.enum';
import { BillingAccountSubscription } from './billing-accounts-subscription';
import { BillingAddress } from './billing-address.model';
import { Debt } from './debt.model';
import { PaymentMethod } from './payment-method.model';
export class BillingAccounts {
	public id: string;
	public billingAddress?: BillingAddress;
	public paymentMethod: PaymentMethod;
	public debt: Debt;
	public subscriptions?: BillingAccountSubscription[];
	public notReflectedCachDesk?: boolean;
	public reflectedPaymentsType?: ReflectedPaymentType;
}
