import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { GenericModule } from '@mva10/mva10-angular';
import { TranslateModule } from '@ngx-translate/core';
import { AngularSvgIconModule } from 'angular-svg-icon';
import { LottieModule } from 'ngx-lottie';
import { ImgFullPathModule } from './../../pipes/img-full-path/img-full-path.module';
import { IframeModalModule } from './../iframe-modal/iframe-modal.module';
import { SpErrorMessageModule } from './../sp-error-message/sp-error-message.module';
import { SpTextAreaModule } from './../sp-text-area/sp-text-area.module';
import { SpTextModule } from './../sp-text/sp-text.module';
import { AsessorDigitalComponent } from './bubble-help-content/asessor-digital/asessor-digital.component';
import { BubbleHelpContentComponent } from './bubble-help-content/bubble-help-content.component';
import { ContactUsComponent } from './bubble-help-content/contact-us/contact-us.component';
import { DocumentumComponent } from './bubble-help-content/documentum/documentum.component';
import { OutOfTimeComponent } from './bubble-help-content/out-of-time/out-of-time.component';
import { SatisfactionSurveyComponent } from './bubble-help-content/satisfaction-survey/satisfaction-survey.component';
import { BubbleHelpComponent } from './bubble-help.component';

const components = [
	BubbleHelpComponent,
	BubbleHelpContentComponent,
	ContactUsComponent,
	SatisfactionSurveyComponent,
	AsessorDigitalComponent,
	OutOfTimeComponent,
	DocumentumComponent,
];

@NgModule({
	declarations: [...components],
	imports: [
		CommonModule,
		LottieModule,
		TranslateModule,
		AngularSvgIconModule.forRoot(),
		IframeModalModule,
		SpErrorMessageModule,
		ReactiveFormsModule,
		FormsModule,
		SpTextModule,
		SpTextAreaModule,
		ImgFullPathModule,
		GenericModule,
	],
	exports: [...components],
})
export class BubbleHelpModule {}
