import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { BehaviorSubject, Observable, Subject } from 'rxjs';
import { map, tap } from 'rxjs/operators';
import { AppService } from '../app.service';
import * as constants from '../shared/constants/defines';
import { home5G, secondResidences } from '../shared/constants/defines';
import { FaultManagmentTicketType } from '../shared/enums/fault-managmenet-ticket-type.enum';
import { ServiceType } from '../shared/enums/serviceType.enum';
import { TicketStatus, TicketTemplateCode } from '../shared/enums/ticket-status.enum';
import { CustomerAccount } from '../shared/models/customer-account.model';
import { OpenNewTicket } from '../shared/models/openTicket.model';
import { ServiceAvailability, ServiceAvailablityType } from '../shared/models/service-avalability.model';
import { CardServiceImageSelectorModel, ServiceModel } from '../shared/models/service.model';
import { Template } from '../shared/models/template.model';
import { TicketModel } from '../shared/models/ticket.model';
import { ServiceIdClosedPerCategory, ServiceIdPerCategory } from '../shared/models/ticketPerCategory.model';
import { FaultManagementData } from './fault-management.data';

@Injectable()
export class FaultManagementService {
	showToast: boolean = false;
	serviceAvailability: ServiceAvailability;
	services: ServiceModel[] = [];
	template: Template;
	selectedService: ServiceModel;
	selectedServiceName: string;
	ticketId: object;
	templateName: string = '';
	previousTemplateAnalytics: string = '';
	public faultManagementOpenNewTicketLoading: boolean = false;
	private userSiteSubj: BehaviorSubject<CustomerAccount[]> = new BehaviorSubject<CustomerAccount[]>([]);
	userSites$: Observable<CustomerAccount[]> = this.userSiteSubj.asObservable();
	selectedSiteId: string;
	tickets: TicketModel[] = [];
	openServicePerCategory: ServiceIdPerCategory = {
		movil: '',
		fixed: '',
		default: '',
	};
	closedServicePerCategory: ServiceIdClosedPerCategory = {
		movil: [],
		fixed: [],
		default: [],
	};
	_showFullOverlay: boolean = false;
	faultManagementGetTicketsLoading: boolean = false;
	specificAveriaVariables = {
		issue_type: '',
		issue_information: '',
	};
	passBonitaFlow = false;
	startExitBonitaFlowSubject: Subject<any> = new Subject<any>();
	private _showOverlay: boolean = false;
	private _showSecondOverlay: boolean = false;
	private _showThirdOverlay: boolean = false;
	private _showTimeLineFaultOverlay: boolean = false;
	cardImageSelectorList: CardServiceImageSelectorModel[] = [];
	public set showFullOverlay(v: boolean) {
		if (v) {
			this.appService.openBubbleStopScroll = true;
		} else {
			this.appService.openBubbleStopScroll = false;
		}
		this._showFullOverlay = v;
	}

	public get showFullOverlay(): boolean {
		return this._showFullOverlay;
	}

	public get showOverlay(): boolean {
		return this._showOverlay;
	}
	public set showOverlay(v: boolean) {
		this._showOverlay = v;
		if (v) {
			this.startExitBonitaFlowSubject.next();
		}
	}
	public getShowSecondOverlay(): boolean {
		return this._showSecondOverlay;
	}
	public getShowThirdOverlay(): boolean {
		return this._showThirdOverlay;
	}
	public getShowTimeLineFaultOverlay(): boolean {
		return this._showTimeLineFaultOverlay;
	}
	public setShowSecondOverlay(value: boolean): void {
		this._showSecondOverlay = value;
		if (value) {
			this.startExitBonitaFlowSubject.next();
		}
	}
	public setShowThirdOverlay(value: boolean): void {
		this._showThirdOverlay = value;
		if (value) {
			this.startExitBonitaFlowSubject.next();
		}
	}
	public setShowTimeLineFaultOverlay(value: boolean): void {
		this._showTimeLineFaultOverlay = value;
		if (value) {
			this.startExitBonitaFlowSubject.next();
		}
	}
	constructor(
		public settingData: FaultManagementData,
		private appService: AppService,
		private http: HttpClient,
		private translate: TranslateService
	) {}
	getTickets(siteId: string): Observable<any> {
		this.tickets = [];
		return this.settingData.getTicketsData(siteId).pipe(
			map((res: any) => {
				return res.items.map(this.ticketDetail);
			}),
			tap((items) => {
				this.tickets = items;
			})
		);
	}
	getTicketById(ticketId: string, ticketIndex?: number): Observable<TicketModel> {
		return this.settingData.getCurrentTicket(this.selectedSiteId, ticketId).pipe(
			map((res: TicketModel) => {
				this.tickets[ticketIndex].template = this.ticketDetail(res).template;
				this.tickets[ticketIndex].attachment = this.ticketDetail(res).attachment;
				this.tickets[ticketIndex].caseBE = this.ticketDetail(res).caseBE;
				this.tickets[ticketIndex].journey = this.ticketDetail(res).journey;
				this.tickets[ticketIndex].status = this.ticketDetail(res).status;
				this.tickets[ticketIndex].chatRecorded = this.ticketDetail(res).chatRecorded;
				return this.tickets[ticketIndex];
			})
		);
	}

	getServiceAvailability(siteId: string): Observable<ServiceAvailability> {
		return this.settingData.getServiceAvailability(siteId).pipe(
			map((data: ServiceAvailability) => {
				if (data) {
					this.serviceAvailability = this.mapServiceAvailability(data);
					return this.serviceAvailability;
				}
			})
		);
	}
	postServiceAvailability(siteId: string, msisdn, serviceType, massiveTicketId) {
		return this.settingData.postServiceAvailability(siteId, msisdn, serviceType, massiveTicketId).pipe(
			map((data: any) => {
				if (data) {
					this.ticketId = data.ticketId;
					return this.serviceAvailability;
				}
			})
		);
	}

	mapServiceAvailability(data: ServiceAvailability): ServiceAvailability {
		const serviceAvailability = new ServiceAvailability();
		serviceAvailability.hasMassiveTicket = data.hasMassiveTicket;
		serviceAvailability.hasOrderTicket = data.hasOrderTicket;
		serviceAvailability.isMobile = data.isMobile;
		serviceAvailability.isInternet = data.isInternet;
		serviceAvailability.isTV = data.isTV;
		serviceAvailability.isFixed = data.isFixed;
		serviceAvailability.massiveTicketId = data.massiveTicketId;
		serviceAvailability.massiveTicketType = data.massiveTicketType;
		serviceAvailability.scheduledJobStartDateTime = data.scheduledJobStartDateTime;
		serviceAvailability.hasScheduledJob = data.hasScheduledJob;
		serviceAvailability.scheduledJobEndDateTime = data.scheduledJobEndDateTime;
		serviceAvailability.scheduledJobTicket = data.scheduledJobTicket;
		serviceAvailability.scheduledJobTicketText = data.scheduledJobTicketText;
		serviceAvailability.hasMassiveTobiTicket = data.hasMassiveTobiTicket;
		serviceAvailability.massiveTobiTicketText = data.massiveTobiTicketText;
		serviceAvailability.massiveTicketText = data.massiveTicketText;

		return serviceAvailability;
	}
	getTicketTemplate(customerAccountId: string, newTicketObject: OpenNewTicket): Observable<Template> {
		return this.settingData.getTicketTemplate(customerAccountId, newTicketObject).pipe(
			map((res: Template) => {
				this.template = new Template();
				this.template.status = res.status;
				this.template.taskId = res.taskId;
				this.template.form = res.form;
				this.template.backend = res.backend;
				this.template.symptom = res.symptom;
				this.template.technology = res.technology;
				this.template.deco = res.deco;
				this.template.ticketId = res.ticketId;
				return this.template;
			})
		);
	}
	ticketDetail(res: TicketModel): TicketModel {
		const ticketDetail = new TicketModel();
		ticketDetail.ticketId = res.ticketId;
		ticketDetail.title = res.title;
		ticketDetail.averia = res.averia;
		ticketDetail.ticketName = res.ticketName;
		ticketDetail.ticketType = res.ticketType;
		ticketDetail.status = res.status;
		ticketDetail.serviceId = res.serviceId;
		ticketDetail.creationDate = res.creationDate;
		ticketDetail.icon = res.icon;
		ticketDetail.attachment = res.attachment;
		ticketDetail.isGeneralBreakDown = res.isGeneralBreakDown;
		ticketDetail.showTicketDetail = false;
		ticketDetail.showCloseOpen = false;
		ticketDetail.closingDate = res.closingDate;
		ticketDetail.journey = res.journey;
		ticketDetail.chatRecorded = res.chatRecorded;
		ticketDetail.template = {};
		ticketDetail.template.templateCode = res.template?.templateCode;
		ticketDetail.template.templateId = res.template?.templateId;
		ticketDetail.template.templateDate = res.template?.templateDate;
		ticketDetail.template.visitFrom = res.template?.visitFrom;
		ticketDetail.template.visitTo = res.template?.visitTo;
		ticketDetail.template.outSLA = res.template?.outSLA;
		ticketDetail.caseBE = {};
		ticketDetail.caseBE.caseId = res.caseBE?.caseId;
		ticketDetail.caseBE.status = res.caseBE?.status;
		ticketDetail.caseBE.channelId = res.caseBE?.channelId;
		ticketDetail.caseBE.agentName = res.caseBE?.agentName;
		ticketDetail.caseBE.postId = res.caseBE?.postId;
		ticketDetail.caseBE.createdAt = res.caseBE?.createdAt;
		ticketDetail.caseBE.statusUpdatedAt = res.caseBE?.statusUpdatedAt;
		return ticketDetail;
	}
	updateStatus(ticketId, status) {
		return this.settingData.updateTicketStatus(this.selectedSiteId, ticketId, status).pipe(
			map((res: any) => {
				return this.ticketDetail(res);
			})
		);
	}
	setInteractionForMonitoring(
		customerAccountId: string,
		interactionsId: string,
		serviceType: string,
		backend: string
	): Observable<string> {
		return this.settingData.sendInteractions(customerAccountId, interactionsId, serviceType, backend);
	}
	setInteractionForMonitoringSLA(
		customerAccountId: string,
		interactionsId: string,
		ticketId: string,
		ticketName: string,
		creationDate: string,
		sendCustomerAccountId: boolean
	): Observable<string> {
		return this.settingData.sendInteractionsSLA(
			customerAccountId,
			interactionsId,
			ticketId,
			ticketName,
			creationDate,
			sendCustomerAccountId
		);
	}
	ticketStatus(ticketstatus) {
		let title;
		this.translate.get('faultManagement.messagesList').subscribe((data) => {
			if (ticketstatus === TicketStatus.Open) {
				title = data.faultInProgressStatus.faultInProgressStatus_description;
			} else {
				title = data.faultSolvedStatus.faultSolvedStatus_description;
			}
		});
		return title;
	}
	setServiceCategory(_services?: ServiceModel[]): ServiceModel[] {
		_services.forEach((service: ServiceModel) => {
			switch (service.type.toLowerCase()) {
				case ServiceType.Prepaid.toLowerCase():
				case ServiceType.Postpaid.toLowerCase():
				case ServiceType.MbbPrepaid.toLowerCase():
					this.checkServiceOngoingOrder(service, { ...new ServiceAvailablityType(), isMobile: true });
					service.serviceCategory = FaultManagmentTicketType.MOVIL;
					break;
				case ServiceType.MbbPostpaid.toLowerCase(): {
					if (service.tarrifCode === home5G.tariffCode) {
						this.checkServiceOngoingOrder(service, { ...new ServiceAvailablityType(), isFixed: true });
						service.serviceCategory = FaultManagmentTicketType.FIXED;
					} else {
						this.checkServiceOngoingOrder(service, { ...new ServiceAvailablityType(), isMobile: true });
						service.serviceCategory = FaultManagmentTicketType.MOVIL;
					}
					break;
				}
				case ServiceType.Fixed.toLowerCase():
				case ServiceType.VodafoneEnTuCasa.toLowerCase():
				case ServiceType.Landline.toLowerCase():
					this.checkServiceOngoingOrder(service, { ...new ServiceAvailablityType(), isFixed: true });
					service.serviceCategory = FaultManagmentTicketType.FIXED;
					break;
				case ServiceType.ADSL.toLowerCase():
				case ServiceType.Fibre.toLowerCase():
				case ServiceType.Internet.toLowerCase():
					this.checkServiceOngoingOrder(service, { ...new ServiceAvailablityType(), isInternet: true });
					service.serviceCategory = FaultManagmentTicketType.FIXED;
					break;
				case ServiceType.Tv.toLowerCase():
					this.checkServiceOngoingOrder(service, { ...new ServiceAvailablityType(), isTv: true });
					service.serviceCategory = FaultManagmentTicketType.FIXED;
					break;
			}
		});
		this.services = _services;
		return _services;
	}
	getOpenTicketForCategries(): void {
		this.openServicePerCategory.movil = '';
		this.openServicePerCategory.fixed = '';
		this.openServicePerCategory.default = '';
		this.tickets.forEach((ticket: TicketModel) => {
			if (
				(ticket.template?.templateCode && ticket.template?.templateCode?.includes(TicketTemplateCode.GARANTIA)) ||
				ticket.status === TicketStatus.Open
			) {
				if (ticket.ticketType === FaultManagmentTicketType.MOVIL && !this.openServicePerCategory.movil) {
					this.openServicePerCategory.movil = ticket.serviceId || ticket.ticketId;
				} else if (ticket.ticketType === FaultManagmentTicketType.FIXED && !this.openServicePerCategory.fixed) {
					this.openServicePerCategory.fixed = ticket.serviceId || ticket.ticketId;
				} else if (ticket.ticketType === FaultManagmentTicketType.DEFAULT && !this.openServicePerCategory.default) {
					this.openServicePerCategory.default = ticket.serviceId || ticket.ticketId;
				}
			}
		});
	}
	getClosedTicketForCategriesAndOpenChat(): void {
		this.closedServicePerCategory.movil = [];
		this.closedServicePerCategory.fixed = [];
		this.closedServicePerCategory.default = [];
		this.tickets.forEach((ticket) => {
			if (ticket.template?.templateCode?.includes(TicketTemplateCode.GARANTIA)) return;
			if (
				[TicketStatus.Closed.toString(), TicketStatus.Pending.toString()].includes(ticket.status) &&
				ticket.averia &&
				!ticket.isGeneralBreakDown
			) {
				if (ticket.ticketType === FaultManagmentTicketType.MOVIL) {
					this.closedServicePerCategory.movil.push(ticket.serviceId);
				} else if (ticket.ticketType === FaultManagmentTicketType.FIXED) {
					this.closedServicePerCategory.fixed.push(ticket.serviceId);
				} else if (ticket.ticketType === FaultManagmentTicketType.DEFAULT) {
					this.closedServicePerCategory.default.push(ticket.serviceId);
				}
			}
		});
	}

	private handleOpenServiceTicket(service: ServiceModel, servicePerCategory: string): void {
		if (this.openServicePerCategory[servicePerCategory] === service.id) service.hasBlueLabelOpenTicket = true;
		else service.disabled = true;

		service.openTicketId = this.openServicePerCategory[servicePerCategory];
	}

	private handleClosedServiceTicket(service: ServiceModel, servicePerCategory: string): void {
		for (let ser of this.closedServicePerCategory[servicePerCategory]) {
			if (ser === service.id) service.hasBlueLabelClosedTicket48Hrs = true;
		}
	}

	dimedServices(): void {
		this.services?.forEach((service: ServiceModel) => {
			if (this.openServicePerCategory.default) {
				this.handleOpenServiceTicket(service, constants.defaultType);
			} else if (this.openServicePerCategory.fixed && service.serviceCategory === FaultManagmentTicketType.FIXED) {
				this.handleOpenServiceTicket(service, constants.fixed);
			} else if (this.openServicePerCategory.movil && service.serviceCategory === FaultManagmentTicketType.MOVIL) {
				this.handleOpenServiceTicket(service, constants.movil);
			}
		});
	}
	closedServices(): void {
		this.services?.forEach((service: ServiceModel) => {
			if (this.closedServicePerCategory.default.length > 0) {
				this.handleClosedServiceTicket(service, constants.defaultType);
			} else if (
				this.closedServicePerCategory.fixed.length > 0 &&
				service.serviceCategory === FaultManagmentTicketType.FIXED
			) {
				this.handleClosedServiceTicket(service, constants.fixed);
			} else if (
				this.closedServicePerCategory.movil.length > 0 &&
				service.serviceCategory === FaultManagmentTicketType.MOVIL
			) {
				this.handleClosedServiceTicket(service, constants.movil);
			}
		});
	}
	checkServiceOngoingOrder(service: ServiceModel, serviceAvailablityType: ServiceAvailablityType) {
		if (this.serviceAvailability?.hasOrderTicket) {
			if (this.serviceAvailability?.isMobile && serviceAvailablityType.isMobile) {
				service.hasOnGoingOrder = true;
			} else if (this.serviceAvailability?.isFixed && serviceAvailablityType.isFixed) {
				service.hasOnGoingOrder = true;
			} else if (this.serviceAvailability?.isInternet && serviceAvailablityType.isInternet) {
				service.hasOnGoingOrder = true;
			} else if (this.serviceAvailability?.isTV && serviceAvailablityType.isTv) {
				service.hasOnGoingOrder = true;
			}
		}
	}

	updateServiceSelectorModel(
		service: ServiceModel,
		hasbluelabelcerrada: boolean,
		hasblueLabelabierta: boolean,
		goToTicket: boolean
	): void {
		const itemImageCardInd: number = this.cardImageSelectorList?.findIndex((card) => card.id === service.id);
		if (itemImageCardInd < 0) return;
		if (goToTicket) {
			this.cardImageSelectorList[itemImageCardInd].goToTicket = true;
		} else if (hasblueLabelabierta) {
			this.cardImageSelectorList[itemImageCardInd].blueLabel = this.translate.instant(
				'faultManagement.itemsList.openTicketAlert2.body'
			);
		} else if (hasbluelabelcerrada) {
			this.cardImageSelectorList[itemImageCardInd].blueLabel = this.translate.instant(
				'v10.faultManagement.contentList.closeTicketAlert.text'
			);
		}
	}

	mapServicesToSelectorModel() {
		this.cardImageSelectorList = [];
		const closedTicketBlueLabelText: string = this.translate.instant(
			'v10.faultManagement.contentList.closeTicketAlert.text'
		);
		this.services.forEach((service: ServiceModel) => {
			let itemImageCard: CardServiceImageSelectorModel = new CardServiceImageSelectorModel();
			itemImageCard = this.mapServiceSelector(service, service.type);
			this.translate.get('faultManagement.itemsList').subscribe((text) => {
				if (service.hasBlueLabelOpenTicket) {
					itemImageCard.blueLabel = text.openTicketAlert2.body;
				} else if (service.hasBlueLabelClosedTicket48Hrs) {
					itemImageCard.blueLabel = closedTicketBlueLabelText;
				} else if (service.disabled) {
					itemImageCard.disabled = true;
				}
			});
			this.cardImageSelectorList.push(itemImageCard);
		});
	}

	resetSelectedSite_service() {
		this.selectedSiteId = '';
		this.serviceAvailability = new ServiceAvailability();
		this.selectedService = new ServiceModel();
		this.userSiteSubj.next([]);
		this.tickets = [];
	}
	mapServiceSelector(item: ServiceModel, type: ServiceType): CardServiceImageSelectorModel {
		const itemImageCard: CardServiceImageSelectorModel = new CardServiceImageSelectorModel();
		this.translate.get('v10').subscribe((text) => {
			switch (type.toLowerCase()) {
				case ServiceType.Postpaid.toLowerCase():
					itemImageCard.title = text.dashboard.overlay_outrate.mobile.postpaid;
					itemImageCard.description = item.id;
					itemImageCard.image = this.appService.getImgFullPath(text.dashboard.images.product_selector.mobile);
					itemImageCard.id = item.id;
					break;
				case ServiceType.MbbPostpaid.toLowerCase(): {
					itemImageCard.id = item.id;
					if (item.tarrifCode === home5G.tariffCode) {
						itemImageCard.title = text.faultManagement.itemsList.home5g.body;
						itemImageCard.image = this.appService.getImgFullPath(text.dashboard.images.product_selector.hogar5g);
					} else if (item.tarrifCode === secondResidences.tariffCode) {
						itemImageCard.title = text.commercial.secondResidences.bonuses.description;
						itemImageCard.description = item.id;
						itemImageCard.image = this.appService.getImgFullPath(text.dashboard.images.product_selector.mbb);
					} else {
						itemImageCard.title = text.dashboard.overlay_outrate.mbb.postpaid;
						itemImageCard.description = item.id;
						itemImageCard.image = this.appService.getImgFullPath(text.dashboard.images.product_selector.mbb);
					}
					break;
				}
				case ServiceType.MBB_Holiday.toLowerCase():
					itemImageCard.title = text.dashboard.overlay_outrate.mbb.postpaid;
					itemImageCard.description = item.id;
					itemImageCard.image = this.appService.getImgFullPath(text.dashboard.images.product_selector.mbb);
					itemImageCard.id = item.id;
					break;
				case ServiceType.Prepaid.toLowerCase():
					itemImageCard.title = text.dashboard.overlay_outrate.mobile.prepaid;
					itemImageCard.description = item.id;
					itemImageCard.image = this.appService.getImgFullPath(text.dashboard.images.product_selector.mobile);
					itemImageCard.id = item.id;
					break;
				case ServiceType.MbbPrepaid.toLowerCase():
					itemImageCard.title = text.dashboard.overlay_outrate.mbb.prepaid;
					itemImageCard.description = item.id;
					itemImageCard.image = this.appService.getImgFullPath(text.dashboard.images.product_selector.mbb);
					itemImageCard.id = item.id;
					break;
				case ServiceType.Landline.toLowerCase():
				case ServiceType.VodafoneEnTuCasa.toLowerCase():
					itemImageCard.title = text.common.literals.phone_C;
					itemImageCard.description = item.id;
					itemImageCard.image = this.appService.getImgFullPath(text.dashboard.images.product_selector.landline);
					itemImageCard.id = item.id;
					break;
				case ServiceType.Tv.toLowerCase():
				case ServiceType.TV_online.toLowerCase():
					itemImageCard.title = text.common.literals.tv_C;
					itemImageCard.image = this.appService.getImgFullPath(text.dashboard.images.product_selector.tv);
					itemImageCard.id = item.id;
					break;
				case ServiceType.ADSL.toLowerCase():
					itemImageCard.title = text.common.literals.adsl;
					itemImageCard.image = this.appService.getImgFullPath(text.dashboard.images.product_selector.fibre_adsl);
					itemImageCard.id = item.id;
					break;
				case ServiceType.Fibre.toLowerCase():
					itemImageCard.title = text.common.literals.fiber_C;
					itemImageCard.image = this.appService.getImgFullPath(text.dashboard.images.product_selector.fibre_adsl);
					itemImageCard.id = item.id;
					break;
				case ServiceType.Others.toLowerCase():
					itemImageCard.title = text.faultManagement.contentList.dynamicLanding.FE.body;
					itemImageCard.image = this.appService.getImgFullPath(text.faultManagement.images.FE.png.url);
					itemImageCard.id = item.id;
					break;
			}
			itemImageCard.checked = false;
		});
		return itemImageCard;
	}
	addCloseRopenBtnsToTickets() {
		this.getOpenTicketForCategries();
		this.tickets.forEach((ticket) => {
			if (ticket.status === TicketStatus.Open && !ticket.isGeneralBreakDown) {
				ticket.showCloseOpen = true;
			} else if (
				ticket.status === TicketStatus.Closed &&
				!ticket.isGeneralBreakDown &&
				!this.checkOpenDefaultORSameType(ticket.ticketType) &&
				this.isClosedLessThan24Hours(ticket.closingDate)
			) {
				ticket.showCloseOpen = true;
			} else {
				ticket.showCloseOpen = false;
			}
		});
	}
	checkOpenDefaultORSameType(ticketType: string): string {
		return (
			this.openServicePerCategory.default ||
			(ticketType.toLowerCase() === FaultManagmentTicketType.MOVIL.toLowerCase() &&
				this.openServicePerCategory.movil) ||
			(ticketType.toLowerCase() === FaultManagmentTicketType.FIXED.toLowerCase() &&
				this.openServicePerCategory.fixed) ||
			(ticketType.toLowerCase() === FaultManagmentTicketType.DEFAULT.toLowerCase() &&
				(this.openServicePerCategory.fixed || this.openServicePerCategory.movil))
		);
	}
	isClosedLessThan24Hours(closeDate: any) {
		const date1 = new Date(closeDate);
		const timeStamp = Math.round(new Date().getTime() / 1000);
		const timpStampYesterday = timeStamp - 24 * 3600;
		const is24 = date1.getTime() >= new Date(timpStampYesterday * 1000).getTime();
		return is24;
	}
	setUserSites(sites: CustomerAccount[]): void {
		this.userSiteSubj.next(sites);
	}
	get selectedSiteAddress(): string {
		if (!this.selectedSiteId) return '';
		const sites: CustomerAccount[] = this.userSiteSubj.getValue();
		return sites.find((site) => site.id === this.selectedSiteId)?.address?.formattedAddress || '';
	}
}
