import { UserSettingToken } from '../enums/user-setting-token.enum';

export class ChatBotTokenRequest {
	chatUrl?: string;
	originWeb?: string;
	segment?: string;
	microCompanySegment?: string;
	rsYUSegment?: string;
	rsSegment?: string;
	grantType: string;
	clientId: string;
	subjectToken?: string;
	subjectTokenType: string;
	actorTokenType: string;
	actorToken: string;
	chatbotScope?: string;
}

export class ChatBotTokenBodyRequest {
	attributes: {
		name: UserSettingToken;
		params?: {
			selectedSite?: string;
			selectedService?: string;
			chatBotToken?: string;
			origin?: string;
			segment?: string;
			averia?: string;
			ticketId?: string;
			journey?: string;
			orderId?: string;
			email?: string;
			msisdn?: string;
			originSection?: string;
			tipo_flujo?: string;
			paso_flujo?: string;
			motivo_soporte?: string;
		};
	}[];
}
