import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { throwError } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { PAGES } from '../../shared/constants/defines';
import { Error } from '../../shared/models/error.model';
import { Notification } from '../../shared/models/notification.model';
import { NotificationService } from '../../shared/services/notification.service';
import { RetryService } from '../../shared/services/retry.service';
import { ErrorHandlingService } from './../../core/services/errorHandling.service';
import { CustomerAccountService } from './../../shared/services/customer-account.service';

@Injectable()
export class PermissionsAndPreferencesResolver {
	permissionError: Error;
	constructor(
		private customerAccountService: CustomerAccountService,
		private errorHandling: ErrorHandlingService,
		private notificationService: NotificationService,
		private retryService: RetryService
	) {}
	resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
		return this.customerAccountService.getPermissionsAndPreferences().pipe(
			map(() => {
				return true;
			}),
			catchError((error) => {
				// set tagging error Variables
				const notificationError = new Notification();
				this.permissionError = this.errorHandling.lookUpError(PAGES.MY_ACCOUNT, error);
				if (this.permissionError) {
					notificationError.bodyContent = this.permissionError.description;
					notificationError.bodyTitle = this.permissionError.title;
					notificationError.primaryButtonText =
						this.permissionError.dismissButtonText || this.permissionError.confirmButtonText;
					notificationError.showRetryButton = this.permissionError.retryButton;
					this.retryService.url = state.url;
					notificationError.primaryButtonClick = () => {
						this.notificationService.notificationModal.hide();
					};
					this.notificationService.createNotification(notificationError);
				}

				return throwError(null);
			})
		);
	}
}
