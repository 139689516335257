import { Component, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { tagging } from '../../../../../../config/tagging-config';
import { AppService } from '../../../../../app.service';
import { TaggingHelperService } from '../../../../../core/services/tagging.helper.service';
import { BIZTALKERROR, contactUsDefaultMapping } from '../../../../constants/defines';
import { ContactUsService } from '../../../../services/contact-us.service';

@Component({
	selector: 'sp-contact-us',
	templateUrl: './contact-us.component.html',
	styleUrls: ['./contact-us.component.scss'],
})
export class ContactUsComponent implements OnInit {
	showContent: boolean = true;
	showError: Boolean = false;
	showLoading: Boolean = false;
	showEmailError: boolean = false;
	showSucces: boolean = false;
	showItems: boolean;
	selectedSection: string;
	public editForm: UntypedFormGroup;
	successIconSrc: string = '';
	errorIconSrc: string = '';
	sections: any[];
	constructor(
		private formBuilder: UntypedFormBuilder,
		private appService: AppService,
		private translate: TranslateService,
		private taggingHelper: TaggingHelperService,
		private contactUsService: ContactUsService,
		private tealium: TaggingHelperService,
		private router: Router
	) {
		this.editForm = formBuilder.group({
			message: [''],
			email: ['', Validators.required],
		});
	}

	ngOnInit() {
		this.loadWcs();
	}

	retry() {
		this.showError = false;
		this.showContent = true;
	}
	send() {
		if (this.editForm.valid && this.selectedSection) {
			this.appService.showFullAppLoader = true;
			const email = this.editForm.controls['email'].value;
			const message = this.editForm.controls['message'].value;
			this.contactUsService.sendContactUsRequest(this.selectedSection, message, email).subscribe(
				(data) => {
					this.showContent = false;
					this.appService.showFullAppLoader = false;
					this.showSucces = true;
					const taggingVariabels = { ...tagging.contactUsScreen };
					this.taggingHelper.track(
						taggingVariabels.btnSend.data.event_name,
						taggingVariabels.btnSend.data,
						taggingVariabels.view
					);
				},
				(err) => {
					if (err.error && err.error.ecode && err.error.ecode.toString() === BIZTALKERROR) {
						this.appService.NudgeBiztalkError();
						const taggingVariabels = { ...tagging.contactUsScreen };
						this.taggingHelper.track(
							taggingVariabels.btnSend.data.event_name,
							taggingVariabels.btnSend.data,
							taggingVariabels.view
						);
					} else {
						this.showContent = false;
						this.appService.showFullAppLoader = false;
						this.showError = true;
						const taggingVariabels = { ...tagging.contactUsScreen };
						this.taggingHelper.track(
							taggingVariabels.btnSend.data.event_name,
							taggingVariabels.btnSend.data,
							taggingVariabels.view
						);
					}
				}
			);
		}
	}
	validateEmail() {
		if (this.editForm.controls['email'].errors) {
			this.showEmailError = true;
		}
	}

	selectSection(Section) {
		this.showItems = false;
		this.selectedSection = Section;
	}
	loadWcs() {
		this.translate.get('common.contactUs').subscribe((data) => {
			this.successIconSrc = this.appService.getImgFullPath(
				data.messagesList.contactSendOverlay.contactSendOverlay_icon.url
			);
			this.errorIconSrc = this.appService.getImgFullPath(
				data.messagesList.contactWarningOverlay.contactWarningOverlay_icon.url
			);
			this.getSectionsMapSelectedSecion(data);
		});
	}
	getSectionsMapSelectedSecion(wcs) {
		this.sections = wcs ? wcs.itemsList.contactUsDropDownList.dropdownValues : [];
		const currentPage = this.tealium.getCurrentPage(this.router.url);
		const partialURL =
			currentPage && currentPage.page_name ? currentPage.page_name.split(' ').join('_').split(':').join('/') : '';
		if (wcs && wcs.itemsList && wcs.itemsList[partialURL]) {
			this.selectedSection = wcs.itemsList[partialURL].body;
		} else {
			this.selectedSection = wcs.itemsList[contactUsDefaultMapping.key].body;
		}
	}
	closeBubble() {
		this.appService.closeNeedHelpBubble.next();
	}
}
