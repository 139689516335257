import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { BehaviorSubject } from 'rxjs';
import { StorageService } from '../../core/services/storage.service';
import { SubscriptionService } from '../../core/services/subscription.service';
import { Migration, TicketTemplates } from '../constants/defines';
import { CustomerType } from '../enums/customerType.enum';
import { CampaignPromotion } from '../models/campaign-promotion.model';
import { SpecialPromotionModel } from '../models/special-promotion.model';
import { CustomerAccountService } from './customer-account.service';

@Injectable()
export class CampaignPromotionsService {
	public campaignPromotions: CampaignPromotion[] = [];
	public segment: string;
	public promoPlan: string;
	public promoSegment: string;
	public promoName: string;
	public addressClient: string;
	private selectedPromotion: CampaignPromotion;
	private selectedPromotionSubject = new BehaviorSubject({});
	public selectedPromotion$ = this.selectedPromotionSubject.asObservable();

	constructor(
		private customerAccountService: CustomerAccountService,
		private translate: TranslateService,
		private subscriptionService: SubscriptionService,
		private storageService: StorageService
	) {}

	public setSegment(segment: string): void {
		this.segment = segment;
	}

	public setPromoPlan(promoPlan: string): void {
		this.promoPlan = promoPlan;
	}

	public setPromoSegment(promoSegment: string): void {
		this.promoSegment = promoSegment;
	}

	public setPromoName(promoName: string): void {
		this.promoName = promoName;
	}
	/**
	 * Loads promotions from WCS
	 */
	public loadCampaignPromotions(specialPromotion: SpecialPromotionModel) {
		this.setSegment(specialPromotion.segment);
		this.setPromoPlan(specialPromotion.promoPlan);
		this.setPromoSegment(specialPromotion.promoSeg);
		this.setPromoName(specialPromotion.promoName);

		this.selectedPromotion = new CampaignPromotion();
		this.translate.get(`v10.promotions.${this.segment}.products`).subscribe(
			(data) => {
				if (typeof data === 'object') {
					data.forEach((promotion) => {
						this.campaignPromotions.push(this.jsonToCampaignPromotionsModel(promotion));
					});
				}
			},
			(err) => this.campaignPromotions.push(this.selectedPromotion)
		);

		this.getAddress();
	}
	/**
	 * Transforms a promotion to CampaignPromotion model
	 * @param promotion any promotion from WCS json response
	 */
	public jsonToCampaignPromotionsModel(promotion: any) {
		const model = new CampaignPromotion();
		model.billsAttr = promotion.billsAttr || null;
		model.attrs = promotion.attrs || null;
		model.moreInfoAttrs = promotion.moreInfoAttrs || null;
		model.alertInfoAttr = promotion.alertInfoAttr || null;
		model.serialNumber = promotion.serialNumber || null;
		model.brandName = promotion.brandName || null;
		model.name = promotion.name || null;
		model.promoCondition = promotion.promoCondition || null;
		model.promoConditionHighlight = promotion.promoConditionHighlight || null;
		model.shortDescription = promotion.shortDescription || null;
		model.legalConditionsText = promotion.legalConditionsText || null;
		model.legalConditionsLinkText = promotion.legalConditionsLinkText || null;
		model.imagenPrp = promotion.imagenPrp || null;
		model.imagenDetll = promotion.imagenDetll || null;
		model.color = promotion.color || null;
		model.buttonText = promotion.buttonText || null;
		model.moreInfoTitle = promotion.moreInfoTitle || null;
		model.ticketDescription = promotion.ticketDescription || null;
		return model;
	}
	/**
	 * Method to select one promotion
	 * @param serialNumber {string} promotion serialNumber
	 */
	public selectPromotion(serialNumber: string) {
		this.selectedPromotion = this.campaignPromotions.find((promo) => promo.serialNumber === serialNumber);
		this.selectedPromotionSubject.next(this.selectedPromotion);
	}
	/**
	 * Method to generate the ticket body
	 */
	public generateTicketBody(): any {
		let template;
		this.translate.get(`v10.promotions.${this.segment}`).subscribe((data) => {
			template = {
				ticketType: data.troubleTicket_ticketType,
				description: this.getTicketDescription(),
				severity: data.troubleTicket_severity,
				relatedParty: [
					{
						id: this.subscriptionService.customerData.customerAccountsId,
					},
				],
				note: [
					{
						id: Migration.Ticket.FaultyCode.key,
						text:
							this.storageService.userProfile.customerType.toLowerCase() === CustomerType.Consumer.toLocaleLowerCase()
								? data.troubleTicket_faultyCodeRS
								: data.troubleTicket_faultyCodeME,
					},
					{
						id: Migration.Ticket.Triplet.key,
						text: data.troubleTicket_tripletCode,
					},
					{
						id: Migration.Ticket.ContactMethod.key,
						text: data.troubleTicket_contactMethod,
					},
				],
			};
		});
		return template;
	}
	/**
	 * Method to generate the ticket body description
	 */
	private getTicketDescription() {
		return `
  ${TicketTemplates.CampaignPromotion.siteId}: ${this.subscriptionService.customerData.customerAccountsId}
  ${TicketTemplates.CampaignPromotion.msisdn}: ${this.subscriptionService.customerData.currentService.id}
  ${TicketTemplates.CampaignPromotion.address}: ${this.addressClient}
  ${TicketTemplates.CampaignPromotion.tariffCode}: ${this.subscriptionService.customerData.currentService.tarrifCode}
  ${TicketTemplates.CampaignPromotion.mail}: ${this.storageService.userProfile.email}
  ${TicketTemplates.CampaignPromotion.nif}: ${this.storageService.userProfile.document.id}
  ${TicketTemplates.CampaignPromotion.date}: ${new Date()}
  ${this.selectedPromotion.ticketDescription}`;
	}
	/**
	 * Method to prepare data for documentum request
	 */
	public getDocumentumData() {
		return {
			siteId: this.subscriptionService.customerData.customerAccountsId,
			nif: this.storageService.userProfile.document.id,
			ticket: { description: this.getTicketDescription() },
		};
	}
	/**
	 * Method to get client address
	 */
	getAddress() {
		this.customerAccountService
			.GetCustomerAccountById(this.subscriptionService.customerData.currentService.siteId)
			.subscribe(() => {
				if (
					this.customerAccountService.customerAccount.address &&
					this.customerAccountService.customerAccount.address.formattedAddress
				) {
					this.addressClient = this.customerAccountService.customerAccount.address.formattedAddress;
				} else if (this.customerAccountService.customerAccount.address) {
					this.addressClient = this.customerAccountService.customerAccount.address.street
						? this.customerAccountService.customerAccount.address.street
						: '';
					this.addressClient =
						this.addressClient +
						(this.customerAccountService.customerAccount.address.buildingNo
							? ' ' + this.customerAccountService.customerAccount.address.buildingNo
							: '');
					this.addressClient =
						this.addressClient +
						(this.customerAccountService.customerAccount.address.level
							? ', ' + this.customerAccountService.customerAccount.address.level
							: '');
					this.addressClient =
						this.addressClient +
						(this.customerAccountService.customerAccount.address.postcode
							? '.  ' + this.customerAccountService.customerAccount.address.postcode
							: '');
					this.addressClient =
						this.addressClient +
						(this.customerAccountService.customerAccount.address.town
							? ',  ' + this.customerAccountService.customerAccount.address.town
							: '');
				}
			});
	}
}
