import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { Observable, Subject } from 'rxjs';
import { catchError, map, switchMap } from 'rxjs/operators';
import { AppService } from '../../app.service';
import { StorageService } from '../../core/services/storage.service';
import { PaymentNavigationService } from '../../payment/services/payment-navigation.service';
import { MasterCardFirstDigit, VisaFirstDigit, pagoRecurrenteF } from '../constants/defines';
import { PaymentPages } from '../enums/payment-pages.enum';
import { PaymentTrayAction } from '../enums/payment-tray-action.enum';
import { PaymentCardModel, PaymentCardPatchModel } from '../models/payment-card.model';
import { PaymentCardsDataService } from './payment-card-data.service';
import { SpMva10OverlayService } from './sp-mva10-overlay.service';

@Injectable()
export class PaymentWalletService {
	//#region Declarations
	private _cardsList: Array<PaymentCardModel>;
	private _selectedCard: PaymentCardModel;
	private _visaImageUrl: string;
	private _masterImageUrl: string;
	private _otherImageUrl: string;
	private _otherCardText: string;
	private _principalTitleText: string;
	private _principalActionText: string;
	private _deleteActionText: string;

	$cardsListChangedSubject: Subject<Array<PaymentCardModel>> = new Subject<Array<PaymentCardModel>>();
	$selectedCardChangedSubject: Subject<PaymentCardModel> = new Subject<PaymentCardModel>();
	$savedCreditCardStatus: Subject<boolean> = new Subject<boolean>();
	$deletedCreditCard: Subject<boolean> = new Subject<boolean>();
	txId_COF?: string;
	pagoRecurrente?: string;

	//#endregion

	constructor(
		private paymentCardDataService: PaymentCardsDataService,
		private storageService: StorageService,
		public trayService: SpMva10OverlayService,
		private translate: TranslateService,
		private paymentNavigationService: PaymentNavigationService,
		private appService: AppService
	) {
		this.paymentNavigationService.changePaymentCompSubject.subscribe(({ actionNeeded }) => {
			if (actionNeeded === PaymentTrayAction.forceClose) {
				this._cardsList = null;
				this._selectedCard = null;
				this._visaImageUrl = null;
				this._masterImageUrl = null;
				this._otherImageUrl = null;
				this._otherCardText = null;
				this._principalTitleText = null;
				this._principalActionText = null;
				this._deleteActionText = null;
			}
		});
	}

	//#region Setters & Getters

	/** Selected Card */
	setSelectedCard(card: PaymentCardModel): void {
		this._selectedCard = card;
		this.$selectedCardChangedSubject.next(card);
	}
	get selectedCard(): PaymentCardModel {
		return this._selectedCard;
	}

	get cardsList(): Array<PaymentCardModel> {
		return this._cardsList;
	}

	/** Principal Card */
	get principalCard(): PaymentCardModel {
		return this.cardsList.find((card) => card.principle);
	}

	/** Principal Text */
	get principalTitleText(): string {
		return this._principalTitleText;
	}

	/** Principal Action Text */
	get principalAction(): string {
		return this._principalActionText;
	}

	/** Delete Action Text */
	get deleteAction(): string {
		return this._deleteActionText;
	}

	/** Other Wallet Image URL */
	get otherImageUrl(): string {
		return this._otherImageUrl;
	}

	/** Other Wallet Text */
	get otherCardText(): string {
		return this._otherCardText;
	}

	/** Menu Actions (Make Principal & Delete Actions) */
	get menuActions(): string[] {
		return [this.principalAction, this.deleteAction];
	}

	/** Method for getting text and icon of selected card or other card for wallet chevron component */
	get selectedCardTextAndIcon(): Observable<any> {
		this.checkAPIAndGetSelectedData();
		/** to be executed after return selectedCardChangedSubject line (for the subscribers) */
		return this.$selectedCardChangedSubject;
	}

	/** Selected Card Text */
	get selectedCardText(): string {
		return this.selectedCardTextOrIcon(this._otherCardText, this.selectedCard ? this.selectedCard.cardMask : null);
	}
	/** Selected Card Icon */
	get selectedCardIcon(): string {
		return this.selectedCardTextOrIcon(this._otherImageUrl, this.selectedCard ? this.selectedCard.cardImage : null);
	}

	//#region Private Getters

	/** Check if API loaded then return the selected (text & icon), otherwise wait for loading the API and return the same data */
	private checkAPIAndGetSelectedData(): void {
		if (this.selectedCard || this.isNewCardSelected) {
			setTimeout(() => {
				this.$selectedCardChangedSubject.next();
			}, 200);
		} else {
			this.fillPaymentCards().subscribe();
		}
	}

	/** Selected Card Text or Icon */
	private selectedCardTextOrIcon(otherTextOrIcon: string, cardTextOrIcon: string): string {
		let result: string = null;
		if (this.isNewCardSelected) {
			/** Here will return text or icon URL of (Usar nueva tarjeta)  */
			result = otherTextOrIcon;
		} else if (this.selectedCard) {
			/** Here will return text or icon URL of the selected card */
			result = cardTextOrIcon;
		}
		return result;
	}

	/** isNewCardSelected */
	private get isNewCardSelected(): boolean {
		/** if there is at least one card in the list and selectedCard is undefined,
		 * so the new card (Usar nueva tarjeta) option is selected from wallet screen */
		return this.cardsList && this.cardsList.length && !this.selectedCard;
	}

	/** Document ID of Current User */
	private get documentID(): string {
		return this.storageService.userProfile?.document?.id;
	}

	//#endregion

	//#endregion

	//#region CRUD Operations

	/**
	 * Method for refresh and re-fill local cards list
	 */
	fillPaymentCards(): Observable<void> {
		return this.paymentCardDataService.getPaymentCardsByDocumentId(this.documentID).pipe(
			map((res: any) => {
				const cards: Array<PaymentCardModel> = new Array<PaymentCardModel>();

				if (res.items && res.items.length > 0) {
					res.items.forEach((card) => {
						cards.push(this.mapCardData(card));
					});
				}
				this._cardsList = !this.paymentNavigationService.isPayingEnergyDebt ? cards : null;
				this.fillNeededDataFromWCS();
				this.setImagesForEachCard();
				this.setPrincipalAsSelected();
				this.cardsListChanged();
			}),
			catchError((err) => {
				this._cardsList = new Array<PaymentCardModel>();
				this.$selectedCardChangedSubject.error(err);
				setTimeout(() => {
					this.$selectedCardChangedSubject = new Subject<PaymentCardModel>();
				}, 500);
				return [];
			})
		);
	}

	/**
	 * Method for add/edit card items (save & make principal)
	 * @param body the body of the new/edited card item
	 * @param uuid the unique identifier of the new/edited card item
	 * @param makeItRecurrent to be truthy when we updating saved credit card to be recurrent
	 */
	savePaymentCard(body: PaymentCardPatchModel, uuid?: string, makeItRecurrent?: boolean): Observable<void> {
		const isPrincipal: boolean = body.principle;
		const saveAPI: Observable<Object> = this.paymentCardDataService.patchPaymentCard(
			this.documentID,
			body,
			this.txId_COF,
			this.pagoRecurrente
		);

		return saveAPI.pipe(
			map((res) => {
				if (!makeItRecurrent) {
					if (isPrincipal) {
						/**
						 * if isPrincipal equals true --> then it's a patch for making some card to be principal which need to re-fill cardsList
						 * otherwise --> so we are adding a new one then no need for refreshing API to get the list,
						 *  instead we can save it locally
						 */
						const editedCard: PaymentCardModel = this.cardsList.find((card) => card.uuid === uuid);
						this.cardsList.splice(this.cardsList.indexOf(editedCard), 1);
						/** Update current principal card to be not principal */
						this.principalCard.principle = false;
						/** Set the current one to be principal */
						editedCard.principle = true;
						this.cardsList.splice(0, 0, editedCard);

						/** Check if we editing the selected one then we should reset it into selectedCard */
						if (uuid && uuid === this.selectedCard.uuid) {
							this.setPrincipalAsSelected();
						}
					} else {
						/**
						 * Save the card locally
						 * Map the new saved card and push it to the local cards list
						 */
						this.cardsList.push(this.mapCardData(res));
					}

					this.cardsListChanged();
				}
			})
		);
	}

	/**
	 * Method for deleting a card item
	 * @param uuid the unique identifier of the card
	 */
	deletePaymentCard(uuid: string): Observable<void> {
		const deletedCard: PaymentCardModel = this._cardsList.find((card) => card.uuid === uuid);
		const deleteAPI: Observable<Object> = this.paymentCardDataService.deletePaymentCard(this.documentID, uuid);

		if (deletedCard && deletedCard.principle) {
			/** Refresh API if deleted card is principal */
			return deleteAPI.pipe(switchMap(() => this.fillPaymentCards()));
		} else {
			/** Delete the card locally */
			return deleteAPI.pipe(
				map(() => {
					/** Check if this card is the selected card --> then set principal card to the selected card */
					if (this.selectedCard.uuid === deletedCard.uuid) {
						this.setPrincipalAsSelected();
					}
					/** Delete the card from cardsList */
					this._cardsList.splice(this._cardsList.indexOf(deletedCard), 1);

					this.cardsListChanged();
				})
			);
		}
	}

	//#endregion

	//#region Data Mapping and Logic

	/**
	 * Method for mapping the response from back-end to payment card model
	 * @param cardData the response that received from back-end
	 */
	mapCardData(cardData: any): PaymentCardModel {
		const card: PaymentCardModel = new PaymentCardModel();

		card.uuid = cardData.uuid || '';
		card.token = cardData.token || '';
		card.savedDate = cardData.savedDate || '';
		card.principle = cardData.principle || false;
		card.lastUsedDate = cardData.lastUsedDate || '';
		card.journey = cardData.journey || '';
		card.expiryDate = cardData.expiryDate || '';
		card.documentType = cardData.documentType || '';
		card.documentID = cardData.documentID || '';
		card.cardMask = cardData.cardMask || '';
		card.pagoRecurrente = cardData.pagoRecurrente || '';
		card.txID_COF = cardData.txId_COF || '';

		return card;
	}

	/**
	 * Find principal and set it to selected card
	 */
	private setPrincipalAsSelected(): void {
		// mina should call this method when exit from payment module and re enter it again to start a new journey
		this.setSelectedCard(this.principalCard);
	}

	/** Method for fire the cards list changed subject */
	private cardsListChanged(): void {
		this.$cardsListChangedSubject.next(this.cardsList);
	}

	/** Method for setting the images for cards list */
	private setImagesForEachCard(): void {
		this.cardsList.forEach((card) => (card.cardImage = this.getImageUrlOfCard(card.cardMaskData)));
	}

	/** Method for checking the card type and return its matched image URL */
	private getImageUrlOfCard(cardMask: string): string {
		let imageUrl: string = this._otherImageUrl;

		if (cardMask && cardMask[0] === VisaFirstDigit) {
			imageUrl = this._visaImageUrl;
		} else if (cardMask && cardMask[0] === MasterCardFirstDigit) {
			imageUrl = this._masterImageUrl;
		}

		return imageUrl;
	}

	//#endregion

	//#region WCS Data

	/** Method for filling needed data from WCS to be loaded one time */
	private fillNeededDataFromWCS(): void {
		this.translate.get(this.paymentNavigationService.getWcsPath(PaymentPages.wallet)).subscribe((data) => {
			this.fillImagesUrls(data);
			this.fillPrincipalText(data);
			this.fillOtherCardText(data);
			this.setMenuActions(data);
		});
	}

	/** Method for setting all cards images URLs */
	private fillImagesUrls(data: any): void {
		this._visaImageUrl = this.appService.getImgFullPath(data.visaIcon);
		this._masterImageUrl = this.appService.getImgFullPath(data.masterCardIcon);
		this._otherImageUrl = this.appService.getImgFullPath(data.otherIcon);
	}

	/** Method for setting Principal card title */
	private fillPrincipalText(data: any): void {
		this._principalTitleText = data.principal;
	}

	/** Method for setting other card card title */
	private fillOtherCardText(data: any): void {
		this._otherCardText = data.newCreditCardText;
	}

	/** Method for setting menu actions texts */
	private setMenuActions(data: any): void {
		this._principalActionText = data.setPrincipalWalletButton;
		this._deleteActionText = data.deleteWalletButton;
	}
	/**
	 * Method for refresh and re-fill local cards list
	 */
	getPaymentCards(onlyCardsWithPagoRecurrenteF?: boolean): Observable<Array<PaymentCardModel>> {
		return this.paymentCardDataService.getPaymentCardsByDocumentId(this.documentID).pipe(
			map((res: any) => {
				const cards: Array<PaymentCardModel> = new Array<PaymentCardModel>();
				let cardsFilteredByPagoRecurrente: Array<PaymentCardModel> = new Array<PaymentCardModel>();
				if (res.items && res.items.length > 0) {
					res.items.forEach((card) => {
						cards.push(this.mapCardData(card));
					});
					if (onlyCardsWithPagoRecurrenteF) {
						cardsFilteredByPagoRecurrente = cards.filter((item) => item.pagoRecurrente === pagoRecurrenteF);
					}
				}
				onlyCardsWithPagoRecurrenteF ? (this._cardsList = cardsFilteredByPagoRecurrente) : (this._cardsList = cards);
				this.fillNeededDataFromWCS();
				this.setImagesForEachCard();
				this.setPrincipalAsSelected();
				this.cardsListChanged();
				return this._cardsList;
			})
		);
	}

	//#endregion
}
