import { Injectable } from '@angular/core';
import { ActivatedRoute, ActivatedRouteSnapshot, Params, Router, RouterStateSnapshot } from '@angular/router';
import { Observable, of as observableOf, throwError } from 'rxjs';
import { catchError, finalize, map, mergeMap } from 'rxjs/operators';
import { AppService } from '../../app.service';
import { ConfigurationService } from '../../core/services/configuration.service';
import { StorageService } from '../../core/services/storage.service';
import {
	adaraValues,
	payDeptTab,
	typeParameter,
	whatsAppParameter,
	whatsAppRedirectLink,
} from '../../shared/constants/defines';
import { ImpersonationGroup } from '../../shared/enums/impersonationGroup.enum';
import { UserProfile } from '../../shared/models/user-profile.model';
import { ImpersonationService } from '../../shared/services/impersonation.service';
import { SiteHandlingService } from '../../shared/services/site-handling.service';
import { UtilitiesService } from '../../shared/utils/utilities.service';
import { AuthenticateService } from '../services/authenticate.service';
import { config } from './../../../config/pages-config';
import { SiteStatus } from './../../shared/enums/siteStatus.enum';

@Injectable()
export class AuthenticationGuard {
	constructor(
		private authenticate: AuthenticateService,
		private impersonationService: ImpersonationService,
		private router: Router,
		private route: ActivatedRoute,
		private siteHandlingService: SiteHandlingService,
		private configService: ConfigurationService,
		private storage: StorageService,
		private appService: AppService,
		private utilitiesService: UtilitiesService
	) {}
	canActivate(
		route: ActivatedRouteSnapshot,
		state: RouterStateSnapshot
	): boolean | Observable<boolean> | Promise<boolean> {
		if (this.configService.isConfigLoaded) {
			return this.validateAuthentication(state, route);
		} else {
			return this.appService.settingsSubject.pipe(
				mergeMap((res) => {
					const result: boolean | Observable<boolean> = this.validateAuthentication(state, route);
					if (result instanceof Observable) {
						return result;
					} else {
						return observableOf(result);
					}
				})
			);
		}
	}

	canActivateChild(
		childRoute: ActivatedRouteSnapshot,
		state: RouterStateSnapshot
	): boolean | Observable<boolean> | Promise<boolean> {
		return this.canActivate(childRoute, state);
	}

	validateAuthentication(state: RouterStateSnapshot, route?: ActivatedRouteSnapshot): boolean | Observable<boolean> {
		const response: UserProfile | Observable<UserProfile> = this.authenticate.validateAuthentication(state);
		if (response instanceof Observable) {
			this.authenticate.isRefreshingToken = true;
			return response.pipe(
				finalize(() => {
					this.authenticate.isRefreshingToken = false;
				}),
				catchError((error) => {
					this.authenticate.refreshTokenEmmiter.next(false);
					this.router.navigate([config.login.route], {
						queryParams: {
							targetUrl: state.url,
						},
					});
					return throwError(error);
				}),
				map(
					(res) => {
						this.authenticate.refreshTokenEmmiter.next(true);
						if (res) {
							return this.handleCaseOfLoggedInUser(state, route, res);
						} else {
							return this.handleCaseOfNotLoggedInUser(state);
						}
					},
					(error) => {
						if (error.error) {
							return false;
						}
					}
				)
			);
		} else if (response) {
			return this.handleCaseOfLoggedInUser(state, route, response);
		} else {
			return this.handleCaseOfNotLoggedInUser(state);
		}
	}

	makeNavigation(state: RouterStateSnapshot): void {
		if (!this.configService.configuration.billingFlag) {
			this.router.navigate([config.billIsDown.route]);
		} else if (state.url !== config.adara.route) {
			this.router.navigate([config.BillingVf10.route]);
		}
	}

	handleTopUpLoggedInUser(state: RouterStateSnapshot): void {
		if (
			state?.url?.split('?')[0] === config.TopUp.topUpPrepaidservice.route ||
			state?.url?.indexOf(config.TopUp.privateTopUp.route) > -1
		) {
			const routeExtraParams: {} = this.utilitiesService.getRouteParams(state.url);
			this.router.navigate([config.dashboard.route], {
				queryParams: { tab: config.TopUp.topUpPrepaidservice.route, ...routeExtraParams },
			});
		}
	}

	handleCaseOfLoggedInUser(state: RouterStateSnapshot, route?: ActivatedRouteSnapshot, user?: UserProfile): boolean {
		this.handleTopUpLoggedInUser(state);
		// Check Firma digital
		this.storage.showFirmaEntryPoint = user?.firmaDigital && !this.storage.isFirmaFinished;
		if (this.hasOnlyAdaraProduct(user)) {
			this.router.navigate([config.adara.name], { queryParams: { origen: adaraValues.originLogin } });
			return true;
		}
		if (state.url === config.adara.route) {
			return true;
		}
		if (this.siteHandlingService.isSitePendingInstall()) {
			if (state.url === config.myorders.route) {
				this.storage.customerAccountsLoaded = true;
			}
			return this.siteHandlingService.handelPendingInstallationCase(route);
		}
		if (
			this.siteHandlingService.isSiteSuspended() &&
			(state.url.indexOf(config.Inbox.route) > -1 || state.url.indexOf(config.billing.billPayment.name) > -1)
		) {
			return true;
		}
		if (state.url.indexOf(config.BillingVf10.route) === -1) {
			if (
				this.siteHandlingService.isSiteP2() ||
				this.impersonationService.impersonatedUser?.Group?.toLocaleLowerCase() ===
					ImpersonationGroup.PayADebt.toLocaleLowerCase()
			) {
				this.makeNavigation(state);
				return true;
			}
		} else if (!this.configService.configuration.billingFlag) {
			this.router.navigate([config.billIsDown.route]);
			return false;
		}

		return true;
	}

	handleCaseOfNotLoggedInUser(state: RouterStateSnapshot): boolean {
		let redirigirWhatsapp: boolean = false;

		const params: URLSearchParams = new URLSearchParams(state.url.split('?')[1]);
		let whatsAppContent: string = params.get(whatsAppParameter.name);
		let type: string = params.get(typeParameter.name);

		if (whatsAppContent && type && type.localeCompare(typeParameter.values.chat) === 0) {
			redirigirWhatsapp = true;
		}

		if (
			!this.route.snapshot.queryParams['targetUrl'] &&
			state.url !== config.login.companyChooser.route &&
			state.url !== config.login.route
		) {
			if (state.url === config.dashboard.route) {
				this.router.navigate([config.login.route]);
			} else if (state.url.split('?')[0] === config.billing.BillPayment.route) {
				const routeExtraParams: {} = this.utilitiesService.getRouteParams(state.url);
				/** SWAT-230 (incase user unlogged in and he is trying to access bill payment) with ignoring any query param here */
				this.router.navigate([config.login.route], { queryParams: { tab: payDeptTab, ...routeExtraParams } });
			} else if (state.url.split('?')[0] === config.TopUp.topUpPrepaidservice.route) {
				/** AM-298 (incase user is unlogged in and he is trying to access the TopUp tray*/
				this.router.navigate([config.publicTopup.route]);
			} else if (state.url === config.BillingVf10.MainScreen.route) {
				const routeExtraParams: Params = this.router.getCurrentNavigation().extractedUrl.queryParams;
				this.router.navigate([config.login.route], {
					queryParams: {
						targetUrl: state.url,
						...routeExtraParams,
					},
				});
			} else {
				if (
					(redirigirWhatsapp && state.url.split('?')[0] === config.bubble.route) ||
					(redirigirWhatsapp && state.url.split('?')[0] !== config.dashboard.route)
				)
					this.redirectToWhatsapp(whatsAppContent);
				else
					this.router.navigate([config.login.route], {
						queryParams: {
							targetUrl: state.url,
						},
					});
			}
		}
		return false;
	}

	private redirectToWhatsapp(whatsAppContent: string): void {
		if (whatsAppContent.localeCompare(whatsAppParameter.values.notextRedirection) === 0)
			window.open(whatsAppRedirectLink, '_self');
		else window.open(whatsAppRedirectLink + whatsAppContent, '_self');
	}

	hasOnlyAdaraProduct(userProfile: UserProfile): boolean {
		if (
			userProfile.sites.filter((site) => site.status !== SiteStatus.Cancelado).length === 1 &&
			userProfile.sites.filter((site) => site.status !== SiteStatus.Cancelado)[0].marketType ===
				adaraValues.CLIENT_ADARA
		) {
			return true;
		} else {
			return false;
		}
	}
}
