import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Breakpoints } from '@mva10/mva10-angular';
import { TranslateService } from '@ngx-translate/core';
import { ServiceType } from 'src/app/shared/enums/serviceType.enum';
import { ServiceModel } from 'src/app/shared/models/service.model';
import * as routes from '../../../config/pages-config';
import { tagging } from '../../../config/tagging-config';
import { AppService } from '../../app.service';
import { SubscriptionService } from '../../core/services/subscription.service';
import { TaggingHelperService } from '../../core/services/tagging.helper.service';
import { AppThemes } from '../../shared/constants/defines';
import { TaggingViewModel } from '../../shared/models/tagging.model';
import { TariffService } from '../../shared/services/tariff.service';

@Component({
	selector: 'sp-smart-pay-management',
	templateUrl: './smart-pay-management.component.html',
	styleUrls: ['./smart-pay-management.component.scss'],
})
export class SmartPayManagementComponent implements OnInit {
	public smartPayText: string;
	public smartPayRechargeText: string;
	public smartPayDiscoverText: string;
	public smartPayMyLinesText: string;
	public smartPayMyRenewalsText: string;
	public smartPayNoLinesText: string;
	public smartPayAddLineText: string;
	public smartPayImage: string;
	public smartPayImageResponsive: string;
	public smartPayActiveText: string;
	public smartPayInactiveText: string;
	public smartPayHistoryText: string;

	public isMobileView: boolean;

	public linesAvailable: boolean;
	public msisdnList: ServiceModel[];
	public filteredMsisdnList: any;

	public smartPayFinder: string;

	public loaded: boolean;
	public pattern: string;

	public page: TaggingViewModel;
	public data: TaggingViewModel;
	/*  public
  public
  public
  public
  public */

	constructor(
		public appService: AppService,
		public appTheme: AppService,
		private translate: TranslateService,
		public subscription: SubscriptionService,
		private subscriptionData: SubscriptionService,
		public tariffService: TariffService,
		public router: Router,
		private tagging: TaggingHelperService
	) {
		this.appService.theme = AppThemes.ThemeCommercial;
	}

	ngOnInit(): void {
		this.isMobileView = window.innerWidth < Breakpoints.TABLET;
		this.getWCSData();
		this.getCustomerAccounts();
		this.setOnViewTaggingLogic();
	}

	private getWCSData(): void {
		this.translate.get('v10.productsServices.smartPay.PyS.management').subscribe((data) => {
			this.smartPayRechargeText = data.recharge;
			this.smartPayMyLinesText = data.prepaid_lines;
			this.smartPayMyRenewalsText = data.renewals;
			this.smartPayNoLinesText = data.no_lines;
			this.smartPayAddLineText = data.add_line;
			this.smartPayImage = data.image;
			this.smartPayImageResponsive = data.image_rsp;
			this.isMobileView ? (this.smartPayImage = data.image) : (this.smartPayImage = data.image_rsp);
		});
		this.translate.get('v10.productsServices.smartPay').subscribe((data) => {
			this.appTheme.appHeaderTitle = data.common.easypay;
			this.smartPayText = data.common.easypay;
			this.smartPayActiveText = data.tariff_dsh.easypay_active;
			this.smartPayInactiveText = data.tariff_dsh.easypay_inactive;
			this.smartPayDiscoverText = data.common.discovery;
		});
		this.translate.get('v10.productsServices.smartPay.PyS.history').subscribe((data) => {
			this.smartPayHistoryText = data.title;
		});
	}

	private getCustomerAccounts(): void {
		this.msisdnList = this.subscriptionData.customerData.services;
		this.filteredMsisdnList = this.msisdnList.filter((n) => n.type === ServiceType.Prepaid);
		this.filteredMsisdnList.length > 0 ? (this.linesAvailable = true) : (this.linesAvailable = false);
		this.loaded = true;
	}

	public goToSmartPayLanding(): void {
		this.router.navigate([routes.config.smartpayLanding.route]);
	}

	public goToSmartPayPage(): void {
		this.router.navigate([routes.config.ProductsAndServices.Smartpay.route]);
	}

	private setOnViewTaggingLogic(): void {
		this.page = tagging.smartPayManagementOnViewTags.smartPayManagement.page;
		const smartPayManagementOnViewTags: TaggingViewModel = Object.assign(this.page);
		this.tagging.viewReplace(false, smartPayManagementOnViewTags);
	}

	public setOnClickLinesTaggingLogic(): void {
		this.page = tagging.smartPayManagementOnClickTags.smartPayManagementLineClick.page;
		this.data = tagging.smartPayManagementOnClickTags.smartPayManagementLineClick.data;
		const smartPayManagementOnClickLinesTags: TaggingViewModel = Object.assign(this.page, this.data);
		this.tagging.trackReplace(this.data.event_name, smartPayManagementOnClickLinesTags, true);
	}

	public setOnClickHistoryTaggingLogic(): void {
		this.page = tagging.smartPayManagementOnClickTags.smartPayManagementHistoryClick.page;
		this.data = tagging.smartPayManagementOnClickTags.smartPayManagementHistoryClick.data;
		const smartPayManagementOnClickHistoryTags: TaggingViewModel = Object.assign(this.page, this.data);
		this.tagging.trackReplace(this.data.event_name, smartPayManagementOnClickHistoryTags, true);
	}
}
