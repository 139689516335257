import { Component, Input, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { NewTaggingHelperService } from 'src/app/core/services/new-tagging.helper.service';
import {
	smartPayLandingImage,
	smartPayRetentionImages,
	smartPayTagging,
	tariffCodes,
} from 'src/app/shared/constants/defines';
import { NewTaggingJsonModel } from 'src/app/shared/models/new-tagging-json-model';
import { MsisdnToShowPipe } from 'src/app/shared/pipes/msisdn-to-show.pipe';
import { SmartPayService } from '../services/smart-pay.service';

@Component({
	selector: 'sp-smart-pay-info-card',
	templateUrl: './smart-pay-info-card.component.html',
	styleUrls: ['./smart-pay-info-card.component.scss'],
	providers: [MsisdnToShowPipe],
})
export class SmartPayInfoCardComponent implements OnInit {
	statusTitle: string;
	indicatorIconPath: string = smartPayLandingImage.indicatorIconPath;
	@Input() isSmartPay: boolean;
	@Input() isDay28: boolean;
	@Input() msisdn: string;
	@Input() tariffCode: string;

	constructor(
		public translateService: TranslateService,
		private smartPayService: SmartPayService,
		private newTagging: NewTaggingHelperService
	) {}

	ngOnInit(): void {
		this.smartPayService.isDay28 = this.isDay28;
		this.setUIContent();
	}

	clickMoreInfoButtonTagging(): void {
		const interactionKey: string = this.isSmartPay
			? smartPayTagging.pago_facil_active_mas_info_click
			: smartPayTagging.pago_facil_inactive_mas_info_click;
		const stateKey: string = this.isSmartPay ? smartPayTagging.pago_facil_active : smartPayTagging.pago_facil_inactive;
		this.newTagging.getJsonTagging('smart-pay').subscribe((data: NewTaggingJsonModel) => {
			this.newTagging.interaction(data.eventList[interactionKey], data.page['stateList'][stateKey]);
		});
	}

	setUIContent(): void {
		this.translateService.get('v10.productsServices.smartPay').subscribe((data) => {
			this.statusTitle = this.isSmartPay ? data.common.active : data.common.inactive;
		});
	}

	isBigOrHeavyTariff(): boolean {
		if (
			this.tariffCode === tariffCodes.tarifaHeavyYuser[0].code ||
			this.tariffCode === tariffCodes.tarifaBigYuser[0].code
		) {
			return true;
		} else {
			return false;
		}
	}

	openDeactivateSmartpayOverlay(): void {
		this.openDeactivateSmartpayOverlayTagging();
		this.setHighlitSmartPayBenefitsOverlay();
		this.smartPayService.loadingScreenText = '';
		this.smartPayService.showOverlay = true;
	}
	openDeactivateSmartpayOverlayTagging(): void {
		this.newTagging.getJsonTagging('smart-pay').subscribe((data: NewTaggingJsonModel) => {
			this.newTagging.interaction(
				data.eventList['pago_facil_active_deactivate_pago_facil_click'],
				data.page['stateList']['pago_facil_active']
			);
		});
	}

	setConfirmationDeactivationOverlayTagging(): void {
		this.newTagging.getJsonTagging('smart-pay').subscribe((data: NewTaggingJsonModel) => {
			this.newTagging.state(data.page['stateList']['pago_facil_active_deactivate_screen']);
		});
	}
	setConfirmationDeactivationOverlay(): void {
		this.setConfirmationDeactivationOverlayTagging();
		this.translateService.get('v10.productsServices.smartPay.PyS.benefits.method').subscribe((data) => {
			if (this.isDay28) {
				this.smartPayService.overlayData.titleOverlay = data.deactive.questionDay28;
				this.smartPayService.overlayData.textOverlay = data.deactive.descDay28.replace('<br/>', '\n');
			} else {
				this.smartPayService.overlayData.titleOverlay = data.deactive.question;
				this.smartPayService.overlayData.textOverlay = data.deactive.desc;
			}
			this.smartPayService.overlayData.secundaryBtnText = data.deactive.pay;
			this.smartPayService.overlayData.primaryBtnText = data.keep;
		});
		this.smartPayService.overlayIcon = smartPayRetentionImages.warning;
	}

	setHighlitSmartPayBenefitsOverlay(): void {
		this.smartPayService.deactivationScreens = true;
		this.smartPayService.overlayData.textOverlay = '';
		this.smartPayService.overlayData.titleOverlay = '';
		this.smartPayService.showMaintainSmartPayOverlay = false;
		this.smartPayService.showConfirmDeactivationOverlay = false;
		this.smartPayService.showSuccessOrErrOverlay = false;
		this.smartPayService.overlayIcon = smartPayRetentionImages.charity_giving;
		this.smartPayService.overlayData.horizontalBtn = true;
		this.smartPayService.overlayData.largeButtons = false;
		this.translateService
			.get('v10.productsServices.smartPay.PyS.benefits.deactivation.benefits_reminder')
			.subscribe((data) => {
				this.smartPayService.overlayData.primaryBtnText = data.keep_button_title;
				this.smartPayService.overlayData.secundaryBtnText = data.deactivate_button_title;
			});
		this.newTagging.getJsonTagging('smart-pay').subscribe((data: NewTaggingJsonModel) => {
			this.newTagging.state(data.page['stateList'][smartPayTagging.highlight_benefit_screen]);
		});
	}
}
