import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { OverlayFullScreenModel } from '@mva10/mva10-angular';
import { TranslateService } from '@ngx-translate/core';
import { AppService } from 'src/app/app.service';
import { SubscriptionService } from 'src/app/core/services/subscription.service';
import {
	MasterCardFirstDigit,
	VisaFirstDigit,
	defines,
	smartPayErrors,
	smartPayRetentionImages,
	smartpayProductsAndServicesTagging,
} from 'src/app/shared/constants/defines';
import { CardType } from 'src/app/shared/enums/card-type.enum';
import { ServiceType } from 'src/app/shared/enums/serviceType.enum';
import { CreditCardModel } from 'src/app/shared/models/credit-card-model';
import { PaymentCardModel } from 'src/app/shared/models/payment-card.model';
import { PaymentCardsService } from 'src/app/shared/services/payment-cards.service';
import { TariffService } from 'src/app/shared/services/tariff.service';
import { UtilitiesService } from 'src/app/shared/utils/utilities.service';
import { TopUpService } from 'src/app/top-up/top-up.service';
import { config } from 'src/config/pages-config';
import { StorageService } from '../../core/services/storage.service';
import { Error } from '../../shared/models/error.model';

@Injectable()
export class SmartPayService {
	showOverlay: boolean;
	showMaintainSmartPayOverlay: boolean;
	showUpdateCardOverlay: boolean;
	showFullLoader: boolean;
	overlayData: OverlayFullScreenModel = new OverlayFullScreenModel();
	overlayIcon: string;
	loadingScreenText: string;
	footerHeight: number;
	isCTASubmitted: boolean;
	isCTAEnabled: boolean;
	hintCTA: string;
	showCheckboxAndFooter: boolean;
	showCheckBox: boolean = true;
	isDay28: boolean;
	activateSmartPaySuccessfully: boolean;
	deactivateSmartPaySuccessfully: boolean;
	isActivateOverlay: boolean;
	showAddNewCardButton: boolean;
	hasCreditCard: boolean = true;
	selectedCreditCard: CreditCardModel = new CreditCardModel();
	defaultActiveSmartpayCard: PaymentCardModel = new PaymentCardModel();
	updateCardWithDefault: boolean;
	showPaymentCardTitle: boolean;
	showConfirmDeactivationOverlay: boolean;
	deactivationSuccOrErrOverlay: OverlayFullScreenModel = new OverlayFullScreenModel();
	showSuccessOrErrOverlay: boolean;
	deactivationScreens: boolean;
	constructor(
		private tariffService: TariffService,
		public translateService: TranslateService,
		private storageService: StorageService,
		public topupService: TopUpService,
		private appService: AppService,
		private paymentCardsService: PaymentCardsService,
		private utilitiesService: UtilitiesService,
		private subscriptionService: SubscriptionService,
		public router: Router
	) {}

	setFailureOverlay(error: Error): void {
		this.translateService.get('v10.common.literals.msg.error').subscribe((data) => {
			this.deactivationSuccOrErrOverlay.titleOverlay = data.ups_seems;
			this.deactivationSuccOrErrOverlay.primaryBtnText = data.retryText;
		});
		this.deactivationSuccOrErrOverlay.secundaryBtnText = '';
		this.deactivationSuccOrErrOverlay.closeBtn = true;
		this.overlayIcon = smartPayRetentionImages.errorImg;
		this.deactivationSuccOrErrOverlay.textOverlay = this.setFailureOverlayDescription(error);
	}

	setFailureOverlayDescription(error: Error): string {
		let message: string;
		this.translateService.get('v10.productsServices.smartPay.activate.error').subscribe((data) => {
			switch (error.ecode) {
				case smartPayErrors.unexpected:
					message = data.unexpected;
					break;
				case smartPayErrors.retry:
					message = data.retry;
					break;
				case smartPayErrors.interventions:
					message = data.interventions;
					break;
				case smartPayErrors.max:
					message = data.max;
					break;
				case smartPayErrors.debt:
					message = data.debt;
					break;
				case smartPayErrors.advance:
					message = data.advance;
					break;
				default:
					message = data.retry;
					break;
			}
		});
		return message;
	}

	mapSelectedCard(selectedCard: PaymentCardModel): void {
		this.selectedCreditCard.tokenCreditCard = selectedCard.token;
		this.selectedCreditCard.maskCreditCard = selectedCard.cardMaskData;
		this.selectedCreditCard.creditCardExpiryDate = selectedCard.expiryDate;
		this.selectedCreditCard.payCycle = selectedCard.txID_COF;
		this.selectedCreditCard.creditCardNumber = this.utilitiesService.cardFormatted(selectedCard.cardMaskData);
		this.selectedCreditCard.creditCardImage = this.getCardImage(selectedCard.cardMaskData[0]);
		this.selectedCreditCard.brand = this.getCardBrand(selectedCard.cardMaskData[0]);
	}

	getCardImage(brand: string): string {
		let cardImage: string;
		this.translateService.get('payment.paymentMethods.images').subscribe((data) => {
			if (brand === CardType.visa || brand === VisaFirstDigit) {
				cardImage = this.appService.getImgFullPath(data.walletVisa.url);
			} else if (brand === CardType.mastercard || brand === MasterCardFirstDigit) {
				cardImage = this.appService.getImgFullPath(data.walletMasterCard.url);
			} else {
				cardImage = this.appService.getImgFullPath(data.walletOther.url);
			}
		});
		return cardImage;
	}

	getCardBrand(firstDigit: string): string {
		let brandCard: string;
		if (firstDigit === VisaFirstDigit) {
			brandCard = CardType.visa;
		} else if (firstDigit === MasterCardFirstDigit) {
			brandCard = CardType.mastercard;
		}
		return brandCard;
	}

	setInactiveFooterHint(): void {
		this.translateService.get('v10.productsServices.pagoFacil.Footer').subscribe((data) => {
			if (this.tariffService.isDay28()) {
				this.hintCTA = data.day28Text;
				this.isCTAEnabled = false;
				this.showCheckBox = false;
			} else {
				this.hintCTA = data.notDay28Text;
				this.isCTAEnabled = true;
				this.showCheckBox = true;
			}
		});
	}

	handleInactiveUIFooter(card?: PaymentCardModel): void {
		this.showPaymentCardTitle = false;
		if (card) {
			this.mapSelectedCard(card);
			this.setInactiveFooterHint();
			this.showCheckboxAndFooter = true;
			this.showAddNewCardButton = false;
		} else {
			this.showAddNewCardButton = true;
			this.showCheckboxAndFooter = false;
			this.showAddNewCreditCard();
		}
	}

	handleUIFooter(smartpay: boolean, card?: PaymentCardModel): void {
		if (smartpay) {
			this.handleActiveUIFooter(card);
		} else {
			this.handleInactiveUIFooter(card);
		}
	}

	resetFlags(): void {
		this.showAddNewCardButton = false;
		this.showCheckboxAndFooter = false;
		this.hintCTA = '';
	}

	changeToDefaultCreditCard(isSmartPay: boolean): void {
		this.showPaymentCardTitle = false;
		this.showAddNewCardButton = false;
		if (isSmartPay) {
			this.showCheckboxAndFooter = false;
			this.mapSelectedCard(this.defaultActiveSmartpayCard);
		} else {
			this.handleInactiveUIFooter(this.topupService.selectedCreditCard);
		}
	}

	updateCardToBeDefault(isSmartpay: boolean): void {
		if (this.hasCreditCard) {
			this.changeToDefaultCreditCard(isSmartpay);
		} else {
			this.getSavedCreditCards();
		}
	}

	handleActiveUIFooter(card?: PaymentCardModel): void {
		this.hintCTA = '';
		if (card) {
			this.showAddNewCardButton = false;
			if (card.cardMaskData === this.defaultActiveSmartpayCard.cardMaskData) {
				this.showCheckboxAndFooter = false;
				this.showPaymentCardTitle = false;
			} else {
				this.isCTAEnabled = true;
				this.showCheckBox = true;
				this.showCheckboxAndFooter = true;
				this.showPaymentCardTitle = true;
			}
			this.mapSelectedCard(card);
		} else {
			this.showCheckboxAndFooter = true;
			this.showPaymentCardTitle = true;
			this.showCheckBox = false;
			this.isCTAEnabled = false;
			this.showAddNewCreditCard();
		}
	}

	showAddNewCreditCard(): void {
		this.showAddNewCardButton = true;
		this.selectedCreditCard.creditCardNumber = '';
		this.selectedCreditCard.creditCardImage = '';
	}

	getSavedCreditCards(): void {
		this.paymentCardsService.getPaymentCardsByDocumentId(this.storageService.userProfile?.document?.id).subscribe(
			() => {
				this.topupService.selectedCreditCard =
					this.paymentCardsService.cardsList?.length > 0
						? this.paymentCardsService.cardsList.find((card) => card.principle && card.pagoRecurrente === 'F')
						: null;
				if (this.topupService.selectedCreditCard) {
					this.hasCreditCard = true;
					this.handleInactiveUIFooter(this.topupService.selectedCreditCard);
				} else {
					this.hasCreditCard = false;
					this.showAddNewCardButton = true;
					this.showCheckboxAndFooter = false;
					this.showAddNewCreditCard();
				}
			},
			(error) => {
				this.paymentCardsService.cardsList = new Array<PaymentCardModel>();
				this.selectedCreditCard = new CreditCardModel();
				this.topupService.selectedCreditCard = null;
			}
		);
	}

	navigateToSmartpayPage(): void {
		if (
			(this.subscriptionService?.customerData?.currentService?.type?.toLowerCase() ===
				ServiceType.Prepaid.toLowerCase() &&
				this.tariffService?.tariffWs10?.extension?.es?.benefits?.status &&
				this.tariffService?.tariffWs10?.extension?.es?.benefits?.status?.toLowerCase() ===
					defines.active.toLowerCase() &&
				this.tariffService?.tariffWs10?.extension?.es?.benefits?.isEligible) ||
			this.subscriptionService?.customerData?.currentService?.smartPay === true
		) {
			this.router.navigate([config.ProductsAndServices.Smartpay.route]);
		} else {
			this.router.navigate([config.smartpayLanding.route], {
				queryParams: {
					origen: smartpayProductsAndServicesTagging.origen,
				},
			});
		}
	}
}
