import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { TranslateModule } from '@ngx-translate/core';
import { SpErrorMessageModule } from './../sp-error-message/sp-error-message.module';
import { IframeModalComponent } from './iframe-modal.component';

@NgModule({
	declarations: [IframeModalComponent],
	imports: [CommonModule, TranslateModule, SpErrorMessageModule],
	exports: [IframeModalComponent],
})
export class IframeModalModule {}
