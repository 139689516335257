import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { CoreModule, GenericModule } from '@mva10/mva10-angular';
import { TranslateModule } from '@ngx-translate/core';
import { AngularSvgIconModule } from 'angular-svg-icon';
import { SharedModule } from '../../shared.module';
import { SmartpayLandingRoutingModule } from './smartpay-landing-routing.module';
import { SmartpayLandingComponent } from './smartpay-landing.component';

@NgModule({
	declarations: [SmartpayLandingComponent],
	imports: [
		CommonModule,
		SmartpayLandingRoutingModule,
		TranslateModule,
		CoreModule,
		GenericModule,
		AngularSvgIconModule.forRoot(),
		SharedModule,
	],
})
export class SmartpayLandingModule {}
