import { Guid } from 'guid-typescript';
import { roamingProductmangment } from '../constants/defines';
export class ProductOfferingPostObject {
	externalId: string;
	productOrderItem: ProductItem[];
	relatedParty: RelatedParty[];
	constructor() {
		this.relatedParty = new Array<RelatedParty>(1);
		this.relatedParty[0] = new RelatedParty();
		this.externalId = Guid.create()['value'];
		// remove all dashesh from UUID and then take first 15 char as required from dxl side
		this.externalId = this.externalId.replace(/-/g, '').slice(0, 15);
	}
}
export class RelatedParty {
	'id': string;
	'role': string;
	'@referredType': string;
	'constructor'() {
		// static values as requested form backend side
		this.role = roamingProductmangment.role;
		// static values as requested form backend side
		this['@referredType'] = roamingProductmangment.referredType;
	}
}
export class ProductItem {
	'id': string;
	'action': RoamingAction;
	'category': RoamingCategory[];
	'product'?: ProductDeatis;
	'@type': string;
	'constructor'() {
		this['@type'] = roamingProductmangment.productOrderItemType;
		this.category = new Array<RoamingCategory>(1);
		this.category[0] = new RoamingCategory();
		// static values as requested form backend side and change in another casese by overiding it.
		this.category[0].id = roamingProductmangment.RoamingCategory.id_value;
		// static values as requested form backend side
		this.category[0].name = roamingProductmangment.RoamingCategory.name;
	}
}
export enum RoamingAction {
	activate = 'add',
	deActivate = 'delete',
}
export class RoamingCategory {
	id: string;
	name: string;
	constructor() {
		this.name = roamingProductmangment.RoamingCategory.name;
	}
}
export class ProductDeatis {
	productSerialNumber: string;
	isBundle: boolean;
	productOffering: ProductOffering;
	productCharacteristic: ProductCharacteristic[];
	constructor() {
		this.isBundle = true;
		this.productOffering = new ProductOffering();
		this.productCharacteristic = new Array<ProductCharacteristic>(1);
		this.productCharacteristic[0] = new ProductCharacteristic();
	}
}
export class ProductOffering {
	id: string;
}
export class ProductCharacteristic {
	value: string;
	name: string;
}
