import { Component, Input, OnInit } from '@angular/core';

@Component({
	selector: 'sp-picker-item',
	templateUrl: './picker-item.component.html',
	styleUrls: ['./picker-item.component.scss'],
})
export class PickerItemComponent implements OnInit {
	@Input() text: string;
	@Input() icon: string;
	public sizeTextBig: boolean = true;

	ngOnInit(): void {
		this.sizeTextBig = this.text?.length < 29;
	}
}
