import { HttpClient, HttpErrorResponse, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { BehaviorSubject, Observable, of, throwError } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { TaggingViewModel } from 'src/app/shared/models/tagging.model';
import { tagging } from 'src/config/tagging-config';
import { StorageService } from '../../core/services/storage.service';
import { SubscriptionService } from '../../core/services/subscription.service';
import { TaggingHelperService } from '../../core/services/tagging.helper.service';
import { ClientTypology } from '../../shared/enums/clientTopology.enum';
import {
	CommercialJourneys,
	LOCAL_STORAGE_KEYS,
	VERIFYEMAIL,
	allowedServicesDef,
	cadErrorServices,
	commercialMobile,
	entryPointsMVA10,
	errorConstants,
	errorFunction,
	errorFunctionAnalitycs,
	errorTecnichal,
	errorTecnichalAnalitycs,
	journeyTags,
	terminalDetailErrorPaths,
	undefinedType,
} from '../constants/defines';
import { API_URLS } from '../constants/routes-config';
import { CustomerType } from '../enums/customerType.enum';
import { UserSettingToken } from '../enums/user-setting-token.enum';
import { AllowedLinesData, MsidinPriority, errorRes } from '../models/allowedLines.model';
import { CommercialOptions } from '../models/commercial-list-terminals.model';
import { OfferDiscount } from '../models/commercial-offer.model';
import { EntryPointModel, EntryPointResponseModel } from '../models/entry-points.model';
import { ErrorList } from '../models/error-list.model';
import { RecoProductPackageModel } from '../models/reco-product-package.model';
import { UtilitiesService } from '../utils/utilities.service';
import { AllowedServicesService } from './allowed-services.service';
import { EntryPointsService } from './entry-points.service';

@Injectable({
	providedIn: 'root',
})
export class MicroFlowsService {
	public dataInMf: string[] = [];
	public entrePointsRes: object;
	public allowedRes: object;
	private averia: string = '';
	public isMe: boolean;
	public webToken: string;
	public chatToken: string;
	public partialError: boolean = false;
	public screenCode: string;
	public retry: number = 0;
	public cartId: string;
	public isMainFlow: boolean = false;
	public laEntryPoint: EntryPointModel;
	public offerDiscounts: Array<OfferDiscount> = [];
	public recoProductPackage: RecoProductPackageModel;
	private postResetCookie: BehaviorSubject<boolean>;
	public postResetCookie$: Observable<boolean>;
	private unsuscribeDiscounts: string;
	private stepCommercial: string;
	private errorReco: boolean = false;
	public navigateError: HttpErrorResponse;
	public responseEntrypoints: EntryPointResponseModel;

	public productsTag: string;
	public tarifChanged: boolean = false;
	public showArrowBack: boolean = false;

	public offerDescriptorDL: string;

	public microflow: string;
	public microUrlService: string;
	public microTypeError: string;

	public getErrorReco: HttpErrorResponse;
	public buyNow: boolean = false;
	public buyNowNavigation: boolean = false;
	public showNTOLKO: boolean = false;
	private errorReset: boolean = false;
	public terminalListLoaded: boolean = false;
	public allowedLinesData: BehaviorSubject<AllowedLinesData> = new BehaviorSubject({ ready: false });

	constructor(
		public subscriptionData: SubscriptionService,
		private storage: StorageService,
		public http: HttpClient,
		public entryPointService: EntryPointsService,
		private allowedServices: AllowedServicesService,
		private translateService: TranslateService,
		private readonly router: Router,
		private readonly utilities: UtilitiesService,
		private tagging: TaggingHelperService
	) {
		this.postResetCookie = new BehaviorSubject<boolean>(false);
		this.postResetCookie$ = this.postResetCookie.asObservable();
	}

	public setCommercialStep(step: string): void {
		this.stepCommercial = step;
	}

	public getCommercialStep(): string {
		return this.stepCommercial;
	}
	public dataPush(prop: string): void {
		this.dataInMf.push(prop);
	}
	public getTokens(
		type: UserSettingToken,
		siteId?: string,
		serviceId?: string,
		menuItemClicked = true,
		chatBotToken?: string,
		WCSData?: any,
		isdigital: boolean = null
	): Observable<any> {
		const url: string = API_URLS.userSettings.userSettings;
		const segment: string = this.setSegment(WCSData, isdigital);
		const body: object = this.setBody(menuItemClicked, serviceId, WCSData, type, siteId, chatBotToken, segment);
		body['attributes'][0]['params'].averia = this.averia;
		const options: object = this.headersData();

		return this.http.post(url, body, options).pipe(
			map((res: any) => {
				// jsonpath refactor: added 'any' type due to missing model
				this.webToken = res.webToken || null;
				this.chatToken = res.chatToken || null;
				return true;
			}),
			catchError((error) => {
				return throwError(error);
			})
		);
	}

	private setSegment(WCSData: any, isdigital: boolean): string {
		let segment: string;
		this.storage.userProfile &&
		((this.storage.userProfile.customerType &&
			this.storage.userProfile.customerType.toLowerCase() === CustomerType.Employee.toLocaleLowerCase()) ||
			this.storage.userProfile.customerType.toLowerCase() === CustomerType.Authorized.toLocaleLowerCase())
			? (segment = WCSData && WCSData.microCompanySegment ? WCSData.microCompanySegment : '')
			: this.altSegment(WCSData, isdigital);

		return segment;
	}

	private altSegment(WCSData: any, isdigital: boolean): string {
		let segment: string;
		if (isdigital) {
			segment = WCSData ? WCSData.rsYUSegment : '';
		} else {
			segment = WCSData ? WCSData.rsSegment : '';
		}
		return segment;
	}

	private setBody(
		menuItemClicked: boolean = true,
		serviceId: string,
		WCSData: any,
		type: UserSettingToken,
		siteId: string,
		segment: string,
		chatBotToken?: string
	): object {
		const body: object = menuItemClicked
			? { attributes: [{ name: type }] }
			: serviceId && WCSData
			? {
					attributes: [
						{
							name: type,
							params: {
								selectedSite: siteId,
								selectedService: serviceId,
								chatBotToken: chatBotToken,
								origin: WCSData.originWeb,
								segment: segment,
							},
						},
					],
			  }
			: serviceId
			? {
					attributes: [
						{
							name: type,
							params: {
								selectedSite: siteId,
								selectedService: serviceId,
							},
						},
					],
			  }
			: {
					attributes: [
						{
							name: type,
							params: {
								selectedSite: siteId,
							},
						},
					],
			  };
		return body;
	}

	public reset(): void {
		this.dataInMf = [];
		this.entrePointsRes = [];
		this.allowedRes = [];
	}

	public setClientType(): void {
		const isMicroResegmented: boolean = this.utilities.isConsumerAndHasMicro();
		const customerType: string = this.storage.userProfile.customerType.toLowerCase();
		if (customerType === CustomerType.Consumer.toLowerCase() && !isMicroResegmented) {
			this.isMe = false;
		} else {
			this.isMe = true;
		}
	}

	public headersData(): object {
		const headers: HttpHeaders = new HttpHeaders();
		headers.append('Content-Type', 'application/json');
		const options: object = {
			headers: headers,
			withCredentials: true,
		};
		return options;
	}

	public getEntryPoints(
		screenCode: string,
		customerAccountId: string,
		maxNumber?: number,
		insideJourney?: boolean,
		serviceId?: string,
		version?: string,
		cartId?: string,
		offerdescriptor?: string,
		interactionID?: string,
		rank?: string,
		channel?: string
	): Observable<any> {
		const url: string = this.entryPointsUrl(
			screenCode,
			customerAccountId,
			maxNumber,
			insideJourney,
			serviceId,
			version,
			cartId,
			offerdescriptor,
			interactionID,
			rank,
			channel
		);
		const options: object = this.headersData();
		if (screenCode === entryPointsMVA10.HSR) {
			this.terminalListLoaded = false;
			this.entryPointService.serviceId = '';
		}
		if (
			screenCode !== entryPointsMVA10.LAD ||
			!this.entryPointService.laEntryPoint ||
			Object.keys(this.entryPointService.laEntryPoint)?.length === 0
		) {
			return this.http.get(url, options).pipe(
				map((res: EntryPointResponseModel) => {
					this.responseEntrypoints = res;
					if (res.entryPoints.length > 0) {
						this.entryPointService.logicEntry(res, screenCode);
						if (res.entryPoints[0].entryPoint === entryPointsMVA10.LA_PEGA) {
							this.laEntryPoint = res.entryPoints[0];
						}
					} else {
						this.partialError = true;
					}
					this.entryPointService.identifyOTorDebt(this.responseEntrypoints, screenCode);
					return res.entryPoints.length > 0 ? true : false;
				}),
				catchError((error) => {
					return throwError(error);
				})
			);
		} else {
			this.entryPointService.logicEntry(this.entryPointService.laEntryPoint, screenCode);
			this.laEntryPoint = this.entryPointService.laEntryPoint.entryPoints[0];
			return of(true);
		}
	}

	public putResetCookie(postReset: boolean): void {
		this.postResetCookie.next(postReset);
	}

	public resetCookieWpega(url: string, params: object, options: object): Observable<string> {
		return this.http.post(url, params, options).pipe(
			map((res: any) => {
				this.cartId = res?.NTOL_TXID;
				this.postResetCookie.next(true);
				return res;
			}),
			catchError((err) => {
				this.errorReset = true;
				return throwError(err);
			})
		);
	}

	public resetCookieWOpega(url: string, params: object, options: object): Observable<string> {
		return this.http.post(url, params, options).pipe(
			map((res: any) => {
				this.cartId = res?.NTOL_TXID;
				return res;
			}),
			catchError((err) => {
				this.errorReset = true;
				return throwError(err);
			})
		);
	}

	public resetCookie(isMobileToPack?: boolean): Observable<any> {
		let url: string = API_URLS.Checkout.deteleReset
			.replace('{idClient}', this.subscriptionData.customerData.currentService.siteId)
			.replace(
				'{clientTypeID}',
				this.isMe ? commercialMobile.clientTypeMicro.toString() : commercialMobile.clientType.toString()
			);
		url = isMobileToPack ? url + `?idTypeJourney=mobileToPack` : url;
		const options: object = this.headersData();
		const params: object = isMobileToPack ? null : {};
		return this.resetCookieWOpega(url, params, options);
	}

	public getAllEntryPointData(): EntryPointModel {
		this.laEntryPoint.unsubscribeDiscounts = this.unsuscribeDiscounts;
		return this.laEntryPoint;
	}

	public getAllowedServices(journeyCode: string, customerAccountId: string, onlyAllowed: boolean): Observable<any> {
		let url: string = API_URLS.Checkout.allowedServices
			.replace('{journeyCode}', journeyCode)
			.replace('{customerAccountId}', customerAccountId)
			.replace('{onlyAllowed}', onlyAllowed.toString())
			.replace('{channel.id}', CommercialJourneys.entryPointsRequest.channel);
		this.translateService.get('v10').subscribe((text) => {
			url = url.concat('&version=', text.commercial.versionPortalEP);
		});
		if (this.cartId) {
			url += allowedServicesDef.query.replace('{cartid}', this.cartId);
		}

		const headers: HttpHeaders = new HttpHeaders();
		const options: object = {
			headers: headers,
		};
		return this.http.get(url, options).pipe(
			map((res: any) => {
				if (res.allowedServices.length > 0) {
					this.allowedServices.allowedLogic(res.allowedServices);
				} else {
					this.partialError = true;
				}
			}),
			catchError((err) => {
				return throwError(err);
			})
		);
	}

	public getAllowedLinesServices(): void {
		let url: string = API_URLS.Checkout.allowedLinesServices
			.replace('{clientId}', this.subscriptionData.customerData.currentService.siteId)
			.replace('{shopType}', this.getShopType())
			// jsonpath refactor: added join() due to replace typescript error
			.replace('{listAllowedMsisdn}', this.allowedServices.listAllowedMsisdn?.join())
			.replace('{listAllowedCartItemId}', this.allowedServices.listAllowedCartItemId?.join());
		const headers: HttpHeaders = new HttpHeaders();
		const options: CommercialOptions = {
			headers: headers,
			auth_ticket: this.storage.getLocalStorage(LOCAL_STORAGE_KEYS.JWT),
		};
		this.allowedLinesData.next({ ready: false });
		this.http.get(url, options).subscribe(
			(res: MsidinPriority[]) => {
				const data: AllowedLinesData = {
					ready: true,
					msisdnList: res,
				};
				this.allowedLinesData.next(data);
				return true;
			},
			(err) => {
				if (err.error) {
					this.tagginURL(err.error, err.status, err.transactioId);
				}
				this.allowedLinesData.next({ ready: true });
				return true;
			}
		);
	}

	public tagginURL(error: errorRes, http: string, transactioId: string): void {
		const pageError: TaggingViewModel = { ...tagging.servicesErrorAllowed.page };
		const dataError: TaggingViewModel = { ...tagging.servicesErrorAllowed.data };
		dataError.event_label = error.message?.toLowerCase();
		pageError.page_name =
			error.errorType?.toLowerCase() === errorFunction
				? pageError.page_name.replace(errorTecnichalAnalitycs, errorFunctionAnalitycs)
				: pageError.page_name;
		dataError.journey_category = this.tagging.getUserType(this.storage.userProfile.customerType, ClientTypology);
		dataError.journey_detail =
			error.errorType?.toLowerCase() === errorFunction ? errorFunctionAnalitycs : errorTecnichal;
		dataError.journey_subcategory = this.utilities.isPurePrepaid() ? journeyTags.prepaid : journeyTags.pospaid;
		dataError['&&events'] =
			error.ecode === VERIFYEMAIL.errorPasswordNotSynced ? errorConstants.Event114 : errorConstants.Event115;
		dataError.event_context = dataError.event_context.replace('<ec>', error.ecode);
		dataError.error_list = dataError.error_list
			.replace('<ec>', error.ecode?.toLowerCase())
			.replace('<ed>', error.message?.toLowerCase())
			.replace('<et>', error.errorType?.toLowerCase())
			.replace('<ep>', terminalDetailErrorPaths.allowedLines)
			.replace('<em>', error.systemOrigin?.toLowerCase());
		dataError.error_descripcion = error.message?.toLowerCase();
		dataError.error_code = error.ecode?.toLowerCase();
		dataError.error_path = terminalDetailErrorPaths.allowedLines;
		dataError.error_origin = error.systemOrigin?.toLowerCase();
		dataError.error_type = error.errorType?.toLowerCase();
		dataError.error_http = http;
		dataError.error_module = error.systemOrigin?.toLowerCase();
		this.tagging.viewReplace(false, pageError, dataError);
	}

	getShopType(): string {
		const customerType: string = this.storage.userProfile.customerType.toLowerCase();
		const isMicroResegmented: boolean = this.utilities.isConsumerAndHasMicro();
		const shopTypeMicro: string = this.translateService.instant('v10.commercial.request_config.shopType_Micro');
		const shopTypePartis: string = this.translateService.instant('v10.commercial.request_config.shopType_Partis');

		return isMicroResegmented || customerType !== CustomerType.Consumer.toLowerCase() ? shopTypeMicro : shopTypePartis;
	}

	public entryPointsUrl(
		screenCode: string,
		customerAccountId: string,
		maxNumber?: number,
		insideJourney?: boolean,
		serviceId?: string,
		version?: string,
		cartId?: string,
		offerdescriptor?: string,
		interactionID?: string,
		rank?: string,
		channel?: string
	): string {
		let url = '';
		maxNumber
			? (url = API_URLS.EntryPointManagement.entryPointsWithMaxNumber
					.replace('{screenCode}', screenCode)
					.replace('{customerAccountId}', customerAccountId)
					.replace('{maxNumber}', String(maxNumber))
					.replace('{channel.id}', CommercialJourneys.entryPointsRequest.channel))
			: (url = API_URLS.EntryPointManagement.entryPointsWithOutMaxNumber
					.replace('{screenCode}', screenCode)
					.replace('{customerAccountId}', customerAccountId)
					.replace('{channel.id}', CommercialJourneys.entryPointsRequest.channel));

		if (serviceId) {
			url = url.concat('&serviceId=', serviceId);
		}
		if (cartId && cartId !== '') {
			url = url.concat('&cartId=', cartId);
		}
		if (offerdescriptor) {
			url = url.concat('&offerVFDescriptor=', encodeURIComponent(offerdescriptor));
		}
		if (interactionID) {
			url = url.concat('&commsInteractionID=', encodeURIComponent(interactionID));
		}
		if (rank) {
			url = url.concat('&commsRank=', encodeURIComponent(rank));
		}
		if (channel) {
			url = url.concat('&commsChannel=', encodeURIComponent(channel));
		}
		this.translateService.get('v10').subscribe((text) => {
			url = url.concat('&version=', text.commercial.versionPortalEP);
		});
		return url;
	}

	getRecommendations(fields?: string, screenCode?: string, offerVFDescriptor?: string): Observable<any> {
		let url: string = API_URLS.Recommendation.getRecommendation.replace(
			'{customerAccountId}',
			this.subscriptionData.customerData.customerAccountsId
		);

		let headers: HttpHeaders = new HttpHeaders();
		headers = headers.append('Content-Type', 'application/json');
		headers = headers.append(
			'Authorization',
			'Bearer ' + this.storage.getLocalStorage(LOCAL_STORAGE_KEYS.ACCESS_TOKEN)
		);

		if (fields) {
			url = url.concat('&fields=', fields);
		}
		if (screenCode) {
			url = url.concat('&screenCode=', screenCode);
		}
		if (offerVFDescriptor) {
			url = url.concat('&offerVFDescriptor=', encodeURIComponent(offerVFDescriptor));
		}

		return this.http.get(url, { headers }).pipe(
			map((res: any) => {
				if (typeof res.recommendedOffers !== 'undefined') {
					res.recommendedOffers.forEach((offer) => {
						if (typeof offer.discounts !== 'undefined') {
							offer.discounts.forEach((discount) => {
								if (discount.type === 'Origin') {
									this.offerDiscounts.push({
										code: discount.code,
										instance: discount.instance,
										type: discount.type,
										dtoStart: discount.dtoStart,
										dtoExpire: discount.dtoExpire,
										dtoDuration: discount.dtoDuration,
										amount: discount.amount,
										netAmount: discount.netAmount,
									});
								}
							});
						}
					});
				}
				if (res.product) {
					this.recoProductPackage = res.product;
					this.recoProductPackage.subscriptionsAmount = res.subscriptionsAmount
						? res.subscriptionsAmount
						: res.product.subscriptionsAmount;
					this.recoProductPackage.subscriptionsNetAmount = res.subscriptionsNetAmount
						? res.subscriptionsNetAmount
						: res.product.subscriptionsNetAmount;
				}
				if (this.offerDiscounts.length > 0) {
					this.unsuscribeDiscounts = '';
				}
				this.errorReco = false;
				return res;
			}),
			catchError((err) => {
				this.errorReco = true;
				this.getErrorReco = err;
				const url: string = API_URLS.Checkout.deteleReset
					.replace('{idClient}', this.subscriptionData.customerData.currentService.siteId)
					.replace(
						'{clientTypeID}',
						this.isMe ? commercialMobile.clientTypeMicro.toString() : commercialMobile.clientType.toString()
					);
				const options: object = this.headersData();
				const params: object = {};
				const obs$: Observable<string> = this.resetCookieWpega(url, params, options);
				this.postResetCookie.next(false);
				return obs$;
			})
		);
	}

	public logicRetry(): void {
		this.retry++;
		this.partialError = this.retry >= 3;
	}

	public cleanMf(): void {
		this.screenCode = '';
	}

	public setTrackErrorTaggingLogic(
		_microflow: string,
		_access: string,
		_typeError: string,
		_listError: Array<ErrorList>,
		_event: boolean = false
	): void {
		const pageError: string = _typeError === errorTecnichal ? errorTecnichalAnalitycs : errorFunctionAnalitycs;
		const page: TaggingViewModel = { ...tagging.servicesError.page };
		page.page_subcategory_level_2 = page.page_subcategory_level_2.replace('{0}', _microflow);
		page.page_subcategory_level_3 = page.page_subcategory_level_3.replace('{0}', _access);
		page.navigation_level_3 = page.navigation_level_3.replace('{0}', _microflow);
		page.navigation_level_4 = page.navigation_level_4.replace('{0}', pageError);
		page.page_screen = page.page_screen.replace('{0}', pageError);
		page.page_name = `${page.page_section}:${page.page_subcategory_level_1}:${page.page_subcategory_level_2}:${page.page_subcategory_level_3}:${page.navigation_level_4}`;
		const journey: TaggingViewModel = { ...tagging.servicesError.journey };
		const journeyStep: string = `${_microflow}:${pageError}`;
		journey.journey_name = journey.journey_name.replace('{0}', _microflow);
		journey.journey_category = this.tagging.getUserType(this.storage.userProfile.customerType, ClientTypology);
		journey.journey_subcategory = this.utilities.isPurePrepaid() ? journeyTags.prepaid : journeyTags.pospaid;
		journey.journey_detail = journey.journey_detail.replace('{0}', pageError);
		journey.journey_step = journey.journey_step.replace('{0}', journeyStep);
		const state: string = page.page_name;
		let context_data: TaggingViewModel = { ...page, ...journey };
		if (_listError?.length > 0) {
			let cadena: string = '';
			_listError.forEach((item: ErrorList) => {
				let _auxCadena: string = cadErrorServices;
				_auxCadena = _auxCadena
					.replace('{0}', item.type)
					.replace('{1}', item.error?.error?.code || '')
					.replace('{2}', item.error?.error?.message?.toLocaleLowerCase() || undefinedType)
					.replace('{3}', item.error?.status?.toString() || undefinedType)
					.replace('{4}', item.path.toLocaleLowerCase());
				_auxCadena += `,`;
				cadena += _auxCadena;
			});
			cadena = cadena.slice(0, -1);
			const contextErrorData: TaggingViewModel = { ...tagging.servicesError.errorData };
			contextErrorData.error_list = cadena;
			context_data = { ...context_data, ...contextErrorData };
		}
		const event: TaggingViewModel = _event
			? { ...tagging.servicesError.event }
			: { ...tagging.servicesError.eventBlocked };
		context_data = { ...context_data, ...event };
		this.tagging.track(state, context_data);
	}
}
