import {
	AfterContentChecked,
	ChangeDetectorRef,
	Component,
	ElementRef,
	EventEmitter,
	Input,
	OnChanges,
	OnDestroy,
	Output,
	SimpleChanges,
	ViewChild,
	ViewChildren,
} from '@angular/core';
import { ConfigurationService } from '../../../../app/core/services/configuration.service';
import { StorageService } from '../../../core/services/storage.service';
import { TaggingHelperService } from '../../../core/services/tagging.helper.service';
import { SSNDB, SwiperSelectedSlide } from '../../constants/defines';
import { iconSvgPath } from '../../enums/IconSvgPath.enum';
import { ServiceType } from '../../enums/serviceType.enum';
import { Swiper } from '../../models/swiper.model';
import { CustomerAccountService } from '../../services/customer-account.service';
import { SpServiceSelectorModalComponent } from '../sp-service-selector-modal/sp-service-selector-modal.component';
import { tagging } from './../../../../config/tagging-config';
import { Slide } from './slide.model';

declare var TweenLite: { to: Function };

@Component({
	selector: 'sp-swiper',
	templateUrl: './sp-swiper.component.html',
	styleUrls: ['./sp-swiper.component.scss'],
})
export class SpSwiperComponent implements OnDestroy, AfterContentChecked, OnChanges {
	@Output() addTopupFn: EventEmitter<void> = new EventEmitter<void>();
	@Input() public noWrap: boolean;
	@Input() public noPause: boolean;
	@Input() public noTransition: boolean;
	@Input() public slidesData: Array<Slide>;
	@Input() public defaultCheckedId: number = 0;
	@Input() public showAdd = true;
	@Input() public emitClickOnInitial = true;
	@Input() public firstSlide: Slide;
	@Output() public selectedService = new EventEmitter();
	@ViewChild('fullModal', { static: true }) fullModal: SpServiceSelectorModalComponent;

	forceShowAddBtn: boolean = false;

	destroyed: boolean = false;
	currentSlide: Slide;
	addService: boolean = false;
	addNewTopup: boolean;
	public allSlides: Array<Slide>;
	@Input() public ShowFirstAdd: boolean = false;
	defaultSelected: string = '';
	private index: number = 0;
	move: number = 0;
	@ViewChildren('image') images: Swiper;
	@ViewChildren('parentSwiper') parentDiv: Swiper;
	icon_addOne: string = iconSvgPath.icon_addOne;
	constructor(
		public customerAccountService: CustomerAccountService,
		private tagging: TaggingHelperService,
		public storage: StorageService,
		private changeDetector: ChangeDetectorRef,
		private configService: ConfigurationService
	) {
		this.checkUserProfile();
	}
	public ngOnInit(): void {
		this.addSlide();
	}
	ngAfterContentChecked(): void {
		this.checkUserProfile();
	}
	ngOnChanges(changes: SimpleChanges): void {
		if (changes['slidesData']) {
			this.allSlides = new Array<Slide>();
			if (this.firstSlide) {
				this.allSlides.push(this.firstSlide);
			}
			this.slidesData.forEach((slide) => {
				this.allSlides.push(slide);
			});
			this.changeDetector.detectChanges();
			this.addSlide();
		}
	}
	public ngOnDestroy(): void {
		this.destroyed = true;
		this.fullModal = null;
	}
	select(currentSlide: Slide, isEmitClickOnInitial: boolean = true): void {
		this.defaultSelected = '';
		this.addService = false;
		this.allSlides.map((el) => {
			if (el.id === currentSlide.id) {
				el.selected = SSNDB.selected;
				el.checked = true;
				el.radioDisabled = false;
			} else {
				el.selected = '';
				el.checked = false;
				el.radioDisabled = true;
			}
		});

		if (currentSlide && currentSlide !== this.currentSlide) {
			this.goNext(currentSlide);
		}
		this.navigate();
		if (currentSlide.type === ServiceType.firstAdd) {
			this.addTopupFn.emit();
		} else {
			if (isEmitClickOnInitial) {
				this.selectedService.emit(currentSlide.id);
			}
		}
	}
	navigate(): void {
		if (this.images) {
			const images: ElementRef[] = this.images._results as ElementRef[];
			const parentDiv: ElementRef[] = this.parentDiv._results as ElementRef[];
			let currentIndex: number;
			if (this.currentSlide) {
				if (this.firstSlide) {
					currentIndex = this.currentSlide.index + 1;
				} else {
					currentIndex = this.currentSlide.index;
				}
				const scrollToCalc: number =
					images[currentIndex].nativeElement.offsetLeft -
					parentDiv[0].nativeElement.clientWidth / 2 +
					images[currentIndex].nativeElement.clientWidth / 2;
				TweenLite.to(parentDiv[0].nativeElement, 0.5, { scrollTo: { x: scrollToCalc } });
			}
		}
	}
	private goNext(slide: Slide): void {
		if (this.destroyed) {
			return;
		}

		slide.active = true;

		if (this.currentSlide) {
			this.currentSlide.active = false;
		}

		this.currentSlide = slide;
	}

	private getSlideByIndex(index: number): Slide {
		const len: number = this.allSlides.length;
		for (let i: number = 0; i < len; ++i) {
			if (this.allSlides[i].index === index) {
				return this.allSlides[i];
			}
		}
	}

	private getCurrentIndex(): number {
		return !this.currentSlide ? 0 : this.currentSlide.index;
	}

	private next(): void {
		const newIndex: number = (this.getCurrentIndex() + 1) % this.allSlides.length;

		if (newIndex === 0 && this.noWrap) {
			return;
		}

		return this.select(this.getSlideByIndex(newIndex));
	}

	private prev(): void {
		const newIndex: number = this.getCurrentIndex() - 1 < 0 ? this.allSlides.length - 1 : this.getCurrentIndex() - 1;

		if (this.noWrap && newIndex === this.allSlides.length - 1) {
			return;
		}

		return this.select(this.getSlideByIndex(newIndex));
	}

	public addSlide(): void {
		if (this.allSlides.length > 1 || this.defaultCheckedId !== 0) {
			this.allSlides.map((el) => {
				if (el.id === this.defaultCheckedId.toString()) {
					el.checked = true;
					this.configService.configuration.showMovementsHistoryNewScreen
						? this.select(el, false)
						: this.select(el, this.emitClickOnInitial);
				}
			});
		} else {
			this.select(this.allSlides[this.index]);
		}
	}
	add(): void {
		this.tagging.track(tagging.serviceSelector.start.eventName, tagging.serviceSelector.start.data);
		this.defaultSelected = SwiperSelectedSlide.Selected;
		this.fullModal.nonDashoard = true;
		this.fullModal.show();
		this.addService = true;
		setTimeout(() => {
			this.defaultSelected = '';
		}, 500);
	}
	checkType(type: string): boolean {
		if (
			type.toLowerCase() === ServiceType.ADSL.toLowerCase() ||
			type.toLowerCase() === ServiceType.Fibre.toLowerCase() ||
			type.toLowerCase() === ServiceType.Internet.toLowerCase() ||
			type.toLowerCase() === ServiceType.Tv.toLowerCase()
		) {
			return false;
		} else {
			return true;
		}
	}

	private checkUserProfile(): void {
		if (
			(this.customerAccountService.customerAccounts && this.customerAccountService.customerAccounts.length > 1) ||
			(this.storage.userProfile &&
				((this.storage.userProfile.companies && this.storage.userProfile.companies.length > 1) ||
					(this.storage.userProfile.sites &&
						this.storage.userProfile.sites.length > 1 &&
						this.customerAccountService.isP1Sites(this.storage.userProfile))))
		) {
			this.forceShowAddBtn = true;
		}
	}
}
