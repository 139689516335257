import { HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Params } from '@angular/router';
import { IconType } from '@mva10/mva10-angular';
import { TranslateService } from '@ngx-translate/core';
import * as _ from 'lodash';
import moment from 'moment';
import { DeviceDetectorService } from 'ngx-device-detector';
import { map } from 'rxjs/operators';
import { environment } from '../../../environments/environment';
import { StorageService } from '../../core/services/storage.service';
import { SubscriptionService } from '../../core/services/subscription.service';
import { IconClassNames } from '../../shared/enums/iconClassNames.enum';
import { ServiceType } from '../../shared/enums/serviceType.enum';
import { ServiceTypeAnalitics } from '../../shared/enums/serviceTypeAnalitics.enum';
import { CompanyService } from '../../shared/services/company.service';
import { CLIENT_RS, customerTypes, returnDefines, SEGMENT_MICRO, tabsMyOrders } from '../constants/defines';
import { BundleType } from '../enums/bundleType.enum';
import { DayName } from '../enums/day-name.enum';
import { FaultManagmentServiceType } from '../enums/fault-managment-service-type.enum';
import { MonthName } from '../enums/monthName.enum';
import { OperatingSystem } from '../enums/operating-systems.enum';
import { ServiceTypeSpanish } from '../enums/service-types-spanish';
import { SERVICES_TYPES } from '../enums/servicesTypes.enum';
import { shortMonthName } from '../enums/shortMonthName';
import { SiteStatus } from '../enums/siteStatus.enum';
import { Company } from '../models/company.model';
import { Dashboard } from '../models/dashboard.model';
import { ServiceModel } from '../models/service.model';
import { UnitCode } from './../enums/unitCode.enum';
const { version: appVersion } = require('../../../../package.json');
declare global {
	interface Navigator {
		msSaveBlob: (blobOrBase64: Blob | string, filename: string) => void;
	}
}
@Injectable()
export class UtilitiesService {
	public deviceInfo: any;
	result;
	finalResult;
	public downloadInprogres: boolean;
	/**
	 * Method for retreiving a new refernce of the object and its children objects
	 * @param obj the object that will be cloned to another object
	 */
	static deepCopy(obj: any): any {
		return JSON.parse(JSON.stringify(obj));
	}
	constructor(
		private deviceService: DeviceDetectorService,
		private translate: TranslateService,
		private subscriptionService: SubscriptionService,
		private storage: StorageService,
		private companyService: CompanyService
	) { }
	public getNewDataRef(array: any[]) {
		const newArray = [];
		array.map((el) => {
			newArray.push(JSON.parse(JSON.stringify(el)));
		});
		return newArray;
	}
	public getDeviceInfo() {
		this.deviceInfo = this.deviceService.getDeviceInfo();
	}
	validateIBAN(IBAN) {
		IBAN = IBAN.toUpperCase();
		// remove spaces from beginning and end of the string
		IBAN = IBAN.trim();
		// remove spaces from th string
		IBAN = IBAN.replace(/\s/g, '');
		let letra1, letra2, num1, num2;
		let isbanaux;
		// IBAN must  be 24 characters
		if (IBAN.length !== 24) {
			return false;
		}
		// convert first two letter to numbers
		letra1 = IBAN.substring(0, 1);
		letra2 = IBAN.substring(1, 2);
		num1 = this.convertLetterToNum(letra1);
		num2 = this.convertLetterToNum(letra2);
		isbanaux = String(num1) + String(num2) + IBAN.substring(2);
		isbanaux = isbanaux.substring(6) + isbanaux.substring(0, 6);
		const resto = this.modulo97(isbanaux);
		if (Number(resto) === 1) {
			return true;
		} else {
			return false;
		}
	}
	modulo97(iban) {
		const parts = Math.ceil(iban.length / 7);
		let remainer = '';
		for (let i = 1; i <= parts; i++) {
			remainer = String(parseFloat(remainer + iban.substr((i - 1) * 7, 7)) % 97);
		}
		return remainer;
	}
	/** function to convert letter to  number */
	convertLetterToNum(letra) {
		let ls_letras;
		ls_letras = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ';
		return ls_letras.search(letra) + 10;
	}
	group(keyOne: string, arr: Array<any>, keyTwo?: string) {
		this.result = _.chain(arr)
			.groupBy(keyOne)
			.toPairs()

			.map(function (pair) {
				return _.zipObject(['keyOne', 'items'], pair);
			})
			.value();
		if (keyTwo) {
			this.finalResult = new Array<any>();
			this.result.forEach((element) => {
				const keyOne = element.keyOne;
				const result2 = _.chain(element.items)
					.groupBy(keyTwo)
					.toPairs()
					.map(function (pair) {
						return _.zipObject(['keyTwo', 'items'], pair);
					})
					.value();
				this.finalResult.push({ keyOne: keyOne, items: result2 });
			});
			return this.finalResult;
		} else {
			return this.result;
		}
	}

	getCookie(cname) {
		const name = cname + '=';
		const cookiesArray = document.cookie.split(';');

		for (let index = 0; index < cookiesArray.length; index++) {
			let cookie = cookiesArray[index];
			while (cookie.charAt(0) === ' ') {
				cookie = cookie.substring(1);
			}
			if (cookie.indexOf(name) === 0) {
				return cookie.substring(name.length, cookie.length);
			}
		}
		return '';
	}

	public deleteCookie(_nameCookie: string): void {
		document.cookie = _nameCookie + '=;expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;';
	}

	getDayDiffBetween2DatesWithoutRound(firstDate: Date, secondDate: Date) {
		const date1 = new Date(firstDate);
		const date2 = new Date(secondDate);
		const timeDiff = Math.abs(date2.getTime() - date1.getTime());
		const diffDays = Math.trunc(timeDiff / (1000 * 3600 * 24));
		return diffDays;
	}

	setServiceIcon(serviceType: string) {
		let icon;
		switch (serviceType.trim().toLocaleLowerCase()) {
			case ServiceType.Tv.toLocaleLowerCase():
				icon = IconClassNames.icon_tv;
				break;
			case ServiceType.Mobile.toLocaleLowerCase():
				icon = IconClassNames.icon_mobile;
				break;
			case ServiceType.Internet.toLocaleLowerCase():
				icon = IconClassNames.icon_broadband_or_wifi;
				break;
			case ServiceType.Landline.toLocaleLowerCase():
				icon = IconClassNames.icon_busines_phone;
				break;
			case ServiceType.Postpaid.toLocaleLowerCase():
				icon = IconClassNames.icon_mobile_postpaid;
				break;
			case ServiceType.Prepaid.toLocaleLowerCase():
				icon = IconClassNames.icon_mobile_prepaid;
				break;
			case ServiceType.MbbPostpaid.toLocaleLowerCase():
			case ServiceType.MbbPrepaid.toLocaleLowerCase():
				icon = IconClassNames.icon_mbb;
				break;
			case ServiceType.Fibre.toLocaleLowerCase():
				icon = IconClassNames.icon_internet;
				break;
			case ServiceType.VodafoneOne.toLowerCase():
				icon = IconClassNames.icon_vodafone_one;
				break;
			case ServiceType.ADSL.toLocaleLowerCase():
				icon = IconClassNames.icon_adsl;
				break;
			case ServiceType.VodafoneEnTuCasa.toLocaleLowerCase():
				icon = IconClassNames.icon_busines_phone;
				break;
			default:
				break;
		}
		return icon;
	}
	parseDate(str, splitChar) {
		let date = str.split('T')[0];
		date = date.split(splitChar);
		return new Date(date[0], date[1] - 1, date[2]);
	}

	parseDateReversedDate(str: string, splitChar: string): Date {
		const date: string = str.split('T')[0];
		const seperatedDate: string[] = date.split(splitChar);
		return new Date(
			parseInt(seperatedDate[2], null),
			parseInt(seperatedDate[1], null) - 1,
			parseInt(seperatedDate[0], null)
		);
	}

	public checkTodayEqualityWithDayBeforeEndDate(date) {
		const today = new Date();
		// dayBeforeDate will return date - 24 hours
		const dayBeforeDate = new Date(+new Date(date) - 864e5);
		if (
			today.getDate() === dayBeforeDate.getDate() &&
			today.getMonth() === dayBeforeDate.getMonth() &&
			today.getFullYear() === dayBeforeDate.getFullYear()
		) {
			return true;
		} else {
			return false;
		}
	}
	getDayDiffBetween2Dates(firstDate: Date, secondDate: Date) {
		const date1 = new Date(firstDate);
		const date2 = new Date(secondDate);
		const timeDiff = Math.abs(date2.getTime() - date1.getTime());
		const diffDays = Math.round(timeDiff / (1000 * 3600 * 24));
		return diffDays;
	}
	daydiff(first, second) {
		return Math.trunc((second - first) / (1000 * 60 * 60 * 24));
	}
	hourDiff(newTime, oldTime) {
		// @newTime, @oldTime : is Date() object
		return Math.abs(newTime - oldTime) / 36e5; // return number in hours
	}

	getMonthName(month: string, isShortName?: boolean): string {
		let alphabeticMonth = '';
		switch (month) {
			case '01':
			case '1':
				alphabeticMonth = isShortName ? shortMonthName.January : MonthName.January;
				break;
			case '02':
			case '2':
				alphabeticMonth = isShortName ? shortMonthName.February : MonthName.February;
				break;
			case '03':
			case '3':
				alphabeticMonth = isShortName ? shortMonthName.March : MonthName.March;
				break;
			case '04':
			case '4':
				alphabeticMonth = isShortName ? shortMonthName.April : MonthName.April;
				break;
			case '05':
			case '5':
				alphabeticMonth = isShortName ? shortMonthName.May : MonthName.May;
				break;
			case '06':
			case '6':
				alphabeticMonth = isShortName ? shortMonthName.June : MonthName.June;
				break;
			case '07':
			case '7':
				alphabeticMonth = isShortName ? shortMonthName.July : MonthName.July;
				break;
			case '08':
			case '8':
				alphabeticMonth = isShortName ? shortMonthName.August : MonthName.August;
				break;
			case '09':
			case '9':
				alphabeticMonth = isShortName ? shortMonthName.September : MonthName.September;
				break;
			case '10':
				alphabeticMonth = isShortName ? shortMonthName.October : MonthName.October;
				break;
			case '11':
				alphabeticMonth = isShortName ? shortMonthName.November : MonthName.November;
				break;
			case '12':
				alphabeticMonth = isShortName ? shortMonthName.December : MonthName.December;
				break;
		}
		return alphabeticMonth;
	}
	/**
	 * result should be "Sunday: Domingo OR Monday: Lunes
												Tuesday: Martes OR Wednesday: Miércoles OR Thursday: Jueves OR
												Friday: Viernes" OR Saturday: Sábado
	* @param dayNumber must be between 0 (Sunday) and 6 (Saturday)
	*/
	getDayName(dayNumber: number) {
		let alphabeticDay: string;
		switch (+dayNumber) {
			case 0:
				alphabeticDay = DayName.sunday;
				break;
			case 1:
				alphabeticDay = DayName.monday;
				break;
			case 2:
				alphabeticDay = DayName.tuesday;
				break;
			case 3:
				alphabeticDay = DayName.wednesday;
				break;
			case 4:
				alphabeticDay = DayName.thursday;
				break;
			case 5:
				alphabeticDay = DayName.friday;
				break;
			case 6:
				alphabeticDay = DayName.saturday;
				break;
		}
		return alphabeticDay;
	}
	/** the key values must be numbers */
	sumObjOfArray(arr: any[], keyName: string) {
		let result = 0;
		arr.map((obj) => {
			result += +obj[keyName];
		});
		return result;
	}
	/** function to convert date from 2017-03-20T02:00 to 20/03 */
	formatDateToDayAndMonth(date: string) {
		if (date.includes('/')) {
			return date;
		}
		let formatedDate = date.split('T')[0];
		const fDate = formatedDate.split('-');
		formatedDate = fDate[2] + '/' + fDate[1];
		return formatedDate;
	}
	/** function to convert date from 2017-03-20T02:00 to 20/03/2017 or 20/03/17 */
	getDateFormatted(date: string, shortYear: boolean = false): string {
		if (!date) {
			return '';
		}
		if (date.includes('/')) {
			return date;
		}
		const splitDate = date.split('-');
		const year = splitDate[0];
		const month = splitDate[1];
		const day = splitDate[2].split('T')[0];

		return day + '/' + month + '/' + (shortYear ? year.substring(2) : year);
	}
	/** function return time*/
	getTime(date: string): string {
		if (!date) {
			return '';
		}
		const time = date.split('T')[1];
		return time;
	}
	converamountToPositive(amount) {
		let editAmount;
		let addDash: boolean;
		if (amount < 0) {
			editAmount = amount * -1;
			addDash = true;
		} else {
			editAmount = amount;
			addDash = false;
		}
		return { amount: editAmount, addDash: addDash };
	}
	/**
	 * result should be "09 de Feb" OR "09 de Febrero" OR "09 de Feb 2019" OR "09 de Febrero 2019"
	 * @param date date with ISO format
	 * @param isShortMonthName false(default): return Febrero, true: return Feb
	 * @param withoutYear false(default): return the date without year number, true: return with year number
	 * @param sync false(default): return Observable<string>, true: return string
	 * @param withDay false(default): return date without day name, true: return date with day name at first
	 */
	getDateWithMonthNameAsync(
		date,
		isShortMonthName = false,
		withoutYear = false,
		sync = false,
		withDayName = false
	): any {
		const arr = this.getDateFormatted(date).split('/');
		const monthName = this.getMonthName(arr[1], isShortMonthName);
		if (!sync) {
			return this.translate.get('billing.billDetails.fieldsList.fromText.body').pipe(
				map((data) => {
					return `${!withDayName ? '' : this.getDayName(this.parseDate(date, '-').getDay()) + ', '}${arr[0]
						} ${data} ${monthName}${withoutYear ? '' : ' ' + arr[2]}`;
				})
			);
		} else {
			let result: string;
			this.translate.get('billing.billDetails.fieldsList.fromText.body').subscribe((data) => {
				result = `${!withDayName ? '' : this.getDayName(this.parseDate(date, '-').getDay()) + ', '}${arr[0]
					} ${data} ${monthName}${withoutYear ? '' : ' ' + arr[2]}`;
			});
			return result;
		}
	}
	public currencyFormat(amount: string) {
		return +amount.split('.').join('').replace(',', '.');
	}
	public currencyFormatForPrePaid(amount: string) {
		const result = +amount;
		return result.toFixed(2);
	}

	translateStatusToSpanish(status) {
		let statusSP = status;

		switch (status.toLowerCase()) {
			case SiteStatus.Active.toLowerCase():
				statusSP = SiteStatus.Activo.toLowerCase();
				break;
			case SiteStatus.Suspending.toLowerCase():
				statusSP = SiteStatus.Suspendido.toLowerCase();
				break;
			case SiteStatus.Disconnected.toLowerCase():
				statusSP = SiteStatus.Desconectado.toLowerCase();
				break;
			case SiteStatus.Disconnect_Pend.toLowerCase():
				statusSP = SiteStatus.Pend_de_Desconectar.toLowerCase();
				break;
			case SiteStatus.Reconnect_Pend.toLowerCase():
				statusSP = SiteStatus.Pend_de_Reconectar.toLowerCase();
				break;
			case SiteStatus.Pending_Change.toLowerCase():
				statusSP = SiteStatus.Pend_de_Cambio.toLowerCase();
				break;
			default:
				break;
		}
		return statusSP;
	}
	convertDateTOISO(date) {
		// We add the timezone offset so that when calling toISOString we get the
		// same current date/time
		const tzoffset = new Date().getTimezoneOffset() * -1;
		date = moment(date).add(tzoffset, 'm').toDate();

		let isoDate = date.toISOString();
		isoDate = isoDate.split(':');
		return isoDate[0] + ':' + isoDate[1];
	}
	getIntersection(arr1: string[], arr2: string[]) {
		const intersection = [];
		arr1.map((data) => {
			arr2.map((data2) => {
				if (data.toLowerCase() === data2.toLowerCase()) {
					intersection.push(data);
				}
			});
		});
		return intersection;
	}
	downloadPDF(url: string, documentId?: string, skipTagging?: boolean, isHtmlFormat?: boolean): void {
		if (!this.downloadInprogres) {
			this.downloadInprogres = true;
			if (!skipTagging) {
				this.storage.onPdfDownload.next(documentId);
			}
			let headers: HttpHeaders = new HttpHeaders();
			headers = headers.append('accept', 'application/json');
			const options = {
				headers: headers,
				responseType: 'blob' as 'blob',
			};
			this.subscriptionService.DownloadFile(url, options).subscribe(
				(response) => {
					this.downloadInprogres = false;
					const blob: Blob = new Blob([response], { type: isHtmlFormat ? 'text/html' : 'application/pdf' });
					const objectUrl: string = URL.createObjectURL(blob);
					window.open(objectUrl);
				},
				(error) => {
					this.downloadInprogres = false;
				}
			);
		}
	}

	downloadBlob(fileToDownload: Blob, fileNameWithExtension: string): void {
		if (navigator.msSaveBlob) {
			// IE 10+
			navigator.msSaveBlob(fileToDownload, fileNameWithExtension);
		} else {
			const link: HTMLAnchorElement = document.createElement('a');
			if (link.download !== undefined) {
				// Browsers that support HTML5 download attribute
				const url: string = URL.createObjectURL(fileToDownload);
				link.setAttribute('href', url);
				link.setAttribute('download', fileNameWithExtension);
				link.style.visibility = 'hidden';
				document.body.appendChild(link);
				link.click();
				document.body.removeChild(link);
			}
		}
	}

	downloadBase64(base64Data: string, fileNameWithExtension: string = 'document.pdf'): void {
		this.downloadBlob(this.base64toBlob(base64Data), fileNameWithExtension);
	}

	base64toBlob(base64Stream: string, contentType: string = 'application/pdf', sliceSize: number = 512): Blob {
		const byteCharacters: string = atob(base64Stream);
		const byteArrays: Uint8Array[] = [];
		for (let offset: number = 0; offset < byteCharacters.length; offset += sliceSize) {
			const slice: string = byteCharacters.slice(offset, offset + sliceSize);
			const byteNumbers: number[] = new Array(slice.length);
			for (let i: number = 0; i < slice.length; i++) {
				byteNumbers[i] = slice.charCodeAt(i);
			}
			const byteArray: Uint8Array = new Uint8Array(byteNumbers);
			byteArrays.push(byteArray);
		}
		const blob: Blob = new Blob(byteArrays, { type: contentType });
		return blob;
	}

	/** Format date from 2017-06-01T08:00 to 1 Jun 2017 */
	getDateWithMonth(
		date: string,
		isShortMonthName: boolean = false,
		withYear: boolean = true,
		withDay: boolean = true
	): string {
		const arr: string[] = this.getDateFormatted(date).split('/');
		const monthName: string = this.getMonthName(arr[1], isShortMonthName);
		return (withDay ? arr[0] + ' ' : '') + monthName + (withYear ? ' ' + arr[2] : '');
	}

	// return true if today is bigger than date passed
	checkDateHasExpired(date) {
		if (date) {
			const todayDate: any = new Date();
			const expDate = new Date(date);
			if (todayDate > expDate) {
				return true;
			} else {
				return false;
			}
		}
	}

	sortByKey(arr, key) {
		return arr.sort((current, next) => {
			return next[key] - current[key];
		});
	}

	sortByKeyReverse(arr, key) {
		return arr.sort((current, next) => {
			return current[key] - next[key];
		});
	}

	sortByAlphabetical(arr, key) {
		return arr.sort((current, next) => {
			return next[key] > current[key];
		});
	}

	sortByAlphabeticalAsc(arr, key): void {
		arr.sort(function (a, b) {
			if (a[key] < b[key]) {
				return -1;
			}
			if (b[key] < a[key]) {
				return 1;
			}
			return 0;
		});
	}
	sortByAlphabeticalDesc(arr, key): void {
		arr.sort(function (a, b) {
			if (a[key] > b[key]) {
				return -1;
			}
			if (b[key] > a[key]) {
				return 1;
			}
			return 0;
		});
	}

	getFormatedDateWithTime(date: string): string {
		const splittedDate = date.split('-');
		const dayandTime = splittedDate[2].split('T');
		let result;
		this.getDateWithMonthNameAsync(date, false, true).subscribe((res) => {
			result = res;
			result += ' ' + dayandTime[1];
		});
		return result;
	}

	roundDecimals(number, decimalPlaces) {
		return +(Math.round(+(number + 'e+' + decimalPlaces)) + ('e-' + decimalPlaces));
	}
	getUnitCodeFromWCS = (unitCode: string) => {
		let wcsUnitCode;
		this.translate.get('dashboard.contentList.guage').subscribe((data) => {
			if (unitCode) {
				switch (unitCode.toLowerCase()) {
					case UnitCode.KB.toLowerCase():
						wcsUnitCode = data.kilobytes;
						break;
					case UnitCode.MB.toLowerCase():
						wcsUnitCode = data.megabytes;
						break;
					case UnitCode.GB.toLowerCase():
						wcsUnitCode = data.gigabytes;
						break;
					case UnitCode.Min.toLowerCase():
					case UnitCode.Mins.toLowerCase():
						wcsUnitCode = data.Mins;
						break;
					case UnitCode.SMS.toLowerCase():
						wcsUnitCode = data.SMS;
						break;
				}
			}
		});
		return wcsUnitCode;
	};
	getImageFromWCS(data, ListedData, product) {
		for (let item = 0; item < ListedData.length; item++) {
			if (ListedData[item].toLowerCase() === product.Code.split('_')[0].toLowerCase()) {
				return data.channelsList[ListedData[item]].medium_url
					? data.channelsList[ListedData[item]].medium_url
					: 'https://assets-es-pprd.dxlpreprod.local.vodafone.es/assets/images/layer.png';
			}
		}
	}

	getUnlimitedFromWCS(content: Dashboard) {
		if (content && content.type) {
			let result: string;
			this.translate.get('dashboard.contentList.guage').subscribe((data) => {
				switch (content.type.toLowerCase()) {
					case BundleType.SMS.toLowerCase():
						result = data.unlimited;
						break;
					case BundleType.Voice.toLowerCase():
					case BundleType.Fixed.toLowerCase():
						result = data.itemList.voiceUnlimited.body;
						break;
					case BundleType.Fibre.toLowerCase():
					case BundleType.ADSL.toLowerCase():
						result = data.itemList.internetUnlimited.body;
						break;
					case BundleType.Data.toLowerCase():
						result = data.itemList.rgkValue.body;
						break;
				}
			});
			return result;
		}
		return '';
	}

	convertMilisecondsToDays(miliseconds: any) {
		let days, total_hours: number, total_minutes, total_seconds: number;
		total_seconds = Math.trunc(Math.floor(miliseconds / 1000));
		total_minutes = Math.trunc(Math.floor(total_seconds / 60));
		total_hours = Math.trunc(Math.floor(total_minutes / 60));
		days = Math.trunc(Math.floor(total_hours / 24));
		return days;
	}

	public getCurrentSiteServices() {
		if (this.subscriptionService.customerData && this.subscriptionService.customerData.services) {
			return this.subscriptionService.customerData.services.filter((service) => {
				return service.siteId === this.subscriptionService.customerData.customerAccountsId;
			});
		}
		return [];
	}
	public getCurrentSiteStatus(): SiteStatus {
		const currentSiteServices: ServiceModel[] = this.getCurrentSiteServices();
		let activeStatus: boolean;
		let activeClientStatus: boolean;
		if (currentSiteServices.length) {
			activeStatus = currentSiteServices.every(
				(srv) =>
					srv.status &&
					(srv.status.toLowerCase() === SiteStatus.Active.toLowerCase() ||
						srv.status.toLowerCase() === SiteStatus.Activo.toLowerCase())
			);
			activeClientStatus = currentSiteServices.every(
				(srv) =>
					srv.clientStatus &&
					(srv.clientStatus.toLowerCase() === SiteStatus.Active.toLowerCase() ||
						srv.clientStatus.toLowerCase() === SiteStatus.Activo.toLowerCase())
			);
		}
		return activeStatus || activeClientStatus ? SiteStatus.Active : SiteStatus.Disconnected;
	}
	public getSpanishCurrentSiteStatus(): string {
		const currentSiteStatus = this.getCurrentSiteStatus();
		return this.translateStatusToSpanish(currentSiteStatus);
	}
	public isCurrentSiteServicesHas(serviceType: ServiceType) {
		const currentSiteServices = this.getCurrentSiteServices();
		if (currentSiteServices.length) {
			return !!currentSiteServices.find(
				(service) =>
					service.type.toLowerCase() === serviceType.toLowerCase() ||
					service.type.toLowerCase() === SERVICES_TYPES.TV.toLowerCase()
			);
		}
		return false;
	}
	public hasPospaidInPackage(): boolean {
		if (this.subscriptionService.serviceListSite.length) {
			return this.subscriptionService.serviceListSite.some(
				(srv: ServiceModel) => srv.package && srv.type.toLowerCase() === ServiceType.Postpaid.toLowerCase()
			);
		}
		return false;
	}
	public hasOverdue(): boolean {
		if (this.subscriptionService.serviceListSite.length) {
			return this.subscriptionService.serviceListSite.some((srv: ServiceModel) => srv.overdue);
		}
		return false;
	}
	public isPurePrepaid(): boolean {
		const currentSiteServices: ServiceModel[] = this.getCurrentSiteServices();
		if (currentSiteServices.length) {
			return currentSiteServices.every(
				(srv: ServiceModel) => srv.type.toLowerCase() === ServiceType.Prepaid.toLowerCase()
			);
		}
		return false;
	}
	public isMixedPrepaid(): boolean {
		const currentSiteServices: ServiceModel[] = this.getCurrentSiteServices();
		if (currentSiteServices.length) {
			return currentSiteServices.some(
				(srv: ServiceModel) => srv.type.toLowerCase() === ServiceType.Prepaid.toLowerCase()
			);
		}
		return false;
	}
	public browserName() {
		const sUsrAg = navigator.userAgent;
		let sBrowser = navigator.userAgent;

		// The order matters here, and this may report false positives for unlisted browsers.

		if (sUsrAg.indexOf('Firefox') > -1) {
			sBrowser = 'Firefox';
			// 'Mozilla/5.0 (X11; Ubuntu; Linux x86_64; rv:61.0) Gecko/20100101 Firefox/61.0'
		} else if (sUsrAg.indexOf('Opera') > -1 || sUsrAg.indexOf('OPR') > -1) {
			sBrowser = 'Opera';
			// 'Mozilla/5.0 (Macintosh; Intel Mac OS X 10_14_0)
			// AppleWebKit/537.36 (KHTML, like Gecko) Chrome/70.0.3538.102 Safari/537.36 OPR/57.0.3098.106'
		} else if (sUsrAg.indexOf('Trident') > -1) {
			sBrowser = 'IE';
			// 'Mozilla/5.0 (Windows NT 10.0; WOW64; Trident/7.0;
			// .NET4.0C; .NET4.0E; Zoom 3.6.0; wbx 1.0.0; rv:11.0) like Gecko'
		} else if (sUsrAg.indexOf('Edge') > -1) {
			sBrowser = 'Edge';
			// 'Mozilla/5.0 (Windows NT 10.0; Win64; x64) AppleWebKit/537.36 (KHTML, like Gecko)
			// Chrome/58.0.3029.110 Safari/537.36 Edge/16.16299'
		} else if (sUsrAg.indexOf('Chrome') > -1) {
			sBrowser = 'Chrome';
			// 'Mozilla/5.0 (X11; Linux x86_64) AppleWebKit/537.36 (KHTML, like Gecko)
			// Ubuntu Chromium/66.0.3359.181 Chrome/66.0.3359.181 Safari/537.36'
		} else if (sUsrAg.indexOf('Safari') > -1) {
			sBrowser = 'Safari';
			// 'Mozilla/5.0 (iPhone; CPU iPhone OS 11_4 like Mac OS X) AppleWebKit/605.1.15
			// (KHTML, like Gecko) Version/11.0 Mobile/15E148 Safari/604.1 980x1306'
		} else {
			sBrowser = 'web';
		}

		return sBrowser;
	}
	public getCategoryIcon(category: FaultManagmentServiceType) {
		switch (category) {
			case FaultManagmentServiceType.Mobile:
				return IconClassNames.icon_mobile;
			case FaultManagmentServiceType.Internet:
				return IconClassNames.icon_country;
			case FaultManagmentServiceType.Fixed:
				return IconClassNames.icon_busines_phone;
			case FaultManagmentServiceType.TV:
				return IconClassNames.icon_tv;

			default:
				break;
		}
	}
	// From 2018-12-30T22:00 to date format like Martes, 30/12/2018  22:00h
	public dateWithDayName(date) {
		const currentDate = new Date(date);
		const splitDate = date.split('-');
		const year = splitDate[0];
		const month = splitDate[1];
		const day = splitDate[2].split('T')[0];
		const hours = date.split('T')[1].split(':')[0];
		const minties = date.split('T')[1].split(':')[1];
		return `${this.getDayName(currentDate.getDay())},
      ${day}/${month}/${year}  ${hours}:${minties}h`;
	}
	// From 2018-12-30T22:00 to date format like 22:00,30/12/2018
	public dateFormateWithHours(date) {
		const splitDate = date.split('-');
		const year = splitDate[0];
		const month = splitDate[1];
		const day = splitDate[2].split('T')[0];
		const hours = date.split('T')[1].split(':')[0];
		const minties = date.split('T')[1].split(':')[1];
		return `${hours}:${minties},
      ${day}/${month}/${year}`;
	}

	/**merge 2 arrays */
	mergeArrays(firstArray, secondArray) {
		const combineArrays = [];
		for (let index = 0; index < firstArray.length; index++) {
			if (secondArray.length > 0 && secondArray[index]) {
				combineArrays.push({ ...firstArray[index], ...secondArray[index] });
			} else {
				combineArrays.push(firstArray[index]);
			}
		}
		return combineArrays;
	}

	isEmptyObject(obj) {
		return obj && Object.keys(obj).length === 0;
	}

	/** format date to  1/01/001 */
	getStringDateFormat(date: Date): string {
		return date.getDate() + '/' + (date.getMonth() + 1) + '/' + date.getFullYear();
	}

	/** format date to  01/01/2020 */
	getDateFormatedAsDMY(date: string): string {
		const creationDate: Date = new Date(date);
		let day: string = creationDate.getDate().toString();
		let month: string = (creationDate.getMonth() + 1).toString();
		const fullYear: number = creationDate.getFullYear();
		if (+day < 10) {
			day = `0${day}`;
		}
		if (+month < 10) {
			month = `0${month}`;
		}
		date = `${day}/${month}/${fullYear}`;
		return date;
	}

	/** check if two dates are equal */
	checkIfTwoDatesAreEqual(date1: string, date2: string): boolean {
		const firstDate: Date = new Date(date1);
		const secondDate: Date = new Date(date2);
		if (firstDate.getTime() === secondDate.getTime()) {
			return true;
		} else {
			return false;
		}
	}
	checkIfTwoDatesAreGreaterEqual(date1: string, date2: string): boolean {
		const _date1: Date = new Date(date1);
		const _date2: Date = new Date(date2);
		return _date1 >= _date2;
	}

	/** format date to  1 jun */
	getDateWithMonthWithoutYear(date, isShortMonthName = false) {
		const arr = this.getDateFormatted(date).split('/');
		const monthName = this.getMonthName(arr[1], isShortMonthName);
		return arr[0] + ' ' + monthName;
	}
	detectDevice() {
		if (
			navigator.userAgent.match(/Android/i) ||
			navigator.userAgent.match(/webOS/i) ||
			navigator.userAgent.match(/iPhone/i) ||
			navigator.userAgent.match(/iPad/i) ||
			navigator.userAgent.match(/iPod/i) ||
			navigator.userAgent.match(/BlackBerry/i) ||
			navigator.userAgent.match(/Windows Phone/i)
		) {
			return true;
		} else {
			return false;
		}
	}
	/**
	 * Method to evaluate the app version
	 * @param minVersion
	 */
	releaseHigherThan(minVersion: string): boolean {
		const appVersionArr = appVersion.split('.').map((char: string) => parseInt(char, 10));
		const minVersionArr = minVersion.split('.').map((char: string) => parseInt(char, 10));
		return appVersionArr[0] >= minVersionArr[0] && appVersionArr[1] >= minVersionArr[1];
	}

	/**
	 * Returns route params: object or undefined
	 * @param route
	 */
	getRouteParams(route: string): Params {
		const query = {};
		if (route.split('?')[1]) {
			const queryString: string = route.split('?')[1];
			const pairs: string[] = (queryString.startsWith('?') ? queryString.substring(1) : queryString).split('&');
			for (const element of pairs) {
				const pair: string[] = element.split('=');
				if (pair[0]?.length > 0 && pair[1]?.length > 0) {
					query[decodeURIComponent(pair[0])] = decodeURIComponent(pair[1]);
				}
			}
		}
		return query;
	}

	/** check if the device OS the same as argument that sent */
	checkDeviceOperatingSystem(osType: OperatingSystem) {
		return navigator.userAgent.indexOf(osType) !== -1;
	}
	formatDateWithTimeOffset(date: number) {
		if (!date) {
			return '';
		}
		const dateObj = new Date(date);
		const dateString = dateObj.toLocaleDateString();

		const dateTimeArray = dateObj.toLocaleTimeString().split(':');
		const dateTime = dateTimeArray[0] + ':' + dateTimeArray[1];
		return dateString + ', ' + dateTime;
	}

	public stringToCamelCase(text: string): string {
		const textToModify: Array<string> = text.trim().toLocaleLowerCase().split(' ');
		textToModify.forEach((string, index) => {
			textToModify[index] = string.charAt(0).toLocaleUpperCase().concat(string.slice(1));
		}, true);
		return textToModify.join(' ');
	}

	capitalizeText(text: string) {
		return (text || '')
			.toLowerCase()
			.split(' ')
			.map((s) => s.charAt(0).toUpperCase() + s.substring(1))
			.join(' ');
	}

	mapServiceIcon(type: ServiceType | string): string {
		let iconType;
		switch (type.trim().toLowerCase()) {
			case ServiceType.Postpaid.trim().toLowerCase():
				iconType = IconType.ICON_VOICE;
				break;
			case ServiceType.MbbPostpaid.trim().toLowerCase():
			case ServiceType.MbbHoliday:
				iconType = IconType.ICON_MBB;
				break;
			case ServiceType.Prepaid.trim().toLowerCase():
				iconType = IconType.ICON_VOICE;
				break;
			case ServiceType.MbbPrepaid.trim().toLowerCase():
				iconType = IconType.ICON_MBB;
				break;
			case ServiceType.Landline.trim().toLowerCase():
			case ServiceType.VodafoneEnTuCasa:
				iconType = IconType.ICON_LANDLINE;
				break;
			case ServiceType.Tv.trim().toLowerCase():
			case ServiceType.TvOnline.trim().toLowerCase():
				iconType = IconType.ICON_TV;
				break;
			case ServiceType.ADSL.trim().toLowerCase():
				iconType = IconType.ICON_NETWORK;
				break;
			case ServiceType.Fibre.trim().toLowerCase():
				iconType = IconType.ICON_NETWORK;
				break;
			case ServiceType.Mobile.trim().toLowerCase():
				iconType = IconType.ICON_VOICE;
				break;
			case ServiceType.Internet.trim().toLowerCase():
				iconType = IconType.ICON_NETWORK;
				break;
			default:
				break;
		}
		return iconType;
	}

	public transformProductsAnalitics(type) {
		let result = '';
		switch (type.toUpperCase()) {
			case ServiceType.Postpaid.toUpperCase():
				result = ServiceTypeAnalitics.Postpaid;
				break;
			case ServiceType.MbbPostpaid.toUpperCase():
			case ServiceType.MbbHoliday.toUpperCase():
				result = ServiceTypeAnalitics.Mbb;
				break;
			case ServiceType.Prepaid.toUpperCase():
				result = ServiceTypeAnalitics.Prepaid;
				break;
			case ServiceType.MbbPrepaid.toUpperCase():
				result = ServiceTypeAnalitics.Mbb;
				break;
			case ServiceType.Landline.toUpperCase():
			case ServiceType.VodafoneEnTuCasa.toUpperCase():
				result = ServiceTypeAnalitics.Landline;
				break;
			case ServiceType.Tv.toUpperCase():
			case ServiceType.TvOnline.toUpperCase():
				result = ServiceTypeAnalitics.Tv;
				break;
			case ServiceType.ADSL.toUpperCase():
				result = ServiceTypeAnalitics.ADSL;
				break;
			case ServiceType.Fibre.toUpperCase():
				result = ServiceTypeAnalitics.Fibre;
				break;
		}
		return result;
	}

	/**
	 * Returns a string with the converted amout of data and its unit (from KB up to GB) formatted based on the entry parameters.
	 * For example: 74,30MB
	 * @param quantity Number of KB, MB or GB to convert
	 * @param unit KB, MB or GB
	 * @param unitSeparator String separator between the amount and the unit (defaults to '')
	 * @param decimals Fixed number of decimals (defaults to 2)
	 */
	formatBytes(quantity: number, unit: string, unitSeparator = '', decimals = 2): string {
		if (quantity === 0) {
			return '0' + unitSeparator + unit;
		}
		const conversionRate = 1024;
		if (unit.toUpperCase() === UnitCode.KB && quantity >= conversionRate) {
			quantity = quantity / conversionRate;
			unit = UnitCode.MB;
		}
		if (unit.toUpperCase() === UnitCode.MB && quantity >= conversionRate) {
			quantity = quantity / conversionRate;
			unit = UnitCode.GB;
		}
		return (
			quantity
				.toFixed(decimals < 0 ? 0 : decimals)
				.replace('.', ',')
				.replace(',00', '') +
			unitSeparator +
			unit
		);
	}

	getQuantityInKiloBytes(quantity: number, unit: string): number {
		if (quantity === 0) {
			return 0;
		}
		const conversionRate = 1024;
		if (unit.toUpperCase() === UnitCode.GB && quantity >= conversionRate) {
			quantity = quantity * conversionRate;
			unit = UnitCode.MB;
		}
		if (unit.toUpperCase() === UnitCode.MB && quantity >= conversionRate) {
			quantity = quantity * conversionRate;
			unit = UnitCode.KB;
		}
		return quantity;
	}

	/**
	 * Remove accentuated characters from a string
	 */
	removeAccents(str: string): string {
		return str?.normalize('NFD').replace(/[\u0300-\u036f]/g, '');
	}

	/**
	 * Method with sort criteria
	 */
	sortByAttr(a, b) {
		const sortKey = this['sortField'];
		return a[sortKey] > b[sortKey] ? 1 : b[sortKey] > a[sortKey] ? -1 : 0;
	}

	/**
	 * Function to convert to Date from string with dd/mm/yyyy, yyyy-mm-dd or mm-dd-yyyy format.
	 * @param date Formatted Date
	 * @param maintainHours Whether to keep original hours from date or not. If not specified, sets hours to 00:00:00
	 */
	getDateFromString(date: string, maintainHours?: boolean): Date {
		if (!date) {
			return null;
		}
		let formattedDate: Date = null;
		// If it is a hyphen date, convert directly
		if (date.includes('-')) {
			formattedDate = new Date(date);
		}
		// If it is a slash date, format properly
		else if (date.includes('/')) {
			const splitDate = date.split('/').map((val) => parseInt(val, 10));
			formattedDate = new Date();
			formattedDate.setFullYear(splitDate[2], splitDate[1] - 1, splitDate[0]);
		}
		// Avoid hours conversion if requested
		if (!maintainHours && formattedDate && formattedDate.getTime && !isNaN(formattedDate.getTime())) {
			formattedDate.setHours(0, 0, 0, 0);
		} else {
			if (date.includes(':')) {
				const hour: string = date.substring(date.indexOf(' '), date.length);
				const splitDate: number[] = hour.split(':').map((val) => parseInt(val, 10));
				formattedDate.setHours(splitDate[0], splitDate[1], splitDate[2], 0);
			}
		}
		return formattedDate;
	}

	/**
	 * Returns url matching config.PAGE_NAME.route from pages-config.ts file.
	 * Example: '/myPage/123456'
	 * @param route Current ActivatedRouteSnapshot
	 */
	getResolvedUrlFromRoute(route: ActivatedRouteSnapshot): string {
		if (!route || !route.pathFromRoot) {
			return null;
		}
		return (
			'/' +
			route.pathFromRoot
				.filter((path) => path.url.length !== 0)
				.map((path) => path.url.map((segment) => segment.toString()).join('/'))
				.join('/')
		);
	}

	/**
	 * Returns url matching config.PAGE_NAME.name from pages-config.ts file.
	 * Example: 'myPage/:id'
	 * @param route Current ActivatedRouteSnapshot
	 */
	getConfiguredNameFromRoute(route: ActivatedRouteSnapshot): string {
		if (!route || !route.pathFromRoot) {
			return null;
		}
		return route.pathFromRoot
			.filter((path) => path.routeConfig)
			.map((path) => path.routeConfig.path)
			.join('/');
	}

	/**
	 * Returns the date with the format "17 oct 2020".
	 */
	dateWithMonthNameString(date: string): string {
		const dateAux: Date = this.getDateFromString(date);
		let finalDate: string;

		const day: Number = dateAux.getDate();
		const month: Number = dateAux.getMonth() + 1;
		const year: Number = dateAux.getFullYear();

		finalDate = day.toString() + ' ' + this.getMonthName(month.toString(), true) + ' ' + year.toString();

		return finalDate;
	}

	/**
	 * Returns the date with the format "Enero 2020".
	 */
	dateWithMonthYearNameString(date: string): string {
		const dateAux: Date = this.getDateFromString(date);
		let finalDate: string;

		const month: number = dateAux.getMonth() + 1;
		const year: number = dateAux.getFullYear();

		finalDate = this.getMonthName(month.toString(), false) + ' ' + year.toString();

		return finalDate;
	}

	thousandNumber(item: string): string {
		if (parseFloat(item)) {
			const number = item
				.toString()
				.split('')
				.reverse()
				.join('')
				.replace(/(?=\d*\.?)(\d{3})/g, '$1.');
			const num = number.split('').reverse().join('').replace(/^[\.]/, '');
			return num;
		} else {
			return item;
		}
	}

	/**
	 * Iterates through the given object to remove empty values recursively and returns a new object with the updated values
	 * @param objectData Object to parse
	 * @param valueToSet [Optional] Value by which we want to replace the empty values, defaults to empty string.
	 * Used only if deleteEmptyKeys is not true.
	 * @param deleteEmptyKeys [Optional] Whether to delete (completely remove from object) the empty keys or not
	 */
	parseNullValues<T>(objectData: T, valueToSet: any = '', deleteEmptyKeys?: boolean): T {
		const objectCopy: T = Object.assign({}, objectData);
		if (typeof objectCopy === 'object') {
			for (const key in objectCopy) {
				if (typeof objectCopy[key] === 'object') {
					this.parseNullValues(objectCopy[key], valueToSet, deleteEmptyKeys);
				} else if (!objectCopy[key] && !['number', 'boolean'].includes(typeof objectCopy[key])) {
					if (deleteEmptyKeys) {
						delete objectCopy[key];
					} else {
						objectCopy[key] = valueToSet;
					}
				}
			}
		}
		return objectCopy;
	}

	/**
	 * Deletes the decimals if the number is X.00
	 * @param fee number yo want to format
	 * @param absolute to return absolute value or not
	 */
	formatNumberNoDecimals(fee: number, absolute?: boolean): number {
		let response: number = fee;
		if (fee.toFixed(2).includes('00')) {
			response = parseInt(fee.toFixed(0), 10);
		}
		return absolute ? Math.abs(response) : response;
	}

	public validateUrl(path: string): boolean {
		/**
		 *
		 * 1) First group excludes from matching:
		 *  - From a to z
		 *  - From 0 to 9
		 *  - \u00D1 \u00F1 are Ñ ñ (spanish letters)
		 *  - \x2F \x2D \x5F are / - _ (one sourceLink have '/')
		 *
		 * 2) Second group matches if token is greater than 2 or a combination of two of them.
		 * 3) Third groupd matches if length is greater than 64.
		 * 4) Fourth group matches if length is lower than 3
		 *
		 * Example:
		 *
		 * Matches 'Ñ ñ' from "/Ñ ñasc 89"
		 * Matches '- ' from "super-link- "
		 * Matches 'ó' and 'ª -/ ' from "alójomorª -/ "
		 * Matches because of the maxLength: "abcdefghijklmnopqrstuvwxyz123456"
		 * Matches because of the minLength: "ab"
		 */
		const regExp = /([^a-z0-9\s\u00D1\u00F1\x2F\x2D\x5F])|(\x2D|\x5F|\s|\u00D1|\u00F1|\x2F){2,}|^.{64,}|^.{0,2}$/gi;
		const decodedPath = decodeURI(path);
		return !decodedPath.match(regExp);
	}

	/**
	 * @returns if the current user is a ME with any site with micro that has to re-register
	 */
	public hasToReregister(): boolean {
		if (this.storage.userProfile.customerType === customerTypes.AUTHORIZED) {
			const companyData = this.getSelectedCompanyData();
			return this.hasAnyResegmentedSite(companyData.sites);
		} else {
			return false;
		}
	}

	/**
	 * @returns if the current user is a user re-registered (MICRO RS)
	 */
	public isConsumerAndHasMicro(): boolean {
		if (this.storage.userProfile.customerType === customerTypes.CONSUMER) {
			return this.hasAnyResegmentedSite(this.storage.userProfile.sites);
		} else {
			return false;
		}
	}

	/**
	 * @returns if the current user has any site with marketSegment "MICRO"
	 */
	public hasAnyResegmentedSite(sites: any[]): boolean {
		if (sites) {
			return sites.some((site: any) => site.clientType === CLIENT_RS && site.marketSegment === SEGMENT_MICRO);
		} else {
			return false;
		}
	}

	/**
	 * @returns the company data finding by the current selectedCompanyId stored in the CompanyService
	 */
	public getSelectedCompanyData(): Company | null {
		if (this.companyService.selectedCompanyId) {
			return this.storage.userProfile.companies.find((company) => company.id === this.companyService.selectedCompanyId);
		} else {
			return null;
		}
	}

	/**
	 * Retrieves correct eCareUrl depending on the environment
	 */
	getECareUrl(destination?: string): string {
		const eCareUrl: string = this.translate.instant(`login.buttonList.eCareUrl.extLinkURL`);
		return typeof destination === 'string' ? environment.eCareUrl.replace('{0}', destination) : eCareUrl;
	}

	public getGestionadoIkki(): boolean | null {
		if (this.companyService.selectedCompanyId) {
			return this.storage.userProfile.companies
				.find((company) => company.id === this.companyService.selectedCompanyId)
				.hasOwnProperty('gestionadoIkki')
				? this.storage.userProfile.companies.find((company) => company.id === this.companyService.selectedCompanyId)
					.gestionadoIkki
				: null;
		}
	}

	/**retrieve card formatted like (**** **** **** 1234) */
	cardFormatted(card: string): string {
		return '**** **** **** ' + card.substring(12, 16);
	}

	/**get the remaining days if the expiry date formatted is DD/MM */
	getremainingDays(expiryDate: string): number {
		const todayDate: Date = new Date();
		todayDate.setHours(0, 0, 0, 0);
		const expiryDateWithCurrentYear: string = expiryDate + '/' + todayDate.getFullYear();
		const expiryDateFormatted: Date = this.getDateFromString(expiryDateWithCurrentYear);
		expiryDateFormatted.setDate(expiryDateFormatted.getDate() + 1);
		return this.getDayDiffBetween2Dates(expiryDateFormatted, todayDate);
	}
	// the output will be 25 apr 2017 and the input date is month/day/year
	getDateFormattedName(date: string): string {
		const splitDate: string[] = date.split('/');
		const month: string = splitDate[1];
		const day: string = splitDate[0];
		const year: string = splitDate[2];
		let alphabeticMonth = '';
		alphabeticMonth = this.getMonthName(month, true);
		return day + ' ' + alphabeticMonth + ' ' + year;
	}

	getDateFormatReturn(dateReturn: string): string {
		const arr: string[] = this.getDateFormatted(dateReturn).split(tabsMyOrders.forWardSlash);
		const day: string = arr[0].substring(0, 1) === returnDefines.zero ? arr[0].substring(1, 2) : arr[0];
		const monthName: string = this.getMonthName(arr[1], false);
		return `${day}${tabsMyOrders.ofString}${monthName}`;
	}

	/**
	 * Converts a date string in the format "day monthAbbrev year" to a Date object.
	 * @param dateString The date string to convert.
	 * @returns The converted Date object, or null if the date string is invalid.
	 */
	convertDateStringToDate(dateString: string): Date | null {
		// Define a mapping of Spanish month abbreviations to their numeric representation
		const monthMap: { [key: string]: number } = {
			Ene: 0,
			Feb: 1,
			Mar: 2,
			Abr: 3,
			May: 4,
			Jun: 5,
			Jul: 6,
			Ago: 7,
			Sep: 8,
			Oct: 9,
			Nov: 10,
			Dic: 11,
		};

		// Split the date string into day, month abbreviation, and year
		const [dayStr, monthAbbrev, yearStr] = dateString.split(' ');

		// Convert day and year to numbers
		const day: number = parseInt(dayStr);
		const year: number = parseInt(yearStr);

		// Check if day, monthAbbrev, and year are valid numbers
		if (isNaN(day) || isNaN(year) || !monthMap[monthAbbrev]) {
			return null;
		}

		// Convert the month abbreviation to its numeric representation
		const month: number = monthMap[monthAbbrev];

		// Create a new Date object using the extracted day, month, and year
		const convertedDate: Date = new Date(year, month, day);

		return convertedDate;
	}

	translateServiceTypeToSpanish(serviceType: string): string {
		switch (serviceType?.toLowerCase()) {
			case ServiceType.Postpaid.toLowerCase():
				serviceType = ServiceTypeSpanish.postpaid.toLowerCase();
				break;
			case ServiceType.Tv.toLowerCase():
				serviceType = ServiceTypeSpanish.tv.toLowerCase();
				break;
			case ServiceType.Landline.toLowerCase():
				serviceType = ServiceTypeSpanish.landline.toLowerCase();
				break;
			default:
				break;
		}
		return serviceType;
	}

	/**
	 * Function to allow searching for recursive fields, returning an array of values from provided field name
	 *
	 * Example: if fieldName is 'type' it will return all values in the json from nested keys named 'type' as an array: ['type1', 'type2'].
	 *
	 * @param json Object where the fieldName will be looked for
	 * @param fieldName Name of the field you want the value to be returned in the array
	 * @param fieldValues Initial array value, defaults to empty array. This arg will be used for recursive iteration
	 * @returns
	 */
	getArrayOfFieldValuesFromJson<T>(json: object, fieldName: string, fieldValues: T[] = []): T[] {
		// jsonpath refactor: created function to replace '$..key' inputs for JSONPath.prototype.query from jsonpath 1.1.1
		for (const key in json) {
			if (json.hasOwnProperty(key)) {
				if (typeof json[key] === 'object') {
					this.getArrayOfFieldValuesFromJson(json[key], fieldName, fieldValues);
				} else if (key === fieldName) {
					fieldValues.push(json[key]);
				}
			}
		}
		return fieldValues;
	}
}
