import { ModuleWithProviders, NgModule } from '@angular/core';
import { MdlDialogOutletModule } from './dialog-outlet/mdl-dialog-outlet.module';
import { MdlSnackbarModule } from './snackbar/mdl-snackbar.module';

const MODULES = [MdlDialogOutletModule, MdlSnackbarModule];

@NgModule({
	declarations: [],
	imports: [MdlDialogOutletModule.forRoot(), MdlSnackbarModule.forRoot()],
	exports: [...MODULES],
})
export class MdlModule {}

@NgModule({
	imports: MODULES,
	exports: MODULES,
})
// @deprectaed use MdlModule - all services are provided in root by default.
export class MdlNonRootModule {
	static forRoot(): ModuleWithProviders<MdlModule> {
		return { ngModule: MdlModule };
	}
}
