<div class="chat-pass row change-padding cont-height">
	<div class="col-xs-12">
		<!-- Without Mobility -->
		<div *ngIf="!Bundle.mobility" class="reamin-usage row change-padding">
			<div
				class="remain"
				[ngClass]="
					isUnlimited || Bundle.IsUnLimited || (Bundle.TotalConsumption && Bundle.ConsumedConsumption != null)
						? 'col-xs-7'
						: 'col-xs-12'
				"
			>
				<ng-container *ngIf="!isUnlimited">
					<span class="bundle-name-lines">
						<mva10-c-icon
						[size]="5"
						[iconId]="'icon-call'"
						style="margin:-7px 18px 0 0"
						></mva10-c-icon>
						<span *ngIf="!Bundle.title"> {{ Bundle.Name }}&nbsp;</span>
						<span *ngIf="Bundle.title"> {{ Bundle.title }}&nbsp;</span>
					</span>
					<p class="call-price active-unlimated">
						<span
							*ngIf="parent.tariffService.Tariff.isPrepaidCommunity && parent.isBenifitsActive && parent.isPrePaid"
							class="active-package"
						>
							{{ 'v10.productsServices.prepaidCommunity.benefits.unlimitedMinutes' | translate }}
						</span>
					</p>
				</ng-container>
				<ng-container *ngIf="isUnlimited">
					<mva10-c-icon
						[size]="5"
						[iconId]="'icon-call'"
						></mva10-c-icon>
					<span class="normalFont"> {{ 'productsServices.rgk.item.voice.title' | translate }} </span>
				</ng-container>
			</div>
			<ng-container *ngIf="!isUnlimited">
				<div
					class="col-xs-5 usage"
					*ngIf="!Bundle.IsUnLimited && Bundle.TotalConsumption && Bundle.ConsumedConsumption != null"
				>

				</div>
				<div class="col-xs-5 usage" *ngIf="Bundle.IsUnLimited">
					<span> {{ 'productsServices.rgk.item.voice.description' | translate }} </span>
				</div>
			</ng-container>
			<ng-container *ngIf="isUnlimited">
				<div class="col-xs-5 usageDestiny">
					<span> {{ 'productsServices.rgk.item.voice.description' | translate }} </span>
				</div>
			</ng-container>
		</div>
		<!-- With Mobility -->
		<div *ngIf="Bundle.mobility" class="reamin-usage row change-padding">
			<div
				class="remain"
				[ngClass]="
					Bundle.IsUnLimited || (Bundle.TotalConsumption && Bundle.ConsumedConsumption != null)
						? 'col-xs-7'
						: 'col-xs-12'
				"
			>
				<span class="bundle-name-lines">
					<span *ngIf="Bundle.mobility.name"> {{ Bundle.mobility.name }}&nbsp;</span>
					<span *ngIf="!Bundle.mobility.name"> {{ Bundle.mobility.title }}&nbsp;</span>
					<span *ngIf="!Bundle.mobility.name"> {{ Bundle.titleLimit }} {{ Bundle.titleRate }} </span>
				</span>
				<p class="call-price active-unlimated">
					<span
						*ngIf="parent.tariffService.Tariff.isPrepaidCommunity && parent.isBenifitsActive && parent.isPrePaid"
						class="active-package"
					>
						{{ 'v10.productsServices.prepaidCommunity.benefits.unlimitedMinutes' | translate }}
					</span>
				</p>
			</div>
			<div
				class="col-xs-5 usage"
				*ngIf="
					!Bundle.mobility.IsUnLimited &&
					Bundle.mobility.TotalConsumption &&
					Bundle.mobility.ConsumedConsumption != null
				"
			>
				<sp-landing-percentage
					title="{{ 'productsServices.itemsList.psRemianing.body' | translate }}"
					unit="Mins"
					[remaining]="Bundle.mobility.RemainConsumptionMinutes | dot"
					[percentage]="Bundle.mobility.RemainingConsumptionPercentage"
				>
				</sp-landing-percentage>
			</div>
			<div class="col-xs-5 usage" *ngIf="Bundle.mobility.IsUnLimited">
				{{ 'productsServices.prepaidPlan.itemsList.planEstablish.body' | translate }}
			</div>
		</div>
		<div class="call-info" *ngIf="Bundle.PayPerUse && Bundle.CallSetup">
			<!-- pay per use b true w mintues -->
			<p class="call-price">
				<span class="package">+ {{ 'productsServices.prepaidPlan.itemsList.planEstablish.body' | translate }} </span>
			</p>
		</div>
	</div>
</div>
