import { Component, Input } from '@angular/core';
import { AppService } from '../../app.service';
import { CommercialCheckoutCartService } from '../../shared/models/commercial-checkout-shopping-cart.model';
import { commercialMobile } from './../../shared/constants/defines';

@Component({
	selector: 'sp-nexus-op',
	templateUrl: './nexus-op.component.html',
	styleUrls: ['./nexus-op.component.scss'],
})
export class NexusOpComponent {
	@Input() services: Array<CommercialCheckoutCartService>;
	public coinMonth: string = commercialMobile.coinMonth;

	constructor(public appService: AppService) {}
}
