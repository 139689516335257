export enum TicketStatus {
	Open = 'open',
	Solved = 'Solved',
	Closed = 'closed',
	Pending = 'pending',
}

export enum TicketTemplateCode {
	MRI = 'MRI',
	DIAGNOSTICO = 'DIAGNOSTICO',
	DEFAULT = 'DEFAULT',
	BARBECHO = 'BARBECHO',
	GENERAL_BREAKDOWN_WITH_DATE = 'GENERAL_BREAKDOWN_WITH_DATE',
	GENERAL_BREAKDOWN_WITHOUT_DATE = 'GENERAL_BREAKDOWN_WITHOUT_DATE',
	GENERAL_BREAKDOWN_WITHOUT_DATE_SLA = 'GENERAL_BREAKDOWN_WITHOUT_DATE_SLA',
	FRONT_OFFICE = 'FRONT_OFFICE',
	CONTRATA_APPOINTMENT_WITHOUT_DATE = 'CONTRATA_APPOINTMENT_WITHOUT_DATE',
	CONTRATA_APPOINTMENT_WITH_CTA = 'CONTRATA_APPOINTMENT_WITH_CTA',
	CONTRATA_HOME_VISIT_ALL_DAY = 'CONTRATA_HOME_VISIT_ALL_DAY',
	CONTRATA_HOME_VISIT = 'CONTRATA_HOME_VISIT',
	CONTRATA_WITH_APPOINTMENT = 'CONTRATA_WITH_APPOINTMENT',
	MOVIL_SOLUCIONADOYSLA = 'MOVIL_SOLUCIONADOYSLA',
	MOVIL_ILOC_FALTANDATOS = 'MOVIL_ILOC.FALTANDATOS',
	GEOLOCALIZACION = 'GEOLOCALIZACION',
	GEOLOCALIZACION2 = 'GEOLOCALIZACION2',
	GARANTIA = 'GARANTIA',
	GARANTIA1 = 'GARANTIA1',
	GARANTIA2 = 'GARANTIA2',
	GARANTIA3 = 'GARANTIA3',
	GARANTIA4 = 'GARANTIA4',
	GARANTIA_INTERACCION = 'GARANTIA_INTERACCION',
	GARANTIA_CLOSED = 'GARANTIA_CLOSED',
	PENDINGTRACKING = 'PENDINGTRACKING',
	PENDINGSOLVED = 'PENDINGSOLVED',
	PENDING = 'PENDING',
}

export enum TicketImageName {
	generic = 'generic',
	mobile = 'mobile-mid copy',
	landLine = 'landline_or_call',
	musicAndMovies = 'music_and_movies',
	router = 'router',
	tv = 'tv',
}

export enum TicketJourney {
	Reiterado_abandono = 'Reiterado_abandono',
	Reiterado_finalizado_flujo = 'Reiterado_finalizado_flujo',
	Notificar_averia = 'Notificar_averia',
	Reabrir_averia = 'Reabrir_averia ',
	Retomar_chat_abierto = 'Retomar_chat_abierto',
	Modificar_cita_averia_campo = 'modificar_cita_averia_campo',
	Notificar_averia_solucionada = 'notificar_averia_solucionada',
	Averia_fuera_de_SLA = 'averia_fuera_de_SLA',
	Averia_ilocalizable = 'Averia_ilocalizable',
	Autoinstalacion = 'Autoinstalacion',
	reabrir_averia_chatRecorded = 'reabrir_averia_chatRecorded',
	retomar_chat_seguimiento = 'retomar_chat_seguimiento',
	retomar_chat_cerrada = 'retomar_chat_cerrada',
	Reabrir_averia_garantia = 'reabrir_averia_garantia',
	Reabrir_averia_garantia1 = 'reabrir_averia_garantia1',
	Reabrir_averia_garantia2 = 'reabrir_averia_garantia2',
	Reabrir_averia_garantia3 = 'reabrir_averia_garantia3',
	Reabrir_averia_garantia4 = 'reabrir_averia_garantia4',
	Retomar_chat_garantia1 = 'retomar_chat_garantia1',
	Retomar_chat_garantia2 = 'retomar_chat_garantia2',
	Retomar_chat_garantia3 = 'retomar_chat_garantia3',
	Retomar_chat_garantia4 = 'retomar_chat_garantia4',
	Pendiente_confirmacion = 'pendiente_confirmacion',
	Pendiente_cerrar = 'pendiente_cerrar',
}
