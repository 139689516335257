import { Component, EventEmitter, Input, Output } from '@angular/core';
import { BannerCardModel } from '../../models/banner-card.model';

@Component({
	selector: 'sp-banner-card',
	templateUrl: './banner-card.component.html',
	styleUrls: ['./banner-card.component.scss'],
})
export class BannerCardComponent {
	@Input() bannerCard: BannerCardModel;
	@Output() clickOnCTA: EventEmitter<void> = new EventEmitter<void>();

	clickOnBannerCTA(): void {
		this.clickOnCTA.emit();
	}
}
