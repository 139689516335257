import { IconClassNames } from '../enums/iconClassNames.enum';
import { ServiceType } from '../enums/serviceType.enum';
import { ServiceBalanceSubscription } from './service-balance-subscription.model';

export class OutOfBundleModel {
	icon: IconClassNames;
	id: string;
	type: ServiceType;
	expirationDate: Date;
	name: string;
	amount: number;
	showDots: boolean;
	amountPostPaid: number;
	currency: string;
	public services: ServiceBalanceSubscription;
}
