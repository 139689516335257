import { Injectable } from '@angular/core';
import { FormArray, UntypedFormGroup } from '@angular/forms';
import { Breakpoints } from '@mva10/mva10-angular';
import { TranslateService } from '@ngx-translate/core';
import { tagging } from '../../../config/tagging-config';
import { ConfigurationService } from '../../core/services/configuration.service';
import { TaggingHelperService } from '../../core/services/tagging.helper.service';
import {
	TemplateImage,
	Validation,
	backButton,
	faultManagementImages,
	formItemNames,
	templateWithImagesInV10,
	templateWithOutImagesInV10,
	typeConnectionKeys,
	v10FaultManagementImages,
} from '../../shared/constants/defines';
import { AnalyticsTypeString } from '../../shared/enums/analyticsTypeString.enum';
import { FaultManagmentServiceType } from '../../shared/enums/fault-managment-service-type.enum';
import { InteractionsId } from '../../shared/enums/interactionsId.enum';
import { ServiceType } from '../../shared/enums/serviceType.enum';
import { OpenNewTicket, TicketInput } from '../../shared/models/openTicket.model';
import { FormItem, Template } from '../../shared/models/template.model';
import { AppService } from './../../app.service';
import { TemplateFormType, TemplateStatus, ValidatorType } from './../../shared/enums/templateStatus.enum';
import { FaultManagementService } from './../fault-management.service';

@Injectable({
	providedIn: 'root',
})
export class TemplatesService {
	private template: Template;
	private newTicket: OpenNewTicket;
	backButton: string = backButton;
	inputsOnStartTemplate: TicketInput[];
	startTemplate: Template;
	private inputs: TicketInput;
	private templateForm: UntypedFormGroup;
	errorMessage: string;
	isDateValidDate: boolean = false;
	media: { id: string; reference: string; type: string }[];

	constructor(
		public faultManagementService: FaultManagementService,
		private configurationService: ConfigurationService,
		private tagging: TaggingHelperService,
		private translate: TranslateService,
		public appService: AppService
	) {}

	/**setbody of template request because it depending on status is start or next template */
	setBodyOfTemplateReq(
		label: any,
		serviceID: string,
		serviceType: string,
		taskID: string,
		miwifi: string,
		platformInfo: string,
		typeConnection: string,
		template: Template,
		templateForm: UntypedFormGroup,
		inputs: TicketInput[] = [],
		ticketId?: string
	): OpenNewTicket {
		this.newTicket = new OpenNewTicket();
		this.newTicket.serviceId = serviceID;
		this.newTicket.taskId = taskID;
		this.newTicket.serviceType = serviceType;
		this.newTicket.miwifi = miwifi;
		this.newTicket.platform = 'WAP';
		this.newTicket.inputs = inputs;
		this.newTicket.platformInfo = platformInfo;
		this.newTicket.typeConnection = typeConnection;
		if (ticketId) {
			this.newTicket.ticketId = ticketId;
		}
		this.template = template;
		this.templateForm = templateForm;
		if (this.configurationService.configuration && this.configurationService.configuration.chatEnabled) {
			this.newTicket.chatEnabled = 'true';
		} else {
			this.newTicket.chatEnabled = 'false';
		}
		/**if status not start check if not tepmplate #3 (not form) set input value with action name and button value  */
		if (taskID.toLocaleLowerCase() !== TemplateStatus.start.toLocaleLowerCase()) {
			if (label === backButton) {
				this.inputs = new TicketInput();
				this.inputs.name = 'opcion';
				this.inputs.value = this.template.form.backButton;
				this.newTicket.inputs.push(this.inputs);
			} else {
				if (+this.template.form.template !== 3 && +this.template.form.template !== 10) {
					this.setBodyOfTemplateReqAuxFuncOne(label);
				} else {
					/**if  template#3  then set input value with form field (name &&value ) and buttton with action (name and value ) */
					const formControls: string[] = Object.keys(this.templateForm.controls);
					formControls.forEach((control, index) => {
						const inputs: TicketInput = new TicketInput();
						this.setBodyOfTemplateReqAuxFuncTwo(inputs, control, index);
					});
					/**if action name exist push in input if not don't push it  */
					this.setBodyOfTemplateReqAuxFuncThree(label);
				}
			}
		}
		return this.newTicket;
	}

	setBodyOfTemplateReqAuxFuncOne(label: any): void {
		if (this.template.form.actions[0].name) {
			this.inputs = new TicketInput();
			const inputs: { label: any; value: string } = this.template.form.actions[0].values.find(
				(item) => item.label === label
			);
			this.inputs.name = this.template.form.actions[0].name;
			this.inputs.value = inputs.value;
			this.newTicket.inputs.push(this.inputs);
		}
	}

	setBodyOfTemplateReqAuxFuncTwo(inputs: TicketInput, control: string, index: number): void {
		if (this.template.form.items) {
			this.inputs = new TicketInput();
			const formItem: FormItem = this.template.form.items.find(
				(item) => item.id === control || item.component?.name === control
			);
			this.inputs.id = formItem.name === formItemNames.Form ? undefined : formItem.id;
			this.inputs.name = formItem.name === formItemNames.Form ? formItem.component?.name : formItem.name;
			if (formItem.name === formItemNames.CheckButton || formItem.name === formItemNames.ToggleButton) {
				this.inputs.values = (<FormArray>this.templateForm.get(control)).value;
			} else {
				this.inputs.value = this.templateForm.get(control).value;
			}
			this.newTicket.inputs.push(this.inputs);
		}
		if (this.template.form.inputs && this.template.form.inputs[index].name) {
			inputs.name = this.template.form.inputs[index].name;
			inputs.value = this.templateForm.controls[control].value;
			this.newTicket.inputs.push(inputs);
		}
	}

	setBodyOfTemplateReqAuxFuncThree(label: any): void {
		if (this.template.form.actions?.length && this.template.form.actions[0]?.name) {
			this.inputs = new TicketInput();
			const action: { label: any; value: string } = this.template.form.actions[0].values.find(
				(item) => item.label === label
			);
			this.inputs.name = this.template.form.actions[0].name;
			this.inputs.value = action.value;
			this.newTicket.inputs.push(this.inputs);
		}
	}

	closeOverlay(): void {
		this.faultManagementService.showOverlay = false;
	}

	closeSecondOverlay(): void {
		this.faultManagementService.showOverlay = false;
		this.faultManagementService.setShowSecondOverlay(false);
	}

	closeThirdOverlay(): void {
		this.faultManagementService.setShowThirdOverlay(false);
	}

	setBooleansAndsetInteractionForMonitoring(
		interactionsId: InteractionsId,
		templateAnalytics: string,
		customerAccountId: string,
		selectedServiceInteractionName: string,
		selectedServiceName: string,
		templateBackend: string
	): void {
		templateAnalytics = this.checkTemplateAnalytics(templateAnalytics, interactionsId);
		this.faultManagementService.showOverlay = false;
		this.faultManagementService.passBonitaFlow = true;
		this.faultManagementService
			.setInteractionForMonitoring(
				customerAccountId,
				templateAnalytics,
				selectedServiceInteractionName,
				templateBackend
			)
			.subscribe(() => {
				tagging.faultManagement.cancelFaultCreation.success.data.journey_options =
					this.faultManagementService.templateName;
				tagging.faultManagement.cancelFaultCreation.success.data.journey_subcategory = selectedServiceName;
				this.tagging.track(
					tagging.faultManagement.cancelFaultCreation.success.eventName,
					tagging.faultManagement.cancelFaultCreation.success.data
				);
			});
	}

	checkTemplateAnalytics(templateAnalytics: string, interactionsId: InteractionsId): string {
		switch (templateAnalytics) {
			case AnalyticsTypeString.mensajeEspera_SCH:
				return InteractionsId.keepWaiting;
			case AnalyticsTypeString.reboot1:
				return InteractionsId.reboot;
			case AnalyticsTypeString.reboot2:
			case AnalyticsTypeString.esperareboot:
				return InteractionsId.rebootWait;
			default:
				return interactionsId;
		}
	}

	serviveTypeTvOrInternet(selectedServiceType: ServiceType): boolean {
		return (
			selectedServiceType.toLowerCase() === FaultManagmentServiceType.Internet.toLowerCase() ||
			selectedServiceType.toLowerCase() === FaultManagmentServiceType.TV.toLowerCase() ||
			selectedServiceType.toLowerCase() === FaultManagmentServiceType.fibre.toLowerCase() ||
			selectedServiceType.toLowerCase() === FaultManagmentServiceType.adsl.toLowerCase()
		);
	}

	/**check form validation in template 3 */
	checkValidation(inputType: Template['form']['inputs'][0]): string {
		if (inputType.validator) {
			let validation: string;
			switch (inputType.validator.toLocaleLowerCase()) {
				case TemplateFormType.email.toLocaleLowerCase():
					this.translate.get('faultManagement.itemsList.validationMessageForEmail.body').subscribe((data) => {
						this.errorMessage = data;
						inputType.errorMessage = this.errorMessage;
					});
					validation = Validation.Email;
					break;

				case TemplateFormType.cellphone.toLocaleLowerCase():
					this.translate.get('faultManagement.itemsList.validationMessageForPhone.body').subscribe((data) => {
						this.errorMessage = data;
						inputType.errorMessage = this.errorMessage;
					});
					validation = Validation.phone;
					break;

				case TemplateFormType.phone.toLocaleLowerCase():
					this.translate.get('faultManagement.itemsList.validationMessageForPhone.body').subscribe((data) => {
						this.errorMessage = data;
						inputType.errorMessage = this.errorMessage;
					});
					validation = Validation.bonitaTemp2phoneValidation;
					break;

				case ValidatorType.numDir.toLocaleLowerCase():
					validation = Validation.streetAvenueNumber;
					break;

				case ValidatorType.postalCod.toLocaleLowerCase():
					validation = Validation.zipCode;
					break;

				case ValidatorType.avenue.toLocaleLowerCase():
					validation = Validation.streetAvenueNumber;
					break;
			}
			return validation;
		}
		if (!inputType.validator) {
			this.translate.get('faultManagement.itemsList.validationMessageForMinLenght.body').subscribe((data) => {
				this.errorMessage = data;
				inputType.errorMessage = this.errorMessage;
				return null;
			});
		}
	}

	templateTextWithMediaAndLastText(
		templateForm: Template['form']
	): [any[], { id: string; value: string; size: string }] {
		/** templateTextWithMedia is array contain text and media */
		let templateTextWithMedia = [];
		let lastText: { id: string; value: string; size: string };
		const text: { id: string; value: string; size?: string }[] = templateForm.texts.slice(2, templateForm.texts.length);
		this.media = templateForm.media.filter((item) => {
			/**skip any image with name tools */
			if (item.reference.toLocaleLowerCase() !== TemplateImage.tools.toLocaleLowerCase()) {
				return item;
			}
		});

		this.media = this.getImageWcs(templateForm.template, this.media);

		if (templateForm.template === TemplateStatus.template10) {
			const maxLength: number = Math.max(this.media.length, text.length);
			for (let index = 0; index < maxLength; index++) {
				if (index < this.media.length) {
					templateTextWithMedia.push(this.media[index]);
				}

				if (index < text.length - 1) {
					templateTextWithMedia.push(text[index]);
				}
				if (index === text.length - 1) {
					lastText = {
						id: text[index].id,
						value: text[index].value,
						size: text[index].size,
					};
				}
			}
		} else {
			templateTextWithMedia = [...this.media, ...text];
		}
		return [templateTextWithMedia, lastText];
	}

	/**get Image from WCS */
	getImageWcs(
		isTempWithImagesInV10: string,
		media: { id: string; reference: string; type: string }[]
	): { id: string; reference: string; type: string }[] {
		let images: string = faultManagementImages;
		if (isTempWithImagesInV10 === templateWithImagesInV10) {
			images = v10FaultManagementImages;
		}
		this.translate.get(images).subscribe((data) => {
			const image: string = data;
			const imageObject: string[] = Object.keys(image);
			let notFoundImage: boolean = true;
			media.forEach((item) => {
				for (const key of imageObject) {
					if (item.reference.split('.')[0].toLocaleLowerCase() === key.toLocaleLowerCase()) {
						item.reference = this.appService.getImgFullPath(image[key].url);
						if (isTempWithImagesInV10 === templateWithImagesInV10) {
							item.reference = this.appService.getImgFullPath(image[key]);
							notFoundImage = false;
						}
					}
				}
			});
			if (isTempWithImagesInV10 === templateWithImagesInV10 && notFoundImage) {
				this.getImageWcs(templateWithOutImagesInV10, media);
			}
		});
		return media;
	}

	getConnectionType(): string {
		const isDesktopView = window.innerWidth >= Breakpoints.DESKTOP;
		let connection: string;
		let type: string;
		let typeConnection: string;
		if (navigator.userAgent.toLowerCase().indexOf(typeConnectionKeys.safari) === -1) {
			connection =
				navigator[typeConnectionKeys.connection] ||
				navigator[typeConnectionKeys.mozConnection] ||
				navigator[typeConnectionKeys.webkitConnection];
			type = connection ? connection[typeConnectionKeys.type] : null;
		}
		!isDesktopView
			? (typeConnection =
					connection && type && type === typeConnectionKeys.wifi ? typeConnectionKeys.wifi : typeConnectionKeys.data)
			: (typeConnection = typeConnectionKeys.wifi);
		return typeConnection;
	}

	setValidators(input: Template['form']['inputs'][0]): void {
		if (!input.validator) {
			switch (input.name.toLocaleLowerCase()) {
				case ValidatorType.numDir.toLocaleLowerCase():
					input.validator = ValidatorType.numDir;
					break;
				case ValidatorType.postalCod.toLocaleLowerCase():
					input.validator = ValidatorType.postalCod;
					break;
				case ValidatorType.avenue.toLocaleLowerCase():
					input.validator = ValidatorType.avenue;
					break;
			}
		}
	}
}
