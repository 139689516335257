import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { StorageService } from '../../core/services/storage.service';
import * as constants from '../../shared/constants/defines';
import { API_URLS } from '../../shared/constants/routes-config';
import { ProductOfferingPostObject } from '../models/roaming-productOrderItem';

@Injectable({
	providedIn: 'root',
})
export class RoamingDataService {
	constructor(private http: HttpClient, private storageService: StorageService) {}
	getRoamingEligibleProductOffering(
		customerAccountId: string,
		nationalTariff: string,
		zoneId: string,
		serviceId: string
	): Observable<any> {
		const url: string = API_URLS.Roaming.roamingEligibleProductOffering
			.replace('{customerAccountId}', customerAccountId)
			.replace('{nationalTariff}', nationalTariff)
			.replace('{zoneId}', zoneId)
			.replace('{serviceId}', serviceId);
		let headers: HttpHeaders = new HttpHeaders();
		headers = headers.append('Content-Type', 'application/json');
		headers.append('vf-country-code', 'ES');
		headers.append(
			'Authorization',
			'Bearer ' + this.storageService.getLocalStorage(constants.LOCAL_STORAGE_KEYS.ACCESS_TOKEN)
		);

		const options: any = {
			headers: headers,
		};

		return this.http.get(url, options);
	}
	toggleproductOrderingManagement(reqBody: ProductOfferingPostObject): Observable<any> {
		const url: string = API_URLS.Roaming.productOrderingManagement;
		let headers: HttpHeaders = new HttpHeaders();
		headers = headers.append('Content-Type', 'application/json');
		headers = headers.append('Accept', 'application/json');
		headers = headers.append('X-VF-API-Process', 'CreateFulfillment');
		headers = headers.append('vf-country-code', 'ES');
		headers = headers.append(
			'Authorization',
			'Bearer ' + this.storageService.getLocalStorage(constants.LOCAL_STORAGE_KEYS.ACCESS_TOKEN)
		);
		const options: any = {
			headers: headers,
		};
		const body: any = reqBody;
		return this.http.post(url, body, options);
	}
}
