import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import moment from 'moment';
import { Observable } from 'rxjs/internal/Observable';
import { throwError } from 'rxjs/internal/observable/throwError';
import { catchError, map } from 'rxjs/operators';
import { ChangeAddress } from 'src/app/shared/models/change-address.model';
import { CommercialListTerminals, CommercialOptions } from 'src/app/shared/models/commercial-list-terminals.model';
import { StorageService } from '../../core/services/storage.service';
import { SubscriptionService } from '../../core/services/subscription.service';
import { TaggingHelperService } from '../../core/services/tagging.helper.service';
import {
	CommercialJourneys,
	LOCAL_STORAGE_KEYS,
	PAGES,
	commercialMobile,
	deliveryParams,
	stringOfTrue,
} from '../constants/defines';
import { API_URLS } from '../constants/routes-config';
import { ProductConfigurationID } from '../enums/product-configuration-id.enum';
import { CommercialCapacity } from '../models/commercial-capacity.model';
import { CommercialCartItem } from '../models/commercial-cart-item.model';
import { CommercialData } from '../models/commercial-data.model';
import { CommercialDetail, PackageImage, PackageText } from '../models/commercial-detail.model';
import {
	CommercialBrandFilter,
	CommercialCapacityFilter,
	CommercialColorFilter,
} from '../models/commercial-filters.model';
import { PlaylistListTerminalsCommercial } from '../models/commercial-playlist-list-terminals.model';
import { CommercialPlaylist, PlayListCommercial } from '../models/commercial-playlist.model';
import { CommercialPrice } from '../models/commercial-price.model';
import { CommercialService } from '../models/commercial-service.model';
import { CommercialShippingCosts } from '../models/commercial-shipping-costs.model';
import {
	CommercialCheckoutCriteriaDto,
	CommercialShopingCartBuyNowRes,
	CommercialShoppingCart,
} from '../models/commercial-shopping-cart.model';
import { CommercialFinancingOption, CommercialPayment } from '../models/commercial-terminal-financing.model';
import { CommercialTerminalList, IdPromos, ParamsBuyNow, Semaphore } from '../models/commercial-terminal-list.model';
import { CommercialKeywordsAll, CommercialTerminal } from '../models/commercial-terminal.model';
import { DeliveryDate } from '../models/delivery-date.model';
import { DeliveryHours } from '../models/delivery-hours.model';
import { DeliveryModality } from '../models/delivery-modality.model';
import { DeliveryOffice } from '../models/delivery-office.model';
import { DeliverySelectedStore } from '../models/delivery-selected-store.model';
import { DeliveryStore } from '../models/delivery-store.model';
import { DeliveryType } from '../models/delivery-type.model';
import { InsuranceModel, InsurancePrices } from '../models/insurance.model';
import { LegalTermDetail } from '../models/legalTermDetail.model';
import { LegalTerms } from '../models/legalTerms.model';
import { OptionCommercialMobile } from '../models/options-commercial-mobile.model';
import { ParamsCommercialService } from '../models/params-commercial-service.model';
import { PostalAddress, PostalAddressBuyNow } from '../models/postal-address.model';
import { PrivateOfferListCartModel } from '../models/private-offer-list-cart.model';
import { PromoCommercial } from '../models/promo-commercial.model';
import { ServiceAddress } from '../models/service-address.model';
import { StoreCoordinates } from '../models/store-coordinates.model';
import { UtilitiesService } from '../utils/utilities.service';
import { AllowedServicesService } from './allowed-services.service';
import { MicroFlowsService } from './micro-flows.service';
import { ProductConfigurationService } from './product-configuration.service';

@Injectable()
export class CommercialMobileService {
	public playlists: CommercialPlaylist;

	private terminals: CommercialTerminal[];
	public allTerminals: CommercialTerminal[];

	public promos: PromoCommercial[];

	public loaded: boolean = null;
	public errorPreScoring: boolean = false;
	public attempsNumber: number = 0;
	public retry: boolean = true;
	backFromStep: boolean[] = new Array<boolean>();
	origin: string = PAGES.DASHBORD;
	public stepTitles: string[];
	public stepCurrent: number;
	public productTagg: string;
	public sap: string = '';
	public group: string = '';
	public offerId: string = '';
	public paymentTerminalType: number;
	public terminalID: number;
	public comeFromMyAccount: boolean = false;
	public playlistSelected: PlayListCommercial;
	public PlaylistListTerminals: PlaylistListTerminalsCommercial[];
	public brandsOptions: CommercialBrandFilter[];
	public brandsSelected: CommercialBrandFilter[];
	public capacitiesOptions: CommercialCapacityFilter[];
	public capacitiesSelected: CommercialCapacityFilter[];
	public colorsOptions: CommercialColorFilter[];
	public colorsSelected: CommercialColorFilter[];
	public listFiltered: CommercialTerminal[];
	public PlaylistName: string;
	public filterTagg: string;
	public playlistId: Number;
	public privatePlaylist: boolean = false;
	public mboxPlayList: string;
	public selectOffer: string;
	public installationDate: string;
	public installationHour: string;
	public listTerminals: Array<PlaylistListTerminalsCommercial> = new Array<PlaylistListTerminalsCommercial>();
	public phoneNumber: string;
	public defaultNumber: string;
	public originNumber: string;
	public terminalListLoaded: boolean = false;
	public prescoringErrorLoad: boolean = false;
	public listKeywords: CommercialKeywordsAll;
	public searchAll: boolean = false;

	personalData: CommercialData;
	ecodePersonalData: string;
	postalAddress: PostalAddress;
	addressHome: PostalAddress;
	changeAddress: ChangeAddress[];
	zipCodeStore: string;
	deliveryTypes: DeliveryType[];
	startDate: Date;
	deliveryModality: DeliveryModality;
	datesHome: DeliveryModality;
	datesStore: DeliveryModality;
	availableStores: DeliveryStore[];
	stores: DeliveryStore[];
	commercialshoppingCartItems: CommercialShoppingCart;
	cartItemId: string;
	paymentType: boolean | number;
	legalTermsArray: LegalTerms[];
	shippingType: string;
	isHome: boolean;
	public isOffice: boolean;

	shopDelivery: string;
	addressDelivery: string;
	dateDelivery: string;
	timeDelivery: any;
	public priceToPay: number;
	public priceInitialPay: number;
	public numMeses: number;

	public shippingScheduleText: string;
	public ecodeTerminalList: string;
	public taxType: string;
	public isME: boolean;
	public isYU: boolean;
	public switchTaxes: boolean;

	public canon: number;
	public promotedCanon: boolean;

	public sapDeeplink = false;

	private showFinalPayment: boolean = false;

	public cartItemIdTerminals: string;
	public msisdn: string;
	public idPlaylist: number;

	public hasInsuranceOnCart: boolean = false;
	public hasOneProfOnCart: boolean = false;
	public isMicroResegmented: boolean;

	public notFindSearchMessage: string;
	public serachTextOK: string;
	public showFindSearch: boolean;
	public showNotFindSearch: boolean;
	public textInFinder: string = '';
	public defaultPlaylistSearch: PlayListCommercial;
	public insuranceModality: string = null;
	public finderArray: Array<string> = [];
	public promotions: PromoCommercial[];

	public orderByCachePosition: number;

	constructor(
		public http: HttpClient,
		private subscriptionData: SubscriptionService,
		private taggingHelperService: TaggingHelperService,
		private productConfiguration: ProductConfigurationService,
		private storageService: StorageService,
		private allowedServices: AllowedServicesService,
		private microFlowService: MicroFlowsService,
		private translate: TranslateService,
		private readonly utilities: UtilitiesService
	) {
		this.isMicroResegmented = this.utilities.isConsumerAndHasMicro();
	}

	public getPlaylist() {
		let url = API_URLS.CommercialMobiles.getPlaylist
			.replace(
				'{clientType}',
				this.isME || this.isMicroResegmented
					? commercialMobile.clientTypeMicro.toString()
					: commercialMobile.clientType.toString()
			)
			.replace(
				'{shopType}',
				this.isME || this.isMicroResegmented
					? commercialMobile.shopTypeMicro.toString()
					: this.isYU
					? commercialMobile.shopTypeYu.toString()
					: commercialMobile.shopType.toString()
			)
			.replace('{lineType}', commercialMobile.lineType.toString())
			.replace('{registerType}', commercialMobile.registerType.toString())
			.replace('{sceneType}', commercialMobile.sceneType.toString())
			.replace('{contractType}', commercialMobile.contractType.toString());
		if (this.playlistId) {
			url = url.concat('&playlistId=' + this.playlistId);
		}

		const headers = new HttpHeaders();
		const options = {
			headers: headers,
		};
		return this.http.get(url, options).pipe(
			map((res: any) => {
				return this.playlistLogic(res);
			}),
			catchError((err) => {
				this.setErrorEcodes(err.error.ecode);
				return throwError(err);
			})
		);
	}

	public getOrderedList(): Observable<any> {
		const url: string = API_URLS.CommercialMobiles.getOrderedList
			.replace('{contractType}', commercialMobile.contractType.toString())
			.replace(
				'{clientType}',
				this.isME || this.isMicroResegmented
					? commercialMobile.clientTypeMicro.toString()
					: commercialMobile.clientType.toString()
			)
			.replace('{sceneType}', commercialMobile.sceneType.toString())
			.replace(
				'{shopType}',
				this.isME || this.isMicroResegmented
					? commercialMobile.shopTypeMicro.toString()
					: this.isYU
					? commercialMobile.shopTypeYu.toString()
					: commercialMobile.shopType.toString()
			)
			.replace('{registerType}', CommercialJourneys.registerType)
			.replace('{name}', '2NDHOME');

		const headers: HttpHeaders = new HttpHeaders();

		const options: object = {
			headers: headers,
		};

		return this.http.get(url, options).pipe(
			map((res: any) => {
				this.setErrorEcodes(res);
				if (!this.errorPreScoring) {
					return res;
				}
			}),
			catchError((err) => {
				return throwError(err);
			})
		);
	}

	private playlistLogic(playlist: CommercialPlaylist) {
		this.playlists = new CommercialPlaylist();
		this.playlists.ecode = playlist.ecode || null;
		// jsonpath refactor: params read as item['xxx'] are missing in model
		const list = playlist['playlists'] || [];
		if (list) {
			this.playlists.playlist = new Array<PlayListCommercial>();
			list.map((plists) => {
				const plist = new PlayListCommercial();
				plist.code = plists.code || 0;
				plist.name = plists.name || null;
				plist.order = plists.order || 0;
				plist.type = plists.type || null;
				plist.flgAutoComplete = plists.flgAutoComplete || false;
				plist.items = plists.items || null;
				this.playlists.playlist.push(plist);
			});
		}
		this.playlists.playlist?.sort((playlistFirst, playlistSecond) => {
			return Number(playlistFirst.order) - Number(playlistSecond.order);
		});
	}

	public setPlaylistName(): void {
		this.PlaylistName = this.taggingHelperService.replaceSpecialCharReplace(this.playlistSelected.name, true);
		this.privatePlaylist = this.playlistSelected.type === commercialMobile.privatePlaylist;
	}

	public getTerminals(playlistId?: number, allowed?: boolean): Observable<CommercialListTerminals> {
		this.terminals = new Array<CommercialTerminal>();
		let headers: HttpHeaders = new HttpHeaders();
		headers = headers.append('Content-Type', 'application/json');
		const options: CommercialOptions = {
			headers: headers,
			auth_ticket: this.storageService.getLocalStorage(LOCAL_STORAGE_KEYS.JWT),
			NTOL_TXID: this.microFlowService.cartId,
			withCredentials: true,
		};
		const url: string = this.setUrlGetTerminals(playlistId, allowed);

		return this.http.get(url, options).pipe(
			map((res: CommercialListTerminals) => {
				this.attempsNumber = 0;
				this.terminalLogic(res);
				this.promosLogic(res);
				this.ecodeTerminalList = res.ecode;
				if (res.playlist && !playlistId) {
					this.playlistSelected.code = res.playlist;
				}
				this.taxType = res.taxType;
				this.setErrorEcodes(res.ecode);
				return res;
			}),
			catchError((err) => {
				this.ecodeTerminalList = err.error.ecode;
				this.setErrorEcodes(err);
				return throwError(err);
			})
		);
	}

	public getClientType(): string {
		return this.isME || this.isMicroResegmented
			? commercialMobile.clientTypeMicro.toString()
			: commercialMobile.clientType.toString();
	}

	public getShopType(): string {
		return this.isME || this.isMicroResegmented
			? commercialMobile.shopTypeMicro.toString()
			: this.isYU
			? commercialMobile.shopTypeYu.toString()
			: commercialMobile.shopType.toString();
	}

	public setUrlGetTerminals(playlistId?: number, allowed?: boolean): string {
		let code: string = null;
		let url: string = API_URLS.CommercialMobiles.getTerminalList
			.replace('{clientType}', this.getClientType())
			.replace('{shopType}', this.getShopType())
			.replace('{lineType}', commercialMobile.lineType.toString())
			.replace('{registerType}', commercialMobile.registerType.toString())
			.replace('{sceneType}', commercialMobile.sceneType.toString())
			.replace('{contractType}', commercialMobile.contractType.toString())
			.replace('{clientId}', this.subscriptionData.customerData.currentService.siteId)
			.replace('{profile}', commercialMobile.profile)
			.replace('{version}', this.translate.instant('v10.commercial.fullVersionPortalEP'));
		if (playlistId && !this.searchAll) {
			url = url
				.replace('{terminalType}', CommercialJourneys.registerTypeMTP)
				.replace('{msisdn}', '')
				.replace('{cartItemId}', CommercialJourneys.registerType)
				.concat('&playlist=' + playlistId);
		} else if (this.playlistSelected && !this.searchAll) {
			code = this.playlistSelected.code.toString();
			url = url
				.replace('{terminalType}', commercialMobile.terminalType.toString())
				.replace('{msisdn}', this.msisdn)
				.replace('{cartItemId}', this.cartItemIdTerminals)
				.concat('&playlist=' + code);
		} else {
			url = API_URLS.CommercialMobiles.getTerminalList
				.replace('{clientType}', this.getClientType())
				.replace('{shopType}', this.getShopType())
				.replace('{lineType}', commercialMobile.lineType.toString())
				.replace('{terminalType}', commercialMobile.terminalType.toString())
				.replace('{registerType}', commercialMobile.registerType.toString())
				.replace('{sceneType}', commercialMobile.sceneType.toString())
				.replace('{contractType}', commercialMobile.contractType.toString())
				.replace('{clientId}', this.subscriptionData.customerData.currentService.siteId)
				.replace('{msisdn}', this.msisdn)
				.replace('{profile}', commercialMobile.profile)
				.replace('{cartItemId}', this.cartItemIdTerminals)
				.replace('{version}', this.translate.instant('v10.commercial.fullVersionPortalEP'));
		}
		return url;
	}

	private terminalLogic(terminal) {
		this.showFinalPayment = this.productConfiguration.shouldShowFeature(ProductConfigurationID.showFinalPayment);
		this.terminals = new Array<CommercialTerminal>();
		this.allTerminals = new Array<CommercialTerminal>();
		const terms = terminal.items || [];
		terms.map((term) => {
			const terminalCommercial = new CommercialTerminal();
			terminalCommercial.id = term.id || 0;
			terminalCommercial.type = term.type || null;
			terminalCommercial.nombre = term.nombre || null;
			terminalCommercial.orden = term.orden || 0;
			terminalCommercial.ordenCuotaMensual = term.ordenCuotaMensual || 0;
			terminalCommercial.ordenPagoInicial = term.ordenPagoInicial || 0;
			terminalCommercial.commercialTerminalList = term.listTerminals || null;
			terminalCommercial.isPrioritary = term.isPrioritary || false;
			terminalCommercial.orderCriteria = term.orderCriteria || false;
			if (terminalCommercial.commercialTerminalList) {
				terminalCommercial.commercialTerminalList = this.terminalListLogic(
					terminalCommercial.commercialTerminalList,
					term
				);
			}
			this.terminals.push(terminalCommercial);
			this.allTerminals.push(terminalCommercial);
			this.loaded = this.terminalsLoaded();
			return terminalCommercial;
		});
	}

	private terminalListLogic(terminalToStore, terminalToGet): CommercialTerminalList[] {
		terminalToStore = new Array<CommercialTerminalList>();
		const listTerms: CommercialTerminalList[] = terminalToGet.listTerminals || [];
		listTerms.forEach((listT: CommercialTerminalList) => {
			let terminalList: CommercialTerminalList = new CommercialTerminalList();
			terminalList = this.terminalListDetail(listT, terminalList);
			terminalList = this.terminalListDetailCaracteristics(listT, terminalList);
			const promosArray = listT.listaPromos || null;
			if (promosArray) {
				promosArray.forEach((id) => {
					terminalList.listaPromos = (listT.listaPromos as IdPromos[]) || null;
				});
			}
			this.terminalListfinancingOptions(listT, terminalList);
			if (terminalList.financingOptions.length) {
				terminalToStore.push(terminalList);
			}
		});
		return terminalToStore;
	}

	public terminalListfinancingOptions(listT: CommercialTerminalList, terminalList: CommercialTerminalList): void {
		// jsonpath refactor: params read as item['xxx'] are missing in model
		const financingOptions: CommercialFinancingOption[] = listT['multiFinancingDevice']?.financingOptions || [];
		if (financingOptions.length) {
			terminalList.financingOptions = [];
			financingOptions.forEach((option) => {
				const financingOption: CommercialFinancingOption = new CommercialFinancingOption();
				financingOption.numMeses = option.numMeses || 0;
				const payments: CommercialPayment[] = option.payments || [];
				if (payments.length) {
					financingOption.payments = [];
					payments.forEach((item) => {
						let payment: CommercialPayment = new CommercialPayment();
						payment = this.terminalListPaymets(item, payment);
						if (!payment.flagFinalPayment || (this.showFinalPayment && payment.flagFinalPayment)) {
							financingOption.payments.push(payment);
						}
					});
				}
				this.terminalListfinancingOptionsPush(financingOption, terminalList);
			});
		}
	}

	public terminalListfinancingOptionsPush(
		financingOption: CommercialFinancingOption,
		terminalList: CommercialTerminalList
	): void {
		if (financingOption.payments.length) {
			terminalList.financingOptions.push(financingOption);
		}
	}

	public terminalListPaymets(item: CommercialPayment, payment: CommercialPayment): CommercialPayment {
		payment.availableBuyNow = item.availableBuyNow || false;
		payment.idPrecio = item.idPrecio || 0;
		payment.pagoAlContadoConImpuesto = item.pagoAlContadoConImpuesto || 0;
		payment.pagoAlContadoSinImpuesto = item.pagoAlContadoSinImpuesto || 0;
		payment.cuotaMensualConImpuesto = item.cuotaMensualConImpuesto || 0;
		payment.cuotaMensualSinImpuesto = item.cuotaMensualSinImpuesto || 0;
		payment.pagoInicialConImpuesto = item.pagoInicialConImpuesto || 0;
		payment.pagoInicialSinImpuesto = item.pagoInicialSinImpuesto || 0;
		payment.crossedOfferOriginal = item.crossedOfferOriginal || '';
		payment.offerType = item.offerType || '';
		payment.canonConImpuesto = item.canonConImpuesto || 0;
		payment.canonSinImpuesto = item.canonSinImpuesto || 0;
		payment.paymentType = item.paymentType || null;
		// jsonpath refactor: params read as item['xxx'] are missing in model
		payment.flagFinalPayment = item['finalPayment']?.flagFinalPayment || false;
		return this.terminalListPaymetsTaxes(item, payment);
	}
	public terminalListPaymetsTaxes(item: CommercialPayment, payment: CommercialPayment): CommercialPayment {
		// jsonpath refactor: params read as item['xxx'] are missing in model
		payment.flagOptionsRefinancingFp = item['finalPayment']?.flagOptionsRefinancingFp || false;
		payment.deviceFinalPaymentTaxes = item['finalPayment']?.deviceFinalPaymentTaxes || 0;
		payment.deviceFinalPayment = item['finalPayment']?.deviceFinalPayment || 0;
		payment.pvpTotal = item.pvpTotal || null;
		payment.pvpTotalTaxes = item.pvpTotalTaxes || null;
		return payment;
	}

	public terminalListDetail(
		listT: CommercialTerminalList,
		terminalList: CommercialTerminalList
	): CommercialTerminalList {
		terminalList.idGrupo = listT.idGrupo || 0;
		terminalList.ordenGrupo = listT.ordenGrupo || 0;
		terminalList.sap = listT.sap || null;
		terminalList.color = listT.color || null;
		terminalList.codigoColor = listT.codigoColor || null;
		terminalList.capacidadValorUnidad = new CommercialCapacity();
		// jsonpath refactor: params read as item['xxx'] are missing in model
		terminalList.capacidadValorUnidad.capacidad = listT['capacidad'] || null;
		terminalList.capacidadValorUnidad.valor = listT.capacidadValorUnidad.valor || null;
		terminalList.capacidadValorUnidad.unidad = listT.capacidadValorUnidad.unidad || null;
		terminalList.imagenListado = listT.imagenListado || null;
		terminalList.textoImagenListado = listT.textoImagenListado || null;
		terminalList.modelo = listT.modelo || null;
		terminalList.so = listT.so || null;
		terminalList.altImagenSo = listT.altImagenSo || null;
		terminalList.analyticsCategoryType = listT.analyticsCategoryType || null;
		return terminalList;
	}

	public terminalListDetailCaracteristics(listT, terminalList: CommercialTerminalList): CommercialTerminalList {
		terminalList.marca = listT.marca || null;
		terminalList.idMarca = listT.idMarca || 0;
		terminalList.idTerminal = listT.idTerminal || 0;
		terminalList.idTipoTerminal = listT.idTipoTerminal || 0;
		terminalList.limitado = listT.limitado || false;
		terminalList.catalogOffer = listT.catalogOffer || 0;
		terminalList.nombreTarifa = listT.nombreTarifa || null;
		terminalList.exclusivoVodafone = listT.exclusivoVodafone || 0;
		terminalList.textoNoCobroCanon = listT.textoNoCobroCanon || null;
		terminalList.semaphore = new Semaphore();
		terminalList.semaphore.color = listT.semaphore?.color || null;
		terminalList.semaphore.text = listT.semaphore?.text || null;
		terminalList.mainCharacteristics = listT.mainCharacteristics || null;
		terminalList.isPrioritary = listT.isPrioritary;
		terminalList.techDeviceType = listT.techDeviceType;
		terminalList.presale = listT.itemStock?.presale;
		terminalList.accelerator = listT.itemStock?.accelerator;
		terminalList.unavailable = listT.itemStock?.unavailable;
		return terminalList;
	}

	private promosLogic(promo) {
		this.promos = new Array<PromoCommercial>();
		const proms = promo.promos || [];
		proms.map((prom: any) => {
			// jsonpath refactor: added 'any' type due to missing model
			const commercialPromo = new PromoCommercial();
			commercialPromo.id = prom.id || null;
			commercialPromo.applyTo = prom.applyTo || null;
			commercialPromo.descriptionCRM = prom.descriptionCrm || null;
			commercialPromo.discountAmount = prom.discountAmount || null;
			commercialPromo.discountClassType = prom.discountClassType || null;
			commercialPromo.discountDuration = prom.discountDuration || null;
			commercialPromo.discountType = prom.discountType || null;
			commercialPromo.evaluationOrder = prom.evaluationOrder || null;
			commercialPromo.flagIgnoreCRM = prom.flagIgnoreCRM || false;
			commercialPromo.flagRegional = prom.flagRegional || false;
			commercialPromo.headbandOpenText = prom.headbandOpenText || null;
			commercialPromo.internalName = prom.internalName || null;
			commercialPromo.linkedToCatalog = prom.linkedToCatalog || null;
			commercialPromo.longText = prom.longText || null;
			commercialPromo.publicName = prom.publicName || null;
			commercialPromo.detailTextList = prom.detailTextList || null;
			commercialPromo.urlMobilePromotionImage = prom.urlMobilePromotionImage || null;
			commercialPromo.urlMobilePromotionImageFicha = prom.urlMobilePromotionImageFicha || null;
			commercialPromo.shortText = prom.shortText || null;
			commercialPromo.listIcon = prom.listIcon || null;
			commercialPromo.detailIcon = prom.detailIcon || null;
			commercialPromo.cardColor = prom.cardColor || null;
			commercialPromo.cardText = prom.cardText || null;
			commercialPromo.detailPromotionText = prom.detailPromotionText || null;
			this.promos.push(commercialPromo);
			return commercialPromo;
		});
	}

	private setErrorEcodes(error: string): void {
		if (error === commercialMobile.errorCodes.preScoring3 || error === commercialMobile.errorCodes.preScoring4) {
			this.errorPreScoring = true;
			this.PlaylistListTerminals = new Array<PlaylistListTerminalsCommercial>();
		}
	}

	public terminalsLoaded(): boolean {
		this.loaded = this.terminals ? true : false;
		return this.loaded;
	}

	public setOrigen(origin: string): string {
		this.origin = origin;
		return this.origin;
	}

	public saveStepper(stepTitles: string[]): void {
		this.stepTitles = stepTitles;
	}

	public getStepper(): string[] {
		return this.stepTitles;
	}

	private headersData() {
		const headers = new HttpHeaders();

		const options: OptionCommercialMobile = {
			headers: headers,
			NTOL_TXID: this.microFlowService.cartId,
			withCredentials: true,
		};
		return options;
	}

	public deleteReset(idClient: string, _clientTypeID: string): Observable<void> {
		const url: string = API_URLS.Checkout.deteleReset
			.replace('{idClient}', idClient)
			.replace(
				'{clientTypeID}',
				this.isME || this.isMicroResegmented
					? commercialMobile.clientTypeMicro.toString()
					: commercialMobile.clientType.toString()
			);

		const options: OptionCommercialMobile = this.headersData();

		const params: {} = {};
		return this.http.post(url, params, options).pipe(
			map((res: any) => {}),
			catchError((err) => {
				return throwError(err);
			})
		);
	}

	public postShoppingCartItem(
		idPrecio: number,
		paymentType: boolean | number,
		flagPaymentOptionFp: boolean,
		finalPaymentMonths?: number,
		itemId?: number
	): Observable<any> {
		this.paymentType = paymentType;
		const url: string = API_URLS.Checkout.postShoppingCartItem;
		const params: object = this.setShoppingCartParams(
			idPrecio,
			paymentType,
			flagPaymentOptionFp,
			finalPaymentMonths,
			itemId
		);
		const options = this.headersData();

		return this.http.post(url, params, options).pipe(
			map((res: any) => {
				if (
					Number(res.ecode) === Number(commercialMobile.errorCodes.preScoring) ||
					Number(res.ecode) === Number(commercialMobile.errorCodes.preScoring5)
				) {
					return res;
				} else {
					this.shoppingCartLogic(res);
					return res;
				}
			}),
			catchError((err) => {
				return throwError(err);
			})
		);
	}

	public postShoppingCartItemBuyNow(
		idPrecio: number,
		paymentType: boolean | number,
		flagPaymentOptionFp: boolean,
		itemId?: number
	): Observable<CommercialShopingCartBuyNowRes> {
		this.paymentType = paymentType;
		const url: string = API_URLS.Checkout.postShoppingCartItemBuyNow;
		const params: ParamsBuyNow = this.setShoppingCartParamsBuyNow(idPrecio, paymentType, flagPaymentOptionFp, itemId);
		const options: OptionCommercialMobile = this.headersData();
		return this.http.post(url, params, options).pipe(
			map((res: CommercialShopingCartBuyNowRes) => {
				return res;
			}),
			catchError((err) => {
				return throwError(err);
			})
		);
	}

	public getShoppingCartItem() {
		const url: string = API_URLS.Checkout.getShoppingCartItem;

		const options: OptionCommercialMobile = this.headersData();
		return this.http.get(url, options).pipe(
			map((res: any) => {
				this.shoppingCartLogic(res);
				res.pega = res.pega || {};
				return res;
			}),
			catchError((err) => {
				return throwError(err);
			})
		);
	}

	public postPersonalData(param) {
		const url = API_URLS.Checkout.postPersonalData;

		const params = Object.assign(param, {
			clientType: this.isME || this.isMicroResegmented ? commercialMobile.clientTypeMicro : commercialMobile.clientType,
			shopType:
				this.isME || this.isMicroResegmented
					? commercialMobile.shopTypeMicro
					: this.isYU
					? commercialMobile.shopTypeYu
					: commercialMobile.shopType,
			sceneType: 1,
		});

		const options = this.headersData();

		return this.http.post(url, params, options).pipe(
			map((res: any) => {
				this.personalData = new CommercialData();
				this.personalData.completed = res.completed || false;
				this.personalData.order_id = res.order_id || null;
				this.personalData.ecode = res.ecode || null;
				if (param.email) {
					this.personalData.email = param.email;
				}
				this.ecodePersonalData = this.personalData.ecode;
				return res;
			}),
			catchError((err) => {
				return throwError(err);
			})
		);
	}

	public getDeliveryTypes(): Observable<DeliveryType[]> {
		const url: string = API_URLS.Checkout.getDeliveryTypes;

		const options: OptionCommercialMobile = this.headersData();

		return this.http.get(url, options).pipe(
			map((res: any) => {
				return this.deliveryTypesLogic(res);
			}),
			catchError((err) => {
				return throwError(err);
			})
		);
	}

	private deliveryTypesLogic(response: any): DeliveryType[] {
		// jsonpath refactor: added 'any' type due to missing model
		const resTypes = response.destinosCostesEntrega || [];
		this.deliveryTypes = new Array<DeliveryType>();
		this.deliveryTypes = resTypes.map((type) => {
			const deliveryType = new DeliveryType();
			deliveryType.idType = type.idTipoEntrega || null;
			deliveryType.cdType = type.cdTipoDeliveryType || null;
			deliveryType.price = type.xCoste?.eurPrecio || 0;
			deliveryType.priceTax = type.xCoste?.eurPrecioTaxes || 0;
			deliveryType.selected = type.itMarcadoDefault || false;
			deliveryType.nmOrden = type.nmOrden || 0;
			deliveryType.title = type.nombrePubDestEntrega || null;
			deliveryType.description = type.descrDestinoEntrega || null;
			deliveryType.itMarcadoDefault = type.itMarcadoDefault || null;
			deliveryType.xOpcEntrega = type.xOpcEntrega?.[0] || null;
			return deliveryType;
		});
		return this.deliveryTypes;
	}

	public getDeliveryDates(address: ServiceAddress): Observable<DeliveryModality> {
		const url: string = API_URLS.Checkout.getDeliveryDates;

		// Body params
		const params: ParamsCommercialService = {
			cpDireccionEntrega: address.cp,
			idTipoEntrega: address.idType,
			cdTipoDeliveryType: address.cdType,
		};

		const options: OptionCommercialMobile = this.headersData();

		return this.http.post(url, params, options).pipe(
			map((res: any) => {
				return this.deliveryDatesLogic(res);
			}),
			catchError((err) => {
				return throwError(err);
			})
		);
	}

	private deliveryDatesLogic(response: any): DeliveryModality {
		// jsonpath refactor: added 'any' type due to missing model
		this.deliveryModality = new DeliveryModality();
		this.deliveryModality.cdTipoProceso = response.xTipoProceso?.cdTipoProceso || null;
		this.deliveryModality.dsTipoProceso = response.xTipoProceso?.dsTipoProceso || null;

		this.deliveryModality.xCanal = response.xCanal || null;
		this.deliveryModality.xPerfil = response.xPerfil || null;
		this.deliveryModality.xTipoCliente = response.xTipoCliente || null;

		const responseDates = response.fechasEntrega || [];
		this.deliveryModality.fechasEntrega = responseDates.map((date) => {
			const deliveryDate = new DeliveryDate();
			deliveryDate.fechaEntregaPedido = date.fechaEntregaPedido || null;
			const portabilityDates = date.fechasPortabilidad || null;
			deliveryDate.fechaEntregaPedido = deliveryDate.fechaEntregaPedido
				? moment(deliveryDate.fechaEntregaPedido).toDate()
				: null;

			if (portabilityDates?.[0]) {
				deliveryDate.fechaPortabilidad = moment(portabilityDates[0].fechaPortabilidad).toDate() || null;
				deliveryDate.xDiaEntrega = portabilityDates[0].xDiaEntrega || null;
				deliveryDate.xHoraLiberacion = portabilityDates[0].xHoraLiberacion || null;
				deliveryDate.xSlaFraude = portabilityDates[0].xSlaFraude || null;
			}
			return deliveryDate;
		});

		const responseHours = response.horasEntrega || [];
		this.deliveryModality.horasEntrega = responseHours.map((data) => {
			const deliveryHour = new DeliveryHours();
			deliveryHour.cdTipoDeliveryType = data.cdTipoDeliveryType || null;
			deliveryHour.dsHoraEntrega = data.dsHoraEntrega || null;
			deliveryHour.dsOpcEntrega = data.dsOpcEntrega || null;
			deliveryHour.idModalidadEntrega = data.idModalidadEntrega || null;
			deliveryHour.idTipoEntrega = data.idTipoEntrega || null;
			deliveryHour.nombreModalidadEntrega = data.nombreModalidadEntrega || null;
			deliveryHour.cdCatalogOfferCharge = data.cdCatalogOfferCharge || null;
			deliveryHour.dsCatalogOfferChargeName = data.xCosteOpcEntrega?.dsCatalogOfferChargeName || null;
			deliveryHour.euCatalogOfferCharge = data.euCatalogOfferCharge || 0;
			deliveryHour.eurPrecio = data.eurPrecio || 0;
			deliveryHour.eurPrecioPromocion = data.eurPrecioPromocion || 0;
			deliveryHour.eurPrecioPromocionTaxes = data.eurPrecioPromocionTaxes || 0;
			deliveryHour.eurPrecioTaxes = data.eurPrecioTaxes || 0;
			deliveryHour.idModalidadEntrega = data.idModalidadEntrega || null;
			deliveryHour.idTipoEntrega = data.idTipoEntrega || null;
			deliveryHour.xIdentificacion = data.xIdentificacion || null;
			deliveryHour.xOpcEntrega = data.xOpcEntrega || null;
			deliveryHour.xPrioridadEntrega = data.xPrioridadEntrega || null;
			return deliveryHour;
		});
		return this.deliveryModality;
	}

	public postDeliveryData(
		address: PostalAddress,
		deliveryInfo: DeliveryModality,
		horasEntrega: DeliveryHours,
		deliveryDate: DeliveryDate,
		selectedStore: DeliverySelectedStore,
		office?: DeliveryOffice,
		deliveryPhone?: string
	) {
		const url: string = API_URLS.Checkout.postDeliveryDataSecure;
		let params = {
			deliveryPhone: deliveryPhone,
			flagTieneError: false,
			itPortabilidad: 0,
			clientType:
				this.isME || this.isMicroResegmented
					? commercialMobile.clientTypeMicro.toString()
					: commercialMobile.clientType.toString(),
			shopType:
				this.isME || this.isMicroResegmented
					? commercialMobile.shopTypeMicro.toString()
					: this.isYU
					? commercialMobile.shopTypeYu.toString()
					: commercialMobile.shopType.toString(),
			sceneType: 1,
			address: {
				door: address.door,
				floor: address.floor,
				name: address.name,
				number: address.addressNumber,
				postcode: address.zipCode,
				province: Number(address.province),
				town: address.town,
				type: address.type,
				verticalId: address.verticalId,
				longitude: address.longitud,
				latitude: address.latitud,
			},
			cdTipoProceso: deliveryInfo.cdTipoProceso,
			xTipoProceso: deliveryInfo.dsTipoProceso,
			xCanal: deliveryInfo.xCanal,
			xPerfil: deliveryInfo.xPerfil,
			xTipoCliente: deliveryInfo.xTipoCliente,
			idModalidadEntrega: horasEntrega.idModalidadEntrega,
			idTipoEntrega: horasEntrega.idTipoEntrega,
			cdTipoDeliveryType: horasEntrega.cdTipoDeliveryType,
			xOpcEntrega: horasEntrega.xOpcEntrega,
			nombreModalidadEntrega: horasEntrega.nombreModalidadEntrega,
			dsHoraEntrega: horasEntrega.dsHoraEntrega,
			dsOpcEntrega: horasEntrega.dsOpcEntrega,
			xIdentificacion: horasEntrega.xIdentificacion,
			xPrioridadEntrega: horasEntrega.xPrioridadEntrega,
			xCosteOpcEntrega: {
				eurPrecio: horasEntrega.eurPrecio,
				eurPrecioPromocion: horasEntrega.eurPrecioPromocion,
				eurPrecioTaxes: horasEntrega.eurPrecioTaxes,
				eurPrecioPromocionTaxes: horasEntrega.eurPrecioPromocionTaxes,
				cdCatalogOfferCharge: horasEntrega.cdCatalogOfferCharge,
				dsCatalogOfferChargeName: horasEntrega.dsCatalogOfferChargeName,
				euCatalogOfferCharge: horasEntrega.euCatalogOfferCharge,
			},
			xDestEntrega: horasEntrega.cdTipoDeliveryType,
			xCosteEur: horasEntrega.eurPrecioTaxes,
			xCostePromoEur: horasEntrega.eurPrecioPromocionTaxes,
			xDsOpcEntrega: horasEntrega.dsOpcEntrega,
			eurPrecio: horasEntrega.eurPrecio,
			eurPrecioPromocion: horasEntrega.eurPrecioPromocion,
			eurPrecioTaxes: horasEntrega.eurPrecioTaxes,
			eurPrecioPromocionTaxes: horasEntrega.eurPrecioPromocionTaxes,
			cdCatalogOfferCharge: horasEntrega.cdCatalogOfferCharge,
			dsCatalogOfferChargeName: horasEntrega.dsCatalogOfferChargeName,
			euCatalogOfferCharge: horasEntrega.euCatalogOfferCharge,
			xDiaEntrega: deliveryDate.xDiaEntrega ? deliveryDate.xDiaEntrega : null,
			xHoraLiberacion: deliveryDate.xHoraLiberacion ? deliveryDate.xHoraLiberacion : null,
			xSlaFraude: deliveryDate.xSlaFraude ? deliveryDate.xSlaFraude : null,
			fcEntregaPedido: deliveryDate.fechaEntregaPedido ? deliveryDate.fechaEntregaPedido : deliveryDate,
			fechaPortabilidad: deliveryDate.fechaPortabilidad ? deliveryDate.fechaPortabilidad : null,
			fcPortabilidad: deliveryDate.fechaPortabilidad ? deliveryDate.fechaPortabilidad : null,
		};
		if (selectedStore) {
			const tiendaParams = {
				tiendaNombre: selectedStore.tiendaNombre,
				tiendaSfid: selectedStore.tiendaSfid,
				tiendaTelefono: selectedStore.tiendaTelefono,
				tiendaProvincia: selectedStore.tiendaProvincia,
				tiendaCdAlmacen: selectedStore.tiendaCdAlmacen,
			};
			params = Object.assign({}, params, tiendaParams);
		}
		if (office) {
			const officeParams = {
				correosId: office.id,
				correosCodired: office.codired,
			};
			params = Object.assign({}, params, officeParams);
		}
		const options = this.headersData();

		return this.http.post(url, params, options).pipe(
			map((res: any) => {
				return res;
			}),
			catchError((err) => {
				return throwError(err);
			})
		);
	}

	public getPostalAddress() {
		const url: string = API_URLS.Checkout.getPostalAddress;

		const options: OptionCommercialMobile = this.headersData();

		return this.http.get(url, options).pipe(
			map((res: any) => {
				return this.postalAddressLogic(res);
			}),
			catchError((err) => {
				return throwError(err);
			})
		);
	}

	public getPostalAddressBuyNow(): Observable<PostalAddressBuyNow> {
		const url: string = API_URLS.Checkout.getPostalAddressBuyNnow.replace(
			'{siteId}',
			this.subscriptionData.customerData.currentService.siteId
		);
		const options: OptionCommercialMobile = this.headersData();
		return this.http.get(url, options).pipe(
			map((res: any) => {
				return this.postalAddressLogicBuyNow(res);
			}),
			catchError((err) => {
				return throwError(err);
			})
		);
	}

	public getChangeAddress(): Observable<ChangeAddress[]> {
		const url: string = API_URLS.Checkout.getChangeAddress;
		const options: OptionCommercialMobile = this.headersData();
		return this.http.get(url, options).pipe(
			map((res: ChangeAddress[]) => {
				return this.changeAddressLogic(res);
			}),
			catchError((err) => {
				return throwError(err);
			})
		);
	}

	private postalAddressLogic(response: PostalAddress): PostalAddress {
		const postalAddress = new PostalAddress();
		postalAddress.addressNumber = response.addressNumber || null;
		postalAddress.door = response.door || null;
		postalAddress.floor = response.floor || null;
		postalAddress.name = response.name || null;
		postalAddress.province = response.province || null;
		postalAddress.town = response.town || null;
		postalAddress.type = response.type || null;
		postalAddress.verticalId = response.verticalId || null;
		postalAddress.zipCode = response.zipCode || null;
		postalAddress.notNormalizedAllowed = response.notNormalizedAllowed || false;
		this.postalAddress = postalAddress;
		return this.postalAddress;
	}

	public postalAddressLogicBuyNow(response: PostalAddressBuyNow): PostalAddressBuyNow {
		let postalAddress: PostalAddressBuyNow = new PostalAddressBuyNow();
		postalAddress.id = response.id || 0;
		postalAddress.type = response.type || null;
		postalAddress.name = response.name || null;
		postalAddress.addressNumber = response.addressNumber || null;
		postalAddress.stairway = response.stairway || null;
		postalAddress.floor = response.floor || null;
		postalAddress.door = response.door || null;
		postalAddress.otherdoor = response.otherdoor || null;
		postalAddress.zipCode = response.zipCode || null;
		postalAddress.province = response.province || null;
		postalAddress.town = response.town || null;
		postalAddress.additionalInfo = response.additionalInfo || null;
		postalAddress.verticalId = response.verticalId || null;
		postalAddress.normalized = response.normalized || false;
		postalAddress = this.postalAddressLogicBuyNowLocation(response, postalAddress);
		return postalAddress;
	}

	public postalAddressLogicBuyNowLocation(
		response: PostalAddressBuyNow,
		address: PostalAddressBuyNow
	): PostalAddressBuyNow {
		address.num = response.block || null;
		address.block = response.town || null;
		address.identificador = response.identificador || null;
		address.letter = response.letter || null;
		address.hand1 = response.hand1 || null;
		address.hand2 = response.hand2 || null;
		address.descriptionNumber = response.descriptionNumber || null;
		address.descriptionStair = response.descriptionStair || null;
		address.descriptionFloor = response.descriptionFloor || null;
		address.descriptionDoor = response.descriptionDoor || null;
		address.observations = response.observations || null;
		address.deliveryDate = response.deliveryDate || null;
		address.deliveryTime = response.deliveryTime || null;
		address.deliveryType = response.deliveryType || null;
		return address;
	}

	private changeAddressLogic(respuestaProvinciaDD: ChangeAddress[]): ChangeAddress[] {
		let provinciaList: ChangeAddress[] = new Array<ChangeAddress>();
		// jsonpath refactor: params read as item['xxx'] are missing in model
		provinciaList = respuestaProvinciaDD['ProvinceDD'] || [];

		provinciaList.forEach((province) => {
			const changeAddress: ChangeAddress = new ChangeAddress();
			changeAddress.id = province.id || null;
			changeAddress.name = province.name || null;
			changeAddress.code = province.code || null;
			changeAddress.prefixes = province.prefixes || null;
			provinciaList.push(changeAddress);
		});
		this.changeAddress = provinciaList;
		return this.changeAddress;
	}

	public shoppingCartLogic(items: CommercialShoppingCart) {
		let shoppingCart = new CommercialShoppingCart();
		shoppingCart = this.getShopppingCartPart1(shoppingCart, items);
		const price = items.price || null;
		if (price) {
			shoppingCart.price = this.getPrices(shoppingCart.price, items);
		}
		const itemsCart = new Array<CommercialCartItem>();
		const item = items.cartItems || null;
		if (item) {
			item.map((it) => {
				const itemCart: CommercialCartItem = new CommercialCartItem();
				itemCart.cartItemId = it.cartItemId || null;
				this.cartItemId = itemCart.cartItemId;
				itemCart.price = it.price || null;
				if (itemCart.price) {
					itemCart.price = this.getPrices(itemCart.price, it);
				}
				let services: Array<CommercialService> = new Array<CommercialService>();
				const service: Array<CommercialService> = it.services || null;

				services = this.getServices(service, services);

				itemCart.services = services;
				itemsCart.push(itemCart);
			});
		}
		shoppingCart.cartItems = itemsCart;
		const shippingCosts = items.shippingCosts || null;

		if (shippingCosts) {
			shoppingCart.shippingCosts = this.getShippingCosts(shoppingCart.shippingCosts, items);
		}
		const checkoutCriteriaDto = items.checkoutCriteriaDto || null;
		shoppingCart = this.getShopppingCartPart3(shoppingCart, items);

		if (checkoutCriteriaDto) {
			shoppingCart.checkoutCriteriaDto = this.getCheckoutCriteriaDto(shoppingCart.checkoutCriteriaDto, items);
		}
		this.commercialshoppingCartItems = shoppingCart;
		return this.commercialshoppingCartItems;
	}

	public getShopppingCartPart1(
		shoppingCart: CommercialShoppingCart,
		items: CommercialShoppingCart
	): CommercialShoppingCart {
		shoppingCart.deleteItems = items.deleteItems || false;
		shoppingCart.overMaxInternetLines = items.overMaxInternetLines || false;
		shoppingCart.overMaxLines = items.overMaxLines || false;
		shoppingCart.remainingInternetLines = items.remainingInternetLines || null;
		shoppingCart.remainingMobileLines = items.remainingMobileLines || null;
		shoppingCart.taxType = items.taxType || null;
		shoppingCart.taxValue = items.taxValue || null;
		shoppingCart.siteId = items.siteId || null;
		shoppingCart = this.getShopppingCartPart2(shoppingCart, items);
		return shoppingCart;
	}

	public getShopppingCartPart2(
		shoppingCart: CommercialShoppingCart,
		items: CommercialShoppingCart
	): CommercialShoppingCart {
		shoppingCart.privateOfferList = this.getPrivateOffer(items);
		shoppingCart.pega = items.pega || {};
		return shoppingCart;
	}

	public getShopppingCartPart3(
		shoppingCart: CommercialShoppingCart,
		items: CommercialShoppingCart
	): CommercialShoppingCart {
		shoppingCart.ecode = items.ecode || 0;
		return shoppingCart;
	}

	public getServices(service: Array<CommercialService>, services: Array<CommercialService>): Array<CommercialService> {
		const insuranceOnCartArray: boolean[] = [];
		service.forEach((sv) => {
			let serv: CommercialService = new CommercialService();
			serv = this.getServPart1(serv, sv);

			if (serv.detail) {
				serv.detail = new CommercialDetail();
				serv = this.getServDetailPart1(serv, sv);

				if (serv.detail.promotions) {
					const promotion: Array<PromoCommercial> = sv.detail?.promotions || null;
					serv.detail.promotions = this.getPromotion(promotion);
				}

				const packageText: PackageText = sv.detail?.packageText || null;
				if (packageText) {
					serv.detail.packageText = this.getpackageText(packageText);
				}
				const packageImage: PackageImage = sv.detail?.packageImage || null;
				if (packageImage) {
					serv.detail.packageImage = this.getPackageImage(packageImage);
				}

				if (sv.insurance) {
					serv.insurance = sv.insurance;
					insuranceOnCartArray.push(true);
				} else {
					insuranceOnCartArray.push(false);
				}
				this.hasOneProfOnCart = sv.services?.some(
					(service: CommercialService) => service.detail.serviceItemType === CommercialJourneys.OneProfessionalService
				);
			}
			this.hasInsuranceOnCart = insuranceOnCartArray.find((item: boolean) => item === true);
			services.push(serv);
		});
		return services;
	}

	public getServPart1(serv: CommercialService, sv: CommercialService): CommercialService {
		serv.catalogElementType = sv.catalogElementType || null;
		serv.code = sv.code || null;
		serv.itemId = sv.itemId || null;
		serv.tax = sv.tax || 0;
		serv.productHierarchyType = sv.productHierarchyType || null;
		serv.price = sv.price || null;
		// jsonpath refactor: added any as model does not match
		serv.nodeItemId = sv.nodeItemId?.id as any;
		if (serv.price) {
			serv.price = this.getPrices(serv.price, sv);
		}
		serv.detail = sv.detail || ({} as CommercialDetail);
		return serv;
	}

	public getServDetailPart1(serv: CommercialService, sv: CommercialService): CommercialService {
		serv.detail.additionalLine = sv.detail?.additionalLine || false;
		serv.detail.agentOffer = sv.detail?.agentOffer || false;
		serv.detail.catalogOffer = sv.detail?.catalogOffer || null;
		serv.detail.clientType = sv.detail?.clientType || 0;
		serv.detail.contractType = sv.detail?.contractType || 0;
		serv.detail.coverageTechnology = sv.detail?.coverageTechnology || null;
		serv.detail.crossedOfferId = sv.detail?.crossedOfferId || null;
		serv.detail.description = sv.detail?.description || null;
		serv = this.getServDetailPart2(serv, sv);
		return serv;
	}
	public getServDetailPart2(serv: CommercialService, sv: CommercialService): CommercialService {
		serv.detail.idesc = sv.detail?.idesc || null;
		serv.detail.manualProvision = sv.detail?.manualProvision || false;
		serv.detail.name = sv.detail?.name || null;
		serv.detail.nameList = sv.detail?.nameList || null;
		serv.detail.numberLine = sv.detail?.numberLine || 0;
		serv.detail.offerId = sv.detail?.offerId || 0;
		serv.detail.os = sv.detail?.os || null;
		serv.detail.permanenceDuration = sv.detail?.permanenceDuration || null;
		serv = this.getServDetailPart3(serv, sv);
		return serv;
	}
	public getServDetailPart3(serv: CommercialService, sv: CommercialService): CommercialService {
		serv.detail.plusLine = sv.detail?.plusLine || false;
		serv.detail.rateType = sv.detail?.rateType || 0;
		serv.detail.rateTypeMapeo = sv.detail?.rateTypeMapeo || 0;
		serv.detail.registerType = sv.detail?.registerType || 0;
		serv.detail.registerTypeName = sv.detail?.registerTypeName || null;
		serv.detail.sap = sv.detail?.sap || null;
		serv.detail.sceneType = sv.detail?.sceneType || 0;
		serv.detail.serviceItemType = sv.detail?.serviceItemType || 0;
		serv = this.getServDetailPart4(serv, sv);
		return serv;
	}
	public getServDetailPart4(serv: CommercialService, sv: CommercialService): CommercialService {
		serv.detail.serviceItemTypeName = sv.detail?.serviceItemTypeName || null;
		serv.detail.shopType = sv.detail?.shopType || 0;
		serv.detail.symmetricSpeed = sv.detail?.symmetricSpeed || false;
		serv.detail.finalPaymentNumberMonths = sv.detail?.finalPaymentNumberMonths || null;
		serv.detail.promotions = sv.detail?.promotions || null;
		// jsonpath refactor: changed to string due to incompatible types
		serv.detail.associatedLine = sv.detail?.associatedLine || '0';
		serv.detail.brand = sv.detail?.brand || null;
		serv.detail.promotedCanon = sv.detail?.promotedCanon || false;
		this.promotedCanon = serv.detail.promotedCanon;
		serv = this.getServDetailPart5(serv, sv);
		return serv;
	}
	public getServDetailPart5(serv: CommercialService, sv: CommercialService): CommercialService {
		this.canon = sv.detail?.canonTaxes || 0;
		serv.detail.canon = !serv.detail.promotedCanon ? sv.detail?.canon : 0 || 0;
		serv.detail.canonTaxes = !serv.detail.promotedCanon ? sv.detail?.canonTaxes : 0 || 0;
		serv.detail.capacity = sv.detail?.capacity || null;
		serv.detail.cash = sv.detail?.cash || 0;
		serv.detail.cashTaxes = sv.detail?.cashTaxes || 0;
		serv = this.getServDetailPart6(serv, sv);
		return serv;
	}

	public getServDetailPart6(serv: CommercialService, sv: CommercialService): CommercialService {
		serv.detail.color = sv.detail?.color || null;
		serv.detail.manufacturedType = sv.detail?.manufacturedType || null;
		serv.detail.months = sv.detail?.months || 0;
		serv.detail.paymentType = sv.detail?.paymentType || null;
		serv.detail.permanencePenalty = sv.detail?.permanencePenalty || 0;
		serv.detail.permanencePenaltyTaxes = sv.detail?.permanencePenaltyTaxes || 0;
		serv.detail.terminalType = sv.detail?.terminalType || 0;
		return serv;
	}

	public getpackageText(packageText: PackageText): PackageText {
		packageText = this.getpackageTextPart1(packageText);
		packageText.htmlTextInfo = packageText.htmlTextInfo || null;
		packageText.htmlTextRateTable = packageText.htmlTextRateTable || null;
		packageText.htmlTextTicketMobile = packageText.htmlTextTicketMobile || null;
		packageText.otherDetailList = packageText.otherDetailList || null;
		packageText.otherList = packageText.otherList || null;
		packageText.roamingList = packageText.roamingList || null;
		return packageText;
	}

	public getpackageTextPart1(packageText: PackageText): PackageText {
		packageText.callsIncludedDetailList = packageText.callsIncludedDetailList || null;
		packageText.callsIncludedFicha = packageText.callsIncludedFicha || null;
		packageText.callsIncludedList = packageText.callsIncludedList || null;
		packageText.callsIncludedSecondFicha = packageText.callsIncludedSecondFicha || null;
		packageText.callsIncludedSecondList = packageText.callsIncludedSecondList || null;
		packageText.callsList = packageText.callsList || null;
		packageText.dataDetailFicha = packageText.dataDetailFicha || null;
		packageText.dataDetailList = packageText.dataDetailList || null;
		packageText = this.getpackageTextPart2(packageText);
		return packageText;
	}
	public getpackageTextPart2(packageText: PackageText): PackageText {
		packageText.dataIncludedDetailList = packageText.dataIncludedDetailList || null;
		packageText.dataIncludedFicha = packageText.dataIncludedFicha || null;
		packageText.dataIncludedList = packageText.dataIncludedList || null;
		packageText.dataIncludedSecondFicha = packageText.dataIncludedSecondFicha || null;
		packageText.dataIncludedSecondList = packageText.dataIncludedSecondList || null;
		packageText.dataList = packageText.dataList || null;
		packageText.dataSpeedFicha = packageText.dataSpeedFicha || null;
		packageText.dataSpeedList = packageText.dataSpeedList || null;
		return packageText;
	}

	public getPackageImage(packageImage: PackageImage): PackageImage {
		packageImage = this.getPackageImagePart1(packageImage);
		packageImage.tabletDataIncludedIcon = packageImage.tabletDataIncludedIcon || null;
		packageImage.tabletOtherIcon = packageImage.tabletOtherIcon || null;
		packageImage.tabletRoamingIcon = packageImage.tabletRoamingIcon || null;

		return packageImage;
	}

	public getPackageImagePart1(packageImage: PackageImage): PackageImage {
		packageImage.altCallsIncludedIcon = packageImage.altCallsIncludedIcon || null;
		packageImage.altDataIncludedIcon = packageImage.altDataIncludedIcon || null;
		packageImage.altImageIcon = packageImage.altImageIcon || null;
		packageImage.altOtherIcon = packageImage.altOtherIcon || null;
		packageImage.altRoamingIcon = packageImage.altRoamingIcon || null;
		packageImage.desktopCallsIncludedIcon = packageImage.desktopCallsIncludedIcon || null;
		packageImage.desktopDataIncludedIcon = packageImage.desktopDataIncludedIcon || null;
		packageImage.desktopOtherIcon = packageImage.desktopOtherIcon || null;
		packageImage = this.getPackageImagePart2(packageImage);
		return packageImage;
	}

	public getPackageImagePart2(packageImage: PackageImage): PackageImage {
		packageImage.desktopRoamingIcon = packageImage.desktopRoamingIcon || null;
		packageImage.mobileCallsIncludedIcon = packageImage.mobileCallsIncludedIcon || null;
		packageImage.mobileDataIncludedIcon = packageImage.mobileDataIncludedIcon || null;
		packageImage.mobileOtherIcon = packageImage.mobileOtherIcon || null;
		packageImage.mobileRoamingIcon = packageImage.mobileRoamingIcon || null;
		packageImage.rateImageIcon = packageImage.rateImageIcon || null;
		packageImage.rateImageIconFicha = packageImage.rateImageIconFicha || null;
		packageImage.tabletCallsIncludedIcon = packageImage.tabletCallsIncludedIcon || null;
		return packageImage;
	}

	public getPromotion(promotion: PromoCommercial[]): PromoCommercial[] {
		const promotions: Array<PromoCommercial> = new Array<PromoCommercial>();
		promotion.forEach((prom) => {
			let promo: PromoCommercial = new PromoCommercial();
			promo.id = prom.id || null;
			promo.applyTo = prom.applyTo || null;
			promo.discountAmount = prom.discountAmount || null;
			promo.discountAmountTaxes = prom.discountAmountTaxes || null;
			promo.discountClassType = prom.discountClassType || null;
			promo.discountDuration = prom.discountDuration || null;
			promo.discountType = prom.discountType || null;
			promo = this.getPromotionPart1(promo, prom);
			promo = this.getPromotionPart2(promo, prom);
			promotions.push(promo);
		});
		return promotions;
	}

	public getPromotionPart1(promo: PromoCommercial, prom: PromoCommercial): PromoCommercial {
		promo.evaluationOrder = prom.evaluationOrder || null;
		// jsonpath refactor: changed to string due to incompatible model
		promo.flagIgnoreCRM = prom.flagIgnoreCRM || 'false';
		promo.flagRegional = prom.flagRegional || 'false';
		promo.headbandOpenText = prom.headbandOpenText || null;
		promo.internalName = prom.internalName || null;
		promo.linkedToCatalog = prom.linkedToCatalog || null;
		promo.longText = prom.longText || null;
		promo.publicName = prom.publicName || null;
		return promo;
	}

	public getPromotionPart2(promo: PromoCommercial, prom: PromoCommercial): PromoCommercial {
		promo.detailTextList = prom.detailTextList || null;
		promo.urlMobilePromotionImage = prom.urlMobilePromotionImage || null;
		promo.urlMobilePromotionImageFicha = prom.urlMobilePromotionImageFicha || null;
		promo.llbbText = prom.llbbText || null;
		promo.llbbUrl = prom.llbbUrl || null;
		promo.cardColor = prom.cardColor || null;
		promo.cardText = prom.cardText || null;
		promo.detailPromotionText = prom.detailPromotionText || null;
		return promo;
	}

	public getPrivateOffer(items: CommercialShoppingCart): Array<PrivateOfferListCartModel> {
		const privateOfferList: Array<PrivateOfferListCartModel> = new Array<PrivateOfferListCartModel>();
		const privateOffer: Array<PrivateOfferListCartModel> = items.privateOfferList;
		if (privateOffer) {
			privateOffer.forEach((privateOffer) => {
				const offer: PrivateOfferListCartModel = new PrivateOfferListCartModel();
				offer.techDeviceType = privateOffer.techDeviceType;
				offer.nodeItemId = privateOffer.nodeItemId;
				privateOfferList.push(offer);
			});
		}
		return privateOfferList;
	}
	private getPrices(itemToStore, itemToGet) {
		itemToStore = new CommercialPrice();
		itemToStore.devicesUpfront = itemToGet.devicesUpfront || 0;
		itemToStore.devicesUpfront = itemToGet.devicesUpfront || 0;
		itemToStore.devicesUpfrontPromo = itemToGet.price?.devicesUpfrontPromo || 0;
		itemToStore.devicesUpfrontTaxes = itemToGet.price?.devicesUpfrontTaxes || 0;
		itemToStore.devicesUpfrontTaxesPromo = itemToGet.price?.devicesUpfrontTaxesPromo || 0;
		itemToStore.installment = itemToGet.price?.installment || 0;
		itemToStore.installmentPromo = itemToGet.price?.installmentPromo || 0;
		itemToStore.installmentPromoTaxes = itemToGet.price?.installmentPromoTaxes || 0;
		itemToStore.installmentTaxes = itemToGet.price?.installmentTaxes || 0;
		itemToStore.singlePayment = itemToGet.price?.singlePayment || 0;
		itemToStore.singlePaymentPromo = itemToGet.price?.singlePaymentPromo || 0;
		itemToStore.singlePaymentTaxes = itemToGet.price?.singlePaymentTaxes || 0;
		itemToStore.singlePaymentTaxesPromo = itemToGet.price?.singlePaymentTaxesPromo || 0;
		itemToStore.upfront = itemToGet.price?.upfront || 0;
		itemToStore.upfrontPromo = itemToGet.price?.upfrontPromo || 0;
		itemToStore.upfrontTaxes = itemToGet.price?.upfrontTaxes || 0;
		itemToStore.upfrontTaxesPromo = itemToGet.price?.upfrontTaxesPromo || 0;
		itemToStore.deviceFinalPayment = itemToGet.price?.deviceFinalPayment || null;
		itemToStore.deviceFinalPaymentTaxes = itemToGet.price?.deviceFinalPaymentTaxes || null;
		itemToStore.finalPaymentMonthlyFee = itemToGet.price?.finalPaymentMonthlyFee || null;
		itemToStore.finalPaymentMonthlyFeeTaxes = itemToGet.price?.finalPaymentMonthlyFeeTaxes || null;
		return itemToStore;
	}

	public getCoordinates(zipCode) {
		const url: string = API_URLS.Checkout.getCoordinates.replace('{zipCode}', zipCode);
		const options: OptionCommercialMobile = this.headersData();

		return this.http.get(url, options).pipe(
			map((res: any) => {
				return this.getCoordinatesLogic(res);
			}),
			catchError((err) => {
				return throwError(err);
			})
		);
	}

	getCoordinatesLogic(response): StoreCoordinates {
		const coords: StoreCoordinates = new StoreCoordinates();
		coords.city = response.city || null;
		coords.country = response.country || null;
		coords.latitude = response.latitude || 0;
		coords.longitude = response.longitude || 0;
		coords.province = response.province || null;
		coords.region = response.region || null;
		return coords;
	}

	getTiendasEntregaLogic(response): DeliveryStore[] {
		const tiendas: DeliveryStore[] = response.tiendas || [];
		this.stores = new Array<DeliveryStore>();
		this.stores = tiendas.map((store) => {
			const deliveryStore = new DeliveryStore();
			deliveryStore.diaFin = store.diaFin || null;
			deliveryStore.diaInicio = store.diaInicio || null;
			deliveryStore.distanciaPuntoRef = store.distanciaPuntoRef || null;
			deliveryStore.direccion = store.direccion || null;
			deliveryStore.horaFin = store.horaFin || null;
			deliveryStore.horaFinJornadaPartida = store.horaFinJornadaPartida || null;
			deliveryStore.horaInicio = store.horaInicio || null;
			deliveryStore.horaInicioJornadaPartida = store.horaInicioJornadaPartida || null;
			deliveryStore.nombreTienda = store.nombreTienda || null;
			deliveryStore.sfid = store.sfid || null;
			deliveryStore.codigoPostal = store.codigoPostal || null;
			deliveryStore.latitud = store.latitud || 0;
			deliveryStore.longitud = store.longitud || 0;
			// jsonpath refactor: changed default value to string
			deliveryStore.localidad = store.localidad || '0';
			deliveryStore.cdAlmacen = store.cdAlmacen || null;
			deliveryStore.provincia = store.provincia || null;
			deliveryStore.telefono = store.telefono || null;
			deliveryStore.checked = false;
			return deliveryStore;
		});
		return this.stores;
	}

	private getOficinasCorreosEntregaLogic(response): DeliveryOffice[] {
		const offices: DeliveryOffice[] = response.oficinasCorreos || [];
		let arrayOffices = new Array<DeliveryOffice>();
		arrayOffices = offices.map((office) => {
			const deliveryOffice = new DeliveryOffice();
			deliveryOffice.acceso = office.acceso || null;
			deliveryOffice.ccaa = office.ccaa || null;
			deliveryOffice.codHorario = office.codHorario || null;
			deliveryOffice.codProvincia = office.codProvincia || null;
			deliveryOffice.codigoPostal = office.codigoPostal || null;
			deliveryOffice.codired = office.codired || null;
			deliveryOffice.coordXETRS89 = office.coordXETRS89 || null;
			deliveryOffice.coordXWGS84 = office.coordXWGS84 || null;
			deliveryOffice.coordYETRS89 = office.coordYETRS89 || null;
			deliveryOffice.coordYWGS84 = office.coordYWGS84 || null;
			deliveryOffice.direccion = office.direccion || null;
			deliveryOffice.distanciaPuntoRef = office.distanciaPuntoRef || null;
			deliveryOffice.horario = office.horario || null;
			deliveryOffice.id = office.id || null;
			deliveryOffice.latitud = office.latitud || null;
			deliveryOffice.latitudETRS89 = office.latitudETRS89 || null;
			deliveryOffice.latitudWGS84 = office.latitudWGS84 || null;
			deliveryOffice.localidad = office.localidad || null;
			deliveryOffice.longitud = office.longitud || null;
			deliveryOffice.longitudETRS89 = office.longitudETRS89 || null;
			deliveryOffice.longitudWGS84 = office.longitudWGS84 || null;
			deliveryOffice.nombreUnidad = office.nombreUnidad || null;
			deliveryOffice.provincia = office.provincia || null;
			deliveryOffice.provinciaEs = office.provinciaEs || null;
			deliveryOffice.telefono = office.telefono || null;
			deliveryOffice.checked = false;
			return deliveryOffice;
		});
		return arrayOffices;
	}

	public getTiendasEntrega(province, latitude, longitude) {
		const url = API_URLS.Checkout.getTiendasEntrega
			.replace('{province}', province)
			.replace('{latitude}', latitude)
			.replace('{longitude}', longitude)
			.replace(
				'{shopType}',
				this.isME || this.isMicroResegmented
					? commercialMobile.shopTypeMicro.toString()
					: this.isYU
					? commercialMobile.shopTypeYu.toString()
					: commercialMobile.shopType.toString()
			)
			.replace('{checkStock}', 'false');
		const options = this.headersData();
		return this.http.get(url, options).pipe(
			map((res: any) => {
				return this.getTiendasEntregaLogic(res);
			}),
			catchError((err) => {
				return throwError(err);
			})
		);
	}

	public getOficinasCorreosEntrega(postalCode, latitude, longitude) {
		let url = API_URLS.Checkout.getOficinasCorreosEntrega;
		url = url.replace('{postalCode}', postalCode);
		url = url.replace('{latitude}', latitude);
		url = url.replace('{longitude}', longitude);
		url = url.replace(
			'{shopType}',
			this.isME || this.isMicroResegmented
				? commercialMobile.shopTypeMicro.toString()
				: this.isYU
				? commercialMobile.shopTypeYu.toString()
				: commercialMobile.shopType.toString()
		);
		const options = this.headersData();
		return this.http.get(url, options).pipe(
			map((res: any) => {
				return this.getOficinasCorreosEntregaLogic(res);
			}),
			catchError((err) => {
				return throwError([]);
			})
		);
	}

	private getShippingCosts(
		itemToStore: CommercialShippingCosts,
		itemToGet: CommercialShoppingCart
	): CommercialShippingCosts {
		itemToStore = new CommercialShippingCosts();
		// jsonpath refactor: params read as item['xxx'] are missing in model
		// itemToGet should be type CommercialShippingCosts (<CommercialShoppingCart>.shippingCosts)
		itemToStore.fromActivated = itemToGet['fromActivated'] || false;
		itemToStore.postalCode = itemToGet['postalCode'] || null;
		itemToStore.upfront = itemToGet['upfront'] || 0;
		itemToStore.upfrontPromo = itemToGet['upfrontPromo'] || 0;
		itemToStore.upfrontTaxes = itemToGet['upfrontTaxes'] || 0;
		itemToStore.upfrontTaxesPromo = itemToGet['upfrontTaxesPromo'] || 0;
		return itemToStore;
	}

	private getCheckoutCriteriaDto(
		itemToStore: CommercialCheckoutCriteriaDto,
		itemToGet: CommercialShoppingCart
	): CommercialCheckoutCriteriaDto {
		itemToStore = new CommercialCheckoutCriteriaDto();
		// jsonpath refactor: params read as item['xxx'] are missing in model
		// itemToGet should be type CommercialCheckoutCriteriaDto (<CommercialShoppingCart>.checkoutCriteriaDto)
		itemToStore.deliveryType = itemToGet['deliveryType'] || null;
		itemToStore.postalCode = itemToGet['postalCode'] || null;
		itemToStore.bankAccount = itemToGet['bankAccount'] || null;
		itemToStore.paymenttypeid = itemToGet['paymenttypeid'] || 0;
		return itemToStore;
	}

	getLegalterms() {
		const url = API_URLS.Checkout.getLegalTerms
			.replace(
				'{clientType}',
				this.isME || this.isMicroResegmented
					? commercialMobile.clientTypeMicro.toString()
					: commercialMobile.clientType.toString()
			)
			.replace(
				'{shopType}',
				this.isME || this.isMicroResegmented
					? commercialMobile.shopTypeMicro.toString()
					: this.isYU
					? commercialMobile.shopTypeYu.toString()
					: commercialMobile.shopType.toString()
			)
			.replace('{page}', commercialMobile.page);
		const options: OptionCommercialMobile = this.headersData();

		return this.http.get(url, options).pipe(
			map((res: any) => {
				this.legalTermsLogic(res);
				return res;
			}),
			catchError((err) => {
				return throwError(err);
			})
		);
	}

	private legalTermsLogic(legal: LegalTermDetail) {
		this.legalTermsArray = new Array<LegalTerms>();
		// jsonpath refactor: params read as item['xxx'] are missing in model
		const listAceptos: LegalTerms[] = legal['listAceptos'] || [];
		listAceptos.map((op) => {
			const legalBasics = new LegalTerms();

			legalBasics.checked = op.checked || false;
			legalBasics.id = op.id || null;
			legalBasics.internalDescription = op.internalDescription || null;
			legalBasics.orden = op.orden || null;

			legalBasics.legalTermDetail = new Array<LegalTermDetail>();
			// jsonpath refactor: params read as item['xxx'] are missing in model
			const legalDetail: LegalTermDetail[] = op['legalTermDetails'] || [];
			legalDetail.map((ap) => {
				const legalDetailes = new LegalTermDetail();
				legalDetailes.htmlText = ap.htmlText || null;
				legalDetailes.linkShortText = ap.linkShortText || null;
				legalDetailes.shortText = ap.shortText || null;

				legalBasics.legalTermDetail.push(legalDetailes);
				return legalDetailes;
			});
			this.legalTermsArray.push(legalBasics);
			return legalBasics;
		});
		this.sortLegalTerms(this.legalTermsArray);
	}

	private sortLegalTerms(legalTerms: LegalTerms[]) {
		legalTerms.sort((termFirst, termSecond) => {
			return termFirst.orden < termSecond.orden ? -1 : 1;
		});
		return legalTerms;
	}

	postLegalTerms(id: number[]): Observable<void> {
		const url: string = API_URLS.Checkout.postLegalTerms;
		const headers: HttpHeaders = new HttpHeaders();
		const options: { headers: HttpHeaders; responseType: 'json'; withCredentials: boolean } = {
			headers: headers,
			responseType: 'text' as 'json',
			withCredentials: true,
		};
		const listAceptos: Array<{ id: number; checked: boolean }> = [];
		id.forEach((res) => {
			const plantilla: { id: number; checked: boolean } = { id: res, checked: true };
			listAceptos.push(plantilla);
		});
		const params: { listAceptos: Array<{ id: number; checked: boolean }> } = {
			listAceptos,
		};
		return this.http.post(url, params, options).pipe(
			map((res: any) => {
				return res;
			}),
			catchError((err) => {
				return throwError(err);
			})
		);
	}

	postLegalTermsSave() {
		const url = API_URLS.Checkout.postLegalTermsSave;
		const headers = new HttpHeaders();
		const options = {
			headers: headers,
			withCredentials: true,
		};
		const params = {};
		return this.http.post(url, params, options).pipe(
			map((res: any) => {
				this.personalData.termsId = res.idIkkiRequest;
				this.personalData.email = res.email;
				return res;
			}),
			catchError((err) => {
				return throwError(err);
			})
		);
	}

	public getTerminalsElement(): CommercialTerminal[] {
		return this.terminals.slice();
	}

	public setTerminalsElement(terminals: CommercialTerminal[]) {
		this.terminals = terminals.slice();
	}

	public deleteProducts(itemId: string): Observable<any> {
		const url: string = API_URLS.DeleteProduct;
		const headers: HttpHeaders = new HttpHeaders();
		const options: any = {
			headers: headers,
			auth_ticket: this.storageService.getLocalStorage(LOCAL_STORAGE_KEYS.JWT),
			NTOL_TXID: this.microFlowService.cartId,
			withCredentials: true,
		};
		const params: any = { itemid: itemId };

		return this.http.post(url, params, options).pipe(
			map((res: any) => {
				this.shoppingCartLogic(res);
				return res;
			}),
			catchError((err) => {
				return throwError(err);
			})
		);
	}

	private setShoppingCartParams(
		idPrecio: number,
		paymentType: boolean | number,
		flagPaymentOptionFp: boolean,
		finalPaymentMonths?: number,
		itemId?: number
	): object {
		const params: object = { idPrecio: idPrecio, paymentType: paymentType, flagPaymentOptionFp: flagPaymentOptionFp };
		if (flagPaymentOptionFp) {
			params['finalPaymentMonths'] = finalPaymentMonths;
		}
		params['shopTypeId'] =
			this.isME || this.isMicroResegmented
				? Number(commercialMobile.shopTypeMicro)
				: this.isYU
				? Number(commercialMobile.shopTypeYu)
				: Number(commercialMobile.shopType);

		if (itemId || itemId === 0) {
			params['itemid'] = itemId;
		} else if (this.allowedServices?.selectedLine?.itemId) {
			params['itemid'] = Number(this.allowedServices.selectedLine.itemId) ?? Number(this.cartItemIdTerminals);
		} else {
			params['relatedService'] = Number(this.allowedServices?.selectedLine?.serviceId) || Number(this.msisdn);
		}
		if (this.insuranceModality) {
			params['modalidadSeguro'] = this.insuranceModality;
		}
		return params;
	}

	public setShoppingCartParamsBuyNow(
		idPrecio: number,
		paymentType: boolean | number,
		flagPaymentOptionFp: boolean,
		itemId?: number
	): ParamsBuyNow {
		const params: ParamsBuyNow = {
			idPrecio: idPrecio,
			paymentType: paymentType,
			flagPaymentOptionFp: flagPaymentOptionFp,
		};
		if (this.isME || this.isMicroResegmented) {
			params['shopType'] = Number(commercialMobile.shopTypeMicro);
		} else {
			params['shopType'] = this.isYU ? Number(commercialMobile.shopTypeYu) : Number(commercialMobile.shopType);
		}
		if (itemId || itemId === 0) {
			params['itemid'] = itemId;
		} else if (this.allowedServices?.selectedLine?.itemId) {
			params['itemid'] = Number(this.allowedServices.selectedLine.itemId) ?? Number(this.cartItemIdTerminals);
		} else {
			params['relatedService'] = Number(this.allowedServices?.selectedLine?.serviceId) || Number(this.msisdn);
		}
		if (this.insuranceModality) {
			params['modalidadSeguro'] = this.insuranceModality;
		}
		params['app'] = 0;
		params['version'] = this.translate.instant('v10.commercial.fullVersionPortalEP');
		params['siteId'] = this.subscriptionData.customerData.currentService.siteId;
		params['clientType'] =
			this.isME || this.isMicroResegmented
				? Number(commercialMobile.clientTypeMicro)
				: Number(commercialMobile.clientType);
		params['sceneType'] = Number(commercialMobile.sceneType);
		return params;
	}

	postRequestNotifyMeByEmail(email: string, legalConditionsAccepted: boolean, sap: Number): Observable<any> {
		const url: string = API_URLS.CommercialMobiles.presaleTerminal.clientNotification;
		const headers: HttpHeaders = new HttpHeaders();
		const options: any = {
			headers: headers,
			withCredentials: true,
		};
		const params: any = {
			emailContact: email,
			acceptConditions: legalConditionsAccepted,
			sap: sap,
			clientType:
				this.isME || this.isMicroResegmented
					? Number(commercialMobile.clientTypeMicro)
					: Number(commercialMobile.clientType),
			shopType:
				this.isME || this.isMicroResegmented
					? Number(commercialMobile.shopTypeMicro)
					: this.isYU
					? Number(commercialMobile.shopTypeYu)
					: Number(commercialMobile.shopType),
			sceneType: Number(commercialMobile.sceneType),
			clientId: this.subscriptionData.customerData.currentService.siteId,
		};
		return this.http.post(url, params, options).pipe(
			map((res: any) => {
				return res;
			}),
			catchError((err) => {
				return throwError(err);
			})
		);
	}

	hasXrContentDeviceOnCart(): boolean {
		const saps: string[] = this.translate
			.instant('v10.commercial.checkout.confirmation.xrContents.IDSAPList')
			.split(',');
		return (
			this.commercialshoppingCartItems.cartItems.some((item) =>
				item.services.find((srv) => saps.includes(srv.detail.sap))
			) &&
			this.translate.instant('v10.commercial.checkout.confirmation.xrContents.active').toLowerCase() === stringOfTrue
		);
	}
	selectNumber(
		temp: DeliveryType,
		flagStore: boolean,
		flagOffice: boolean,
		flagHomeAdd: boolean,
		flagHomeChange: boolean,
		wasInvalid: boolean
	): string {
		switch (temp?.cdType) {
			case deliveryParams.storeCd:
				if (!flagStore) {
					return this.defaultNumber;
				} else {
					return this.phoneNumber;
				}
			case deliveryParams.homeCd:
				return this.homeNumberSelect(flagHomeAdd, flagHomeChange, wasInvalid);
			case deliveryParams.officeCd:
				if (!flagOffice) {
					return this.defaultNumber;
				} else {
					return this.phoneNumber;
				}
		}
	}

	homeNumberSelect(flagHomeAdd: boolean, flagHomeChange: boolean, wasInvalid: boolean): string {
		if ((!flagHomeAdd && !flagHomeChange) || (wasInvalid && flagHomeChange && !flagHomeAdd)) {
			return this.originNumber;
		} else if (!flagHomeChange) {
			return this.defaultNumber;
		} else {
			return this.phoneNumber;
		}
	}

	public resetPLTerminals(): void {
		this.PlaylistListTerminals = undefined;
		this.playlistSelected = null;
	}

	public getInsurancesList(shopType: string, sap: string): Observable<InsuranceModel[]> {
		const url: string = API_URLS.Insurance.getInsurance
			.replace('{msisdn}', this.msisdn)
			.replace('{sap}', sap)
			.replace('{shopType}', shopType);
		const headers: HttpHeaders = new HttpHeaders();
		const options: { headers: HttpHeaders; withCredentials: boolean } = {
			headers: headers,
			withCredentials: true,
		};

		return this.http.get(url, options).pipe(
			map((res: InsurancePrices[]) => {
				return res.map((item: InsurancePrices) => this.mapInsuranceList(item));
			}),
			catchError((err) => {
				return throwError(err);
			})
		);
	}

	private mapInsuranceList(res: InsurancePrices): InsuranceModel {
		const wcsInsurance: InsuranceModel = this.translate
			.instant('v10.commercial.care.insuranceSelector.overlay.insurances')
			.find((insurance: InsuranceModel) => insurance.name.toLowerCase() === res.name.toLowerCase());
		return {
			name: res.name,
			description: wcsInsurance.description,
			longDescription: wcsInsurance.longDescription,
			excess: res.excessFee,
			discountedFee: res.promotion,
			fee: res.finalPrice,
			discountDuration: res.duration,
			productId: res.productId,
			coverages: wcsInsurance.coverages,
		};
	}
}
