export class DeliveryStoreResponse {
	tiendas?: DeliveryStore[];
}

export class DeliveryStore {
	sfid: string;
	nombreTienda: string;
	distanciaPuntoRef: number;
	direccion: string;
	horaInicio: string;
	horaFin: string;
	diaInicio: string;
	diaFin: string;
	horaInicioJornadaPartida: string;
	horaFinJornadaPartida: string;
	codigoPostal: string;
	latitud: number;
	longitud: number;
	localidad: string;
	cdAlmacen: string;
	provincia: string;
	telefono: number;
	checked?: boolean;
}
