<vfac-overlay
	[overlayData]="unavailabilityModalData"
	[show]="showUnavailabilityModal"
	[loaded]="true"
	loaderType="vodafone"
	(closed)="close()"
	(primaryBtnClicked)="close()"
	[zIndex]="1000"
>
	<div body>
		<div class="modal-unavailability">
			<div class="modal-unavailability-body">
				<div class="modal-unavailability-title mva10-u--mt4x">
					<span id="UnavailabilityComp_txt_title" class="mva10-u-heading--3" [innerHtml]="title"></span>
				</div>
				<div class="modal-unavailability-image">
					<img
						id="UnavailabilityComp_img"
						[src]="'https://assets-es-pprd.dxlpreprod.local.vodafone.es/assets/images/ic-warning-hi-light-theme@2x.png' | imgFullPath"
						alt="Warning icon"
					/>
				</div>
				<div class="modal-unavailability-content">
					<span id="UnavailabilityComp_txt_body" class="mva10-u--body" [innerHtml]="description"></span>
				</div>
			</div>
		</div>
	</div>
</vfac-overlay>
