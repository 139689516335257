import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import moment from 'moment';
import { Observable, throwError } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { StorageService } from '../../core/services/storage.service';
import { SubscriptionService } from '../../core/services/subscription.service';
import * as constants from '../constants/defines';
import * as Routes from '../constants/routes-config';
import { CustomerBillModel } from '../models/customer-bill.model';
import { BillingService } from './../../billing/billing.service';
import { UtilitiesService } from './../utils/utilities.service';

// MVA10
import { DecimalPipe } from '@angular/common';
import {
	ActionsError,
	ColorsDsl,
	EmptyTallCardModel,
	ErrorDisplayModel,
	ErrorOverlayModel,
	FormOverlayModel,
	IconType,
	OverlayModel,
	TallTileModel,
} from '@mva10/mva10-angular';
import { TranslateService } from '@ngx-translate/core';
import { AppService } from '../../app.service';
import { BillStatus } from '../enums/billStatus.enum';
import { Billing } from '../models/billing.model';
import { CustomerType } from './../enums/customerType.enum';
import { ServiceType } from './../enums/serviceType.enum';
import { TariffService } from './tariff.service';

@Injectable()
export class CustomerBillService {
	public customerAccountId: string;
	userBill: CustomerBillModel;
	bill: CustomerBillModel;

	billingTileData: TallTileModel;
	emptyData: EmptyTallCardModel;
	errorBillingTile: ErrorDisplayModel;
	overlayBillingLoaded: boolean = false;

	valueAmountPrepaid: string;
	endBilling: any;
	billingPeriodEnd: any;
	billingPeriodEndFormat: any;
	billingPeriodStart: any;
	nextBilling: any;

	formOverlayData: FormOverlayModel;
	overlayBillingData: ErrorOverlayModel;
	headerOverlayErrorBilling: OverlayModel;

	constructor(
		private http: HttpClient,
		private storageService: StorageService,
		private subscription: SubscriptionService,
		private subscriptionService: SubscriptionService,
		private tariffService: TariffService,
		private translateService: TranslateService,
		private numberPipe: DecimalPipe,
		private utilitiesService: UtilitiesService,
		private appService: AppService,
		private billingService: BillingService
	) {
		this.bill = new CustomerBillModel();
	}

	public GetCustomerBill() {
		let headers: HttpHeaders = new HttpHeaders();
		const siteID: string = this.subscription.GetCustomerAccountsId();
		headers = headers.append('Content-Type', 'application/json');
		headers = headers.append('accept', 'application/json');
		const options = {
			headers: headers,
		};
		return this.http.get(Routes.API_URLS.Bill.GetCustomerBill.replace('{siteId}', siteID), options).pipe(
			map((response: any) => {
				this.userBill = new CustomerBillModel();
				const startTime = response.items?.[0]?.parts?.[0]?.billingCycle?.billingPeriod?.startDate
					? new Date(response.items?.[0]?.parts?.[0]?.billingCycle?.billingPeriod?.startDate)
					: undefined;
				if (startTime !== undefined) {
					this.userBill.startDate = startTime;
					this.userBill.formatedStart_date =
						this.getDateFormatted(
							startTime.getFullYear() + '-' + (startTime.getMonth() + 1) + '-' + startTime.getDate()
						) || null;
				}
				const endDate = response.items?.[0]?.parts?.[0]?.billingCycle?.billingPeriod?.endDate;
				const date = new Date(endDate);
				this.userBill.endDate = date;
				this.userBill.formatedEnd_date =
					this.getDateFormatted(date.getFullYear() + '-' + (date.getMonth() + 1) + '-' + date.getDate()) || null;
				this.bill = this.userBill;
			})
		);
	}

	public GetCustomerBillTemporal(customerAccountsId) {
		let headers = new HttpHeaders();

		headers = headers.append('Content-Type', 'application/json');
		headers = headers.append('accept', 'application/json');
		const options = {
			headers: headers,
		};
		return this.http.get(Routes.API_URLS.Bill.GetCustomerBill.replace('{siteId}', customerAccountsId), options).pipe(
			map((response: any) => {
				const userBill = new CustomerBillModel();
				const startTime = response.items?.[0]?.parts?.[0]?.billingCycle?.billingPeriod?.startDate
					? new Date(response.items?.[0]?.parts?.[0]?.billingCycle?.billingPeriod?.startDate)
					: undefined;
				if (startTime !== undefined) {
					userBill.startDate = startTime;
					userBill.formatedStart_date =
						this.getDateFormatted(
							startTime.getFullYear() + '-' + (startTime.getMonth() + 1) + '-' + startTime.getDate()
						) || null;
				}
				const endDate = response.items?.[0]?.parts?.[0]?.billingCycle?.billingPeriod?.endDate;
				const date = new Date(endDate);
				userBill.endDate = date;
				userBill.formatedEnd_date =
					this.getDateFormatted(date.getFullYear() + '-' + (date.getMonth() + 1) + '-' + date.getDate()) || null;
				return userBill;
			})
		);
	}

	public getDateFormatted(date: string): string {
		// date = 2017-05-31
		const splitDate = date.split('-');
		const day = (+splitDate[2]).toLocaleString('en-US', { minimumIntegerDigits: 2, useGrouping: false });
		const month = splitDate[1];
		const year = splitDate[0];
		let alphabeticMonth = '';
		alphabeticMonth = this.utilitiesService.getMonthName(month);
		return day + ' ' + alphabeticMonth + ' ' + year;
	}

	setErrorTallTile() {
		this.translateService.get('v10.common.literals').subscribe((text) => {
			this.billingTileData = null;
			this.emptyData = null;
			this.errorBillingTile = new ErrorDisplayModel();
			this.errorBillingTile.icon = IconType.ICON_ERROR_GLOBAL;
			this.errorBillingTile.description = text.msg.error.ups;
			this.errorBillingTile.action = text.retry_C;
		});
	}

	getAmountPrepaid(value: Array<any>): string {
		let amountValue: number = 0;
		if (value && value.length > 0) {
			for (let index: number = 0; index < value.length; index++) {
				if (value[index].type === constants.defines.credit && value[index].amount) {
					amountValue = value[index].amount.amount
						? amountValue + parseFloat(value[index].amount.amount)
						: amountValue + parseFloat(value[index].amount);
				}
			}
			this.valueAmountPrepaid = amountValue.toFixed(2).toString().replace('.', ',').replace(',00', '');
		} else {
			this.valueAmountPrepaid = constants.defines.amountZero;
		}
		if (this.billingTileData) {
			this.billingTileData.descriptionLeft = this.valueAmountPrepaid;
			this.billingTileData.descriptionRight = constants.defines.euro;
		}
		return this.valueAmountPrepaid;
	}

	getDataCustomerBill(): Observable<any> {
		return this.http
			.get(
				Routes.API_URLS['Dashboard-WS10'].CustomerBill.CustomerAccountId.replace(
					'{siteId}',
					this.subscriptionService.customerData.customerAccountsId
				)
			)
			.pipe(
				map((response: any) => {
					if (response.items.length !== 0) {
						moment.locale('es');
						this.endBilling = new Date(response.items[0].parts[0].billingCycle.billingPeriod.endDate);
						this.billingPeriodEnd = moment(new Date()).format('DD MMM').replace('.', '');
						this.billingPeriodEndFormat = moment(new Date()).format('DD MMM YYYY').replace('', '/');
						this.billingPeriodStart = moment(new Date(response.items[0].parts[0].billingCycle.billingPeriod.startDate))
							.format('DD MMM')
							.replace('.', '');

						this.nextBilling = new Date(response.items[0].parts[0].billingCycle.billingPeriod.endDate);
						this.nextBilling = moment(new Date(response.items[0].parts[0].billingCycle.billingPeriod.endDate))
							.format('DD MMMM YYYY')
							.replace('.', '');
					} else {
						this.setEmptyBillingTile(constants.HttpOk);
					}
				}),
				catchError((error) => {
					this.getStatusError(error);
					return throwError(error);
				})
			);
	}

	getStatusError(error) {
		if (
			(error.status === 400 && error.error.ecode === constants.NoBILLS.billsError) ||
			(error.status === 400 && error.error.ecode === constants.NoBILLS.noBillsOnboardingError) ||
			error.status === 403
		) {
			this.setEmptyBillingTile(error.status);
		} else {
			this.setErrorTallTile();
		}
	}

	setEmptyBillingTile(error?) {
		this.billingTileData = null;
		this.emptyData = null;
		this.formOverlayData = null;
		this.overlayBillingLoaded = true;
		if (
			(this.tariffService.tariffWs10 && this.tariffService.tariffWs10.extension) ||
			this.subscriptionService.customerData.currentService.type !== ServiceType.Prepaid
		) {
			this.translateService.get('v10').subscribe((text) => {
				if (this.tariffService.tariffWs10 && this.tariffService.tariffWs10.extension) {
					if (
						this.tariffService.tariffWs10 &&
						this.tariffService.tariffWs10.extension.es.benefits &&
						this.tariffService.tariffWs10.extension.es.benefits.isEligible === true
					) {
						this.billingTileData = new TallTileModel();
						this.billingTileData.icon = IconType.ICON_PREPAID;
						this.billingTileData.title = text.dashboard.common.urbalance;
						this.billingTileData.subtitle = text.dashboard.common.urbalance_description;
						this.billingTileData.descriptionLeft = this.valueAmountPrepaid
							? this.valueAmountPrepaid
							: constants.defines.amountZero + constants.defines.spacer;
						this.billingTileData.descriptionRight = constants.defines.euro;
						this.billingTileData.footerText = text.dashboard.common.recharge;
						this.billingTileData.footerIcon = IconType.ICON_PREPAID_ADD;
						this.overlayBillingData = null;
					} else {
						this.emptyData = new EmptyTallCardModel();
						this.emptyData.icon1 = IconType.ICON_TALL_TILE;
						this.emptyData.icon2 = this.appService.getImgFullPath(text.dashboard.common.tile_bills_image_rsp);
						this.emptyData.title = text.dashboard.common.tile_bill_title;
						this.emptyData.description = text.dashboard.common.tile_billTutorial_description;
						this.emptyData.bgColor = ColorsDsl.LIGHTGREY;
						this.overlayBillingData = null;
					}
				} else {
					this.emptyData = new EmptyTallCardModel();
					if (error && (error === 400 || error === constants.HttpOk)) {
						this.emptyData.icon1 = IconType.ICON_TALL_TILE;
						this.emptyData.icon2 = this.appService.getImgFullPath(text.dashboard.common.tile_bills_image_rsp);
						this.emptyData.title = text.dashboard.common.tile_bill_title;
						this.emptyData.description = text.dashboard.common.tile_billTutorial_description;
						this.emptyData.bgColor = ColorsDsl.LIGHTGREY;
						this.overlayBillingData = null;
					} else {
						this.emptyData.icon1 = IconType.ICON_TALL_TILE;
						this.emptyData.icon2 = IconType.ICON_TAP_ACCESS;
						this.emptyData.title = text.dashboard.common.tile_bill_title;
						this.emptyData.description = text.dashboard.common.no_pass_tile;

						this.translateService.get('v10').subscribe((text) => {
							this.headerOverlayErrorBilling = new OverlayModel();
							this.headerOverlayErrorBilling.title = ' ';
							this.headerOverlayErrorBilling.isScrollableTray = true;
							this.overlayBillingData = new ErrorOverlayModel();
							this.overlayBillingData.icon = IconType.ICON_TAP_ACCESS;
							this.overlayBillingData.title = text.dashboard.overlay_bill.title;
							this.overlayBillingData.description = text.dashboard.overlay_bill.subtitle;
							this.overlayBillingData.secondaryBtnText = text.common.literals.close_C;
							this.overlayBillingData.secondaryBtnAction = ActionsError.CLOSE;
						});
						// FORM OVERLAY DATA
						this.translateService.get('v10').subscribe((text) => {
							this.formOverlayData = new FormOverlayModel();
							this.formOverlayData.icon = IconType.ICON_TAP_ACCESS;
							this.formOverlayData.title = text.dashboard.overlay_light_to_complete.title;
							this.formOverlayData.description = text.dashboard.overlay_light_to_complete.subtitle;
							this.formOverlayData.primaryBtnText = text.common.literals.agree_C;
						});
					}
				}
			});
		}
	}

	getDataBilling(): Observable<any> {
		this.errorBillingTile = null;
		this.emptyData = null;
		this.billingTileData = null;
		let headers: HttpHeaders = new HttpHeaders();
		headers = headers.append('Content-Type', 'application/json');
		headers = headers.append('version', '2.0');
		const options: { [key: string]: HttpHeaders } = {
			headers: headers,
		};
		if (this.storageService.userProfile.customerType.toUpperCase() === CustomerType.Authorized.toUpperCase()) {
			return this.http
				.get(
					Routes.API_URLS['Dashboard-WS10'].CustomerBill.Billing +
						constants.defines.cif +
						this.storageService.userProfile.document.id,
					options
				)
				.pipe(
					map((response: any) => {
						const bills: Billing[] = response.items;
						bills.map((bill, index: number) => {
							if (response.items[index]?.billOverview?.amounts[0]) {
								bill.billOverview.amounts = response.items[index]?.billOverview?.amounts[0];
							}
						});
						this.initAuthorizedBillingData(bills);
					}),
					catchError((error) => {
						this.getStatusError(error);
						return throwError(error);
					})
				);
		} else {
			return this.http.get(Routes.API_URLS['Dashboard-WS10'].CustomerBill.Billing, options).pipe(
				map((response: any) => {
					const bills: Billing[] = response.items;
					bills.map((bill, index: number) => {
						if (response.items[index]?.billOverview?.amounts[0]) {
							bill.billOverview.amounts = response.items[index]?.billOverview?.amounts[0];
						}
					});
					this.initCustomerBillingData(bills);
				}),
				catchError((error) => {
					this.getStatusError(error);
					return throwError(error);
				})
			);
		}
	}

	initAuthorizedBillingData(bills: Billing[]): void {
		if (bills.length !== 0) {
			const valueAmount: string = this.getAmountBill(bills);
			this.initBillingTile(valueAmount, bills);
		} else {
			this.setEmptyBillingTile(constants.HttpOk);
		}
	}

	initCustomerBillingData(bills: Billing[]): void {
		if (bills.length !== 0) {
			const responseJson: Billing[] = bills;

			let array: {} = {};

			responseJson.forEach((item) => {
				const val: string = item.billingCycle.billingYear + constants.defines.guin + item.billingCycle.billingMonth;
				const json: { year: string; month: string; amount: number; date: string } = {
					year: item.billingCycle.billingYear,
					month: item.billingCycle.billingMonth,
					amount: 0,
					date: item.billingCycle.startDate,
				};

				array[val] = array[val] || json;
				array[val].amount += item.billOverview.amounts.grossAmountDue;
				return json;
			});
			array = Object.keys(array)
				.map((key) => array[key])
				.sort((a, b) => {
					if (a.date < b.date) {
						return 1;
					}
					if (a.date > b.date) {
						return -1;
					}
					return 0;
				});

			const valueAmount: string = this.formatAmount(array[0].amount);
			this.initBillingTile(valueAmount, bills);
		} else {
			this.setEmptyBillingTile(constants.HttpOk);
		}
	}

	formatAmount(value) {
		const cadena = this.numberPipe.transform(value, '1.2-2').split('.');
		const resultCadena: string = (
			cadena[0].replace(',', '.') +
			constants.defines.comma +
			cadena[1] +
			constants.defines.euro
		).replace(',00', '');
		return resultCadena;
	}

	getAmountBill(amount: Billing[]): string {
		const amountBill: number = amount.reduce((suma, item) => {
			const addAll: number = (suma += item.billOverview.amounts.grossAmountDue);
			return addAll;
		}, 0);
		const resultCadena = this.formatAmount(amountBill);
		return resultCadena;
	}

	initBillingChart() {
		return this.billingService.GetBills().pipe(
			map((response: Array<Billing>) => {
				const billingChartItems = [];
				if (response.length !== 0) {
					const items: Billing[] = response
						.reverse()
						.filter((e) => e.billOverview.amounts)
						.slice(0, constants.billingTileChart.maxBills)
						.reverse();
					this.getLastBillingData(items);

					let billAmount = 0;
					if (items.length > 0) {
						let year: string = items[0].billingCycle.billingYear;

						items.forEach((item) => {
							billAmount += item.billOverview.amounts.grossAmountDue;
							billingChartItems.push({
								value: billAmount,
								monthText: this.utilitiesService.getMonthName(item.billingCycle.billingMonth, true),
								dateText: year !== item.billingCycle.billingYear ? item.billingCycle.billingYear : null,
							});
							year = item.billingCycle.billingYear;
							billAmount = 0;
						});
					}

					return billingChartItems;
				} else {
					this.setEmptyBillingTile(constants.HttpOk);
				}
			}),

			catchError((error) => {
				this.getStatusError(error);
				return throwError(error);
			})
		);
	}

	getLastBillingData(bills: Billing[]): void {
		bills = bills.filter(
			(bill) =>
				bill.status.toLowerCase() === BillStatus.ready.toLowerCase() ||
				bill.status.toLowerCase() === BillStatus.pdf.toLowerCase()
		);
		const lastbill: Billing = bills[bills.length - 1];
		bills = bills.filter(
			(bill) =>
				bill.billingCycle.billingYear === lastbill.billingCycle.billingYear &&
				bill.billingCycle.billingMonth === lastbill.billingCycle.billingMonth &&
				bill.billingCycle.billingDate === lastbill.billingCycle.billingDate
		);
		if (this.storageService.userProfile?.customerType?.toLowerCase() === CustomerType.Authorized.toLowerCase()) {
			this.initAuthorizedBillingData(bills);
		} else {
			this.initCustomerBillingData(bills);
		}
	}

	initBillingTile(amount: string, bills: Billing[]): void {
		this.billingTileData = null;
		this.emptyData = null;
		this.formOverlayData = null;

		if (
			(this.tariffService.tariffWs10 &&
				this.tariffService.tariffWs10.extension &&
				this.tariffService.tariffWs10.extension.es &&
				this.tariffService.tariffWs10.extension.es.benefits &&
				this.tariffService.tariffWs10.extension.es.benefits.isEligible === true &&
				this.tariffService.tariffWs10.extension.es.benefits.status === constants.defines.active) ||
			this.subscriptionService.customerData.currentService.type !== ServiceType.Prepaid
		) {
			this.billingTileData = new TallTileModel();
			this.translateService.get('v10').subscribe((text) => {
				this.billingTileData.title = text.dashboard.common.tile_bill_title;
				if (bills.length > 1) {
					this.billingTileData.subtitle = text.dashboard.common.tile_bills_description;
				} else {
					this.billingTileData.subtitle = text.dashboard.common.tile_bill_description;
				}
				this.billingTileData.descriptionLeft = amount;
				this.billingTileData.icon = IconType.ICON_TALL_TILE;
			});
		}
	}

	clearTileData(): void {
		this.emptyData = null;
		this.billingTileData = null;
		this.errorBillingTile = null;
	}
}
