import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Router, RouterStateSnapshot } from '@angular/router';
import { Observable } from 'rxjs';
import { config } from 'src/config/pages-config';
import { CustomerType } from '../../shared/enums/customerType.enum';
import { DeepLinkingService } from '../../shared/services/deep-linking.service';
import { AuthenticateService } from '../services/authenticate.service';

@Injectable()
export class AuthorizedUserGuard {
	constructor(
		private auth: AuthenticateService,
		private router: Router,
		private deepLinkingService: DeepLinkingService
	) {}
	canActivate(
		next: ActivatedRouteSnapshot,
		state: RouterStateSnapshot
	): Observable<boolean> | Promise<boolean> | boolean {
		if (this.auth.hasRole(CustomerType.Authorized.toLowerCase())) {
			this.deepLinkingService.isError = false;
			return true;
		} else {
			this.deepLinkingService.isError = true;
			this.router.navigate([config.dashboard.route]);
			return false;
		}
	}
}
