import { AfterContentChecked, Component, OnDestroy, OnInit, Renderer2 } from '@angular/core';
import { NavigationCancel, NavigationEnd, NavigationError, NavigationStart, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import * as PagesConfig from '../config/pages-config';
import { tagging } from '../config/tagging-config';
import { config } from './../config/pages-config';
import { AppService } from './app.service';
import { BillingService } from './billing/billing.service';
import { AuthenticateService } from './core/services/authenticate.service';
import { CommonService } from './core/services/common.service';
import { ConfigurationService } from './core/services/configuration.service';
import { NewTaggingHelperService } from './core/services/new-tagging.helper.service';
import { RoutesConfigService } from './core/services/routes-config.service';
import { StorageService } from './core/services/storage.service';
import { TaggingHelperService } from './core/services/tagging.helper.service';
import { LeftMenuWs10Service } from './mva10/shared/services/left-menu-ws10.service';
import { ProductsAndServicesService } from './products-and-services/products-and-services.service';
import { CampaignService } from './shared/components/campaign-overlay/campaign-overlay.service';
import {
	AppThemes,
	LOCAL_STORAGE_KEYS,
	MessageCode0,
	MessageCode2,
	NAVIGATION_ERROR_CANT_MATCH_ROUTE, portalHTML5, STORE_URL,
	TOBibubbleValues
} from './shared/constants/defines';
import { CustomerType } from './shared/enums/customerType.enum';
import { OperatingSystem } from './shared/enums/operating-systems.enum';
import { BubbleHelpConfiguration } from './shared/models/bubbleHelpConfiguration';
import { Notification } from './shared/models/notification.model';
import { Route } from './shared/models/Route.model';
import { taggingConfig } from './shared/models/tagging.model';
import { URLConfig } from './shared/models/url.model';
import { CrossFunctionalityService } from './shared/services/cross-functionality.service';
import { DeepLinkingService } from './shared/services/deep-linking.service';
import { Mva10FullOverlayService } from './shared/services/mva10-full-overlay.service';
import { NotificationService } from './shared/services/notification.service';
import { SidemenuConfigService } from './shared/services/sidemenu-config.service';
import { SpMva10OverlayService } from './shared/services/sp-mva10-overlay.service';
import { LoggerService } from './shared/utils/LoggerService';
import { UtilitiesService } from './shared/utils/utilities.service';
import { TopUpNavigationService } from './top-up/top-up-navigation.service';

@Component({
	selector: 'sp-root',
	templateUrl: './app.component.html',
	styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit, OnDestroy, AfterContentChecked {
	title = 'Mi Vodafone';
	siteContent = {};
	firstNudgeButtonText: string;
	secondNudgeButtonText: string;
	showAdviseApp: boolean;
	OPERETINGSYSTEM_MAC = OperatingSystem.mac;

	public AppThemes = AppThemes;
	constructor(
		public storage: StorageService,
		public authenticate: AuthenticateService,
		public appTheme: AppService,
		public commonService: CommonService,
		private router: Router,
		private notificationService: NotificationService,
		public cross: CrossFunctionalityService,
		public utilitiesService: UtilitiesService,
		private tealium: TaggingHelperService,
		private deepLinkingService: DeepLinkingService,
		private rendrer: Renderer2,
		private routeConfigService: RoutesConfigService,
		private logger: LoggerService,
		private translateService: TranslateService,
		private productAndService: ProductsAndServicesService,
		private billService: BillingService,
		public menuService: SidemenuConfigService,
		public campaignService: CampaignService,
		public configurationService: ConfigurationService,
		private topupNavigationService: TopUpNavigationService,
		public mva10FullOverlayService: Mva10FullOverlayService,
		public leftMenuService: LeftMenuWs10Service,
		/** Load app from web banner at first time */
		public _Mva10OverlayService: SpMva10OverlayService,
		public _newTaggingService: NewTaggingHelperService
	) { }

	loadFrequencyFromWCS() {
		if (!this.appTheme.frequency && this.appTheme.frequency !== 0 && this.router.url !== '/') {
			this.translateService.get('dashboard.itemsList.frequencyToShowNudge.frequencyToShowNudge').subscribe((data) => {
				this.appTheme.frequency = +data || 0;
			});
		}
	}
	getPageOriginalReferingFromDeepLink(url: URLConfig): string {
		try {
			return decodeURIComponent(tagging.staticVariables.pageOriginalRefering.replace('{0}', url.destination));
		} catch (error) {
			return '';
		}
	}
	configureBubbleHelp() {
		this.commonService.showChatInBubbleHelp = true;
		this.commonService.showFAQsInBubbleHelp = false;
		const currentPage = this.tealium.getCurrentPage(this.router.url);
		const page_name: string = currentPage?.page_name ?? currentPage?.name;
		let bubbleHelpConfiguration: BubbleHelpConfiguration = {
			chatEnabled: true,
			faqsEnabled: false,
			module: page_name,
			name: page_name,
			taggingName: page_name,
			tobiBubble: TOBibubbleValues.directOrigin,
			pageName: page_name,
		};
		this.commonService.currentPageBubbleHelpConfig = bubbleHelpConfiguration;
		this.commonService.showContactUS =
			this.storage.userProfile &&
			this.storage.userProfile.customerType &&
			this.storage.userProfile.customerType.toString().toLowerCase() === CustomerType.Authorized.toLowerCase();
	}
	public getRoute(url: string): Route {
		let route: Route = null;
		const conf = config;
		const linkUrl = url.split('?')[0];
		Object.keys(conf).forEach((moduleKey) => {
			if (conf[moduleKey].route === linkUrl) {
				route = conf[moduleKey];
			} else {
				Object.keys(conf[moduleKey]).forEach((stepKey) => {
					if (conf[moduleKey][stepKey].route === linkUrl) {
						route = conf[moduleKey][stepKey];
					}
				});
			}
		});
		return route;
	}

	ngOnInit(): void {
		this.logger.info('Starting app. component');
		this.initRouteListener();
		this.menuService.bubbleHelpConfigSubject.subscribe(() => {
			this.configureBubbleHelp();
		});
		this.utilitiesService.getDeviceInfo();
		// check if browser window is offline, the popup will appears
		this.rendrer.listen('window', 'offline', (evt) => {
			this.appTheme.onlineFlag = false;
		});
		this.rendrer.listen('window', 'online', (evt) => {
			this.appTheme.onlineFlag = true;
		});
	}

	ngOnDestroy(): void {
		this.leftMenuService.clearMenu();
	}

	ngAfterContentChecked(): void {
		// Adding Try catch to stop tagging from breaking the application if it caused an error
		try {
			const exceptionCheck = this.tealium.checkFinishLoadOfExceptionRouts();
			if (
				taggingConfig.navigationEnd &&
				taggingConfig.pendingRequests.length === 0 &&
				taggingConfig.componentFinishInit &&
				!this.appTheme.showFullAppLoader &&
				!this.billService.showSpinner &&
				exceptionCheck
			) {
				if (!this.router.url.includes(config.ProductsAndServices.name)) {
					this.tealium.sendViewState();
				}
			}
		} catch (error) {
			// Do nothing
		}
	}


	hideBackBtnMenuAyoda() {
		this.commonService.showMenuButton = false;
		this.appTheme.showBackButton = false;
		this.commonService.showChatInBubbleHelp = false;
		this.commonService.showFAQsInBubbleHelp = false;
		this.commonService.showContactUS = false;
	}

	checkAndAppendExtraQueryStrings(extraQueryString: string, url: URLConfig): void {
		if (extraQueryString) {
			const parsedURL: URL = new URL(url.destination);

			const nativeLink = parsedURL.searchParams.get('link');
			const webLink = parsedURL.searchParams.get('afl');

			/** Append query string to (link param for native, afl and ifl for web) by replacing them with new links includes extra params */
			this.changeDestinationURL(nativeLink, extraQueryString, url);
			this.changeDestinationURL(webLink, extraQueryString, url);
		}
	}
	changeDestinationURL(partialURL: string, extraQueryString: string, url: URLConfig): void {
		if (partialURL) {
			partialURL = encodeURIComponent(partialURL);
			/** Check if it already has a query param then we have to append on it using `&` otherwise using `?` */
			const separator = encodeURIComponent(partialURL.includes('?') ? '&' : '?');
			/** using split because of web has two URLs, so we need to replace them together */
			url.destination = url.destination
				.split(partialURL)
				.join(`${partialURL}${separator}${encodeURIComponent(extraQueryString)}`);
		}
	}

	routeToValidUrl(url: string, queryString: string): void {
		if (this.utilitiesService.validateUrl(url)) {
			this.routeConfigService.getUrl(url).subscribe(
				(resUrl: URLConfig) => {
					const messageCode: number = resUrl.messageCode;
					this.deepLinkingService.deeplinkStartFlag = messageCode === MessageCode0;
					switch (messageCode) {
						case MessageCode0: {
							resUrl.destination = decodeURIComponent(resUrl.destination);
							const queryUrl: string[] = resUrl.destination.split('?');
							if (queryUrl.length > 1) {
								const params: {} = this.utilitiesService.getRouteParams(resUrl.destination);
								this.router.navigate([queryUrl[0]], { queryParams: params });
							} else {
								this.router.navigate([resUrl.destination]);
							}
							break;
						}
						case MessageCode2: {
							// in case of message code = 2 we have to check on the device info
							// in case of android we will navigate to play store if mac or ios we will navigate to apple store
							// in case of desktop we will navigate to dashboard which is the default case
							this.utilitiesService.getDeviceInfo();
							if (this.utilitiesService.deviceInfo && this.utilitiesService.deviceInfo.os) {
								switch (this.utilitiesService.deviceInfo.os.toLowerCase()) {
									case OperatingSystem.android.toLowerCase():
										window.open(STORE_URL.ANDROID, '_self');
										break;
									case OperatingSystem.mac.toLowerCase():
									case OperatingSystem.ios.toLowerCase():
										window.open(STORE_URL.IOS, '_self');
										break;
									default:
										this.router.navigate([config.dashboard.route]);
								}
							} else {
								this.router.navigate([config.dashboard.route]);
							}
							break;
						}
						default:
							this.router.navigate([config.dashboard.route]);
							break;
					}
					const pageOriginalRefering: string = this.getPageOriginalReferingFromDeepLink(resUrl);
					this.storage.setStorage(LOCAL_STORAGE_KEYS.TAGGING_PAGE_ORIGINAL_REFERING, pageOriginalRefering);

					this.checkAndAppendExtraQueryStrings(queryString, resUrl);
				},
				(error) => {
					this.router.navigate([config.dashboard.route]);
				}
			);
		} else {
			this.router.navigate([config.dashboard.route]);
		}
	}

	handleTvOnlineUrl(url: string): void {
		const tvOnlinePattern = /^\/tvonline\/deviceFamilyId\/(\d+)\/settings\.RegisterDevice\?pin=(\d+)/;
		const match = tvOnlinePattern.exec(url);
		if (match) {
			const deviceFamilyId = match[1];
			const pin = match[2];
			sessionStorage.setItem('deviceFamilyId', deviceFamilyId);
			sessionStorage.setItem('pin', pin);
			const newUrl = `/ProductsAndServices/vodafoneTv`;
			this.router.navigate([newUrl]);
		}
	}

	initRouteListener(): void {
		this.router.events.subscribe((event) => {
			this.topupNavigationService.init();
			if (event instanceof NavigationStart) {
				this.navigationStartHandling(event);
			}
			if (event instanceof NavigationError) {
				this.navigationErrorHandling(event);
			}
			if (event instanceof NavigationEnd) {
				this.navigationEndHandling(event);
			}
			if (event instanceof NavigationCancel) {
				this.navigationCancelHandling(event);
			}
		});
	}

	navigationStartHandling(event: NavigationStart): void {
		// This method is for handling the deeplink of TV Online
		this.handleTvOnlineUrl(event.url);

		if (this.commonService.overrideBackHandling) {
			this.commonService.backBtnSubject.next();
		}
		const contentRoot: HTMLCollectionOf<Element> = document.getElementsByClassName('main-content');
		if (event['id']) {
			this.deepLinkingService.navigationId = event['id'];
		}
		if (contentRoot.length > 0) {
			contentRoot[0].scrollTop = 0;
		}
		this.tealium.resetErrorVariables();
		taggingConfig.navigationEnd = false;
		if (!this.appTheme.onlineFlag) {
			this.translateService.get('common.messagesList.noConnectionError').subscribe((data) => {
				if (data) {
					const errorObj: Notification = new Notification();
					errorObj.bodyTitle = data.title;
					errorObj.bodyContent = data.description;
					errorObj.secondaryButtonText = data.confirmButtonText;
					errorObj.secondaryButtonClick = () => {
						if (this.appTheme.onlineFlag) {
							this.notificationService.notificationModal.hide();
						}
					};
					this.notificationService.createNotification(errorObj);
				}
			});
		}
		this.loadFrequencyFromWCS();
	}

	navigationErrorHandling(event: NavigationError): void {
		taggingConfig.navigationEnd = true;
		/** Reset this flag once navigation is done */
		this.appTheme.backButtonClicked = false;
		if (
			event?.error?.message &&
			NAVIGATION_ERROR_CANT_MATCH_ROUTE?.some((noMatchStr) => event.error.message.indexOf(noMatchStr) !== -1)
		) {
			this.deepLinkingService.deeplinkStartFlag = false;
			let url = event.url;
			if (url.split(portalHTML5.portalHTML5).length > 1) {
				url = url.split(portalHTML5.portalHTML5)[1];
			}
			if (url && url.length > 0 && !this.getRoute(url)) {
				let queryString;
				if (window.location.href.includes('?')) {
					/** Get appended query string on friendly url */
					queryString = `${window.location.href.split('?')[1]}`;
				}
				/** Get url only without appended query string
				 *  url is source link will sent relative not full path
				 */
				const urlOnly = (queryString ? url.split('?')[0] : url).slice(1);
				this.routeToValidUrl(urlOnly, queryString);
			} else {
				location.reload();
			}
		}
	}

	navigationEndHandling(event: NavigationEnd): void {
		/** Reset this flag once navigation is done */
		this.appTheme.backButtonClicked = false;
		taggingConfig.navigationEnd = true;
		this.commonService.showMenu = false;
		this.appTheme.showBackButton = true;
		this.tealium.previous_page = event.url;
		this.appTheme.previousUrl = this.appTheme.currentUrl;
		this.appTheme.currentUrl = event.url;
		this.deepLinkingService.deeplinkStartFlag = false;
		if (
			this.router.url.split('?')[0] === PagesConfig.config.login.route ||
			this.router.url.split('?')[0] === PagesConfig.config.login.otp.route ||
			this.router.url === PagesConfig.config.splash.route ||
			this.router.url.includes(PagesConfig.config.buySim.name)
		) {
			this.hideBackBtnMenuAyoda();
			/** Load app from web banner after logout */
			this.appTheme.loadBanner = true;
		} else {
			this.commonService.showMenuButton = true;
			this.configureBubbleHelp();
			if (
				this.authenticate.isAccessTokenExist() &&
				!this.authenticate.isAccessTokenExpired() &&
				this.router.url.split('?')[0] !== PagesConfig.config.login.companyChooser.route
			) {
				this.leftMenuService.initLeftMenu();
			}
		}
		this.appTheme.curentPageChangedEmitter.next();
		this.loadFrequencyFromWCS();
		setTimeout(() => {
			this.appTheme.showNewHeader && this.commonService.updateMainHeader();
		}, 200);
	}

	navigationCancelHandling(event: NavigationCancel): void {
		if (
			this.router.url.split('?')[0] === PagesConfig.config.login.route ||
			this.router.url === PagesConfig.config.splash.route
		) {
			this.hideBackBtnMenuAyoda();
		}
		taggingConfig.navigationEnd = true;
		/** Reset this flag once navigation is done */
		this.appTheme.backButtonClicked = false;
	}
}
