<div class="landing-container">
	<div class="nav-img" *ngIf="!showTickets">
		<ng-container *ngIf="!generalLoading">
			<div class="multifunctional-alert" *ngIf="fMService.serviceAvailability?.hasScheduledJob">
				<mva10-c-alert [layout]="closedticket48horasAlert.horizontal" [state]="closedticket48horasAlert.warn">
					<div id="multifunctionaldescription" [innerHTML]="multiFunctionalAlertWCSDescription"></div>
					<button id="multifunctionalbutton" (click)="openScheduledJobOverlayHandler()" class="multifunctional-btn">
						{{ multiFunctionalAlertWCSButton }} <span id="button-arrow">&#8594;</span>
					</button>
				</mva10-c-alert>
			</div>
			<div class="alert48" *ngIf="closedServices.length > 0 && !fMService.serviceAvailability?.scheduledJobTicket">
				<mva10-c-alert
					[layout]="closedticket48horasAlert.horizontal"
					[state]="closedticket48horasAlert.warn"
					[heading]="'v10.faultManagement.contentList.alertInfo.title' | translate"
				>
					<p id="alert-description">{{ 'v10.faultManagement.contentList.alertInfo.text' | translate }}</p>
				</mva10-c-alert>
			</div>
			<ng-container *ngIf="closedServices.length <= 0 && !fMService.serviceAvailability?.scheduledJobTicket">
				<picture>
					<source media="(min-width:64em)" [srcset]="'v10.faultManagement.images.header3x' | translate | imgFullPath" />
					<source
						media="(min-width:27.5em)"
						[srcset]="'v10.faultManagement.images.header2x' | translate | imgFullPath"
					/>
					<img id="banner-img" [src]="'v10.faultManagement.images.header' | translate | imgFullPath" alt="nav-img" />
				</picture>
			</ng-container>
		</ng-container>
	</div>

	<div class="landing-container__services">
		<!-- site selector -->
		<div class="ticket-listing" *ngIf="!showTickets && (userSites$ | async).length > 1">
			<p id="siteSelector-desc" class="site-seletor-desc">
				{{ 'faultManagement.itemsList.selectSiteTitle.body' | translate }}
			</p>
			<div class="site-selector-btns-container">
				<button
					class="selectors"
					id="selectors"
					[class.selected]="fMService.selectedSiteId && site.id === fMService.selectedSiteId"
					*ngFor="let site of userSites$ | async; let i = index"
					(click)="selectSite(site.id)"
				>
					<p [id]="'siteselectorbtn' + site.id" [title]="site.address.formattedAddress">
						{{ site.address.formattedAddress }}
					</p>
				</button>
			</div>
		</div>

		<ng-container *ngIf="!showTickets">
			<h3 id="myservices" class="myservices">{{ 'faultManagement.itemsList.myServices.body' | translate }}</h3>
			<h5 id="selectservice" class="selectservice">
				{{ 'faultManagement.itemsList.selectServiceSubtitle.body' | translate }}
			</h5>
		</ng-container>

		<!-- tickets view  -->
		<div class="tickets-view-loading">
			<!-- user story ciks 300  -->
			<div *ngIf="showTickets && !generalLoading && showPageHeader" class="ticket-listing">
				<h2 id="ticketsheaderTitle">
					<b id="ticketsheaderTitleBold"> {{ pageTitle }}</b>
				</h2>
				<sp-ticket-box
					id="box{{ i }}"
					[ticket]="_ticket"
					(toggleTicketDetailsBtnClicked)="toggleTicketDetails($event, i)"
					[showTicketDetail]="_ticket.showTicketDetail"
					[generalBreakDownText]="
						fMService.serviceAvailability?.massiveTicketText || fMService.serviceAvailability?.massiveTobiTicketText
					"
					[ticketIndex]="i"
					*ngFor="let _ticket of tickets; let i = index"
					(openOverlayBreakdownTicket)="openDetailedTobiMassiveOverlay()"
					(reopenCloseBtnClicked)="showConfirmbackDrop($event)"
				>
				</sp-ticket-box>
			</div>

			<div class="generalloading" *ngIf="generalLoading">
				<sp-services-loading> </sp-services-loading>
			</div>

			<section
				class="services-list"
				*ngIf="!showTickets && serviceSelectorLoaded && !generalLoading && fMService?.cardImageSelectorList"
			>
				<div class="services-list__item" *ngFor="let service of fMService.cardImageSelectorList">
					<div class="services-list__item-container">
						<p class="services-list__item-container__blue-label" [id]="service.id" *ngIf="service.blueLabel">
							{{ service.blueLabel }}
						</p>
						<div
							class="services-list__item-container__image"
							[class.bluelabel]="service.blueLabel"
							[class.disabled]="service.disabled"
							(click)="selectorCardServiceClicked(service)"
						>
							<span *ngIf="service.blueLabel" [id]="'reccircle' + service.id" class="redcircle"></span>
							<small [id]="'title' + service.id" class="services-list__item-container__image--title">
								{{ service.title }} </small
							><small [id]="'text' + service.id" class="services-list__item-container__image--text">
								{{ service.description }} </small
							><img
								class="services-list__item-container__image--icon"
								[alt]="service.id"
								[id]="service.id"
								[src]="service.image | translate | imgFullPath"
							/>
						</div>
					</div>
				</div>
			</section>
			<div class="faqs" *ngIf="!showTickets">
				<h2 id="faqs-title" class="faqs-title">{{ 'v10.faultManagement.faqsTitle' | translate }}</h2>
				<ul class="faqs-list">
					<li *ngFor="let faq of faqs; let ind = index" [id]="'faqlistitem' + ind">
						<a [id]="'faqlink' + ind" [href]="faq.link" target="_blank" class="faqs-link">{{ faq.title }}</a>
					</li>
				</ul>
			</div>
		</div>
	</div>
</div>
<!-- user story ciks 175  general break down overlay -->
<vfac-overlay
	#consumptionResponseOverlay
	class="general-breakdown-over"
	[overlayData]="headernewtorkErrorData"
	[show]="showNetworkErrorOverlay"
	[loaded]="true"
	(closed)="closeNetworkOverlay()"
	[page]="0"
>
	<div body>
		<img class="overlay-icon" [src]="networkErrorUiData.icon" alt="" />
		<div class="text-content">
			<div class="title">
				<p [innerHtml]="networkErrorUiData.title | safe : 'html'"></p>
			</div>
			<div class="description">
				<p
					[innerHtml]="
						generalTobiBreakDown && networkErrorUiData?.description?.length >= 100
							? ('v10.faultManagement.messagesList.overlay.generalBreakdownTobi.description' | translate)
							: (networkErrorUiData.description | safe : 'html')
					"
				></p>
			</div>
		</div>
		<button
			class="general-breakdown-more-info-btn"
			(click)="openDetailedTobiMassiveOverlay()"
			*ngIf="generalTobiBreakDown && networkErrorUiData?.description?.length >= 100"
		>
			{{ 'v10.faultManagement.messagesList.overlay.generalBreakdownTobi.buttonInfo_text' | translate }}
		</button>
		<div class="btn-container">
			<mva10-c-button
				[body]="networkErrorUiData.button1Text"
				[styleClass]="'mva10-c-button--primary'"
				[name]="'overlayData.primaryButton'"
				(click)="closeNetworkOverlay()"
			>
			</mva10-c-button>
		</div>
	</div>
</vfac-overlay>
<!-- user story ciks 175 end  -->

<sp-landing-overlay
	*ngIf="!fMService.serviceAvailability?.hasMassiveTicket && !fMService.serviceAvailability?.hasMassiveTobiTicket"
	[overlayWcsData]="landingOverlayUiData"
	[generalError]="showGeneralError"
	[isOngoingOrder]="isOngoingOrder"
	[closedServices]="closedServices"
	[hasScheduledJob]="fMService.serviceAvailability?.hasScheduledJob"
	[scheduledJobTicket]="fMService.serviceAvailability?.scheduledJobTicket"
	[scheduledJobTicketText]="fMService.serviceAvailability?.scheduledJobTicketText"
	[scheduledJobvalueDXL]="scheduledJobValueDXL"
	(closeOverlayEmitter)="handleOverrideBackBtn()"
	(firstBtnClick)="fullOverlayFirstBtnClick($event)"
>
</sp-landing-overlay>

<sp-landing-overlay
	*ngIf="fMService.serviceAvailability?.hasMassiveTicket || fMService.serviceAvailability?.hasMassiveTobiTicket"
	[overlayWcsData]="landingOverlayUiData"
	[hasMassiveTobiTicket]="true"
	(firstBtnClick)="closeDetailedTobiMassiveOverlay()"
	(closeOverlayEmitter)="closeDetailedTobiMassiveOverlay()"
>
</sp-landing-overlay>
<!-- user story ciks 302  -->

<!-- user story ciks 379 reopen-close ticket -->
<vfac-overlay
	#confirmOverlay
	[ngClass]="{ 'confirm-overlay': !showConfirmError, 'confirm-overlay-error': showConfirmError }"
	[overlayData]="confirmHeaderData"
	[show]="showReopenCloseOverlay"
	[loaded]="true"
	(closed)="closeReopenCloseOverlay()"
	[page]="2"
>
	<div body *ngIf="!showConfirmError">
		<div class="text-content">
			<div class="title">
				<p [innerHtml]="confirmReOpenCloseData.title | safe : 'html'"></p>
			</div>
		</div>
		<div class="btns-container">
			<div class="btn-container-confirm add-margin">
				<mva10-c-button
					[body]="confirmReOpenCloseData.button1Text"
					[styleClass]="'mva10-c-button--primary'"
					[name]="'confirmChangeStatus'"
					(click)="changeTicketStatus()"
				>
				</mva10-c-button>
			</div>
			<div class="btn-container-confirm">
				<mva10-c-button
					[body]="confirmReOpenCloseData.button2Text"
					[styleClass]="'mva10-c-button--tertiary'"
					[name]="'confirmClose'"
					(click)="closeReopenCloseOverlay()"
				>
				</mva10-c-button>
			</div>
		</div>
	</div>
	<div body *ngIf="showConfirmError">
		<img class="overlay-icon" [src]="changeTicketStatusErr.icon" alt="" />
		<div class="text-content">
			<div class="title">
				<p [innerHtml]="changeTicketStatusErr.title | safe : 'html'"></p>
			</div>
			<div class="description">
				<p [innerHtml]="changeTicketStatusErr.description | safe : 'html'"></p>
			</div>
		</div>
		<div class="btn-container-error">
			<mva10-c-button
				[body]="changeTicketStatusErr.buttonText"
				[styleClass]="'mva10-c-button--primary'"
				[name]="'closeErrorUpdateStatus'"
				(click)="closeReopenCloseOverlay()"
			>
			</mva10-c-button>
		</div>
	</div>
</vfac-overlay>
