import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Router, RouterStateSnapshot } from '@angular/router';
import { Observable } from 'rxjs';
import { StorageService } from '../../core/services/storage.service';
import { SubscriptionService } from '../../core/services/subscription.service';
import { CustomerType } from '../../shared/enums/customerType.enum';
import { UserSettingToken } from '../../shared/enums/user-setting-token.enum';
import { CompanyService } from '../../shared/services/company.service';
import { DeepLinkingService } from '../../shared/services/deep-linking.service';
import { UserSettingsService } from '../../shared/services/user-settings.service';
import { UtilitiesService } from '../../shared/utils/utilities.service';
import { config } from './../../../config/pages-config';

@Injectable()
export class FederationGuard {
	constructor(
		private router: Router,
		private userSettingsService: UserSettingsService,
		private deepLink: DeepLinkingService,
		private services: SubscriptionService,
		private storageService: StorageService,
		private utilitiesService: UtilitiesService,
		private company: CompanyService
	) {}
	canActivate(
		next: ActivatedRouteSnapshot,
		state: RouterStateSnapshot
	): Observable<boolean> | Promise<boolean> | boolean {
		if (this.userSettingsService.webToken || !this.deepLink.isDeepLink) {
			return true;
		} else if (!this.userSettingsService.webToken && this.deepLink.isDeepLink) {
			const customerType = this.storageService.userProfile.customerType.toLowerCase();
			if (customerType === CustomerType.Consumer.toLowerCase()) {
				this.services.GetDefaultServices().subscribe(() => {
					this.getToken(state);
				});
			} else {
				this.services.GetCompanyServices(this.company.selectedCompanyId).subscribe(() => {
					this.getToken(state);
				});
			}
		} else {
			this.router.navigate([config.FedError.route]);
			return false;
		}
	}

	getToken(state: RouterStateSnapshot): void {
		this.userSettingsService
			.getUserSettingsToken(
				UserSettingToken.DxlAuth,
				this.services.customerData.customerAccountsId,
				this.services.customerData.currentService.id,
				false
			)
			.subscribe(
				(res) => {
					if (res === null) {
						this.router.navigate([config.FedError.route]);
						return false;
					}
					if (state.url.indexOf('?') > -1) {
						this.router.navigate([state.url.split('?')[0]], {
							queryParams: this.utilitiesService.getRouteParams(state.url),
						});
						return true;
					}
					this.router.navigate([state.url]);
					return true;
				},
				(error) => {
					this.router.navigate([config.FedError.route]);
					return false;
				}
			);
	}
}
