import { HttpClient, HttpHeaders, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Observable, of, Subject, throwError } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { config } from '../../../config/pages-config';
import { environment } from '../../../environments/environment';
import { AppService } from '../../app.service';
import { JWTHelper } from '../../core/services/jwt.helper';
import { StorageService } from '../../core/services/storage.service';
import {
	adaraValues, AppThemes, cachingKeys,
	home5G, LOCAL_STORAGE_KEYS, secondResidences, ServiceSelector
} from '../../shared/constants/defines';
import { API_URLS } from '../../shared/constants/routes-config';
import { evict } from '../../shared/decorators/evict.decorator';
import { BundleType, RoamersBundleType } from '../../shared/enums/bundleType.enum';
import { CustomerType } from '../../shared/enums/customerType.enum';
import { ServiceType } from '../../shared/enums/serviceType.enum';
import { SiteType } from '../../shared/enums/siteType.enum';
import { ProductInventoryModel } from '../../shared/models/product-inventory.model';
import { ServicePackageModel } from '../../shared/models/service-package.model';
import { ServiceModel, ServiceResponse } from '../../shared/models/service.model';
import { Subscription, SubscriptionModel } from '../../shared/models/subscription.model';
import { UserProfile } from '../../shared/models/user-profile.model';
import { CompanyService } from '../../shared/services/company.service';

@Injectable()
export class SubscriptionService {
	postpaid = 0;
	transactional: boolean = true;
	prepaid = 0;
	memberAllDataUpdated: boolean;
	serviceSubjectEmitter: Subject<any> = new Subject<any>();
	public customerData: Subscription = new Subscription();
	public changeService: Subject<any> = new Subject<any>();
	public customerSubject: Subject<ServiceModel>;
	public serviceListSite: ServiceModel[];
	showSpinner: boolean;
	showOfferTVDashboard: boolean = false;
	hasOfferTVDashboard: boolean;
	showTVOnline: boolean;
	isCurrentServiceLoaded: Subject<boolean> = new Subject();
	public subscriptionAPICalled: boolean;
	public loadedCompaneis: string[] = [];
	public superOffer: string;
	public customerCoute;
	public customerCouteWithoutDiscount;
	public customerCouteNoTax: string;
	public customerCouteWithoutDiscountNoTax: string;
	public customerExtras: boolean;
	public customerDiscounts;
	public yuUser: boolean = false;
	public inactiveItems: number;
	private cachedServices: {
		[siteId: string]: ServiceModel[];
	} = {};

	public cachedRoamers: { [siteId: string]: { [serviceId: string]: BundleType[] } } = {};

	public smartPay: boolean;

	constructor(
		private http: HttpClient,
		private appService: AppService,
		private storageService: StorageService,
		private companyService: CompanyService,
		private jwtHelper: JWTHelper,
		private router: Router
	) {
		this.customerSubject = new Subject<ServiceModel>();
	}

	/**
	 * returns all services of the current cutomer and the first service is the
	 * default service
	 */
	GetDefaultServices(tagetMsisdn?) {
		this.customerData.services = new Array<ServiceModel>();
		let url;
		if (tagetMsisdn) {
			url = API_URLS.Subscriptions.Subscription + `?targetMsisdn=${tagetMsisdn}`;
		} else {
			url = API_URLS.Subscriptions.Subscription;
		}
		this.serviceListSite = [];
		let headers = new HttpHeaders();
		headers = headers.append('Content-Type', 'application/json');
		if (environment.stubsFlag) {
			// headers = headers.append('stubs-id', '1');
		}
		const options = {
			headers: headers,
			observe: 'response' as 'body',
		};
		return this.http.get(url, options).pipe(
			map((response: HttpResponse<any>) => {
				// jsonpath refactor: similar model for res is SubscriptionModel, but is missing some params (e.g. parts: Parts[])
				const res: any = response.body;
				const parts: string[] = response.headers.get('Authorization').split('.');
				// ensure the jwt is correct
				if (parts.length === 3) {
					this.storageService.setLocalStorage(LOCAL_STORAGE_KEYS.JWT, [response.headers.get('Authorization')]);
					this.storageService.userProfile = this.getUserProfile(response.headers.get('Authorization'));
					this.storageService.gdprUpdatedLocaly = false;
				}
				this.superOffer = res.items?.[0]?.parts?.[0]?.customerAccounts?.[0]?.superOffer;
				this.yuUser = res.items?.[0]?.parts?.[0]?.customerAccounts?.[0]?.digital;
				this.customerData.customerAccountsId = res.items?.[0]?.parts?.[0]?.customerAccounts?.[0]?.id;
				const services = this.appService.orderByType(res.items as []);
				this.tarnsformServiceData(services);
				this.customerCoute = res.subscriptionDecimalAmount;
				this.inactiveItems = res.inactiveItems;
				this.customerCouteWithoutDiscount = res.subscriptionWithoutDiscountAmount;
				this.customerCouteNoTax = res.subscriptionDecimalAmountNoTax;
				this.customerCouteWithoutDiscountNoTax = res.subscriptionWithoutDiscountAmountNoTax;
				this.customerExtras = res.extras;
				this.customerDiscounts = res.discounts;
				this.customerData.currentService = this.customerData.services[0] || null;
				this.smartPay = res.items?.[0]?.parts?.[0]?.customerAccounts?.[0]?.smartPay;
				this.customerSubject.next(this.customerData.services[0]);
				this.refreshRoamers(this.customerData.customerAccountsId);
				this.mapMbb();

				this.findUnlimited(res);
				this.appService.initGetUserInit(res);
				return res;
			}),
			catchError((error) => {
				this.serviceSubjectEmitter.next(error);
				if (!tagetMsisdn) {
					this.storageService.empty();
					this.router.navigate([config.login.route]);
				}
				return throwError(error);
			})
		);
	}
	findUnlimited(res: SubscriptionModel): void {
		res.items.forEach((item) => {
			if (item.id === this.customerData.currentService.id) {
				this.customerData.currentService.ilimitada = item.ilimitada;
			}
		});
	}

	mapMbb(): void {
		this.customerData.services.forEach((el, index) => {
			if (el.siteId === this.customerData.customerAccountsId) {
				const subscriptionMbb: ServiceModel = this.customerData.services.find(
					(item) => item.tarrifCode === secondResidences.tariffCode || item.tarrifCode === home5G.tariffCode
				);
				if (subscriptionMbb) {
					if (el.name === secondResidences.title || el.tarrifCode === home5G.tariffCode) {
						el.name = el.desc;
					}
				}
				this.serviceListSite.push(el);
			}
			if (index + 1 === this.customerData.services.length) {
				this.checkShowOfferTVDashboard();
			}
		});
	}

	getUserProfile(jwt: string): UserProfile {
		const userProfile: UserProfile = this.jwtHelper.getUserProfileFromJWT(jwt);
		this.storageService.userProfile = userProfile;
		this.storageService.setLocalStorage('username', userProfile.username);
		this.redirectToAdara(userProfile);
		return userProfile;
	}
	/**
	 * Fetch Customer Accounts to get all sites of the current customer using cif comes from company chooser
	 */
	GetCompanyServices(cif: string, isAutoSelected?: boolean) {
		if (isAutoSelected) {
			/* Clear currentService to load from new CIF */
			this.customerData.services = new Array<ServiceModel>();
			this.customerData.currentService = null;
		}
		if (!this.customerData.services) {
			this.customerData.services = new Array<ServiceModel>();
		}
		this.serviceListSite = [];
		const url = API_URLS.Subscriptions.Subscription + `?holderId=${cif}`;
		let headers = new HttpHeaders();
		headers = headers.append('accept', 'application/json');
		headers = headers.append('Content-Type', 'application/json');
		const options = {
			headers: headers,
			observe: 'response' as 'body',
		};
		return this.http.get(url, options).pipe(
			map((response: HttpResponse<SubscriptionModel>) => {
				// jsonpath refactor: similar model for res is SubscriptionModel, but is missing some params (e.g. parts: Parts[])
				const res: any = response.body;
				this.loadedCompaneis.push(cif);
				this.superOffer = res.items?.[0]?.parts?.[0]?.customerAccounts?.[0]?.superOffer;
				this.customerData.customerAccountsId = res.items?.[0]?.parts?.[0]?.customerAccounts?.[0]?.id;
				const services = this.appService.orderByType(res.items);
				this.tarnsformServiceData(services);
				this.customerCoute = res.subscriptionDecimalAmount;
				this.customerCouteWithoutDiscount = res.subscriptionWithoutDiscountAmount;
				this.customerCouteNoTax = res.subscriptionDecimalAmountNoTax;
				this.customerCouteWithoutDiscountNoTax = res.subscriptionWithoutDiscountAmountNoTax;
				this.customerExtras = res.extras;
				this.customerDiscounts = res.discounts;
				this.customerData.currentService = this.customerData.services[0] || null;
				this.inactiveItems = res.inactiveItems;
				this.customerSubject.next(this.customerData.services[0]);
				this.refreshRoamers(this.customerData.customerAccountsId);
				this.customerData.services.map((el, index) => {
					if (el.siteId === this.customerData.customerAccountsId) {
						this.serviceListSite.push(el);
					}
					if (index + 1 === this.customerData.services.length) {
						this.checkShowOfferTVDashboard();
					}
				});

				res.items.forEach((item) => {
					if (item.id === this.customerData.currentService.id) {
						this.customerData.currentService.ilimitada = item.ilimitada;
					}
				});

				this.appService.initGetUserInit(res);
				return res;
			}),
			catchError((error) => {
				this.serviceSubjectEmitter.next(error);
				return throwError(error);
			})
		);
	}
	/**
	 * Fetch Customer Accounts to get all sites of the current customer using service id
	 */
	GetCustomerServices(
		siteId: string,
		keepOldServices: boolean = false,
		changeCurrentSite: boolean = true
	): Observable<Object> {
		let url;
		if (this.companyService.selectedCompanyId) {
			url =
				API_URLS.Subscriptions.Subscription +
				`?holderId=${this.companyService.selectedCompanyId}&customerAccountId=${siteId}`;
		} else {
			url = API_URLS.Subscriptions.Subscription + `?customerAccountId=${siteId}`;
		}
		let headers = new HttpHeaders();
		headers = headers.append('Content-Type', 'application/json');
		headers = headers.append('accept', 'application/json');
		const options = {
			headers: headers,
		};
		return this.http.get(url, options).pipe(
			map((res: any) => {
				this.subscriptionAPICalled = true;
				this.customerData.customerAccountsId = res.items?.[0]?.parts?.[0]?.customerAccounts?.[0]?.id;
				const services = res.items;
				const mappedServices = this.tarnsformServiceData(services, keepOldServices, changeCurrentSite);
				this.customerCoute = res.subscriptionDecimalAmount;
				this.inactiveItems = res.inactiveItems;
				if (changeCurrentSite) {
					this.changeCurrentSite(res.items?.[0]?.parts?.[0]?.customerAccounts?.[0]?.id);
					this.superOffer = res.items?.[0]?.parts?.[0]?.customerAccounts?.[0]?.superOffer;
				}
				this.appService.initGetUserInit(res);
				this.cachedServices[siteId] = mappedServices;
				return mappedServices || res;
			})
		);
	}

	changeCurrentSite(siteId: string): void {
		this.serviceListSite = [];
		this.customerData.customerAccountsId = siteId;
		this.customerData.services.map((el, index) => {
			if (el.siteId === siteId) {
				this.serviceListSite.push(el);
			}
			if (index + 1 === this.customerData.services.length) {
				this.checkShowOfferTVDashboard();
			}
		});
		this.refreshRoamers(siteId);
	}

	getCachedServicesForSite(siteId: string): Observable<ServiceModel[]> {
		if (!this.cachedServices[siteId] || (this.cachedServices[siteId] && this.cachedServices[siteId].length === 0)) {
			let url = API_URLS.Subscriptions.Subscription + `?customerAccountId=${siteId}`;
			if (this.companyService.selectedCompanyId) {
				url += `&holderId=${this.companyService.selectedCompanyId}`;
			}
			let headers: HttpHeaders = new HttpHeaders();
			headers = headers.append('Content-Type', 'application/json');
			headers = headers.append('accept', 'application/json');
			return this.http.get(url, { headers }).pipe(
				map((res: any) => {
					const services = res.items;
					this.cachedServices[siteId] = this.tarnsformServiceData(services);
					return this.cachedServices[siteId];
				})
			);
		} else {
			return of(this.cachedServices[siteId]);
		}
	}

	/*
	Get Specific service details
	*/
	GetService(serviceId: string) {
		const url = API_URLS.Subscriptions.SubscriptionById.replace('{id}', serviceId);
		let headers = new HttpHeaders();
		headers = headers.append('Content-Type', 'application/json');
		headers = headers.append('accept', 'application/json');
		const options = {
			headers: headers,
		};
		return this.http.get(url, options).pipe(
			map((res) => {
				this.customerSubject.next(this.mapServiceData(res));
				return this.mapServiceData(res);
			})
		);
	}

	private tarnsformServiceData(data: any, keepOldServices?: boolean, changeCurrentSite: boolean = true) {
		this.postpaid = 0;
		this.prepaid = 0;
		const Services: ServiceModel[] = [];
		data.map((el) => {
			const service = this.mapServiceData(el);
			if (changeCurrentSite) {
				if (
					service.type.toLocaleLowerCase() === ServiceType.Postpaid.toLocaleLowerCase() ||
					service.type.toLocaleLowerCase() === ServiceType.MbbPostpaid.toLocaleLowerCase()
				) {
					this.postpaid++;
				} else if (
					service.type.toLocaleLowerCase() === ServiceType.Prepaid.toLocaleLowerCase() ||
					service.type.toLocaleLowerCase() === ServiceType.MbbPrepaid.toLocaleLowerCase()
				) {
					this.prepaid++;
				} else {
					this.postpaid++;
				}
			}
			service.msisdn = el.msisdn;

			if (
				(this.customerData.services &&
					!this.customerData.services.find((el) => {
						return el.id === service.id;
					})) ||
				keepOldServices
			) {
				Services.push(service);
			}
		});
		this.customerData.services = keepOldServices || Services.length === 0 ? this.customerData.services : Services;

		if (this.postpaid) {
			this.customerData.SiteType = SiteType.Postpaid;
		} else if (this.prepaid > this.postpaid && this.postpaid === 0) {
			this.customerData.SiteType = SiteType.Prepaid;
		}
		return Services;
	}
	private mapServiceData(resp: ServiceResponse): ServiceModel {
		let service: ServiceModel = new ServiceModel();
		// jsonpath refactor: params read as item['xxx'] are missing in model
		service.id = resp.id;
		service.ilimitada = resp['ilimitada'];
		service.name = resp.name;
		service.internetSpeed = resp['internetSpeed'];
		service.desc = resp.description;
		service.status = resp['status'];
		service.siteDigital = resp.parts?.[0]?.customerAccounts?.[0]?.digital;
		service.serviceDigital = resp.digital || false;
		service.type = resp.type as ServiceType;
		service.siteDigital = resp.parts?.[0]?.customerAccounts?.[0]?.digital;
		service.serviceDigital = resp.digital || false;
		service.onePlus = resp.parts?.[0]?.customerAccounts?.[0]?.onePlus;
		service.onePlusMicro = resp.parts?.[0]?.customerAccounts?.[0]?.onePlusMicro;
		service.siteId = resp.parts?.[0]?.customerAccounts?.[0]?.id;
		service.segment = resp.parts?.[0]?.customerAccounts?.[0]?.segment;
		service.siteType = resp.parts?.[0]?.customerAccounts?.[0]?.type;
		service.clientStatus = resp.parts?.[0]?.customerAccounts?.[0]?.status;
		service.overdue = resp.parts?.[0]?.customerAccounts?.[0]?.overdue;
		service.smartPay = resp.parts?.[0]?.customerAccounts?.[0]?.smartPay;
		const allPackagesTypes = Object.keys(ServiceSelector);
		const packageType: string = resp.parts?.[0]?.productOffers?.[0]?.type || '';

		if (
			resp.parts?.[0]?.productOffers?.[0] &&
			Object.keys(resp.parts?.[0]?.productOffers?.[0]).length > 0 &&
			packageType &&
			allPackagesTypes.map((pack) => pack.toLowerCase()).includes(packageType.toLowerCase())
		) {
			service.package = this.mapPackage(resp);
			if (packageType.toLowerCase() === ServiceSelector.Familia.toLowerCase()) {
				service.familia = this.mapPackage(resp);
			}
		}
		service.tarrifCode = resp.code || null;
		service.instance = resp.instance || null;

		service = this.transformNameHome5G(service);
		return service;
	}

	public transformNameHome5G(service: ServiceModel): ServiceModel {
		if (service.tarrifCode === home5G.tariffCode) {
			service.name = service.desc;
		}
		return service;
	}

	checkShowOfferTVDashboard() {
		this.hasOfferTVDashboard = false;
		this.showOfferTVDashboard = false;
		this.showTVOnline = false;
		for (const service of this.serviceListSite) {
			if (service.type.toUpperCase() === ServiceType.Tv.toUpperCase()) {
				this.hasOfferTVDashboard = false;
				break;
			} else if (
				this.storageService.userProfile?.customerType.toString().toLowerCase() !== CustomerType.Employee.toLowerCase()
			) {
				this.hasOfferTVDashboard = true;
			}
			if (service.type.toUpperCase() === ServiceType.Postpaid.toUpperCase()) {
				this.showTVOnline = true;
			}
		}
	}

	mapPackage(resp) {
		const servicePackage = new ServicePackageModel();
		servicePackage.code = resp.parts?.[0]?.productOffers?.[0]?.code || '';
		servicePackage.name = resp.parts?.[0]?.productOffers?.[0]?.name || '';
		servicePackage.type = resp.parts?.[0]?.productOffers?.[0]?.type || '';
		servicePackage.title = resp.parts?.[0]?.productOffers?.[0]?.title || '';
		servicePackage.subtitle = resp.parts?.[0]?.productOffers?.[0]?.subtitle || '';
		servicePackage.additionalLinesDesc = resp.parts?.[0]?.productOffers?.[0]?.additionalLinesDesc || '';
		servicePackage.startDate = resp.parts?.[0]?.productOffers?.[0]?.startDate || '';
		return servicePackage;
	}

	/**
	 * Recover a list with the "grifos" for a user
	 *
	 * @param siteId
	 * @param keepOldServices
	 * @param changeCurrentSite
	 *
	 * @returns Array
	 */

	GetGrifoService(
		siteId: string,
		keepOldServices: boolean = false,
		changeCurrentSite: boolean = true
	): Observable<any[]> {
		let url;

		if (this.companyService.selectedCompanyId) {
			url = API_URLS.Subscriptions.Subscription + `?holderId=${this.companyService.selectedCompanyId}`;
		} else {
			url = API_URLS.Subscriptions.Subscription + `?customerAccountId=${siteId}`;
		}
		let headers = new HttpHeaders();
		headers = headers.append('Content-Type', 'application/json');
		headers = headers.append('accept', 'application/json');
		const options = {
			headers: headers,
		};

		return this.http.get(url, options).pipe(
			map((response: any) => {
				const grifos: any[] = [];
				if (response.properties) {
					response.properties.forEach((element) => {
						grifos.push(element);
					});
				}
				return grifos;
			})
		);
	}

	/**
	 * Refresh roamers Observable while has not been cached before
	 * @param siteId: string
	 */
	private refreshRoamers(siteId: string): void {
		if (!this.cachedRoamers[siteId]) {
			this.GetProductInventory(siteId).subscribe((res: ProductInventoryModel[]) => {
				this.cachedRoamers[siteId] = {};
				res.forEach((serviceInventory) => {
					this.cachedRoamers[siteId][serviceInventory.productSerialNumber] = !serviceInventory.product
						? []
						: serviceInventory.product
							.filter((sva) => RoamersBundleType[sva.productOffering?.id?.toUpperCase()])
							.map((roamerSVA) => RoamersBundleType[roamerSVA.productOffering?.id?.toUpperCase()]);
				});
			});
		}
	}

	/**
	 * Get SVA inventory by customer account id
	 * @param siteId: string
	 * @returns Observable<ProductInventoryModel[]>
	 */
	GetProductInventory(siteId: string): Observable<ProductInventoryModel[]> {
		const url: string = API_URLS.ProductSVAInventory.replace('{siteId}', siteId);

		let headers: HttpHeaders = new HttpHeaders();
		headers = headers.append('X-VF-API-Process', 'ConsultarServicios');

		return this.http.get(url, { headers: headers }).pipe(
			map((res: ProductInventoryModel[]) => {
				return res;
			})
		);
	}
	/**
	 * Get customer account ID from Customer-Data or User-Profile
	 */
	public GetCustomerAccountsId(): string {
		let siteID: string = this.customerData?.customerAccountsId;
		if (!siteID && this.storageService.userProfile?.sites?.length > 0) {
			siteID = this.storageService.userProfile.sites[0].id;
		}
		return siteID;
	}

	public DownloadFile(url: string, options: any): Observable<any> {
		return this.http.get(url, options);
	}

	@evict(cachingKeys.Subscriptions)
	GetDefaultServicesWithoutCache(targetMsisdn: string): Observable<any> {
		return this.GetDefaultServices(targetMsisdn).pipe(map((res) => res));
	}

	private redirectToAdara(userProfile: UserProfile): void {
		if (userProfile.category === adaraValues.categoryAdara) {
			this.appService.theme = AppThemes.ThemeBkgWhite;
			this.appService.showFullAppLoader = true;
			this.router.navigate([config.adara.name], { queryParams: { origen: adaraValues.originLogin } });
		}
	}
}
