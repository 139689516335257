<vfac-overlay-full-screen [overlayFullScreenData]="overlayConfig" [show]="show" (closeBtnClicked)="closeEventF()">
	<div class="vfac-ctc-overlay__content" *ngIf="show && data">
		<p class="vfac-ctc-overlay__title" [innerHtml]="data.title"></p>
		<p class="vfac-ctc-overlay__text" [innerHtml]="data.text"></p>
		<div class="vfac-ctc-overlay__button">
			<mva10-c-button [body]="data.buttonText" [type]="'mva10-c-button--block'" (mva10OnClick)="onClickButton()">
			</mva10-c-button>
		</div>
	</div>
</vfac-overlay-full-screen>
