<div class="wallet-container-responsive">
	<div id="mainPaymentWalletTabShop" class="top-up-cards-container">
		<sp-wallet-card
			[menuItems]="actions"
			[isDotsShow]="true"
			[isPrinciple]="card.principle"
			[label]="principalTitle"
			[cardImg]="card.cardImage"
			[cardNumber]="card.cardMask"
			*ngFor="let card of cardsList; trackBy: cardIdentify"
			(menuActionClickEvent)="updateOrDelete($event, card)"
			(cardClickEvent)="selectAndBack(card)"
		>
		</sp-wallet-card>
		<sp-wallet-card
			*ngIf="!standAloneWallet"
			[isDotsShow]="false"
			[isPrinciple]="false"
			[cardImg]="anotherCreditCardWalletImage"
			[cardNumber]="anotherCreditCardWalletText"
			(click)="selectAndBack(null)"
			class="last-card"
		>
		</sp-wallet-card>
		<div class="alert" *ngIf="alertObject?.showModel">
			<mva10-c-alert [layout]="alertObject?.layout?.toString()" [state]="alertObject?.modelType?.toString()">
				<p [innerHtml]="alertObject?.message"></p>
			</mva10-c-alert>
		</div>
	</div>
</div>
