import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { SliceCurrencyPipe } from './slice-currency.pipe';

@NgModule({
	declarations: [SliceCurrencyPipe],
	exports: [SliceCurrencyPipe],
	providers: [SliceCurrencyPipe],
	imports: [CommonModule],
})
export class SliceCurrencyModule {}
