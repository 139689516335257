import { AfterViewChecked, Component, ElementRef, Input, OnInit, Renderer2, ViewChild } from '@angular/core';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';
import { Router } from '@angular/router';
import { Breakpoints } from '@mva10/mva10-angular';
import { config } from '../../../../config/pages-config';
import { ResizeService } from '../../../mva10/shared/services/resize.service';
import { TariffInfoCard } from '../../../shared/models/tariff-Info-card.model';
import { TariffChangeService } from '../services/tariff-change.service';

@Component({
	selector: 'sp-tarrif-card-info',
	templateUrl: './tarrif-card-info.component.html',
	styleUrls: ['./tarrif-card-info.component.scss'],
})
export class TarrifCardInfoComponent implements OnInit, AfterViewChecked {
	dataUsage: SafeHtml;
	@Input() infoCard: TariffInfoCard;
	@ViewChild('dataUsageRef', { static: true }) dataUsageRef: ElementRef;
	/** isMobile is to determine if the device is mobile or not */
	isMobile: boolean;
	tariffPrice: string;
	constructor(
		public sanitizer: DomSanitizer,
		private renderer: Renderer2,
		private resizeService: ResizeService,
		public tariffChangeService: TariffChangeService,
		private router: Router
	) {}
	ngAfterViewChecked(): void {
		this.setUi();
	}
	ngOnInit(): void {
		this.dataUsage = this.sanitizer.bypassSecurityTrustHtml(this.infoCard?.price);
	}
	setUi(): void {
		const selector: Element = this.dataUsageRef.nativeElement.querySelector('span');
		if (selector) {
			this.tariffPrice = selector.textContent;
			this.renderer.removeStyle(selector, 'font-size');

			this.isMobile = this.resizeService.breakpoint < Breakpoints.TABLET;

			this.renderer.setStyle(selector, 'font-weight', 'bold');

			if (this.isMobile) {
				this.renderer.setStyle(selector, 'font-size', '24px');
			} else {
				this.renderer.setStyle(selector, 'font-size', '28px');
			}
		}
	}

	navigateToTariffDetailsPage(): void {
		this.tariffChangeService.isDeeplink = false;
		this.tariffChangeService.selectedTariffCode = this.infoCard.tariffType;
		this.tariffChangeService.selectedTariffName = this.infoCard.tariffName;
		this.tariffChangeService.selectedTariffPrice = this.tariffPrice;
		this.router.navigate([config.TariffChange.TariffDetailsPage.route]);
	}
}
