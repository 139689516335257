import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MaxLengthDirective } from './../../directives/max-length.directive';
import { SPTextAreaComponent } from './sp-text-area.component';

@NgModule({
	declarations: [SPTextAreaComponent, MaxLengthDirective],
	exports: [SPTextAreaComponent, MaxLengthDirective],
	imports: [CommonModule, FormsModule, ReactiveFormsModule],
})
export class SpTextAreaModule {}
