import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { StorageService } from 'src/app/core/services/storage.service';
import { LOCAL_STORAGE_KEYS, add, idInTariffChangeRequest } from 'src/app/shared/constants/defines';
import { API_URLS } from 'src/app/shared/constants/routes-config';

@Injectable({
	providedIn: 'root',
})
export class TariffChangeDataService {
	constructor(private http: HttpClient, private storageService: StorageService) {}

	PostChangeTariffRequest(tariffCode: string, userServiceNumber: string): Observable<Object> {
		const url: string = API_URLS.TariffChange.ProductOrder;
		let headers: HttpHeaders = new HttpHeaders();
		headers = headers.append('X-VF-API-Process', 'CAMBIOPLANPRECIOSPREPAGO');
		headers = headers.append(
			'Authorization',
			'Bearer ' + this.storageService.getLocalStorage(LOCAL_STORAGE_KEYS.ACCESS_TOKEN)
		);
		const options: { [key: string]: HttpHeaders } = {
			headers: headers,
		};
		const body: { [key: string]: any } = {
			productOrderItem: [
				{
					id: idInTariffChangeRequest.replace('{0}', tariffCode).replace('{1}', userServiceNumber),
					action: add,
					productOffering: {
						id: tariffCode,
					},
					product: {
						id: tariffCode,
						productSerialNumber: userServiceNumber,
					},
				},
			],
		};
		return this.http.post(url, body, options).pipe(
			map((res) => {
				return res;
			})
		);
	}
}
