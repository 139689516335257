import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import moment from 'moment';
import { CookieService } from 'ngx-cookie';
import { BehaviorSubject, Observable, throwError } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { config } from '../../../config/pages-config';
import { StorageService } from '../../core/services/storage.service';
import { MobileToPackExpediteService } from '../../mobile-to-pack/services/mobile-to-pack-expedite.service';
import { CommercialJourneys, commercialMobile, maxNumberOperator, packageUpgrade } from '../constants/defines';
import { API_URLS } from '../constants/routes-config';
import { CustomerType } from '../enums/customerType.enum';
import { CartItems } from '../models/cartItems.model';
import { CommercialCartItem } from '../models/commercial-cart-item.model';
import { CommercialData } from '../models/commercial-data.model';
import { CommercialDetail } from '../models/commercial-detail.model';
import { CommercialOperator } from '../models/commercial-operator.model';
import { CommercialPersonalData } from '../models/commercial-personal-data.model';
import { CommercialPrice } from '../models/commercial-price.model';
import { CommercialService } from '../models/commercial-service.model';
import { CommercialShippingCosts } from '../models/commercial-shipping-costs.model';
import { CommercialShoppingCart } from '../models/commercial-shopping-cart.model';
import { DeliveryDate } from '../models/delivery-date.model';
import { DeliveryHours } from '../models/delivery-hours.model';
import { DeliveryModality } from '../models/delivery-modality.model';
import { DeliveryOffice } from '../models/delivery-office.model';
import { DeliverySelectedStore } from '../models/delivery-selected-store.model';
import { DeliveryStore, DeliveryStoreResponse } from '../models/delivery-store.model';
import { DeliveryType } from '../models/delivery-type.model';
import { DropdownsModel, OperadoresFijo, OperadoresMovil } from '../models/dropdowns.model';
import { HeadersDataOptions } from '../models/dxl-header-data-options.model';
import { InstallationDataModel } from '../models/installationData.model';
import { LegalOpTerms } from '../models/legalOneProfTerms.model';
import { LegalTermDetail } from '../models/legalTermDetail.model';
import { LegalTerms } from '../models/legalTerms.model';
import {
	LandDataInput,
	ParamsCheckoutComercial,
	ParamsLandData,
	ParamsSecondLineData,
} from '../models/params-land-data.model';
import { ParamsMobileData } from '../models/params-mobile-data.model';
import { PostTermsLegal } from '../models/postTermsLegal.model';
import { PostalAddress } from '../models/postal-address.model';
import { PromoCommercial } from '../models/promo-commercial.model';
import { StoreCoordinates } from '../models/store-coordinates.model';
import { DestinosCostesEntregaResponse } from '../models/tiposEntrega.model';
import { UtilitiesService } from '../utils/utilities.service';
import { FederationErrorService } from './federation-error.service';

@Injectable()
export class CheckoutCommercialService {
	commercialOperators?: CommercialOperator[];
	personalData: CommercialData;
	commercialshoppingCartItems: CommercialShoppingCart;
	cartItemId: string;
	postTermsLegal: PostTermsLegal;
	legalTermsArray: LegalTerms[];
	legalDetailsArray: LegalTermDetail[];
	cart: CartItems[];
	public ecodeMail: string;
	public installationCode: number;

	federror: any;

	public ntolString: string;

	ntolMtp: string;
	private installationData: InstallationDataModel;
	private installationDataSubject = new BehaviorSubject({});
	public installationData$ = this.installationDataSubject.asObservable();

	public mtpPorta: boolean;

	public selectedDeliveryOffice: DeliveryOffice;
	public priceShippinCosts: number;

	constructor(
		public http: HttpClient,
		private storageService: StorageService,
		private cookieService: CookieService,
		private router: Router,
		private fedErrorService: FederationErrorService,
		private utilitiesService: UtilitiesService,
		public mobileToPackExpediteService: MobileToPackExpediteService
	) {}

	public headersData() {
		const headers = new HttpHeaders();
		const options = {
			headers: headers,
			withCredentials: true,
		};
		return options;
	}

	public deleteReset(idClient: string, clientTypeID: string, isMTP?) {
		const isMicroResegmented: boolean = this.utilitiesService.isConsumerAndHasMicro();

		let url: string = API_URLS.Checkout.deteleReset
			.replace('{idClient}', idClient)
			.replace('{clientTypeID}', isMicroResegmented ? commercialMobile.clientTypeMicro.toString() : clientTypeID);

		if (isMTP) {
			url += `?idTypeJourney=${CommercialJourneys.mobileToPack}`;
		}
		const options = this.headersData();

		const params = {};
		return this.http.post(url, params, options).pipe(
			map((res: any) => {}),
			catchError((err) => {
				return throwError(err);
			})
		);
	}

	public getDropDowns(_isMTP: boolean): Observable<any> {
		const url = API_URLS.Checkout.getDropdowns;
		const options = this.headersData();

		return this.http.get(url, options).pipe(
			map((res: any) => {
				this.dropdownsLogic(res, _isMTP);
				return res;
			}),
			catchError((err) => {
				return throwError(err);
			})
		);
	}

	public postPersonalData(param) {
		const isMicroResegmented: boolean = this.utilitiesService.isConsumerAndHasMicro();

		const url = API_URLS.Checkout.postPersonalData;
		const params = Object.assign(param, {
			sceneType: param.sceneType,
			shopType: isMicroResegmented
				? Number(commercialMobile.shopTypeMicro)
				: this.storageService.userProfile.customerType.toLowerCase() === CustomerType.Consumer.toLowerCase()
				? Number(commercialMobile.shopType)
				: Number(commercialMobile.shopTypeMicro),
			clientType: isMicroResegmented ? commercialMobile.clientTypeMicro : param.clientType,
		});

		const options = this.headersData();

		return this.http.post(url, params, options).pipe(
			map((res: any) => {
				this.personalData = new CommercialData();
				this.personalData.completed = res.completed || false;
				this.personalData.order_id = res.order_id || null;
				if (param.email) {
					this.personalData.email = param.email;
				}
				this.ecodeMail = res.ecode;
				return res;
			}),
			catchError((err) => {
				return throwError(err);
			})
		);
	}

	public postShoppingCartItem(
		offerId?: number,
		packageId?: number,
		isMtp?: boolean,
		opUpgrade?: string,
		interactionID?: string,
		rank?: string
	): Observable<any> {
		const url = API_URLS.Checkout.postShoppingCartItem;
		let params;
		if (!isMtp && !opUpgrade) {
			params = { idPrecio: offerId, paymentType: 0 };
			if (interactionID) {
				params = { ...params, interactionID: interactionID };
			}
			if (rank) {
				params = { ...params, rank: rank };
			}
		} else if (opUpgrade) {
			params = {
				idTypeJourney: 'packToPack',
				idPrecio: offerId,
				paymentType: 1,
				oneplus: opUpgrade === packageUpgrade.linesOP ? true : false,
			};
		} else {
			params = { idPackage: packageId, idTypeJourney: CommercialJourneys.mobileToPack };
		}

		const headers = new HttpHeaders();
		const options = {
			headers: headers,
			withCredentials: true,
		};
		return this.http.post(url, params, options).pipe(
			map((res: any) => {
				this.shoppingCartLogic(res);
				return res;
			}),
			catchError((err) => {
				return throwError(err);
			})
		);
	}

	public getShoppingCartItem() {
		const url = API_URLS.Checkout.getShoppingCartItem;
		const options = this.headersData();

		return this.http.get(url, options).pipe(
			map((res: any) => {
				this.shoppingCartLogic(res);
				return res;
			}),
			catchError((err) => {
				return throwError(err);
			})
		);
	}

	public postMobileData(
		clientType: string,
		shopType: string,
		sceneType: string,
		registerType: string,
		idOperator: number,
		phoneNumber: string,
		userPersonalData: CommercialPersonalData
	): Observable<any> {
		const url = API_URLS.Checkout.postMobileData;
		let docType;
		if (
			userPersonalData.dni.charAt(-1) === 'X' ||
			userPersonalData.dni.charAt(-1) === 'Y' ||
			userPersonalData.dni.charAt(-1) === 'Z'
		) {
			docType = 2;
		} else {
			docType = 1;
		}
		let params;
		// Body params
		if (!userPersonalData.change) {
			params = {
				clientType: clientType,
				mobileData: [
					{
						lineHolder: true,
						operator: idOperator,
						phoneNumber: phoneNumber,
						registerType: registerType,
						email: userPersonalData.email,
					},
				],
				sceneType: sceneType,
				shopType: shopType,
			};
		} else {
			params = {
				clientType: clientType,
				mobileData: [
					{
						docNumber: userPersonalData.dni,
						docType: docType,
						lineHolder: false,
						name: userPersonalData.firstName,
						operator: idOperator,
						phoneNumber: phoneNumber,
						registerType: registerType,
						surname1: userPersonalData.middleName,
						surname2: userPersonalData.familyName,
						email: userPersonalData.email,
					},
				],
				sceneType: sceneType,
				shopType: shopType,
			};
		}
		this.personalData.email = userPersonalData.email;
		this.personalData.phoneNumber = phoneNumber;

		const options = this.headersData();

		return this.http.post(url, params, options).pipe(
			map((res: any) => {
				return res;
			}),
			catchError((err) => {
				return throwError(err);
			})
		);
	}

	public getDeliveryTypes() {
		const url = API_URLS.Checkout.getDeliveryTypes;

		const options = this.headersData();

		return this.http.get(url, options).pipe(
			map((res: DestinosCostesEntregaResponse) => {
				return this.deliveryTypesLogic(res);
			}),
			catchError((err) => {
				return throwError(err);
			})
		);
	}

	public getPostalAddress() {
		const url = API_URLS.Checkout.getPostalAddress;

		const options = this.headersData();

		return this.http.get(url, options).pipe(
			map((res: any) => {
				return this.postalAddressLogic(res);
			}),
			catchError((err) => {
				return throwError(err);
			})
		);
	}

	public getDeliveryDates(address) {
		const url = API_URLS.Checkout.getDeliveryDates;

		// Body params
		const params = {
			cpDireccionEntrega: address.cp,
			idTipoEntrega: address.idType,
			cdTipoDeliveryType: address.cdType,
		};

		const options = this.headersData();

		return this.http.post(url, params, options).pipe(
			map((res: any) => {
				return this.deliveryDatesLogic(res);
			}),
			catchError((err) => {
				return throwError(err);
			})
		);
	}

	public postDeliveryData(
		itPortabilidad,
		clientType,
		shopType,
		sceneType,
		address: PostalAddress,
		deliveryInfo: DeliveryModality,
		horasEntrega: DeliveryHours,
		deliveryDate: DeliveryDate,
		selectedStore: DeliverySelectedStore,
		selectedOffice?: DeliveryOffice
	) {
		const isMicroResegmented: boolean = this.utilitiesService.isConsumerAndHasMicro();

		const url = API_URLS.Checkout.postDeliveryData;

		let params = {
			flagTieneError: false,
			itPortabilidad: Number(itPortabilidad),
			clientType: isMicroResegmented ? commercialMobile.clientTypeMicro : Number(clientType),
			shopType: isMicroResegmented ? commercialMobile.shopTypeMicro : Number(shopType),
			sceneType: Number(sceneType),
			address: {
				door: address.door,
				floor: address.floor,
				name: address.name,
				number: address.addressNumber,
				postcode: address.zipCode,
				province: Number(address.province),
				town: address.town,
				type: address.type,
				verticalId: address.verticalId,
				longitude: address.longitud,
				latitude: address.latitud,
			},
			cdTipoProceso: deliveryInfo.cdTipoProceso,
			xTipoProceso: deliveryInfo.dsTipoProceso,
			xCanal: deliveryInfo.xCanal,
			xPerfil: deliveryInfo.xPerfil,
			xTipoCliente: deliveryInfo.xTipoCliente,
			idModalidadEntrega: horasEntrega.idModalidadEntrega,
			idTipoEntrega: horasEntrega.idTipoEntrega,
			cdTipoDeliveryType: horasEntrega.cdTipoDeliveryType,
			xOpcEntrega: horasEntrega.xOpcEntrega,
			nombreModalidadEntrega: horasEntrega.nombreModalidadEntrega,
			dsHoraEntrega: horasEntrega.dsHoraEntrega,
			dsOpcEntrega: horasEntrega.dsOpcEntrega,
			xIdentificacion: horasEntrega.xIdentificacion,
			xPrioridadEntrega: horasEntrega.xPrioridadEntrega,
			xCosteOpcEntrega: {
				eurPrecio: horasEntrega.eurPrecio,
				eurPrecioPromocion: horasEntrega.eurPrecioPromocion,
				eurPrecioTaxes: horasEntrega.eurPrecioTaxes,
				eurPrecioPromocionTaxes: horasEntrega.eurPrecioPromocionTaxes,
				cdCatalogOfferCharge: horasEntrega.cdCatalogOfferCharge,
				dsCatalogOfferChargeName: horasEntrega.dsCatalogOfferChargeName,
				euCatalogOfferCharge: horasEntrega.euCatalogOfferCharge,
			},
			xDestEntrega: horasEntrega.cdTipoDeliveryType,
			xCosteEur: horasEntrega.eurPrecioTaxes,
			xCostePromoEur: horasEntrega.eurPrecioPromocionTaxes,
			xDsOpcEntrega: horasEntrega.dsOpcEntrega,
			eurPrecio: horasEntrega.eurPrecio,
			eurPrecioPromocion: horasEntrega.eurPrecioPromocion,
			eurPrecioTaxes: horasEntrega.eurPrecioTaxes,
			eurPrecioPromocionTaxes: horasEntrega.eurPrecioPromocionTaxes,
			cdCatalogOfferCharge: horasEntrega.cdCatalogOfferCharge,
			dsCatalogOfferChargeName: horasEntrega.dsCatalogOfferChargeName,
			euCatalogOfferCharge: horasEntrega.euCatalogOfferCharge,
			fcEntregaPedido: deliveryDate?.fechaEntregaPedido,
			fechaPortabilidad: deliveryDate?.fechaPortabilidad,
			xDiaEntrega: deliveryDate?.xDiaEntrega,
			xHoraLiberacion: deliveryDate?.xHoraLiberacion,
			xSlaFraude: deliveryDate?.xSlaFraude,
			fcPortabilidad: deliveryDate?.fechaPortabilidad,
		};

		if (selectedStore) {
			params = Object.assign(params, {
				tiendaSfid: selectedStore.tiendaSfid,
				tiendaNombre: selectedStore.tiendaNombre,
				tiendaProvincia: selectedStore.tiendaProvincia,
				tiendaCdAlmacen: selectedStore.tiendaCdAlmacen,
				tiendaTelefono: selectedStore.tiendaTelefono,
			});
		}

		if (selectedOffice) {
			params = Object.assign(params, {
				correosId: selectedOffice.id,
				correosCodired: selectedOffice.codired,
			});
			this.selectedDeliveryOffice = selectedOffice;
		}

		const options = this.headersData();

		return this.http.post(url, params, options).pipe(
			map((res: any) => {
				return res;
			}),
			catchError((err) => {
				return throwError(err);
			})
		);
	}

	saveParamsMtp(params?: InstallationDataModel) {
		this.installationData = params;
		this.installationDataSubject.next(this.installationData);
	}

	public postSaveCitaOrig(fechaCita: string, franjaHorariaCita: string) {
		const url = API_URLS.Checkout.postSaveCitaOrig;
		const options = this.headersData();

		const params = {
			fechaCita: fechaCita,
			franjaHorariaCita: franjaHorariaCita,
			flagAgendarCita: true,
		};

		return this.http.post(url, params, options).pipe(
			map((res: any) => {
				return res;
			})
		);
	}

	public getCoordinates(zipCode) {
		const url = API_URLS.Checkout.getCoordinates.replace('{zipCode}', zipCode);
		const options = this.headersData();

		return this.http.get(url, options).pipe(
			map((res: StoreCoordinates) => {
				return this.getCoordinatesLogic(res);
			}),
			catchError((err) => {
				return throwError(err);
			})
		);
	}

	public getTiendasEntrega(province, latitude, longitude, shopType) {
		const url = API_URLS.Checkout.getTiendasEntrega
			.replace('{province}', province)
			.replace('{latitude}', latitude)
			.replace('{longitude}', longitude)
			.replace('{shopType}', shopType)
			.replace('{checkStock}', 'false');
		const options = this.headersData();
		return this.http.get(url, options).pipe(
			map((res: DeliveryStoreResponse) => {
				return this.getTiendasEntregaLogic(res);
			}),
			catchError((err) => {
				return throwError(err);
			})
		);
	}

	public deliveryTypesLogic(response: DestinosCostesEntregaResponse): DeliveryType[] {
		const resTypes = response.destinosCostesEntrega || [];
		return resTypes.map((type) => {
			const deliveryType = new DeliveryType();
			deliveryType.idType = type.idTipoEntrega || null;
			deliveryType.cdType = type.cdTipoDeliveryType || null;
			deliveryType.price = type.xCoste?.eurPrecio || 0;
			deliveryType.priceTax = type.xCoste?.eurPrecioTaxes || 0;
			deliveryType.itMarcadoDefault = type.itMarcadoDefault || false;
			deliveryType.nmOrden = type.nmOrden || 0;
			deliveryType.title = type.nombrePubDestEntrega || null;
			return deliveryType;
		});
	}

	private postalAddressLogic(response: PostalAddress): PostalAddress {
		const postalAddress = new PostalAddress();
		postalAddress.addressNumber = response.addressNumber || null;
		postalAddress.door = response.door || null;
		postalAddress.floor = response.floor || null;
		postalAddress.name = response.name || null;
		postalAddress.province = response.province || null;
		postalAddress.town = response.town || null;
		postalAddress.type = response.type || null;
		postalAddress.verticalId = response.verticalId || null;
		postalAddress.zipCode = response.zipCode || null;
		postalAddress.stairway = response.stairway || null;
		return postalAddress;
	}

	private deliveryDatesLogic(response: any): DeliveryModality {
		// jsonpath refactor: added 'any' type due to missing model - Seems to be similar to ModalidadesEntregaResponse
		const deliveryModality = new DeliveryModality();
		deliveryModality.cdTipoProceso = response.xTipoProceso.cdTipoProceso || null;
		deliveryModality.dsTipoProceso = response.xTipoProceso.dsTipoProceso || null;

		deliveryModality.xCanal = response.xCanal || null;
		deliveryModality.xPerfil = response.xPerfil || null;
		deliveryModality.xTipoCliente = response.xTipoCliente || null;

		const responseDates = response.fechasEntrega || null;
		deliveryModality.fechasEntrega = responseDates.map((date) => {
			const deliveryDate = new DeliveryDate();
			deliveryDate.fechaEntregaPedido = date.fechaEntregaPedido || null;
			const portabilityDates = date.fechasPortabilidad || null;
			deliveryDate.fechaEntregaPedido = deliveryDate.fechaEntregaPedido
				? moment(deliveryDate.fechaEntregaPedido).toDate()
				: null;

			if (portabilityDates?.[0]) {
				deliveryDate.fechaPortabilidad = moment(portabilityDates[0].fechaPortabilidad).toDate() || null;
				deliveryDate.xDiaEntrega = portabilityDates[0].xDiaEntrega || null;
				deliveryDate.xHoraLiberacion = portabilityDates[0].xHoraLiberacion || null;
				deliveryDate.xSlaFraude = portabilityDates[0].xSlaFraude || null;
			}

			return deliveryDate;
		});

		const responseHours = response.horasEntrega || null;
		deliveryModality.horasEntrega = responseHours.map((data) => {
			const deliveryHour = new DeliveryHours();
			deliveryHour.cdTipoDeliveryType = data.cdTipoDeliveryType || null;
			deliveryHour.dsHoraEntrega = data.dsHoraEntrega || null;
			deliveryHour.dsOpcEntrega = data.dsOpcEntrega || null;
			deliveryHour.idTipoEntrega = data.idTipoEntrega || null;
			deliveryHour.nombreModalidadEntrega = data.nombreModalidadEntrega || null;
			deliveryHour.cdCatalogOfferCharge = data.xCosteOpcEntrega?.cdCatalogOfferCharge || null;
			deliveryHour.dsCatalogOfferChargeName = data.xCosteOpcEntrega?.dsCatalogOfferChargeName || null;
			deliveryHour.euCatalogOfferCharge = data.xCosteOpcEntrega?.euCatalogOfferCharge || 0;
			deliveryHour.eurPrecio = data.xCosteOpcEntrega?.eurPrecio || 0;
			deliveryHour.eurPrecioPromocion = data.xCosteOpcEntrega?.eurPrecioPromocion || 0;
			deliveryHour.eurPrecioPromocionTaxes = data.xCosteOpcEntrega?.eurPrecioPromocionTaxes || 0;
			deliveryHour.eurPrecioTaxes = data.xCosteOpcEntrega?.eurPrecioTaxes || 0;
			deliveryHour.idModalidadEntrega = data.idModalidadEntrega || null;
			deliveryHour.xIdentificacion = data.xIdentificacion || null;
			deliveryHour.xOpcEntrega = data.xOpcEntrega || null;
			deliveryHour.xPrioridadEntrega = data.xPrioridadEntrega || null;
			return deliveryHour;
		});

		return deliveryModality;
	}

	getCoordinatesLogic(response: StoreCoordinates): StoreCoordinates {
		const coords = new StoreCoordinates();
		coords.city = response.city || null;
		coords.country = response.country || null;
		coords.latitude = response.latitude || 0;
		coords.longitude = response.longitude || 0;
		coords.province = response.province || null;
		coords.region = response.region || null;
		return coords;
	}

	getTiendasEntregaLogic(response: DeliveryStoreResponse): DeliveryStore[] {
		const tiendas = response.tiendas || null;
		return tiendas.map((store) => {
			const deliveryStore = new DeliveryStore();
			deliveryStore.diaFin = store.diaFin || null;
			deliveryStore.diaInicio = store.diaInicio || null;
			deliveryStore.distanciaPuntoRef = store.distanciaPuntoRef || null;
			deliveryStore.horaFin = store.horaFin || null;
			deliveryStore.horaFinJornadaPartida = store.horaFinJornadaPartida || null;
			deliveryStore.horaInicio = store.horaInicio || null;
			deliveryStore.horaInicioJornadaPartida = store.horaInicioJornadaPartida || null;
			deliveryStore.nombreTienda = store.nombreTienda || null;
			deliveryStore.sfid = store.sfid || null;
			deliveryStore.codigoPostal = store.codigoPostal || null;
			deliveryStore.latitud = store.latitud || 0;
			deliveryStore.longitud = store.longitud || 0;
			deliveryStore.localidad = store.localidad || null;
			deliveryStore.cdAlmacen = store.cdAlmacen || null;
			deliveryStore.provincia = store.provincia || null;
			deliveryStore.telefono = store.telefono || null;
			return deliveryStore;
		});
	}

	postLegalTerms(id) {
		const url = API_URLS.Checkout.postLegalTerms;
		const headers = new HttpHeaders();
		const options = {
			headers: headers,
			responseType: 'text' as 'json',
			withCredentials: true,
		};
		const params = {
			listAceptos: [
				{
					id: id,
					checked: true,
				},
			],
		};
		return this.http.post(url, params, options).pipe(
			map((res: any) => {
				return res;
			}),
			catchError((err) => {
				return throwError(err);
			})
		);
	}

	postLegalTermsM2P(legalList) {
		const url = API_URLS.Checkout.postLegalTerms;
		const headers = new HttpHeaders();
		const options = {
			headers: headers,
			responseType: 'text' as 'json',
			withCredentials: true,
		};
		const legalChecksList = [];
		legalList.forEach((res) => {
			const plantilla = { id: res, checked: true };
			legalChecksList.push(plantilla);
		});
		const params = {
			legalChecksList,
		};
		return this.http.post(url, params, options).pipe(
			map((res: any) => {
				return res;
			}),
			catchError((err) => {
				return throwError(err);
			})
		);
	}

	getLegalterms(clientType, shopType, isMtp?) {
		const isMicroResegmented: boolean = this.utilitiesService.isConsumerAndHasMicro();

		const url: string = API_URLS.Checkout.getLegalTerms
			.replace('{clientType}', isMicroResegmented ? commercialMobile.clientTypeMicro.toString() : clientType)
			.replace('{shopType}', isMicroResegmented ? commercialMobile.shopTypeMicro : shopType)
			.replace('{page}', isMtp ? CommercialJourneys.pageMtp : CommercialJourneys.page);

		const options = this.headersData();

		return this.http.get(url, options).pipe(
			map((res: LegalOpTerms) => {
				this.legalTermsLogic(res);
				return res;
			}),
			catchError((err) => {
				return throwError(err);
			})
		);
	}

	postLegalTermsSave(NTOL?: string): Observable<{ email: string; idIkkiRequest: string }> {
		const url: string = API_URLS.Checkout.postLegalTermsSave;
		const headers: HttpHeaders = new HttpHeaders();
		const options: { headers: HttpHeaders; NTOL_TXID: string; withCredentials: boolean } = {
			headers: headers,
			NTOL_TXID: NTOL,
			withCredentials: true,
		};
		const params = {};
		return this.http.post(url, params, options).pipe(
			map((res: { email: string; idIkkiRequest: string }) => {
				this.personalData = new CommercialData();
				this.personalData.termsId = res.idIkkiRequest;
				this.personalData.email = res.email;
				return res;
			}),
			catchError((err) => {
				return throwError(err);
			})
		);
	}

	public callLineData(
		clientype: string,
		selectedPorta: boolean,
		registerTypeMTP: number,
		sceneType: number,
		shopType: string,
		email: string,
		number: string,
		operator?: number,
		linePhone?: string,
		dataUserLine?: CommercialPersonalData,
		_dataUserLineMobile?: LandDataInput
	): Observable<any> {
		const isMicroResegmented: boolean = this.utilitiesService.isConsumerAndHasMicro();

		this.cookieService.put(
			'clientTypeID',
			isMicroResegmented ? commercialMobile.clientTypeMicro.toString() : clientype
		);
		const headers: HttpHeaders = new HttpHeaders();
		const options: HeadersDataOptions = {
			headers: headers,
			withCredentials: true,
		};
		const url: string = API_URLS.Checkout.getLineData;
		const docType: number =
			dataUserLine.dni.charAt(-1) === 'X' || dataUserLine.dni.charAt(-1) === 'Y' || dataUserLine.dni.charAt(-1) === 'Z'
				? 2
				: 1;
		let paramsLandData: ParamsLandData, paramsMobileData: ParamsMobileData;
		paramsLandData = this.setParamsLandData(
			clientype,
			selectedPorta,
			registerTypeMTP,
			sceneType,
			shopType,
			email,
			number,
			docType,
			operator,
			linePhone,
			dataUserLine
		);
		paramsMobileData = {
			mobileData: [
				{
					cartItemID: 0,
					email: email,
					lineHolder: true,
					phoneNumber: number,
					registerType: 5,
				},
			],
			clienteType: Number(isMicroResegmented ? commercialMobile.clientTypeMicro.toString() : clientype),
			sceneType: sceneType,
			shopType: isMicroResegmented ? commercialMobile.shopTypeMicro : shopType,
		};
		const mobileData: Array<LandDataInput> = [];
		if (_dataUserLineMobile) {
			let paramsSecondLineData: ParamsSecondLineData;
			if (_dataUserLineMobile.registerType === 2) {
				// Is PortabilityMobileSelected
				paramsSecondLineData = {
					mobileData: [
						{
							docNumber: _dataUserLineMobile.docNumber,
							docType: _dataUserLineMobile.docType,
							name: _dataUserLineMobile.name,
							surname1: _dataUserLineMobile.surname1,
							surname2: _dataUserLineMobile.surname2,
							operator: _dataUserLineMobile.operator,
							phoneNumber: _dataUserLineMobile.phoneNumber,
						},
					],
				};
			} else {
				paramsSecondLineData = {
					mobileData: [
						{
							email: _dataUserLineMobile.email,
						},
					],
				};
			}
			paramsSecondLineData.mobileData[0].cartItemID = 0;
			paramsSecondLineData.mobileData[0].registerType = _dataUserLineMobile.registerType;
			paramsSecondLineData.mobileData[0].lineHolder = _dataUserLineMobile.lineHolder;
			mobileData.push(paramsSecondLineData.mobileData[0]);
		}
		const params: ParamsCheckoutComercial = { ...paramsLandData, paramsMobileData, mobileData };
		return this.http.post(url, params, options).pipe(
			catchError((err) => {
				return throwError(err);
			})
		);
	}

	public setParamsLandData(
		clientype: string,
		selectedPorta: boolean,
		registerTypeMTP: number,
		sceneType: number,
		shopType: string,
		email: string,
		number: string,
		docType: number,
		operator?: number,
		linePhone?: string,
		dataUserLine?: CommercialPersonalData
	): ParamsLandData {
		let paramsLandData: ParamsLandData;
		if (registerTypeMTP === 2) {
			// Is PortabilityLandSelected
			paramsLandData = {
				landData: [
					{
						cartItemID: 0,
						email: email,
						lineHolder: !dataUserLine.change,
						registerType: registerTypeMTP,
						operator: operator,
						phoneNumber: linePhone,
						name: dataUserLine.firstName,
						surname1: dataUserLine.middleName,
						surname2: dataUserLine.familyName,
						docNumber: dataUserLine.dni,
						docType: docType,
					},
				],
			};
		} else {
			paramsLandData = {
				landData: [
					{
						cartItemID: 0,
						email: email,
						lineHolder: selectedPorta,
						registerType: registerTypeMTP,
					},
				],
			};
			if (operator !== maxNumberOperator) {
				paramsLandData.landData[0].operator = operator;
			}
			if (linePhone !== '') {
				paramsLandData.landData[0].phoneNumber = linePhone;
			}
		}
		if (this.mobileToPackExpediteService.iua) {
			paramsLandData.landData[0].iua = this.mobileToPackExpediteService.iua;
		}

		return paramsLandData;
	}

	public getContainBoxless() {
		const url = API_URLS.Checkout.containBoxless;
		const headers = new HttpHeaders();
		const options = {
			headers: headers,
			withCredentials: true,
		};
		return this.http.get(url, options).pipe(
			map((res) => {
				return res;
			})
		);
	}

	public postEmailBoxless(mailBoxless) {
		const url = API_URLS.Checkout.updateMailBoxless.replace('{mailBoxless}', mailBoxless);
		const headers = new HttpHeaders();
		const options = {
			headers: headers,
			withCredentials: true,
		};
		const params = {
			mailBoxless: mailBoxless,
		};
		return this.http.post(url, params, options).pipe(map(() => {}));
	}

	public fedError(error) {
		this.federror = error;
		this.fedErrorService.fedError = this.fedError;
		this.router.navigate([config.FedError.route]);
	}

	getCheckOrderCorrepondAppointment() {
		const url =
			API_URLS.Checkout.comprobarPedidoIkkiCorrespondeCita +
			CommercialJourneys.offersRequest.M2P.checkout.flagCheckItemsCart;
		const headers = new HttpHeaders();
		const options = {
			headers: headers,
			withCredentials: true,
		};
		return this.http.get(url, options).pipe(
			map((res: any) => {
				return res;
			}),
			catchError((err) => {
				return throwError(err);
			})
		);
	}

	getServiceGetAvailabilityAppointment() {
		const url = API_URLS.Checkout.getDisponibilidadCita + CommercialJourneys.offersRequest.M2P.checkout.requestActive;
		const headers = new HttpHeaders();
		const options = {
			headers: headers,
			withCredentials: true,
		};
		return this.http.get(url, options).pipe(
			map((res: any) => {
				return res;
			})
		);
	}

	SetSave() {
		const url = API_URLS.Checkout.postLegalTermsSave + CommercialJourneys.offersRequest.M2P.checkout.flagCheckItemsCart;
		const headers = new HttpHeaders();
		const options = {
			headers: headers,
			withCredentials: true,
		};
		return this.http.get(url, options).pipe(
			map((res: any) => {
				return res;
			}),
			catchError((err) => {
				return throwError(err);
			})
		);
	}

	legalTermsLogic(arrayLegal: LegalOpTerms) {
		this.legalTermsArray = new Array<LegalTerms>();
		const listAceptos = arrayLegal.listAceptos || null;
		listAceptos.map((op) => {
			const legalBasics = new LegalTerms();

			legalBasics.checked = op.checked || false;
			legalBasics.id = op.id || null;
			legalBasics.internalDescription = op.internalDescription || null;
			legalBasics.orden = op.orden || null;

			legalBasics.legalTermDetail = new Array<LegalTermDetail>();
			const legalDetail = op.legalTermDetails || null;
			legalDetail.map((ap) => {
				const legalDetailes = new LegalTermDetail();
				legalDetailes.htmlText = ap.htmlText || null;
				legalDetailes.linkShortText = ap.linkShortText || null;
				legalDetailes.shortText = ap.shortText || null;

				legalBasics.legalTermDetail.push(legalDetailes);
				return legalDetailes;
			});
			this.legalTermsArray.push(legalBasics);
			return legalBasics;
		});
	}

	public dropdownsLogic(operator: DropdownsModel, _isMTP: boolean): void {
		this.commercialOperators = new Array<CommercialOperator>();

		// jsonpath refactor: changed due to incompatible type
		const oper: (OperadoresFijo | OperadoresMovil)[] =
			(_isMTP ? operator.operadoresFijo : operator.operadoresMovil) || [];

		oper.map((op) => {
			const operatorCommercial = new CommercialOperator();
			operatorCommercial.id = op.id || null;
			operatorCommercial.name = op.name || null;
			operatorCommercial.objId = op.objId || null;
			operatorCommercial.visible = op.visible || false;
			operatorCommercial.xDesCortaOper = op.xDesCortaOper || null;
			operatorCommercial.xNomIdentificacion = (op as OperadoresMovil).xNomIdentificacion || null;
			operatorCommercial.xNomOperadora = (op as OperadoresMovil).xNomOperadora || null;
			operatorCommercial.xNumIdentificacion = (op as OperadoresMovil).xNumIdentificacion || null;
			this.commercialOperators.push(operatorCommercial);
			return operatorCommercial;
		});
	}

	private shoppingCartLogic(items: CommercialShoppingCart) {
		const shoppingCart = new CommercialShoppingCart();
		shoppingCart.deleteItems = items.deleteItems || false;
		shoppingCart.overMaxInternetLines = items.overMaxInternetLines || false;
		shoppingCart.overMaxLines = items.overMaxLines || false;
		shoppingCart.remainingInternetLines = items.remainingInternetLines || null;
		shoppingCart.remainingMobileLines = items.remainingMobileLines || null;
		shoppingCart.taxType = items.taxType || null;
		shoppingCart.taxValue = items.taxValue || null;
		shoppingCart.siteId = items.siteId || null;
		if (items.price) {
			shoppingCart.price = this.getPrices(shoppingCart.price, items);
		}
		const itemsCart = new Array<CommercialCartItem>();
		const item = items.cartItems || null;
		item.map((it) => {
			const itemCart = new CommercialCartItem();
			itemCart.cartItemId = it.cartItemId || null;
			this.cartItemId = itemCart.cartItemId;
			itemCart.price = it.price || null;
			if (itemCart.price) {
				itemCart.price = this.getPrices(itemCart.price, it);
			}
			const services = new Array<CommercialService>();
			const service = it.services || null;
			service.map((sv) => {
				const serv = new CommercialService();
				serv.catalogElementType = sv.catalogElementType || null;
				serv.code = sv.code || null;
				serv.itemId = sv.itemId || null;
				serv.tax = sv.tax || 0;
				serv.price = sv.price || null;
				if (serv.price) {
					serv.price = this.getPrices(serv.price, sv);
				}

				serv.detail = sv.detail || null;
				if (serv.detail) {
					serv.detail = new CommercialDetail();
					serv.detail.additionalLine = sv.detail?.additionalLine || false;
					serv.detail.agentOffer = sv.detail?.agentOffer || false;
					serv.detail.catalogOffer = sv.detail?.catalogOffer || null;
					serv.detail.clientType = sv.detail?.clientType || 0;
					serv.detail.contractType = sv.detail?.contractType || 0;
					serv.detail.coverageTechnology = sv.detail?.coverageTechnology || null;
					serv.detail.crossedOfferId = sv.detail?.crossedOfferId || null;
					serv.detail.description = sv.detail?.description || null;
					serv.detail.idesc = sv.detail?.idesc || null;
					serv.detail.manualProvision = sv.detail?.manualProvision || false;
					serv.detail.name = sv.detail?.name || null;
					serv.detail.nameList = sv.detail?.nameList || null;
					serv.detail.numberLine = sv.detail?.numberLine || 0;
					serv.detail.offerId = sv.detail?.offerId || 0;
					serv.detail.os = sv.detail?.os || null;
					serv.detail.permanenceDuration = sv.detail?.permanenceDuration || null;
					serv.detail.plusLine = sv.detail?.plusLine || false;
					serv.detail.rateType = sv.detail?.rateType || 0;
					serv.detail.rateTypeMapeo = sv.detail?.rateTypeMapeo || 0;
					serv.detail.registerType = sv.detail?.registerType || 0;
					serv.detail.registerTypeName = sv.detail?.sap || null;
					serv.detail.sap = sv.detail?.sap || null;
					serv.detail.sceneType = sv.detail?.sceneType || 0;
					serv.detail.serviceItemType = sv.detail?.serviceItemType || null;
					serv.detail.serviceItemTypeName = sv.detail?.serviceItemTypeName || null;
					serv.detail.shopType = sv.detail?.shopType || 0;
					serv.detail.symmetricSpeed = sv.detail?.symmetricSpeed || false;
					serv.detail.promotions = sv.detail?.promotions || null;
					if (serv.detail.promotions) {
						const promotions = new Array<PromoCommercial>();
						// jsonpath refactor: changed default value to []
						const promotion = sv.detail?.promotions || [];
						promotion.map((prom) => {
							const promo = new PromoCommercial();
							promo.id = prom.id || null;
							promo.applyTo = prom.applyTo || null;
							promo.discountAmount = prom.discountAmount || null;
							promo.discountAmountTaxes = prom.discountAmountTaxes || null;
							promo.discountClassType = prom.discountClassType || null;
							promo.discountDuration = prom.discountDuration || null;
							promo.discountType = prom.discountType || null;
							promo.evaluationOrder = prom.evaluationOrder || null;
							// jsonpath refactor: changed default values to string
							promo.flagIgnoreCRM = prom.flagIgnoreCRM || 'false';
							promo.flagRegional = prom.flagRegional || 'false';
							promo.headbandOpenText = prom.headbandOpenText || null;
							promo.internalName = prom.internalName || null;
							promo.linkedToCatalog = prom.linkedToCatalog || null;
							promo.longText = prom.longText || null;
							promo.publicName = prom.publicName || null;
							promo.detailTextList = prom.detailTextList || null;
							promo.urlMobilePromotionImage = prom.urlMobilePromotionImage || null;
							promo.urlMobilePromotionImageFicha = prom.urlMobilePromotionImageFicha || null;
							promotions.push(promo);
						});
						serv.detail.promotions = promotions;
					}
				}
				services.push(serv);
			});
			itemCart.services = services;
			itemsCart.push(itemCart);
		});
		const shippingCosts = items.shippingCosts || null;
		if (shippingCosts) {
			shoppingCart.shippingCosts = this.getShippingCosts(shoppingCart.shippingCosts, items);
		}
		shoppingCart.cartItems = itemsCart;
		this.commercialshoppingCartItems = shoppingCart;
		return this.commercialshoppingCartItems;
	}

	private getPrices(
		itemToStore: CommercialPrice,
		itemToGet: CommercialShoppingCart | CommercialCartItem | CommercialService
	) {
		itemToStore = new CommercialPrice();
		itemToStore.devicesUpfront = itemToGet.price?.devicesUpfront || 0;
		itemToStore.devicesUpfront = itemToGet.price?.devicesUpfront || 0;
		itemToStore.devicesUpfrontPromo = itemToGet.price?.devicesUpfrontPromo || 0;
		itemToStore.devicesUpfrontTaxes = itemToGet.price?.devicesUpfrontTaxes || 0;
		itemToStore.devicesUpfrontTaxesPromo = itemToGet.price?.devicesUpfrontTaxesPromo || 0;
		itemToStore.installment = itemToGet.price?.installment || 0;
		itemToStore.installmentPromo = itemToGet.price?.installmentPromo || 0;
		itemToStore.installmentPromoTaxes = itemToGet.price?.installmentPromoTaxes || 0;
		itemToStore.installmentTaxes = itemToGet.price?.installmentTaxes || 0;
		itemToStore.singlePayment = itemToGet.price?.singlePayment || 0;
		itemToStore.singlePaymentPromo = itemToGet.price?.singlePaymentPromo || 0;
		itemToStore.singlePaymentTaxes = itemToGet.price?.singlePaymentTaxes || 0;
		itemToStore.singlePaymentTaxesPromo = itemToGet.price?.singlePaymentTaxesPromo || 0;
		itemToStore.upfront = itemToGet.price?.upfront || 0;
		itemToStore.upfrontPromo = itemToGet.price?.upfrontPromo || 0;
		itemToStore.upfrontTaxes = itemToGet.price?.upfrontTaxes || 0;
		itemToStore.upfrontTaxesPromo = itemToGet.price?.upfrontTaxesPromo || 0;
		return itemToStore;
	}

	public getShippingCosts(itemToStore: CommercialShippingCosts, itemToGet: CommercialShoppingCart) {
		itemToStore = new CommercialShippingCosts();
		itemToStore.fromActivated = itemToGet.shippingCosts?.fromActivated || false;
		itemToStore.postalCode = itemToGet.shippingCosts?.postalCode || null;
		itemToStore.upfront = itemToGet.shippingCosts?.upfront || 0;
		itemToStore.upfrontPromo = itemToGet.shippingCosts?.upfrontPromo || 0;
		itemToStore.upfrontTaxes = itemToGet.shippingCosts?.upfrontTaxes || 0;
		itemToStore.upfrontTaxesPromo = itemToGet.shippingCosts?.upfrontTaxesPromo || 0;
		return itemToStore;
	}

	public formatSchedule(store: DeliveryStore, text: string): string {
		const horarioFijo: string = store.horaInicio && store.horaFin && store.diaInicio && store.diaFin;
		const horarioPartido: string = horarioFijo && store.horaInicioJornadaPartida && store.horaFinJornadaPartida;
		let shippingScheduleText: string;

		if (horarioFijo) {
			shippingScheduleText =
				store.diaInicio[0] + '-' + store.diaFin[0] + ' ' + store.horaInicio + ' a ' + store.horaFin;
			if (horarioPartido) {
				shippingScheduleText += ' y de ' + store.horaInicioJornadaPartida + ' a ' + store.horaFinJornadaPartida;
			}
		} else {
			shippingScheduleText = text;
		}
		return shippingScheduleText;
	}

	public checkModeSelected(selected: boolean, isMtp: boolean, autoinstalableResponse: boolean): boolean {
		return !selected && (!isMtp || autoinstalableResponse);
	}
}
