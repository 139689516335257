import { Component, ElementRef, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { AuthenticateService } from '../../core/services/authenticate.service';
import { ErrorHadlingUsingNotificationService } from '../../core/services/error-hadling-using-notification.service';
import { JWTHelper } from '../../core/services/jwt.helper';
import { TaggingHelperService } from '../../core/services/tagging.helper.service';
import { HttpRequestMethods, PAGES } from '../../shared/constants/defines';
import { ProfileType } from '../../shared/enums/profileType.enum';
import { Error } from '../../shared/models/error.model';
import { UpgradeProfile } from '../UpgradeProfile.enum';
import { UpgradeProfileService } from '../upgrade-profile.service';
import { tagging } from './../../../config/tagging-config';
import { SpModalFullComponent } from './../../shared/components/sp-modal-full/sp-modal-full.component';

@Component({
	selector: 'sp-step-one',
	templateUrl: './step-one.component.html',
	styleUrls: ['./step-one.component.scss'],
})
export class StepOneComponent implements OnInit, OnDestroy {
	@ViewChild('overlay', { static: true }) overlay: ElementRef;
	@ViewChild('fullModal', { static: true }) fullModal: SpModalFullComponent;
	isupgrade: boolean;
	form: UntypedFormGroup;
	loginNameInfo = '';
	upgradeError: Error;
	ngOnDestroy(): void {
		this.isupgrade = false;
	}
	constructor(
		public upgrade: UpgradeProfileService,
		private jwt: JWTHelper,
		private fb: UntypedFormBuilder,
		private auth: AuthenticateService,
		private router: Router,
		private translate: TranslateService,
		private taggingHelperService: TaggingHelperService,
		private errorHadlingUsingNotificationService: ErrorHadlingUsingNotificationService
	) {}

	ngOnInit() {
		this.taggingHelperService.view(tagging.loginUpgrade);
		this.isupgrade = true;
		this.form = this.fb.group({
			username: ['', [Validators.required]],
			password: ['', [Validators.required]],
		});
		this.translate.get('login.loginForm.fieldsList').subscribe((data) => {
			this.loginNameInfo =
				'<b>' +
				data.loginNameInfoItem1 +
				'</b> <br/>' +
				data.loginNameInfoItem2 +
				'<br/>' +
				'<b>' +
				data.loginNameInfoItem3 +
				'</b> <br/>' +
				data.loginNameInfoItem4 +
				'<br/>' +
				'<b>' +
				data.loginNameInfoItem5 +
				'</b> <br/>' +
				data.loginNameInfoItem6 +
				'<br/>';
		});
		this.taggingHelperService.view(tagging.upgradeStepOne);
		this.taggingHelperService.track(tagging.upgrade.start.eventName, tagging.upgrade.start.data);
	}
	/**
	 * dismiss the modal
	 */
	dismiss() {
		this.overlay.nativeElement.style.display = 'none';
		this.upgrade.startScenario = false;
		this.upgrade.step = UpgradeProfile.stepZero;
	}
	/**
	 * submit form sending username and password and validate to continue upgrade scenario
	 */
	public submit() {
		const saveToken = true;
		const form = this;
		if (this.form.valid) {
			this.auth.authenticate(this.form.controls.username.value, this.form.controls.password.value, saveToken).subscribe(
				(response) => {
					const userProfileType: ProfileType = this.jwt.getUserProfileFromJWT(response.jws).profileType;
					if (response.access_token && userProfileType.toString().toLowerCase() === ProfileType[2].toLowerCase()) {
						this.router.navigate([this.upgrade.targetRoute]);
						this.overlay.nativeElement.style.display = 'none';
					} else if (response.access_token) {
						this.upgrade.step = UpgradeProfile.stepTwo;
					}
				},
				(error) => {
					const notificationModal = this.errorHadlingUsingNotificationService.errorHandlingUsingNotification(
						error,
						HttpRequestMethods.post,
						PAGES.LOGIN,
						true
					);
					notificationModal.show();
					notificationModal.primaryButtonStyle = 'btn registration';
				}
			);
		}
	}
	/**
	 * destroy function delegated to close button in the modal
	 */
	destroy = function () {
		this.upgrade.startScenario = false;
	};

	forgetPassword() {
		this.translate.get('login.messagesList.recoverPasswordMessage.title').subscribe((data) => {
			this.fullModal.bodyTitle = data;
		});
		this.translate.get('login.messagesList.recoverPasswordMessage.description').subscribe((data) => {
			this.fullModal.bodyContent = data;
		});

		this.translate.get('login.messagesList.recoverPasswordMessage.button1.extLinkText').subscribe((data) => {
			this.fullModal.primaryButtonText = data;
		});
		let privateRegisterUrl = '';
		this.translate.get('login.messagesList.recoverPasswordMessage.button1.extLinkURL').subscribe((data) => {
			privateRegisterUrl = data;
		});
		this.fullModal.primaryButtonClick = function () {
			window.open(privateRegisterUrl);
		};

		this.translate.get('login.messagesList.recoverPasswordMessage.button2.extLinkText').subscribe((data) => {
			this.fullModal.secondaryButtonText = data;
		});
		let companyRegisterUrl = '';
		this.translate.get('login.messagesList.recoverPasswordMessage.button2.extLinkURL').subscribe((data) => {
			companyRegisterUrl = data;
		});
		this.fullModal.secondaryButtonClick = function () {
			window.open(companyRegisterUrl);
		};

		this.fullModal.show();
	}
}
