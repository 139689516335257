import { Injectable } from '@angular/core';
import { OverlayFullScreenModel } from '@mva10/mva10-angular';
import { TranslateService } from '@ngx-translate/core';
import { BehaviorSubject } from 'rxjs';

@Injectable()
export class CommercialConfirmationClose {
	confirmationClose = new OverlayFullScreenModel();
	private showConfirmation: boolean = false;
	private showConfirmationSubject = new BehaviorSubject(this.showConfirmation);
	public showConfirmation$ = this.showConfirmationSubject.asObservable();
	private reloadTagging: boolean = false;
	private reloadTaggingSubject = new BehaviorSubject(this.reloadTagging);
	public reloadTagging$ = this.reloadTaggingSubject.asObservable();
	constructor(public translate: TranslateService) {}

	public getWcsKeys(key) {
		this.translate.get(key).subscribe((data) => {
			this.confirmationClose.icon = data.image;
			this.confirmationClose.titleOverlay = data.title;
			this.confirmationClose.textOverlay = data.subtitle;
			this.confirmationClose.primaryBtnText = data.btnContinue;
			this.confirmationClose.secundaryBtnText = data.btnExit;
			this.confirmationClose.closeBtn = true;
			this.confirmationClose.largeButtons = true;
			this.confirmationClose.stylesSecundaryBtn = 'mva10-c-button--tertiary';
			this.confirmationClose.stylesPrimaryBtn = 'vfes-button--primary';
		});
		return this.confirmationClose;
	}

	public closeConfirmation(open: boolean) {
		this.showConfirmationSubject.next(open);
	}

	public taggingObservable(reload: boolean) {
		this.reloadTaggingSubject.next(reload);
	}
}
