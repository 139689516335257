import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { SubscriptionService } from '../../../core/services/subscription.service';
import { CommercialJourneys, deliveryParams } from '../../constants/defines';
import { DeliveryDate } from '../../models/delivery-date.model';
import { DeliveryModality } from '../../models/delivery-modality.model';
import { DeliveryStore } from '../../models/delivery-store.model';
import { ServiceModel } from '../../models/service.model';
import { AppService } from './../../../app.service';

@Component({
	selector: 'sp-delivery-types-v2',
	templateUrl: './sp-delivery-types-v2.component.html',
	styleUrls: ['./sp-delivery-types-v2.component.scss'],
})
export class SpDeliveryTypesV2Component implements OnInit {
	/**
	 * Delivery Types Module.
	 * @see module: CommercialMobileService as example
	 */

	/**Array with shipping types.*/
	@Input() deliveryTypes: Array<any>;
	/**Array with the dates returned by the service*/
	@Input() dates: DeliveryModality;
	/**For cases which the calendar should show a default date, this date is received here.
  If you do not receive this entry, no default date appears.*/
	@Input() startDate?: Date;
	/**Customer or store address*/
	@Input() address: any;
	/** If getCoordinates service returns impositive error, this input must become true */
	@Input() impositiveError: boolean = false;
	/** If getCoordinates service returns not found error, this input must become true */
	@Input() notFoundError: boolean = false;
	/** Error when user submits the form when is prohibited*/
	@Input() submitError: boolean = false;
	/** Text to show below the text input */
	@Input() inputHelper: string;

	@Input() zipCodeStore: string;
	/**Array with the stores returned by the service*/
	@Input() availableStores: DeliveryStore[];
	/**If the chosen shipment is a house, it returns true, if it does not return a false one.*/
	@Output() typeSelected = new EventEmitter<boolean>();
	/**The new zip entered in the input*/
	@Output() newZip = new EventEmitter<any>();
	/**Emits when a shop is clicked*/
	@Output() store = new EventEmitter<any>();
	/**Emits when changes on date */
	@Output() newDate = new EventEmitter<any>();

	/**Finally, the component has a <ng-content> to be able to pass the necessary HTML*/
	public selected: any;
	public isHome: boolean;
	public isShop: boolean;
	public free: string = CommercialJourneys.free;
	public selectedDeliveryDate: Date;
	public formatedAddress: any;
	public zipError: boolean = false;
	public showSpinner: boolean = false;
	public firstShowShop: boolean = true;
	public maxDate: Date;
	public minDate: Date;
	inputError: boolean = false;
	boxImage: string;
	postalCodeText: string;
	loadingShops: boolean;
	showTextIcon: boolean = true;
	calendar: boolean = false;
	dateToShow: string;
	fechas: Date[];
	pickUpText: string;
	public daysPassed: number;
	public packageStartDate: string;
	public listServices: ServiceModel[];
	constructor(
		public subscriptionData: SubscriptionService,
		public appService: AppService,
		public translate: TranslateService
	) {}

	ngOnInit() {
		this.orderTypes();
		this.autoSelect();
		this.formatHomeAddress(this.address);
		this.selectedDeliveryDate = this.startDate;
		this.dateToShow = this.selectedDeliveryDate.toLocaleDateString('es-ES');
		this.newDate.emit(this.selectedDeliveryDate);
		this.fechas = [];
		this.dates.fechasEntrega.forEach((fecha) => {
			this.fechas.push(fecha.fechaEntregaPedido);
		});
		this.maxDate = new Date(Math.max.apply(null, this.fechas));
		this.minDate = new Date(Math.min.apply(null, this.fechas));
		this.boxImage = this.appService.getImgFullPath(
			this.translate.instant('v10.dashboard.onePlus.shippingProcess.home.boxIcon')
		);
		this.postalCodeText = this.translate.instant('v10.dashboard.onePlus.shippingProcess.store.defaultcode');
		this.pickUpText = this.translate.instant('v10.dashboard.onePlus.shippingProcess.store.pickUp');
		this.listServices = this.subscriptionData.customerData.services;
		const onePlusStartDate: string = this.listServices[0].package.startDate;
		const dateOneplus: Date = new Date();
		dateOneplus.setTime(Date.parse(onePlusStartDate));
		const milliSeconds: number = 24 * 60 * 60 * 1000;
		const elapsedMilliseconds: number = Math.abs(dateOneplus.getTime() - new Date().getTime());
		this.daysPassed = Math.round(elapsedMilliseconds / milliSeconds);
		if (isNaN(this.daysPassed)) {
			this.daysPassed = -1;
		}
	}

	orderTypes() {
		this.deliveryTypes.sort(function (a, b) {
			return a.nmOrden - b.nmOrden;
		});
	}

	onChangeMode(mode) {
		if (!mode.selected) {
			this.deliveryTypes.map((res) => {
				res.selected = !res.selected;
				if (res.selected) {
					this.selected = res;
				}
			});
		}
		this.dateToShow = this.startDate ? this.startDate.toLocaleDateString('es-ES') : '';
		this.setBoolType();
	}

	autoSelect() {
		/**If a shipping type arrives as selected, It would be the selected One. Else, must be the first one */
		this.deliveryTypes.map((res) => {
			if (res.selected) {
				this.selected = res;
			}
		});
		if (!this.selected) {
			this.deliveryTypes[0].selected = true;
			this.selected = this.deliveryTypes[0];
		}
		this.setBoolType();
	}

	setBoolType() {
		if (this.selected.cdType === deliveryParams.homeCd) {
			this.isShop = false;
			this.isHome = true;
		} else {
			this.isShop = true;
			this.isHome = false;
		}
		this.typeSelected.emit(this.isHome);
	}

	onDateChange(date) {
		this.selectedDeliveryDate = this.findDeliveryDate(date).fechaEntregaPedido;
		this.dateToShow = this.selectedDeliveryDate.toLocaleDateString('es-ES');
		this.newDate.emit(this.selectedDeliveryDate);
		this.calendar = false;
	}

	private findDeliveryDate(date: Date): DeliveryDate {
		const validDates = this.dates.fechasEntrega;
		return validDates.filter((validDate: DeliveryDate) => {
			return date.getTime() === validDate.fechaEntregaPedido.getTime();
		})[0];
	}

	formatHomeAddress(address) {
		this.formatedAddress = '';
		if (address.type) {
			this.formatedAddress += address.type + ' ';
		}
		if (address.name) {
			this.formatedAddress += address.name + ' ';
		}
		if (address.addressNumber) {
			this.formatedAddress += address.addressNumber + ' ';
		}
		if (address.floor) {
			this.formatedAddress += address.floor + ', ';
		}
		if (address.door) {
			this.formatedAddress += address.door + ', ';
		}
		if (address.town) {
			this.formatedAddress += address.town + ', ';
		}
		if (address.zipCode) {
			this.formatedAddress += address.zipCode;
		}
	}

	changeStore(currentStore: DeliveryStore) {
		this.store.emit(currentStore);
		this.dateToShow = this.startDate ? this.startDate.toLocaleDateString('es-ES') : '';
		if (this.startDate) {
			this.selectedDeliveryDate = this.findDeliveryDate(this.startDate).fechaEntregaPedido;
			this.newDate.emit(this.selectedDeliveryDate);
		}
	}

	ngOnChanges() {
		if (this.firstShowShop && this.availableStores) {
			const store = this.availableStores.find((store) => store.checked === true);
			if (store) {
				this.changeStore(store);
			} else {
				this.changeStore(this.availableStores[0]);
			}
			this.firstShowShop = false;
		}
		if (
			this.loadingShops &&
			this.availableStores !== undefined &&
			this.availableStores !== null &&
			this.availableStores.length > 0
		) {
			this.loadingShops = false;
			this.showTextIcon = true;
		}
	}

	textChanged(text: string) {
		this.availableStores = null;
		this.submitError = false;
		this.showTextIcon = true;
		if (text.length === 0) {
			this.inputError = false;
			this.inputHelper = this.translate.instant('v10.dashboard.onePlus.shippingProcess.store.code');
			this.newZip.emit(-1);
		} else if (text.length < 5 || text.match(/^[0-9]+$/) == null) {
			this.inputHelper = this.translate.instant('v10.dashboard.onePlus.shippingProcess.store.error')[1];
			this.inputError = true;
			this.newZip.emit(-1);
		} else if (text.length === 5) {
			this.inputError = false;
			this.zipError = false;
			this.inputHelper = this.translate.instant('v10.dashboard.onePlus.shippingProcess.store.code');
			this.showTextIcon = false;
			this.loadingShops = true;
			this.newZip.emit(text);
		}
	}
}
