import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Params } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import moment from 'moment';
import { BehaviorSubject, Observable, Subject } from 'rxjs';
import { map } from 'rxjs/operators';
import { AuthenticateService } from '../../core/services/authenticate.service';
import { StorageService } from '../../core/services/storage.service';
import { SubscriptionService } from '../../core/services/subscription.service';
import * as constants from '../constants/defines';
import * as Routes from '../constants/routes-config';
import { API_URLS } from '../constants/routes-config';
import { BundleCategory } from '../enums/bundleCategory.enum';
import { BundleType } from '../enums/bundleType.enum';
import { CallType } from '../enums/callType.enum';
import { CustomerType } from '../enums/customerType.enum';
import { IconClassNames } from '../enums/iconClassNames.enum';
import { PlanType } from '../enums/plan-type.enum';
import { ProfileType } from '../enums/profileType.enum';
import { ServiceType } from '../enums/serviceType.enum';
import { SiteType } from '../enums/siteType.enum';
import { UnitCode } from '../enums/unitCode.enum';
import { Bundle } from '../models/bundle.model';
import { Dashboard } from '../models/dashboard.model';
import { OutOfBundleModel } from '../models/out-of-bundle-model.model';
import { OutOfBundle } from '../models/out-of-bundle.model';
import { ProducOrders } from '../models/product-order.model';
import { ServiceBalanceSubscription } from '../models/service-balance-subscription.model';
import { ServiceModel } from '../models/service.model';
import { UserProfile } from '../models/user-profile.model';
import { ParamsEncoder } from '../utils/params-encoder.service';
import { LOCAL_STORAGE_KEYS, monthMoment } from './../constants/defines';
import { DataSharingType } from './../enums/data-sharing-type.enum';
import { UtilitiesService } from './../utils/utilities.service';
import { CreditBalanceService } from './credit-balance.service';
import { DestinyService } from './destiny.service';

@Injectable()
export class DashboardService {
	isVOVBiztalkReplicaTabViewed: boolean;
	isCurrentServiceChanged: boolean;
	errorFlag: boolean;
	loadBundle: boolean;
	dateError: boolean;
	public currentService: ServiceModel;
	public customerAccountId: string;
	bundle: OutOfBundle;
	serviceBundles: OutOfBundle;
	outOfBundles: OutOfBundleModel[];
	memberAllData: any;
	AllDataOfWonerAndMembers: any;
	public bundles: Bundle[] = [];
	public allData: Dashboard[] = [];
	public fixedAllData: Dashboard[] = [];
	public total: number;
	public openModel = false;
	public creditBundles: Dashboard[];
	public allDataLeader: Dashboard[] = [];
	public allDataMembers: Dashboard[] = [];
	public sumAvailableForOwner: Number = 0;
	isShared: boolean;
	isLeader: boolean;
	content: Dashboard;
	dataIdex = 0;
	isPrepaidYuserWithActiveBenifits: boolean;
	public responseStatus: string;
	public hasPartialError: boolean;
	hasUnlimited: boolean = false;
	relaodDashboardSubject = new Subject();
	public numberOrders: number;
	public showExtraTileTrayFlag$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
	constructor(
		private http: HttpClient,
		private storageService: StorageService,
		private subscriptionService: SubscriptionService,
		private auth: AuthenticateService,
		public utilitiesService: UtilitiesService,
		private creditBalanceService: CreditBalanceService,
		private translate: TranslateService,
		public destinyService: DestinyService,
		public subscriptionData: SubscriptionService
	) {
		this.setCurrentService();
		this.destinyService.unlimitedObservable.subscribe((state) => {
			this.hasUnlimited = state;
		});
	}

	public init() {
		this.currentService = this.subscriptionService.customerData.currentService;
	}
	public getBalanceForService(bundleType = constants.BundleType.outOfPlan) {
		this.outOfBundles = [];
		this.serviceBundles = new OutOfBundle();
		this.serviceBundles.bundles = new Array<OutOfBundleModel>();
		return this.http
			.get(
				Routes.API_URLS.Dashboard.ServiceBalance.replace(
					'{siteId}',
					this.subscriptionService.customerData.customerAccountsId
				)
					.replace('{serviceId}', this.subscriptionService.customerData.currentService.id)
					.replace('{bundleType}', bundleType)
			)
			.pipe(
				map((response: any) => {
					this.serviceBundles.totalAmount = 0;
					this.outOfBundles = new Array<OutOfBundleModel>();
					this.serviceBundles = new OutOfBundle();
					this.serviceBundles.bundles = new Array<OutOfBundleModel>();
					response.items.map((el) => {
						if (
							el.subscriptions &&
							(el.subscriptions[0].type.toLowerCase() === ServiceType.Tv.toLowerCase() ||
								el.subscriptions[0].type.toLowerCase() === ServiceType.Postpaid.toLowerCase() ||
								el.subscriptions[0].type.toLowerCase() === ServiceType.MbbPostpaid.toLowerCase() ||
								el.subscriptions[0].type.toLowerCase() === ServiceType.Landline.toLowerCase() ||
								el.subscriptions[0].type.toLowerCase() === ServiceType.Prepaid.toLowerCase())
						) {
							if (this.serviceBundles.bundles.length > 0) {
								let service = new OutOfBundleModel();
								service = this.serviceBundles.bundles.find((item) => item.id === el.subscriptions[0].id);
								if (service) {
									if (service.id === el.subscriptions[0].id) {
										service.amount += this.getNumber(el.amount.amount.toString());
									}
								} else {
									service = new OutOfBundleModel();
									service.amount = this.getNumber(el.amount.amount.toString());
									service.name = el.subscriptions[0].name;
									service.id = el.subscriptions[0].id;
									service.type = el.subscriptions[0].type;

									if (
										service.type.toLowerCase() !== ServiceType.Tv.toLowerCase() &&
										service.type.toLowerCase() !== ServiceType.Internet.toLowerCase()
									) {
										service.showDots = true;
									}
									switch (el.subscriptions[0].type.toLowerCase()) {
										case ServiceType.Postpaid.toLowerCase():
											service.icon = IconClassNames.icon_mobile_postpaid;
											break;
										case ServiceType.Tv.toLowerCase():
											service.icon = IconClassNames.icon_tv;
											break;
										case ServiceType.MbbPostpaid.toLowerCase():
											service.icon = IconClassNames.icon_mbb;
											break;
										case ServiceType.Landline.toLowerCase():
											service.icon = IconClassNames.icon_busines_phone;
											break;
										case ServiceType.Prepaid.toLowerCase():
											service.icon = IconClassNames.icon_mobile_prepaid;
											break;
										default:
											break;
									}
									this.serviceBundles.bundles.push(service);
								}
							} else {
								const service = new OutOfBundleModel();
								service.amount = this.getNumber(el.amount.amount.toString());
								service.name = el.subscriptions ? el.subscriptions[0].name : '';
								service.id = el.subscriptions ? el.subscriptions[0].id : '';
								service.type = el.subscriptions ? el.subscriptions[0].type : '';
								if (el.subscriptions) {
									switch (el.subscriptions[0].type.toLowerCase()) {
										case ServiceType.Postpaid.toLowerCase():
											service.icon = IconClassNames.icon_mobile_postpaid;
											break;
										case ServiceType.Tv.toLowerCase():
											service.icon = IconClassNames.icon_tv;
											break;
										case ServiceType.MbbPostpaid.toLowerCase():
											service.icon = IconClassNames.icon_mbb;
											break;
										case ServiceType.Landline.toLowerCase():
											service.icon = IconClassNames.icon_busines_phone;
											break;
										case ServiceType.Prepaid.toLowerCase():
											service.icon = IconClassNames.icon_mobile_prepaid;
											break;
										default:
											break;
									}
								}

								if (
									service.type.toLowerCase() !== ServiceType.Tv.toLowerCase() &&
									service.type.toLowerCase() !== ServiceType.Internet.toLowerCase()
								) {
									service.showDots = true;
								}

								this.serviceBundles.bundles.push(service);
							}
						} else if (
							this.subscriptionService.customerData.currentService.type === ServiceType.MbbPrepaid ||
							this.subscriptionService.customerData.currentService.type === ServiceType.Prepaid
						) {
							this.handlePrepaidService(el);
						}
					});
					/** Out of bundle for vodafone en tu casa */
					if (
						this.subscriptionService.customerData.currentService &&
						this.subscriptionService.customerData.currentService.type === ServiceType.VodafoneEnTuCasa
					) {
						const entuCasaServiceId = this.subscriptionService.customerData.currentService.id;
						const allenTuCasaBundles = response.items.slice().filter((el) => {
							return (
								(el.subscriptions &&
									el.subscriptions[0].id === this.subscriptionService.customerData.currentService.id) ||
								el.subscriptions[0].type === ServiceType.Postpaid
							);
						});
						allenTuCasaBundles.map((el) => {
							const bundle = this.outOfBundles.find(
								(e) => el.type === BundleType.Voice || e.services.id === el.subscriptions[0].id
							);
							if (bundle) {
								bundle.amount += this.getNumber(el.amount.amount.toString());
							} else {
								const serviceBundel: OutOfBundleModel = new OutOfBundleModel();
								serviceBundel.amount = this.getNumber(el.amount.amount.toString());
								serviceBundel.id = entuCasaServiceId;
								serviceBundel.name = el.name;
								serviceBundel.type = el.type;
								serviceBundel.icon = IconClassNames.icon_landline;
								serviceBundel.services = new ServiceBalanceSubscription();
								serviceBundel.services.id = entuCasaServiceId;
								serviceBundel.services.name = el.subscriptions ? el.subscriptions[0].name : '';
								serviceBundel.services.type = el.subscriptions ? el.subscriptions[0].type : '';
								switch (el.type.toLowerCase()) {
									case BundleType.Benefits.toLowerCase():
										serviceBundel.icon = IconClassNames.icon_mobile;
										break;
									case BundleType.Data.toLowerCase():
										serviceBundel.icon = IconClassNames.icon_data;
										break;
									case BundleType.Voice.toLowerCase():
										serviceBundel.icon = IconClassNames.icon_landline;
										break;
									case BundleType.SMS.toLowerCase():
										serviceBundel.icon = IconClassNames.icon_text;
										break;
									case BundleType.Fixed.toLowerCase():
										serviceBundel.icon = IconClassNames.icon_landline;
										break;
									case BundleType.International.toLowerCase():
										serviceBundel.icon = IconClassNames.icon_country;
										break;
									default:
										break;
								}
								this.outOfBundles.push(serviceBundel);
							}
						});
					} else {
						response.items.map((el) => {
							if (
								this.outOfBundles.find(
									(e) =>
										el.subscriptions &&
										e.type.toLowerCase() === el.type.toLowerCase() &&
										el.subscriptions &&
										e.services.id === el.subscriptions[0].id
								)
							) {
								this.outOfBundles.find(
									(e) => e.type.toLowerCase() === el.type.toLowerCase() && e.services.id === el.subscriptions[0].id
								).amount += this.getNumber(el.amount.amount.toString());
							} else {
								const serviceBundel: OutOfBundleModel = new OutOfBundleModel();
								serviceBundel.amount =
									this.subscriptionService.customerData.currentService.type.toLowerCase() ===
									ServiceType.Prepaid.toLowerCase()
										? +this.utilitiesService.currencyFormatForPrePaid(el.amount.amount.toString())
										: this.getNumber(el.amount.amount.toString());
								serviceBundel.id = el.id;
								serviceBundel.name = el.name;
								serviceBundel.type = el.type;
								serviceBundel.icon = IconClassNames.icon_landline;
								serviceBundel.services = new ServiceBalanceSubscription();
								serviceBundel.services.id = el.subscriptions ? el.subscriptions[0].id : '';
								serviceBundel.services.name = el.subscriptions ? el.subscriptions[0].name : '';
								serviceBundel.services.type = el.subscriptions ? el.subscriptions[0].type : '';
								switch (el.type.toLowerCase()) {
									case BundleType.Benefits.toLowerCase():
										serviceBundel.icon = IconClassNames.icon_mobile;
										break;
									case BundleType.Data.toLowerCase():
										serviceBundel.icon = IconClassNames.icon_data;
										break;
									case BundleType.Voice.toLowerCase():
										serviceBundel.icon = IconClassNames.icon_landline;
										break;
									case BundleType.SMS.toLowerCase():
										serviceBundel.icon = IconClassNames.icon_text;
										break;
									case BundleType.Fixed.toLowerCase():
										serviceBundel.icon = IconClassNames.icon_landline;
										break;
									case BundleType.International.toLowerCase():
										serviceBundel.icon = IconClassNames.icon_country;
										break;
									default:
										break;
								}
								if (
									serviceBundel.amount !== 0 &&
									serviceBundel.services.type.toLowerCase() !== ServiceType.Tv.toLowerCase()
								) {
									this.outOfBundles.push(serviceBundel);
								}
							}
						});
					}
				})
			);
	}
	public handlePrepaidService(el) {
		if (this.serviceBundles.bundles.length > 0) {
			let service = new OutOfBundleModel();
			service = this.serviceBundles.bundles.find(
				(item) => item.id === this.subscriptionService.customerData.currentService.id
			);
			if (service) {
				service.amount += +this.utilitiesService.currencyFormatForPrePaid(el.amount.amount.toString());
			}
		} else {
			const service = new OutOfBundleModel();
			service.amount = +this.utilitiesService.currencyFormatForPrePaid(el.amount.amount.toString());
			service.name = el.subscriptions ? el.subscriptions[0].name : '';
			service.id = this.subscriptionService.customerData.currentService.id;
			service.type = this.subscriptionService.customerData.currentService.type;
			switch (this.subscriptionService.customerData.currentService.type.toLowerCase()) {
				case ServiceType.Postpaid.toLowerCase():
					service.icon = IconClassNames.icon_mobile_postpaid;
					break;
				case ServiceType.Tv.toLowerCase():
					service.icon = IconClassNames.icon_tv;
					break;
				case ServiceType.MbbPostpaid.toLowerCase():
					service.icon = IconClassNames.icon_mbb;
					break;
				case ServiceType.Landline.toLowerCase():
					service.icon = IconClassNames.icon_busines_phone;
					break;
				case ServiceType.Prepaid.toLowerCase():
					service.icon = IconClassNames.icon_mobile_prepaid;
					break;
				default:
					break;
			}

			if (
				service.type.toLowerCase() !== ServiceType.Tv.toLowerCase() &&
				service.type.toLowerCase() !== ServiceType.Internet.toLowerCase()
			) {
				service.showDots = true;
			}

			this.serviceBundles.bundles.push(service);
		}
	}
	public getServiceBalance(bundleType = constants.BundleType.inPlan) {
		this.hasPartialError = false;
		return this.creditBalanceService
			.getServiceBalance(
				this.subscriptionService.customerData.customerAccountsId,
				this.subscriptionService.customerData.currentService.id
			)
			.pipe(
				map(
					(response: any) => {
						this.destinyService.checkUnlimitedServiceBalance(response.items);

						this.responseStatus = '';
						if (response.status && this.currentService && this.currentService.type) {
							const isMBB = this.currentService.type.toLowerCase().includes(ServiceType.MbbPostpaid.toLowerCase());
							const isMobilePostpaid = this.currentService.type
								.toLowerCase()
								.includes(ServiceType.Postpaid.toLowerCase());
							const isLandLine = this.currentService.type.toLowerCase().includes(ServiceType.Landline.toLowerCase());
							this.responseStatus = response.status;

							/** if there is an issue in back-end to get all bundles consumption then will simulate error case */
							if (
								(isMobilePostpaid && this.faildInLoadingDataBundle() && this.faildInLoadingVoiceAndSMSBundles()) ||
								/** in mbb case if response has -1 as status then simulate failier case */
								(isMBB && this.faildInLoadingDataBundle()) ||
								/** in landline case if response has -2 as status then simulate failier case */
								(isLandLine && this.faildInLoadingVoiceAndSMSBundles())
							) {
								this.hasPartialError = true;
								return null;
							}
						}

						this.bundles = [];
						this.fixedAllData = [];
						this.creditBundles = [];
						this.handleYuUserCases(response);
						let newDashboard: Dashboard;
						if (response.status && response.status.includes('-6') && !this.isPrepaidYuserWithActiveBenifits) {
							return null;
						}
						for (const item of response.items) {
							newDashboard = new Dashboard();
							newDashboard.id = item.id || null;
							newDashboard.code = item.code || null;
							newDashboard.type = item.type || null;
							newDashboard.category = item.categories?.[0] || null;
							newDashboard.group = item.group || null;
							newDashboard.name = item.name || null;
							newDashboard.shortDescription = item.description?.short || null;
							const dates = item.validityPeriod;
							newDashboard.expirationDate = dates ? dates.toDate : null;
							newDashboard.activationDate = item.activationDate || null;
							const amount = item.amount?.amount;
							newDashboard.amount = parseFloat(amount ? amount : 0);
							newDashboard.currency = item.amount?.currency || null;
							newDashboard.services = item.subscriptions || null;
							newDashboard.basicEnjoyMore = item.basicEnjoyMore || null;
							newDashboard.productType = item.productType || null;

							const dataSharing = item.datasharing || null;
							if (dataSharing) {
								newDashboard.isShared = dataSharing.isShared || false;
								newDashboard.currentStatus = dataSharing.limitStatus || null;
								newDashboard.targetLimit = item.datasharing?.targetLimit || null;
								newDashboard.sharingType = item.datasharing?.type || null;
								newDashboard.isEligibleForSharing = item.datasharing?.isEligibleForSharing;
							}
							newDashboard.dataSharingType = item.subscriptions?.[0]?.dataSharingType || null;

							const allowances = item.allowance || null;

							if (allowances) {
								// isowner and current item is member aya
								// set available_allowance = 0
								newDashboard.available_allowance = parseFloat(allowances.availableAllowance) || 0;
								newDashboard.available_allowance_unit_code = allowances.availableAllowanceUnitCode || null;
								newDashboard.used_allowance = parseFloat(allowances.usedAllowance) || 0;
								newDashboard.used_allowance_unit_code = allowances.usedAllowanceUnitCode || null;

								newDashboard.remaining_allawonce = parseFloat(allowances.remainingAllowance) || 0;
								newDashboard.remaining_allowance_unit_code = allowances.usedAllowanceUnitCode || null;

								newDashboard.isLimited = !(allowances.unlimited || null);
								newDashboard.uploadSpeed = parseFloat(allowances.uploadSpeed) || null;
								newDashboard.downloadSpeed = parseFloat(allowances.downloadSpeed) || null;
							}
							newDashboard.remaining_allowance_unit_code = newDashboard.available_allowance_unit_code || null;
							newDashboard.dataSharingType = item.subscriptions?.[0]?.dataSharingType || null;
							this.fixedAllData.push(newDashboard);
							if (
								this.currentService &&
								this.currentService.type &&
								this.currentService.type.toLowerCase().includes(ServiceType.Prepaid.toLowerCase())
							) {
								if (newDashboard.type.toLowerCase() === BundleType.Credit.toLowerCase()) {
									this.creditBundles.push(newDashboard);
								}
							}
						}
						this.setPrepaidStatusCrossVariable();
						if (this.currentService && this.currentService.type) {
							if (
								this.currentService.type.toLowerCase().includes(ServiceType.Postpaid.toLowerCase()) ||
								this.currentService.type.toLowerCase().includes(ServiceType.Prepaid.toLowerCase())
							) {
								if (this.filterByBundleType(BundleType.Voice, this.fixedAllData)) {
									this.addNewBundle(BundleType.Voice);
								}
								if (this.filterByBundleType(BundleType.SMS, this.fixedAllData)) {
									this.addNewBundle(BundleType.SMS);
								}
								if (this.filterByBundleType(BundleType.Data, this.fixedAllData)) {
									this.addNewBundle(BundleType.Data);
								}
							} else if (this.currentService.type.toLowerCase().includes(ServiceType.MbbPostpaid.toLowerCase())) {
								if (this.filterByBundleType(BundleType.Data, this.fixedAllData)) {
									this.addNewBundle(BundleType.Data);
								}
							} else if (this.currentService.type.toLowerCase().includes(ServiceType.Landline.toLowerCase())) {
								if (this.filterByBundleType(BundleType.Voice, this.fixedAllData)) {
									let result = false;
									let index = 0;
									while (index < this.fixedAllData.length && !result) {
										if (
											this.fixedAllData[index].type.toLowerCase().includes(BundleType.Voice.toLowerCase()) &&
											(!this.fixedAllData[index].group ||
												!this.fixedAllData[index].group.toLowerCase().includes(BundleType.International))
										) {
											result = true;
										}
										index++;
									}
									if (result) {
										this.addNewBundle(BundleType.Voice);
									}
								}
								if (this.filterByBundleType(BundleType.Fixed, this.fixedAllData)) {
									this.addNewBundle(BundleType.Fixed);
								}
								if (this.filterByBundleType(BundleType.International, this.fixedAllData)) {
									this.addNewBundle(BundleType.International);
								}
							} else if (
								this.currentService.type.toLowerCase().includes(ServiceType.Internet.toLowerCase()) ||
								this.currentService.type.toLowerCase().includes(ServiceType.ADSL.toLowerCase()) ||
								this.currentService.type.toLowerCase().includes(ServiceType.Fibre.toLowerCase())
							) {
								if (this.filterByBundleType(BundleType.ADSL, this.fixedAllData)) {
									this.addNewBundle(BundleType.ADSL);
								} else if (this.filterByBundleType(BundleType.Fibre, this.fixedAllData)) {
									this.addNewBundle(BundleType.Fibre);
								}
							} else if (this.currentService.type.toLowerCase().includes(ServiceType.Tv.toLowerCase())) {
								if (this.filterByBundleType(BundleType.TV, this.fixedAllData)) {
									this.addNewBundle(BundleType.TV);
								}
							} else if (this.currentService.type.toLowerCase().includes(ServiceType.VodafoneEnTuCasa.toLowerCase())) {
								this.addTuCasaBundles();
							}
						}
						return this.bundles.length < 1 ? null : this.bundles;
					},
					(error) => {
						return null;
					}
				)
			);
	}
	addTuCasaBundles() {
		if (
			this.filterByBundleType(BundleType.Voice, this.fixedAllData) ||
			this.filterByBundleType(BundleType.Fixed, this.fixedAllData) ||
			this.filterByBundleType(BundleType.International, this.fixedAllData)
		) {
			this.addNewBundle(BundleType.Voice);
		}
		if (this.filterByBundleType(BundleType.Data, this.fixedAllData)) {
			this.addNewBundle(BundleType.Data);
		}
		if (this.filterByBundleType(BundleType.SMS, this.fixedAllData)) {
			this.addNewBundle(BundleType.SMS);
		}
	}
	handleYuUserCases(response) {
		if (!response.status && this.isPrepaidYuserWithActiveBenifits) {
			this.addStaticYuserBundles(response);
		}
		if (response.status && response.status.includes('-6') && this.isPrepaidYuserWithActiveBenifits) {
			this.responseStatus += '-1';
			this.fillNamePropInYuBundles(response);
		}
	}
	private fillNamePropInYuBundles(response) {
		const ybundlesVoice = response.items.filter((item) => {
			return (
				item.group &&
				item.group.toLowerCase() === PlanType.yu.toLowerCase() &&
				item.type.toLowerCase() === BundleType.Voice.toLowerCase()
			);
		});
		const ybundlesSms = response.items.filter((item) => {
			return (
				item.group &&
				item.group.toLowerCase() === PlanType.yu.toLowerCase() &&
				item.type.toLowerCase() === BundleType.SMS.toLowerCase()
			);
		});
		this.translate.get('dashboard.contentList.guage.itemList').subscribe((data) => {
			if (ybundlesVoice && ybundlesVoice.length === 1) {
				ybundlesVoice[0].name = data.yuCallsBundle.body;
				ybundlesVoice[0].allowance = {
					unlimited: true,
				};
				ybundlesVoice[0].category = [BundleCategory.Basic];
			}
			if (ybundlesSms && ybundlesSms.length === 1) {
				ybundlesSms[0].name = data.yuSmsBundle.body;
				ybundlesSms[0].allowance = {
					unlimited: true,
				};
				ybundlesSms[0].category = [BundleCategory.Basic];
			}
		});
		response.items.push({
			type: BundleType.Data,
		});
	}
	private addStaticYuserBundles(response) {
		let voiceBundleName = '';
		let smsBundleName = '';
		const ybundlesVoice = response.items.filter((item) => {
			return (
				item.group &&
				item.group.toLowerCase() === PlanType.yu.toLowerCase() &&
				item.type.toLowerCase() === BundleType.Voice.toLowerCase()
			);
		});
		const ybundlesSms = response.items.filter((item) => {
			return (
				item.group &&
				item.group.toLowerCase() === PlanType.yu.toLowerCase() &&
				item.type.toLowerCase() === BundleType.SMS.toLowerCase()
			);
		});
		this.translate.get('dashboard.contentList.guage.itemList').subscribe((data) => {
			if (!ybundlesVoice || ybundlesVoice.length === 0) {
				voiceBundleName = data.yuCallsBundle.body;
				response.items.push({
					type: BundleType.Voice,
					name: voiceBundleName,
					group: PlanType.yu,
					allowance: {
						unlimited: true,
					},
					categories: [BundleCategory.Basic],
				});
			}
			if (!ybundlesSms || ybundlesSms.length === 0) {
				smsBundleName = data.yuSmsBundle.body;
				response.items.push({
					type: BundleType.SMS,
					name: smsBundleName,
					group: PlanType.yu,
					allowance: {
						unlimited: true,
					},
					categories: [BundleCategory.Basic],
				});
			}
		});
	}
	setPrepaidStatusCrossVariable() {
		this.storageService.creditBalanceAmount = null;
		if (this.creditBundles && this.creditBundles.length > 0) {
			this.storageService.creditBalanceAmount = this.creditBundles
				.map((item) => {
					return this.utilitiesService.checkDateHasExpired(item.expirationDate) ? 0 : item.amount;
				})
				.reduce((previous, next) => {
					return previous + next;
				});
		}
	}
	public faildInLoadingDataBundle() {
		return this.responseStatus.includes('-1');
	}
	public faildInLoadingVoiceAndSMSBundles() {
		return this.responseStatus.includes('-2');
	}
	public faildInLoadingDataBundlePrepaidY() {
		return this.isPrepaidYuserWithActiveBenifits && this.responseStatus && this.responseStatus.includes('-6');
	}
	public getContent(bundleIndex: number) {
		if (!this.bundles[bundleIndex].data) {
			this.allData = this.getOriginalData();
			if (this.currentService && this.currentService.type) {
				if (
					this.currentService.type.toLowerCase().includes(ServiceType.Postpaid.toLowerCase()) ||
					this.currentService.type.toLowerCase().includes(ServiceType.Prepaid.toLowerCase()) ||
					this.currentService.type.toLowerCase().includes(ServiceType.VodafoneEnTuCasa.toLowerCase())
				) {
					if (this.bundles[bundleIndex].bundleType === BundleType.Data) {
						this.bundles[bundleIndex].data = this.getDataMobileOrMbb();
					} else {
						this.bundles[bundleIndex].data = this.getFirstBasicCategory(this.bundles[bundleIndex].bundleType);
					}
				} else if (
					this.currentService.type.toLowerCase().includes(ServiceType.MbbPostpaid.toLowerCase()) ||
					this.currentService.type.toLowerCase().includes(ServiceType.Postpaid.toLowerCase())
				) {
					this.bundles[bundleIndex].data = this.getDataMobileOrMbb();
				} else if (this.currentService.type.toLowerCase().includes(ServiceType.Landline)) {
					if (this.bundles[bundleIndex].bundleType === BundleType.International) {
						this.bundles[bundleIndex].data = this.getVoiceInternational();
					} else {
						this.bundles[bundleIndex].data = this.getFirstVoiceOrFixed(this.bundles[bundleIndex].bundleType);
					}
				} else if (
					this.currentService.type.toLowerCase().includes(ServiceType.Internet) ||
					this.currentService.type.toLowerCase().includes(ServiceType.ADSL.toLowerCase()) ||
					this.currentService.type.toLowerCase().includes(ServiceType.Fibre.toLowerCase())
				) {
					this.bundles[bundleIndex].data = this.getInternet(this.bundles[bundleIndex].bundleType);
				} else if (this.currentService.type.toLowerCase().includes(ServiceType.Tv)) {
					this.bundles[bundleIndex].data = this.getTotalTV();
				}
			}
		}

		return this.bundles[bundleIndex].data;
	}

	/**
	 * getAllOfBundle  //i  get all bundle of type data
	 */
	public getAllOfBundle(bundleType: BundleType) {
		try {
			const result: Dashboard[] = [];
			this.allData = this.getOriginalData(bundleType);
			if (
				this.currentService &&
				this.currentService.type &&
				this.currentService.type.toLowerCase() === ServiceType.Landline.toLowerCase()
			) {
				this.allData.map((item) => {
					if (
						(bundleType.toLowerCase() === BundleType.International.toLowerCase() &&
							item.type.toLowerCase().includes(BundleType.Voice.toLowerCase()) &&
							item.group &&
							item.group.toLowerCase().includes(BundleType.International.toLowerCase())) ||
						(bundleType.toLowerCase() === BundleType.Voice &&
							item.type.toLowerCase().includes(BundleType.Voice.toLowerCase()) &&
							(!item.group ||
								(item.group && !item.group.toLowerCase().includes(BundleType.International.toLowerCase())))) ||
						(bundleType.toLowerCase() === BundleType.Fixed.toLowerCase() &&
							item.type.toLowerCase().includes(BundleType.Fixed.toLowerCase()))
					) {
						this.calculateItemRemaining(item);
						result.push(item);
					}
				});
			} else {
				if (
					this.currentService.type.toLowerCase() === ServiceType.VodafoneEnTuCasa.toLowerCase() &&
					bundleType === BundleType.Voice
				) {
					this.allData = this.getOriginalData();
					return this.getVoiceBundlesForTuCasaService();
				}
				this.allData.forEach((item) => {
					if (item.type.toLowerCase().includes(bundleType.toLowerCase())) {
						/** check if item is member and isLeader === true --> then do nothing */
						if (
							!(
								item.type.toLowerCase().includes(BundleType.Data.toLowerCase()) &&
								this.isLeader &&
								this.isShared &&
								item.dataSharingType &&
								item.dataSharingType.toLowerCase() === DataSharingType.member.toLowerCase()
							)
						) {
							this.calculateItemRemaining(item);
							result.push(item);
						}
					}
				});
			}

			return result;
		} catch (error) {
			return null;
		}
	}

	public getBundleByCode(bundleCode: string): Bundle {
		return this.bundles.length ? this.bundles.find((bundle) => bundle.data.code === bundleCode) : null;
	}
	getVoiceBundlesForTuCasaService() {
		const voiceBundles = this.allData.filter((bundle) => {
			if (
				bundle.type &&
				(bundle.type.toLowerCase() === BundleType.Voice.toLowerCase() ||
					bundle.type.toLowerCase() === BundleType.Fixed.toLowerCase() ||
					bundle.type.toLowerCase() === BundleType.International.toLowerCase())
			) {
				this.calculateItemRemaining(bundle);
				return true;
			}
		});
		if (voiceBundles && voiceBundles.length > 0) {
			return voiceBundles;
		} else {
			return null;
		}
	}
	public calculateItemRemaining(item: Dashboard) {
		if (item.isLimited) {
			const type = item.type.toLowerCase();
			/** calculate remaining */
			/** here I have 3 cases
			 * 1- isLeader and this is leader data sharing item --> Do nothing
			 * 2- isMember and this is member data sharing item --> Deal with remaining key
			 * 2- isMember and this is member extra item --> Remaining = available - used
			 */
			if (
				!(
					!this.isLeader &&
					this.isShared &&
					item.dataSharingType &&
					item.dataSharingType.toLowerCase() === DataSharingType.member.toLowerCase()
				)
			) {
				item.remaining_allawonce = item.available_allowance - item.used_allowance;
			}

			/** check if the type is data */
			if (type.includes(BundleType.Data)) {
				if (item.available_allowance >= 1000) {
					item.available_allowance /= 1024;
					item.available_allowance_unit_code = UnitCode.GB;
				} else {
					item.available_allowance_unit_code = UnitCode.MB;
				}

				if (item.available_allowance.toString().includes('.')) {
					item.available_allowance = this.round(item.available_allowance);
				}

				if (Math.abs(item.remaining_allawonce) >= 1000) {
					item.remaining_allawonce /= 1024;
					item.remaining_allowance_unit_code = UnitCode.GB;
				} else {
					item.remaining_allowance_unit_code = UnitCode.MB;
				}

				if (item.remaining_allawonce.toString().includes('.')) {
					item.remaining_allawonce = this.round(item.remaining_allawonce);
				}
				/** check if the type is voice */
			} else if (
				type.includes(BundleType.Voice) ||
				type.includes(BundleType.Fixed) ||
				type.includes(BundleType.International)
			) {
				this.calculateMinutes(item);

				/** check if the type is voice */
			} else if (type.includes(BundleType.SMS)) {
				item.remaining_allowance_unit_code = UnitCode.SMS;
			}
			this.calculatePercentage(item);
		} else {
			item.percentage = 100;
		}
	}
	private addNewBundle(bundleType: BundleType) {
		const bundle: Bundle = {
			bundleType: bundleType,
			data: null,
		};
		this.bundles.push(bundle);
	}

	public filterByBundleType(value: string, allData: Dashboard[]): boolean {
		let result = false;
		let index = 0;
		if (value.toLowerCase() !== BundleType.International.toLowerCase()) {
			while (index < allData.length && !result) {
				if (allData[index].type.toLowerCase().includes(value.toLowerCase())) {
					result = true;
				}
				index++;
			}
		} else {
			while (index < allData.length && !result) {
				if (
					allData[index].group !== undefined &&
					allData[index].group !== null &&
					allData[index].group.toLowerCase().includes(value.toLowerCase()) &&
					allData[index].type.toLowerCase().includes(BundleType.Voice.toLowerCase())
				) {
					result = true;
				}
				index++;
			}
		}
		return result;
	}

	/** get first basic category for voice or sms */
	public getFirstBasicCategory(bundleType: BundleType): Dashboard {
		let data: Dashboard = null;
		let index = 0;
		if (
			this.currentService.type.toLowerCase() === ServiceType.VodafoneEnTuCasa.toLowerCase() &&
			bundleType === BundleType.Voice
		) {
			return this.getVoiceBundleForTuCasaService();
		}
		/** try to get first basic */
		while (index < this.allData.length && !data) {
			if (
				this.allData[index].type &&
				this.allData[index].type.toLowerCase() === bundleType.toLowerCase() &&
				this.allData[index].category &&
				this.allData[index].category.toLowerCase() === BundleCategory.Basic.toLowerCase() &&
				(!this.allData[index].group ||
					(this.allData[index].group && this.allData[index].group.toLowerCase() !== PlanType.yu.toLowerCase()))
			) {
				data = this.allData[index];
			}
			index++;
		}
		if (!data) {
			data = this.allData.find((bundle) => {
				return (
					bundle.type &&
					bundle.type.toLowerCase() === bundleType.toLowerCase() &&
					bundle.category &&
					bundle.category.toLowerCase() === BundleCategory.Basic.toLowerCase()
				);
			});
		}
		if (!data) {
			index = 0;
			/** try to get first bundle that has same bundle type parameter */
			while (index < this.allData.length && !data) {
				if (this.allData[index].type.toLowerCase() === bundleType.toLowerCase()) {
					data = this.allData[index];
				}
				index++;
			}
		}
		if (data) {
			if (data.type.toLowerCase() === BundleType.Voice.toLowerCase()) {
				this.calculateMinutes(data);
			} else {
				data.used_allowance = Math.trunc(data.used_allowance);
				data.available_allowance = Math.trunc(data.available_allowance);
				data.remaining_allawonce = data.available_allowance - data.used_allowance;
				data.available_allowance_unit_code = data.remaining_allowance_unit_code = UnitCode.SMS;
			}

			this.calculatePercentage(data);
		}
		/** if no data found the will return null */
		return data;
	}
	getVoiceBundleForTuCasaService(): Dashboard {
		let result: Dashboard;
		/** try to get first basic mobile bundle */
		result = this.allData.find((bundle) => {
			return (
				bundle.type &&
				bundle.type.toLowerCase() === BundleType.Voice.toLowerCase() &&
				(!bundle.category ||
					(bundle.category && bundle.category.toLowerCase() === BundleCategory.Basic.toLowerCase())) &&
				(!bundle.group || (bundle.group && bundle.group.toLowerCase() !== PlanType.yu.toLowerCase()))
			);
		});

		/** if first basic mobile bundle not found */
		if (!result) {
			/** try to get first basic fixed bundle */
			result = this.allData.find((bundle) => {
				return (
					bundle.type &&
					bundle.type.toLowerCase() === BundleType.Fixed.toLowerCase() &&
					(!bundle.category ||
						(bundle.category && bundle.category.toLowerCase() === BundleCategory.Basic.toLowerCase())) &&
					(!bundle.group || (bundle.group && bundle.group.toLowerCase() !== PlanType.yu.toLowerCase()))
				);
			});
		}

		if (result) {
			this.calculateMinutes(result);
			this.calculatePercentage(result);
		}

		return result;
	}

	/** get first voice or fixed bundle type for fixed line */
	public getFirstVoiceOrFixed(bundleType: BundleType): Dashboard {
		let data: Dashboard = null;
		let index = 0;
		if (bundleType === BundleType.Voice) {
			while (index < this.allData.length && !data) {
				if (
					(!this.allData[index].group || !this.allData[index].group.toLowerCase().includes(BundleType.International)) &&
					this.allData[index].type.toLowerCase().includes(BundleType.Voice.toLowerCase())
				) {
					data = this.allData[index];
				}
				index++;
			}
		} else {
			while (index < this.allData.length && !data) {
				if (this.allData[index].type.toLowerCase().includes(bundleType.toLowerCase())) {
					data = this.allData[index];
				}
				index++;
			}
		}
		if (data) {
			this.calculateMinutes(data);
		}
		return data;
	}

	private calculateMinutes(data: Dashboard) {
		if (data.isLimited) {
			data.remaining_allawonce = data.available_allowance - data.used_allowance;
			data.available_allowance /= 60;
			data.remaining_allawonce /= 60;
			data.available_allowance = Math.trunc(data.available_allowance);
			data.remaining_allawonce = Math.trunc(data.remaining_allawonce);
			data.available_allowance_unit_code = data.available_allowance > 1 ? UnitCode.Mins : UnitCode.Min;
			data.remaining_allowance_unit_code = data.remaining_allawonce > 1 ? UnitCode.Mins : UnitCode.Min;
		}

		this.calculatePercentage(data);
	}

	private getVoiceInternational(): Dashboard {
		let data: Dashboard = null;
		this.allData.map((item) => {
			if (
				item.group &&
				item.group.toLowerCase().includes(BundleType.International.toLowerCase()) &&
				item.type.toLowerCase().includes(BundleType.Voice.toLowerCase()) &&
				item.isLimited
			) {
				if (!data) {
					data = item;
				} else {
					data.available_allowance += item.available_allowance;
					data.used_allowance += item.used_allowance;
				}
			}
		});

		if (!data) {
			this.allData.map((item) => {
				if (
					item.group &&
					item.group.toLowerCase().includes(BundleType.International.toLowerCase()) &&
					item.type.toLowerCase().includes(BundleType.Voice.toLowerCase())
				) {
					if (!data) {
						data = item;
					}
				}
			});
		}
		if (data) {
			this.calculateMinutes(data);
		}

		return data;
	}

	/** get data for mobile and mbb */
	public getDataMobileOrMbb() {
		let data: Dashboard = null;
		let sumAvailable = 0;
		let sumUsed = 0;
		let sumRemaining = 0;

		/** false here means that this is member */
		this.isLeader = false;
		this.isShared = false;
		this.allDataLeader = [];
		this.allDataMembers = [];
		if (
			this.currentService.type.toLowerCase().includes(ServiceType.Postpaid.toLowerCase()) ||
			this.currentService.type.toLowerCase().includes(ServiceType.VodafoneEnTuCasa.toLowerCase())
		) {
			this.allDataLeader = this.allData.filter(
				(data) =>
					data.isShared &&
					data.dataSharingType &&
					data.dataSharingType.toLowerCase() === DataSharingType.owner.toLowerCase() &&
					data.isLimited &&
					data.type &&
					data.type.toLowerCase() === BundleType.Data.toLowerCase()
			);
		}
		/** check if leader */
		if (this.allDataLeader.length) {
			this.isLeader = true;
			this.isShared = true;

			/** get all members under this leader (members here will never have any extras because I am a leader) */
			this.allDataMembers = this.allData.filter(
				(item) =>
					item.isShared === true &&
					item.dataSharingType &&
					item.dataSharingType.toLowerCase() === DataSharingType.member.toLowerCase() &&
					item.type &&
					item.type.toLowerCase() === BundleType.Data.toLowerCase()
			);

			/** sum total and used (this total will be displayed in the gauge) */
			this.allDataLeader.forEach((item) => {
				if (
					((item.category.toLowerCase() === BundleCategory.Basic.toLowerCase() ||
						item.category.toLowerCase().replace(' ', '') === BundleCategory.WorryFree.toLowerCase()) &&
						(!item.group || item.group.toLowerCase() === 'null')) ||
					((item.category.toLowerCase() === BundleCategory.Monthly.toLowerCase() ||
						item.category.toLowerCase().replace(' ', '') === BundleCategory.Adhoc.toLowerCase()) &&
						item.group &&
						item.group.toLowerCase() === BundleCategory.Extra.toLowerCase() &&
						item.isLimited)
				) {
					if (data) {
						sumAvailable += item.available_allowance;
					} else {
						data = item;
						sumAvailable = +item.available_allowance;
					}
					sumRemaining += item.available_allowance - item.used_allowance;
				}
			});
			/** till now I have total and remaining that will display in the gauge */
			/** data default value was null, Then if data exists it means that it will be isLimited=true */
			if (data) {
				data.available_allowance = sumAvailable;
				data.remaining_allawonce = sumRemaining;
				data.used_allowance = data.available_allowance - data.remaining_allawonce;
				this.calculateUnitCodeForData(data);
			}

			return data;
		} else if (
			/** check if the service is mobile postpaid or mbb postpaid then maybe this is members user */
			this.currentService.type.toLowerCase().includes(ServiceType.Postpaid.toLowerCase()) ||
			this.currentService.type.toLowerCase().includes(ServiceType.MbbPostpaid.toLowerCase()) ||
			this.currentService.type.toLowerCase().includes(ServiceType.VodafoneEnTuCasa.toLowerCase())
		) {
			{
				/** check if member */
				const memberBundle = this.allData.find(
					(data) =>
						data.isShared &&
						data.dataSharingType.toLowerCase() === DataSharingType.member.toLowerCase() &&
						data.isLimited &&
						data.type &&
						data.type.toLowerCase() === BundleType.Data.toLowerCase()
				);
				if (memberBundle) {
					this.isLeader = false; // because he will be member
					this.isShared = true;

					/** check if he has extras */
					const isOneFamiliaMember = this.allData.find(
						(data) =>
							data.isEligibleForSharing &&
							data.isLimited &&
							data.type &&
							data.type.toLowerCase() === BundleType.Data.toLowerCase()
					);

					/** sum total and used (this total will be displayed in the gauge) */
					this.allData
						.filter((el) => el.type && el.type.toLowerCase() === BundleType.Data.toLowerCase() && el.isLimited)
						.forEach((item) => {
							if (isOneFamiliaMember) {
								if (data) {
									sumAvailable += item.available_allowance;
									sumUsed += item.used_allowance;
								} else {
									data = item;
									sumAvailable = +item.available_allowance;
									sumUsed = +item.used_allowance;
								}
								if (
									item.isShared &&
									item.dataSharingType &&
									item.dataSharingType.toLowerCase() === DataSharingType.member.toLowerCase()
								) {
									/** because here the remaining must calculated in the back-end with another equation */
									sumRemaining += item.remaining_allawonce;
								} else {
									sumRemaining += item.available_allowance - item.used_allowance;
								}
							} else if (
								(item.isShared &&
									item.dataSharingType &&
									item.dataSharingType.toLowerCase() === DataSharingType.member.toLowerCase()) ||
								(data.category &&
									(item.category.toLowerCase() === BundleCategory.Monthly.toLowerCase() ||
										item.category.toLowerCase().replace(' ', '') === BundleCategory.Adhoc.toLowerCase()) &&
									item.group &&
									item.group.toLowerCase() === BundleCategory.Extra.toLowerCase())
							) {
								if (data) {
									sumAvailable += item.available_allowance;
									sumUsed += item.used_allowance;
								} else {
									data = item;
									sumAvailable = +item.available_allowance;
									sumUsed = +item.used_allowance;
								}
								if (
									item.isShared &&
									item.dataSharingType &&
									item.dataSharingType.toLowerCase() === DataSharingType.member.toLowerCase()
								) {
									/** because here the remaining must calculated in the back-end with another equation */
									sumRemaining += item.remaining_allawonce;
								} else {
									sumRemaining += item.available_allowance - item.used_allowance;
								}
							}
						});
					/** till now I have total and remaining that will display in the gauge */
					/** data default value was null, Then if data exists it means that it will be isLimited=true */
					if (data) {
						data.available_allowance = sumAvailable;
						data.used_allowance = sumUsed;
						data.remaining_allawonce = sumRemaining;
						this.calculateUnitCodeForData(data);
					}

					return data;
				}
			}
		}

		this.allData
			.filter((data) => data.category && data.type && data.type.toLowerCase() === BundleType.Data.toLowerCase())
			.forEach((item) => {
				if (
					((item.category.toLowerCase() === BundleCategory.Basic.toLowerCase() ||
						item.category.toLowerCase().replace(' ', '') === BundleCategory.WorryFree.toLowerCase()) &&
						(!item.group || item.group.toLowerCase() === 'null')) ||
					((item.category.toLowerCase() === BundleCategory.Monthly.toLowerCase() ||
						item.category.toLowerCase().replace(' ', '') === BundleCategory.Adhoc.toLowerCase()) &&
						item.group &&
						item.group.toLowerCase() === BundleCategory.Extra.toLowerCase() &&
						item.isLimited)
				) {
					const consumed =
						+item.used_allowance > +item.available_allowance ? +item.available_allowance : +item.used_allowance;
					if (data) {
						sumAvailable += item.available_allowance;
						sumUsed += consumed;
					} else {
						data = item;
						sumAvailable = +item.available_allowance;
						sumUsed = consumed;
					}
				}
			});

		/** calculate remaining and percentage if and only if the data is limited */
		if (data) {
			data.available_allowance = sumAvailable;
			data.used_allowance = sumUsed;
			if (data.isLimited) {
				/** calculate remaining */
				data.remaining_allawonce = data.available_allowance - data.used_allowance;
				this.calculateUnitCodeForData(data);
			}
			if (this.destinyService.hasUnlimited) {
				data.isLimited = false;
			}
		}

		return data;
	}
	/** calculate and convert data unit code (MB and GB) */
	public calculateUnitCodeForData(data) {
		/** the unit code will returned as a MB only */
		if (data.available_allowance >= 1000) {
			data.available_allowance /= 1024;
			data.available_allowance_unit_code = UnitCode.GB;
		} else {
			data.available_allowance_unit_code = UnitCode.MB;
		}

		if (data.remaining_allawonce >= 1000) {
			data.remaining_allawonce /= 1024;
			data.remaining_allowance_unit_code = UnitCode.GB;
		} else {
			data.remaining_allowance_unit_code = UnitCode.MB;
		}
		/** set used unit code for the leader (Administrator) */
		if (data.used_allowance >= 1000) {
			data.used_allowance /= 1024;
			data.used_allowance_unit_code = UnitCode.GB;
		} else {
			data.used_allowance_unit_code = UnitCode.MB;
		}

		if (data.available_allowance.toString().includes('.')) {
			data.available_allowance = this.round(data.available_allowance);
		}
		if (data.remaining_allawonce.toString().includes('.')) {
			data.remaining_allawonce = this.round(data.remaining_allawonce);
		}
		if (data.used_allowance.toString().includes('.')) {
			data.used_allowance = this.round(data.used_allowance);
		}
		this.calculatePercentage(data);
	}

	/** calculate remaining percentage */
	public calculatePercentage(data: Dashboard) {
		const remaining =
			data.remaining_allowance_unit_code &&
			data.remaining_allowance_unit_code.toLowerCase() === UnitCode.MB.toLowerCase()
				? data.remaining_allawonce
				: data.remaining_allawonce * 1024;
		const available =
			data.available_allowance_unit_code &&
			data.available_allowance_unit_code.toLowerCase() === UnitCode.MB.toLowerCase()
				? data.available_allowance
				: data.available_allowance * 1024;
		data.percentage = (data.isLimited ? Math.trunc((remaining / available) * 100) : 100) || 0;
		/** to draw the gauge's circle */
		if (data.percentage < 0) {
			data.percentage = 0;
		}
	}

	/** get internet bundle type for internet adsl or fibre */
	public getInternet(bundleType: BundleType, allData?: Dashboard): Dashboard {
		let data: Dashboard = allData;
		let index = 0;
		while (index < this.allData.length && !data) {
			if (this.allData[index].type.toLowerCase() === bundleType.toLowerCase()) {
				data = this.allData[index];
			}
			index++;
		}
		if (data) {
			data.isLimited = false;
			data.percentage = 100;
		}
		return data;
	}

	public getTotalTV() {
		const data: Dashboard = new Dashboard();
		let tvCount = 0;
		data.remaining_allawonce = 0;
		for (const item of this.allData) {
			if (item.type.toLowerCase() === BundleType.TV.toLowerCase()) {
				tvCount += item.available_allowance;
			}
		}
		data.type = BundleType.TV;
		data.remaining_allawonce = tvCount;
		return data;
	}

	public getOriginalData(bundleType: BundleType = null) {
		if (bundleType !== null) {
			if (bundleType.toLowerCase() === BundleType.International.toLowerCase()) {
				const arr = this.fixedAllData.filter((data) => {
					return (
						data.type &&
						data.group &&
						data.type.toLowerCase() === BundleType.Voice &&
						data.group.toLowerCase() === bundleType.toLowerCase()
					);
				});
				return this.utilitiesService.getNewDataRef(arr);
			} else {
				const arr = this.fixedAllData.filter((data) => data.type.toLowerCase() === bundleType.toLowerCase());
				return this.utilitiesService.getNewDataRef(arr);
			}
		} else {
			return this.utilitiesService.getNewDataRef(this.fixedAllData);
		}
	}

	public round(amount: number) {
		const decimals = amount.toString().substr(amount.toString().indexOf('.'), 3);
		return parseFloat(Math.trunc(amount) + decimals);
	}

	public GetBundlesData() {
		this.bundle = new OutOfBundle();
		this.bundle.bundles = new Array<OutOfBundleModel>();
		this.fixedAllData.map((item) => {
			item.services.map((subscription) => {
				let flag = false;
				if (subscription.id === this.subscriptionService.customerData.currentService.id) {
					flag = true;
				}
				if (flag) {
					this.bundle.totalAmount += parseFloat(item.amount.toString());
					if (this.bundle.totalAmount.toString().includes('.')) {
						this.bundle.totalAmount = this.round(this.bundle.totalAmount);
					}
					const bundle = new OutOfBundleModel();
					bundle.amount = item.amount;
					bundle.name = item.name;
					switch (item.type.toLowerCase()) {
						case BundleType.Voice.toLowerCase():
							bundle.icon = IconClassNames.icon_landline;
							break;
						case BundleType.SMS.toLowerCase():
							bundle.icon = IconClassNames.icon_mail;

							break;
						case BundleType.Data.toLowerCase():
							bundle.icon = IconClassNames.icon_data;
							break;
						default:
							break;
					}

					this.bundle.bundles.push(bundle);
				}
			});
		});

		this.ViewBundle();
	}

	public GetServicesData() {
		this.serviceBundles = new OutOfBundle();
		this.serviceBundles.bundles = new Array<OutOfBundleModel>();
		this.outOfBundles.map((item) => {
			if (
				item.services[0].type.toLowerCase() !== ServiceType.Internet.toLowerCase() ||
				item.services[0].type.toLowerCase() !== ServiceType.Prepaid.toLowerCase() ||
				item.services[0].type.toLowerCase() !== ServiceType.MbbPrepaid.toLowerCase()
			) {
				if (this.serviceBundles.bundles.length > 0) {
					this.serviceBundles.bundles.map((el) => {
						let serviceBundle = new OutOfBundleModel();
						serviceBundle = this.serviceBundles.bundles.find(
							(el) => el.type.toLowerCase() === item.services[0].type.toLowerCase()
						);
						if (serviceBundle) {
							this.serviceBundles.bundles.map((el) => {
								if (el.type.toLowerCase() === item.services[0].type.toLowerCase()) {
									el.amount += parseFloat(item.amount.toString());
								}
							});
						} else {
							serviceBundle = new OutOfBundleModel();
							serviceBundle.amount = item.amount;
							serviceBundle.name = item.services[0].type;
							serviceBundle.id = item.services[0].id;
							serviceBundle.type = item.services[0].type;

							if (
								serviceBundle.type.toLowerCase() !== ServiceType.Tv.toLowerCase() &&
								serviceBundle.type.toLowerCase() !== ServiceType.Internet.toLowerCase()
							) {
								serviceBundle.showDots = true;
							}
							switch (item.type.toLowerCase()) {
								case ServiceType.Postpaid.toLowerCase():
									serviceBundle.icon = IconClassNames.icon_mobile_postpaid;
									break;
								case ServiceType.Tv.toLowerCase():
									serviceBundle.icon = IconClassNames.icon_tv;
									break;
								case ServiceType.MbbPostpaid.toLowerCase():
									serviceBundle.icon = IconClassNames.icon_mbb;
									break;
								case ServiceType.Landline.toLowerCase():
									serviceBundle.icon = IconClassNames.icon_busines_phone;
									break;
								default:
									break;
							}

							this.serviceBundles.bundles.push(serviceBundle);
						}
					});
				} else {
					const serviceBundle = new OutOfBundleModel();
					serviceBundle.amount = item.amount;
					serviceBundle.name = item.services[0].type;
					serviceBundle.id = item.services[0].id;
					serviceBundle.type = item.services[0].type;

					switch (item.type.toLowerCase()) {
						case ServiceType.Postpaid.toLowerCase():
							serviceBundle.icon = IconClassNames.icon_mobile_postpaid;
							break;
						case ServiceType.Tv.toLowerCase():
							serviceBundle.icon = IconClassNames.icon_tv;
							break;
						case ServiceType.MbbPostpaid.toLowerCase():
							serviceBundle.icon = IconClassNames.icon_mbb;
							break;
						case ServiceType.Landline.toLowerCase():
							serviceBundle.icon = IconClassNames.icon_busines_phone;
							break;
						default:
							break;
					}

					if (
						serviceBundle.type.toLowerCase() !== ServiceType.Tv.toLowerCase() &&
						serviceBundle.type.toLowerCase() !== ServiceType.Internet.toLowerCase()
					) {
						serviceBundle.showDots = true;
					}

					this.serviceBundles.bundles.push(serviceBundle);
				}
			}
		});
	}

	ViewBundle() {
		const userProfile: UserProfile = this.auth.getUserProfile(
			this.storageService.getLocalStorage(LOCAL_STORAGE_KEYS.JWT)[0]
		);
		const userType = new Array<string>();
		userType.push(userProfile.customerType);

		if (
			userProfile.profileType.toLowerCase() === ProfileType.LIGHT.toLowerCase() &&
			this.subscriptionService.customerData.SiteType.toLowerCase() === SiteType.Postpaid.toLowerCase() &&
			userType.includes(CustomerType.Consumer.toLowerCase())
		) {
			if (
				this.subscriptionService.customerData.currentService.type.toLowerCase() ===
					ServiceType.MbbPostpaid.toLowerCase() ||
				this.subscriptionService.customerData.currentService.type.toLowerCase() ===
					ServiceType.MbbPrepaid.toLowerCase() ||
				this.subscriptionService.customerData.currentService.type.toLowerCase() === ServiceType.Postpaid.toLowerCase()
			) {
				this.bundle.showLockIcon = true;
			} else {
				this.bundle.showLockIcon = false;
			}
		}
		if (this.subscriptionService.customerData.SiteType) {
			if (
				(userProfile.profileType.toLowerCase() === ProfileType.LIGHT.toLowerCase() &&
					this.subscriptionService.customerData.SiteType.toLowerCase() === SiteType.Postpaid.toLowerCase() &&
					userType.includes(CustomerType.Consumer.toLowerCase())) ||
				(userProfile.profileType.toLowerCase() === ProfileType.LIGHT.toLowerCase() &&
					userType.includes(CustomerType.Employee.toLowerCase()))
			) {
				if (
					this.subscriptionService.customerData.currentService.type.toLowerCase() ===
						ServiceType.Postpaid.toLowerCase() ||
					this.subscriptionService.customerData.currentService.type.toLowerCase() ===
						ServiceType.MbbPostpaid.toLowerCase() ||
					this.subscriptionService.customerData.currentService.type.toLowerCase() ===
						ServiceType.MbbPrepaid.toLowerCase()
				) {
					this.bundle.display = true;
				}
			} else if (
				userProfile.profileType.toLowerCase() === ProfileType.COMPLETE.toLowerCase() &&
				this.subscriptionService.customerData.SiteType.toLowerCase() === SiteType.Postpaid.toLowerCase() &&
				userType.includes(CustomerType.Consumer.toLowerCase())
			) {
				if (
					this.subscriptionService.customerData.currentService.type.toLowerCase() !== ServiceType.Internet.toLowerCase()
				) {
					this.bundle.display = true;
				}
			} else if (
				userProfile.profileType.toLowerCase() !== ProfileType.NETWORK.toLowerCase() &&
				this.subscriptionService.customerData.SiteType.toLowerCase() === SiteType.Prepaid.toLowerCase() &&
				userType.includes(CustomerType.Consumer.toLowerCase())
			) {
				// out of scope this sprint
				this.bundle.display = true;
			} else if (
				userProfile.profileType.toLowerCase() === ProfileType.COMPLETE.toLowerCase() &&
				userType.includes(CustomerType.Authorized.toLowerCase())
			) {
				if (
					this.subscriptionService.customerData.currentService.type.toLowerCase() !==
						ServiceType.Prepaid.toLowerCase() ||
					this.subscriptionService.customerData.currentService.type.toLowerCase() === ServiceType.Internet.toLowerCase()
				) {
					this.bundle.display = true;
				}
			}
		}
	}

	viewBillButton() {
		const userProfile: UserProfile = this.auth.getUserProfile(
			this.storageService.getLocalStorage(LOCAL_STORAGE_KEYS.JWT)[0]
		);
		const userType = new Array<string>();

		if (
			userProfile.profileType.toLowerCase() === ProfileType.LIGHT.toLowerCase() &&
			userType.includes(CustomerType.Employee.toLowerCase())
		) {
			if (
				this.subscriptionService.customerData.currentService.type.toLowerCase() ===
					ServiceType.Postpaid.toLowerCase() ||
				this.subscriptionService.customerData.currentService.type.toLowerCase() ===
					ServiceType.MbbPostpaid.toLowerCase() ||
				this.subscriptionService.customerData.currentService.type.toLowerCase() === ServiceType.MbbPrepaid.toLowerCase()
			) {
				this.bundle.showBillButton = false;
			}
		}
	}

	getOwnerSharingData() {
		return this.getServiceBalance().pipe(
			map((data) => {
				for (let index = 0; index < data.length; index++) {
					if (data[index].bundleType.toLowerCase() === BundleType.Data.toLowerCase()) {
						this.dataIdex = index;
					}
				}
				this.checkIfOwnerSharingData();
				if (this.memberAllData) {
					if (
						this.memberAllData.find(
							(data) =>
								data.dataSharingType && data.dataSharingType.toLowerCase() === DataSharingType.owner.toLowerCase()
						)
					) {
						return true;
					} else {
						return false;
					}
				} else {
					return false;
				}
			})
		);
	}

	getFormattedNumber(amount: number, content?: Dashboard) {
		if (
			content &&
			content.type &&
			amount &&
			amount.toString().includes('.') &&
			content.type.toLowerCase() === BundleType.Data.toLowerCase()
		) {
			return this.getDataBundleNumbersFormat(amount);
		} else if (
			content &&
			content.group &&
			amount &&
			content.group.toLowerCase() === CallType.International.toLowerCase()
		) {
			// For International Bundles, split the amount with .
			return amount.toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1.');
		}
		return amount;
	}

	getDataBundleNumbersFormat(amount: number) {
		const splittedRoundedNumber = this.round(amount).toString().split('.');
		const decimals = splittedRoundedNumber[1];
		if (decimals.length > 1 && decimals[1] === '0') {
			return splittedRoundedNumber[0] + ',' + decimals[0];
		}
		return splittedRoundedNumber.join(',');
	}
	checkIfOwnerSharingData() {
		this.recalculateConsumptionAfterDatasharingBinded();
		this.ownerCalculationLogic();
	}

	private ownerCalculationLogic() {
		let limitOfMaxPointMena;
		if (
			(this.memberAllData &&
				this.memberAllData['0'].services['0'].id.toString() !== this.content.services['0'].id.toString()) ||
			!this.memberAllData ||
			this.subscriptionService.memberAllDataUpdated
		) {
			this.subscriptionService.memberAllDataUpdated = false;
			if (this.content.available_allowance_unit_code.toLowerCase() === UnitCode.GB.toLowerCase()) {
				limitOfMaxPointMena = this.content.available_allowance * 1024;
			} else {
				limitOfMaxPointMena = this.content.available_allowance;
			}
			if (this.allDataMembers.length > 0) {
				this.allDataMembers.forEach(function (element) {
					if (element.available_allowance === 0) {
						element.isSameLimitOfLeader = true;
					} else {
						element.precentageForMember = Math.round((element.available_allowance / limitOfMaxPointMena) * 100);
						element.MaxpointPrecentageStop = (element.available_allowance / limitOfMaxPointMena) * 360 - 90;
						element.precentageForUsedMember = Math.round(
							(element.used_allowance / element.available_allowance) *
								(element.available_allowance / limitOfMaxPointMena) *
								100
						);
					}
					element = this.getUnitCodeForData(element);
				}, this);
			}
			this.content.isSameLimitOfLeader = true;
			this.content.precentageForMember = 100;
			this.memberAllData = Array(this.content).concat(this.allDataMembers);
			this.memberAllData.forEach(function (element) {
				if (element.isSameLimitOfLeader) {
					element.precentageForMember = 100;

					element.precentageForUsedMember =
						element.used_allowance_unit_code === UnitCode.MB
							? Math.round((element.used_allowance / limitOfMaxPointMena) * 100)
							: Math.round(((element.used_allowance * 1024) / limitOfMaxPointMena) * 100);
					element.MaxpointPrecentageStop = -90;
				}
				element.used_allowance = this.getFormattedNumber(element.used_allowance, this.content);
				element.available_allowance = this.getFormattedNumber(element.available_allowance, this.content);
			}, this);
		}
	}

	recalculateConsumptionAfterDatasharingBinded() {
		// get sum of members consumption values
		let sumDataSharingConsumtopnInMBForMembers = 0;
		this.allDataMembers.forEach((bundle: Dashboard) => {
			sumDataSharingConsumtopnInMBForMembers +=
				bundle.used_allowance_unit_code.toLowerCase() === UnitCode.GB.toLowerCase()
					? +bundle.used_allowance.toString().replace(',', '.') * 1024
					: +bundle.used_allowance.toString().replace(',', '.');
		});
		// total available value to consume
		const sumLeaderAvailableInMB =
			this.content.available_allowance_unit_code.toLowerCase() === UnitCode.GB.toLowerCase()
				? +this.content.available_allowance.toString().replace(',', '.') * 1024
				: +this.content.available_allowance.toString().replace(',', '.');
		// remaining value which not consumed yet
		const sumLeaderRemainingInMB =
			this.content.remaining_allowance_unit_code.toLowerCase() === UnitCode.GB.toLowerCase()
				? +this.content.remaining_allawonce.toString().replace(',', '.') * 1024
				: +this.content.remaining_allawonce.toString().replace(',', '.');

		// get "leader" comsumption value
		this.content.used_allowance =
			sumLeaderAvailableInMB - (sumDataSharingConsumtopnInMBForMembers + sumLeaderRemainingInMB);
		// check if used consumption is bigger than 1000, the unit of number will be in GIGABYTES, else unit will be MEGABYTES
		if (this.content.used_allowance >= 1000) {
			this.content.used_allowance /= 1024;
			this.content.used_allowance_unit_code = UnitCode.GB;
		} else {
			this.content.used_allowance_unit_code = UnitCode.MB;
		}
	}

	getUnitCodeForElement(allowence: number, unitcode: UnitCode): { allowence: number; unitcode: UnitCode } {
		if (allowence < 1024) {
			allowence = allowence;
			unitcode = UnitCode.MB;
		} else {
			allowence = allowence / 1024;
			unitcode = UnitCode.GB;
		}
		return { allowence, unitcode };
	}
	getUnitCodeForData(data: Dashboard) {
		const available_allowance_edited = this.getUnitCodeForElement(
			data.available_allowance,
			data.available_allowance_unit_code
		);
		data.available_allowance = available_allowance_edited.allowence;
		data.available_allowance_unit_code = available_allowance_edited.unitcode;
		const available_used_edited = this.getUnitCodeForElement(data.used_allowance, data.used_allowance_unit_code);
		data.used_allowance = available_used_edited.allowence;
		data.used_allowance_unit_code = available_used_edited.unitcode;
		const available_remaining_edited = this.getUnitCodeForElement(
			data.remaining_allawonce,
			data.remaining_allowance_unit_code
		);
		data.remaining_allawonce = available_remaining_edited.allowence;
		data.remaining_allowance_unit_code = available_remaining_edited.unitcode;
		return data;
	}
	setCurrentService() {
		this.currentService = this.subscriptionService.customerData.currentService;
	}
	private getNumber(amount: string) {
		return +amount.replace(',', '.');
	}

	public checkOob() {
		const listServices = this.subscriptionData.customerData.services;
		if (
			listServices.length > 0 &&
			(this.auth.hasRole(CustomerType.Authorized.toLowerCase()) ||
				this.subscriptionData.customerData.SiteType.toLowerCase() === SiteType.Postpaid.toLowerCase())
		) {
			return true;
		}
		if (this.subscriptionData.customerData.SiteType.toLowerCase() === SiteType.Prepaid.toLowerCase()) {
			if (
				listServices.find(
					(el) =>
						el.type.toLowerCase() !== ServiceType.Prepaid.toLowerCase() &&
						(el.type.toLowerCase() !== ServiceType.ADSL.toLowerCase() ||
							el.type.toLowerCase() !== ServiceType.Fibre.toLowerCase())
				)
			) {
				return true;
			} else {
				return false;
			}
		}
		if (
			listServices.find((el) => el.type.toLowerCase() === ServiceType.Prepaid.toLowerCase()) &&
			listServices.find(
				(el) =>
					el.type.toLowerCase() === ServiceType.ADSL.toLowerCase() ||
					el.type.toLowerCase() === ServiceType.Fibre.toLowerCase() ||
					el.type.toLowerCase() === ServiceType.Internet.toLowerCase()
			) &&
			!listServices.find(
				(el) =>
					el.type.toLowerCase() !== ServiceType.Prepaid.toLowerCase() &&
					el.type.toLowerCase() !== ServiceType.ADSL.toLowerCase() &&
					el.type.toLowerCase() !== ServiceType.Internet.toLowerCase() &&
					el.type.toLowerCase() !== ServiceType.Fibre.toLowerCase()
			)
		) {
			return false;
		}
	}

	getBadge(): Observable<ProducOrders[]> {
		const date: string[] = this.setDateGet();
		const url: string = API_URLS.ProductOrderingManagement.ProductOrderBadgeDashboard.replace(
			'{dni}',
			this.storageService?.userProfile?.document?.id
		)
			.replace('{fromdate}', date[0])
			.replace('{todate}', date[1]);
		const headers: HttpHeaders = new HttpHeaders({
			'Content-Type': 'application/json',
			'X-VF-API-Process': 'QueryVisibleOT',
		});
		const params: Params = {
			[`relatedParty[?(@role=='Customer')].id`]:
				this.subscriptionData.customerData?.currentService?.siteId || this.storageService.userProfile?.sites?.[0].id,
		};
		for (const paramKey in params) {
			if (!params[paramKey]) {
				delete params[paramKey];
			}
		}
		return this.http
			.get(url, { headers, params: new HttpParams({ encoder: new ParamsEncoder(), fromObject: params }) })
			.pipe(map((res: ProducOrders[]) => res || []));
	}

	public setDateGet(): string[] {
		const now: Date = new Date();
		const date: moment.Moment = moment();
		const fromData: string = date.subtract(1, monthMoment).toISOString();
		const toDate: string = now.toISOString();
		return [fromData, toDate];
	}
}
