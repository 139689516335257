import {
	AfterViewChecked,
	ChangeDetectorRef,
	Component,
	ComponentFactoryResolver,
	OnInit,
	ViewChild,
	ViewContainerRef,
} from '@angular/core';
import { Mva10SubHeaderService } from '../../services/mva10-sub-header.service';

@Component({
	selector: 'sp-mva10-sub-header',
	templateUrl: './mva10-sub-header.component.html',
})
export class Mva10SubHeaderComponent implements OnInit, AfterViewChecked {
	/** component: this is the property that will deal with the dynamic component */
	component: any;
	/** isLoadContent: to prevent recreation for dynamic component */
	isLoadContent = false;
	/** the reference of recieved components */
	@ViewChild('dynamicComponentRef', { read: ViewContainerRef }) dynamicComponentRef: ViewContainerRef;

	constructor(
		public mva10SubHeaderService: Mva10SubHeaderService,
		private resolver: ComponentFactoryResolver,
		private cd: ChangeDetectorRef
	) {}

	ngOnInit() {
		this.mva10SubHeaderService.showMva10SubHeaderSubject.subscribe((keepState) => {
			if (!keepState) {
				this.component = null;
			}
		});
	}

	/** This method for dynamic component */
	showMva10SubHeader(component) {
		this.component = component;
		this.isLoadContent = true;
	}

	ngAfterViewChecked(): void {
		if (this.isLoadContent) {
			this.isLoadContent = false;
			const compFactory = this.resolver.resolveComponentFactory(this.component);
			this.dynamicComponentRef.clear();
			this.dynamicComponentRef.createComponent(compFactory);
			this.cd.detectChanges();
		}
	}
}
