export class HistoricoOL {
	estado: string;
	fecha?: number;
	descripcion?: string;
	slaSuperado?: boolean;
}

export interface PedidoHistoricoOLResponse {
	historicoOL: HistoricoOL[];
}

export class DataShippingOrders {
	idOrder?: string;
	address?: string;
	trackOrder?: string;
	dateDelivery?: string;
}
