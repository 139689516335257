export class OpenNewTicket {
	serviceId: string;
	taskId: string;
	serviceType: string;
	miwifi: string;
	platform: string;
	inputs: TicketInput[];
	platformInfo: string;
	typeConnection: string;
	chatEnabled: 'true' | 'false';
	ticketId?: string;
}

export class TicketInput {
	name: string;
	value?: string;
	values?: string[];
	id?: string;
}
