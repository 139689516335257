import { Component } from '@angular/core';
import { UpgradeProfileService } from './upgrade-profile.service';

@Component({
	selector: 'sp-upgrade-profile',
	templateUrl: './upgrade-profile.component.html',
})
export class UpgradeProfileComponent {
	constructor(public upgrade: UpgradeProfileService) {}
}
