<mva10-c-card>
	<div class="main-landing-template mva10-flex__align-items--center">
		<div class="mva10-u--1/1 mva10-u--6/12@desktop mva10-u--6/12@tablet">
			<div class="mva10-flex mva10-flex__align-items--center">
				<mva10-c-icon
					iconId="icon-dial-pad-mid"
					[title]="'navigate'"
					[size]="5"
					[appearance]="'secondary'"
				></mva10-c-icon>

				<div class="wf">
					<p class="title">{{ firstLine }}</p>
					<p>{{ secondLine }}</p>
					<div class="mva10-flex mva10-flex__align-items--center mva10-u--pl4x">
						<span class="simple-badge-bubble" [ngClass]="{ active: green, desactive: red, pending: yellow }"></span>
						<span class="mva10-u--pl2x">{{ stautsText }} </span>
					</div>
				</div>
			</div>
		</div>
		<div class="mva10-u--1/1 mva10-u--6/12@desktop mva10-u--6/12@tablet">
			<div
				class="mva10-flex mva10-flex__align-items--center mva10-flex__justify--end mva10-flex__gap--3 mva10-u--pt10x"
			>
				<sp-toggle-button
					size="0"
					*ngIf="showToggle && !ispendingSBA"
					[check]="checkToggle"
					(option)="changeStatus($event)"
				>
				</sp-toggle-button>
			</div>
		</div>
	</div>
</mva10-c-card>

<sp-modal-full
	[imgSrc]="fullModal.imgSrc"
	[spinner]="showConfigurationSpinner"
	(xButtonClicked)="drawComponent()"
	[hideX]="showConfigurationSpinner"
	#fullModal
>
	<div *ngIf="showConfigurationSpinner" class="billing-spinnerContainer">
		<div class="loader"></div>
		<p>{{ 'common.messagesList.overlayLoadingMessage.overlayLoadingMessage_description' | translate }}</p>
	</div>
</sp-modal-full>
