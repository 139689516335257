import { Component, EventEmitter, Input, OnInit, Output, ViewEncapsulation } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { tagging } from '../../../../config/tagging-config';
import { SubscriptionService } from '../../../core/services/subscription.service';
import { TaggingHelperService } from '../../../core/services/tagging.helper.service';
import { Validation } from '../../constants/defines';

@Component({
	selector: 'sp-alert-info',
	templateUrl: './alert-info.component.html',
	styleUrls: [
		'./alert-info.component.scss',
		'../../../shared/components/sp-double-toggle-dropdown/sp-double-toggle-dropdown.component.scss',
		'../../../shared/components/sp-double-toggle-dropdown/sp-double-toggle-dropdown.component.scss',
	],
	encapsulation: ViewEncapsulation.None,
})
export class AlertInfoComponent implements OnInit {
	@Input() hasInputField: boolean;
	@Input() generalBreakDownSecondAlert: boolean;
	@Input() icon: string;
	@Input() boldTitle: string;
	@Input() message: string;
	@Input() messageSecondary: string;
	@Input() description: string;
	@Input() showDescription: boolean;
	@Input() showLine: boolean;
	@Input() showButton: boolean;
	@Input() centerIcon: boolean;
	@Input() boldText: string;
	@Input() navigateDescription: string;
	@Input() navigateUrl: string;
	@Input() showNavigator: boolean;
	@Input() buttonAction;
	@Input() buttonText;
	@Input() choices;
	@Output() sendService = new EventEmitter();
	@Output() navigateEvent = new EventEmitter();
	@Output() intValueForDd = new EventEmitter();
	@Output() alertButtonClicked = new EventEmitter();
	@Input() alertColor: string;
	/** in some UI has padding-bottom: 24px and some cases needs to be 0 as in SWAT-372 for example */
	@Input() removeBottomPadding: boolean;
	Validation = Validation;
	public alertForm: UntypedFormGroup;
	public msisdn: string;
	choice: any;
	@Input() placeHolder: string;
	customerNumber: string;
	@Input() iconSVG: string;
	@Input() iconDSL: string;
	@Input() commercial: boolean;
	@Input() linkTextAction: string;
	@Output() linkTextActionClicked: EventEmitter<void> = new EventEmitter();
	@Input() adjustButtonDesign: boolean;
	constructor(private subscriptionService: SubscriptionService, private tagging: TaggingHelperService) {}

	ngOnInit() {
		if (this.hasInputField) {
			this.customerNumber = this.subscriptionService.customerData.currentService.id;
			this.alertForm = new UntypedFormGroup({
				msisdn: new UntypedFormControl(),
			});
		}
	}
	naigateTo(url) {
		this.navigateEvent.emit();
	}

	executeButtonAction() {
		if (this.buttonAction) {
			this.tagging.track(
				tagging.productAndServices.activatePromotion.eventName,
				tagging.productAndServices.activatePromotion.data
			);
			this.buttonAction();
			if (this.hasInputField) {
				this.buttonAction(this.alertForm.controls.msisdn.value);
			} else {
				this.buttonAction();
			}
		} else {
			this.alertButtonClicked.emit();
		}
	}

	updateForm(choice: any) {
		this.choice = choice;
	}
	checked(choice: any) {
		return this.choice === choice;
	}

	changeLineStatus(expandStatus) {
		this.showLine = !expandStatus;
	}
	selectedValue(value) {
		this.sendService.emit(value);
	}

	getInitValue(value) {
		this.intValueForDd.emit(value);
	}

	msisdnValueChanged(textboxValue: string) {
		this.msisdn = textboxValue;
	}

	linkTextActionClickedFn(): void {
		this.linkTextActionClicked.emit();
	}
}
