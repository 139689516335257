import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { AngularSvgIconModule } from 'angular-svg-icon';
import { SpServiceSelectorModalModule } from './../sp-service-selector-modal/sp-service-selector-modal.module';
import { SpSwiperComponent } from './sp-swiper.component';

@NgModule({
	declarations: [SpSwiperComponent],
	exports: [SpSwiperComponent],
	imports: [CommonModule, SpServiceSelectorModalModule, AngularSvgIconModule.forRoot()],
})
export class SpSwiperModule {}
