import { Injectable } from '@angular/core';
import { Validation } from '../../constants/defines';
import { PasswordStrength } from '../../enums/passwordStrength.enum';

@Injectable()
export class SpPasswordValidationService {
	constructor() {}
	/**
	 * function that check the strength of password
	 * @param password value to check
	 */
	passwordStrengthValidation(password: string) {
		if (password === '') {
			return PasswordStrength.EmpytText;
		}
		if (password.length >= 6) {
			if (this.hasChars(password) && this.hasNumber(password) && this.hasSpecialChars(password)) {
				return PasswordStrength.Strong;
			}
			if (
				(this.hasChars(password) && this.hasNumber(password)) ||
				(this.hasChars(password) && this.hasSpecialChars(password)) ||
				(this.hasSpecialChars(password) && this.hasNumber(password))
			) {
				return PasswordStrength.Meduim;
			} else {
				return PasswordStrength.Weak;
			}
		} else {
			return PasswordStrength.Weak;
		}
	}
	/**
	 * function to check if password contains lower case
	 * @param password
	 */
	hasLower(password: string): boolean {
		let result = false;
		let index = 0;
		while (index < password.length && !result) {
			const charToTest = password.charAt(index);
			if (!isNaN(Number(charToTest) * 1)) {
				result = false;
			} else {
				if (password.charAt(index) === password.charAt(index).toLowerCase()) {
					result = true;
				}
				if (this.hasSpecialChars(charToTest)) {
					result = false;
				}
			}
			index++;
		}
		return result;
	}
	/**
	 * function to check if password contains upper case
	 * @param password
	 */
	hasUpper(password: string): boolean {
		let result = false;
		let index = 0;
		while (index < password.length && !result) {
			const charToTest = password.charAt(index);
			if (!isNaN(Number(charToTest) * 1)) {
				result = false;
			} else {
				if (password.charAt(index) === password.charAt(index).toUpperCase()) {
					result = true;
				}
				if (this.hasSpecialChars(charToTest)) {
					result = false;
				}
			}
			index++;
		}
		return result;
	}
	/**
	 * function to check if password contains numbers
	 * @param password
	 */
	hasNumber(password: string): boolean {
		const result = password.match(Validation.Numbers);
		if (result === null) {
			return false;
		} else {
			return true;
		}
	}
	/**
	 * function to check if password contains letters
	 * @param password
	 */
	hasChars(password: string): boolean {
		const result = password.match(Validation.Char);
		if (result === null) {
			return false;
		} else {
			return true;
		}
	}
	/**
	 * function to check if password contains special chars
	 * @param password
	 */
	hasSpecialChars(password: string): boolean {
		const result = password.match(Validation.SpecialChars);
		if (result === null) {
			return false;
		} else {
			return true;
		}
	}

	passwordStrengthValidationRegex(password: string): PasswordStrength {
		if (password === '') {
			return PasswordStrength.EmpytText;
		}
		const patternStrong: RegExp = new RegExp(Validation.strongPassword);
		const patternMedium: RegExp = new RegExp(Validation.mediumPassword);
		if (patternStrong.test(password)) {
			return PasswordStrength.Strong;
		}
		if (patternMedium.test(password)) {
			return PasswordStrength.Meduim;
		} else {
			return PasswordStrength.Weak;
		}
	}
}
