import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { StorageService } from '../../core/services/storage.service';
import { SubscriptionService } from '../../core/services/subscription.service';
import { MyAccountService } from '../../my-account/my-account.service';
import { OneProfesionalService } from '../../op-products/one-profesional.service';
import { API_URLS } from '../constants/routes-config';
import { CustomerType } from '../enums/customerType.enum';
import { ServiceType } from '../enums/serviceType.enum';
import { AditionalLines } from '../models/aditional-lines.model';
import { CilckReturnModel } from '../models/clickReturn.model';
import { CombinationModel } from '../models/combination.model';
import { OfferModel } from '../models/offer.model';
import { ProductModel } from '../models/product-composition.model';
import { ServiceKeepWrap } from '../models/service-keep-wrap.model';
import { ServiceKeepModel } from '../models/service-keep.model';
import { ServiceProductModel } from '../models/service-product.model';
import { TryAndBuyBody } from '../models/tryAndBuyBody.model';
import { TryAndBuyResponse } from '../models/tryAndBuyResponse.model';
import { UtilitiesService } from '../utils/utilities.service';
import { tagging } from './../../../config/tagging-config';
import { TryAndBuySetTaggingService } from './../../try-and-buy/try-and-buy-set-tagging.service';
import { LOCAL_STORAGE_KEYS } from './../constants/defines';
import { BackButtons, TrueFalse } from './../enums/backButtons.enum';
import { TryAndBuyOverlayPackage } from './../enums/try-and-buy.enum';
import { UserPermissions } from './../enums/user-permissions.enum';

@Injectable()
export class TryAndBuyService {
	public keepsViewModel: ServiceKeepWrap[];

	public compareModel = {};

	public offers: OfferModel[];
	public currentProduct: ProductModel;

	// misisdn selector
	public identifierOptions: any[] = [];

	// Offers observable
	private offerSubject = new BehaviorSubject(null);
	public offers$ = this.offerSubject.asObservable();

	public isCTC: boolean = false;

	// CurrentProduct observable
	private currentProductSubject = new BehaviorSubject(null);
	public currentProduct$ = this.currentProductSubject.asObservable();

	// comparator model observalbe
	private comparationSubject = new BehaviorSubject(null);
	public comparationModel$ = this.comparationSubject.asObservable();

	// keeps model observable
	private keeps: any[];
	private keepsSubject = new BehaviorSubject(null);
	public keepsModel$ = this.keepsSubject.asObservable();
	public keepTotalAmount = { fee: 0, netFee: 0 };

	public unlimitedLit;
	public selectedLines: AditionalLines[] = [];
	public allContracts;
	public tbStep: number = 0;
	public tbBreadcrumb: number = 0;
	public step: number = 0;
	public breadcrumbStep: number = -1;
	public isNewForm: boolean = false;
	public isResume: boolean = false;
	public isProcessOut: boolean = false;
	public overlayShow: boolean = false;
	public modeBuy: boolean = false;
	public tobBarTitle: string;
	public tobBarTitleTAB: string;
	public directBuy: boolean = false;
	public modeBuyFromTry: boolean = false;
	public hasPermissionToBuy: boolean = true;
	public showPermissionToBuyLabel: boolean = false;
	public hasBuyRequest: boolean = false;
	public actTabTitleDesc: string;
	public typeReco: string;

	constructor(
		private myAccountService: MyAccountService,
		public http: HttpClient,
		private storageService: StorageService,
		private subscriptionService: SubscriptionService,
		private translate: TranslateService,
		private utilitiesService: UtilitiesService,
		private tryAndBuySetTaggingService: TryAndBuySetTaggingService,
		private oneProfesionalService: OneProfesionalService
	) {}

	/**
	 * Fetch All products of the current site and current service
	 */
	GetProductAndRecommendations() {
		// TODO Reemplazar por el end point definitivo
		const url = API_URLS.TryAndBuy.getTryAndBuy.replace(
			'{customerAccountId}',
			this.subscriptionService.customerData.customerAccountsId
		);

		let headers = new HttpHeaders();
		headers = headers.append('Content-Type', 'application/json');
		headers = headers.append(
			'Authorization',
			'Bearer ' + this.storageService.getLocalStorage(LOCAL_STORAGE_KEYS.ACCESS_TOKEN)
		);
		const options = {
			headers: headers,
		};
		return this.http.get(url, options).pipe(
			map((res: any) => {
				return res;
			})
		);
	}

	getTagging() {
		let status_benefits = this.storageService.productAndServiceVariables.status_benefits;

		if (!status_benefits) {
			status_benefits = tagging.staticVariables.benefitsStatus.unknown;
		}

		this.myAccountService.setPlanCommitmentforMyaccount(this.allContracts);
	}

	SendTicket(body: any) {
		const url = API_URLS.Ticket.post;

		let headers = new HttpHeaders();
		headers = headers.append('Content-Type', 'application/json');
		const options = {
			headers: headers,
		};

		return this.http.post(url, body, options).pipe(
			map((res) => {
				return res;
			})
		);
	}

	SendTicketTryAndBuy(bodyTryAndBuy: TryAndBuyBody): Observable<TryAndBuyResponse> {
		const url: string = API_URLS.TicketTryAndBuy.patch;

		let headers: HttpHeaders = new HttpHeaders();
		headers = headers.append('Content-Type', 'application/json');
		const options: { headers: HttpHeaders } = {
			headers: headers,
		};
		return this.http.patch<TryAndBuyResponse>(url, bodyTryAndBuy, options).pipe(
			map((res) => {
				return res;
			})
		);
	}

	public setServiceId(currentValue, newValue) {
		Object.keys(this.compareModel).forEach((key) => {
			if (this.isMobile(this.compareModel[key].serviceType)) {
				this.compareModel[key].offered.forEach((service) => {
					if (service.id === currentValue) {
						service.id = newValue;
					} else if (service.id === newValue) {
						service.id = currentValue;
					}
				});
			}
		});

		this.offers.forEach((offer) => {
			if (offer.product.packages.services && offer.product.packages.services.length > 0) {
				offer.product.packages.services.forEach((service) => {
					this.updateServiceId(service, currentValue, newValue);
				});
			}

			if (offer.product.services && offer.product.services.length > 0) {
				offer.product.services.forEach((service) => {
					this.updateServiceId(service, currentValue, newValue);
				});
			}
		});

		// call keeps login to know if extras or others has been excluded
		this.keepsLogic(this.keeps);

		this.offerSubject.next(this.offers);
		this.keepsSubject.next(this.keepsViewModel);
	}

	public updateServiceId(service: ServiceProductModel, currentValue: string, newValue: string) {
		if (this.isMobile(service.type)) {
			if (service.id === currentValue) {
				service.id = newValue;
			} else if (service.id === newValue) {
				service.id = currentValue;
			}
		}
	}

	/**
	 * Method to parse keeps json to view model
	 */
	public keepsLogic(keeps) {
		this.keepsViewModel = new Array<ServiceKeepWrap>();

		// Group keeps by serviceId
		const keepsDiccionary = [];
		if (keeps) {
			keeps.map((item) => {
				const idetify = item.id || null;
				if (!keepsDiccionary[idetify]) {
					keepsDiccionary[idetify] = [];
				}
				keepsDiccionary[idetify].push(this.jsonToServiceKeepModel(item));
			});

			// Model as ServiceKeepWrap[]
			Object.keys(keepsDiccionary).forEach((key) => {
				const keepServiceWrap = new ServiceKeepWrap();

				keepServiceWrap.id = key;
				const pckOrder = this.getPriorityAsPackage(key);

				keepServiceWrap.serviceType = this.getServiceType(key);
				keepServiceWrap.serviceName = this.getServiceName(keepServiceWrap.serviceType);
				keepServiceWrap.img = this.utilitiesService.setServiceIcon(keepServiceWrap.serviceType);
				keepServiceWrap.order = `${pckOrder}_${this.getOrderByType(keepServiceWrap.serviceType, key)}`;

				this.keepsViewModel.push(keepServiceWrap);
			});

			this.keepTotalAmount.fee = this.keepTotalAmount.netFee = 0;
			this.keepsViewModel.forEach((service) => {
				service.extras.forEach((extra) => {
					this.keepTotalAmount.fee += extra.fee ? parseFloat(extra.fee) : 0;
					this.keepTotalAmount.netFee += extra.netFee ? parseFloat(extra.netFee) : 0;
				});
				service.others.forEach((other) => {
					this.keepTotalAmount.fee += other.fee ? parseFloat(other.fee) : 0;
					this.keepTotalAmount.netFee += other.netFee ? parseFloat(other.netFee) : 0;
				});
			});

			// Order keeps
			this.keepsViewModel.sort(this.utilitiesService.sortByAttr?.bind({ sortField: 'order' }));

			this.keepsSubject.next(this.keepsViewModel);
		}
	}

	/**
	 * Method that return order index for service type
	 * @return {string} order key
	 */
	private getOrderByType(serviceType: string, id: string) {
		let order;
		switch (serviceType.toLowerCase()) {
			case ServiceType.Mobile.toLowerCase():
			case ServiceType.Postpaid.toLowerCase():
			case ServiceType.Prepaid.toLowerCase():
				order = TryAndBuyOverlayPackage.first;
				break;
			case ServiceType.Internet.toLowerCase():
			case ServiceType.Fibre.toLowerCase():
				order = TryAndBuyOverlayPackage.second;
				break;
			case ServiceType.Landline.toLowerCase():
				order = TryAndBuyOverlayPackage.third;
				break;
			case ServiceType.MbbPostpaid.toLowerCase():
			case ServiceType.MbbPrepaid.toLowerCase():
				order = TryAndBuyOverlayPackage.forth;
				break;
			case ServiceType.Tv.toLowerCase():
				order = TryAndBuyOverlayPackage.fifth;
				break;
		}
		return `${order}_${id}`;
	}

	/**
	 * Method to get the serviceType from an id
	 * @param id
	 */
	public getServiceType(id: string): string {
		let idPack;
		let idServ;
		if (
			this.currentProduct.packages &&
			this.currentProduct.packages.services &&
			this.currentProduct.packages.services.length > 0
		) {
			idPack = this.currentProduct.packages.services.filter((num) => num.id === id)[0];
		}
		if (this.currentProduct.services && this.currentProduct.services.length > 0) {
			idServ = this.currentProduct.services.filter((num) => num.id === id)[0];
		}
		return idPack ? idPack.type.toLowerCase() : idServ ? idServ.type.toLowerCase() : '';
	}

	/**
	 * Method to get the serviceName from serviceType
	 * @param serviceType
	 */
	public getServiceName(serviceType: string): string {
		let serviceName: string;
		switch (serviceType) {
			case ServiceType.Tv.toLowerCase():
				this.translate
					.get('migration.tarifa.tarifa_mantiene.itemList.lit_mantiene_tele_titulo.body')
					.subscribe((data) => {
						serviceName = data;
					});
				break;
			case ServiceType.Mobile.toLowerCase():
			case ServiceType.Postpaid.toLowerCase():
			case ServiceType.Prepaid.toLowerCase():
				this.translate
					.get('migration.tarifa.tarifa_mantiene.itemList.lit_mantiene_movil_titulo.body')
					.subscribe((data) => {
						serviceName = data;
					});
				break;
			case ServiceType.Landline.toLowerCase():
				this.translate
					.get('migration.tarifa.tarifa_mantiene.itemList.lit_mantiene_fijo_titulo.body')
					.subscribe((data) => {
						serviceName = data;
					});
				break;
			case ServiceType.Internet.toLowerCase():
			case ServiceType.Fibre.toLowerCase():
				this.translate
					.get('migration.tarifa.tarifa_mantiene.itemList.lit_mantiene_fibra_titulo.body')
					.subscribe((data) => {
						serviceName = data;
					});
				break;
			case ServiceType.MbbPostpaid.toLowerCase():
			case ServiceType.MbbPrepaid.toLowerCase():
				this.translate
					.get('migration.tarifa.tarifa_mantiene.itemList.lit_mantiene_mbb_titulo.body')
					.subscribe((data) => {
						serviceName = data;
					});
				break;
			default:
				serviceName = '';
				break;
		}
		return serviceName;
	}

	/**
	 * Bussines logic that transforms response keeps attribute to objects model
	 */
	public jsonToServiceKeepModel(item) {
		const keep = new ServiceKeepModel();
		keep.code = item.code || null;
		keep.dateExpire = item.dateExpire || null;
		keep.id = item.id || null;
		keep.name = item.name || null;
		keep.type = item.type || null;

		if (item.combinations) {
			const combinations = new Array<CombinationModel>();
			item.combinations.map((comb) => {
				const combination = new CombinationModel();
				combination.excludeAddon = comb.excludeAddon;
				combination.tariffId = comb.tariffId || null;
				combinations.push(combination);
			});

			keep.combinations = combinations;
		}

		keep.fee = item.fee || null;
		keep.netFee = item.netFee || null;

		return keep;
	}

	public isMobile(serviceType: string) {
		return (
			serviceType.toLowerCase() === ServiceType.Mobile.toLowerCase() ||
			serviceType.toLowerCase() === ServiceType.Postpaid.toLowerCase() ||
			serviceType.toLowerCase() === ServiceType.Prepaid.toLowerCase()
		);
	}

	public evaluateAdditionalLines(): boolean {
		const packageSrv = this.currentProduct.packages ? this.currentProduct.packages.services : null;
		const lineServices = this.currentProduct.services
			? this.currentProduct.services.filter(
					(srv) =>
						srv.type.toLowerCase() === ServiceType.Postpaid.toLowerCase() ||
						srv.type.toLowerCase() === ServiceType.Prepaid.toLowerCase()
			  )
			: null;
		let maxPartis;
		let maxMicro;
		this.translate.get('migration.tarifasCommon.itemsList.reco').subscribe((data) => {
			maxPartis = data.maxLineasPartis.body;
			maxMicro = data.maxLineasMicro.body;
		});

		if (
			packageSrv &&
			lineServices &&
			packageSrv.find((srv) => srv.type === ServiceType.Postpaid) &&
			((packageSrv.length > 1 &&
				lineServices.length < parseInt(maxPartis, 10) &&
				this.storageService.userProfile.customerType.toLowerCase() === CustomerType.Consumer.toLowerCase()) ||
				(packageSrv.length > 1 &&
					lineServices.length < parseInt(maxMicro, 10) &&
					this.storageService.userProfile.customerType.toLowerCase() === CustomerType.Authorized.toLowerCase()))
		) {
			return true;
		} else {
			return false;
		}
	}

	public getPriorityAsPackage(id: string): number {
		let idPack;
		let idServ;
		if (
			this.currentProduct.packages &&
			this.currentProduct.packages.services &&
			this.currentProduct.packages.services.length > 0
		) {
			idPack = this.currentProduct.packages.services.filter((num) => num.id === id)[0];
		}
		if (this.currentProduct.services && this.currentProduct.services.length > 0) {
			idServ = this.currentProduct.services.filter((num) => num.id === id)[0];
		}
		return idPack ? TryAndBuyOverlayPackage.first : idServ ? TryAndBuyOverlayPackage.second : 0;
	}

	clean() {
		this.currentProduct = new ProductModel();
		this.keepsViewModel = [];
		this.offers = new Array<OfferModel>();
		this.compareModel = {};
		this.identifierOptions = [];
		this.isCTC = false;
		this.modeBuyFromTry = false;
		this.modeBuy = false;
	}

	advanceToResume(): void {
		this.directBuy = true;
		this.step = 2;
		this.breadcrumbStep = 1;
		this.isNewForm = false;
		this.isResume = true;
		this.tryAndBuySetTaggingService.step = this.step;
		this.tbStep = 2;
		this.tbBreadcrumb = 2;
		this.tryAndBuySetTaggingService.isResume = this.isResume;
		if (!this.modeBuy) {
			this.tryAndBuySetTaggingService.setTagging();
		}
	}

	advanceToFrom(): void | boolean {
		const logicActive: boolean =
			this.translate.instant(
				`v10.commercial.permissionRequest.enablePermissionValidation.${UserPermissions.BUY.toLowerCase()}`
			) === TrueFalse.true;
		this.hasPermissionToBuy =
			this.storageService.userProfile.customerType.toLowerCase() === CustomerType.Authorized.toLowerCase() ||
			this.hasPermissionBuy();

		if ((this.hasPermissionToBuy && logicActive) || !this.modeBuy || !logicActive) {
			this.step++;
			this.breadcrumbStep++;
			this.tryAndBuySetTaggingService.step = this.step;
			if (this.step === 3) {
				this.isNewForm = false;
				this.isResume = true;
				this.tryAndBuySetTaggingService.isResume = this.isResume;
				this.tryAndBuySetTaggingService.isNewForm = this.isNewForm;
			} else {
				this.isNewForm = true;
				this.isResume = false;
				this.tryAndBuySetTaggingService.isResume = this.isResume;
				this.tryAndBuySetTaggingService.isNewForm = this.isNewForm;
			}
			this.tbStep++;
			this.tbBreadcrumb++;
			if (!this.modeBuy) {
				this.tryAndBuySetTaggingService.setTagging();
			}
		} else {
			this.tryAndBuySetTaggingService.setBackdropTagging(this.typeReco, this.actTabTitleDesc);
			this.showPermissionToBuyLabel = true;
		}
	}

	hasPermissionBuy(): boolean {
		return (
			this.storageService.userProfile?.userPermissions?.find(
				(permission) => permission.id.toLowerCase() === UserPermissions.BUY.toLowerCase()
			)?.status || this.storageService.userProfile?.userPermissions?.length === 0
		);
	}

	steepBack(step: CilckReturnModel): void {
		if (step.leftIcon.iconTitle === BackButtons.labReturn && this.step > 0) {
			this.step--;
			this.tbStep--;
			if (this.breadcrumbStep >= 0) {
				this.breadcrumbStep--;
				this.tbBreadcrumb--;
			}
		} else {
			if (this.oneProfesionalService.newDataPega.isPega && step.leftIcon.iconTitle !== BackButtons.labReturn) {
				this.oneProfesionalService.showBackdropPega = true;
			} else {
				this.isProcessOut = true;
				this.overlayShow = true;
				this.tryAndBuySetTaggingService.leaveProccessTrack();
			}
		}
		if (this.step === 0) {
			this.setStepFrom();
		}
		if (this.step === 1) {
			// this.newproductDescription.step = 1;
			this.isResume = false;
			this.isNewForm = false;
			this.tryAndBuySetTaggingService.isResume = this.isResume;
			this.tryAndBuySetTaggingService.isNewForm = this.isNewForm;
		} else {
			// this.newproductDescription.step = this.step;
			this.isNewForm = true;
			this.isResume = false;
			this.tryAndBuySetTaggingService.isResume = this.isResume;
			this.tryAndBuySetTaggingService.isNewForm = this.isNewForm;
		}
		if (this.directBuy) {
			this.directBuy = false;
			this.breadcrumbStep = -1;
			this.tbStep = 0;
			this.tbBreadcrumb = 0;
		}
	}

	setStepFrom() {
		this.isNewForm = false;
		this.isResume = false;
		if (this.modeBuyFromTry) {
			this.modeBuy = false;
			this.modeBuyFromTry = false;
			this.step = 1;
		} else {
			this.tobBarTitle = this.tobBarTitleTAB;
		}
		this.tryAndBuySetTaggingService.isNewForm = this.isNewForm;
		this.tryAndBuySetTaggingService.isResume = this.isResume;
	}
}
