import { Injectable } from '@angular/core';
import { IframeStatus } from '../../shared/enums/iframe-status.enum';
import { PaymentErrorCategory } from '../../shared/enums/payment-error-category.enum';
import { PaymentJourneyDetail } from '../../shared/enums/payment-journey-detail.enum';
import { PaymentPages } from '../../shared/enums/payment-pages.enum';
import { PaymentCompoentInputData, PaymentError } from '../../shared/models/payment';
import { DelegateCreationService } from './delegate-creation.service';
import { PaymentNavigationService } from './payment-navigation.service';
import { PaymentTaggingService } from './payment-tagging.service';

@Injectable()
export class PaymentErrorService {
	currnetError: PaymentError = new PaymentError();
	constructor(
		private paymentNavService: PaymentNavigationService,
		private delegateService: DelegateCreationService,
		private paymentTaggingService: PaymentTaggingService
	) {}

	/**
	 * function to fill error overlay with it's proper
	 * wcs values and btn actions
	 */
	handleErrorByErrorCode(ecode: string): void {
		try {
			this.handleErrorCategory(this.delegateService.paymentErrorConfig.mapErrorCode(ecode));
		} catch (error) {
			const handled: boolean = this.delegateService.paymentErrorConfig.handleExceptionalEcode(ecode);
			if (!handled) {
				this.handleErrorCategory();
			}
		}
	}

	/**
	 * @param errorCategory error Category
	 * according to the error Category we fill the wcs overlay object and decide the button actions
	 * navigate to the ko screen
	 * fire tagging
	 */
	handleErrorCategory(
		errorCategory: PaymentErrorCategory = this.delegateService.paymentErrorConfig.defaultErrorCategory
	): void {
		this.paymentNavService.finalStatus = IframeStatus.failed;
		this.currnetError = this.delegateService.paymentErrorConfig.fillErrorOverlay(errorCategory);
		this.paymentNavService.openPaymentComponent(PaymentPages.ko);
		this.paymentTaggingService.sendTaggingForPostPaidJourney(PaymentJourneyDetail.ko, {
			errorCode: this.paymentNavService.thirdPartyURLParams.errorCode,
			errorDesc: this.paymentNavService.thirdPartyURLParams.errorDesc,
		});
	}
	/**
	 * @param errorCategory error Category
	 * according to the error Category we fill the wcs overlay object and decide the button actions
	 * navigate to the ko screen
	 * @param componentData data input value need to be sent to the ko component
	 */
	handleEnteryPonitsErrorsCategory(
		errorCategory: PaymentErrorCategory = this.delegateService.paymentErrorConfig.defaultErrorCategory,
		componentData?: PaymentCompoentInputData[]
	): void {
		this.currnetError = this.delegateService.paymentErrorConfig.fillErrorOverlay(errorCategory);
		if (componentData && componentData.length > 0) {
			this.paymentNavService.openPaymentComponent(PaymentPages.ko, this.paymentNavService.WCSJourneyKey, componentData);
		} else {
			this.paymentNavService.openPaymentComponent(PaymentPages.ko);
		}
	}
}
