<div class="payment-in-advance">
	<form class="payment-in-advance__form-container" [formGroup]="paymentForm" (ngSubmit)="onSubmit()">
		<div class="payment-in-advance__partial-pay">
			<div class="payment-in-advance__desc-container">
				<p class="payment-in-advance__desc" [innerHtml]="wcsData.description | safe : 'html'"></p>
				<img class="payment-in-advance__wink-image" [src]="winkImage" alt="wink" />
			</div>
			<div class="text-box">
				<sp-text
					ngDefaultControl
					formControlName="amountField"
					(blurFnEvent)="addEurSignToValidInputOnBlur()"
					[mva10Textbox]="true"
					[placeholder]="wcsData.textboxPlaceholder"
					(focusFnEvent)="onFocus()"
					[showRightIconSuccess]="false"
					[showErrorMessage]="showErrorMsg"
					[patternErrorMessage]="wcsData.paidAmountErrorMsg"
					[redHelperText]="true"
					[resetButton]="paymentForm.controls['amountField'].value && !showErrorMsg && showXIcon"
					(xButtonClicked)="clickXButtonInTextField()"
				>
				</sp-text>
			</div>
			<sp-payment-wallet-chevron [marginTop]="56" *ngIf="!isAnonymous"> </sp-payment-wallet-chevron>
		</div>
		<div
			class="btn-container"
			[ngClass]="{
				'payment-in-advance__increase-top-margin': isFieldFocused && !paymentWalletService.selectedCard,
				'payment-in-advance__decrease-top-margin': isFieldFocused && paymentWalletService.selectedCard
			}"
		>
			<button
				id="paymentInadvance_btn_submit"
				type="submit"
				[disabled]="!paymentForm.valid || isConsultant"
				[ngClass]="{ 'invalid-form': !paymentForm.valid || isConsultant }"
			>
				{{ wcsData.confirmButtonText }}
			</button>
		</div>
	</form>
	<div class="payment-in-advance__footer">
		<div class="payment-in-advance__footer-images">
			<div class="visa-image">
				<img [src]="visaImage" alt="visa" />
			</div>
			<div class="mastercard-image">
				<img [src]="masterCardImage" alt="mastercard" />
			</div>
		</div>
		<div class="payment-in-advance__footer-securedssl">
			<p>{{ securedSslText }}</p>
		</div>
	</div>
</div>
