import { OverlayModel } from '@mva10/mva10-angular';
import { Guid } from 'guid-typescript';
import { AnonymousPaymentScope, GRANT_TYPE } from '../constants/defines';
import { IframeStatus } from '../enums/iframe-status.enum';
import { PaymentCompoentInputData_Keys } from '../enums/payment-compoent-input-data-keys.enum';
import { PaymentJourneyDetail } from '../enums/payment-journey-detail.enum';
import { PaymentJourneyEnvironment } from '../enums/payment-journey-environment.enum';
import { PaymentJourneyIds } from '../enums/payment-journey-ids.enum';
import { PaymentJourneyName } from '../enums/payment-journey-name.enum';
import { PaymentJourneyPaymentType } from '../enums/payment-journey-payment-type.enum';
import { PaymentJourneyProcess } from '../enums/payment-journey-process.enum';
import { PaymentJourneySavedCard } from '../enums/payment-journey-saved-card.enum';
import { PaymentJourneySubCategory } from '../enums/payment-journey-sub-category.enum';
import { PaymentJourneyType } from '../enums/payment-journey-type.enum';
import { PaymentJourny } from '../enums/payment-journy.enum';
import { PaymentLanguages } from '../enums/payment-languages.enum';
import { PaymentPages } from '../enums/payment-pages.enum';
import { PaymentTrayAction } from '../enums/payment-tray-action.enum';
import { AmountCustomerDebt } from './amount-customer-debt.model';

export class PaymentScreenConfig {
	thirdPartyContainer: OverlayModel;
	okScreen: OverlayModel;
	koScreen: OverlayModel;
	cancelScreen: OverlayModel;
	default: OverlayModel;
}

export interface ForceTrayConfigType {
	[name: string]: PaymentFullTrayModel;
}
export interface ForceStringType {
	[name: string]: any;
}

export class PaymentError {
	title: string;
	desc: string;
	imgUrl?: string;
	lottieUrl?: string = 'https://assets-es-pprd.dxlpreprod.local.vodafone.es/assets/warning.json';
	firstBtnText: string;
	secondBtnText?: string;
	supportPhoneNumber?: string;
	firstBtnAction: Function;
	secondBtnAction?: Function;
}
export class PaymentSuccess {
	title: string;
	desc: string;
	iconUrl?: string;
	descSecondLine?: string;
	toggleTitle?: string;
	firstBtnText: string;
	secondBtnText?: string;
	walletToggleTitle?: string;
	isSuccessToggleON?: boolean;
	isWalletEligible?: boolean;
	firstBtnAction: Function;
	secondBtnAction?: Function;
}
export class PaymentCancel {
	title: string;
	desc: string;
	lottieFile: string = 'https://assets-es-pprd.dxlpreprod.local.vodafone.es/assets/warning.json';
	firstBtnText: string;
	secondBtnText?: string;
	firstBtnAction: Function;
	secondBtnAction?: Function;
}
export class CustomStyle {
	constructor(public selector: string, public className: string) {}
}
export class PaymentTrayCustomConfig {
	closeBtnAction: Function = null;
	BackBtnAction: Function = null;
	initComponantOnBack: boolean = true;
	title_english?: string = '';
	title_spanish?: string = '';
	customStyles: Array<CustomStyle> = new Array();
	forceShowBackBtn: boolean = false;
}
export class PaymentFullTrayModel {
	MVA10Config: NewOverlayModel = new NewOverlayModel();
	paymentCustomConfig: PaymentTrayCustomConfig = new PaymentTrayCustomConfig();
}
export class NewOverlayModel extends OverlayModel {
	showVodafoneLogo?: boolean;
}
export class PaymentCompHistory {
	compName: PaymentPages;
	comp: any;
	compData?: PaymentCompoentInputData[] = new Array();
}
export class PaymentTaggingObject {
	pageName: PaymentTaggingPageName = new PaymentTaggingPageName();
	pageName_ok: PaymentTaggingPageName = new PaymentTaggingPageName();
	pageName_ko: PaymentTaggingPageName = new PaymentTaggingPageName();
	journey?: PaymentTaggingJourney = new PaymentTaggingJourney();
}

export class PaymentTaggingObjectArchetype {
	pageName: ArchetypeTaggingData = new ArchetypeTaggingData();
	pageName_ok: ArchetypeTaggingData = new ArchetypeTaggingData();
	pageName_ko: ArchetypeTaggingData = new ArchetypeTaggingData();
	folder: string;
}

export class ArchetypeTaggingData {
	state_id: string;
	custom_map: { [key: string]: string };
}
export class PaymentTaggingJourney {
	'journey_name'?: PaymentJourneyName;
	'journey_category'?: string;
	'journey_subcategory'?: PaymentJourneySubCategory;
	'journey_type'?: PaymentJourneyType;
	'journey_process'?: PaymentJourneyProcess;
	'journey_payment_type'?: PaymentJourneyPaymentType;
	'journey_payment_method'?: string;
	'journey_detail'?: PaymentJourneyDetail;
	'journey_step'?: string;
	'journey_card_selected'?: PaymentJourneySavedCard;
	'journey_environment'?: PaymentJourneyEnvironment;
	'&&events'?: string;
	'&&products'?: string;
	'event_error'?: string;
	'event_success'?: string;
	'payment_id'?: string;
	'error_list'?: string;
	'state_flow'?: string;
	'journey_transaction_type'?: string;
}
export class ThirdPartyURLParam {
	transactionId?: string;
	errorCode?: string;
	errorDesc?: string;
	transactionDate?: string;
	cardMask?: string;
	cardToken?: string;
	expiryDate?: string;
}
export class TrackActionsObject {
	eventName?: string;
	data?: TrackActionsDataObject;
}
export class TrackActionsDataObject {
	event_name: string;
	event_context: string;
	event_category: string;
	event_label: string;
}

export class TaggingJourney {
	'journey_name'?: string;
	'journey_category'?: string;
	'journey_subcategory'?: string;
	'journey_type'?: string;
	'journey_process'?: string;
	'journey_detail'?: string;
	'journey_step'?: string;
	'journey_payment_type'?: PaymentJourneyPaymentType;
	'journey_payment_method'?: string;
	'&&events'?: string;
	'error_list'?: string;
	'journey_error_description'?: string;
	'journey_error_category'?: string;
	'journey_error_code'?: string;
}

export class TrackActions {
	eventName?: string;
	data?: TaggingJourney;
}

export class PaymentTaggingPageName {
	'page_name'?: string;
	'page_section'?: string;
	'page_subcategory_level_1'?: string;
	'page_subcategory_level_2'?: string;
	'page_subcategory_level_3'?: string;
	'page_subcategory_level_4'?: string;
	'page_screen'?: string;
	'page_typology'?: string;
	'journey_name'?: string;
	'journey_category'?: string;
	'journey_subcategory'?: string;
	'journey_type'?: string;
	'journey_process'?: string;
	'journey_detail'?: string;
	'journey_step'?: string;
	'journey_payment_type'?: PaymentJourneyPaymentType;
	'journey_payment_method'?: string;
	'&&events'?: string;
	'error_list'?: string;
}
export class MessagePlaceHolders {
	okScreeen: { subtitle: Array<string>; desc: Array<string> } = {
		subtitle: new Array<string>(),
		desc: new Array<string>(),
	};
}

export class PaymentJourneyParams {
	// the one who offer his crediet card for payment
	payer: PaymentCustomer = new PaymentCustomer();
	// the one who gets the payment money
	buyer: PaymentCustomer = new PaymentCustomer();
	amount: string;
	WCSJournyKey: PaymentJourny;
	isAnonymous: boolean;
	language: PaymentLanguages = PaymentLanguages.spanish;
	// wait for cancel refund and check complete refund category
	isHandleWaiting: boolean = true;
	journeyId: PaymentJourneyIds;
	// for the iframe concepto string
	concepto: string;
	// for saved credit card
	token?: string;
	expiryDate?: string;
	cardMask?: string;
	// couch base related
	systemReferenceId: string;
	systemID: string;
	// ok ko screens related
	okUrlExternal: string;
	okUrlInternal: string;
	koUrlExternal: string;
	koUrlInternal: string;
	placeHolders: MessagePlaceHolders = new MessagePlaceHolders();
	// for tagging configration
	paymentTaggingConfig: PaymentTaggingObject = new PaymentTaggingObject();
	paymentTaggingConfigArchetype: PaymentTaggingObjectArchetype = new PaymentTaggingObjectArchetype();
	amountInCent?: string;
	// for financed devices cancellation
	imei?: string;
	installmentQuantity?: string;
	// for debt journey total or partial
	paymentType?: string;
	promoCode?: string;
	// for top up journey
	icon?: string;
	firstButton?: string;
	secondButton?: string;
	isSuccessTarrifFeeOverlayOpened: boolean;
}
export class PaymentCustomer {
	documentId: string;
	siteId: string;
	serviceId: string;
	documentType?: string;
}
export class ThirdPartyIframeUrlRequest {
	// the one who offer his crediet card for payment
	payer: PaymentCustomer = new PaymentCustomer();
	// the one who gets the payment money
	buyer: PaymentCustomer = new PaymentCustomer();
	amount: string;
	language: PaymentLanguages; // make Enum
	journeyId: string; // enum

	appUUID: Guid; // created inside
	platform?: string; // created inside
	// created from iframe parameters
	token?: string;
	expiryDate?: string;
	cardMask?: string;
	// new added params
	systemReferenceId?: string;
	systemID?: string;
	// related to financed devices journey
	imei?: string;
	installmentQuantity?: string;
	promoCode?: string;
}

export class ThirdPartyIframeRequestBodyHeader {
	// body params
	requestBody: ThirdPartyIframeUrlRequest;
	// for authnication
	isAnonymous: boolean;
	// for url authnication
	urlSiteId: string;
	urlServiceId: string;
}

export class PaymentPatchRequestBody {
	// the one who offer his crediet card for payment
	payer: PaymentCustomer = new PaymentCustomer();
	// the one who gets the payment money
	buyer: PaymentCustomer = new PaymentCustomer();
	journeyId: string; // enum
	platform?: string; // created inside
	// created from iframe parameters
	cardMask?: string;
	cardNetplus?: string;
	transactionId?: string;
	status: IframeStatus;
	error?: IframeError;
	systemReferenceId?: string;
	// for financed devices
	imei?: string;
	installmentQuantity?: string;
}

export class NetPlusParams {
	cardMask: string;
	cardToken: string;
	transactionDate: string;
	cardExpiry: string;
}
export class IframeError {
	errorCode?: string;
	errorDescription?: string;
}
export class ThirdPartyIframePayload {
	status: IframeStatus;
	netPlusParams: NetPlusParams = new NetPlusParams();
	iframUrlParam: any;
	error: IframeError = new IframeError();
	transactionId?: string;
	systemID?: string;
	systemReferenceId?: string;
}

export class ThirdPartyPatchRequestBodyHeader {
	// body params
	requestBody: any;
	// for authnication
	isAnonymous: boolean;
	// for url authnication
	urlSiteId: string;
	urlServiceId: string;
}

export class IframeWCS {
	concept: string;
	amount: string;
	concepto: string;
	amountValue: string;
	conceptImg: string;
	amountImg: string;
	footerVisaImg: string;
	footerMastercardImg: string;
	footerText: string;
}

export class PaymentNavigation {
	compname?: PaymentPages;
	actionNeeded: PaymentTrayAction;
	compData?: PaymentCompoentInputData[] = new Array();
	backSteps?: number;
	finalStatus?: IframeStatus;
}
export class PaymentCompoentInputData {
	key: PaymentCompoentInputData_Keys;
	value: any;
}
export class PaymentTrayNavigation {
	comp: any;
	animationOnTranstion?: boolean = true;
	initComponantOnBack?: boolean = true;
	goBack?: boolean = false;
	compIndex?: number = 0;
}

export class PaymentTicket {
	subtitle: string;
	ticketDetails: TicketDetail[] = new Array();
	firstBtnText: string;
	secondBtnText?: string;
	toast: string;
	secondBtnAction?: Function;
	ticketNote?: string = '';
}
export class TicketDetail {
	key: string;
	value: any;
	spaceFromLeft: number;
}

export class PaymentDeleteConfirmation {
	desc: string;
	secondDesc: string;
	icon: string;
	firstBtnText: string;
	secondBtnText?: string;
}
export class PaymentOutPutParams {
	// status
	codigo?: string;
	// transactionId
	numOperacionOrigen?: string;
	// transaction date
	fechaOpOrigen?: string;
	// message
	mensaje?: string;
	// amount
	importe?: string;
	// systemReferenceId
	systemReferenceId?: string;
	// refundCodigo
	refundCodigo?: string;
	// refundId
	refundId?: string;
	// refundFecha
	refundFecha?: string;
	// refundMensaje
	refundMensaje?: string;
}
export class AnonymousCustomerRequestBody {
	login_hint: string;
	response_type: string = 'code';
	client_id: string;
}
export class AnonymousOTPValiadtionRequestBody {
	code: string;
	grant_type: string = GRANT_TYPE.authorization_code;
	scope: string = AnonymousPaymentScope.ES_CUSTOMER_DEBT_ALL;
	client_id: string;
}
export class AnonymousPaymentCustomer {
	// could be nif or nie or passport or cif
	documentId: string;
	customerMSIDN: string;
	selectedsiteId: string;
	access_token: string;
	dept: AnonymousPaymentCustomerDept = new AnonymousPaymentCustomerDept();
	customerAccountNumber: string;
}
export class AnonymousPaymentCustomerDept {
	// site id
	id: string;
	accountBalanceAmount: string;
	accountBalanceAmountUnit: string;
	state: string;
}

export class AnonymousCustomerDept {
	accountBalance: AccountBalance[];
	id: string;
	state: string;
}

export class AccountBalance {
	amount: AmountCustomerDebt;
	balanceType: string;
}
