import { Component, EventEmitter, Input, Output, ViewChild } from '@angular/core';
import { AccordionPanelComponent } from 'ngx-bootstrap/accordion';
import { GenereicAccordionModel } from '../../models/generic-accordion.model';

@Component({
	selector: 'sp-generic-accordion',
	templateUrl: './generic-accordion.component.html',
	styleUrls: ['./generic-accordion.component.scss'],
})
export class GenericAccordionComponent {
	@Input() accordion: GenereicAccordionModel;
	@Input() lastItem: any;
	@ViewChild('group', { static: false }) group: AccordionPanelComponent;
	@Output() isOpen: EventEmitter<boolean> = new EventEmitter();

	checkisOpenAccordion(isOpen: boolean): void {
		this.isOpen.emit(isOpen);
	}

	openLink(url: string) {
		window.open(url, '_blank');
	}
}
