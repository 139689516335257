import { Component, Input, OnInit } from '@angular/core';
import { OverlayModel } from '@mva10/mva10-angular';
import { TranslateService } from '@ngx-translate/core';
import { Subscription } from 'rxjs';
import { NewTaggingHelperService } from 'src/app/core/services/new-tagging.helper.service';
import { PaymentErrorService } from 'src/app/payment/services/payment-error.service';
import { PaymentNavigationService } from 'src/app/payment/services/payment-navigation.service';
import { smartPayTagging } from 'src/app/shared/constants/defines';
import { AlertlayoutEnum } from 'src/app/shared/enums/AlertTypeEnum.enum';
import { AlertTypeEnum } from 'src/app/shared/enums/alertType.enum';
import { PaymentCompoentInputData_Keys } from 'src/app/shared/enums/payment-compoent-input-data-keys.enum';
import { PaymentErrorCategory } from 'src/app/shared/enums/payment-error-category.enum';
import { PaymentJourny } from 'src/app/shared/enums/payment-journy.enum';
import { PaymentPages } from 'src/app/shared/enums/payment-pages.enum';
import { PaymentTrayAction } from 'src/app/shared/enums/payment-tray-action.enum';
import { NewTaggingJsonModel } from 'src/app/shared/models/new-tagging-json-model';
import { PaymentAlertModel } from 'src/app/shared/models/payment-alertModel.model';
import { SmartPayGetPaymentListModel } from 'src/app/shared/models/smart-pay-get-payment.model';
import { PaymentWalletService } from 'src/app/shared/services/payment-wallet.service';
import { SmartPayPaymentService } from 'src/app/shared/services/smart-pay-payment.service';
import { TariffService } from 'src/app/shared/services/tariff.service';
import { UtilitiesService } from 'src/app/shared/utils/utilities.service';
import { StorageService } from '../../core/services/storage.service';
import { UpdateCardItemsModel } from '../../shared/models/update-card-items-model';
import { SmartPayService } from '../services/smart-pay.service';

@Component({
	selector: 'sp-smart-pay-payment-card',
	templateUrl: './smart-pay-payment-card.component.html',
	styleUrls: ['./smart-pay-payment-card.component.scss'],
})
export class SmartPayPaymentCardComponent implements OnInit {
	@Input() isSmartPay: boolean;
	@Input() msisdn: string;
	@Input() isDay28: boolean;
	@Input() isTariffActive: boolean;
	tariffFeeAmount: string;
	tariffExpiryDate: string;
	submitted: boolean;
	paymentAlert: PaymentAlertModel;
	subscriptions: Subscription[] = [];
	walletSubscribtion: Subscription;
	addNewCardSubscription: Subscription;
	paymentCardsSubscription: Subscription;
	public overlayData: OverlayModel = new OverlayModel();
	public showOverlay: boolean;
	public updateCardItemList: UpdateCardItemsModel[];
	constructor(
		public tariffService: TariffService,
		private newTagging: NewTaggingHelperService,
		public translateService: TranslateService,
		private utilitiesService: UtilitiesService,
		private paymentErrorService: PaymentErrorService,
		private paymentNavigationService: PaymentNavigationService,
		private paymentWalletService: PaymentWalletService,
		private storageService: StorageService,
		public smartPayPaymentService: SmartPayPaymentService,
		public smartPayService: SmartPayService
	) {}

	ngOnInit(): void {
		this.smartPayService.resetFlags();
		this.setUIContent();
		this.getUserCreditCardData();
		this.setUpdateCardData();
	}

	setUpdateCardData(): void {
		this.overlayData.hideXButton = false;
		this.overlayData.primaryButton = this.translateService.instant('v10.productsServices.smartPay.txId_COF.CTA');
		this.translateService.get('v10.productsServices.smartPay.txId_COF.itemsList').subscribe((data) => {
			const cardItemsList: UpdateCardItemsModel[] = data;
			this.updateCardItemList = [...cardItemsList];
		});
	}

	setUIContent(): void {
		this.tariffExpiryDate = this.utilitiesService.getDateFormatedAsDMY(this.tariffService.Tariff.benefitExpiryDate);
		this.translateService.get('v10.productsServices.smartPay.common').subscribe((data) => {
			this.tariffFeeAmount = data.tariffUnit.replace('{0}', this.tariffService.Tariff.Fee);
		});
	}

	getUserCreditCardData(): void {
		if (this.isSmartPay) {
			this.getSmartPayActiveData();
		} else {
			this.smartPayService.getSavedCreditCards();
		}
	}

	getSmartPayActiveData(): void {
		this.smartPayPaymentService
			.getSmartPayPayment(this.msisdn, this.storageService.userProfile.firstName)
			.subscribe((data: SmartPayGetPaymentListModel) => {
				this.setDefaultCreditCard(data);
			});
	}

	setDefaultCreditCard(data: SmartPayGetPaymentListModel): void {
		this.smartPayService.hasCreditCard = true;
		this.smartPayService.defaultActiveSmartpayCard.cardMaskData = data[0].details.mask;
		this.smartPayService.defaultActiveSmartpayCard.cardImage = this.smartPayService.getCardImage(data[0].details.brand);
		this.smartPayService.selectedCreditCard.creditCardNumber = this.utilitiesService.cardFormatted(
			data[0].details.mask
		);
		this.smartPayService.selectedCreditCard.creditCardImage = this.smartPayService.getCardImage(data[0].details.brand);
	}
	clickOnChangeButtonTagging(): void {
		const interactionKey: string = this.isSmartPay
			? smartPayTagging.pago_facil_active_change_payment_method_click
			: smartPayTagging.pago_facil_inactive_change_payment_method_click;
		const stateKey: string = this.isSmartPay ? smartPayTagging.pago_facil_active : smartPayTagging.pago_facil_inactive;
		this.newTagging.getJsonTagging('smart-pay').subscribe((data: NewTaggingJsonModel) => {
			this.newTagging.interaction(data.eventList[interactionKey], data.page['stateList'][stateKey]);
		});
	}

	clickOnChangeButton(): void {
		this.clickOnChangeButtonTagging();
		this.smartPayService.isCTASubmitted = false;
		this.smartPayService.showUpdateCardOverlay = false;
		this.paymentNavigationService.openPaymentComponent(PaymentPages.loading, PaymentJourny.smartPaySavedCard);
		if (this.paymentCardsSubscription) {
			this.paymentCardsSubscription.unsubscribe();
		}
		this.paymentCardsSubscription = this.paymentWalletService.getPaymentCards(true).subscribe(() => {
			if (this.paymentWalletService.cardsList && this.paymentWalletService.cardsList.length > 0) {
				if (this.isSmartPay && this.isDay28) {
					this.showSmartPaySavedCardAlert();
					this.paymentNavigationService.openPaymentComponent(PaymentPages.wallet, PaymentJourny.smartPaySavedCard, [
						{ key: PaymentCompoentInputData_Keys.standAloneWallet, value: false },
						{ key: PaymentCompoentInputData_Keys.alertObject, value: this.paymentAlert },
					]);
				} else {
					this.paymentNavigationService.openPaymentComponent(PaymentPages.wallet, PaymentJourny.smartPaySavedCard, [
						{ key: PaymentCompoentInputData_Keys.standAloneWallet, value: false },
					]);
				}
				this.walletSubscribtion = this.paymentNavigationService.changePaymentCompSubject.subscribe(
					({ actionNeeded }) => {
						if (actionNeeded === PaymentTrayAction.forceClose) {
							this.subscriptions.forEach((sub) => sub.unsubscribe());
						} else {
							this.smartPayService.updateCardWithDefault = false;
							if (this.paymentWalletService.selectedCardText !== this.paymentWalletService.otherCardText) {
								this.smartPayService.handleUIFooter(this.isSmartPay, this.paymentWalletService.selectedCard);
							} else {
								this.smartPayService.handleUIFooter(this.isSmartPay);
							}
						}
						this.walletSubscribtion.unsubscribe();
					}
				);
			} else {
				this.paymentErrorService.handleErrorCategory(PaymentErrorCategory.emptyWallet);
			}
		});
	}

	navigateToSaveCreditCardJourney(): void {
		this.paymentNavigationService.openPaymentComponent(PaymentPages.saveCreditCard, PaymentJourny.saveCreditCard);
		this.addNewCardSubscription = this.paymentNavigationService.changePaymentCompSubject.subscribe(() => {
			this.smartPayService.updateCardToBeDefault(this.isSmartPay);
			this.addNewCardSubscription.unsubscribe();
		});
	}

	showSmartPaySavedCardAlert(): void {
		this.paymentAlert = new PaymentAlertModel();
		this.paymentAlert = {
			showModel: true,
			layout: AlertlayoutEnum.horizontal,
			modelType: AlertTypeEnum.info,
		};
		this.translateService
			.get('v10.payment.manageSavedCredietCard.changeCreditCardDayMinusOneInfoLabel')
			.subscribe((data) => {
				this.paymentAlert.message = data;
			});
	}
}
