import { Component, Input } from '@angular/core';
import { AppService } from '../../../../app.service';
import { TryAndBuyInclude } from '../../../models/tryAndBuyInclude.model';

@Component({
	selector: 'sp-what-include',
	templateUrl: './what-include.component.html',
	styleUrls: ['./what-include.component.scss'],
})
export class WhatIncludeComponent {
	@Input() include: TryAndBuyInclude;
	@Input() modeBuy: boolean = false;
	@Input() productName: string = '';
	public showOverlayCta: boolean = false;
	constructor(public appTheme: AppService) {}

	public closeOverlayCta(): void {
		this.showOverlayCta = false;
	}

	public openOverlayCta(): void {
		this.showOverlayCta = true;
	}
}
