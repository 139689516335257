import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Guid } from 'guid-typescript';
import { map } from 'rxjs/operators';
import { environment } from '../../../environments/environment';
import { AuthenticateService } from '../../core/services/authenticate.service';
import { StorageService } from '../../core/services/storage.service';
import { CONFIG, CSRFToken, GRANT_TYPE, LOCAL_STORAGE_KEYS, transactionKey } from '../constants/defines';
import { API_URLS } from '../constants/routes-config';
import { Impersonation } from '../models/impersonation.model';
import { ImpersonateResponseModel } from '../models/login.model';

@Injectable()
export class ImpersonationService {
	impersonatedUser: Impersonation;

	constructor(
		private http: HttpClient,
		private authenticateService: AuthenticateService,
		private storage: StorageService
	) {
		this.impersonatedUser = new Impersonation();
	}

	PostImpersonate(jwe: string) {
		const url = API_URLS.Impersonation.token;

		let headers = new HttpHeaders();
		headers = headers.append('Content-Type', 'application/x-www-form-urlencoded');
		headers = headers.append('Authorization', 'Basic ' + btoa(CONFIG.CLIENT_ID));
		headers = headers.append('vf-target-environment', 'aws-' + environment.environmentName + '-es');
		const transactionID: Guid = Guid.create()['value'];
		headers = headers.append(transactionKey, transactionID.toString());
		const CSRFTokenValue: any = this.storage.getLocalStorage(LOCAL_STORAGE_KEYS.USER_CSRF_TOEKN);
		headers = headers.append(CSRFToken, CSRFTokenValue.toString());
		headers = headers.append('vf-country-code', CONFIG.COUNTRY_CODE);
		const options = {
			headers: headers,
			withCredentials: true,
		};

		const body =
			'client_id=' +
			CONFIG.CLIENT_ID +
			'&client_secret=' +
			jwe +
			'&scope=' +
			CONFIG.SCOPES.join(' ') +
			'&grant_type=' +
			GRANT_TYPE.CLIENT_CREDENRIALS;
		const saveToken = true;
		return this.authenticateService.createRequest(options, body, url, saveToken).pipe(
			map((res: ImpersonateResponseModel) => {
				const jwt = res.jws;
				const jwtParts = jwt.toString().split('.');

				// convert jwt into string
				const decoded = atob(jwtParts[1]);
				// jsonpath refactor: added 'any', model should be DecodedToken but it's not compatible with Impersonation
				const jwtData: any = JSON.parse(decoded);

				const impersonatedBy = jwtData.impersonatedBy || null;
				if (impersonatedBy) {
					this.impersonatedUser.Subject = impersonatedBy.sub || null;
					this.impersonatedUser.Group = impersonatedBy.group || null;
					this.impersonatedUser.Issuer = impersonatedBy.iss || null;
					this.impersonatedUser.IssueTime = impersonatedBy.iat || null;
					this.impersonatedUser.JwtID = impersonatedBy.jti || null;
				}
				return res;
			})
		);
	}
}
