import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Router, RouterStateSnapshot } from '@angular/router';
import { Observable } from 'rxjs';
import { config } from '../../../config/pages-config';
import { Route } from '../../shared/models/Route.model';

@Injectable()
export class ReloadGuard {
	constructor(private router: Router) {}
	canActivate(
		route: ActivatedRouteSnapshot,
		state: RouterStateSnapshot
	): boolean | Observable<boolean> | Promise<boolean> {
		let currentRoute = window.location.href;
		if (this.router && this.router.url !== '/') {
			currentRoute = this.router.url;
		}

		if (currentRoute.indexOf(state.url) !== -1) {
			this.router.navigate([config.splash.route], { queryParams: { targetUrl: state.url } });
			return false;
		}
		return true;
	}

	canActivateChild(
		childRoute: ActivatedRouteSnapshot,
		state: RouterStateSnapshot
	): boolean | Observable<boolean> | Promise<boolean> {
		return this.canActivate(childRoute, state);
	}

	public getRouteUsingURL(url: string): Route {
		let route: Route = null;
		const conf = config;
		Object.keys(conf).forEach((moduleKey) => {
			if (conf[moduleKey].route === url) {
				route = conf[moduleKey];
			} else {
				Object.keys(conf[moduleKey]).forEach((stepKey) => {
					if (conf[moduleKey][stepKey].route === url) {
						route = conf[moduleKey][stepKey];
					}
				});
			}
		});
		return route;
	}
}
