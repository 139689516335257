export enum iconSvgPath {
	icon_mobile_postpaid = 'https://assets-es-pprd.dxlpreprod.local.vodafone.es/assets/icons-svg/mobile-mid.svg',
	icon_mobile_prepaid = 'https://assets-es-pprd.dxlpreprod.local.vodafone.es/assets/icons-svg/payg-colored.svg',
	icon_mbb = 'https://assets-es-pprd.dxlpreprod.local.vodafone.es/assets/icons-svg/usb_modem.svg',
	icon_tv = 'https://assets-es-pprd.dxlpreprod.local.vodafone.es/assets/icons-svg/tv-mid.svg',
	icon_busines_phone = 'https://assets-es-pprd.dxlpreprod.local.vodafone.es/assets/icons-svg/fixed-line.svg',
	icon_adsl = 'https://assets-es-pprd.dxlpreprod.local.vodafone.es/assets/icons-svg/router-colored.svg',
	icon_internet = 'https://assets-es-pprd.dxlpreprod.local.vodafone.es/assets/icons-svg/router-colored.svg',
	icon_broadband_or_wifi = 'https://assets-es-pprd.dxlpreprod.local.vodafone.es/assets/icons-svg/router-colored.svg',
	icon_addOne = 'https://assets-es-pprd.dxlpreprod.local.vodafone.es/assets/icons-svg/add-ons-colored.svg',
}
