import { Injectable } from '@angular/core';
import { IframeStatus } from '../../shared/enums/iframe-status.enum';
import { PaymentJourneyDetail } from '../../shared/enums/payment-journey-detail.enum';
import { PaymentPages } from '../../shared/enums/payment-pages.enum';
import { PaymentSuccess, PaymentTicket } from '../../shared/models/payment';
import { DelegateCreationService } from './delegate-creation.service';
import { PaymentNavigationService } from './payment-navigation.service';
import { PaymentTaggingService } from './payment-tagging.service';

@Injectable()
export class PaymentSuccessService {
	currnetSuccess: PaymentSuccess = new PaymentSuccess();
	paymentTicket: PaymentTicket = new PaymentTicket();
	constructor(
		private delegateService: DelegateCreationService,
		private paymentTaggingService: PaymentTaggingService,
		private paymentNavService: PaymentNavigationService
	) {}

	/**
	 * function to handle success of payment process
	 * fill the overlay needed with wcs and actions
	 * push ok compnent fire tagging
	 */
	handleSuccessPayment(): void {
		this.currnetSuccess = this.delegateService.paymentSuccessConfig.fillSuccessOverlay();
		this.paymentNavService.openPaymentComponent(PaymentPages.ok);
		this.paymentTaggingService.sendTaggingForPostPaidJourney(PaymentJourneyDetail.ok, {
			transactionId: this.paymentNavService.thirdPartyURLParams.transactionId,
		});
		this.paymentNavService.finalStatus = IframeStatus.complete;
		if (this.paymentNavService.isPayingEnergyDebt) {
			this.paymentNavService.hasPayedEnergyDebt = true;
		}
	}
	/**
	 * function to fill ticket overlay with it's proper
	 * wcs values and btn actions
	 */
	fillPaymentTicketComponent(): void {
		this.paymentTicket = this.delegateService.paymentSuccessConfig.fillTicketOverlay();
	}
}
