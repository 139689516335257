<div class="child-container">
	<div *ngIf="showContent">
		<form role="form" [formGroup]="editForm" novalidate>
			<div>
				<!-- realated section  -->
				<div class="row">
					<div class="col-xs-12 reasons-dropdown-wrapper">
						<p class="section-label">{{ 'common.contactUs.itemsList.contactUsDropDownList.label' | translate }}</p>
						<div
							class="reasons-dropdown-row selected-reason reasons-header"
							[ngClass]="{ 'focused-reasons': showItems }"
							(click)="showItems = !showItems"
						>
							<span> {{ selectedSection }} </span>
							<i class="icon icon-chevron-down"> </i>
						</div>
						<div class="reasons-for-select-wrapper" [ngClass]="{ hide: !showItems, show: showItems }">
							<div *ngFor="let section of sections" (click)="selectSection(section)" class="reasons-dropdown-row">
								<span> {{ section }} </span>
							</div>
						</div>
					</div>
				</div>
				<!-- realated section  end-->

				<!-- problem desc -->
				<div class="row">
					<div class="element-data">
						<sp-text-area
							id="contactUsComp__txt_message"
							max="100"
							class="text"
							formControlName="message"
							label="{{ 'common.contactUs.itemsList.contactProblemInfo.body' | translate }}"
							placeholder="{{ 'common.contactUs.itemsList.contactProblemInfoText.body' | translate }}"
							ngDefaultControl
						>
						</sp-text-area>
					</div>
				</div>
				<!-- problem desc  end-->

				<!-- email sec -->
				<div class="row">
					<div class="col-xs-12">
						<sp-text
							id="contactUsComp_txt_email"
							formControlName="email"
							label="{{ 'common.contactUs.itemsList.contactEmail.body' | translate }}"
							placeholder="{{ 'common.contactUs.itemsList.contactEmailText.body' | translate }}"
							pattern="^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$"
							[enforceValidation]="showEmailError"
							(blurFnEvent)="validateEmail()"
							isRequired="true"
							patternErrorMessage="{{
								'common.contactUs.messagesList.contactEmailValidation.contactEmailValidation_description' | translate
							}}"
							ngDefaultControl
						>
						</sp-text>
					</div>
				</div>
				<!-- email sec end-->

				<!-- button section -->
				<div class="row">
					<div class="col-xs-12 button-wrapper">
						<button
							type="button"
							(click)="send()"
							class="button"
							[ngClass]="{ red: editForm.valid, gray: !editForm.valid }"
							[disabled]="!editForm.valid"
						>
							{{ 'common.contactUs.buttonsList.contactSendBtn.text' | translate }}
						</button>
					</div>
				</div>
				<!-- button section end -->
			</div>
		</form>
	</div>

	<!-- error section -->
	<div *ngIf="showError" class="error-container">
		<sp-error-message
			[ShowErrorImg]="true"
			message="{{
				'common.contactUs.messagesList.contactWarningOverlay.contactWarningOverlay_description' | translate
			}}"
			retryBtn="{{
				'common.contactUs.messagesList.contactWarningOverlay.contactWarningOverlay_button1.text' | translate
			}}"
			[errorIconSrc]="errorIconSrc"
			(navigate)="retry()"
		>
		</sp-error-message>
	</div>
	<!-- error section  end -->

	<!-- success section  -->
	<div *ngIf="showSucces" class="has-success">
		<div class="iconText">
			<img [src]="successIconSrc" class="successImg" />
			<div class="textWarning">
				<p
					class="textLine1"
					[innerHtml]="'common.contactUs.messagesList.contactSendOverlay.contactSendOverlay_description' | translate"
				></p>
			</div>
			<button id="contactUsComp_btn_dismiss" (click)="closeBubble()" class="button gray">
				{{ 'common.contactUs.messagesList.contactSendOverlay.contactSendOverlay_button1.text' | translate }}
			</button>
		</div>
	</div>
	<!-- success section  end-->
</div>
