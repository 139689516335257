import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import * as _ from 'lodash';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { tagging } from '../../../config/tagging-config';
import { StorageService } from '../../core/services/storage.service';
import { SubscriptionService } from '../../core/services/subscription.service';
import * as constants from '../constants/defines';
import { cachingKeys } from '../constants/defines';
import { API_URLS } from '../constants/routes-config';
import { evict } from '../decorators/evict.decorator';
import { BundleStatus } from '../enums/bundleStatus.enum';
import { BundleType } from '../enums/bundleType.enum';
import { CallType } from '../enums/callType.enum';
import { ProductSubtype } from '../enums/product-subtype.enum';
import { ProductCategory } from '../enums/productCategory.enum';
import { ProductType } from '../enums/productType.enum';
import { ServiceType } from '../enums/serviceType.enum';
import { ShippingModesTv } from '../enums/shipping-modes-tv.enum';
import { UnitCode } from '../enums/unitCode.enum';
import { Channels } from '../models/channels.model';
import { DeliveryOffice } from '../models/delivery-office.model';
import { OnlineTv } from '../models/online-tv.model';
import { OpAddon } from '../models/opAddon.model';
import { Product } from '../models/product.model';
import { ProductRestriction } from '../models/products-restriction.model';
import { Promotion } from '../models/promotion.model';
import { RemainingStep } from '../models/remainingStep.model';
import { ServiceModel } from '../models/service.model';
import { WorryFree } from '../models/worryFree.model';
import { UtilitiesService } from '../utils/utilities.service';
import { B5I, COE } from './../constants/defines';
import { DestinyService } from './destiny.service';

@Injectable()
export class ProductService {
	products: Product[] = new Array<Product>();
	staticsProducts: Product[];
	statusError: string;
	worryFreeName: string;
	hasUnlimited: boolean = false;
	accessToNewOffer: boolean = false;
	codesListNewOffer: any[];
	showTVOnline: boolean = false;
	navigateToEnjoyMoreListingFromVFPassLanding: boolean = false;
	public channelDescription: string;
	public channelCode: string;
	public channelStatus: string;
	public channelName: string;
	public chosenPromoCode: string;
	public isThirdPartyLanding: boolean;
	public isProductHomeLoaded: boolean = false;

	constructor(
		private http: HttpClient,
		private storageService: StorageService,
		private subscriptionService: SubscriptionService,
		private utilities: UtilitiesService,
		private translateService: TranslateService,
		private destinyService: DestinyService
	) {
		this.destinyService.unlimitedObservable.subscribe((state) => {
			this.hasUnlimited = state;
		});
	}
	/**
	 * Fetch All products of the current site and current service
	 */
	GetAllProducts(currentServiceId?) {
		const url = API_URLS.Product.Products.replace(
			'{subscription-id}',
			currentServiceId ? currentServiceId : this.subscriptionService.customerData.currentService.id
		).replace('{customer-account-id}', this.subscriptionService.customerData.customerAccountsId);
		this.storageService.navigateToSecureNetFromBilling = false;
		let headers = new HttpHeaders();
		headers = headers.append('Content-Type', 'application/json');
		headers = headers.append(
			'Authorization',
			'Bearer ' + this.storageService.getLocalStorage(constants.LOCAL_STORAGE_KEYS.ACCESS_TOKEN)
		);
		const options = {
			headers: headers,
		};
		return this.http.get(url, options).pipe(
			map((res) => {
				this.productLogic(res);
				return res;
			})
		);
	}

	productLogic(product) {
		this.products = new Array<Product>();
		this.statusError = product.status || '';
		const items = product.items || null;
		this.destinyService.checkUnlimitedProducts(items);
		items.map((item: any) => {
			// jsonpath refactor: added 'any' type due to missing model
			const product = new Product();
			product.Name = item.name || null;
			product.title = item.title || null;
			product.serviceType = item.serviceType || null;
			product.defaultSVACode = item.defaultSVACode || null;
			product.additionalInfo = item.additionalInfo || null;
			product.deviceName = item.deviceName || null;
			// DXL will return true/false values , so can't set initial value true as the button
			// in the enjoy more details initially must been shown
			// so if it returns false it will be true also
			if (item.canBeDeactivated === false) {
				product.canBeDeactivated = false;
			} else {
				product.canBeDeactivated = true;
			}
			product.titleLimit = item.titleLimit || null;
			product.titleRate = item.titleRate || null;
			product.Type = item.type || null;
			product.Code = item.code || null;
			product.offeringCode = item.offeringCode || null;
			product.TypeName = item.typeName || null;
			product.serviceID = this.subscriptionService.customerData.currentService.id;
			const dataSharing = item.datasharing || null;
			product.ProductType = item.productType || null;
			product.benefitsFlag = item.benefitsFlag || null;
			product.productExpirationDate = item.productExpirationDate || null;
			product.mobility = item.mobility || null;
			product.ableToBook = item.ableToBook || false;

			product.channelsTV = (item.channels || []).map((item) => {
				const chn: Channels = new Channels();
				chn.code = item.code || null;
				chn.currentStatus = item.status?.current || null;
				chn.descriptionLong = item.description?.long || null;
				chn.fee = item.cost?.fee || null;
				chn.monthly = item.cost?.monthly || null;
				chn.name = item.name || null;
				chn.monthlyCurrency = item.cost?.monthlyCurrency || null;
				return chn;
			});

			product.addons = (item.addons || []).map((item) => {
				const addon: OpAddon = new OpAddon();
				addon.id = item.id || null;
				addon.name = item.name || null;
				addon.code = item.code || null;
				addon.serviceType = item.serviceType || null;
				addon.type = item.type || null;
				addon.description = item.description || null;
				addon.groupAddon = item.grupoAddon || null;
				addon.costMonthly = item.cost?.monthly || null;
				addon.costMonthlyCurrency = item.cost?.monthlyCurrency || null;
				addon.costMonthlyWithoutIVA = item.cost?.monthlyWithoutIVA || null;
				addon.costMonthlyCurrencyWithoutIVA = item.cost?.monthlyCurrencyWithoutIVA || null;

				return addon;
			});

			if (product.mobility) {
				// jsonpath refactor: params read as item['xxx'] are missing in model
				const consumptionAllownce = product.mobility['allowance'] || null;
				if (consumptionAllownce) {
					product.mobility.IsUnLimited = consumptionAllownce.unlimited || false;
					if (!product.mobility.IsUnLimited) {
						product.mobility.TotalConsumption = parseFloat(consumptionAllownce.availableAllowance) || 0;
						product.mobility.TotalConsumptionUnit = consumptionAllownce.availableAllowanceUnitCode
							? consumptionAllownce.availableAllowanceUnitCode.toUpperCase()
							: '';
						product.mobility.ConsumedConsumption =
							consumptionAllownce.usedAllowance !== undefined ? parseFloat(consumptionAllownce.usedAllowance) : null;
						product.mobility.ConsumedConsumptionUnit = consumptionAllownce.usedAllowanceUnitCode
							? consumptionAllownce.usedAllowanceUnitCode.toUpperCase()
							: '';
					}
					product.mobility.RemainingConsumptionPercentage = product.mobility.TotalConsumption
						? Math.trunc(
							((product.mobility.TotalConsumption - product.mobility.ConsumedConsumption) * 100) /
							product.mobility.TotalConsumption
						)
						: 0;
				}
			}

			product.OrderCreationDate = item.order?.creationDate || null;
			const ProductCharacteristics = item.productCharacteristics || null;
			if (ProductCharacteristics) {
				product.CallType = item.productCharacteristics?.callType || null;
				product.ProductCategory = item.productCharacteristics?.category || null;
			}
			if (item.status) {
				product.lastStatus = item.status?.previous || '';
				product.currentStatus = item.status?.current || null;
				product.transactionStatus = item.status?.transaction || null;
				product.ChannelDeactivationLocked = item.status?.deactivationlocked || null;
				product.ChannelDeactivationLockedCode = item.status?.messageCode || null;
			}
			const extensions = item.extension?.es || null;
			if (extensions) {
				product.Special = item.extension.es.isSpecialBundle || false;
				product.Restrictions = item.extension.es.restrictions || null;
				if (product.Restrictions) {
					product.Restrictions = new ProductRestriction();
					product.Restrictions.id = item.extension.es.restrictions[0]?.id || null;
					product.Restrictions.isChargeToBill = item.extension.es.restrictions[0]?.isChargeToBill || null;
				}
			}
			const consumption = item.consumption || null;
			if (consumption) {
				const consumptionAllownce = consumption.allowance || null;
				if (consumptionAllownce) {
					product.TotalConsumption = parseFloat(consumptionAllownce.availableAllowance) || 0;
					product.TotalConsumptionUnit = consumptionAllownce.worryFree?.remainingStep?.allowance
						.availableAllowanceUnitCode
						? consumptionAllownce.availableAllowanceUnitCode.toUpperCase()
						: '';
					product.ConsumedConsumption =
						consumptionAllownce.usedAllowance !== undefined ? parseFloat(consumptionAllownce.usedAllowance) : null;
					product.ConsumedConsumptionUnit = consumptionAllownce.worryFree?.remainingStep?.allowance
						?.usedAllowanceUnitCode
						? consumptionAllownce.usedAllowanceUnitCode.toUpperCase()
						: '';
					product.IsUnLimited = consumptionAllownce.unlimited || false;
				}
				product.ConsumptionExpiryDate = item.consumption?.expiryDate || null;
			}
			product.activationDate = item.activationDate || null;
			product.RemainingConsumptionPercentage = product.TotalConsumption
				? Math.trunc(((product.TotalConsumption - product.ConsumedConsumption) * 100) / product.TotalConsumption)
				: 0;
			product.RemainingConsumptionDataShareing = item.consumption?.allowance?.remainingAllowance || null;

			if (product.RemainingConsumptionDataShareing) {
				if (product.RemainingConsumptionDataShareing >= 0) {
					product.RemainConsumptionData =
						product.RemainingConsumptionDataShareing >= 1000
							? product.RemainingConsumptionDataShareing / 1024
							: product.RemainingConsumptionDataShareing;
				} else {
					product.RemainConsumptionData = 0;
				}
				product.RemainConsumptionUnit =
					product.RemainingConsumptionDataShareing >= 1000
						? this.utilities.getUnitCodeFromWCS(UnitCode.GB)
						: this.utilities.getUnitCodeFromWCS(UnitCode.MB);
			} else if (
				product.Type &&
				product.Type.toLocaleLowerCase() === BundleType.Data &&
				product.TotalConsumption - product.ConsumedConsumption < 1000
			) {
				product.RemainConsumptionData =
					product.TotalConsumption - product.ConsumedConsumption >= 0
						? product.TotalConsumption - product.ConsumedConsumption
						: 0;
				product.RemainConsumptionUnit = this.utilities.getUnitCodeFromWCS(UnitCode.MB);
			} else if (product.Type && product.Type.toLocaleLowerCase() === BundleType.Data) {
				product.RemainConsumptionData = (product.TotalConsumption - product.ConsumedConsumption) / 1024;
				product.RemainConsumptionUnit = this.utilities.getUnitCodeFromWCS(UnitCode.GB);
			}

			if (product.Type && product.Type.toLocaleLowerCase() === BundleType.SMS) {
				product.RemainConsumptionUnit = this.utilities.getUnitCodeFromWCS(UnitCode.SMS);
			}

			if (product.RemainConsumptionData && product.RemainConsumptionData.toString().indexOf('.') > -1) {
				product.RemainConsumptionData = +product.RemainConsumptionData.toString().substring(
					0,
					product.RemainConsumptionData.toString().indexOf('.') + 3
				);
			}
			product.RemainConsumptionExtraVoice = Math.trunc((product.TotalConsumption - product.ConsumedConsumption) / 60);
			if (product.RemainConsumptionExtraVoice) {
				product.RemainConsumptionExtraVoice =
					product.RemainConsumptionExtraVoice < 0 ? 0 : Math.trunc(product.RemainConsumptionExtraVoice);
			}

			product.RemainConsumptionMinutes =
				this.subscriptionService.customerData.currentService.type.toLowerCase() === ServiceType.Fixed.toLowerCase() ||
					this.subscriptionService.customerData.currentService.type.toLowerCase() === ServiceType.Landline.toLowerCase()
					? Math.trunc((product.TotalConsumption - product.ConsumedConsumption) / 60)
					: this.utilities.roundDecimals((product.TotalConsumption - product.ConsumedConsumption) / 60, 2);
			if (product.RemainConsumptionMinutes) {
				product.RemainConsumptionMinutes =
					product.RemainConsumptionMinutes < 0 ? 0 : Math.trunc(product.RemainConsumptionMinutes);
			}
			product.ShortDescription = item.description?.short || null;
			product.LongDescription = item.description?.long || null;

			const payment = item.payment || null;
			if (payment) {
				product.PayPerUse = item.payment?.perUse || false;
			}
			const cost = item.cost || null;

			if (cost) {
				product.Interval = item.cost?.interval || null;
				product.CallSetup = item.cost?.setup || null;
				product.CallSetupCurrerncy = item.cost?.setupCurrency || null;
				product.fee =
					item.cost?.monthly !== undefined && item.cost?.monthly !== ''
						? item.cost?.monthly.toString().replace('.', ',')
						: null;
				product.feeUnit = item.cost?.monthlyCurrency || null;
				if (product.feeUnit) {
					product.feeUnit = product.feeUnit.replace('|', '/');
				}
			}
			const enjoyMore = item.enjoyMore || null;

			if (enjoyMore && Object.keys(enjoyMore).length) {
				product.EnjoyMoreRenewalDate = item.enjoyMore?.validUntil || null;
				product.EnjoyMoreIncludedApplicationsNames = new Array<string>();
				item.EnjoyMoreIncludedApplications?.map((el) => {
					product.EnjoyMoreIncludedApplicationsNames.push(el.name);
				});
			}
			const worryFree = item.worryFree || null;
			product.monthlyCurrency = product.feeUnit || null;
			if (worryFree) {
				product.worryFree = new WorryFree();
				product.worryFree.isCurrentlyUsed = item.worryFree.isCurrentlyUsed;
				product.worryFree.currentStep = item.worryFree.currentStep || 0;
				product.worryFree.limit = item.worryFree.limit || null;
				product.worryFree.maximumSteps = item.worryFree.maximumSteps || 0;
				product.worryFree.totalPricePaid = item.worryFree.totalPricePaid || null;
				const remainingStep = item.worryFree.remainingStep || null;
				if (remainingStep) {
					product.worryFree.remainingStep = new RemainingStep();
					product.worryFree.remainingStep.name = item.worryFree.remainingStep.name || null;
					product.worryFree.remainingStep.availableAllowance =
						item.worryFree.remainingStep.allowance?.availableAllowance || null;
					product.worryFree.remainingStep.availableAllowanceUnitCode = item.worryFree.remainingStep.allowance
						.availableAllowanceUnitCode
						? item.worryFree.remainingStep.allowance.availableAllowanceUnitCode.toUpperCase()
						: null;
					if (item.worryFree.remainingStep.cost) {
						product.worryFree.remainingStep.costOneTime = item.worryFree.remainingStep.cost.oneTime || null;
						product.worryFree.remainingStep.costOneTimeCurrency =
							item.worryFree.remainingStep.cost.oneTimeCurrency || null;
					}
					product.worryFree.remainingStep.totalLimit = item.worryFree.remainingStep.totalLimit || null;
					product.worryFree.remainingStep.usedAllowance = item.worryFree.remainingStep.allowance?.usedAllowance || null;
					product.worryFree.remainingStep.usedAllowanceUnitCode = item.worryFree.remainingStep.allowance
						.usedAllowanceUnitCode
						? item.worryFree.remainingStep.allowance?.usedAllowanceUnitCode?.toUpperCase()
						: null;
					product.worryFree.remainingStep.unlimited = item.worryFree.remainingStep.allowance?.unlimited || false;
					product.worryFree.remainingStep.totalallowance = item.worryFree.remainingStep.allowance || null;
				}
			}

			if (item.onlineTv) {
				product.onlineTv = new OnlineTv();
				product.onlineTv.hasPendingChannel = item.onlineTv.hasPendingChannel || null;
				product.onlineTv.group = item.onlineTv.group || null;
				product.onlineTv.email = item.onlineTv.email || null;
				let promotions: Promotion[] = new Array<Promotion>();
				if (item.onlineTv.promotions) {
					promotions = (item.onlineTv.promotions as any[]).map((el) => {
						const obj = new Promotion();
						obj.fee = el.fee || null;
						obj.feeUnit = el.feeunit || null;
						obj.feeNumber = obj.fee ? Number(obj.fee) : 0;
						obj.serviceIds = (el.serviceIds as string[]) || null;
						obj.status = el.status || null;
						obj.code = el.code || null;
						obj.activationDate = el.activationDate || null;
						obj.channelCode = el.channelCode || null;
						obj.name = el.name || null;
						return obj;
					});
				}
				product.onlineTv.promotions = promotions;
			}
			if (dataSharing) {
				product.isShared = dataSharing.isShared || false;
			}
			if (item.subscription) {
				product.sharingDataType = item.subscription.dataSharingType || null;
			}
			product.subType = this.getBundleSubtype(product);
			if ([ProductSubtype.EnjoyMore, ProductSubtype.EnjoyMoreExtra].includes(product.subType)) {
				this.setEnjoyMoreIcons(product);
			}
			const enjoyMorePromotions = item.promotions || null;
			if (enjoyMorePromotions && _.isArray(enjoyMorePromotions)) {
				product.oneMonthPromotionCode = item.promotions[0].code || null;
				product.oneMonthPromotionStatus = item.promotions[0].status || null;
			}
			product.productAlert = item.alert;
			product.incompatibilities = item.incompatibilities || [];

			product.tvDiscounts = item.discount || null;
			product.discountedFee = product.tvDiscounts
				? Number(product.tvDiscounts.fee?.replace(',', '.'))
				: Number(product.fee?.replace(',', '.'));
			product.billinDiscount = item.discount || null;
			product.billinCustomerAcount = item.customerAccount || null;
			product.voucherCode = item.voucher?.voucher || null;
			product.availableShipping = item.availableShipping || ShippingModesTv.Tecnico;

			this.products.push(product);
		});
		this.setProductTaggingCrossVariables();
	}

	setProductTaggingCrossVariables() {
		const standAloneTvProducts = this.products.filter((item) => {
			return (
				item.ProductType &&
				item.ProductType.toLowerCase() === ProductType.OnlineTvPackage &&
				item.currentStatus.toLowerCase() === BundleStatus.Active.toLowerCase()
			);
		});
		if (this.subscriptionService.customerData.currentService.type.toLowerCase() !== ServiceType.Tv.toLowerCase()) {
			if (standAloneTvProducts && standAloneTvProducts.length > 0) {
				this.storageService.productAndServiceVariables.tv_online_status =
					tagging.staticVariables.tvOnlineStatus.standAlone;
				this.storageService.productAndServiceVariables.tv_online_bundle =
					tagging.staticVariables.onlineTvBundle.standalone.replace(
						'{0}',
						_.join(_.map(standAloneTvProducts, 'Name'), ',')
					);
			} else {
				this.storageService.productAndServiceVariables.tv_online_bundle = tagging.staticVariables.onlineTvBundle.sin;
			}
		}
		const entertainmentProducts = this.products.filter((item) => {
			return item.Type && item.Type.toLowerCase() === BundleType.Entertainment.toLowerCase();
		});
		if (entertainmentProducts && entertainmentProducts.length > 0) {
			this.storageService.productAndServiceVariables.entertaiment_type = _.join(
				_.map(entertainmentProducts, 'Name'),
				','
			);
		} else {
			this.storageService.productAndServiceVariables.entertaiment_type = '';
		}

		const extraProducts = this.products.filter((item) => {
			return (
				item.ProductType &&
				(item.ProductType.toLowerCase() === ProductType.Extras.toLowerCase() ||
					item.ProductType.toLowerCase() === ProductType.WorryFree.toLowerCase() ||
					item.ProductType.toLowerCase() === ProductType.OnlineTvPackage.toLowerCase() ||
					item.ProductType.toLowerCase() === ProductType.Content.toLowerCase()) &&
				item.currentStatus.toLowerCase() !== BundleStatus.Inactive.toLowerCase()
			);
		});
		if (extraProducts && extraProducts.length > 0) {
			this.storageService.productAndServiceVariables.extra_status = extraProducts
				.map((item) => {
					this.translateService.get('productsServices').subscribe((data) => {
						this.worryFreeName = data.worryFree.vesSectionText;
					});
					let mappedValue = '';
					if (item.ProductType.toLowerCase() === ProductType.WorryFree.toLowerCase()) {
						mappedValue = this.worryFreeName;
					} else {
						mappedValue = item.Name ? item.Name : '';
					}
					switch (item.currentStatus.toLowerCase()) {
						case BundleStatus.Active.toLowerCase():
							mappedValue += ':' + tagging.staticVariables.extraStatus.active;
							break;
						case BundleStatus.PendingActivation.toLowerCase():
						case BundleStatus.PendingDeactivation.toLowerCase():
							mappedValue += ':' + tagging.staticVariables.extraStatus.pending;
							break;
					}
					return mappedValue;
				})
				.join(',');
		} else {
			this.storageService.productAndServiceVariables.extra_status = '';
		}
		const enjoyMoreDataProducts = this.products.filter((item) => {
			return (
				item.ProductType &&
				item.ProductType.toLowerCase() === ProductType.EnjoyMore.toLowerCase() &&
				item.Type &&
				item.Type.toLowerCase() === BundleType.Data &&
				item.ProductCategory &&
				item.ProductCategory.toLowerCase() !== ProductCategory.Adhoc.toLowerCase() &&
				item.currentStatus &&
				item.currentStatus.toLowerCase() === BundleStatus.Active.toLowerCase()
			);
		});

		if (enjoyMoreDataProducts && enjoyMoreDataProducts.length > 0) {
			this.storageService.productAndServiceVariables.special_data_bundles_status = enjoyMoreDataProducts
				.map((item) => {
					let mappedValue = item.Name ? item.Name : item.Code;
					if (
						item.oneMonthPromotionStatus &&
						item.oneMonthPromotionStatus.toLowerCase() === BundleStatus.Active.toLowerCase()
					) {
						return (mappedValue += ':' + tagging.staticVariables.specialDataBundlesStatus.promotion);
					}
					// Basic
					if (item.ProductCategory.toLowerCase() === ProductCategory.Basic.toLowerCase()) {
						return (mappedValue += ':' + tagging.staticVariables.specialDataBundlesStatus.basic);
					} else if (
						item.ProductCategory.toLowerCase() === ProductCategory.Monthly.toLowerCase() &&
						item.currentStatus.toLowerCase() === BundleStatus.Active.toLowerCase()
					) {
						// postpaid
						switch (this.subscriptionService.customerData.currentService.type.toLowerCase()) {
							case ServiceType.Postpaid.toLowerCase() || ServiceType.MbbPostpaid.toLowerCase():
								mappedValue += ':' + tagging.staticVariables.specialDataBundlesStatus.prepaidWithBenefitOrPostpaid;
								break;
							case ServiceType.Prepaid.toLowerCase() || ServiceType.MbbPrepaid.toLowerCase():
								// prepaid with benefit
								if (item.withBenefit === true) {
									mappedValue += ':' + tagging.staticVariables.specialDataBundlesStatus.prepaidWithBenefitOrPostpaid;
								}
								// prepaid without benefit
								else {
									mappedValue += ':' + tagging.staticVariables.specialDataBundlesStatus.prepaidWithoutBenefit;
								}
								break;
						}
					}
					return mappedValue;
				})
				.join(',');
		} else {
			this.storageService.productAndServiceVariables.special_data_bundles_status = '';
		}

		if (this.subscriptionService.customerData.currentService.type.toLowerCase() === ServiceType.Tv.toLowerCase()) {
			if (this.storageService.isHoreca) {
				this.storageService.productAndServiceVariables.tv_online_status = tagging.staticVariables.tvOnlineStatus.notv;
				this.storageService.productAndServiceVariables.tv_online_bundle = tagging.staticVariables.onlineTvBundle.sin;
			}
		}
	}
	getProductByProductType(productType) {
		const serviceId = this.storageService.navigateToSecureNetFromBilling
			? this.storageService.secureNetAlertServiceId
			: this.subscriptionService.customerData.currentService.id;
		const customerAccountsId = this.storageService.navigateToSecureNetFromBilling
			? this.storageService.secureNetAccountId
			: this.subscriptionService.customerData.customerAccountsId;
		const url = API_URLS.Product.ProductByType.replace('{subscription-id}', serviceId)
			.replace('{customer-account-id}', customerAccountsId)
			.replace('{product-type}', productType);
		let headers = new HttpHeaders();
		headers = headers.append('Content-Type', 'application/json');
		headers = headers.append(
			'Authorization',
			'Bearer ' + this.storageService.getLocalStorage(constants.LOCAL_STORAGE_KEYS.ACCESS_TOKEN)
		);
		const options = {
			headers: headers,
		};
		return this.http.get(url, options).pipe(
			map((res) => {
				this.productLogic(res);
				return res;
			})
		);
	}
	@evict(cachingKeys.Products, cachingKeys.Consumption, cachingKeys.getCurrentConsumption)
	BuyOrRemoveBundle(
		id: string,
		status?: string,
		promotionCode?: string,
		email?: string | boolean,
		withBenefit?: boolean,
		products?: any[],
		office?: DeliveryOffice,
		autoinstallment?: boolean
	): Observable<string> {
		const url = API_URLS.Product.BuyOrRemoveBundle.replace('{id}', id);
		let body = {};
		// in case renew benfits will send in the body benefitsFlag true only
		if (withBenefit) {
			body['benefitsFlag'] = withBenefit;
		} else {
			body = {
				status: {
					current: status,
				},
			};
			if (promotionCode) {
				body['promotion'] = promotionCode;
			}
			if (email) {
				body['email'] = email;
			}
			if (products) {
				body['products'] = products;
			}
			if (autoinstallment) {
				if (office) {
					body['deliveryAddress'] = {
						codired: office.codired,
						officeHours: office.horario,
						officePhone: office.telefono,
						deliveryOptions: { deliveryType: COE },
					};
				} else {
					body['deliveryAddress'] = { deliveryOptions: { deliveryType: B5I } };
				}
			}
		}

		let headers = new HttpHeaders();

		headers = headers.append('Content-Type', 'application/merge-patch+json');
		headers = headers.append(
			'Authorization',
			'Bearer ' + this.storageService.getLocalStorage(constants.LOCAL_STORAGE_KEYS.ACCESS_TOKEN)
		);
		const options = {
			headers: headers,
			responseType: 'text' as 'text',
		};

		return this.http.patch(url, body, options).pipe(map((res) => res));
	}
	updateCpe(id) {
		const url = API_URLS.cpe.update.replace('{id}', id);
		let body = {};

		body = {
			status: {
				current: 'active',
			},
		};

		let headers = new HttpHeaders();

		headers = headers.append('Content-Type', 'application/merge-patch+json');
		headers = headers.append(
			'Authorization',
			'Bearer ' + this.storageService.getLocalStorage(constants.LOCAL_STORAGE_KEYS.ACCESS_TOKEN)
		);
		const options = {
			headers: headers,
			responseType: 'text' as 'text',
		};

		return this.http.patch(url, body, options).pipe(map((res) => res));
	}

	getBundleSubtype(bundle: Product): ProductSubtype {
		if (bundle.ProductType) {
			if (
				bundle.ProductCategory &&
				bundle.ProductCategory.toLocaleLowerCase() === ProductCategory.Basic.toLocaleLowerCase()
			) {
				switch (bundle.Type.toLocaleLowerCase()) {
					case BundleType.Data.toLocaleLowerCase():
						if (bundle.ProductType.toLocaleLowerCase() === ProductType.Product.toLocaleLowerCase()) {
							// {{Inclusive data bundle}} Product-type = product (and) type = data (and) product-characteristics.category =	basic
							return ProductSubtype.Data;
						} else if (bundle.ProductType.toLocaleLowerCase() === ProductType.EnjoyMore.toLocaleLowerCase()) {
							// {{Inclusive data bundle}} Product-type = enjoy-more (and) type = data (and) product-characteristics.category =	basicle);
							return ProductSubtype.EnjoyMore;
						}
						break;
					case BundleType.Voice.toLocaleLowerCase():
						if (
							bundle.ProductType.toLocaleLowerCase() === ProductType.Product &&
							bundle.CallType &&
							bundle.CallType.toLocaleLowerCase() === CallType.National.toLocaleLowerCase()
						) {
							// {{Inclusive fix to mobile bundle}} Product-type = product (and) type = Voice
							// (and) product-characteristics.category =	basic  (and) call-type = National
							return ProductSubtype.FixedToMobile;
						} else if (
							bundle.ProductType.toLocaleLowerCase() === ProductType.Product &&
							bundle.CallType &&
							bundle.CallType.toLocaleLowerCase() === CallType.International.toLocaleLowerCase()
						) {
							// {{Inclusive fix to international bundle}} Product-type = product (and) type = Voice
							// (and) product-characteristics.category =	basic (and) call-type = International
							return ProductSubtype.FixedToInternational;
						} else if (bundle.ProductType.toLocaleLowerCase() === ProductType.Product.toLocaleLowerCase()) {
							// {{Inclusive voice bundle}} Product-type = product (and) type = Voice
							// (and) product-characteristics.category =	basic
							return ProductSubtype.Minutes;
						}
						break;
					case BundleType.SMS.toLocaleLowerCase():
						if (bundle.ProductType.toLocaleLowerCase() === ProductType.Product.toLocaleLowerCase()) {
							// {{Inclusive sms bundle}} Product-type = product (and) type = sms (and) product-characteristics.category =	basic
							return ProductSubtype.Sms;
						}
						break;
					case BundleType.Fixed.toLocaleLowerCase():
						if (
							bundle.ProductType.toLocaleLowerCase() === ProductType.Product &&
							bundle.CallType &&
							bundle.CallType.toLocaleLowerCase() === CallType.National.toLocaleLowerCase()
						) {
							// {{Inclusive fix to fix bundle}} Product-type = product (and) type = fixed
							// (and) product-characteristics.category =	basic  (and) call-type = National
							return ProductSubtype.FixedToFixed;
						}
						break;
					default:
						break;
				}
			} else if (
				bundle.ProductCategory &&
				bundle.ProductCategory.toLocaleLowerCase() === ProductCategory.Monthly.toLocaleLowerCase()
			) {
				// product-characteristics.category = Monthly
				switch (bundle.Type.toLocaleLowerCase()) {
					case BundleType.Data.toLocaleLowerCase():
						if (
							bundle.ProductType &&
							bundle.ProductType.toLocaleLowerCase() === ProductType.Extras.toLocaleLowerCase() &&
							(bundle.currentStatus.toLowerCase() === BundleStatus.Active.toLowerCase() ||
								bundle.currentStatus.toLowerCase() === BundleStatus.PendingDeactivation.toLowerCase())
						) {
							// {{Inclusive Extra product}} Product-type = product (and) type = data (and) product-characteristics.category =	Monthly
							return ProductSubtype.Extra;
						} else if (
							bundle.ProductType &&
							bundle.ProductType.toLocaleLowerCase() === ProductType.EnjoyMore.toLocaleLowerCase()
						) {
							// {{Inclusive Extra enjoy more}} Product-type = enjoy-more (and) type = data
							// (and) product-characteristics.category =	Monthly
							return ProductSubtype.EnjoyMoreExtra;
						}
						break;
					case BundleType.Voice.toLocaleLowerCase():
						if (
							bundle.ProductType.toLocaleLowerCase() === ProductType.Extras.toLocaleLowerCase() &&
							(bundle.currentStatus.toLowerCase() === BundleStatus.Active.toLowerCase() ||
								bundle.currentStatus.toLowerCase() === BundleStatus.PendingDeactivation.toLowerCase())
						) {
							// {{Inclusive Extra product}} Product-type = product (and) type = data (and) product-characteristics.category =	Monthly
							return ProductSubtype.Extra;
						}
						break;
					case BundleType.Entertainment.toLocaleLowerCase():
						if (
							(bundle.currentStatus && bundle.currentStatus.toLowerCase() === BundleStatus.Active.toLowerCase()) ||
							bundle.currentStatus.toLowerCase() === BundleStatus.PendingDeactivation.toLowerCase()
						) {
							return ProductSubtype.Extra;
						}

						break;
					case BundleType.SMS.toLocaleLowerCase():
						if (
							bundle.ProductType &&
							bundle.ProductType.toLocaleLowerCase() === ProductType.Extras.toLocaleLowerCase() &&
							(bundle.currentStatus.toLowerCase() === BundleStatus.Active.toLowerCase() ||
								bundle.currentStatus.toLowerCase() === BundleStatus.PendingDeactivation.toLowerCase())
						) {
							// {{Inclusive Extra product}} Product-type = product (and) type = sms (and) product-characteristics.category =	Monthly
							return ProductSubtype.Extra;
						}
						break;
					default:
						break;
				}
			} else if (
				(bundle.ProductCategory &&
					bundle.ProductCategory.toLocaleLowerCase() === ProductCategory.Adhoc.toLocaleLowerCase()) ||
				(bundle.ProductCategory &&
					bundle.ProductCategory.toLocaleLowerCase() === ProductCategory.Accumulate.toLocaleLowerCase())
			) {
				// product-characteristics.category = Adhoc
				if (
					(bundle.Type.toLocaleLowerCase() === BundleType.Data.toLocaleLowerCase() ||
						bundle.Type.toLocaleLowerCase() === BundleType.Voice.toLocaleLowerCase()) &&
					bundle.ProductType.toLocaleLowerCase() === ProductType.Extras.toLocaleLowerCase() &&
					(bundle.currentStatus.toLowerCase() === BundleStatus.Active.toLowerCase() ||
						bundle.currentStatus.toLowerCase() === BundleStatus.PendingDeactivation.toLowerCase())
				) {
					// {{Inclusive Extra product}} Product-type = product (and) type = sms (and) product-characteristics.category =	Monthly
					return ProductSubtype.Extra;
				}
			}
		}
	}
	resetBundleSubtypeForMbbHoliday(bundle: Product) {
		if (
			bundle.ProductCategory &&
			bundle.ProductCategory.toLocaleLowerCase() === ProductCategory.Monthly.toLocaleLowerCase()
		) {
			// product-characteristics.category = Monthly
			switch (bundle.Type.toLocaleLowerCase()) {
				case BundleType.Data.toLocaleLowerCase():
					if (
						bundle.ProductType &&
						bundle.ProductType.toLocaleLowerCase() === ProductType.Extras.toLocaleLowerCase() &&
						(bundle.currentStatus.toLowerCase() === BundleStatus.Active.toLowerCase() ||
							bundle.currentStatus.toLowerCase() === BundleStatus.PendingDeactivation.toLowerCase())
					) {
						// {{Inclusive Extra product}} Product-type = product (and) type = data (and) product-characteristics.category =	Monthly
						bundle.subType = ProductSubtype.Extra;
					} else if (
						bundle.ProductType &&
						bundle.ProductType.toLocaleLowerCase() === ProductType.EnjoyMore.toLocaleLowerCase()
					) {
						// {{Inclusive Extra enjoy more}} Product-type = enjoy-more (and) type = data
						// (and) product-characteristics.category =	Monthly
						bundle.subType = ProductSubtype.EnjoyMoreExtra;
					}
					break;
				case BundleType.Voice.toLocaleLowerCase():
					if (
						bundle.ProductType.toLocaleLowerCase() === ProductType.Extras.toLocaleLowerCase() &&
						(bundle.currentStatus.toLowerCase() === BundleStatus.Active.toLowerCase() ||
							bundle.currentStatus.toLowerCase() === BundleStatus.PendingDeactivation.toLowerCase())
					) {
						// {{Inclusive Extra product}} Product-type = product (and) type = data (and) product-characteristics.category =	Monthly
						bundle.subType = ProductSubtype.Extra;
					}
					break;
				case BundleType.Entertainment.toLocaleLowerCase():
					if (
						(bundle.currentStatus && bundle.currentStatus.toLowerCase() === BundleStatus.Active.toLowerCase()) ||
						bundle.currentStatus.toLowerCase() === BundleStatus.PendingDeactivation.toLowerCase()
					) {
						bundle.subType = ProductSubtype.Extra;
					}

					break;
				case BundleType.SMS.toLocaleLowerCase():
					if (
						bundle.ProductType &&
						bundle.ProductType.toLocaleLowerCase() === ProductType.Extras.toLocaleLowerCase() &&
						(bundle.currentStatus.toLowerCase() === BundleStatus.Active.toLowerCase() ||
							bundle.currentStatus.toLowerCase() === BundleStatus.PendingDeactivation.toLowerCase())
					) {
						// {{Inclusive Extra product}} Product-type = product (and) type = sms (and) product-characteristics.category =	Monthly
						bundle.subType = ProductSubtype.Extra;
					}
					break;
				default:
					break;
			}
		} else if (
			bundle.ProductCategory &&
			bundle.ProductCategory.toLocaleLowerCase() === ProductCategory.Adhoc.toLocaleLowerCase()
		) {
			// product-characteristics.category = Adhoc
			if (
				bundle.Type.toLocaleLowerCase() === BundleType.Data.toLocaleLowerCase() &&
				bundle.ProductType.toLocaleLowerCase() === ProductType.Extras.toLocaleLowerCase() &&
				(bundle.currentStatus.toLowerCase() === BundleStatus.Active.toLowerCase() ||
					bundle.currentStatus.toLowerCase() === BundleStatus.PendingDeactivation.toLowerCase())
			) {
				// {{Inclusive Extra product}} Product-type = product (and) type = sms (and) product-characteristics.category =	Monthly
				bundle.subType = ProductSubtype.Extra;
			}
		}
	}

	setEnjoyMoreIcons(product: Product) {
		this.translateService.get('productsServices').subscribe((data) => {
			const enjoyMoreId = product.Code.split('_')[0];
			if (
				this.subscriptionService.customerData.currentService.type.toLowerCase() === ServiceType.Prepaid.toLowerCase()
			) {
				product.enjoyMoreActiveIcon = data.enjoyMore.prepaidPasses[enjoyMoreId]
					? data.enjoyMore.prepaidPasses[enjoyMoreId][`${enjoyMoreId}_active_icon`]
						? data.enjoyMore.prepaidPasses[enjoyMoreId][`${enjoyMoreId}_active_icon`]['url']
						: constants.defaultEnjoyMoreIcon
					: constants.defaultEnjoyMoreIcon;
				product.enjoyMoreInactiveIcon = data.enjoyMore.prepaidPasses[enjoyMoreId]
					? data.enjoyMore.prepaidPasses[enjoyMoreId][`${enjoyMoreId}_inactive_icon`]
						? data.enjoyMore.prepaidPasses[enjoyMoreId][`${enjoyMoreId}_inactive_icon`]['url']
						: constants.defaultEnjoyMoreIcon
					: constants.defaultEnjoyMoreIcon;
				product.enjoyMoreInactiveIcon = data.enjoyMore.prepaidPasses[enjoyMoreId]
					? data.enjoyMore.prepaidPasses[enjoyMoreId][`${enjoyMoreId}_black_icon`]
						? data.enjoyMore.prepaidPasses[enjoyMoreId][`${enjoyMoreId}_black_icon`]['url']
						: constants.defaultEnjoyMoreIcon
					: constants.defaultEnjoyMoreIcon;
			} else {
				product.enjoyMoreActiveIcon = data.enjoyMore.passes[enjoyMoreId]
					? data.enjoyMore.passes[enjoyMoreId][`${enjoyMoreId}_active_icon`]
						? data.enjoyMore.passes[enjoyMoreId][`${enjoyMoreId}_active_icon`]['url']
						: constants.defaultEnjoyMoreIcon
					: constants.defaultEnjoyMoreIcon;
				product.enjoyMoreInactiveIcon = data.enjoyMore.passes[enjoyMoreId]
					? data.enjoyMore.passes[enjoyMoreId][`${enjoyMoreId}_inactive_icon`]
						? data.enjoyMore.passes[enjoyMoreId][`${enjoyMoreId}_inactive_icon`]['url']
						: constants.defaultEnjoyMoreIcon
					: constants.defaultEnjoyMoreIcon;
				product.enjoyMoreInactiveIcon = data.enjoyMore.passes[enjoyMoreId]
					? data.enjoyMore.passes[enjoyMoreId][`${enjoyMoreId}_black_icon`]
						? data.enjoyMore.passes[enjoyMoreId][`${enjoyMoreId}_black_icon`]['url']
						: constants.defaultEnjoyMoreIcon
					: constants.defaultEnjoyMoreIcon;
			}
		});
	}

	GetAllProductsOnlyPostPaid() {
		const mobilePostPaid: ServiceModel[] = this.subscriptionService.serviceListSite.filter(
			(el) => el.type.toUpperCase() === ServiceType.Postpaid.toUpperCase()
		);
		if (!mobilePostPaid || mobilePostPaid.length === 0) {
			mobilePostPaid[0] = this.subscriptionService.customerData.currentService;
		}

		const url = API_URLS.Product.Products.replace('{subscription-id}', mobilePostPaid[0].id).replace(
			'{customer-account-id}',
			this.subscriptionService.customerData.customerAccountsId
		);
		let headers = new HttpHeaders();
		headers = headers.append('Content-Type', 'application/json');
		headers = headers.append(
			'Authorization',
			'Bearer ' + this.storageService.getLocalStorage(constants.LOCAL_STORAGE_KEYS.ACCESS_TOKEN)
		);
		const options = {
			headers: headers,
		};
		return this.http.get(url, options).pipe(
			map((res) => {
				this.productLogic(res);
				return res;
			})
		);
	}

	/**get currency unit from API */
	getCurrencyCode(monthlyRecurringCurrency: string): string {
		if (monthlyRecurringCurrency && monthlyRecurringCurrency.indexOf('/') !== -1) {
			return monthlyRecurringCurrency.trim().substr(0, monthlyRecurringCurrency.indexOf('/'));
		} else {
			return monthlyRecurringCurrency;
		}
	}

	getCurrentBundleWorrieFree(): Product {
		let dataBundle: Product;
		let dataBundles: Product[] = [];
		dataBundles = this.products.filter((el) => (el.worryFree));
		if (dataBundles.length) {
			const currentBundle =
				dataBundles.find((el) => el.currentStatus.toLowerCase() === BundleStatus.Active.toLowerCase()) ||
				dataBundles.find((el) => el.currentStatus.toLowerCase() === BundleStatus.ChangeLimit.toLowerCase()) ||
				dataBundles.find(
					(el) => el.currentStatus.toLowerCase() === BundleStatus.PendingDeactivation.toLowerCase()
				);
			if (dataBundles.length === 1) {
				dataBundle = dataBundles[0];
			} else if (currentBundle) {
				dataBundle = currentBundle;
			} else if (dataBundles.find((el) => el.currentStatus.toLowerCase() === BundleStatus.PendingActivation.toLowerCase())) {
				dataBundle = dataBundles.find((el) => el.currentStatus.toLowerCase() === BundleStatus.PendingActivation.toLowerCase());
			} else {
				dataBundle = dataBundles[0];
			}
		}
		return dataBundle
	}
}
