import { HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Router, RouterStateSnapshot, UrlTree } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { Observable, of } from 'rxjs';
import { catchError, map, mergeMap } from 'rxjs/operators';
import { config } from '../../../config/pages-config';
import { tagging } from '../../../config/tagging-config';
import { SubscriptionService } from '../../core/services/subscription.service';
import { TaggingHelperService } from '../../core/services/tagging.helper.service';
import {
	ERRORCODES,
	dataToMF,
	entryPointsMVA10,
	errorFunction,
	errorTecnichal,
	journeyStatuseEnum,
	journeyTags,
	microFlowTypeAccess,
	microflowsTagg,
	urlServices,
} from '../../shared/constants/defines';
import { ClientTypology } from '../../shared/enums/clientTopology.enum';
import { UserSettingToken } from '../../shared/enums/user-setting-token.enum';
import { ErrorList } from '../../shared/models/error-list.model';
import { TaggingViewModel } from '../../shared/models/tagging.model';
import { CommercialOffersService } from '../../shared/services/commercial-offers.service';
import { DeepLinkingService } from '../../shared/services/deep-linking.service';
import { DispacherCaptaService } from '../../shared/services/dispacher-capta.service';
import { EntryPointsService } from '../../shared/services/entry-points.service';
import { MicroFlowsService } from '../../shared/services/micro-flows.service';
import { UtilitiesService } from '../../shared/utils/utilities.service';
import { AuthenticateService } from '../services/authenticate.service';
import { StorageService } from '../services/storage.service';

@Injectable({
	providedIn: 'root',
})
export class MicroFlowsGuard {
	public screenCode: string;
	public dataToFork: string[];
	public route: ActivatedRouteSnapshot;
	public cartid: string;
	public isMainFlow: boolean;
	private hasTokens: boolean = false;
	public hasAllowed: boolean = false;
	public hasEntryPoints: boolean = false;
	public allowedCode: string;
	public offerdescriptor: string;
	public interacionID: string;
	public rank: string;
	public channel: string;
	public needCallAllowedLines: boolean;

	constructor(
		public microFlowsService: MicroFlowsService,
		public entryPointService: EntryPointsService,
		public deepLinkingService: DeepLinkingService,
		public subscriptionData: SubscriptionService,
		private router: Router,
		private tagging: TaggingHelperService,
		private authenticateService: AuthenticateService,
		private translate: TranslateService,
		private storageService: StorageService,
		private utilitiesService: UtilitiesService,
		private dispacherCaptaService: DispacherCaptaService,
		private commercialOffersService: CommercialOffersService
	) {}

	/*******
  To ensure that the calls are made correctly,
  implement in the routing module
  canDeactivate: [MicroFlowDeactivateGuard]
  in parent component
  ******/
	canActivate(
		route: ActivatedRouteSnapshot,
		state: RouterStateSnapshot
	): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
		this.route = route;
		this.screenCode = this.microFlowsService.screenCode;
		if (this.route.data['screenCode'] === this.screenCode) {
			this.microFlowsService.retry = 0;
			return this.checkExpiredTokens() ? this.refreshCall() : true;
		} else {
			this.cartid = '';
			this.microFlowsService.cartId = '';
			this.offerdescriptor = '';
			this.checkParams();
			this.resetMF();
			this.dataToFork = new Array();
			this.setData();
			this.microFlowsService.setClientType();
			this.dataToFork.forEach((res) => {
				this.setServices(res);
			});
			return this.checkServices();
		}
	}

	checkParams(): void {
		if (this.route.queryParams.cartid) {
			this.cartid = this.route.queryParams.cartid;
			this.microFlowsService.cartId = this.cartid;
		}
		if (this.route.queryParams?.offerdescriptor) {
			this.offerdescriptor = this.route.queryParams.offerdescriptor;
		}
		if (this.route.queryParams?.interactionid) {
			this.interacionID = this.route.queryParams.interactionid;
		}
		if (this.route.queryParams?.rank) {
			this.rank = this.route.queryParams?.rank;
		}
		if (this.route.queryParams?.channel) {
			this.channel = this.route.queryParams?.channel;
		}
		this.microFlowsService.offerDescriptorDL = this.offerdescriptor;
	}

	private setData(): void {
		for (const prop in this.route.data) {
			if (prop === dataToMF.screenCode) {
				this.screenCode = this.route.data[prop];
				this.microFlowsService.screenCode = this.screenCode;
			} else if (this.microFlowsService.dataInMf.indexOf(prop) < 0) {
				this.dataToFork.push(prop);
				this.microFlowsService.dataPush(prop);
			}
		}
	}

	private setServices(service: string): void {
		switch (service) {
			case dataToMF.entryPoints:
				this.hasEntryPoints = true;
				break;
			case dataToMF.allowedServices:
				this.hasAllowed = true;
				break;
			case dataToMF.tokens:
				this.hasTokens = true;
				break;
		}
	}

	public setTaggingLogic(isMain: boolean): void {
		this.microFlowsService.isMainFlow = isMain ? true : false;
	}

	private caseAllowed(): Observable<any> {
		return this.microFlowsService
			.getAllowedServices(this.allowedCode, this.subscriptionData.customerData.customerAccountsId, true)
			.pipe(
				mergeMap((res) => {
					return this.caseTokens();
				}),
				catchError((error) => {
					this.errorNavigation(error);
					return error;
				})
			);
	}

	private caseTokens(): Observable<any> {
		return this.checkExpiredTokens() ? this.refreshCall() : this.tokensCall();
	}

	private resetMF(): void {
		this.microFlowsService.reset();
		this.hasTokens = false;
		this.hasEntryPoints = false;
		this.hasAllowed = false;
	}

	public generatedErrorNavigation(error?: HttpErrorResponse): void {
		this.microFlowsService.microflow = this.setAnalyticName(journeyStatuseEnum.ko);
		this.microFlowsService.microUrlService = urlServices.entryPoints;
		if (error?.error?.code) {
			this.microFlowsService.microTypeError = errorTecnichal;
			this.errorNavigation(error);
		} else {
			this.microFlowsService.microTypeError = errorFunction;
			this.errorNavigation();
		}
	}
	public ifGetEntryPoints(): Observable<any> {
		if (
			this.screenCode === entryPointsMVA10.HSR &&
			this.dispacherCaptaService.trastiendaKO(this.microFlowsService.responseEntrypoints)
		) {
			this.microFlowsService.showNTOLKO = true;
			return of(this.router.parseUrl(config.CommercialMobile.landing.route));
		} else {
			if (this.isOldHI()) {
				return of(this.router.parseUrl(config.migrahogar.route));
			} else if (this.hasAllowed) {
				this.allowedCode = this.entryPointService.flows[0]?.entryPoint ?? this.entryPointService.flows[0]?.code;
				this.needCallAllowedLines = true;
				return this.caseAllowed();
			}
			return this.caseTokens();
		}
	}
	public checkServices(): Observable<any> {
		if (this.hasEntryPoints) {
			this.sendTaggingAccess(microflowsTagg.access);
			this.microFlowsService.showNTOLKO = false;
			return this.microFlowsService
				.getEntryPoints(
					this.screenCode,
					this.subscriptionData.customerData.customerAccountsId,
					1,
					true,
					this.subscriptionData.customerData.currentService.id,
					null,
					this.cartid,
					this.offerdescriptor,
					this.interacionID,
					this.rank,
					this.channel
				)
				.pipe(
					mergeMap((res) => {
						this.sendTaggingAccess(microflowsTagg.statusep, journeyStatuseEnum.ok);
						if (res && !this.entryPointService.entryPointDebtOtError) {
							return this.ifGetEntryPoints();
						} else {
							this.generatedErrorNavigation();
						}
					}),
					catchError((error) => {
						this.generatedErrorNavigation(error);
						return error;
					})
				);
		} else {
			return this.caseTokens();
		}
	}

	/** In case the user needs to be redirected to migrahogar */
	public isOldHI(): boolean {
		return (
			this.entryPointService.flows[0]['flow'] === entryPointsMVA10.LAHI && this.screenCode === entryPointsMVA10.LAD
		);
	}

	public errorNavigation(error?: HttpErrorResponse): void {
		if (error) {
			this.microFlowsService.navigateError = error;
		}
		this.router.navigate([config.MFError.route], {
			queryParams: {
				origin: this.screenCode,
				cartid: this.cartid,
			},
		});
		this.screenCode = '';
	}

	public checkCartID(): Observable<any> {
		if (!this.cartid && this.route.data.reset) {
			this.microFlowsService.microflow = this.setAnalyticName(journeyStatuseEnum.ko);
			return this.microFlowsService.resetCookie(this.route.data[dataToMF.isMobileToPack]).pipe(
				map(
					(res) => {
						this.microFlowsService.cartId = res?.NTOL_TXID;
						this.commercialOffersService.ntolTxID = res?.NTOL_TXID;
						this.cartid = res?.NTOL_TXID;
						this.setTaggingLogic(true);
					},
					(error) => {
						const _auxError: ErrorList = {
							type: errorTecnichal,
							error: error,
							path: urlServices.reset,
						};
						this.microFlowsService.setTrackErrorTaggingLogic(
							this.microFlowsService.microflow,
							microFlowTypeAccess.access,
							this.microFlowsService.microTypeError,
							[_auxError]
						);
						if (
							error.ecode?.toString() === ERRORCODES.ECODE1300 ||
							error.error.ecode?.toString() === ERRORCODES.ECODE1300
						) {
							this.router.navigate([config.dashboard.route]);
						}
						this.setTaggingLogic(true);
						return error;
					}
				)
			);
		} else if (!this.cartid && !this.route.data.reset) {
			this.microFlowsService.microflow = this.setAnalyticName(journeyStatuseEnum.ko);
			this.microFlowsService.microUrlService = urlServices.reset;
			this.microFlowsService.microTypeError = errorFunction;
			this.errorNavigation();
			return of(true);
		} else {
			this.setTaggingLogic(false);
			return of(true);
		}
	}

	public tokensCall(): Observable<any> {
		return this.microFlowsService
			.getTokens(
				UserSettingToken.DxlAuth,
				this.subscriptionData.customerData.customerAccountsId,
				this.subscriptionData.customerData.currentService.id,
				false
			)
			.pipe(
				mergeMap((res) => {
					if (this.needCallAllowedLines) {
						this.needCallAllowedLines = false;
						this.microFlowsService.getAllowedLinesServices();
					}
					return this.checkCartID();
				}),
				catchError((error) => {
					this.microFlowsService.microflow = this.setAnalyticName(journeyStatuseEnum.ko);
					this.microFlowsService.microUrlService = urlServices.tokens;
					this.microFlowsService.microTypeError = errorTecnichal;
					this.errorNavigation(error);
					return error;
				})
			);
	}

	private refreshCall(): Observable<any> {
		return this.authenticateService.refreshTokens().pipe(
			mergeMap(() => {
				return this.tokensCall();
			})
		);
	}

	private checkExpiredTokens(): boolean {
		return this.authenticateService.isAccessTokenAlmostExpired(5) && !this.authenticateService.isRefreshingToken;
	}

	public sendTaggingAccess(type: string, status?: string): void {
		const analitycsName: string = this.setAnalyticName(status);
		const page: TaggingViewModel = { ...tagging.microflowsAccess.page };
		page.page_name = page.page_name.replace('<microflujo>', analitycsName);
		page.page_subcategory_level_2 = analitycsName;
		page.navigation_level_3 = analitycsName;
		page.journey_name = analitycsName;
		page.journey_principal = analitycsName;
		page.journey_step = page.journey_step.replace('<microflujo>', analitycsName);
		page.journey_category = this.tagging.getUserType(this.storageService.userProfile.customerType, ClientTypology);
		page.journey_subcategory = this.utilitiesService.isPurePrepaid() ? journeyTags.prepaid : journeyTags.pospaid;
		page.state_flow = analitycsName;
		if (type === microflowsTagg.access) {
			setTimeout(() => {
				this.tagging.view(false, page);
			}, 500);
		} else if (type === microflowsTagg.statusep && !this.cartid && this.deepLinkingService.isDeepLink) {
			const data: TaggingViewModel = { ...page, ...tagging.microflowsAccess.data };
			if (this.microFlowsService.responseEntrypoints?.entryPoints.length > 0) {
				data.entrypoint_type = this.microFlowsService.responseEntrypoints.entryPoints[0].statePEGA;
				data.entrypoint_code = this.microFlowsService.responseEntrypoints.entryPoints[0].code;
				data.entrypoint_name = this.microFlowsService.responseEntrypoints.entryPoints[0].name;
			}
			data.event_context = data.event_context.replace('<state>', status);
			setTimeout(() => {
				this.tagging.trackReplace(data.event_name, data, false);
			}, 500);
		}
	}

	public setAnalyticName(response?: string): string {
		let analitycsName: string;
		if (response === journeyStatuseEnum.ok && this.microFlowsService.responseEntrypoints?.entryPoints) {
			const code: string = this.microFlowsService.responseEntrypoints.entryPoints[0].code;
			analitycsName = this.translate.instant(`v10.flows.customization.${code}.analyticsName`);
		}
		return analitycsName ?? this.translate.instant(`v10.flows.nav.${this.screenCode}.analyticsName`);
	}
}
