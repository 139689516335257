import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { SpMiddlePageComponent } from './sp-middle-page.component';

const routes: Routes = [
	{
		path: '',
		component: SpMiddlePageComponent,
	},
];

@NgModule({
	imports: [RouterModule.forChild(routes)],
	exports: [RouterModule],
})
export class SpMiddlePageRoutingModule {}
