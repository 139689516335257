import { Pipe, PipeTransform } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { AppService } from '../../../app.service';

/**
 * Pipe used to transform either a string image key (img.key.from.wcs) or an assets image
 * (https://assets-es-pprd.dxlpreprod.local.vodafone.es/assets/images/image_key.png) into a full href to the specific image.
 *
 * Usage: 'imgKey' | imgFullPath
 */
@Pipe({
	name: 'imgFullPath',
})
export class ImgFullPathPipe implements PipeTransform {
	constructor(private appService: AppService, private translate: TranslateService) {}

	transform(imgKey: string): string {
		if (!imgKey?.trim()) {
			return '';
		}
		if (imgKey.trim().indexOf('assets') === 0) {
			imgKey = '/' + imgKey.trim();
		}
		return this.appService.getImgFullPath(this.translate.instant(imgKey));
	}
}
