<div
	#pageContentContainer
	class="top-up-rows"
	[ngClass]="{
		'forward-transition-tray': trayService?.showForwardTransition,
		'backward-transition-tray': trayService?.showBackwardTransition,
		'fade-out-tray': hideContent,
		'hide-iframe-frame-comp': showCancelationOverlay,
		'top-up-rows-responsive': topupService.useDynamicTray
	}"
>
	<div
		class="top-up-title-with-exit-icon-container"
		[ngClass]="{ 'title-shadow': scrolledView, 'hide-title': topupService.useDynamicTray }"
	>
		<img class="header-img" src="https://assets-es-pprd.dxlpreprod.local.vodafone.es/assets/images/red-small@2x.png" />
		<div class="top-up-title">
			<p>{{ uiModel.refills }}</p>
		</div>
		<div class="exit-logo" *ngIf="topupService.isMobileView">
			<span *ngIf="topupService.isMobileView" (click)="closeTrayWithTagging()" class="icon icon-close"> </span>
		</div>
	</div>
	<div
		[ngClass]="[!showParagraph ? 'title-and-iframe-container' : 'title-for-loading-container']"
		(scroll)="onScroll($event)"
	>
		<div class="account-block" [ngClass]="{ 'hide-msidn-with-amount-container': showFullLoader }">
			<div class="toggle-description">
				<div class="recharge-container">
					<div id="service-id-logo-mobile">
						<img class="logo-mobile" *ngIf="!billingService.startBillingPayment" src="https://assets-es-pprd.dxlpreprod.local.vodafone.es/assets/images/mobile@3x.png" />
						<img *ngIf="billingService.startBillingPayment" src="https://assets-es-pprd.dxlpreprod.local.vodafone.es/assets/icons-svg/generic-tariff-plan.svg" />
					</div>
					<div id="service-id-recharge-text">
						{{ uiModel.numberToRecharge }}
					</div>
					<div id="service-id-recharge-price">
						{{ billingService.startBillingPayment ? uiModel.billPaymentType : topupServiceId }}
					</div>
				</div>
				<div class="recharge-container">
					<div id="service-id-logo-trolley">
						<img
							class="trolly-img"
							*ngIf="!billingService.startBillingPayment"
							src="https://assets-es-pprd.dxlpreprod.local.vodafone.es/assets/images/shoppingTrolley@3x.png"
						/>
						<img *ngIf="billingService.startBillingPayment" src="https://assets-es-pprd.dxlpreprod.local.vodafone.es/assets/icons-svg/make-payment.svg" />
					</div>
					<div id="service-id-amount-text">
						{{ uiModel.quantity }}
					</div>
					<div id="service-id-amount">
						{{
							billingService.startBillingPayment
								? billingService.roundAmount(topupService?.curruentSelectedAmountSelectorValue)
								: topupService?.curruentSelectedAmountSelectorValue
						}}
						€
					</div>
				</div>
			</div>
		</div>
		<div class="top-up-container" #topupContainer [ngClass]="{ 'hide-top-up-container': showFullLoader }">
			<div class="lottiee-loader-container" *ngIf="appService.showLottieLoader">
				<sp-lottie-loader [lottieImageUrl]="'https://assets-es-pprd.dxlpreprod.local.vodafone.es/assets/loader.json'"> </sp-lottie-loader>
			</div>
			<div *ngIf="topupService?.showIframeError" class="error-container-for-iframe">
				<div class="iframe-logo-warning">
					<img src="https://assets-es-pprd.dxlpreprod.local.vodafone.es/assets/icons-svg/ic-warning-mid.svg" />
				</div>
				<div class="iframe-error-header">
					<span id="iframe-error-specific-header"> {{ uiModel.iframeLoadingError_title }} </span>
				</div>
				<div class="iframe-error-description">
					<span id="iframe-error-specific-description"> {{ uiModel.iframeLoadingError_description }} </span>
				</div>
			</div>
			<div
				#iframeContainer
				id="iframeContainer"
				*ngIf="!topupService?.showIframeError && iframeUrl"
				class="iframe-container"
			>
				<iframe #Iframe id="Iframe" (load)="loadURL(Iframe)" name="framename" [src]="iframeUrl" scrolling="auto">
				</iframe>
			</div>
			<div *ngIf="topupService?.showIframeError" class="iframe-error-retry-button">
				<button
					(click)="requestNetplusIframeUrlForMva10Topup()"
					id="LandingComp_btn_routeToExtrasList"
					type="button"
					class="button red two"
				>
					{{ uiModel.iframeLoadingError_button1 }}
				</button>
			</div>
			<div class="button-container-with-payment-buttons">
				<div class="icons-with-security-text">
					<!-- <div class="icons"> -->
					<div class="visa-icon">
						<img [src]="visaImage" alt="" class="img-responsive" />
					</div>
					<div class="master-card-icon">
						<img [src]="masterCardImage" alt="" class="img-responsive" />
					</div>
					<!-- </div> -->
					<div class="security-text-container-having-text">
						<img src="https://assets-es-pprd.dxlpreprod.local.vodafone.es/assets/images/ic-padlock-close.png" *ngIf="breakpoint !== BREAKPOINTS.MOBILE" />
						<p>{{ uiModel.sslSecured }}</p>
					</div>
				</div>
			</div>
		</div>
	</div>

	<div class="full-loader" *ngIf="showFullLoader">
		<div class="">
			<sp-lottie-loader [lottieImageUrl]="'https://assets-es-pprd.dxlpreprod.local.vodafone.es/assets/loader.json'"> </sp-lottie-loader>
		</div>
		<div class="text-container" *ngIf="showParagraph && showFullLoader">
			<p class="Procesando-la-cancel">{{ loaderDescription }}</p>
		</div>
	</div>
</div>
<sp-top-up-general-error-overlay *ngIf="showCancelationOverlay && !showFullLoader"> </sp-top-up-general-error-overlay>
