import { Component, EventEmitter, Input, Output } from '@angular/core';
import { Breakpoints, TileDisplayModel } from '@mva10/mva10-angular';
import { ResizeService } from '../../../mva10/shared/services/resize.service';
import { GenericFlipCardModel } from '../../models/generic-flipcard.model';

@Component({
	selector: 'sp-generic-flip-card',
	templateUrl: './generic-flip-card.component.html',
	styleUrls: ['./generic-flip-card.component.scss'],
})
export class GenericFlipCardComponent {
	@Input() loaded: boolean;
	@Input() consumptionData: TileDisplayModel;
	@Input() flipCardData: GenericFlipCardModel;
	@Output() eventClickButton: EventEmitter<void> = new EventEmitter();

	public BREAKPOINTS: any = Breakpoints;
	public breakpoint: any = Breakpoints;

	constructor(private resizeService: ResizeService) {}

	ngOnInit() {
		this.initResponsive();
		this.resizeService.onResize$.subscribe((size: Breakpoints) => {
			this.initResponsive();
		});
	}

	public clickDetailButton(): void {
		this.eventClickButton.emit();
	}

	private initResponsive(): void {
		this.breakpoint = this.resizeService.breakpoint;
	}
}
