import { Component, Input } from '@angular/core';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { NewTaggingJsonModel } from 'src/app/shared/models/new-tagging-json-model';
import { config } from '../../../../config/pages-config';
import { tagging } from '../../../../config/tagging-config';
import { AppService } from '../../../app.service';
import { TaggingHelperService } from '../../../core/services/tagging.helper.service';
import { ProductsAndServicesService } from '../../../products-and-services/products-and-services.service';
import { ADARA_TRACKING, FOLDER_ADARA_MY_PRODUCTS } from '../../constants/archetype/adaraTracking';
import { TaggingActionModel, TaggingViewModel } from '../../models/tagging.model';
import { eventsKeyUpHandler } from '../../utils/EventsKeyUpHandler';
import { NewTaggingHelperService } from './../../../core/services/new-tagging.helper.service';
import { LeftMenuWs10Service } from './../../../mva10/shared/services/left-menu-ws10.service';
import { CustomerAccountService } from './../../services/customer-account.service';

@Component({
	selector: 'sp-submenu-products',
	templateUrl: './submenu-products.component.html',
	styleUrls: ['./submenu-products.component.scss'],
})
export class SubmenuProductsComponent {
	title: string;
	subtitle: string;

	public telcoFilter: boolean = true;

	@Input() showProducts: boolean;

	selectorDataDescription: string;
	selectorDataButtonText: string;

	isAddresSelector: boolean = false;

	okCif: boolean = true;
	private cifNumber: number;
	private addressNumber: number;

	constructor(
		public customerAccountService: CustomerAccountService,
		public leftMenuService: LeftMenuWs10Service,
		public appService: AppService,
		public router: Router,
		public taggingHelper: TaggingHelperService,
		public productAndService: ProductsAndServicesService,
		public translateService: TranslateService,
		public newTaggingHelperService: NewTaggingHelperService,

	) { }

	ngOnInit() {
		this.okCif = !this.leftMenuService.errorCif && this.leftMenuService.addressSelectorLoaded && this.leftMenuService.addressSelector && this.customerAccountService.selectorCIFData.length > 1
	}

	ngAfterViewInit() {
		this.isAddresSelector = (this.leftMenuService.addressSelectorLoaded && this.leftMenuService.addressSelector && this.leftMenuService.addressSelector.length > 1 && this.telcoFilter);
		this.setSelectorDataText();
		if (this.customerAccountService.selectorCIFData.length > 1) {
			this.newTaggingHelperService.getJsonTagging('products-and-services/products-and-services-dashboard').subscribe((data: NewTaggingJsonModel) => {
				data.page.page_name = "dashboard:selector de sede";
				data.page.page_detail = "selector de sede";
				data.page.page_typology = "backdrop";
				this.newTaggingHelperService.state(data.page);
			});
		}
	}

	selectClick(ind: number, event: Event) {
		event.preventDefault();
		this.addressNumber = ind;
		this.newTaggingHelperService.getJsonTagging('products-and-services/products-and-services-dashboard-select').subscribe((data: NewTaggingJsonModel) => {
			this.newTaggingHelperService.interaction(data.eventList.click_on_site, data.page);
		});
		this.leftMenuService.setPreSiteSelectorClicked(ind);
	}

	private setSelectorDataText() {
		this.translateService.get('v10.common.tray.overlay_myproducts').subscribe((text) => {
			this.selectorDataDescription = text.selectaddress;
			this.selectorDataButtonText = this.customerAccountService.isSitePrepaid
				? text.products_services_button
				: text.mysubscriptions;
		});
	}

	goToMyOrders(): void {
		// Click from tablet products submenu link
		const page: TaggingViewModel = Object.assign({}, tagging.selectorCardView);
		const trackAction: TaggingActionModel = Object.assign({}, tagging.dashboard.pendingInstallMyOrdersTrayClick);
		this.taggingHelper.track(trackAction.eventName, Object.assign(page, trackAction.data));

		this.leftMenuService.closeSubmenu();
		this.router.navigate([config.myorders.route]);
	}

	public clickOnAdaraCTATags(): void {
		this.router.navigate([config.adara.route]);
		this.newTaggingHelperService.getJsonTagging(FOLDER_ADARA_MY_PRODUCTS).subscribe((data: NewTaggingJsonModel) => {
			this.newTaggingHelperService.interaction(data.eventList[ADARA_TRACKING.INTERACTIONS.FILTER_CLICK], data.page);
		});
	}

	public clickOnCif(index): void {
		this.cifNumber = index;
		this.newTaggingHelperService.getJsonTagging('products-and-services/products-and-services-dashboard-select').subscribe((data: NewTaggingJsonModel) => {
			data.page.page_name = "dashboard:selector de sede"
			this.newTaggingHelperService.interaction(data.eventList.click_re_try, data.page);
		});
		this.leftMenuService.setCifSelectorClicked(index);
	}

	public handlerClinkOnCif(event: KeyboardEvent): void {
		if (eventsKeyUpHandler(event)) this.clickOnCif(this.cifNumber);
	}

	public handlerSelectClick(event: KeyboardEvent): void {
		if (eventsKeyUpHandler(event)) this.selectClick(this.addressNumber, event);
	}

}
