import { Component, Input, OnInit } from '@angular/core';
import { AppService } from '../../app.service';
import { PaymentErrorService } from '../services/payment-error.service';

@Component({
	selector: 'sp-payment-error',
	templateUrl: './payment-error.component.html',
	styleUrls: ['./payment-error.component.scss'],
})
export class PaymentErrorComponent implements OnInit {
	@Input() firstBtnAction: Function;
	@Input() isDimmed: boolean = false;
	// default dimming time if not sent is 30 sec
	@Input() dimmingTime: number = 30;

	constructor(public paymentErrorService: PaymentErrorService, public appService: AppService) {}
	ngOnInit(): void {
		if (this.isDimmed) {
			setTimeout(() => {
				this.isDimmed = false;
			}, this.dimmingTime * 1000);
		}
	}

	/**
	 * the default implmentaion of the first btn in delete card confiramtion screen
	 */
	onFirstBtnAction(): void {
		if (this.isDimmed) {
			return;
		} else {
			if (this.firstBtnAction) {
				this.firstBtnAction();
			} else {
				this.paymentErrorService.currnetError.firstBtnAction();
			}
		}
	}
}
