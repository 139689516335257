import { environment } from '../../../environments/environment';

export const API_URLS = {
	'CONFIGURATIONS': {
		CONFIG_GET: environment.middlewareBaseUrl + 'es/v1/appSettings/settings?osType={type}',
		CONFIG_BASE: environment.middlewareBaseUrl + 'es/v1/appSettings/settings',
	},
	'CMS': {
		CMS_GET: environment.contentBaseUrl + 'content' + environment.prodContent,
	},
	'Login': {
		SESSION_START: environment.middlewareBaseUrl + 'v1/apixoauth2password/oauth2/token',
		OTP_AUTHORIZE: environment.middlewareBaseUrl + 'OTPLogin/v1/authorize',
		OTP_TOKEN: environment.middlewareBaseUrl + 'OTPLogin/v1/token',
	},
	'Profile': {
		UPGRADE:
			environment.middlewareBaseUrl + 'v2/subscription/subscriptionProfiles?documentId={docId}&documentType={docType}',
	},
	'Dashboard': {
		ServiceBalance:
			environment.middlewareBaseUrl +
			'v2/service/serviceBalances?customerAccountId={siteId}&serviceIdentifier={serviceId}&categories={bundleType}',
		FTE: environment.middlewareBaseUrl + 'es/v1/customerAccounts/{customerAccountId}/fte',
	},
	'CustomerAccount': {
		FetchCustomerAccounts: environment.middlewareBaseUrl + 'v2/customer/customerAccounts',
		FetchCustomerAccountById: environment.middlewareBaseUrl + 'v2/customer/customerAccounts/{id}',
		FetchCustomerPartiesById: environment.middlewareBaseUrl + 'v2/customer/customerParties/{id}',
		PatchCustomerPartiesById: environment.middlewareBaseUrl + 'v2/customer/customerParties/{id}',
		PutCustomerPartiesById: environment.middlewareBaseUrl + 'v2/customer/customerParties/{id}',
		PatchCustomerAccount: environment.middlewareBaseUrl + 'v2/customer/customerAccounts/{id}',
		FetchBillingAccounts: environment.middlewareBaseUrl + 'v2/customer/customerAccounts/{id}/billingAccounts',
		patchCredential: environment.middlewareBaseUrl + 'es/v2/authenticationCredential/identity/{id}',
		FetchBillingAccountById:
			environment.middlewareBaseUrl + 'v2/customer/customerAccounts/{id}/billingAccounts/{billingAccountId}',
		patchPaymentMethodAddress:
			environment.middlewareBaseUrl + 'v2/customer/customerAccounts/{id}/billingAccounts/{billing-account-id}',
		generatePIN: environment.middlewareBaseUrl + 'es/v1/otp/generate',
		permissionsAndPreferences: environment.middlewareBaseUrl + 'es/v1/customerAccounts/{siteId}/settings',
		getAsociatedUsers: environment.middlewareBaseUrl + 'es/myAccount/v1/customerSystemUserAccount',
		patchAsociatedUsers: environment.middlewareBaseUrl + 'es/myAccount/v1/customerSystemUserAccount/{MSISDN}',
		getMyData: environment.middlewareBaseUrl + 'es/myAccount/v1/myData',
		patchMyData: environment.middlewareBaseUrl + 'es/myAccount/v1/myData/{accountId}',
	},
	'Subscriptions': {
		Subscription: environment.middlewareBaseUrl + 'v2/subscription/subscriptions/',
		SubscriptionById: environment.middlewareBaseUrl + 'v2/subscription/subscriptions/{id}',
	},
	'SideMenuConfig': {
		MENU_ITEMS: environment.middlewareBaseUrl + 'es/v2/adminConfig/menu/items',
		BUBBLE_HELP_CHAT:
			environment.middlewareBaseUrl + 'es/v2/adminConfig/menu/items?config=help&serviceId={selectedService}',
	},
	'Bill': {
		GetCustomerBill: environment.middlewareBaseUrl + 'v3/payment/customerBills?customerAccountId={siteId}',
		GetBills: environment.middlewareBaseUrl + 'v3/payment/customerBills',
		GetStatusNIF:
			environment.middlewareBaseUrl +
			'es/csm-api/customerBillAPI/v3/customerBill?billingAccountId={billId}&relatedParty%5B%3F%28%40.role%3D%3D%27Individual%27%29%5D.id={docID}&relatedParty.role=Individual',
		GetStatusCIF:
			environment.middlewareBaseUrl +
			'es/csm-api/customerBillAPI/v3/customerBill?billingAccountId={billId}&relatedParty%5B%3F%28%40.role%3D%3D%27Individual%27%29%5D.id={docID}&relatedParty.role=Organization',
		GetBillNotification: environment.middlewareBaseUrl + 'es/v1/payment/customerBill/notifications/{id}',
		GetBillDetails: environment.middlewareBaseUrl + 'v3/payment/customerBills/{id}',
		sendEmail: environment.middlewareBaseUrl + 'v2/es/payment/customerBills/clarifications',
		GetServiceUsages:
			environment.middlewareBaseUrl + 'v3/payment/customerBills/{billId}/serviceUsages?subscriptionId={serviceId}',
		GetConfigurations:
			environment.middlewareBaseUrl + 'v2/customer/customerAccounts/{id}/billingAccounts/{billingAccountId}',
		PatchConfiguration:
			environment.middlewareBaseUrl + 'v2/customer/customerAccounts/{id}/billingAccounts/{billingAccountId}',
		getCurrentConsumption:
			environment.middlewareBaseUrl + 'es/v1/customerAccounts/{customerAccountId}/subscriptions/{subscriptionId}/usage',
		PaymentConfirmation:
			environment.middlewareBaseUrl + 'es/v1/customerAccounts/{customerAccountId}/bills/payment/confirmation',
		paymentProcessURL:
			environment.middlewareBaseUrl +
			'es/v1/customerAccounts/{customerAccountId}/subscriptions/{subscriptionId}/payment',
		callOption:
			environment.middlewareBaseUrl +
			'es/v1/customerAccounts/{customerAccountId}/services/{serviceId}/settings/availability?type=all',
	},
	'Product': {
		Products:
			environment.middlewareBaseUrl +
			'v2/product/products?subscriptionId={subscription-id}&customerAccountId={customer-account-id}',
		ProductByType:
			environment.middlewareBaseUrl +
			'v2/product/products?subscriptionId={subscription-id}&customerAccountId={customer-account-id}&productType={product-type}',
		BuyOrRemoveBundle: environment.middlewareBaseUrl + 'v2/product/products/{id}',
		TxProductsFull:
			environment.contentBaseUrl +
			'tienda/vf-back-cesta/api/ikki/secure/catalog/frontend/tvOffers?shopType={shopType}&siteId={siteId}&clientType={clientType}&sceneType={sceneType}&contractType={contractType}',
		TxProducts:
			environment.contentBaseUrl +
			'tienda/vf-back-cesta/api/ikki/secure/catalog/frontend/tvOffers?shopType={shopType}&siteId={siteId}',
		TxAddTvToCart: environment.contentBaseUrl + 'tienda/vf-back-cesta/api/ikki/secure/shoppingcartitem/v1/addTvSa',
		updateMailBoxless:
			environment.contentBaseUrl +
			'tienda/vf-back-cesta/api/ikki/secure/shoppingcart/updateMailBoxless?mailBoxless={mail}',
		changeDecoder:
			environment.contentBaseUrl + 'tienda/vf-back-cesta/api/ikki/secure/shoppingcartitem/v1/changeDecoder',
		BusinessProductsList:
			environment.middlewareBaseUrl +
			'v2/product/products?customerAccountId={customer-account-id}&subscriptionId=OneProfesional',
		BusinessProductDetail:
			environment.middlewareBaseUrl +
			'/v2/product/products?customerAccountId={customer-account-id}&subscriptionId=OneProfesional&productCode={productCode}&channel.id=0',
		GetTVOnlineMultiDevice:
			environment.middlewareBaseUrl +
			'tmf-api/resourceInventoryManagement/v4/resource' +
			'?$.relatedParty%5B?(@.role%3D%3D"customer")%5D.id={relatedPartyId}&$.resourceRelationship%5B?(@.relationshipType%3D%3D"family")%5D.resource.id={resourceId}',
		GetFilteredTvTvOnline:
			environment.middlewareBaseUrl +
			'tmf-api/productInventory/v4/product' +
			'?category.id=tv,tv%20online&relatedParty%5B?(@.role%3D%3D"Customer")%5D.id={siteIds}',
		PatchTvDevice: environment.middlewareBaseUrl + 'tmf-api/resourceInventoryManagement/v4/resource/{id}',
		DeleteTvDevice: environment.middlewareBaseUrl + 'tmf-api/resourceInventoryManagement/v4/resource/{id}',
	},
	'Tariff': {
		fetchAllTariffs:
			environment.middlewareBaseUrl +
			'v2/product/tariffs?subscriptionId={subscription-id}&customerAccountId={customer-account-id}&creditInfo={credit-info-flag}',
		patchProduct: environment.middlewareBaseUrl + 'v2/product/tariffs/{tarrifId}',
		patchChangePassword: environment.middlewareBaseUrl + 'es/v2/authenticationCredential/identity/{id}/app/onlineTV',
		patchChangeUsername: environment.middlewareBaseUrl + 'es/v2/authenticationCredential/identity/{id}/app/onlineTV',
	},
	'TopUp': {
		GetTopUpHistory: environment.middlewareBaseUrl + 'es/v1/payment/customerAccounts/{id}/topup/history',
		GetTopUpCurrentSpendings:
			environment.middlewareBaseUrl + 'es/v1/customerAccounts/{customerAccountId}/subscriptions/{subscriptionId}/usage',
		PostTopup: environment.middlewareBaseUrl + 'es/v1/payment/customerAccounts/{id}/topup',
		GetTopUpHistoryAndMovements: `${environment.middlewareBaseUrl}es/v1/customerAccounts/{customerAccountId}/subscriptions/{serviceId}/usage`,
	},
	'PaymentCards': {
		Get: environment.middlewareBaseUrl + 'es/v1/customerAccounts/{documentId}/payments',
		Delete: environment.middlewareBaseUrl + 'es/v1/customerAccounts/{documentId}/payments/{payMethodUUId}',
	},
	'Contracts': {
		getContracts: environment.middlewareBaseUrl + 'v3/customer/customerAgreements/{id}/penalty',
		downloadContract: environment.middlewareBaseUrl + 'v1/download/',
	},
	'Agreement': {
		GetCustomerAgreements: environment.middlewareBaseUrl + 'v3/customer/customerAgreements/',
	},
	'Normalizador': {
		ValidateAddress: environment.contentBaseUrl + 'normalizador2/suggestGeocoder.rest?address={address}',
	},
	'verifyEmail': {
		verifyEmail:
			environment.middlewareBaseUrl + 'v1/verification/email/{tokenId}?ak={ak}&vf-country-code={countryCode}',
		verifyPdf: environment.middlewareBaseUrl + 'v1/verification/email/{tokenId}?ak={ak}&vf-country-code={countryCode}',
	},
	'serviceSettings': {
		getSIMProfileData: environment.middlewareBaseUrl + 'v2/subscription/subscriptions/{id}/simProfile',
		getSIMDatas: environment.middlewareBaseUrl + 'tmf-api/resourceInventoryManagement/v4/resource/{serviceId}',
		postUnlockSIM: environment.middlewareBaseUrl + 'es/v1/customerAccounts/{id}/settings/sim',
		getThirdPartyData: environment.middlewareBaseUrl + 'es/v1/serviceAccountInfo/{serviceId}',
		deleteThirdParty:
			environment.middlewareBaseUrl + 'es/v1/serviceAccountInfo/{serviceId}/subscriptions/{subscriptionId}',
		getPurchasesAndSubscriptions: environment.middlewareBaseUrl + 'es/v1/serviceAccountInfo/{serviceId}/subscriptions',
		updatePaymentRestrictions: environment.middlewareBaseUrl + 'es/v1/serviceAccountInfo/{serviceId}',
		getCallOptions: environment.middlewareBaseUrl + 'es/v1/serviceAccountInfo/{serviceId}/settings/availability',
		updateCallOptions: environment.middlewareBaseUrl + 'es/v1/serviceAccountInfo/{serviceId}/settings/availability',
		getVoiceMail: environment.middlewareBaseUrl + 'es/v1/serviceAccountInfo/{serviceId}/settings/voicemail',
		getLineRestrictions:
			environment.middlewareBaseUrl + 'es/v1/customerAccounts/{siteId}/{serviceId}/settings/location',
		updateVoiceMailSettings: environment.middlewareBaseUrl + 'es/v1/serviceAccountInfo/{serviceId}/settings/voicemail',
		getPremiumContentSubscription: environment.middlewareBaseUrl + 'es/premiumContent/v1/{serviceId}',
		cancelSubscriptionService: `${environment.middlewareBaseUrl}/es/premiumContent/v1/cancellation`,
	},
	'faultManagement': {
		getTickets: environment.middlewareBaseUrl + 'es/v1/ticket/{CustomerAccountId}/tickets',
		getTicketById: environment.middlewareBaseUrl + 'es/v1/ticket/{CustomerAccountId}/tickets/{ticketId}',
		updateStatusUrl: environment.middlewareBaseUrl + 'es/v1/ticket/{CustomerAccountId}/tickets',
		cancelAppointmentUrl:
			environment.middlewareBaseUrl + 'es/v1/ticket/{CustomerAccountId}/tickets/appointments/{templeteId}',
		getTicketTemplate: environment.middlewareBaseUrl + 'es/v1/ticket/{CustomerAccountId}/tickets/templates',
		SetInteraction: environment.middlewareBaseUrl + 'es/v1/ticket/{CustomerAccountId}/tickets/interactions',
		getServiceAvailability:
			environment.middlewareBaseUrl + 'es/v1/ticket/{CustomerAccountId}/serviceAvailability/tickets',
	},
	'acountSettings': {
		postCheckedUserData: environment.middlewareBaseUrl + 'v2/subscription/subscriptions/{id}/settings',

		getCheckedUserData: environment.middlewareBaseUrl + 'v2/subscription/subscriptions/{id}/settings',
	},
	'Inbox': {
		getPushNotification: environment.middlewareBaseUrl + 'es/v1/users/{userId}/notifications?clientType=web',
		getNotificatoinCount: environment.middlewareBaseUrl + 'es/v1/users/{userId}/notifications/metadata',
		postNotificationStatus: environment.middlewareBaseUrl + 'es/v1/users/{userId}/notifications/events',
	},
	'editDataSharing': {
		patchMemberData: environment.middlewareBaseUrl + 'v2/product/products/{subscriptionId}',
	},
	'cypher': {
		aes: 'https://3qas14kvni.execute-api.eu-central-1.amazonaws.com/prod/vfencrypt2',
	},
	'url': {
		getUrl:
			environment.middlewareBaseUrl + 'es/v1/appUrlsConfiguration/urls?sourceLink={sourceUrl}&osType=web&version=1',
	},
	'Impersonation': {
		token: environment.middlewareBaseUrl + 'es/v1/impersonation/token',
	},
	'userSettings': {
		userSettings: environment.middlewareBaseUrl + 'es/v1/externalUserSettings/tokens',
		onBehalOf: environment.middlewareBaseUrl + 'oauth2OnBehalfOf/v1/token',
	},
	'cpe': {
		update: environment.middlewareBaseUrl + 'v2/product/products/{id}',
	},
	'campaign': {
		fetchCampaign: environment.middlewareBaseUrl + 'es/v1/customerAccounts/{customerAccountId}/campaignPermissions',
	},
	'LogOut': {
		SESSION_END: environment.middlewareBaseUrl + 'v1/tokenRevocation/revoke',
	},
	'MiWifiConfig': {
		GetMiwifiConfig: environment.middlewareBaseUrl + 'es/v1/miWifi/config/{macId}',
		patchMiFi: environment.middlewareBaseUrl + 'es/v1/miWifi/config/{macId}?source=web&platform={platform}',
	},
	'contactUs': {
		patchContactUs: environment.middlewareBaseUrl + 'es/v1/customerAccounts/{siteId}/contact',
	},
	'Recommendation': {
		getRecommendation:
			environment.middlewareBaseUrl +
			'es/v1/nextBestActionRecommendation/recommendation?customerAccountId={customerAccountId}',
		getOPLRecommendation:
			environment.middlewareBaseUrl +
			'es/v1/nextBestActionRecommendation/recommendation?customerAccountId={customerAccountId}&fields=OnePlus',
	},
	'TryAndBuy': {
		getTryAndBuy:
			environment.middlewareBaseUrl +
			'es/v1/nextBestActionRecommendation/recommendation?customerAccountId={customerAccountId}&fields=tryAndBuyMicro&newVersion=true',
		getTryAndBuyProductCode:
			environment.middlewareBaseUrl +
			'/es/v1/nextBestActionRecommendation/recommendation?fields=tryAndBuyMicro&customerAccountId={customerAccountId}' +
			'&productCode={productCode}&newVersion={newVersion}',
	},
	'MemberGetMember': {
		getMemberGetMember: environment.middlewareBaseUrl + 'es/productCatalog/v1/promotion?promotionId=MGM&NIF={nif}',
		setMemberGetMember: environment.middlewareBaseUrl + 'es/productCatalog/v1/promotion/MGM',
	},
	'OpProductList': {
		getOpProductList:
			environment.contentBaseUrl +
			'tienda/vf-back-cesta/api/ikki/secure/catalog/frontend/groupOPOffers?siteId={siteId}&idPuntoProcesoCompra=3&version={lastVersion}',
		getOpProductListTyp:
			environment.contentBaseUrl +
			'tienda/vf-back-cesta/api/ikki/secure/catalog/frontend/groupOPOffers?siteId={siteId}&idPuntoProcesoCompra=3&version={lastVersion}&channel=0',
		getOpProduct:
			environment.contentBaseUrl +
			'tienda/vf-back-cesta/api/ikki/secure/catalog/frontend/productOPOffers?idPuntoProcesoCompra=3' +
			'&siteId={siteId}&idGroupSeleccionado={selectedGroup}',
	},
	'Ticket': {
		post: environment.middlewareBaseUrl + 'tmf-api/troubleTicket/v2/troubleTicket',
		get:
			environment.middlewareBaseUrl +
			"/tmf-api/troubleTicket/v2/troubleTicket?relatedParty[?(@.role%3D%3D'Customer')].id={customerID}" +
			'&ticketType=Peticion&status=Reabierto,Abierto&name=Peticion revision factura&journey=billReview',
		getAutoiTickets:
			environment.middlewareBaseUrl +
			"tmf-api/troubleTicket/v2/troubleTicket?relatedParty[?(@.role%3D%3D'Customer')]" +
			'.id={siteId}&ticketType={ticketType}&status=Abierto',
		getJourney:
			environment.middlewareBaseUrl +
			"/es/billreview/v1/troubleTicket?relatedParty[?(@.role%3D%3D'Customer')].id={customerID}&status=Abierto,Reabierto,Cerrado",
		getEuropeanFunds:
			environment.middlewareBaseUrl +
			'/tmf-api/troubleTicket/v2/troubleTicket?relatedPartyId={relatedPartyId}' +
			'&ticketType=Peticion',
		getTicketTraslado:
			environment.middlewareBaseUrl +
			'/tmf-api/troubleTicket/v2/troubleTicket?relatedPartyId={relatedPartyId}' +
			'&ticketType={ticketType}&status=Abierto&name={ticketDescription}',
	},
	'TicketTryAndBuy': {
		patch: environment.middlewareBaseUrl + 'es/productCatalog/v1/promotion/TRYANDBUY',
	},
	'PurchaseProducts': {
		GetCoute: environment.middlewareBaseUrl + 'es/v1/productsServices/product/price',
		GetSVAs: environment.middlewareBaseUrl + 'es/v1/productsServices/product/sva',
	},
	'Documentum': {
		getDocuments: environment.middlewareBaseUrl + 'tmf-api/document/v4/document',
		getDocumentBinary: environment.middlewareBaseUrl + 'tmf-api/document/v4/document/{id}',
		post: environment.middlewareBaseUrl + 'tmf-api/documentManagement/v1/document',
	},
	'Offers': {
		getOffers: environment.contentBaseUrl + 'tienda/vf-back-cesta/api/ikki/secure/catalog/frontend/offers/',
	},
	'Serviciability': {
		getServiciability:
			environment.contentBaseUrl +
			'tienda/vf-back-tienda/api/ikki/' +
			'coverage/serviciability/{siteId}?shopType={shopType}',
	},
	'ContractedProducts': {
		getContratedProducts:
			environment.contentBaseUrl +
			'tienda/vf-back-tienda/api/ikki/' +
			'secure/contratedpark/contractedProducts?idTypeJourney=mobileToPack&siteId={siteId}',
	},
	'validatePortability': {
		getValidatePortability:
			environment.contentBaseUrl + 'tienda/vf-back-tienda/api/ikki/' + 'secure/clientdata/validatePortability',
	},
	'TypeOrderPortability': {
		typePorta:
			environment.middlewareBaseUrl +
			'tmf-api/resourceOrderingManagement/v4/resourceOrder?relatedEntity.%5B%3F%28%40.' +
			'role%3D%3D%27Order%27%29%5D.id={orderId}&orderType={tipoPortabilidad}&category=1',
		typePortaRechazada:
			environment.middlewareBaseUrl +
			'tmf-api/resourceOrderingManagement/v4/resourceOrder?relatedEntity.%5B%3F%28%40.' +
			'role%3D%3D%27Order%27%29%5D.id={orderId}&orderType={tipoPortabilidad}',
		avanzarPortabilidad:
			environment.middlewareBaseUrl + 'tmf-api/resourceOrderingManagement/v4/resourceOrder/{idOrden}',
	},
	'Checkout': {
		deteleReset:
			environment.contentBaseUrl +
			'tienda/vf-back-cesta/api/ikki/secure/shoppingcartitem/reset/{idClient}/{clientTypeID}',
		getDropdowns: environment.contentBaseUrl + 'tienda/vf-back-tienda/api/ikki/dropDowns/',
		postPersonalData: environment.contentBaseUrl + 'tienda/vf-back-tienda/api/ikki/secure/checkout/personalData',
		postShoppingCartItem: environment.contentBaseUrl + 'tienda/vf-back-cesta/api/ikki/secure/shoppingcartitem/',
		postShoppingCartItemBuyNow:
			environment.contentBaseUrl + 'tienda/vf-back-cesta/api/ikki/secure/shoppingcartitem/buynow',
		postMobileData: environment.contentBaseUrl + 'tienda/vf-back-tienda/api/ikki/secure/checkout/mobileData/',
		getShoppingCartItem: environment.contentBaseUrl + 'tienda/vf-back-cesta/api/ikki/secure/shoppingcart/',
		getLegalTerms:
			environment.contentBaseUrl +
			'tienda/vf-back-tienda/api/ikki/legalterms/' +
			'getlegalterms?clientType={clientType}&shopType={shopType}&page={page}',
		getLegalTermsGenericCheckout:
			environment.contentBaseUrl + 'tienda/vf-back-tienda/api/ikki/secure/legalterms/getlegalterms',
		getLegalTermsOneProfGenericCheckout:
			environment.contentBaseUrl +
			'tienda/vf-back-tienda/api/ikki/legalterms/getlegalterms?clientType={clientType}&shopType={shopType}&page={page}',
		getBascket: environment.contentBaseUrl + 'tienda/vf-back-cesta/api/ikki/shoppingcart',
		postLegalTerms: environment.contentBaseUrl + 'tienda/vf-back-tienda/api/ikki/secure/legalterms/setlegalterms',
		postLegalTermsSave: environment.contentBaseUrl + 'tienda/vf-back-tienda/api/ikki/secure/checkout/save',
		getDeliveryDates:
			environment.contentBaseUrl + 'tienda/vf-back-trastienda/api/ikki/matrizNext/getModalidadesEntrega',
		postDeliveryData: environment.contentBaseUrl + 'tienda/vf-back-tienda/api/ikki/checkout/deliveryData',
		postDeliveryDataSecure: environment.contentBaseUrl + 'tienda/vf-back-tienda/api/ikki/secure/checkout/deliveryData',
		getDeliveryTypes: environment.contentBaseUrl + 'tienda/vf-back-trastienda/api/ikki/matrizNext/getTiposEntrega',
		getLineData: environment.contentBaseUrl + 'tienda/vf-back-tienda/api/ikki/secure/checkout/linesData/',
		getPostalAddress: environment.contentBaseUrl + 'tienda/vf-back-tienda/api/ikki/secure/checkout/getPostalAddress',
		getPostalAddressBuyNnow:
			environment.contentBaseUrl + 'tienda/vf-back-tienda/api/ikki/secure/checkout/getDeliveryAddress?siteId={siteId}',
		getChangeAddress: environment.contentBaseUrl + 'tienda/vf-back-tienda/api/ikki/dropDowns/ProvinceDD',
		getCoordinates:
			environment.contentBaseUrl + 'tienda/vf-back-tienda/api/ikki/coverage/getCoordinates?zipCode={zipCode}',
		getTiendasEntrega:
			environment.contentBaseUrl +
			'tienda/vf-back-trastienda/api/ikki/matrizNext/getTiendasEntrega' +
			'?province={province}&latitude={latitude}&longitude={longitude}&checkStock={checkStock}&shopType={shopType}',
		getOficinasCorreosEntrega:
			environment.contentBaseUrl +
			'tienda/vf-back-trastienda/api/ikki/matrizNext/getOficinasCorreosEntrega' +
			'?postalCode={postalCode}&latitude={latitude}&longitude={longitude}&shopType={shopType}',
		comprobarPedidoIkkiCorrespondeCita:
			environment.contentBaseUrl + 'tienda/vf-back-tienda/api/ikki/gestioncitas/comprobarPedidoIkkiCorrespondeCita',
		getDisponibilidadCita:
			environment.contentBaseUrl + 'tienda/vf-back-tienda/api/ikki/gestioncitas/getDisponibilidadCita',
		postSaveCitaOrig: environment.contentBaseUrl + 'tienda/vf-back-tienda/api/ikki/gestioncitas/saveCitaOrig',
		allowedServices:
			environment.middlewareBaseUrl +
			'es/v1/nextBestActionRecommendation/allowedServices?' +
			'journeyCode={journeyCode}&customerAccountId={customerAccountId}&onlyAllowed={onlyAllowed}&channel.id={channel.id}',
		allowedLinesServices:
			environment.contentBaseUrl +
			'tienda/vf-back-catalogo/api/ikki/secure/allowedlines?' +
			'clientId={clientId}&shopType={shopType}&listAllowedMsisdn={listAllowedMsisdn}&listAllowedCartItemId={listAllowedCartItemId}',
		containBoxless: environment.contentBaseUrl + 'tienda/vf-back-cesta/api/ikki/secure/shoppingcart/containsBoxless',
		updateMailBoxless:
			environment.contentBaseUrl +
			'tienda/vf-back-cesta/api/ikki/secure/shoppingcart/updateMailBoxless?' +
			'mailBoxless={mailBoxless}',
		oneProfesionalData:
			environment.contentBaseUrl + 'tienda/vf-back-tienda/api/ikki/secure/checkout/oneProfesionalData',
		oneProfesionalDataAddon:
			environment.contentBaseUrl + 'tienda/vf-back-tienda/api/ikki/secure/checkout/oneProfesionalDataAddon',
		getNewSuggestGeocoder:
			environment.contentBaseUrl +
			'tienda/vf-back-tienda/api/ikki/coverage/getNewSuggestGeocoder?' +
			'address={address}',
		getNewGeocoder: environment.contentBaseUrl + 'tienda/vf-back-tienda/api/ikki/coverage/getNewGeocoder/',
		contractSummary:
			environment.contentBaseUrl + 'tienda/vf-back-tienda/api/ikki/resumencontrato/createResumenContratoProvisional',
		contractSummaryUpgrade: environment.middlewareBaseUrl + 'es/contractSummary/v1/document',
		finalpayment: environment.contentBaseUrl + 'tienda/vf-back-tienda/api/ikki/secure/checkout/finalpayment',
		getPostalOffices:
			environment.contentBaseUrl +
			'tienda/vf-back-trastienda/api/ikki/matrizNext/getOficinasCorreosEntrega?province={province}&latitude={lat}&longitude={long}&postalCode={cp}&shopType={shopType}',
	},
	'ActivationAndConfiguration': {
		patchChangePin: environment.middlewareBaseUrl + 'tmf-api/ServiceActivationAndConfiguration/v4/service/{mail}',
		activarEquipo: environment.middlewareBaseUrl + 'tmf-api/ServiceActivationAndConfiguration/v4/service',
	},
	'Appointment': {
		getCitasDisponiblesOT: environment.middlewareBaseUrl + '/tmf-api/appointment/v4/searchTimeSlot',
		GestionCitasOT: environment.middlewareBaseUrl + '/tmf-api/appointment/v4/appointment',
	},
	'Orders': {
		getOrders:
			environment.middlewareBaseUrl +
			'es/productOrderProcessFlow/v1/productOrderProcessFlow?relatedParty%5B%3F%28%40.' +
			'role%3D%3D%27Customer%27%29%5D.id={idClient}' +
			'&state=Abierta,Cerrada,Cancelada,Pdte%20Cancelar&orderDate%3E%3D={fromdate}&orderDate%3C%3D={todate}',
	},
	'Slid': {
		getInteractionSLID: environment.middlewareBaseUrl + 'tmf-api/partyInteractionManagement/v1/partyInteraction',
		postInsertInteractionSLID: environment.middlewareBaseUrl + '',
		getPasswordUserConfig:
			environment.middlewareBaseUrl +
			'tmf-api/resourceInventoryManagement/v2/resource?' +
			'relatedParty[?(@.role%3D%3D"Customer")].id={idClient}&type={type}',
	},
	'HistoryOl': {
		getOrderHistoryOL: environment.middlewareBaseUrl + 'tmf-api/shippingOrder/v4.0/shippingOrder?productOrder.id={id}',
		getTaskFlowTypeOL:
			environment.middlewareBaseUrl +
			'/tmf-api/processFlowManagement/v410/' +
			'taskFlowSpecification?name={statusOL}&taskFlowSpecificationType=OL',
		newLogisticShipping: environment.middlewareBaseUrl + 'tmf-api/shippingOrder/v4.0/shippingOrder',
	},
	'CommercialMobiles': {
		getPlaylist:
			environment.contentBaseUrl +
			'tienda/vf-back-catalogo/api/ikki/playlists?contractType={contractType}&&clientType={clientType}&' +
			'sceneType={sceneType}&shopType={shopType}&lineType={lineType}&registerType={registerType}',
		getTerminalList:
			environment.contentBaseUrl +
			'tienda/vf-back-catalogo/api/ikki/secure/terminal/terminallist?' +
			'clientType={clientType}&shopType={shopType}&lineType={lineType}&terminalType={terminalType}&' +
			'registerType={registerType}&sceneType={sceneType}&contractType={contractType}&' +
			'msisdn={msisdn}&clientId={clientId}&perfil={profile}&prescoring=false&' +
			'cartItemId={cartItemId}&app=0&version={version}',
		getTerminaldetail:
			environment.contentBaseUrl +
			'tienda/vf-back-catalogo/api/ikki/secure/terminal/terminaldetail?' +
			'contractType=0&clientType={clientType}&lineType=0&clientId={clientId}&sceneType=1&sap={sap}&' +
			'msisdn={msisdn}&shopType={shopType}&terminalType={terminalType}&registerType=4&perfil={profile}' +
			'&prescoring=false&cartItemId={cartItemId}&app=0&version={version}',
		getOrderedList:
			environment.contentBaseUrl +
			'tienda/vf-back-catalogo/api/ikki/terminal/orderedList?' +
			'contractType={contractType}&clientType={clientType}&sceneType={sceneType}&shopType={shopType}&' +
			'registerType={registerType}&name={name}',
		getTerminalsKeywords: environment.contentBaseUrl + 'tienda/vf-back-catalogo/api/ikki/terminal/keywords',
		presaleTerminal: {
			clientNotification:
				environment.contentBaseUrl + 'tienda/vf-back-trastienda/api/ikki/secure/clientNotification/save',
		},
	},
	'TiendaV10': {
		getBanners: environment.middlewareBaseUrl + 'es/v1/nextBestActionRecommendation/entryPoints',
	},
	'EntryPointManagement': {
		entryPoints:
			environment.middlewareBaseUrl +
			'es/v1/nextBestActionRecommendation/entryPoints?' +
			'screenCode={0}&customerAccountId={1}&channel.id={channel.id}',
		entryPointsWithMaxNumber:
			environment.middlewareBaseUrl +
			'es/v1/nextBestActionRecommendation/entryPoints?' +
			'screenCode={screenCode}&customerAccountId={customerAccountId}&maxNumber={maxNumber}&channel.id={channel.id}',
		entryPointsWithOutMaxNumber:
			environment.middlewareBaseUrl +
			'es/v1/nextBestActionRecommendation/entryPoints?' +
			'screenCode={screenCode}&customerAccountId={customerAccountId}&channel.id={channel.id}',
		entryPointsWithServiceId:
			environment.middlewareBaseUrl +
			'es/v1/nextBestActionRecommendation/entryPoints?' +
			'screenCode={screenCode}&customerAccountId={customerAccountId}&serviceId={serviceId}&channel.id={channel.id}',
		entryPointsWithVersion:
			environment.middlewareBaseUrl +
			'es/v1/nextBestActionRecommendation/entryPoints?' +
			'screenCode={screenCode}&customerAccountId={customerAccountId}&serviceId={serviceId}&channel.id={channel.id}&version={version}',
		entryPointsByCode:
			environment.middlewareBaseUrl +
			'es/v1/nextBestActionRecommendation/entryPoints?' +
			'screenCode={screenCode}&customerAccountId={customerAccountId}&maxNumber={maxNumber}&channel.id={channel.id}',
		entryPointsWithGroup:
			environment.middlewareBaseUrl +
			'es/v1/nextBestActionRecommendation/entryPoints?screenCode=' +
			'{screenCode}&customerAccountId={customerAccountId}&maxNumber={maxNumber}&channel.id={channel.id}&serviceId={serviceId}' +
			'&version={version}&group={group}',
	},
	'Dashboard-WS10': {
		ServiceBalance:
			environment.middlewareBaseUrl +
			'v2/service/serviceBalances?customerAccountId={siteId}&serviceIdentifier={serviceId}&categories={bundleType}&group={group}',
		CustomerBill: {
			CustomerAccountId: environment.middlewareBaseUrl + 'v3/payment/customerBills?customerAccountId={siteId}',
			Billing: environment.middlewareBaseUrl + 'v3/payment/customerBills?offset=-1&type=bill',
		},
		CustomerAccount: {
			FetchCustomerAccounts: environment.middlewareBaseUrl + 'v2/customer/customerAccounts',
			FetchCustomerAccountById: environment.middlewareBaseUrl + 'v2/customer/customerAccounts/{id}',
			History:
				environment.middlewareBaseUrl +
				'es/v1/payment/customerAccounts/{siteId}/topup/history?subscriptionId={subscription-id}&startDate={start}&endDate={end}',
		},
		Products:
			environment.middlewareBaseUrl +
			'v2/product/products?subscriptionId={subscription-id}&customerAccountId={customer-account-id}',
		Sharing: environment.middlewareBaseUrl + 'v2/product/products/{id}',
		ValidateDoc:
			environment.middlewareBaseUrl +
			'v2/subscription/subscriptionProfiles?documentId={document-id}&documentType={document-type}',
		EntryPoints:
			environment.middlewareBaseUrl +
			'es/v1/nextBestActionRecommendation/entryPoints?screenCode=' +
			'{screenCode}&customerAccountId={customerAccountId}&serviceId={serviceId}&channel.id={channel.id}&maxnumber={maxnumber}',
	},
	'SuperWifi': {
		getUserEmailVerifiedStatus: environment.plumeUrl + 'api/Customers/exists?email={emailAddress}',
		getUserToken: environment.plumeUrl + 'api/Customers/passwordLessToken',
		registerUser: environment.plumeUrl + 'api/Nodes/{extensorId}/passwordLessToken',
		getWifiInfo: environment.plumeUrl + 'api/Customers/{costumerId}/locations/{locationId}/wifiNetwork',
		getLocationsWifiNetwork: environment.plumeUrl + 'api/Customers/{costumerId}/Locations',
		saveWifiChanges: environment.plumeUrl + 'api/Customers/{costumerId}/locations/{locationId}/wifiNetwork',
		getExtendersList: environment.plumeUrl + 'api/Customers/{costumerId}/locations/{locationId}/nodes',
		getExtenderDetails: environment.plumeUrl + 'api/Customers/{costumerId}/locations/{locationId}/nodes/{nodeId}',
		editDeviceName: environment.plumeUrl + 'api/Customers/{costumerId}/devices/{mac}',
		setSuperwifiUserTokenDXL: environment.middlewareBaseUrl + 'es/v2/authenticationCredential/federateUserInfo',
		getTokenUserStatus: environment.middlewareBaseUrl + 'tmf-api/openid/v4/userinfo/{siteId}',
		personalData: environment.contentBaseUrl + 'tienda/vf-back-tienda/api/ikki/secure/checkout/personalData',
		resetCesta:
			environment.contentBaseUrl +
			'tienda/vf-back-cesta/api/ikki/secure/shoppingcartitem/reset/{customerAccountId}/{clientType}',
		extraItem:
			environment.contentBaseUrl +
			'tienda/vf-back-cesta/api/ikki/secure/catalog/frontend/extraItemOffersService?sceneType={sceneType}&siteId={siteId}&registerType={registerType}&shopType={shopType}',
		addServiceCesta: environment.contentBaseUrl + 'tienda/vf-back-cesta/api/ikki/secure/shoppingcartitem',
		extraitemData: environment.contentBaseUrl + 'tienda/vf-back-tienda/api/ikki/secure/checkout/extraitemData',
		getLegalTerms:
			environment.contentBaseUrl +
			'tienda/vf-back-tienda/api/ikki/legalterms/getlegalterms?clientType={clientType}&shopType={shopType}&page=CHECKOUT',
		setLegalTerms: environment.contentBaseUrl + 'tienda/vf-back-tienda/api/ikki/secure/legalterms/setlegalterms',
		save: environment.contentBaseUrl + 'tienda/vf-back-tienda/api/ikki/secure/checkout/save',
		getServiciability:
			environment.contentBaseUrl +
			'tienda/vf-back-tienda/api/ikki/coverage/serviciability/{siteId}?shopType={shopType}',
		getDisponibilidadCita:
			environment.contentBaseUrl +
			'tienda/vf-back-tienda/api/ikki/gestioncitas/getDisponibilidadCita?requestActive=true',
		postSaveCitaOrig: environment.contentBaseUrl + 'tienda/vf-back-tienda/api/ikki/gestioncitas/saveCitaOrig',
	},
	'DiscountRenewal': {
		resetCesta:
			environment.contentBaseUrl +
			'tienda/vf-back-cesta/api/ikki/secure/shoppingcartitem/reset/{customerAccountId}/{clientType}?idTypeJourney=renewDiscount',
		addRenewDiscount:
			environment.contentBaseUrl + 'tienda/vf-back-cesta/api/ikki/secure/shoppingcartitem/v1/addRenewDiscount',
		personalData: environment.contentBaseUrl + 'tienda/vf-back-tienda/api/ikki/secure/checkout/personalData',
		save: environment.contentBaseUrl + 'tienda/vf-back-tienda/api/ikki/secure/checkout/save',
	},
	'CPP': {
		resetCesta:
			environment.contentBaseUrl +
			'tienda/vf-back-cesta/api/ikki/secure/shoppingcartitem/reset/{customerAccountId}/{clientType}?idTypeJourney=cpp',
		personalData: environment.contentBaseUrl + 'tienda/vf-back-tienda/api/ikki/secure/checkout/personalData',
		changePricePlan:
			environment.contentBaseUrl + 'tienda/vf-back-cesta/api/ikki/secure/shoppingcartitem/v1/changePricePlan',
		getlegalterms:
			environment.contentBaseUrl +
			'tienda/vf-back-tienda/api/ikki/legalterms/getlegalterms?clientType={clientType}&shopType={shopType}&page=CHECKOUT_CPP',
		setlegalterms: environment.contentBaseUrl + 'tienda/vf-back-tienda/api/ikki/secure/legalterms/setlegalterms',
		save: environment.contentBaseUrl + 'tienda/vf-back-tienda/api/ikki/secure/checkout/save',
	},
	'Ntol': {
		createShoppingCart:
			environment.contentBaseUrl +
			'tienda/vf-back-digital/api/shoppingcart/createShoppingCartSEOIkki/?flagIsMigration=false&flagForcedCreateRequest=false&nameSEO={0}&registerTypeMobile={1}',
		getShoppingCart: environment.contentBaseUrl + 'tienda/vf-back-digital/api/shoppingcart',
		savePersonalData: environment.contentBaseUrl + 'tienda/vf-back-digital/api/checkout/savePersonalData',
		legalTerms: environment.contentBaseUrl + 'tienda/vf-back-digital/api/legalterms/?page={0}',
		saveLegalTerms: environment.contentBaseUrl + 'tienda/vf-back-digital/api/checkout/saveLegalTerms',
		validateId: environment.contentBaseUrl + 'tienda/vf-back-digital/api/checkout/validateIdentificationDoc',
		getNationalities: environment.contentBaseUrl + 'tienda/vf-back-digital/api/catalog/getNationalities',
		saveOffer: environment.contentBaseUrl + 'tienda/vf-back-digital/api/checkout/saveOffer',
		getProvinces: environment.contentBaseUrl + 'tienda/vf-back-digital/api/catalog/getProvinces',
		getNewSuggestGeocoder:
			environment.contentBaseUrl + '/tienda/vf-back-digital/api/coverage/getNewSuggestGeocoder/?address={0}',
		getNewGeocoder:
			environment.contentBaseUrl +
			'tienda/vf-back-digital/api/coverage/getNewGeocoder/?' +
			'provinceId={0}&cityId={1}&streetId={2}&number={3}&' +
			'saveRequest=false%20&contactPhoneNumber=S%2FN&contactEmail=S%2FN&postalCode={4}' +
			'&flagRecalculateTaxes=true&rateType=2&addressType=2',
		getDeliveryDates:
			environment.contentBaseUrl +
			'tienda/vf-back-digital/api/delivery/getFechasHorasEntregaPedido/?' +
			'cpDireccionEntrega={0}',
		getDeliveryOfficeDates:
			environment.contentBaseUrl + 'tienda/vf-back-digital/api/digital/matrizNext/getModalidadesEntrega',
		getTiposEntrega: environment.contentBaseUrl + 'tienda/vf-back-digital/api/delivery/getDisponibilidadEntregaPedido ',
		submitDeliveryInfo: environment.contentBaseUrl + 'tienda/vf-back-digital/api/checkout/saveDeliveryData',
		postDeliveryDataSecure: environment.contentBaseUrl + 'tienda/vf-back-digital/api/checkout/saveDeliveryData',
		submitOrder: environment.contentBaseUrl + 'tienda/vf-back-digital/api/checkout/finalizarPedido/',
		findByToken: environment.contentBaseUrl + 'tienda/vf-back-digital/api/order/findByToken',
		getOperators: environment.contentBaseUrl + 'tienda/vf-back-digital/api/catalog/getOperatorsMobile',
		saveBillingAddress: environment.contentBaseUrl + 'tienda/vf-back-digital/api/checkout/saveAddressBilling',
	},
	'AccountManagement': {
		GetMultifinancingBySiteId:
			environment.middlewareBaseUrl +
			'tmf-api/accountManagement/v4/billingAccount?' +
			'BillingAccount.relatedParty%5B?(@.role%3D%3D%27Individual%27)%5D.id={NIF}&' +
			'BillingAccount.accountRelationship.account.id={siteId}',
		GetFinancedDevicesBySiteId:
			environment.middlewareBaseUrl +
			'es/deviceFinancing/v1/installmentPlan' +
			"?relatedParty[?(@.role%3D%3D'Individual')].id={NIF}" +
			'&accountRelationship.account.id={siteId}',
		GetFinancedDevicesBySiteIdPage:
			environment.middlewareBaseUrl +
			'es/deviceFinancing/v1/installmentPlan' +
			"?relatedParty[?(@.role%3D%3D'Individual')].id={NIF}",
		GetUserPurchases: `${environment.middlewareBaseUrl}tmf-api/productInventory/v3/product?billingAcount.id={siteId}&id=MEGTT`,
		GetMultifinancingByMsisdn:
			environment.middlewareBaseUrl +
			'tmf-api/accountManagement/v4/billingAccount?' +
			'BillingAccount.relatedParty%5B?(@.role%3D%3D%27Individual%27)%5D.id={NIF}&' +
			'BillingAccount.accountRelationship.account.id={siteId}&' +
			'BillingAccount.relatedParty%5B?(@.role%3D%3D%27Subscriber%27)%5D.id={msisdn}',
		GetUserDept:
			environment.middlewareBaseUrl +
			'tmf-api/accountManagement/v4/billingAccount?' +
			'relatedParty[?(@.referredType%3D%3D%27Individual%27)].id={document}&' +
			'relatedParty[?(@.referredType%3D%3D%27Subscriber%27)].id={siteId}',
		GetUserDeptNoOTP:
			environment.middlewareBaseUrl +
			'tmf-api/accountManagement/v4/billingAccount?' +
			'id={id}&' +
			'relatedParty[?(@.referredType%3D%3D%27Individual%27)].id={document}',
		GetFinancedDevicesBySiteIdWithMaestraType:
			environment.middlewareBaseUrl +
			'es/deviceFinancing/v1/installmentPlan' +
			"?relatedParty[?(@.role%3D%3D'Individual')].id={NIF}" +
			'&accountRelationship.account.id={siteId}' +
			'&accountRelationship.account.type=maestra',
	},
	'CustomerDebt': {
		GetUserDebt:
			environment.middlewareBaseUrl +
			'es/customerdebt/v1/customerDebtState?' +
			'relatedParty[?(@.referredType%3D%3D%27Individual%27)].id={document}&' +
			'relatedParty[?(@.referredType%3D%3D%27Subscriber%27)].id={siteId}',
		GetUserDebtNoOTP:
			environment.middlewareBaseUrl +
			'es/customerdebt/v1/customerDebtState?id={id}&' +
			'relatedParty[?(@.referredType%3D%3D%27Individual%27)].id={document}',
		GetUserCommitments: environment.middlewareBaseUrl + 'tmf-api/accountManagement/v4/billingAccount/{SiteID}',
	},
	'ProductOrderingManagement': {
		ProductOrder: environment.middlewareBaseUrl + 'tmf-api/productOrderingManagement/v4/productOrder',
		ProductOrderBadgeDashboard:
			environment.middlewareBaseUrl +
			"tmf-api/productOrderingManagement/v4/productOrder?relatedParty[?(@.@referredType%3D%3D'Individual')].id={dni}" +
			'&state=Abierta,Pdte%20Cancelar&orderDate%3E%3D={fromdate}&orderDate%3C%3D={todate}',
		ProductOrderUpdate: environment.middlewareBaseUrl + 'tmf-api/productOrderingManagement/v4/productOrder/{id}',
		ProductOrderDigitalSignature:
			environment.middlewareBaseUrl +
			"tmf-api/productOrderingManagement/v4/productOrder?relatedParty[?(@.@referredType%3D%3D'Individual')].id={dni}" +
			'&state=Abierta,Cerrada,Cancelada,Pdte%20Cancelar&orderDate%3E%3D={fromdate}&orderDate%3C%3D={todate}',
	},
	'GenericCheckout': environment.contentBaseUrl + 'tienda/vf-back-tienda/api/ikki/secure/checkout/steps',
	'ProductsCommercial':
		environment.contentBaseUrl + 'tienda/vf-back-tienda/api/ikki/secure/products?app=0&version={version}',
	'ProductOneProfCommercial':
		environment.contentBaseUrl +
		'tienda/vf-back-catalogo/api/ikki/secure/oneprofesional/getOnepProductById/{idGrupoSeleccionado}?siteId={customerAccountId}',
	'EverythingOk': environment.middlewareBaseUrl + 'es/everythingIsOkayStatusCheck/v1/everythingIsOkay?customer.id={0}',
	'DeleteProduct': environment.contentBaseUrl + 'tienda/vf-back-cesta/api/ikki/secure/shoppingcartitem/delete',
	'Roaming': {
		roamingEligibleProductOffering:
			environment.middlewareBaseUrl +
			'es/productCatalog/v1/roamingEligibleProductOffering?customerAccountId={customerAccountId}&productType=ROAMING&nationalTariff={nationalTariff}&zoneId={zoneId}&serviceId={serviceId}',
		productOrderingManagement: environment.middlewareBaseUrl + 'tmf-api/productOrderingManagement/v4/productOrder',
	},
	'CaptureResponse': environment.middlewareBaseUrl + 'es/v1/nextBestActionRecommendation/captureResponse/{0}',
	'ProductSVAInventory':
		environment.middlewareBaseUrl +
		'tmf-api/productInventory/v3/product?relatedParty%5B?(@.role%3D%3D%27Customer%27)%5D.id={siteId}',
	'TariffChange': {
		ProductOrder: environment.middlewareBaseUrl + 'tmf-api/productOrderingManagement/v4/productOrder',
	},
	'Insurance': {
		getInsurance:
			environment.contentBaseUrl +
			'tienda/vf-back-tienda/api/ikki/insurance/getInsurance?msisdn={msisdn}&sap={sap}&shopType={shopType}',
		addInsurance: environment.contentBaseUrl + 'tienda/vf-back-cesta/api/ikki/secure/insurance/add',
		deleteInsurance: environment.contentBaseUrl + 'tienda/vf-back-cesta/api/ikki/secure/insurance/delete',
	},
	'SmartPayPayment': {
		getPayment:
			environment.middlewareBaseUrl +
			'tmf-api/paymentMethods/v1/paymentMethod?relatedParty%5B?(@.role%3D%3D%27Subscriber%27)%5D={msisdn}&relatedParty%5B?(@.role%3D%3D%27User%27)%5D=ONLINE',

		createPayment: environment.middlewareBaseUrl + 'tmf-api/paymentMethods/v1/paymentMethod',
		updatePayment: environment.middlewareBaseUrl + 'tmf-api/paymentMethods/v1/paymentMethod',
		deletePayment: environment.middlewareBaseUrl + 'tmf-api/paymentMethods/v1/paymentMethod/smartpay-{msisdn}',
		getTopUpHistory:
			environment.middlewareBaseUrl +
			'tmf-api/usageManagement/v4/usage?usageDate%3C%3D={finalDate}&relatedParty%5B%3F(%40.role%3D%3D%27Customer%27)%5D.id={currentSiteId}' +
			'&relatedParty%5B%3F(%40.role%3D%3D%27Subscriber%27)%5D.id={currentServiceId}&description=%27Recargas%27&usageDate%3E%3D={initialDate}',
		getTopUpHistory2:
			environment.middlewareBaseUrl +
			'tmf-api/usageManagement/v4/usage?usageDate%3C%3D={initialDate}&usageDate%3E%3D={finalDate}&description=%27Recargas%27&relatedParty%5B%3F(%40.role%3D%3D%27Subscriber%27)%5D.id={currentSiteId}' +
			'&relatedParty%5B%3F(%40.role%3D%3D%27Customer%27)%5D.id={currentServiceId}',
	},
	'getPayments': environment.contentBaseUrl + 'tienda/vf-back-tienda/api/ikki/secure/payment/getPaymentMethods',
	'singlePayment': environment.contentBaseUrl + 'tienda/vf-back-tienda/api/ikki/secure/checkout/singlePayment',
	'SmartPayRelatedParty': {
		subscriber: environment.middlewareBaseUrl + 'tmf-api/party/v4/subscriber/',
		individual: environment.middlewareBaseUrl + 'tmf-api/party/v4/individual/',
		user: environment.middlewareBaseUrl + 'tmf-api/party/v4/user/',
	},
	'sharedCodeSSO': environment.middlewareBaseUrl + 'es/oidcImplicitsharedcode/v1/sharedcode',
	'billReviewTicketCreation': environment.middlewareBaseUrl + 'es/billreview/v1/troubleTicket',
	'Stories': {
		getStories: environment.middlewareBaseUrl + 'es/v1/storiesRecommendation/stories',
	},
	'EuropeanFunds': {
		processStatus:
			environment.middlewareBaseUrl +
			'tmf-api/processFlowManagement/v4/processFlow?processFlowSpecification={processFlowSpecification}&relatedParty.id={id}',
		taskStatus: environment.middlewareBaseUrl + 'tmf-api/processFlowManagement/v4/processFlow/{idProcessFlow}/taskFlow',
		startProcess: environment.middlewareBaseUrl + 'tmf-api/processFlowManagement/v4/processFlow',
		updateTask:
			environment.middlewareBaseUrl + 'tmf-api/processFlowManagement/v4/processFlow/{idProcessFlow}/taskFlow/{idTask}',
	},
	'Bundle': {
		deteleReset: `${environment.contentBaseUrl}tienda/vf-back-cesta/api/ikki/secure/shoppingcartitem`,
		Decoders: `${environment.contentBaseUrl}tienda/vf-back-cesta/api/ikki/secure/catalog/frontend/decoders?siteId={siteId}`,
	},
	'CommercialSecuritas': {
		extraItem:
			environment.contentBaseUrl +
			'tienda/vf-back-cesta/api/ikki/secure/catalog/frontend/extraItemOffersService?sceneType={sceneType}&siteId={siteId}&shopType={shopType}&extraItemType={extraItemType}',
		patchProductsSecuritas: environment.middlewareBaseUrl + 'v2/product/products/{codigoSecuritas}_{msisdn}_{siteId}',
	},
	'Ftol': {
		getIsVodafoneClient: environment.contentBaseUrl + 'tienda/vf-back-tienda/api/ftol/checkout/getIsVodafoneClient/',
	},
	'Iteraction': {
		getInteraction: environment.middlewareBaseUrl + 'tmf-api/partyInteractionManagement/v1/partyInteraction',
	},
};
