<div class="child-container">
	<div>
		<div class="col-xs-12 contentClock">
			<img src="https://assets-es-pprd.dxlpreprod.local.vodafone.es/assets/images/ic-clock-hi-light-theme@3x.png" />
		</div>
		<div class="col-xs-12">
			<p class="section-txt">{{ 'v10.dashboard.overlay_assessor.description_3' | translate }}</p>
		</div>
	</div>
</div>
