import { Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { OverlayModel } from '@mva10/mva10-angular';
import { TranslateService } from '@ngx-translate/core';
import { tagging } from '../../../../config/tagging-config';
import { StorageService } from '../../../core/services/storage.service';
import { TaggingHelperService } from '../../../core/services/tagging.helper.service';
import { ProductsAndServicesService } from '../../../products-and-services/products-and-services.service';
import { BundleStatus } from '../../enums/bundleStatus.enum';
import { Product } from '../../models/product.model';
import { CrossFunctionalityService } from '../../services/cross-functionality.service';
import { PurchasingService } from '../../services/purchasing.service';

/**
 * this component is used to handle purchasing process
 * this component uses sp-fixed-bottom-button & vfac-overlay components together as acontainer for them
 * to handle pruchasing process using purchasingService also
 */
@Component({
	selector: 'sp-purchasing',
	templateUrl: './sp-purchasing.component.html',
	styleUrls: ['./sp-purchasing.component.scss'],
})
export class SpPurchasingComponent implements OnInit {
	showTile: boolean = false;
	isExtraProductActive: boolean = false;
	trayLoadedMode: boolean = false;
	showButtons: boolean = true;
	customerEnjoyedBundle: boolean = false;
	overLayDataCpy: OverlayModel = new OverlayModel();
	/** btn is name of button inside the nested component  */
	@Input() btnName: string;
	/** overLayData the data which willbe displayed in tray and passed to the nested component also  */
	@Input() overLayData: OverlayModel = new OverlayModel();
	/** the product which will be buy , should know this product for puchasing and tagging */
	@Input() extraProduct: Product;
	/** the disabled property for button in the nested component */
	@Input() btnDisabled: boolean;
	/** the second line in tray description it can be optional */
	@Input() desctiptionSecondLine: string;

	constructor(
		private translate: TranslateService,
		private tagging: TaggingHelperService,
		private router: Router,
		private cross: CrossFunctionalityService,
		private purchasingService: PurchasingService,
		public productsAndServicesService: ProductsAndServicesService,
		private storageService: StorageService
	) {}

	ngOnInit() {
		this.overLayDataCpy = this.overLayData;
	}
	/**
	 * method is used show confirmation topup tray
	 */
	showConfirmationTile() {
		this.showTile = true;
	}
	/**
	 * method is used hide confirmation topup tray
	 */
	closeTopUpTray() {
		this.showTile = false;
	}
	/**
	 * method is used to complete purchasing process
	 */
	buyProduct() {
		this.translate.get('productsServices.extras.buttonsList.buyBtn.text').subscribe((buttonText) => {
			const currentPage = this.tagging.getCurrentPage(this.router.url);
			const pageName = currentPage ? currentPage.page_name : '';
			const eventName = tagging.productAndServices.extraDetailsClicked.eventName
				.replace('{product_name}', this.extraProduct.Name)
				.replace('{page_name}', pageName)
				.replace('cta', buttonText);
			const data = {
				event_name: tagging.productAndServices.extraDetailsClicked.data.event_name.replace('cta', buttonText),
			};
			this.tagging.track(eventName, data);
		});
		this.sendTaggingViewState(true);
		this.setFlagsforPurchasing();
		this.purchaseProduct();
	}
	/**
	 * method is used to set flags for product to complete purchasing process in next step
	 */
	setFlagsforPurchasing() {
		// set Flags for Purchaing
		if (this.cross.status && this.cross.status.toLowerCase() === BundleStatus.Active.toLowerCase()) {
			this.isExtraProductActive = true;
		}
		if (this.cross.withBenefit) {
			this.customerEnjoyedBundle = true;
		}
		// show skeleton (loading mode)
		this.trayLoadedMode = true;
		// hide the two button in the tray
		this.showButtons = false;
		// reset all tray data show only title
		this.overLayData = new OverlayModel();
		this.overLayData.title = this.overLayDataCpy.title;
	}
	/**
	 * method to calling purchasing service
	 */
	purchaseProduct() {
		// Calling service after setting flags
		this.purchasingService
			.purchasingPrepaidProduct(
				this.isExtraProductActive,
				!this.isExtraProductActive,
				this.customerEnjoyedBundle,
				this.cross.renew
			)
			.subscribe(
				() => {
					this.showTile = false;
					this.cross.showToast = true;
				},
				(error) => {
					this.resetTrayData();
					this.purchasingService.handlingError(error);
				}
			);
	}

	/**
	 *
	 * this method to reset tray data to be used incase of fail
	 */
	resetTrayData() {
		this.showTile = false;
		this.trayLoadedMode = false;
		this.showButtons = true;
		this.overLayData = this.overLayDataCpy;
	}

	/**
	 * tagging method
	 */
	sendTaggingViewState(buy) {
		if (buy) {
			tagging.extraPreconfirmBuyBundle.page_name = tagging.staticVariables.extraPreconfirmBuy.pageName
				.replace(
					'<category>',
					tagging.staticVariables.categories[
						this.productsAndServicesService.extraProduct.Type.toString().toLowerCase().replace(/\s/g, '')
					]
				)
				.replace('<data bundle name>', this.extraProduct.Name);

			tagging.extraPreconfirmBuyBundle.page_subcategory_level_2 =
				tagging.staticVariables.extraPreconfirmBuy.subCategoryLevel2.replace(
					'<category>',
					tagging.staticVariables.categories[
						this.productsAndServicesService.extraProduct.Type.toString().toLowerCase().replace(/\s/g, '')
					]
				);

			tagging.extraPreconfirmBuyBundle.page_subcategory_level_3 =
				tagging.staticVariables.extraPreconfirmBuy.subCategoryLevel3.replace(
					'<data bundle name>',
					this.extraProduct.Name
				);
			this.tagging.view(tagging.extraPreconfirmBuyBundle, this.storageService.productAndServiceVariables);
		} else {
			tagging.extraPreconfirmDeactivateBundle.page_name = tagging.staticVariables.extraPreConfirmDeactivate.pageName
				.replace(
					'<category>',
					tagging.staticVariables.categories[
						this.productsAndServicesService.extraProduct.Type.toString().toLowerCase().replace(/\s/g, '')
					]
				)
				.replace('<data bundle name>', this.extraProduct.Name);

			tagging.extraPreconfirmDeactivateBundle.page_subcategory_level_2 =
				tagging.staticVariables.extraPreConfirmDeactivate.subCategoryLevel2.replace(
					'<category>',
					tagging.staticVariables.categories[
						this.productsAndServicesService.extraProduct.Type.toString().toLowerCase().replace(/\s/g, '')
					]
				);

			tagging.extraPreconfirmDeactivateBundle.page_subcategory_level_3 =
				tagging.staticVariables.extraPreConfirmDeactivate.subCategoryLevel3.replace(
					'<data bundle name>',
					this.extraProduct.Name
				);
			this.tagging.view(tagging.extraPreconfirmDeactivateBundle, this.storageService.productAndServiceVariables);
		}
	}
}
