import { Component, EventEmitter, Input, Output, ViewEncapsulation } from '@angular/core';

@Component({
	selector: 'sp-submenu',
	templateUrl: './submenu.component.html',
	styleUrls: ['./submenu.component.scss'],
	encapsulation: ViewEncapsulation.None,
})
export class SubmenuComponent {
	open = false;
	closing = false;
	openText = false;

	@Input() styles: object;
	@Output() closeEvent: EventEmitter<any> = new EventEmitter();
	@Input() bigsubmenu: boolean;
	@Input() starting: boolean;
	@Input()
	set show(value) {
		if (value) {
			this.starting = true;
			this.open = true;
			setTimeout(() => {
				this.openText = true;
			}, 200);
		} else {
			this.close();
		}
	}

	close() {
		this.starting = false;
		this.closing = true;
		this.openText = false;
		setTimeout(() => {
			this.closing = false;
			this.open = false;
			this.closeEvent.emit();
		}, 200);
	}
}
