import { FaultManagmentServiceType } from '../enums/fault-managment-service-type.enum';

export class ServiceAvailability {
	public hasMassiveTicket: boolean;
	public hasMassiveTobiTicket?: boolean;
	public massiveTicketId: string;
	public massiveTicketType: FaultManagmentServiceType;
	public hasOrderTicket: boolean;
	public isMobile: boolean;
	public isInternet: boolean;
	public isTV: boolean;
	public isFixed: boolean;
	public hasScheduledJob: boolean;
	public scheduledJobEndDateTime?: number;
	public scheduledJobStartDateTime?: number;
	public scheduledJobTicket?: string;
	public scheduledJobTicketText: string;
	public massiveTicketText?: string;
	public massiveTobiTicketText?: string;
	get hasAlerts() {
		return this.hasOrderTicket || this.hasMassiveTicket;
	}
}
export class ServiceAvailablityType {
	isMobile: boolean = false;
	isFixed: boolean = false;
	isInternet: boolean = false;
	isTv: boolean = false;
}

export interface ScheduledJobModal {
	text: string;
	button: string;
	valueDXL: string;
	icon: string;
	button2: string;
	valueDXL2: string;
}
