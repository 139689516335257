export class URLConfig {
	public id?: string;
	public name?: string;
	public source?: string;
	public destination?: string;
	public urlType?: string;
	public messageCode?: number = null;
}

export class URLConfigResponse {
	items?: ItemsConfig[];
	messageCode?: number;
}

export interface ItemsConfig {
	id?: string;
	name?: string;
	sourceLink?: string;
	destination?: string;
	urlType?: string;
}
