<!-- Skeleton -->
<div class="loading-section" *ngIf="showSkeleton">
	<div class="skeleton skeletonTitle"></div>
	<div class="skeleton skeletonSubtitle"></div>
	<div class="skeleton skeletondiv"></div>
	<div class="skeleton skeletondiv"></div>
	<div class="skeleton skeletondiv"></div>
</div>
<!-- Spiner -->
<div *ngIf="showSpinner" class="bill-shock-summery-spinnerContainer">
	<div class="loader"></div>
</div>

<div class="loading-section" *ngIf="!showSkeleton && !showSpinner">
	<!-- ErrorgetListDocument -->
	<div *ngIf="showError" class="contentHeight">
		<img
			class="imgListErrorVoid"
			src="{{ appService.getImgFullPath('v10.dashboard.overlay_assessor.document_manager.error.img' | translate) }}"
		/>
		<span class="titleErrorVoid">
			{{ 'v10.dashboard.overlay_assessor.document_manager.error.title' | translate }}
		</span>
		<span class="descriptionErrorVoid">
			{{ 'v10.dashboard.overlay_assessor.document_manager.error.description' | translate }}
		</span>
		<button class="donwloandErrorButton" *ngIf="donwloandError" (click)="errorBack()">Volver</button>
	</div>
	<!-- ListDocument -->
	<div *ngIf="!showError" class="contentHeight">
		<!-- ListContent -->
		<div *ngIf="contenido">
			<span class="documentunPageTitle">
				{{ 'v10.dashboard.overlay_assessor.document_manager.title' | translate }}
			</span>
			<span class="documentunPagedescription">
				{{ 'v10.dashboard.overlay_assessor.document_manager.description' | translate }}
			</span>
			<section class="mva10-c-card">
				<div
					*ngFor="let document of listDocuments"
					(click)="trackAction(taggingVariabels.btnBuzonDonwload); downloadDocument(document)"
					class="listDocuments"
				>
					<img
						class="imgDocument"
						src="{{
							appService.getImgFullPath('v10.dashboard.overlay_assessor.document_manager.file.img' | translate)
						}}"
					/>
					<span class="documentName"> {{ document.name }}.{{ document.characteristic[2].value }} </span>
					<br />
					<span class="documentType"> {{ getDocumentType(document) }} </span>
					<img
						class="imgDownload"
						src="{{
							appService.getImgFullPath('v10.dashboard.overlay_assessor.document_manager.file.download_img' | translate)
						}}"
					/>
					<span class="documentDate"> {{ getDocumentDateFormat(document) | date : 'd MMM y' }} </span>
					<hr class="hr" />
				</div>
			</section>
		</div>
		<!-- ListVoid -->
		<div *ngIf="!contenido" class="contentHeight">
			<span class="documentunPageTitle">
				{{ 'v10.dashboard.overlay_assessor.document_manager.title' | translate }}
			</span>
			<img
				class="imgListErrorVoid"
				src="{{ appService.getImgFullPath('v10.dashboard.overlay_assessor.document_manager.empty.img' | translate) }}"
			/>
			<span class="titleErrorVoid">
				{{ 'v10.dashboard.overlay_assessor.document_manager.empty.title' | translate }}
			</span>
			<span class="descriptionErrorVoid">
				{{ 'v10.dashboard.overlay_assessor.document_manager.empty.description' | translate }}
			</span>
		</div>
	</div>
</div>
