import { Component, OnInit } from '@angular/core';
import { OverlayModel } from '@mva10/mva10-angular';
import { TranslateService } from '@ngx-translate/core';
import { config } from '../../../../config/pages-config';
import { AppService } from '../../../app.service';
import { TaggingHelperService } from '../../../core/services/tagging.helper.service';
import { UNAVAILABILITY_OVERLAY } from '../../constants/defines';

@Component({
	selector: 'sp-unavilability-modal',
	templateUrl: './unavilability-modal.component.html',
	styleUrls: ['./unavilability-modal.component.scss'],
})
export class UnavilabilityModalComponent implements OnInit {
	private disableClosing: boolean;
	public unavailabilityModalData: OverlayModel;
	public showUnavailabilityModal: boolean;
	public title: boolean;
	public description: boolean;

	constructor(
		private appService: AppService,
		private tagging: TaggingHelperService,
		private translate: TranslateService
	) {}

	ngOnInit() {
		this.tagging.taggingUnavailabilityOverlay(UNAVAILABILITY_OVERLAY.TOTAL);
		this.disableClosing = !window.location.href.includes(config.login.route);
		this.translate.get('common.errorList.400.6666').subscribe((texts) => {
			this.title = texts.title;
			this.description = texts.description;
			this.unavailabilityModalData = {
				hideHeader: this.disableClosing,
				hideXButton: this.disableClosing,
				disableClosing: this.disableClosing,
				primaryButton: texts.dismissButton.text,
				showButtons: !this.disableClosing,
			};
		});
		this.showUnavailabilityModal = true;
	}

	close() {
		this.showUnavailabilityModal = false;
		setTimeout(() => {
			this.appService.errorAvilabilityApp = false;
		}, 500);
	}
}
