export class AsessorOrder {
	lnk_chatonline: number;
	lnk_form: number;
	lnk_email: number;
	lnk_whatsapp: number;
	lnk_tlf: number;
	lnk_documentum: number;
	banner_consulta: number;

	public constructor() {
		this.lnk_chatonline = 0;
		this.lnk_form = 1;
		this.lnk_email = 2;
		this.lnk_whatsapp = 3;
		this.lnk_tlf = 4;
		this.lnk_documentum = 5;
		this.banner_consulta = 99;
	}
}

export class AsessorTagEvent {
	data: {
		event_name: string;
		event_category: string;
		event_label: string;
	};
}

export class AsessorTag {
	view: {
		page_name: string;
		asset_name: string;
		navigation_level_1: string;
		navigation_level_2: string;
		navigation_level_3: string;
		navigation_level_4: string;
		page_screen: string;
	};
	btnCall: AsessorTagEvent;
	btnChat: AsessorTagEvent;
	btnWhatsapp: AsessorTagEvent;
	btnEmail: AsessorTagEvent;
	btnContact: AsessorTagEvent;
	btnRateus: AsessorTagEvent;
	btnBuzon: AsessorTagEvent;
	btnBuzonDonwload: AsessorTagEvent;
}
