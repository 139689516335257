import { Component } from '@angular/core';
import { CommonService } from '../../../../../core/services/common.service';

@Component({
	selector: 'sp-out-of-time',
	templateUrl: './out-of-time.component.html',
	styleUrls: ['./out-of-time.component.scss'],
})
export class OutOfTimeComponent {
	constructor(public commonService: CommonService) {}
}
