import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { tagging } from '../../../../config/tagging-config';
import { NewTaggingHelperService } from '../../../core/services/new-tagging.helper.service';
import { StorageService } from '../../../core/services/storage.service';
import { TaggingHelperService } from '../../../core/services/tagging.helper.service';
import { NeedBubbleHelpTabs } from '../../enums/need-bubble-help-tabs.enum';
import { NewTaggingJsonModel } from '../../models/new-tagging-json-model';

@Injectable()
export class BubbleHelpTaggingService {
	constructor(
		private tagging: TaggingHelperService,
		private storageService: StorageService,
		private newTagging: NewTaggingHelperService,
		private router: Router
	) {}

	sendTaggingCloseBubbleInteraction() {
		this.tagging.track(
			tagging.needHelpBubble.interactions.webviewClose.event_name,
			tagging.needHelpBubble.interactions.webviewClose.data,
			tagging.webview,
			this.storageService.needHelpBubbleVariables
		);
		this.clearBubbleTaggingSpecificVariables();
	}
	clearBubbleTaggingSpecificVariables() {
		this.storageService.needHelpBubbleVariables.asistente_availability = '';
		this.storageService.needHelpBubbleVariables.faqs_availability = '';
	}

	sendFaqTaggingInteraction() {
		this.tagging.track(
			tagging.needHelpBubble.interactions.clickFaq.event_name,
			tagging.needHelpBubble.interactions.clickFaq.data,
			tagging.helpBubble.faqs,
			this.storageService.needHelpBubbleVariables
		);
	}
	sendAsistenteTaggingInteraction() {
		this.tagging.track(
			tagging.needHelpBubble.interactions.clickAsistente.event_name,
			tagging.needHelpBubble.interactions.clickAsistente.data,
			tagging.helpBubble.asistente,
			this.storageService.needHelpBubbleVariables
		);
	}

	setNeedHelpBubbleTaggingSpecificVariables(clickedTab, isError = false) {
		this.clearBubbleTaggingSpecificVariables();
		switch (clickedTab) {
			case NeedBubbleHelpTabs.faqs:
				this.storageService.needHelpBubbleVariables.faqs_availability = isError
					? tagging.staticVariables.faqAvailability.unavailabe
					: tagging.staticVariables.faqAvailability.available;
				break;

			case NeedBubbleHelpTabs.chat:
				this.storageService.needHelpBubbleVariables.asistente_availability = isError
					? tagging.staticVariables.faqAvailability.unavailabe
					: tagging.staticVariables.faqAvailability.available;
				break;
		}
	}

	sendBubbleTaggingViewPage(pageObj) {
		this.tagging.view(pageObj, this.storageService.needHelpBubbleVariables);
	}

	sendTaggingOnLoadInteraction() {
		this.tagging.track(
			tagging.needHelpBubble.interactions.webviewLaunch.event_name,
			tagging.needHelpBubble.interactions.webviewLaunch.data,
			tagging.webview,
			this.storageService.needHelpBubbleVariables
		);
	}

	sendBubbleViewState(clickedTab: string): void {
		switch (clickedTab) {
			case NeedBubbleHelpTabs.faqs:
				this.sendBubbleTaggingViewPage(tagging.helpBubble.faqs);
				break;
			case NeedBubbleHelpTabs.chat:
				this.newTagging.getJsonTagging('tobi-bubble').subscribe((data: NewTaggingJsonModel) => {
					this.newTagging.state(data.page.stateList['tobi_chat']);
				});
		}
	}

	sendInitiateChatInteraction(): void {
		const pageName: string = this.tagging.getCurrentPage(this.router.url)?.page_name
			? this.tagging.getCurrentPage(this.router.url).page_name
			: this.router.url.slice(1).split('/').join(':');
		const pageDetails: string[] = pageName.includes(':') ? pageName.split(':') : [pageName];
		this.newTagging.getJsonTagging('tobi-bubble').subscribe((data: NewTaggingJsonModel) => {
			data.page.stateList['iniciar_chat'].site_section = pageDetails[0];
			data.page.stateList['iniciar_chat'].page_subcategory_level = pageDetails.slice(1);
			data.page.stateList['iniciar_chat'].page_detail = pageDetails[pageDetails.length - 1];
			data.eventList['iniciar_chat'].event.event_context = pageName;
			this.newTagging.interaction(data.eventList['iniciar_chat'], data.page.stateList['iniciar_chat']);
		});
	}
}
