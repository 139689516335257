<div class="background-opacity-tobi"></div>
<div class="tabs-cont-bubble">
	<div class="tab-bubble-header">
		<div class="header-title">
			<ng-container *ngIf="!isEnergySection; else isEnergySectionHeader">
				<img
					id="bubbleHelpHeaderIconFault"
					*ngIf="selectedTab === bubbleHelpTabs.fault"
					src="{{ 'v10.faultManagement.images.chat_light_theme' | imgFullPath }}"
					alt="customer_care-icon"
					style="height: 48px"
				/>
				<img
					id="bubbleHelpHeaderIconAutoi"
					*ngIf="selectedTab === bubbleHelpTabs.autoi"
					src="{{ 'v10.orders.autoi.chat_icon' | imgFullPath }}"
					alt="headphones-icon"
					style="height: 48px"
				/>
				<img
					id="bubbleHelpHeaderIconOther"
					*ngIf="selectedTab !== bubbleHelpTabs.fault && selectedTab !== bubbleHelpTabs.autoi"
					src="{{ 'v10.common.helpBubble.headerImage' | imgFullPath }}"
					alt="tobi"
					class="header-title__icon"
				/>
			</ng-container>
			<ng-template #isEnergySectionHeader>
				<svg id="bubbleHelpHeaderIconEnergy" class="energy-faqs-icon">
					<title>{{ 'common.itemsList.bubbleTab1.body' | translate }}</title>
					<use xlink:href="#icon-help-circle" />
				</svg>
			</ng-template>

			<p id="bubbleHelpHeaderText" [ngClass]="{ 'contact-us-header': selectedTab === bubbleHelpTabs.contactus }">
				{{ headerText }}
			</p>
			<span
				id="bubbleHelpHeaderIconClose"
				*ngIf="
					selectedTab === bubbleHelpTabs.chat ||
						(selectedTab === bubbleHelpTabs.faqs && !isMicro) ||
						!appService.isResponsiveView;
					else minimizeIcon
				"
				(click)="closeClick()"
				class="icon icon-close"
			>
			</span>
			<ng-template #minimizeIcon>
				<svg-icon
					id="bubbleHelpHeaderIconMinimize"
					(click)="closeClick()"
					[src]="bubbleHelpImages.minimize_icon_top_tobi"
					class="SVGicon"
				>
				</svg-icon>
			</ng-template>
		</div>

		<div *ngIf="selectedTab === bubbleHelpTabs.faqs && !isEnergySection && !isMicro" class="faqs-subheader">
			<p [id]="'bubbleHelpHeaderSubtitle'">{{ 'v10.common.tobiBubble.views.ayuda.header.subtitle' | translate }}</p>
			<mva10-c-button
				class="mva10-c-button--small"
				[id]="'bubbleHelpInitiateChatBtn'"
				(mva10OnClick)="onInitiateChatClick()"
				[name]="'openChat'"
				[title]="'openChat'"
				[appearance]="'primary'"
			>
				{{ 'v10.common.tobiBubble.views.ayuda.header.btnTxt' | translate }}
			</mva10-c-button>
		</div>
	</div>
	<ng-container *ngIf="!isEnergySection">
		<!-- FAQs -->
		<div class="tab-bubble-content" *ngIf="selectedTab === bubbleHelpTabs.faqs">
			<sp-iframe-modal
				[tabClickedDetector]="tabClickedDetector"
				class="iframe-modal-chat"
				[url]="FAQURL"
				width="100%"
				(iframeLoadEvent)="iframeLoadEventF($event, bubbleHelpTabs.faqs)"
				(iframeErrorEvent)="iframeErrorEvent($event, bubbleHelpTabs.faqs)"
			>
			</sp-iframe-modal>
		</div>
		<!-- Chat -->
		<div
			class="tab-bubble-content"
			*ngIf="
				selectedTab === bubbleHelpTabs.chat ||
				selectedTab === bubbleHelpTabs.fault ||
				selectedTab === bubbleHelpTabs.autoi
			"
		>
			<sp-iframe-modal
				*ngIf="(isInTime && isMicro) || !isMicro"
				[retryFunc]="appService.getChatToken.bind(appService)"
				class="iframe-modal-chat"
				[url]="appService.chatUrl"
				[showError]="appService.chatUrlShowError"
				[showErrorButton]="appService.chatUrlShowError && appService.timeOutError"
				width="100%"
				(iframeLoadEvent)="iframeLoadEventF($event, bubbleHelpTabs.chat)"
				(iframeErrorEvent)="iframeErrorEvent($event, bubbleHelpTabs.chat)"
			>
			</sp-iframe-modal>
			<sp-out-of-time *ngIf="!isInTime && isMicro"> </sp-out-of-time>
		</div>
		<!-- contact Us -->
		<div class="tab-bubble-content" width="100%" *ngIf="selectedTab === bubbleHelpTabs.contactus">
			<sp-contact-us> </sp-contact-us>
		</div>
		<!-- contact Us -->
		<!-- survey -->
		<div class="tab-bubble-content survey-section" *ngIf="selectedTab === bubbleHelpTabs.survey">
			<sp-satisfaction-survey> </sp-satisfaction-survey>
		</div>
		<!-- survey -->
		<!-- documentum -->
		<div class="tab-bubble-content documentum-section" *ngIf="selectedTab === bubbleHelpTabs.documentum">
			<sp-documentum> </sp-documentum>
		</div>
		<!-- documentum -->
	</ng-container>

	<!-- AdaraFAQS -->
	<div class="tab-bubble-content" *ngIf="isEnergySection">
		<sp-iframe-modal
			[tabClickedDetector]="tabClickedDetector"
			class="iframe-modal-chat"
			[url]="FAQURL"
			width="100%"
			(iframeLoadEvent)="iframeLoadEventF($event, 'faq')"
			(iframeErrorEvent)="iframeErrorEvent($event, 'faq')"
		>
		</sp-iframe-modal>
	</div>
	<!-- AdaraFAQS -->

	<div class="tab-bubble-content" *ngIf="selectedTab === bubbleHelpTabs.asessor">
		<sp-asessor-digital class="iframe-modal-chat" (messageEvent)="receiveMessage($event)"> </sp-asessor-digital>
	</div>
	<!-- footer -->
	<div
		*ngIf="
			selectedTab !== bubbleHelpTabs.fault &&
			selectedTab !== bubbleHelpTabs.autoi &&
			(selectedTab !== bubbleHelpTabs.faqs || isMicro) &&
			selectedTab !== bubbleHelpTabs.chat &&
			!isEnergySection
		"
		class="tab-bubble-footer"
	>
		<div class="footer-content icoAsesor" (click)="tabClicked(bubbleHelpTabs.asessor)" *ngIf="isMicro">
			<img
				id="bubbleHelpContentIcon"
				[src]="bubbleHelpImages.asesores_low_rojo"
				*ngIf="
					selectedTab === bubbleHelpTabs.asessor ||
					selectedTab === bubbleHelpTabs.chat ||
					selectedTab === bubbleHelpTabs.contactus ||
					selectedTab === bubbleHelpTabs.survey ||
					selectedTab === bubbleHelpTabs.documentum
				"
			/>
			<img
				id="bubbleHelpContentIconLowGris"
				[src]="bubbleHelpImages.asesores_low_gris"
				*ngIf="selectedTab === bubbleHelpTabs.faqs"
			/>
			<p id="bubbleHelpContentTitle">
				{{ 'v10.dashboard.overlay_assessor.title' | translate }}
			</p>
		</div>
		<button
			class="footer-content"
			(click)="tabClicked(bubbleHelpTabs.chat)"
			*ngIf="commonService.showChatInBubbleHelp && !isMicro"
		>
			<svg-icon
				id="bubbleHelpContentTabTobiIcon"
				class="svg-icon"
				[src]="bubbleHelpImages.tobi"
				[svgStyle]="{ 'width.px': 90 }"
			>
			</svg-icon>
			<p id="bubbleHelpContentTabTobiBody" [ngClass]="{ 'active-tab': selectedTab === bubbleHelpTabs.chat }">
				{{ 'common.itemsList.bubbleTab2.body' | translate }}
			</p>
		</button>
		<div
			class="footer-content"
			(click)="tabClicked(bubbleHelpTabs.contactus)"
			*ngIf="commonService.showContactUS && !isMicro"
		>
			<div class="icon icon-mail-new" [ngClass]="{ active: selectedTab === bubbleHelpTabs.contactus }"></div>
			<p id="bubbleHelpContentContact">
				{{ 'common.contactUs.itemsList.contactNavigationTitle.body' | translate }}
			</p>
		</div>
		<div
			class="footer-content"
			(click)="tabClicked(bubbleHelpTabs.faqs)"
			[ngClass]="{ 'footer-content-Full': commonService.showChatInBubbleHelp === false }"
		>
			<svg-icon
				id="bubbleHelpContentTabHelpIcon"
				class="svg-icon"
				src="{{
					selectedTab === bubbleHelpTabs.faqs ? bubbleHelpImages.help_icon_active : bubbleHelpImages.help_icon_inactive
				}}"
				[svgStyle]="{ 'width.px': 90 }"
				[ngClass]="{ active: selectedTab === bubbleHelpTabs.faqs }"
			>
			</svg-icon>
			<p id="bubbleHelpContentTabHelpBody" [ngClass]="{ 'active-tab': selectedTab === bubbleHelpTabs.faqs }">
				{{ 'common.itemsList.bubbleTab1.body' | translate }}
			</p>
		</div>
	</div>
</div>
