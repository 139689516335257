import { Component, Input } from '@angular/core';
import { AppService } from 'src/app/app.service';
import { IMVA10TopBarIcon } from 'src/app/shared/models/mva10-top-bar.model';

@Component({
	selector: 'sp-header-with-closebutton',
	templateUrl: './header-with-closebutton.component.html',
})
export class HeaderWithClosebuttonComponent {
	@Input() titleTxt: string;
	@Input() rightIconData: Array<IMVA10TopBarIcon>;

	constructor(public appService: AppService) {}

	ngOnInit(): void {
		this.rightIconData = this.rightIconData ?? [
			{
				iconRef: 'icon-close',
				iconTitle: 'quit',
				badge: {},
			},
		]; // Array to configure right icon
	}

	iconClicked(): void {
		this.appService.iconHeaderAction();
	}
}
