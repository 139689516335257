import { HttpClient } from '@angular/common/http';
import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { Observable, Subscription, of } from 'rxjs';
import { map } from 'rxjs/operators';
import { TemplatesComponent } from 'src/app/fault-management/templates/templates.component';
import { UtilitiesService } from 'src/app/shared/utils/utilities.service';
import { tagging } from '../../../../../config/tagging-config';
import { environment } from '../../../../../environments/environment';
import { AppService } from '../../../../app.service';
import { AuthenticateService } from '../../../../core/services/authenticate.service';
import { CommonService } from '../../../../core/services/common.service';
import { ConfigurationService } from '../../../../core/services/configuration.service';
import { StorageService } from '../../../../core/services/storage.service';
import { SubscriptionService } from '../../../../core/services/subscription.service';
import { TaggingHelperService } from '../../../../core/services/tagging.helper.service';
import {
	FAQURLSchema,
	OnBehalOfTypes,
	TOBibubbleValues,
	bubbleHelpImages,
	fault,
	homePageUrl,
} from '../../../constants/defines';
import { ClientTypeDesc } from '../../../enums/clientTypeDesc.enum';
import { CustomerType } from '../../../enums/customerType.enum';
import { NeedBubbleHelpTabs } from '../../../enums/need-bubble-help-tabs.enum';
import { UserSettingToken } from '../../../enums/user-setting-token.enum';
import { AdaraHelpButtonOptions } from '../../../models/HelpOptions.model';
import { ChatBotTokenRequest } from '../../../models/chat-bot-token-request.model';
import { CustomerAccountResponseModel } from '../../../models/customer-account.model';
import { CrossVariables } from '../../../models/tagging.model';
import { CustomerAccountService } from '../../../services/customer-account.service';
import { GrifoTimeService } from '../../../services/grifo-time.service';
import { Mva10FullModslServiceService } from '../../../services/mva10-full-modsl-service.service';
import { BubbleHelpTaggingService } from '../bubble-help-tagging.service';
import { BubbleHelpService } from '../bubble-help.service';
import { CompanyService } from './../../../services/company.service';

@Component({
	selector: 'sp-bubble-help-content',
	templateUrl: './bubble-help-content.component.html',
	styleUrls: ['./bubble-help-content.component.scss'],
})
export class BubbleHelpContentComponent implements OnInit, OnDestroy {
	@Input() url: string;
	@Output() xButtonClicked: EventEmitter<void> = new EventEmitter();
	/** FAQ is a default selected tab */
	selectedTab: string = NeedBubbleHelpTabs.faqs;
	isInTime: boolean = true;
	isMicro: boolean = true;
	isChatActive: boolean = true;
	isEmailActive: boolean = true;
	isPhoneActive: boolean = true;
	isGrifoTimeActive: boolean = true;
	FAQURL: string;
	headerText: string;
	tabClickedDetector: string = '';
	message: string;
	urlToOpenChatTab: string;
	bubbleHelpTabs: typeof NeedBubbleHelpTabs = NeedBubbleHelpTabs;
	isEnergySection: boolean;
	isEnergyPurchase: boolean;
	adaraSubscription: Subscription;
	bubbleHelpImages: Record<string, string> = bubbleHelpImages;

	constructor(
		private translate: TranslateService,
		private tagging: TaggingHelperService,
		private storageService: StorageService,
		private router: Router,
		public appService: AppService,
		public commonService: CommonService,
		private subscriptionService: SubscriptionService,
		private bubbleTaggingService: BubbleHelpTaggingService,
		private http: HttpClient,
		private GrifoTimeService: GrifoTimeService,
		private auth: AuthenticateService,
		private CustomerAccount: CustomerAccountService,
		private CompanyService: CompanyService,
		public configurationService: ConfigurationService,
		public fullModslServiceService: Mva10FullModslServiceService,
		public utility: UtilitiesService,
		private helpService: BubbleHelpService
	) {}

	receiveMessage($event: string): void {
		this.message = $event;
		this.tabClicked(this.message);
	}

	ngOnInit() {
		this.helpService.currentPageBubbleHelpConfig = this.commonService.currentPageBubbleHelpConfig;
		this.adaraSubscription = this.helpService.isAdaraSectionObservable.subscribe((data: AdaraHelpButtonOptions) => {
			this.isEnergySection = data.isEnergySection;
			this.isEnergyPurchase = data.isEnergySection;
		});
		this.translate.get('common.itemsList.linkToChat.body').subscribe((data) => (this.urlToOpenChatTab = data));
		this.isMicro =
			this.storageService.userProfile.customerType.toLowerCase() === CustomerType.Authorized.toLowerCase() ||
			this.utility.isConsumerAndHasMicro();

		this.loadTabs();
		window.addEventListener('message', (event) => {
			if (event.data && event.data === this.urlToOpenChatTab) {
				if (
					this.isMicro &&
					this.configurationService.configuration &&
					this.configurationService.configuration.tobiChatUnAvailability
				) {
					this.showWarningModal();
				} else {
					this.appService.helpBubbleSelectedTab = NeedBubbleHelpTabs.chat;
					this.loadTabs();
				}
			}
		});
	}

	directOpen(): void {
		const tobiBubbleValue: string = this.commonService.currentPageBubbleHelpConfig?.tobiBubble || '';
		switch (tobiBubbleValue.toLowerCase()) {
			case TOBibubbleValues.faqs.toLowerCase():
				this.tabClicked(NeedBubbleHelpTabs.faqs);
				break;
			case TOBibubbleValues.direct.toLowerCase():
			case TOBibubbleValues.directOrigin.toLowerCase():
				this.tabClicked(NeedBubbleHelpTabs.chat);
				break;
		}
	}

	loadTabs(): void {
		this.directOpen();
		this.isMicro = this.storageService.userProfile.customerType.toLowerCase() === CustomerType.Authorized.toLowerCase();
		let helpBubbleSelectedTab: string = this.appService.helpBubbleSelectedTab?.toLowerCase();
		if (this.appService.helpBubbleSelectedTab) {
			const allTabNames: string[] = Object.keys(NeedBubbleHelpTabs);
			if (allTabNames.includes(helpBubbleSelectedTab)) {
				if (helpBubbleSelectedTab === NeedBubbleHelpTabs.contactus.toLowerCase() && this.commonService.showContactUS) {
					/** now helpBubbleSelectedTab equals contactus but we have to send it to tabClicked fn as contactUs */
					helpBubbleSelectedTab = NeedBubbleHelpTabs.contactus;
				} else if (
					!(helpBubbleSelectedTab === NeedBubbleHelpTabs.chat.toLowerCase() && this.commonService.showChatInBubbleHelp)
				) {
					helpBubbleSelectedTab = this.selectFaultOrFaqs(helpBubbleSelectedTab);
				} else if (helpBubbleSelectedTab === NeedBubbleHelpTabs.survey.toLowerCase()) {
					/** now helpBubbleSelectedTab equals contactus but we have to send it to tabClicked fn as contactUs */
					helpBubbleSelectedTab = NeedBubbleHelpTabs.survey;
				} else if (helpBubbleSelectedTab === NeedBubbleHelpTabs.documentum.toLowerCase()) {
					/** now helpBubbleSelectedTab equals contactus but we have to send it to tabClicked fn as contactUs */
					helpBubbleSelectedTab = NeedBubbleHelpTabs.documentum;
				}
				this.tabClicked(helpBubbleSelectedTab);
			}
			this.appService.helpBubbleSelectedTab = null;
		}
		this.setHeaderText();
		// entry point to change the iframe url
		// next line solve anguler security issue for the faq url throw senitization
		this.changeFAQURL();
	}

	selectFaultOrFaqs(helpBubbleSelectedTab: string): string {
		return helpBubbleSelectedTab.toLowerCase() === NeedBubbleHelpTabs.fault.toLowerCase()
			? NeedBubbleHelpTabs.fault
			: helpBubbleSelectedTab.toLowerCase() === NeedBubbleHelpTabs.autoi.toLowerCase()
			? NeedBubbleHelpTabs.autoi
			: NeedBubbleHelpTabs.faqs;
	}

	changeFAQURL() {
		/** It should be reverted after tetsing on staging 2 */
		this.FAQURLHandler().subscribe((url) => {
			this.FAQURL = url;
		});
	}
	closeClick() {
		this.xButtonClicked.emit();
		this.appService.openBubbleStopScroll = false;
		this.appService.closeNeedHelpBubble.next();
		this.bubbleTaggingService.sendTaggingCloseBubbleInteraction();
		this.url = null;
	}

	setHeaderText(): void {
		let result: string = '';
		let titleAsesor: string = '';
		this.translate.get('v10').subscribe((v10) => {
			titleAsesor = v10.dashboard.overlay_assessor.title;
		});
		if (
			this.selectedTab.toLowerCase() === NeedBubbleHelpTabs.faqs.toLowerCase() ||
			this.selectedTab.toLowerCase() === NeedBubbleHelpTabs.chat.toLowerCase()
		) {
			result = this.translate.instant('v10.common.helpBubble.headerTitle');
		} else {
			this.translate.get('common').subscribe((data) => {
				const titles: Record<string, string> = {
					[NeedBubbleHelpTabs.faqs.toLowerCase()]: data.itemsList.bubbleTab1.body,
					[NeedBubbleHelpTabs.asessor.toLowerCase()]: titleAsesor,
					[NeedBubbleHelpTabs.survey.toLowerCase()]: titleAsesor,
					[NeedBubbleHelpTabs.documentum.toLowerCase()]: titleAsesor,
					[NeedBubbleHelpTabs.contactus.toLowerCase()]: data.contactUs.title,
					[NeedBubbleHelpTabs.fault.toLowerCase()]: fault,
					[NeedBubbleHelpTabs.autoi.toLowerCase()]: this.translate.instant('v10.orders.autoi.chat_title'),
				};
				result = titles[this.selectedTab.toLowerCase()];
			});
			if (
				this.isMicro &&
				this.selectedTab.toLowerCase() !== NeedBubbleHelpTabs.fault.toLowerCase() &&
				this.selectedTab.toLowerCase() !== NeedBubbleHelpTabs.faqs.toLowerCase()
			) {
				result = titleAsesor;
				const taggingVariabels: any = tagging.contactUsScreen;
				this.tagging.view(taggingVariabels.view);
			} else {
				this.tagging.view(tagging.contactUsNeedHelp);
				this.tagging.track(
					tagging.clickOnContactUsNeedHelp.eventName,
					tagging.clickOnContactUsNeedHelp.data,
					tagging.contactUsNeedHelp
				);
			}
		}
		this.headerText = result;
	}

	getGrifoTimeData() {
		if (this.isGrifoTimeActive) {
			return this.GrifoTimeService.getChatGrifo().subscribe((data) => {
				this.isInTime = data.isactive;
			});
		} else {
			this.isInTime = true;
		}
	}

	getActiveTags() {
		this.translate.get('v10').subscribe((v10) => {
			if (v10.common.settings.assessor.web.enable_assessor_chat === 'false') {
				this.isChatActive = false;
			}
			if (v10.common.settings.assessor.web.enable_assessor_email === 'false') {
				this.isEmailActive = false;
			}
			if (v10.common.settings.assessor.web.enable_assessor_tap_schedule === 'false') {
				this.isGrifoTimeActive = false;
			}
			if (v10.common.settings.assessor.web.enable_assessor_micro === 'false') {
				this.isPhoneActive = false;
			}
		});
	}

	tabClicked(clickedTab: string) {
		if (this.selectedTab?.toLowerCase() !== clickedTab?.toLowerCase()) {
			this.selectedTab = clickedTab;

			this.tabClickedDetector = new Date().getTime().toString();
			switch (this.selectedTab.toLowerCase()) {
				case NeedBubbleHelpTabs.faqs.toLowerCase():
					// here faq logic
					// entry point to change the iframe url
					this.bubbleTaggingService.sendFaqTaggingInteraction();
					this.changeFAQURL();
					break;
				case NeedBubbleHelpTabs.chat.toLowerCase():
				case NeedBubbleHelpTabs.fault.toLowerCase():
				case NeedBubbleHelpTabs.autoi.toLowerCase():
					if (this.isMicro) {
						this.getActiveTags();
						this.getGrifoTimeData();
					}
					if (
						this.configurationService.configuration &&
						this.configurationService.configuration.tobiChatUnAvailability
					) {
						// overlay
						this.showWarningModal();
						if (this.isMicro) {
							this.getActiveTags();
							this.getGrifoTimeData();
						}
					} else {
						this.CustomerAccount.GetCustomerAccounts(this.CompanyService.selectedCompanyId).subscribe({
							next: (response: CustomerAccountResponseModel) => {
								// call to find chatSegment
								this.setChatData(response.chatSegment);
							},
							error: () => {
								this.setChatData('');
							},
						});
					}
					break;
			}
			this.setHeaderText();
		}
	}

	setChatData(chatSegment: string): void {
		let chatType: UserSettingToken = UserSettingToken.ChatToken;
		let chatChannel: string = '';
		const WCSData: ChatBotTokenRequest = new ChatBotTokenRequest();
		if (NeedBubbleHelpTabs.fault.toLowerCase() === this.selectedTab.toLowerCase() && !TemplatesComponent.chatParti) {
			chatChannel = `?channelId=${this.translate.instant('faultManagement.itemsList.chatChannel_BE.body')}`;
		}
		if (NeedBubbleHelpTabs.autoi.toLowerCase() === this.selectedTab.toLowerCase()) {
			chatChannel = `?channelId=${this.translate.instant('v10.orders.autoi.chat_channel')}`;
			chatType = UserSettingToken.AutoiChat;
		}
		TemplatesComponent.chatParti = false;
		const itemListData: Record<string, { title: string; body: string }> = this.translate.instant('common.itemsList');
		WCSData.chatUrl = `${environment.chatUrl}${chatChannel}`;
		WCSData.originWeb = itemListData.originWeb.body;
		WCSData.segment = itemListData.segment.body;
		WCSData.microCompanySegment = chatSegment;
		WCSData.rsYUSegment = itemListData.rsYUSegment.body;
		WCSData.rsSegment = itemListData.rsSegment.body;
		WCSData.grantType = OnBehalOfTypes.grantType;
		WCSData.clientId = environment.CLIENT_ID;
		WCSData.subjectTokenType = OnBehalOfTypes.subjectTokenType;
		WCSData.actorTokenType = OnBehalOfTypes.actorTokenType;
		WCSData.actorToken = environment.actorToken;
		WCSData.chatbotScope = '';
		const forceRefreshToken: boolean = true;
		this.auth.refreshTokens(forceRefreshToken).subscribe((re) => {
			this.getChatBotToken(WCSData, chatType);
		});
	}

	getChatBotToken(WCSData: ChatBotTokenRequest, chatType?: UserSettingToken): void {
		this.appService.getChatBotToken(WCSData).subscribe(
			(res: any) => {
				this.appService.getChatToken(
					this.subscriptionService.customerData.customerAccountsId || this.CustomerAccount.customerAccount?.id,
					this.subscriptionService.customerData.currentService?.id,
					res.access_token,
					WCSData,
					this.subscriptionService.customerData.currentService?.siteDigital ||
						this.CustomerAccount.customerAccount?.digital,
					chatType
				);
				this.bubbleTaggingService.sendAsistenteTaggingInteraction();
			},
			(error) => {
				this.bubbleTaggingService.setNeedHelpBubbleTaggingSpecificVariables(
					NeedBubbleHelpTabs.chat.toLowerCase(),
					true
				);
				this.bubbleTaggingService.sendAsistenteTaggingInteraction();
			}
		);
	}

	FAQURLHandler(): Observable<string> {
		if (this.isEnergySection) {
			return of(this.translate.instant('v10.productsServices.adara.help.url.web'));
		}
		if (this.url) {
			return of(this.url);
		}
		// this function add values from CrossVariables to faq url arguments
		const pageInfo = this.tagging.getCurrentPage(this.router.url);
		const pageName = pageInfo && pageInfo.page_name ? this.tagging.replaceSpecialChar(pageInfo.page_name) : '';
		let partialURL = pageName ? pageName.split(' ').join('_').split(':').join('/') : '';
		return this.translate.get('common.faq.itemList').pipe(
			map((data) => {
				let defaultPageUrl: string;
				/**
				 * ex: url = productos_y_servicios/vodafone_pass/Music_Pass
				 * if this url doesn't exist in WCS then we will remove the last segment and
				 * search with the rest of the url ex: [url = productos_y_servicios/vodafone_pass]
				 * if we don't find it in WCS we will remove the last segment and search again ex: [url = productos_y_servicios] and so on ...
				 * at the end if we don't find any url we will return the home url
				 */
				if (data[partialURL]) {
					defaultPageUrl = data[partialURL].body;
				} else {
					const keySegments = partialURL.split('/');
					let correctSegment: string;
					keySegments.find((el) => {
						partialURL = partialURL.substring(0, partialURL.lastIndexOf('/'));
						if (data[partialURL]) {
							correctSegment = partialURL;
							return true;
						}
					});
					if (correctSegment) {
						defaultPageUrl = data[correctSegment].body;
					}
				}

				if (this.appService.helpBubbleItemClicked) {
					data = homePageUrl;
				} else {
					data = defaultPageUrl || homePageUrl;
				}
				let clientType = this.tagging.getUserType(this.storageService.userProfile.customerType, ClientTypeDesc);
				clientType = clientType.toLowerCase() === ClientTypeDesc.prepaid ? ClientTypeDesc.postpaid : clientType;
				return FAQURLSchema.replace('{ikki}', 'ikki')
					.replace('{client_type}', clientType)
					.replace('{partialURL}', data)
					.replace('{client_debt}', CrossVariables.client_debt)
					.replace('{service_type}', CrossVariables.service_type)
					.replace('{client_status}', CrossVariables.client_status)
					.replace('{page_platform}', CrossVariables.page_platform)
					.replace('{client_id_crm}', CrossVariables.client_id_crm)
					.replace('{client_typology}', CrossVariables.client_typology)
					.replace('{client_privileges}', CrossVariables.client_privileges);
			})
		);
	}

	iframeLoadEventF(iframe: HTMLIFrameElement, clickedTab: string): void {
		this.bubbleTaggingService.sendTaggingOnLoadInteraction();
		this.http.jsonp(iframe.src, 'callback').subscribe(
			(data) => data,
			(error) => {
				this.bubbleTaggingService.setNeedHelpBubbleTaggingSpecificVariables(clickedTab, !error.url);
				this.bubbleTaggingService.sendBubbleViewState(clickedTab);
			}
		);
	}

	iframeErrorEvent(event, clickedTab) {
		this.bubbleTaggingService.setNeedHelpBubbleTaggingSpecificVariables(clickedTab, true);
		this.bubbleTaggingService.sendBubbleViewState(clickedTab);
	}

	showWarningModal() {
		this.translate.get('v10.common.tray.tobiWarning').subscribe((data) => {
			this.fullModslServiceService.errorTitle = data.subtitle;
			this.fullModslServiceService.errorSubtitle = data.description;
			this.fullModslServiceService.img = data.icon;
			this.fullModslServiceService.increase_z_index = true;
		});
		this.appService.showErrorFullModal = true;
	}

	ngOnDestroy() {
		this.adaraSubscription?.unsubscribe();
	}

	onInitiateChatClick(): void {
		this.bubbleTaggingService.sendInitiateChatInteraction();
		this.tabClicked(NeedBubbleHelpTabs.chat.toLowerCase());
	}
}
