import { Component, EventEmitter, Input, Output } from '@angular/core';
import { GenereicNavigatorModel } from '../../models/generic-navigator.mode';

@Component({
	selector: 'sp-generic-navigator',
	templateUrl: './generic-navigator.component.html',
	styleUrls: ['./generic-navigator.component.scss'],
})
export class GenericNavigatorComponent {
	@Input() navigator: GenereicNavigatorModel;
	@Output() navigateEvent = new EventEmitter();

	navigateTo() {
		this.navigateEvent.emit();
	}
}
