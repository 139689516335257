<div class="form-group sp-text" [ngClass]="{ 'mva10-style': mva10Textbox }">
	<label *ngIf="label" for="{{ id }}" [ngClass]="{ whiteLabel: upgrade }"> {{ label }} </label>
	<div
		class="warning-caption row"
		*ngIf="(showInlineHint && variable.invalid && !(variable.pristine || parentFormPristine)) || showBackEndError"
	>
		<div class="col-xs-2">
			<div class="icon icon-warning"></div>
		</div>
		<div class="col-xs-10 caption-text">
			<p [innerHTML]="hintText"></p>
		</div>
		<div id="arrow" class="arrow-warning arrow-warning-left"></div>
	</div>
	<ng-content> </ng-content>
	<div
		class="input-txt-container input-txt"
		[ngClass]="{
			focus: showSuccessIcon && (variable.pristine || parentFormPristine),
			success: variable.valid && showSuccessIcon && !(variable.pristine || parentFormPristine) && !showBackEndError,
			fail:
				(showSuccessIcon && variable.invalid && !(variable.pristine || parentFormPristine)) ||
				(showBackEndError && !hideRedBorder)
		}"
	>
		<div
			class="error-message"
			[ngClass]="{ 'error-message-carusal': chaneErrorStyle }"
			*ngIf="
				((patternErrorMessage && !variable.valid && variable.value && enforceValidation) || showError) && !mva10Textbox
			"
		>
			<i class="icon icon-warning"> </i>
			<p>{{ patternErrorMessage }}</p>
		</div>
		<div [ngClass]="{ 'disable-manual-login': dimmedFiled }"></div>
		<div
			class="border"
			*ngIf="mva10Textbox && (focused || (variable.touched && dirtyValidationCheck))"
			[ngClass]="{
				'error-class': !variable.valid && variable.value && enforceValidation,
				'border-gray': showGrayBorderLabel,
				'red-border': mva10Textbox && !variable.valid && (variable.value || dirtyValidationCheck) && enforceValidation
			}"
		>
			<div class="placeholder" *ngIf="variable.value || focused">
				{{ placeholder }}
			</div>
		</div>
		<input
			(blur)="onBlurEventF()"
			(click)="showXButtonFn()"
			autocomplete="off"
			[disabled]="disabledField"
			type="{{ type }}"
			class="form-control"
			name=""
			id="{{ id }}"
			[pattern]="pattern"
			[required]="isRequired"
			[ngClass]="{
				'error': (enforceValidation && !variable.valid) || isMatch,
				'upperCase': isUpperCase,
				'placeholder-right': placeholderToRight,
				'mva10-not-focused-textbox': mva10Textbox && !focused && !(variable.touched && dirtyValidationCheck),
				'mva10-focused-textbox': mva10Textbox && focused
			}"
			[placeholder]="placeholder"
			(keypress)="keyPressFn($event)"
			(paste)="onPaste($event)"
			(keyup)="Change($event)"
			(keydown)="onKeyDown($event)"
			(focus)="onFocusEventF($event)"
			[(ngModel)]="innerValue"
			#variable="ngModel"
			(input)="onInputEventF($event)"
			matInput
			[matDatepicker]="picker"
			(dateChange)="onDateChanged($event)"
		/>
		<mat-datepicker-toggle matSuffix [for]="picker" class="mat-toggle-icon">
			<mat-icon matDatepickerToggleIcon> <img src="https://assets-es-pprd.dxlpreprod.local.vodafone.es/assets/images/ic-calendar@2x.png" /> </mat-icon>
		</mat-datepicker-toggle>
		<mat-datepicker (closed)="toggle(picker)" #picker> </mat-datepicker>
		<span
			*ngIf="mva10Textbox && !variable.valid && (variable.value || dirtyValidationCheck) && enforceValidation"
			[ngClass]="{ red: redHelperText }"
			class="error-text"
		>
			{{ patternErrorMessage }}
		</span>

		<button
			id="SpTextComp_btn_reset_value"
			*ngIf="resetButton && showXButton"
			(click)="innerValue = ''"
			class="icon-reset"
			type="reset"
		>
			<span class="icon icon-close"> </span>
		</button>
		<a class="icon" id="SpTextComp_a_icon">
			<div class="" *ngIf="showSuccessIcon && variable.valid && !isPassword && !showBackEndError">
				<img src="https://assets-es-pprd.dxlpreprod.local.vodafone.es/assets/images/selection.png" alt="" class="selection-icon" />
			</div>
			<div class="" *ngIf="showFailIcon && !variable.valid">
				<img src="https://assets-es-pprd.dxlpreprod.local.vodafone.es/assets/images/incorrect.png" alt="" class="selection-icon" />
			</div>
			<span
				class="icon icon-info-circle icon-login"
				id="icon-info-white"
				(click)="openPopup()"
				[ngClass]="{ resizeIcon: !isShow }"
				*ngIf="
					showInfoIcon && !isPassword && (!showSuccessIcon || (showSuccessIcon && !variable.valid) || showBackEndError)
				"
			>
			</span>
			<span
				class="icon icon-hide-password"
				*ngIf="showPasswordIcon && isPassword && hashed"
				[ngClass]="{ resizeIcon: !isShow }"
				(click)="toggleIcon()"
			>
			</span>
			<span
				class="icon icon-show-password"
				*ngIf="showPasswordIcon && isPassword && !hashed"
				[ngClass]="{ resizeIcon: !isShow }"
				(click)="toggleIcon()"
			>
			</span>
		</a>
	</div>
	<div class="pop-up-container" *ngIf="isShow">
		<div class="pop-up-background"></div>
		<div id="arrow"></div>
		<div class="pop-up-text">
			<div class="row">
				<div class="col-xs-1">
					<span class="icon icon-info-circle" id="icon-info-white"> </span>
				</div>
				<div class="col-xs-9">
					<p class="txt-rg popuptext" id="myPopup" [innerHTML]="infoText"></p>
				</div>

				<div class="col-xs-1 close-icon">
					<span class="icon icon-close" id="close-info-manual-login" (click)="closePopup()"> </span>
				</div>
			</div>
		</div>
	</div>
</div>
