import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { environment } from '../../../environments/environment';
import { SMAPIheaders } from '../../shared/constants/defines';
import { EncryptService } from '../../shared/services/encrypt.service';

@Injectable()
export class SmapiService {
	sec: any = window.sec; // sec instance is now attached with window object
	constructor(private encryptService: EncryptService) {}

	initSmapi(): void {
		this.sec.init({
			enabled: true,
			environment: environment.smapi,
			verbose: false,
			cookiesEnabled: false,
			networkMonitoringEnabled: true,
			spaTrackingOptions: {
				enabled: true,
				replaceState: true,
			},
			headers: SMAPIheaders,
		});
	}

	setSmapiId(id: string): void {
		this.encrypteSmapiId(id).subscribe((encryptedId: string) => {
			this.sec.setUserID(encryptedId);
		});
	}

	removeSmapiId(): void {
		this.sec.setUserID(null);
	}

	encrypteSmapiId(id: string): Observable<string> {
		return this.encryptService.encrypt([id]).pipe(
			map((res: string[]) => {
				if (res.length > 0) {
					return res[0];
				} else {
					return '';
				}
			})
		);
	}
}
