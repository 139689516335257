import { Guid } from 'guid-typescript';
import { ANONYMOUS, codiogo, mensaj, platformWeb } from '../../../shared/constants/defines';
import { IframeStatus } from '../../../shared/enums/iframe-status.enum';
import {
	PaymentPatchRequestBody,
	ThirdPartyIframePayload,
	ThirdPartyIframeRequestBodyHeader,
	ThirdPartyIframeUrlRequest,
	ThirdPartyPatchRequestBodyHeader,
} from '../../../shared/models/payment';
import { PaymentNavigationService } from '../payment-navigation.service';
import { PaymentDxlparamInterface } from './payment-dxlparam-interface';
export class PaymentDxlparamDefault implements PaymentDxlparamInterface {
	constructor(private paymentNavigationService: PaymentNavigationService) {}
	/**
	 * @param appUUID the appUUID uinque id for the user
	 * function to construct the request body needed by the dxl
	 * by the post request (get net plus url)
	 */
	getNetPlusUrlRequestBody(appUUID: Guid): ThirdPartyIframeRequestBodyHeader {
		const thirdPartyIframeUrlInfo: ThirdPartyIframeRequestBodyHeader = new ThirdPartyIframeRequestBodyHeader();
		const requestBody: ThirdPartyIframeUrlRequest = new ThirdPartyIframeUrlRequest();
		requestBody.payer.documentId = this.paymentNavigationService.paymentJourneyParams.payer.documentId;
		requestBody.payer.siteId = this.paymentNavigationService.paymentJourneyParams.payer.siteId;
		requestBody.payer.serviceId = this.paymentNavigationService.paymentJourneyParams.payer.serviceId;
		requestBody.buyer.documentId = this.paymentNavigationService.paymentJourneyParams.buyer.documentId;
		requestBody.buyer.siteId = this.paymentNavigationService.paymentJourneyParams.buyer.siteId;
		requestBody.buyer.serviceId = this.paymentNavigationService.paymentJourneyParams.buyer.serviceId;
		requestBody.amount = this.paymentNavigationService.paymentJourneyParams.amountInCent;
		requestBody.language = this.paymentNavigationService.paymentJourneyParams.language;
		requestBody.journeyId = this.paymentNavigationService.paymentJourneyParams.journeyId;
		requestBody.platform = platformWeb;
		requestBody.appUUID = appUUID;
		requestBody.systemID = this.paymentNavigationService.paymentJourneyParams.systemID;
		requestBody.systemReferenceId = this.paymentNavigationService.paymentJourneyParams.systemReferenceId;
		// this if we have saved credit card
		if (this.paymentNavigationService.paymentJourneyParams.token) {
			requestBody.token = this.paymentNavigationService.paymentJourneyParams.token;
			requestBody.cardMask = this.paymentNavigationService.paymentJourneyParams.cardMask;
			requestBody.expiryDate = this.paymentNavigationService.paymentJourneyParams.expiryDate;
		}
		thirdPartyIframeUrlInfo.requestBody = requestBody;
		thirdPartyIframeUrlInfo.isAnonymous = this.paymentNavigationService.paymentJourneyParams.isAnonymous;
		thirdPartyIframeUrlInfo.urlSiteId = thirdPartyIframeUrlInfo.isAnonymous
			? ANONYMOUS
			: thirdPartyIframeUrlInfo.requestBody.payer.siteId;
		thirdPartyIframeUrlInfo.urlServiceId = thirdPartyIframeUrlInfo.requestBody.payer.serviceId
			? thirdPartyIframeUrlInfo.requestBody.payer.serviceId
			: '0';
		return thirdPartyIframeUrlInfo;
	}
	/**
	 * @param payload object contains payload needed to fill the patch request body
	 * method to construct the patch request (update the dxl with the netplus status)
	 */
	getPatchNetplusStatusRequestBody(payload: ThirdPartyIframePayload): ThirdPartyPatchRequestBodyHeader {
		const thirdPartyPatchRequestBodyHeader: ThirdPartyPatchRequestBodyHeader = new ThirdPartyPatchRequestBodyHeader();
		let requestBody: PaymentPatchRequestBody = new PaymentPatchRequestBody();
		// fill payer data
		requestBody.payer.documentId = this.paymentNavigationService.paymentJourneyParams.payer.documentId;
		requestBody.payer.siteId = this.paymentNavigationService.paymentJourneyParams.payer.siteId;
		requestBody.payer.serviceId = this.paymentNavigationService.paymentJourneyParams.payer.serviceId;
		// fill buyer data
		requestBody.buyer.documentId = this.paymentNavigationService.paymentJourneyParams.buyer.documentId;
		requestBody.buyer.siteId = this.paymentNavigationService.paymentJourneyParams.buyer.siteId;
		requestBody.buyer.serviceId = this.paymentNavigationService.paymentJourneyParams.buyer.serviceId;

		requestBody.journeyId = this.paymentNavigationService.paymentJourneyParams.journeyId;
		requestBody.platform = platformWeb;
		requestBody.transactionId = payload.transactionId;
		requestBody.status = payload.status;
		requestBody.systemReferenceId = this.paymentNavigationService.paymentJourneyParams.systemReferenceId;
		if (payload.netPlusParams && payload.netPlusParams.cardMask) {
			requestBody.cardMask = payload.netPlusParams.cardMask;
			requestBody.cardNetplus = payload.netPlusParams.cardToken;
		}
		if (payload.status === IframeStatus.failed) {
			requestBody.error = payload.error;
		}
		if (payload.status === IframeStatus.complete || payload.status === IframeStatus.failed) {
			requestBody = { ...requestBody, ...payload.iframUrlParam };
		}
		// addedd params for eshop
		if (payload.status === IframeStatus.timeout || payload.status === IframeStatus.canceled) {
			const param: any = {};
			param.codigo = payload.status === IframeStatus.timeout ? codiogo.timeout : codiogo.cancel;
			param.NumOperacionOrigen = this.paymentNavigationService.thirdPartyURLParams.transactionId;
			param.mensaje = payload.status === IframeStatus.timeout ? mensaj.timeoutMessage : mensaj.cancelMessage;
			requestBody = { ...requestBody, ...param };
		}
		thirdPartyPatchRequestBodyHeader.requestBody = requestBody;
		thirdPartyPatchRequestBodyHeader.isAnonymous = this.paymentNavigationService.paymentJourneyParams.isAnonymous;
		thirdPartyPatchRequestBodyHeader.urlSiteId = thirdPartyPatchRequestBodyHeader.isAnonymous
			? ANONYMOUS
			: thirdPartyPatchRequestBodyHeader.requestBody.payer.siteId;
		thirdPartyPatchRequestBodyHeader.urlServiceId = thirdPartyPatchRequestBodyHeader.requestBody.payer.serviceId
			? thirdPartyPatchRequestBodyHeader.requestBody.payer.serviceId
			: '0';
		return thirdPartyPatchRequestBodyHeader;
	}
	mapPaymentOutputParams(res: any): void {
		this.paymentNavigationService.paymentOutPutParams.codigo = res.codigo || null;
		this.paymentNavigationService.paymentOutPutParams.fechaOpOrigen = res.fechaOpOrigen || null;
		this.paymentNavigationService.paymentOutPutParams.mensaje = res.mensaje || null;
		this.paymentNavigationService.paymentOutPutParams.refundCodigo = res.refundCodigo || null;
		this.paymentNavigationService.paymentOutPutParams.refundId = res.refundId || null;
		this.paymentNavigationService.paymentOutPutParams.refundFecha = res.refundFecha || null;
		this.paymentNavigationService.paymentOutPutParams.refundMensaje = res.refundMensaje || null;
		this.paymentNavigationService.paymentOutPutParams.systemReferenceId =
			this.paymentNavigationService.paymentJourneyParams.systemReferenceId;
		this.paymentNavigationService.paymentOutPutParams.numOperacionOrigen =
			this.paymentNavigationService.thirdPartyURLParams.transactionId;
		this.paymentNavigationService.paymentOutPutParams.importe =
			this.paymentNavigationService.paymentJourneyParams.amountInCent;
	}
}
