import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { throwError } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { config } from '../../../config/pages-config';
import { BUY_SIM_JOURNEY } from '../../shared/constants/defines';
import { BuySimSteps } from '../../shared/enums/buy-sim-steps.enum';
import { AddressVerticalInfoModel } from '../../shared/models/buy-sim-address-vertical-info.model';
import { SuggestedAddressModel } from '../../shared/models/buy-sim-suggested-address.model';
import { SaveDeliveryDataRequest } from '../../shared/models/defines.model';
import { IDynamicTray } from '../../shared/models/dynamic-tray.model';
import { CoverageAddressList } from '../../shared/models/geocoder.model';
import { ModalidadesEntregaData } from '../../shared/models/modalidadesEntregaResponse.model';
import { DynamicTrayService } from '../../shared/services/dynamic-tray.service';
import { BuySimDataService } from './buy-sim-data.service';
import { BuySimService } from './buy-sim.service';

@Injectable()
export class NormalizeAddressService {
	/**list of suggested address comes from api */
	suggestedAddressList: SuggestedAddressModel[] = new Array<SuggestedAddressModel>();
	/** selected suggested address by user */
	selectedAddress: SuggestedAddressModel;
	/** list of address vertical info from api */
	addressHorizontalInfoList: AddressVerticalInfoModel[] = new Array<AddressVerticalInfoModel>();
	dynamicTrayData: IDynamicTray = {
		basicTrayData: {
			isScrollableTray: false,
		},
		isBoldTitle: true,
	};
	/**list of address vertical info from api without mapping */
	addressHorizontalDataList: CoverageAddressList[] = [];
	constructor(
		private buySimDataService: BuySimDataService,
		public buySimService: BuySimService,
		private dynamicTrayService: DynamicTrayService,
		private translateService: TranslateService,
		private router: Router
	) {}

	/** gets suggested address based on user search input
	 * @param address {string} user search text
	 */
	getNewSuggestGeocoder(address) {
		return this.buySimDataService.getNewSuggestGeocoder(address).pipe(
			map(
				(data: any) => {
					const addressList = data.data.results || [];
					const status = data.result?.status;
					if (status === BUY_SIM_JOURNEY.statusOk && addressList.length > 0) {
						this.suggestedAddressList = new Array<SuggestedAddressModel>();
						addressList.forEach((address) => {
							const newAddress: SuggestedAddressModel = new SuggestedAddressModel();
							newAddress.formattedAddress = address.formatted_address;
							newAddress.provinceId = this.getAddressComponent(
								BUY_SIM_JOURNEY.addressComponentsType.provinceId,
								address
							);
							newAddress.cityId = this.getAddressComponent(BUY_SIM_JOURNEY.addressComponentsType.cityId, address);
							newAddress.postalCode = this.getAddressComponent(
								BUY_SIM_JOURNEY.addressComponentsType.postalCode,
								address
							);
							newAddress.streetId = this.getAddressComponent(BUY_SIM_JOURNEY.addressComponentsType.streetId, address);

							this.suggestedAddressList.push(newAddress);
						});
						return this.suggestedAddressList.map((data) => {
							const addressElements: string[] = data.formattedAddress.split(',');
							addressElements.splice(-2, 2);
							return addressElements.join(',');
						});
					} else {
						this.buySimService.handleGeneralError(data);
					}
				},
				(error) => {
					this.buySimService.handleGeneralError(error);
				}
			)
		);
	}

	/** gets address component from vertical info using address component type
	 * @param addressComponentType {string}
	 * @param address
	 */
	getAddressComponent(addressComponentType, address) {
		const addressComponents = address.address_components || [];
		const selectedAddressComponent = addressComponents.find((component) => {
			const componentType = component.types?.[0] || '';
			return componentType === addressComponentType;
		});
		return selectedAddressComponent?.short_name || '';
	}

	/** gets vertical info of selected suggested address
	 * @param streetNumber {string}
	 * @param selectedAddressIndex {number} selected index of suggested address
	 */
	getNewGeocoder(streetNumber, selectedAddressIndex) {
		this.selectedAddress = this.suggestedAddressList[selectedAddressIndex];
		return this.buySimDataService.getNewGeocoder(streetNumber, this.selectedAddress).pipe(
			map((data) => {
				const status = data.result?.status;
				if (status === BUY_SIM_JOURNEY.statusOk) {
					this.addressHorizontalInfoList = new Array<AddressVerticalInfoModel>();
					this.addressHorizontalDataList = data.data?.coverageAddressList || [];
					this.addressHorizontalDataList?.forEach((horizontalDataItem: CoverageAddressList) => {
						const horizontalInfoItem: AddressVerticalInfoModel = this.mapVerticalInfo(horizontalDataItem);
						this.addressHorizontalInfoList.push(horizontalInfoItem);
						// jsonpath refactor: wrong type, needs to be casted as any
						this.buySimService.addressVertInfo = horizontalDataItem as any;
					});
					return this.addressHorizontalDataList?.length > 0;
				} else {
					this.buySimService.handleGeneralError(data);
				}
			}),
			catchError((error) => {
				this.buySimService.handleGeneralError(error);
				return throwError(error);
			})
		);
	}

	/** maps address vertical info*/
	mapVerticalInfo(item: CoverageAddressList) {
		const horizontalInfoItem = new AddressVerticalInfoModel();
		horizontalInfoItem.number = item.descriptionNumber;
		horizontalInfoItem.stair = item.descriptionStair;
		horizontalInfoItem.floor = item.descriptionFloor;
		horizontalInfoItem.door = item.descriptionDoor;
		horizontalInfoItem.verticalId = item.verticalId;
		horizontalInfoItem.viaType = item.viaType;
		return horizontalInfoItem;
	}

	/** submit save delivery info using selected address elements
	 * @param selectedAddressElements {AddressVerticalInfoModel}
	 */
	submitAddressInfo(selectedAddressElements: AddressVerticalInfoModel, useVerticalInfo = false) {
		this.buySimService.showFullLoader = true;
		this.dynamicTrayService.close();
		this.setAddressInfoRequestBody(selectedAddressElements, useVerticalInfo).subscribe((addressInfoBodyObj) => {
			this.buySimDataService.submitAddressInfo(addressInfoBodyObj).subscribe(
				(data: any) => {
					const status = data.result?.status;
					this.buySimService.showFullLoader = false;
					if (status === BUY_SIM_JOURNEY.statusOk) {
						this.buySimService.preventScroll = false;
						this.buySimService.isPaymentFullScreen = true;
						this.buySimService.hideTicketDetails = true;
						this.router.navigate([config.buySim.payment.route]);
						this.buySimService.currentStep = BuySimSteps.stepThreePayment;
					} else {
						this.buySimService.handleGeneralError(data);
					}
				},
				(error) => {
					this.buySimService.showFullLoader = false;
					this.buySimService.handleGeneralError(error);
				}
			);
		});
	}

	/** sets save delivery info api body object
	 * @param selectedAddressElements {AddressVerticalInfoModel} selected address elements
	 */
	setAddressInfoRequestBody(selectedAddressElements: AddressVerticalInfoModel, useVerticalInfo = false) {
		const body: SaveDeliveryDataRequest = { ...BUY_SIM_JOURNEY.saveDeliveryDataRequestObj };
		return this.buySimDataService.getDeliveryDates(selectedAddressElements.postalCode).pipe(
			map((data: any) => {
				const status = data.result?.status;
				if (status === BUY_SIM_JOURNEY.statusOk) {
					if (useVerticalInfo) {
						this.setAddressBodyObjFromVerticalInfo(selectedAddressElements, body);
					} else {
						this.setAddressBodyObjFromManualInput(selectedAddressElements, body);
					}
					this.setOrderBodyObj(data.data, body);
					body.address.additionalInfo = selectedAddressElements.additionalInfo;
					return body;
				} else {
					this.dynamicTrayService.close();
					this.buySimService.handleGeneralError(data);
				}
			}),
			catchError((error) => {
				this.dynamicTrayService.close();
				this.buySimService.handleGeneralError(error);
				return throwError(error);
			})
		);
	}

	/** sets order prop in body from getDeliveryDates api */
	setOrderBodyObj(data: ModalidadesEntregaData, requestBody: SaveDeliveryDataRequest) {
		requestBody.xCosteEur = data.horasEntrega?.[0]?.xCosteOpcEntrega?.eurPrecio;
		requestBody.xCanal = data.xCanal;
		requestBody.xCostePromoEur = data.horasEntrega?.[0]?.xCosteOpcEntrega?.eurPrecioPromocion;
		requestBody.xDestEntrega = data.horasEntrega?.[0]?.cdTipoDeliveryType;
		requestBody.xDsOpcEntrega = data.horasEntrega?.[0]?.dsOpcEntrega;
		requestBody.xIdentificacion = data.horasEntrega?.[0]?.xIdentificacion;
		requestBody.xOpcEntrega = data.horasEntrega?.[0]?.xOpcEntrega;
		requestBody.xPerfil = data.xPerfil;
		requestBody.xPrioridadEntrega = data.horasEntrega?.[0]?.xPrioridadEntrega;
		requestBody.xTipoCliente = data.xTipoCliente;
		requestBody.xTipoProceso = data.xTipoProceso.dsTipoProceso;
		requestBody.cdTipoDeliveryType = data.horasEntrega?.[0]?.cdTipoDeliveryType;
		requestBody.cdTipoProceso = data.xTipoProceso.cdTipoProceso;
		requestBody.dsOpcEntrega = data.horasEntrega?.[0]?.dsOpcEntrega;
		requestBody.fcEntregaPedido = data.fechasEntrega?.[0]?.fechaEntregaPedido;
		requestBody.idModalidadEntrega = data.horasEntrega?.[0]?.idModalidadEntrega;
		requestBody.idTipoEntrega = data.horasEntrega?.[0]?.idTipoEntrega;
		if (this.buySimService.portabilityChecked) {
			requestBody.itPortabilidad = true;
			// jsonpath refactor: deliveryDates should be DeliveryDate type but there is a Date-string mismatch type
			// jsonpath refactor: params read as item['xxx'] are missing in model
			const deliveryDates = data.fechasEntrega?.[0]?.['fechasPortabilidad']?.[0];
			if (deliveryDates) {
				requestBody.fcPortabilidad = deliveryDates.fechaPortabilidad;
				requestBody.xDiaEntrega = deliveryDates.xDiaEntrega;
				requestBody.xHoraLiberacion = deliveryDates.xHoraLiberacion;
				requestBody.xSlaFraude = deliveryDates.xSlaFraude;
			}
		}
	}

	/**set address request obj when user choose to enter address manual
	 * @param selectedAddressElements {AddressVerticalInfoModel}
	 * @param requestBody
	 */
	setAddressBodyObjFromManualInput(
		selectedAddressElements: AddressVerticalInfoModel,
		requestBody: SaveDeliveryDataRequest
	) {
		requestBody.address.number = selectedAddressElements.number;
		requestBody.address.name = selectedAddressElements.name;
		requestBody.address.stairway = selectedAddressElements.stair;
		requestBody.address.floor = selectedAddressElements.floor;
		requestBody.address.door = selectedAddressElements.floor;
		requestBody.address.postcode = selectedAddressElements.postalCode;
		requestBody.address.postCodeDelivery = selectedAddressElements.postalCode;
		requestBody.address.province = this.getProvinceId(selectedAddressElements.provinceName);
		requestBody.address.town = selectedAddressElements.city;
		requestBody.address.additionalInfo = selectedAddressElements.additionalInfo;
		requestBody.address.verticalId = null;
	}

	/** sets address obj in api body request using user selected address elements
	 * @param selectedAddressElements {AddressVerticalInfoModel} user selection
	 * @param requestBody
	 */
	setAddressBodyObjFromVerticalInfo(
		selectedAddressElements: AddressVerticalInfoModel,
		requestBody: SaveDeliveryDataRequest
	) {
		const selectedVerticalInfo: CoverageAddressList = this.getSelectedVerticalInfo(selectedAddressElements);
		this.mapAdressObjValues(selectedVerticalInfo, requestBody);
	}

	/** maps address obj in request body of save delivery info api
	 * @param selectedVerticalInfo address selections by user
	 * @param requestBody save delivery info request body
	 */
	mapAdressObjValues(selectedVerticalInfo: CoverageAddressList, requestBody: SaveDeliveryDataRequest) {
		requestBody.address.name = selectedVerticalInfo.street;

		requestBody.address.number = selectedVerticalInfo.number;

		requestBody.address.stairway = selectedVerticalInfo.stair;

		requestBody.address.floor = selectedVerticalInfo.floorNumber;

		requestBody.address.door = selectedVerticalInfo.gate;

		requestBody.address.descriptionNumber = selectedVerticalInfo.descriptionNumber;

		requestBody.address.descriptionStair = selectedVerticalInfo.descriptionStair;

		requestBody.address.descriptionFloor = selectedVerticalInfo.descriptionFloor;

		requestBody.address.descriptionDoor = selectedVerticalInfo.descriptionDoor;

		requestBody.address.postcode = selectedVerticalInfo.cp;

		requestBody.address.postCodeDelivery = selectedVerticalInfo.cp;

		requestBody.address.verticalId = selectedVerticalInfo.verticalId;

		requestBody.address.type = selectedVerticalInfo.viaType;

		requestBody.address.province = this.getProvinceId(selectedVerticalInfo.provinceName);

		requestBody.address.town = selectedVerticalInfo.city;
		// jsonpath refactor: params read as item['xxx'] are missing in model
		requestBody.address.duplicate = selectedVerticalInfo['duplicate'] || null;

		requestBody.address.block = selectedVerticalInfo['block'] || null;

		requestBody.address.identificador = selectedVerticalInfo['identificador'] || null;

		requestBody.address.hand1 = selectedVerticalInfo.hand1 || null;

		requestBody.address.hand2 = selectedVerticalInfo.hand2 || null;
	}

	/** gets address vertical info from api data after user selects address components
	 * @param selectedAddressElements {AddressVerticalInfoModel} user selections
	 */
	getSelectedVerticalInfo(selectedAddressElements: AddressVerticalInfoModel) {
		let verticalId: string;
		let viaType: string;
		return this.addressHorizontalDataList.find((item: CoverageAddressList) => {
			const number = item.descriptionNumber;
			const stair = item.descriptionStair;
			const floor = item.descriptionFloor;
			const door = item.descriptionDoor;
			if (this.buySimService.isCorreosDelivery) {
				verticalId = item.verticalId;
				viaType = item.viaType;
			}
			return (
				selectedAddressElements.number === number &&
				selectedAddressElements.stair === stair &&
				selectedAddressElements.floor === floor &&
				selectedAddressElements.door === door &&
				selectedAddressElements.verticalId == verticalId &&
				selectedAddressElements.viaType == viaType
			);
		});
	}

	/**gets province id by province name
	 * @param provinceName {string}
	 * returns province id
	 */
	getProvinceId(provinceName) {
		const province = this.buySimService.provinceDataList.find(
			(province) =>
				province.name
					.toLowerCase()
					.normalize('NFD')
					.replace(/[^(0-9a-zA-Z- :\\-_,)]/gi, '') ===
				provinceName
					.toLowerCase()
					.normalize('NFD')
					.replace(/[^(0-9a-zA-Z- :\\-_,)]/gi, '')
		);
		return province ? province.id : 0;
	}
}
