import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ImgFullPathPipe } from './img-full-path.pipe';

@NgModule({
	declarations: [ImgFullPathPipe],
	providers: [ImgFullPathPipe],
	exports: [ImgFullPathPipe],
	imports: [CommonModule],
})
export class ImgFullPathModule {}
