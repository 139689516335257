<div class="error__container">
	<div class="error__cont">
		<div class="error__icon" id="paymentCalrifyUserErrorImage">
			<sp-lottie-loader [lottieImageUrl]="'https://assets-es-pprd.dxlpreprod.local.vodafone.es/assets/warning.json'" [loop]="false"> </sp-lottie-loader>
		</div>

		<div class="error__desc-cont">
			<p
				class="error__title"
				id="paymentCalrifyUserErrorTitleParagraph"
				[innerHTML]="
					'v10.payment.itemsList.anonymousPayment.es.anonymousClairfyCustomerAuthentication.title' | translate
				"
			></p>
			<p
				class="error__desc"
				id="paymentCalrifyUserErrorDescParagraph"
				[ngClass]="{ 'hide-break-point-tablet-desktop': breakpoint > BREAKPOINTS.MOBILE }"
			>
				{{ 'v10.payment.itemsList.anonymousPayment.es.anonymousClairfyCustomerAuthentication.subTitle' | translate }}
				<br />
				{{ 'v10.payment.itemsList.anonymousPayment.es.anonymousClairfyCustomerAuthentication.iBAN' | translate }}
			</p>
			<div class="error__desc mva10-u-color--primary" id="paymentCalrifyUserErrorCopyIbanDiv" (click)="onCopy()">
				<span class="error__descSpan" id="paymentCalrifyUserErrorCopyIbanSpan">
					{{
						'v10.payment.itemsList.anonymousPayment.es.anonymousClairfyCustomerAuthentication.iBANCopyButton.title'
							| translate
					}}
				</span>
				<mva10-c-icon
					iconId="icon-case-study"
					title="Copiar IBAN"
					size="24"
					[ngClass]="'mva10-u-color--primary'"
					id="paymentCalrifyUserErrorCopyIbanIcon"
				>
				</mva10-c-icon>
			</div>
		</div>
	</div>
	<div class="error__btns-cont">
		<div class="error__first-btn">
			<mva10-c-button
				id="paymentCalrifyUserErrorButton"
				(click)="firstBtnAction()"
				[body]="
					'v10.payment.itemsList.anonymousPayment.es.anonymousClairfyCustomerAuthentication.primaryButton.title'
						| translate
				"
				[styleClass]="'mva10-c-button--primary'"
			>
			</mva10-c-button>
		</div>
	</div>
</div>
