import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { checkInstallationAnalytic as analytic } from '../../constants/defines';
import { MyOrdersTaggingService } from '../../services/my-orders-tagging.service';
import { OrdersService } from '../my-orders/services/orders.service';

@Component({
	selector: 'sp-schaman-client-confirmation-layout',
	templateUrl: './schaman-client-confirmation-layout.component.html',
	styleUrls: ['./schaman-client-confirmation-layout.component.scss'],
})
export class SchamanClientConfirmationLayoutComponent implements OnInit {
	@Input()
	imageRoute: string;
	@Input()
	title: string;
	@Input()
	openFrom: string = analytic.ayudaAutoi;
	@Input()
	description: string;
	@Input()
	okButtonText: string;
	@Output()
	okButtonClick: EventEmitter<void> = new EventEmitter();
	@Output()
	koButtonClick: EventEmitter<void> = new EventEmitter();

	constructor(private tagging: MyOrdersTaggingService, private ordersService: OrdersService) {}

	ngOnInit(): void {
		if (!this.ordersService.detailOrderData.esOrdenNeba) {
			this.resetTagging();

			this.tagging.sendPage([this.openFrom, analytic.comprobacionHfc]);
		}
	}

	resetTagging(): void {
		this.tagging.extendUtagPageName(
			[
				analytic.detallePedido,
				analytic.ayudaAutoi,
				analytic.comprobacionHfc,
				analytic.comprobacionNeba,
				analytic.confirmacionHfc,
				analytic.confirmacionNeba,
				analytic.inicioComprobacionHfc,
				analytic.inicioComprobacionNeba,
				analytic.clickContinuar,
				analytic.clickAbrirChat,
				analytic.clickInstalacionCompletada,
				analytic.clickConfirmarInstalacionSinTv,
				analytic.ok,
				analytic.ko,
				analytic.guiaRouter,
				analytic.guiaRouterDeco,
				analytic.guiaDesconocida,
				analytic.guiaDecoGen3,
				analytic.guiaRouterDecoGen3,
				analytic.guiaDeco,
			],
			null,
			true
		);
	}
}
