import { AbstractControl, ValidationErrors, ValidatorFn } from '@angular/forms';

/**
 * Validates if the written email is not in the forbidden domains list
 *
 * @returns {ValidationErrors} {forbiddenDomain: { value: control.value }} if there's an error
 * @returns Null if the field is ok
 */
export function ibanValidator(): ValidatorFn {
	return (control: AbstractControl): ValidationErrors | null => {
		let iban: string = control.value.toUpperCase();
		// remove spaces from beginning and end of the string
		iban = iban.trim();
		// remove spaces from th string
		iban = iban.replace(/\s/g, '');
		let letra1, letra2, num1, num2: number;
		let isbanaux: string;
		// iban must  be 24 characters
		if (iban.length !== 24) {
			return { iban: { value: control.value } };
		}
		// convert first two letter to numbers
		letra1 = iban.substring(0, 1);
		letra2 = iban.substring(1, 2);
		num1 = convertLetterToNum(letra1);
		num2 = convertLetterToNum(letra2);
		isbanaux = String(num1) + String(num2) + iban.substring(2);
		isbanaux = isbanaux.substring(6) + isbanaux.substring(0, 6);
		const resto: string = modulo97(isbanaux);
		if (Number(resto) === 1) {
			return null;
		} else {
			return { iban: { value: control.value } };
		}
	};
}

function convertLetterToNum(letra: string): number {
	let ls_letras: string = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ';
	return ls_letras.search(letra) + 10;
}

function modulo97(iban: string): string {
	const parts: number = Math.ceil(iban.length / 7);
	let remainer: string = '';
	for (let i = 1; i <= parts; i++) {
		remainer = String(parseFloat(remainer + iban.substr((i - 1) * 7, 7)) % 97);
	}
	return remainer;
}
