import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { CoreModule, GenericModule, OverlayFullScreenModule, OverlayModule } from '@mva10/mva10-angular';
import { SharedModule } from '../shared/shared.module';
import { SmartPayFooterComponent } from './smart-pay-footer/smart-pay-footer.component';
import { SmartPayInfoCardComponent } from './smart-pay-info-card/smart-pay-info-card.component';
import { SmartPayManagementComponent } from './smart-pay-management/smart-pay-management.component';
import { SmartPayPaymentCardComponent } from './smart-pay-payment-card/smart-pay-payment-card.component';
import { SmartPayRoutingModule } from './smart-pay-routing.module';
import { SmartPayComponent } from './smart-pay.component';

@NgModule({
	declarations: [
		SmartPayComponent,
		SmartPayManagementComponent,
		SmartPayInfoCardComponent,
		SmartPayPaymentCardComponent,
		SmartPayFooterComponent,
	],

	imports: [
		CommonModule,
		CoreModule,
		GenericModule,
		SharedModule,
		OverlayFullScreenModule,
		SmartPayRoutingModule,
		OverlayModule,
	],
})
export class SmartPayModule {}
