import { Injectable } from '@angular/core';
import { tagging } from '../../../config/tagging-config';
import { StorageService } from '../../core/services/storage.service';
import { SubscriptionService } from '../../core/services/subscription.service';
import { TaggingHelperService } from '../../core/services/tagging.helper.service';
import { ServiceType } from '../../shared/enums/serviceType.enum';

@Injectable({
	providedIn: 'root',
})
export class SpecificTemplatesTaggingService {
	private localTagging: any;
	private newTaggingObject: any;
	private newTicket: any;
	private template: any;

	constructor(
		private subscriptionService: SubscriptionService,
		private storageService: StorageService,
		private tagging: TaggingHelperService
	) {}

	startTaggingConfig(state: string, type: string, newTicket: any, template: any): void {
		this.newTaggingObject = { ...tagging.faultTagging };
		this.newTicket = { ...newTicket };
		this.template = { ...template };
		this.taggingInitialState(state, type);
	}

	taggingInitialState(state: string, type: string): void {
		this.isPrePaid();
		this.isAnalytics();
		this.setProfileType();
		this.newTaggingObject.journey_process = `${this.newTicket.serviceType}`;
		this.setTechnology();
		this.newTaggingObject.journey_fault_backend = this.template.backend || 'undefined';
		this.setDeco();
		this.callStateOrTrackTagging(type, state);
	}

	private isPrePaid(): void {
		this.newTaggingObject.journey_subcategory =
			this.subscriptionService.customerData.currentService.type &&
			this.subscriptionService.customerData.currentService.type.toLowerCase() === ServiceType.Prepaid.toLowerCase()
				? tagging.faultVariables.prepaid
				: tagging.faultVariables.pospaid;
	}

	private isAnalytics(): void {
		if (this.template.form && this.template.form.analytics) {
			this.newTaggingObject.page_code = this.template.form.analytics;
		}
	}

	private setProfileType(): void {
		this.newTaggingObject.journey_category =
			this.storageService.userProfile.profileType === tagging.faultVariables.authorized
				? tagging.faultVariables.business
				: tagging.faultVariables.individuals;
	}

	private setTechnology(): void {
		this.newTaggingObject.journey_fiber_technology = this.template.technology || 'undefined';
	}

	private setDeco(): void {
		this.newTaggingObject.journey_decoder_model = this.template.deco || 'undefined';
	}

	private callStateOrTrackTagging(type: string, state: string): void {
		type === 'state' ? this.configStateNewTagging(state) : this.configTrackNewTagging(state);
	}

	configTrackNewTagging(state: string): void {
		const simptomResult: string = this.setSympton(this.template);
		switch (state) {
			case 'yes':
				this.newTaggingObject[
					'page_name'
				] = `${tagging.faultVariables.solvedFault}${this.newTicket.serviceType}:cancelar`;
				this.newTaggingObject['event_context'] = tagging.faultVariables.cancelOverlay;
				this.newTaggingObject['event_name'] = tagging.faultVariables.yesSolved;
				this.newTaggingObject['event_label'] = tagging.faultVariables.yesSolved;
				this.newTaggingObject['event_category'] = tagging.faultVariables.button;
				this.newTaggingObject.journey_detail = tagging.faultVariables.solved;
				this.newTaggingObject['&&events'] = `${tagging.faultVariables.event28},${tagging.faultVariables.event113}`;
				break;
			case 'no':
				this.newTaggingObject[
					'page_name'
				] = `${tagging.faultVariables.solvedFault}${this.newTicket.serviceType}:cancelar`;
				this.newTaggingObject['event_context'] = tagging.faultVariables.cancelOverlay;
				this.newTaggingObject['event_name'] = tagging.faultVariables.notSolved;
				this.newTaggingObject['event_label'] = tagging.faultVariables.notSolved;
				this.newTaggingObject['event_category'] = tagging.faultVariables.button;
				this.newTaggingObject.journey_detail = tagging.faultVariables.nSolved;
				this.newTaggingObject['&&events'] = tagging.faultVariables.event114;
				break;
			case 'chat':
				this.newTaggingObject[
					'page_name'
				] = `${tagging.faultVariables.solvedFault}${this.newTicket.serviceType}:pasos intermedios`;
				this.newTaggingObject['event_context'] = tagging.faultVariables.chat;
				this.newTaggingObject['event_name'] = tagging.faultVariables.openChat;
				this.newTaggingObject['event_category'] = tagging.faultVariables.button;
				this.newTaggingObject.journey_detail = tagging.faultVariables.steps;
				this.newTaggingObject['journey_fault_options'] = simptomResult;
				break;
			case 'call':
				this.newTaggingObject[
					'page_name'
				] = `${tagging.faultVariables.solvedFault}${this.newTicket.serviceType}:pasos intermedios`;
				this.newTaggingObject['event_context'] = tagging.faultVariables.chat;
				this.newTaggingObject['event_name'] = tagging.faultVariables.call;
				this.newTaggingObject['event_category'] = tagging.faultVariables.button;
				this.newTaggingObject.journey_detail = tagging.faultVariables.steps;
				this.newTaggingObject['journey_fault_options'] = simptomResult;
				break;
			case 'chatTicket':
				this.newTaggingObject[
					'page_name'
				] = `${tagging.faultVariables.solvedFault}${this.newTicket.serviceType}:pasos intermedios`;
				this.newTaggingObject['event_context'] = tagging.faultVariables.chat;
				this.newTaggingObject['event_name'] = tagging.faultVariables.created;
				this.newTaggingObject['event_category'] = tagging.faultVariables.event;
				this.newTaggingObject['event_label'] = tagging.faultVariables.created;
				this.newTaggingObject.journey_detail = tagging.faultVariables.steps;
				this.newTaggingObject['journey_fault_options'] = simptomResult;
				this.newTaggingObject['&&events'] = tagging.faultVariables.event28;
				break;
			case 'backButton':
				this.newTaggingObject[
					'page_name'
				] = `${tagging.faultVariables.solvedFault}${this.newTicket.serviceType}:pasos intermedios`;
				this.newTaggingObject['event_context'] = tagging.faultVariables.goBack;
				this.newTaggingObject['event_name'] = tagging.faultVariables.clickBackButton;
				this.newTaggingObject['event_category'] = tagging.faultVariables.button;
				this.newTaggingObject['event_label'] = tagging.faultVariables.goBack;
				this.newTaggingObject.journey_detail = tagging.faultVariables.steps;
				this.newTaggingObject['journey_fault_options'] = simptomResult;
				break;
		}
		this.newTaggingObject.journey_step = `
            ${this.newTaggingObject.journey_name}:${this.newTaggingObject.journey_detail}
          `;
		this.tagging.track(this.newTaggingObject['event_name'], this.newTaggingObject);
	}

	configStateNewTagging(state: string): void {
		const simptomResult: string = this.setSympton(this.template);
		this.resetLocalTagging();
		this.localTagging.page_subcategory_level_1 = 'solucionar averia';
		this.localTagging.page_subcategory_level_2 = this.newTicket.serviceType;
		switch (state) {
			case 'diagnostic':
				this.localTagging.page_name = `${tagging.faultVariables.solvedFault}${this.newTicket.serviceType}:diagnostico`;
				this.newTaggingObject.journey_detail = tagging.faultVariables.start;
				this.newTaggingObject['&&events'] = tagging.faultVariables.event27;
				this.localTagging.page_subcategory_level_3 = 'diagnostico';
				this.localTagging.page_screen = 'diagnostico';
				break;
			case 'steps':
				this.localTagging.page_name = `${tagging.faultVariables.solvedFault}${this.newTicket.serviceType}:pasos intermedios`;
				this.newTaggingObject.journey_detail = tagging.faultVariables.steps;
				this.newTaggingObject['journey_fault_options'] = simptomResult;
				this.localTagging.page_subcategory_level_3 = 'pasos intermedios';
				this.localTagging.page_screen = 'pasos intermedios';
				break;
			case 'solved':
				this.localTagging.page_name = `${tagging.faultVariables.solvedFault}${this.newTicket.serviceType}:solucionada`;
				this.newTaggingObject.journey_detail = tagging.faultVariables.solved;
				this.newTaggingObject['&&events'] = tagging.faultVariables.event28;
				this.newTaggingObject['journey_fault_options'] = simptomResult;
				this.localTagging.page_subcategory_level_3 = 'solucionada';
				this.localTagging.page_screen = 'solucionada';
				break;
			case 'ticket':
				this.localTagging.page_name = `${tagging.faultVariables.solvedFault}${this.newTicket.serviceType}:ticket creado`;
				this.newTaggingObject.journey_detail = tagging.faultVariables.created;
				this.newTaggingObject['&&events'] = tagging.faultVariables.event28;
				this.newTaggingObject['journey_fault_options'] = simptomResult;
				this.localTagging.page_subcategory_level_3 = 'ticket creado';
				this.localTagging.page_screen = 'ticket creado';
				break;
			case 'exit':
				this.localTagging.page_name = `${tagging.faultVariables.solvedFault}${this.newTicket.serviceType}:cancelar`;
				this.newTaggingObject.journey_detail = tagging.faultVariables.cancel;
				this.newTaggingObject['journey_fault_options'] = simptomResult;
				this.localTagging.page_subcategory_level_3 = 'cancelar';
				this.localTagging.page_screen = 'cancelar';
				break;
			case 'error':
				this.localTagging.page_name = `${tagging.faultVariables.solvedFault}${this.newTicket.serviceType}:ko`;
				this.newTaggingObject.journey_detail = tagging.faultVariables.ko;
				this.newTaggingObject['error_list'] = this.setErrorList(this.template);
				this.newTaggingObject['&&events'] = tagging.faultVariables.event103;
				this.newTaggingObject['journey_fault_options'] = simptomResult;
				this.localTagging.page_subcategory_level_3 = 'ko';
				this.localTagging.page_screen = 'ko';
				break;
		}
		this.newTaggingObject.journey_step = `
            ${this.newTaggingObject.journey_name}:${this.newTaggingObject.journey_detail}
          `;
		this.tagging.view(this.localTagging, this.newTaggingObject);
	}

	resetLocalTagging(): void {
		this.localTagging = { ...tagging.openNewTicketTemplate };
	}

	setErrorList(template: any): string {
		let errorList: string;
		const ET = 'et=tecnico';
		const EC = `ec=${template['error'].ecode || 'undefined'}`;
		const EM = 'em=averias';
		const ED = `ed=${template['error'].description || 'undefined'}`;
		errorList = `${ET}|${EC}|${EM}|${ED}`;
		return errorList;
	}
	setSympton(template: any): string {
		const sympton: string = template.symptom || 'undefined';
		return sympton;
	}
}
