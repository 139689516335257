<!-- Edit bank account Overlay -->
<vfac-overlay
	[overlayData]="overlayData"
	[show]="showEditOverlay"
	(closed)="closeOverlay()"
	[loaded]="true"
	[greyMode]="false"
	[page]="pageOverlay"
	class="editBankAccount"
>
	<!-- Bank Account Data Page -->
	<div body id="page1">
		<div class="edit-content">
			<div class="my-account-details-overlay-subtitle" id="editBankAccountTitle">
				{{ 'v10.myAccount.bankAccount.addEditBankAccount.pageTitle' | translate }}
			</div>

			<form [formGroup]="editBankForm" name="editDataForm">
				<div class="form-field-wrapper" id="editBankAccountFormIban">
					<mva10-c-field-wrapper
						[state]="
							editBankForm?.controls['iban']?.touched
								? !editBankForm.controls['iban']?.errors
									? 'success'
									: 'error'
								: null
						"
						[label]="formData?.iban?.label"
						[stateText]="
							editBankForm?.controls['iban']?.touched
								? !editBankForm.controls['iban']?.errors
									? formData?.iban.successText
									: formData?.iban.invalidErrorText
								: null
						"
					>
						<mva10-c-text-field
							formControlName="iban"
							[state]="
								editBankForm?.controls['iban']?.touched
									? !editBankForm.controls['iban']?.errors
										? 'success'
										: 'error'
									: null
							"
							[error]="editBankForm?.controls['iban']?.errors && editBankForm?.controls['iban']?.touched"
							(valueChange)="formatIBAN($event)"
						></mva10-c-text-field>
					</mva10-c-field-wrapper>
				</div>

				<!-- Añadir check mismo titular -->

				<div class="form-field-wrapper" id="editBankAccountFormFirstName">
					<mva10-c-field-wrapper
						[label]="formData.name.label"
						[state]="editBankForm?.controls['name']?.touched && editBankForm.controls['name'].errors ? 'error' : null"
						[stateText]="
							editBankForm?.controls['name']?.touched && editBankForm?.controls['name']?.errors?.required
								? formData?.name.emptyErrorText
								: editBankForm?.controls['name']?.touched && editBankForm?.controls['name']?.errors?.pattern
								? formData?.name.invalidErrorText
								: null
						"
					>
						<mva10-c-text-field
							formControlName="name"
							[error]="editBankForm?.controls['name']?.errors && editBankForm?.controls['name']?.touched"
						></mva10-c-text-field>
					</mva10-c-field-wrapper>
				</div>
				<div class="form-field-wrapper" id="editBankAccountFormMiddleName">
					<mva10-c-field-wrapper
						[label]="formData?.surname?.label"
						[state]="
							editBankForm?.controls['surname']?.touched && editBankForm?.controls['surname']?.errors ? 'error' : null
						"
						[stateText]="
							editBankForm?.controls['surname']?.touched && editBankForm?.controls['surname']?.errors?.required
								? formData?.surname.emptyErrorText
								: editBankForm?.controls['surname']?.touched && editBankForm?.controls['surname']?.errors?.pattern
								? formData?.surname.invalidErrorText
								: null
						"
					>
						<mva10-c-text-field
							formControlName="surname"
							[error]="editBankForm?.controls['surname']?.errors && editBankForm?.controls['surname']?.touched"
						></mva10-c-text-field>
					</mva10-c-field-wrapper>
				</div>

				<div class="form-field-wrapper" id="editBankAccountFormLastName">
					<mva10-c-field-wrapper
						[label]="formData?.secondSurname?.label"
						[state]="
							editBankForm?.controls['secondSurname']?.touched && editBankForm?.controls['secondSurname']?.errors
								? 'error'
								: null
						"
						[stateText]="
							editBankForm?.controls['secondSurname']?.touched && editBankForm?.controls['secondSurname']?.errors
								? formData?.secondSurname.invalidErrorText
								: null
						"
						[necessity]="'v10.myAccount.bankAccount.addEditBankAccount.secondSurnameTitleOptional' | translate"
					>
						<mva10-c-text-field
							formControlName="secondSurname"
							[error]="
								editBankForm?.controls['secondSurname']?.errors && editBankForm?.controls['secondSurname']?.touched
							"
						></mva10-c-text-field>
					</mva10-c-field-wrapper>
				</div>

				<div class="bank-account-form__document-block">
					<mva10-c-field-wrapper
						[label]="formData?.documentType?.label"
						class="bank-account-form__document-type"
						id="editBankAccountFormDocumentType"
					>
						<mva10-c-select-field
							formControlName="documentType"
							[listItems]="documentOptions"
							readOnlyText="true"
							(changeSelect)="onDocumentTypeChange()"
						>
						</mva10-c-select-field>
					</mva10-c-field-wrapper>

					<mva10-c-field-wrapper
						id="editBankAccountFormDocument"
						[label]="formData?.document?.label"
						[state]="
							editBankForm?.controls['document']?.touched && editBankForm?.controls['document']?.errors ? 'error' : null
						"
						[stateText]="
							editBankForm?.controls['document']?.touched && editBankForm?.controls['document']?.errors?.required
								? formData?.document.emptyErrorText
								: editBankForm?.controls['document']?.touched && editBankForm?.controls['document']?.errors?.pattern
								? formData?.document.invalidErrorText
								: null
						"
					>
						<mva10-c-text-field
							formControlName="document"
							[error]="editBankForm?.controls['document']?.errors && editBankForm?.controls['document']?.touched"
						></mva10-c-text-field>
					</mva10-c-field-wrapper>
				</div>
			</form>
		</div>
		<div class="btn-container">
			<!-- using checkFormValidity method here to avoid infinite recursion in ts code -->
			<mva10-c-button
				appearance="'primary'"
				class="overlay-button"
				[disabled]="!editBankForm?.valid"
				(click)="saveEditBankAccount()"
				id="editBankAccountFormSaveButton"
			>
				{{ 'v10.myAccount.bankAccount.addEditBankAccount.saveButtonTitle' | translate }}
			</mva10-c-button>
		</div>
	</div>

	<div body id="page2">
		<ng-container *ngIf="successUpdate" id="editBankAccountSuccessScreen">
			<div body>
				<div class="edit-ok-screen">
					<div class="edit-ok-content">
						<div class="my-account-details-modal-img">
							<img
								id="editBankAccountSuccessImage"
								class="my-account-details-modal-img-file"
								alt="img-tick-red"
								[src]="'v10.common.images.icon.tickRed' | imgFullPath"
							/>
						</div>
						<p class="my-account-details-modal-title" id="editBankAccountSuccessTitle">
							{{ okPaymentStatusTitle }}
						</p>
						<p class="my-account-details-modal-subtitle" id="editBankAccountSuccessMessage">
							{{ okPaymentStatusMessage }}
						</p>
					</div>
					<div class="edit-ok-button">
						<button
							type="button"
							class="button red overlay-button"
							(click)="okayButtonClick()"
							id="editBankAccountSuccessButton"
						>
							{{ 'v10.myAccount.bankAccout.otp.OKConfirmationOverlay.cta' | translate }}
						</button>
					</div>
				</div>
			</div>
		</ng-container>
		<ng-container *ngIf="!successUpdate" id="editBankAccountFailure">
			<div class="edit-ko-screen">
				<div class="edit-ko-content">
					<div class="my-account-details-modal-img">
						<img
							id="editBankAccountFailureImage"
							class="my-account-details-modal-img-file"
							alt="img-warning-red"
							[src]="'v10.common.images.icon.warningRed' | imgFullPath"
						/>
					</div>
					<p class="my-account-details-modal-title" id="editBankAccountFailureTitle">
						{{ 'v10.myAccount.bankAccout.otp.KOConfirmationOverlay.title' | translate }}
					</p>
					<p class="edit-ko-subtitle-first" id="editBankAccountFailureSubtitle1">
						{{ 'v10.myAccount.bankAccout.otp.KOConfirmationOverlay.subTitle1' | translate }}
					</p>
					<p class="edit-ko-subtitle-second" id="editBankAccountFailureSubtitle2">
						{{ 'v10.myAccount.bankAccout.otp.KOConfirmationOverlay.subTitle2' | translate }}
					</p>
				</div>
				<div class="edit-ko-button">
					<button
						type="button"
						class="button red overlay-button"
						(click)="closeOverlay()"
						id="editBankAccountFailureButton"
					>
						{{ 'v10.myAccount.bankAccout.otp.KOConfirmationOverlay.cta' | translate }}
					</button>
				</div>
			</div>
		</ng-container>
	</div>
</vfac-overlay>
