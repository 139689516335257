import {
	AfterViewChecked,
	ChangeDetectorRef,
	Component,
	ComponentFactoryResolver,
	OnInit,
	ViewChild,
	ViewContainerRef,
} from '@angular/core';
import { DynamicTrayService } from '../../services/dynamic-tray.service';

@Component({
	selector: 'sp-dynamic-tray',
	templateUrl: './dynamic-tray.component.html',
	styleUrls: ['./dynamic-tray.component.scss'],
})
export class DynamicTrayComponent implements OnInit, AfterViewChecked {
	/** component: this is the property that will deal with the dynamic component */
	component: any;
	/** showTray: internal property that shows and hide the tray */
	showTray = false;
	/** isLoadContent: to prevent recreation for dynamic component */
	isLoadContent = false;
	/** the reference of recieved components */
	@ViewChild('dynamicComponentRef', { read: ViewContainerRef, static: true }) dynamicComponentRef: ViewContainerRef;

	constructor(
		public dynamicTrayService: DynamicTrayService,
		private resolver: ComponentFactoryResolver,
		private cd: ChangeDetectorRef
	) {}

	ngOnInit() {
		this.dynamicTrayService.closeTraySubject.subscribe((keepState) => {
			if (!keepState) {
				this.component = null;
			}
			setTimeout(() => (this.dynamicTrayService.trayData = {}), 400);
			this.showTray = false;
		});
		this.dynamicTrayService.componentRefClearSubject.subscribe(() => {
			if (this.dynamicComponentRef) {
				this.dynamicComponentRef.clear();
			}
		});
	}

	/** This method for dynamic component */
	openTray(component) {
		if (this.component !== component) {
			this.component = component;
			this.isLoadContent = true;
		}
		this.showTray = true;
	}

	ngAfterViewChecked(): void {
		if (this.isLoadContent) {
			this.isLoadContent = false;
			const compFactory = this.resolver.resolveComponentFactory(this.component);
			this.dynamicComponentRef.clear();
			this.dynamicComponentRef.createComponent(compFactory);
			this.cd.detectChanges();
		}
	}

	closeOverlay() {
		if (!this.checkDisableClosing()) {
			if (this.dynamicTrayService.trayData && this.dynamicTrayService.trayData.closeButtonAction) {
				this.dynamicTrayService.trayData.closeButtonAction();
			} else {
				this.dynamicTrayService.closeTraySubject.next();
				if (this.dynamicComponentRef) {
					this.dynamicComponentRef.clear();
				}
			}
		}
	}
	backOverlay() {
		if (this.dynamicTrayService.trayData && this.dynamicTrayService.trayData.backButtonAction) {
			this.dynamicTrayService.trayData.backButtonAction();
		}
		this.dynamicTrayService.backButtonTraySubject.next();
	}
	primaryButtonClicked() {
		if (this.dynamicTrayService.trayData && this.dynamicTrayService.trayData.primaryButtonAction) {
			this.dynamicTrayService.trayData.primaryButtonAction();
		}
	}
	secondaryButtonClicked() {
		if (this.dynamicTrayService.trayData && this.dynamicTrayService.trayData.secondaryButtonAction) {
			this.dynamicTrayService.trayData.secondaryButtonAction();
		}
	}
	/**
	 * this method is for checking the flag of disabling closing tray or not
	 */
	checkDisableClosing() {
		return (
			this.dynamicTrayService.trayData &&
			this.dynamicTrayService.trayData.basicTrayData &&
			this.dynamicTrayService.trayData.basicTrayData.disableClosing
		);
	}
}
