import { ReviewBillingJourney } from './configuration.model';
import { ProductConfiguration } from './product-configuration.model';

export interface SettingsResponse {
	links?: {
		home?: {
			href?: string;
		};
		self?: {
			href?: string;
		};
	};
	collection?: string;
	secuencia?: string;
	productConfiguration?: ProductConfiguration[];
	appRateRecallDuration?: string;
	otpConfiguration?: OTPConfiguration;
	_links?: {
		self?: {
			href?: string;
			templated?: boolean;
		};
		home?: {
			href?: string;
		};
	};
	networkLogin?: boolean;
	otpLength?: string;
	connectionType?: string;
	forceUpdate?: string;
	appAvailability?: boolean;
	enterprisesLoginAvailability?: boolean;
	paymentModuleAvailability?: boolean;
	rsmAvailabilityForBillReview?: boolean;
	genevaFlag?: boolean;
	ocsFlag?: boolean;
	clarifyFlag?: boolean;
	billingFlag?: boolean;
	biztalkAvailabilityFlag?: boolean;
	biztalkReplicaFlag?: boolean;
	showBillsLink?: boolean;
	chatEnabled?: boolean;
	betaVersionBlocked?: boolean;
	versionsBlocked?: string;
	gdprConfigurations?: GDBRConfigurations[];
	bonitaConfiguration?: BonitaConfiguration[];
	latestVersion?: {
		ios?: string;
		android?: string;
	};
	pciTimer?: string;
	disableWs59?: boolean;
	disableMiwifi?: boolean;
	topupNewPCI?: boolean;
	activateSecurityCheck?: boolean;
	topupNewPCIMsisdnPattern?: string;
	smartPayMSIDNPattern?: string;
	confirmedPaymentMaxDuration?: number;
	secureNetAlertNumberOfDays?: number;
	pciTopupRefundSecurityType?: string;
	disableOcs55Prepaid?: boolean;
	androidAppVersionPCI?: string;
	iosAppVersionPCI?: string;
	webNewImplFlagPCI?: boolean;
	oldPCIFlag?: boolean;
	showBillingEta?: boolean;
	hideBillConfigurationToggles?: boolean;
	globalTimeout?: string;
	topupBackAvailable?: boolean;
	smsRefundContent?: string;
	tobiChatUnAvailability?: boolean;
	tobiUserConfiguration?: TobiUserConfiguration[];
	paymentUnavailability?: boolean;
	transactionalJourneysAvailable?: boolean;
	showEverythingIsOk?: boolean;
	showHappy?: boolean;
	newPrepagoLinesPurchaseDefaultJourney?: string;
	transactionalConfig?: TransactionalConfig;
	pegaAvailable?: boolean;
	showOneNumberSSO?: boolean;
	usernameWalletFilter?: boolean;
	newAnonymousJourney?: boolean;
	storiesPosition?: number;
	showEverythingIsDeudaOk?: boolean;
	showSuperWifi?: boolean;
	showSecureNetSSO?: boolean;
	reviewBillingJourney?: ReviewBillingJourney;
	showStoreReviews?: boolean;
	enabledLoginRememberNotification?: boolean;
	enabledTerminalRememberNotification?: boolean;
	enabledManualLogin?: boolean;
	availabilityOfRMCA?: boolean;
	enabledGenericShopNotification?: boolean;
	enabledBuyTVPackNotification?: boolean;
	enabledAdditionalLinesNotification?: boolean;
	showMovementsHistoryNewScreen?: boolean;
	tvVodafoneAvailability?: boolean;
}

export interface OTPConfiguration {
	otpEnabled?: boolean;
	minAgentVersion?: {
		android?: string;
		ios?: string;
	};
	webOtpEnabled?: boolean;
	skipOTPPendingDigitalSignature?: boolean;
	otpGAE1Enabled?: boolean;
	otpEmployeesEnabled?: boolean;
	dracarysConsumerOtpConf?: {
		originSystem?: string;
		originClient?: string;
		content?: string;
	};
}

export interface GDBRConfigurations {
	userType?: string;
	startDate?: string;
	endDate?: string;
	frequency?: string;
}

export interface BonitaConfiguration {
	id?: string;
	collection?: string;
	secuencia?: string;
	type?: string;
	name?: string;
	version?: string;
}

export class TobiUserConfiguration {
	customerType?: string;
	profileType?: string;
}

export interface TransactionalConfig {
	transactionalJourneysAvailable?: boolean;
	flowAvailability?: Array<{
		code?: string;
		description?: string;
		value?: boolean;
	}>;
}
