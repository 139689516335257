<div class="smartpay-container">
	<div [ngStyle]="{ height: pageHeight }">
		<div #cardsContainer id="smartpayCardsContainer" class="smartpay-cards-container">
			<sp-smart-pay-info-card [isSmartPay]="isSmartPay" [isDay28]="isDay28" [msisdn]="msisdn" [tariffCode]="tariffCode">
			</sp-smart-pay-info-card>
			<sp-smart-pay-payment-card [isSmartPay]="isSmartPay" [msisdn]="msisdn" [isTariffActive]="isTariffActive">
			</sp-smart-pay-payment-card>
		</div>
		<div #footerContainer>
			<sp-smart-pay-footer [isSmartPay]="isSmartPay" [isDay28]="isDay28" [msisdn]="msisdn"> </sp-smart-pay-footer>
		</div>
	</div>
	<!-- Benefits of keeping smartpay overlay -->
	<vfac-overlay-full-screen
		class="smartpay-overlay"
		[overlayFullScreenData]="smartPayService.overlayData"
		[show]="smartPayService.showOverlay"
		(primaryBtnClicked)="closeOverlayButton()"
		id="smartpayOverlay"
		(closeBtnClicked)="closeOverlayButtonX()"
		(secundaryBtnClicked)="setConfirmationDeactivationOverlay()"
	>
		<img
			id="productsAndServicesSmartPayDeactivHiglightImg"
			[src]="'v10.productsServices.smartPay.PyS.benefits.deactivation.benefits_reminder.overlay_icon' | imgFullPath"
			class="overlay-image"
		/>
		<p id="productsAndServicesSmartPayDeactivHiglightTitle" class="overlay-title-highlight-benefit">
			{{ 'v10.productsServices.smartPay.PyS.benefits.deactivation.benefits_reminder.title' | translate }}
		</p>
		<div body>
			<div *ngFor="let item of highlightBenefitsWCS; let i = index" class="highlight-benefits">
				<mva10-c-icon
					[id]="'productsAndServicesSmartPayDeactivHiglightIcon' + i"
					[iconId]="highlightBenefitsIcons[i]"
					size="32"
				>
				</mva10-c-icon>
				<p
					[id]="'productsAndServicesSmartPayDeactivHiglightdesc' + i"
					class="highlight-benefits__desc"
					[innerHTML]="highlightBenefitsWCS[i]"
				></p>
			</div>
		</div>
	</vfac-overlay-full-screen>

	<!-- Maintain smartpay overlay-->
	<vfac-overlay-full-screen
		class="smartpay-overlay maintain-benefit"
		[overlayFullScreenData]="maintainBenefitOverlay"
		[show]="smartPayService.showMaintainSmartPayOverlay"
		(primaryBtnClicked)="closeMaintainBenefitOverlay()"
		id="smartpayOverlay"
		(closeBtnClicked)="closeOverlayButtonX()"
	>
		<img
			id="productsAndServicesSmartPayDeactivMaintainImg"
			[src]="'v10.productsServices.smartPay.PyS.benefits.deactivation.smartpay_kept_overlay.overlay_icon' | imgFullPath"
			class="overlay-image"
		/>
		<p id="productsAndServicesSmartPayDeactivMaintainTitle" class="overlay-title">
			{{ 'v10.productsServices.smartPay.PyS.benefits.deactivation.smartpay_kept_overlay.title' | translate }}
		</p>
		<div body>
			<p
				id="productsAndServicesSmartPayDeactivMaintainDesc"
				class="confirm-deactivation__desc"
				[innerHTML]="
					'v10.productsServices.smartPay.PyS.benefits.deactivation.smartpay_kept_overlay.message' | translate
				"
			></p>
		</div>
	</vfac-overlay-full-screen>

	<!-- confirm deactivation overlays if user in day 28 or not-->
	<vfac-overlay-full-screen
		class="smartpay-overlay confirm-deactivation"
		[overlayFullScreenData]="confirmDeactivationOverlay"
		[show]="smartPayService.showConfirmDeactivationOverlay"
		(primaryBtnClicked)="dismissBenefitDeactivation()"
		id="smartpayOverlay"
		(closeBtnClicked)="closeOverlayButtonX()"
		(secundaryBtnClicked)="deactivateSmartPay()"
	>
		<img
			id="productsAndServicesSmartPayDeactivConfirmImg"
			[src]="
				'v10.productsServices.smartPay.PyS.benefits.deactivation.day_minus_one_user.confirmation.overlay_icon'
					| imgFullPath
			"
			class="overlay-image"
		/>
		<p id="productsAndServicesSmartPayDeactivConfirmTitle" *ngIf="!isDay28" class="overlay-title-confirm">
			{{ 'v10.productsServices.smartPay.PyS.benefits.deactivation.regular_user.confirmation.title' | translate }}
		</p>
		<div *ngIf="!isDay28" body>
			<p
				id="productsAndServicesSmartPayDeactivConfirmDesc"
				class="confirm-deactivation__desc"
				[innerHTML]="
					'v10.productsServices.smartPay.PyS.benefits.deactivation.regular_user.confirmation.message' | translate
				"
			></p>
		</div>
	</vfac-overlay-full-screen>

	<!-- Success or error for activation or deactivation of smartpay-->
	<vfac-overlay-full-screen
		class="smartpay-overlay maintain-benefit"
		[overlayFullScreenData]="smartPayService.deactivationSuccOrErrOverlay"
		[show]="smartPayService.showSuccessOrErrOverlay"
		(primaryBtnClicked)="closeOverlayButtonX()"
		id="smartpayOverlay"
		(closeBtnClicked)="closeOverlayButtonX()"
	>
		<img
			id="productsAndServicesSmartPayDeactivSuccErrImg"
			[src]="smartPayService.overlayIcon | imgFullPath"
			class="overlay-image"
		/>
	</vfac-overlay-full-screen>

	<div class="full-loader" *ngIf="smartPayService.showFullLoader">
		<sp-lottie-loader
			[lottieImageUrl]="'https://assets-es-pprd.dxlpreprod.local.vodafone.es/assets/loader.json'"
			id="smartpayLoader"
			[loadingText]="smartPayService.loadingScreenText"
		>
		</sp-lottie-loader>
	</div>
</div>
