<div *ngIf="showLoading" class="spinner spinner-out-container-sva">
	<div class="content-wrapper row spinner-container">
		<div class="spinnerContainer col-xs-12">
			<svg class="spinner" width="65px" height="65px" viewBox="0 0 66 66" xmlns="http://www.w3.org/2000/svg">
				<circle class="path" fill="none" stroke-width="6" stroke-linecap="round" cx="33" cy="33" r="30"></circle>
			</svg>
		</div>
		<span class="loading-text col-xs-8 col-xs-push-2">
			{{ 'common.messagesList.browserAndPdfLoading.browserAndPdfLoading_description' | translate }}
		</span>
	</div>
</div>

<div *ngIf="!showLoading" class="asesor-digital" id="mainElements">
	<div class="content-tex">
		<p>{{ 'v10.dashboard.overlay_assessor.description_1' | translate }}</p>
		<p>{{ 'v10.dashboard.overlay_assessor.description_2' | translate }}</p>
	</div>
	<hr class="hr" />
	<!-- phoneCall -->
	<div class="navigate" *ngIf="isTlfActive" [ngStyle]="{ order: asessorOrder.lnk_tlf }">
		<a
			href="tel:{{ 'v10.dashboard.overlay_assessor.lnk_tlf' | translate }}"
			(click)="trackAction(taggingVariabels.btnCall)"
		>
			<div class="icon icon-call active"></div>
			<span class="contentItem"> {{ 'v10.dashboard.overlay_assessor.lnk_tlf' | translate }} </span>
			<i class="icon icon-chevron-right"> </i>
		</a>
		<hr class="hr" />
	</div>
	<!-- Chat -->
	<!-- Chat out of hours -->
	<div
		class="navigate"
		*ngIf="
			segmentActiveChat &&
			isXhrOk &&
			isChatActive &&
			!isInTime &&
			(!grifosActivados.chat || (grifosActivados.chat && userInGrifo.chat))
		"
		[ngStyle]="{ order: asessorOrder.lnk_chatonline }"
	>
		<a>
			<div class="icon icon-chat active"></div>
			<span class="contentItem"> {{ 'v10.dashboard.overlay_assessor.lnk_chatonline' | translate }} </span>
			<span class="disponibility"> {{ 'v10.dashboard.overlay_assessor.available' | translate }} </span>
		</a>
		<hr class="hr" />
	</div>
	<!-- Chat fine -->
	<div
		class="navigate"
		*ngIf="
			segmentActiveChat &&
			isXhrOk &&
			isChatActive &&
			isInTime &&
			(!grifosActivados.chat || (grifosActivados.chat && userInGrifo.chat))
		"
		(click)="sendMessage('chat'); trackAction(taggingVariabels.btnChat)"
		[ngStyle]="{ order: asessorOrder.lnk_chatonline }"
	>
		<a>
			<div class="icon icon-chat active"></div>
			<span class="contentItem"> {{ 'v10.dashboard.overlay_assessor.lnk_chatonline' | translate }} </span>
			<i class="icon icon-chevron-right"> </i>
		</a>
		<hr class="hr" />
	</div>
	<!-- Chat not avalible for error in xhr -->
	<div
		class="navigate"
		*ngIf="
			segmentActiveChat &&
			!isXhrOk &&
			isChatActive &&
			isInTime &&
			(!grifosActivados.chat || (grifosActivados.chat && userInGrifo.chat))
		"
		[ngStyle]="{ order: asessorOrder.lnk_chatonline }"
	>
		<a>
			<div class="icon icon-chat active"></div>
			<span class="contentItem"> {{ 'v10.dashboard.overlay_assessor.lnk_chatonline' | translate }} </span>
			<span class="disponibility"> {{ 'v10.dashboard.overlay_assessor.not_available' | translate }} </span>
		</a>
		<hr class="hr" />
	</div>
	<!-- whatsapp -->
	<div
		class="navigate"
		*ngIf="isWhatsappActive && (!grifosActivados.whatsapp || (grifosActivados.whatsapp && userInGrifo.whatsapp))"
		[ngStyle]="{ order: asessorOrder.lnk_whatsapp }"
	>
		<a
			href="https://api.whatsapp.com/send?phone={{ 'v10.dashboard.overlay_assessor.whatsapp_tlf' | translate }}&text={{
				'v10.dashboard.overlay_assessor.whatsapp_text' | translate
			}}"
			(click)="trackAction(taggingVariabels.btnWhatsapp)"
		>
			<img src="https://assets-es-pprd.dxlpreprod.local.vodafone.es/assets/icons-svg/icon-whatsapp.svg" class="icon icon-whatsapp active" />
			<span class="contentItem"> {{ 'v10.dashboard.overlay_assessor.lnk_whatsapp' | translate }} </span>
			<i class="icon icon-chevron-right"> </i>
		</a>
		<hr class="hr" />
	</div>
	<!-- Email -->
	<div
		class="navigate"
		*ngIf="isEmailActive && (!grifosActivados.email || (grifosActivados.email && userInGrifo.email))"
		[ngStyle]="{ order: asessorOrder.lnk_email }"
	>
		<a
			href="mailto:{{ 'v10.dashboard.overlay_assessor.email' | translate }}"
			(click)="trackAction(taggingVariabels.btnEmail)"
		>
			<div class="icon icon-mail-new active"></div>
			<span class="contentItem"> {{ 'v10.dashboard.overlay_assessor.lnk_email' | translate }} </span>
			<i class="icon icon-chevron-right"> </i>
		</a>
		<hr class="hr" />
	</div>
	<!-- Form -->
	<div
		class="navigate"
		*ngIf="isFormActive"
		(click)="sendMessage('contactUs'); trackAction(taggingVariabels.btnContact)"
		[ngStyle]="{ order: asessorOrder.lnk_form }"
	>
		<a>
			<div class="icon icon-blog active"></div>
			<span class="contentItem"> {{ 'v10.dashboard.overlay_assessor.lnk_form' | translate }} </span>
			<i class="icon icon-chevron-right"> </i>
		</a>
		<hr class="hr" />
	</div>

	<!-- Documentum -->
	<!-- tagging-->
	<div
		class="navigate"
		*ngIf="isDocumentumActive"
		(click)="trackAction(taggingVariabels.btnBuzon); sendMessage('documentum')"
		[ngStyle]="{ order: asessorOrder.lnk_documentum }"
	>
		<section class="mva10-c-card DocumentumBanner">
			<span class="contentItem"> {{ 'v10.dashboard.overlay_assessor.document_manager_txt' | translate }} </span>
			<img
				id="downloadIcon"
				src="{{ appService.getImgFullPath('v10.dashboard.overlay_assessor.document_manager_img' | translate) }}"
			/>
		</section>
	</div>

	<!-- AssessorRateus -->
	<div [ngStyle]="{ order: asessorOrder.banner_consulta }">
		<a *ngIf="isAssessorRateusActive" (click)="trackAction(taggingVariabels.btnRateus); sendMessage('survey')">
			<section
				_ngcontent-c37=""
				class="mva10-c-card satisfactionBanner"
				data-responsive=""
				[ngStyle]="styleAssessorRateus"
			>
				<div>
					<div>
						<svg class="vfac-c-big-tile__icon">
							<title>Title</title>
							<use xlink:href="#icon-chevron-right"></use>
						</svg>
						<h4 class="vfes-mb-- valoracionAsesor">{{ 'v10.dashboard.overlay_assessor.rateus_title' | translate }}</h4>
					</div>
					<p style="margin-left: 16px">
						{{ 'v10.dashboard.overlay_assessor.rateus_subtitle' | translate }}
					</p>
				</div>
			</section>
		</a>
	</div>
</div>
