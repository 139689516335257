import { Component, OnInit } from '@angular/core';
import { Breakpoints } from '@mva10/mva10-angular';
import { TranslateService } from '@ngx-translate/core';
import { PaymentJourny } from 'src/app/shared/enums/payment-journy.enum';
import { tagging } from '../../../../config/tagging-config';
import { AppService } from '../../../app.service';
import { TaggingHelperService } from '../../../core/services/tagging.helper.service';
import { CommercialJourneys } from '../../../shared/constants/defines';
import { TaggingViewModel } from '../../../shared/models/tagging.model';
import { PriceToShowPipe } from '../../../shared/pipes/price-to-show/price-to-show.pipe';
import { CommercialMobileService } from '../../../shared/services/commercial-mobile.service';
import { CommercialPaymentService } from '../../../shared/services/commercial-payment.service';
import { NexusService } from '../../../shared/services/nexus.service';
import { PaymentNavigationService } from '../../services/payment-navigation.service';

@Component({
	selector: 'sp-payment-card',
	templateUrl: './payment-card.component.html',
	styleUrls: ['./payment-card.component.scss'],
})
export class PaymentCardComponent implements OnInit {
	public visaImage: string;
	public masterCardImage: string;
	public winkImage: string;
	public initialPay: string;
	public descriptionText: string;
	public confirmButtonText: string;
	public securedSslText: string;
	private isMobileView: boolean;
	public concept: string;

	constructor(
		public appService: AppService,
		private translate: TranslateService,
		public commercialMobileService: CommercialMobileService,
		public commercialPaymentService: CommercialPaymentService,
		public nexusService: NexusService,
		public tagging: TaggingHelperService,
		public paymentNavService: PaymentNavigationService
	) {}

	ngOnInit(): void {
		this.isMobileView = window.innerWidth < Breakpoints.TABLET;
		this.paymentNavService.productTagg = this.nexusService.productTagg;
		this.translate.get('v10.payment.itemsList.eShopMiVF').subscribe((data) => {
			this.descriptionText = data.es.preorder.description;
			this.confirmButtonText = data.es.preorder.button;
			this.visaImage = this.appService.getImgFullPath(data.es.paymentThirdParty.visa);
			this.masterCardImage = this.appService.getImgFullPath(data.es.paymentThirdParty.masterCard);
			this.concept = data.es.paymentThirdParty.conceptValue;
		});
		this.translate.get('payment').subscribe((data) => {
			if (this.isMobileView) {
				this.winkImage = this.appService.getImgFullPath(data.messagesList.cashDeskPayment.icon.url);
			} else {
				this.winkImage = this.appService.getImgFullPath(data.images.cashDeskResponsiveIcon.url);
			}
			this.securedSslText = data.itemsList.securedSsl.body;
		});
		this.setInitialPay();
	}

	public setInitialPay(): void {
		if (this.commercialMobileService.commercialshoppingCartItems.price.devicesUpfrontTaxesPromo !== 0) {
			this.initialPay = PriceToShowPipe.prototype.transform(
				this.commercialMobileService.commercialshoppingCartItems.price.devicesUpfrontTaxesPromo
			);
			this.confirmButtonText = this.confirmButtonText.replace('{0}', this.initialPay.toString());
		}
	}
	public openPaymentProcess(): void {
		this.commercialPaymentService.openPaymentModule(
			this.initialPay,
			PaymentJourny.postCardPaymentCommercial,
			this.concept
		);
		const pagePayment: TaggingViewModel = Object.assign({}, tagging.genericCheckout.paymentMethod.pageInitPayment);
		const dataPayment: TaggingViewModel = Object.assign({}, tagging.genericCheckout.paymentMethod.dataInitPayment);
		const eventPayment: TaggingViewModel = Object.assign({}, tagging.genericCheckout.paymentMethod.eventInitPayment);
		const trackdata: TaggingViewModel = Object.assign(pagePayment, dataPayment, eventPayment);
		trackdata['&&products'] = this.nexusService.productTagg;
		trackdata.event_name = CommercialJourneys.paymentCard.initPayment.event_name;
		trackdata.event_category = CommercialJourneys.paymentCard.initPayment.event_category;
		trackdata.event_context = CommercialJourneys.paymentCard.initPayment.event_context;
		trackdata.event_label = this.confirmButtonText.toLowerCase();
		this.tagging.trackReplace(trackdata.event_name, trackdata, false);
	}
}
