import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { BillTicket } from 'src/app/shared/models/bill-details.model';
import { SubscriptionService } from '../../core/services/subscription.service';
import * as constants from '../../shared/constants/defines';
import { API_URLS } from '../../shared/constants/routes-config';
import { ProductPurchase } from '../../shared/models/product-purchase.model';
import { CompanyService } from '../../shared/services/company.service';

@Injectable()
export class BillingVf10Data {
	constructor(
		private http: HttpClient,
		private companyService: CompanyService,
		private subscriptionService: SubscriptionService
	) {}

	public getCustomerAccount(siteId: string) {
		let url;
		if (this.companyService.selectedCompanyId) {
			url = `${API_URLS.Subscriptions.Subscription}?holderId=${this.companyService.selectedCompanyId}&customerAccountId=${siteId}`;
		} else {
			url = `${API_URLS.Subscriptions.Subscription}?customerAccountId=${siteId}`;
		}
		let headers = new HttpHeaders();
		headers = headers.append('Content-Type', 'application/json');
		headers = headers.append('accept', 'application/json');
		const options = {
			headers: headers,
		};
		return this.http.get(url, options);
	}

	public getBalanceForService(siteId: string, bundleType = constants.BundleType.outOfPlan) {
		return this.http
			.get(
				API_URLS.Dashboard.ServiceBalance.replace('{siteId}', siteId)
					.replace('{serviceId}', this.subscriptionService.customerData.currentService.id)
					.replace('{bundleType}', bundleType)
			)
			.pipe(
				map((response: any) => {
					return response;
				})
			);
	}
	public getTicket(customerId: string): Observable<BillTicket[] | BillTicket[][]> {
		let url: string;
		url = API_URLS.Ticket.getJourney;
		url = url.replace('{customerID}', customerId);
		let headers: HttpHeaders = new HttpHeaders();
		headers = headers.append('Content-Type', 'application/json');
		const options: { [key: string]: HttpHeaders } = {
			headers: headers,
		};
		return this.http.get<BillTicket[]>(url, options);
	}
	getUserPurchases(siteId: string): Observable<ProductPurchase[]> {
		const url: string = API_URLS.AccountManagement.GetUserPurchases.replace('{siteId}', siteId);
		let headers: HttpHeaders = new HttpHeaders();
		headers = headers.append('Content-Type', 'application/json');
		const options: { [key: string]: HttpHeaders } = {
			headers: headers,
		};
		return this.http.get<ProductPurchase[]>(url, options).pipe(
			map((response) => {
				return response;
			}),
			catchError(() => of([]))
		);
	}
}
