import { Component, OnDestroy, OnInit } from '@angular/core';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import { ActivatedRoute, Router } from '@angular/router';
import { Breakpoints, OverlayFullScreenModel, OverlayModel } from '@mva10/mva10-angular';
import { TranslateService } from '@ngx-translate/core';
import { Subscription } from 'rxjs';
import { config } from '../../../config/pages-config';
import { environment } from '../../../environments/environment';
import { AppService } from '../../app.service';
import { BubbleHelpService } from '../../shared/components/bubble-help/bubble-help.service';
import {
	ADARA_TRACKING,
	FOLDER_ADARA_WEBVIEW_CHECKOUT,
	FOLDER_ADARA_WEBVIEW_EXIT,
} from '../../shared/constants/archetype/adaraTracking';
import {
	OnBehalOfTypes,
	adaraEntrypointRequestMaxNumber,
	adaraParams,
	adaraUrlParams,
	adaraValues,
	entryPointsMVA10,
	stringOfTrue,
} from '../../shared/constants/defines';
import { AdaraReducedTokenDataModel } from '../../shared/models/adara-webview.model';
import { EnergyDebtResponse } from '../../shared/models/energy-debt-model';
import { EntryPointResponseModel } from '../../shared/models/entry-points.model';
import { NewTaggingJsonModel } from '../../shared/models/new-tagging-json-model';
import { AdaraEventData } from '../../shared/models/orders-event-data.model';
import { BillingData } from './../../billing/billing-data';
import { BillingService } from './../../billing/billing.service';
import { AuthenticateService } from './../../core/services/authenticate.service';
import { NewTaggingHelperService } from './../../core/services/new-tagging.helper.service';
import { StorageService } from './../../core/services/storage.service';
import { SubscriptionService } from './../../core/services/subscription.service';
import { LeftMenuWs10Service } from './../../mva10/shared/services/left-menu-ws10.service';
import { PaymentNavigationService } from './../../payment/services/payment-navigation.service';
import { PaymentJourny } from './../../shared/enums/payment-journy.enum';
import { PaymentPages } from './../../shared/enums/payment-pages.enum';
import { EntryPointsService } from './../../shared/services/entry-points.service';

@Component({
	selector: 'sp-adara-main',
	templateUrl: './adara-main.component.html',
	styleUrls: ['./adara-main.component.scss'],
})
export class AdaraMainComponent implements OnInit, OnDestroy {
	redirectUrl: string;
	webToken: string = '';
	urlIframe: SafeResourceUrl;
	showIframe: boolean = false;
	adaraIframe: HTMLIFrameElement;
	iframeHasError: boolean = false;
	public reducedToken: string;
	public origin: string;
	public comesFromEP: boolean = true;
	public comesFromShop: boolean = false;
	public comesFromLogin: boolean = false;
	public comesFromBills: boolean = false;
	public comesFromPyS: boolean = false;
	public isMobile: boolean;
	public isLeaving: boolean;

	public adaraCloseOverlayTitle: string;
	public adaraCloseOverlaySubtitle: string;
	public doYouWantToLeaveTitle: string;
	public adaraIframeUrlBase: string;

	public haveYouFinishedOverlay: OverlayModel;
	public exitBuyingFullScreenOverlay: OverlayFullScreenModel;
	public errorOverlay: OverlayFullScreenModel;

	public showDoYouWantToExitBuyingFullScreenOverlay: boolean;
	public showHaveYouFinishedOverlay: boolean;
	public showErrorOverlay: boolean;

	public errorModalTitle: string;
	public errorModalSubtitle: string;
	public errorModalButton: string;
	handleReceivedMessageFromIframe: (event: MessageEvent) => void;

	isPayingEnergyDebtObservable: Subscription;

	constructor(
		private translate: TranslateService,
		public appService: AppService,
		private router: Router,
		private sanitizer: DomSanitizer,
		private activeRoute: ActivatedRoute,
		private newTaggingHelperService: NewTaggingHelperService,
		private authenticateService: AuthenticateService,
		private leftMenuService: LeftMenuWs10Service,
		private subscriptionService: SubscriptionService,
		private entryPointsService: EntryPointsService,
		private storageService: StorageService,
		private bubbleHelpService: BubbleHelpService,
		private debtService: BillingData,
		private paymentNavService: PaymentNavigationService,
		private billingService: BillingService
	) {
		this.appService.hiddenHeader = true;
	}

	ngOnInit(): void {
		this.appService.showFullAppLoader = true;
		this.activeRoute.queryParams.subscribe((params) => {
			this.origin = params.origen;
			this.comesFromEP = this.origin === adaraValues.originEP;
			this.comesFromLogin = this.origin === adaraValues.originLogin;
			this.comesFromBills = this.origin === adaraValues.originBills;
			this.comesFromPyS = this.origin === adaraValues.originPyS;
			this.leftMenuService.showLeftMenu = false;
			this.origin === adaraValues.originShop
				? (this.comesFromShop = this.comesFromEP = true)
				: (this.comesFromShop = false);
			this.initData(window.location.href.includes(config.energy.route));
		});
		this.isMobile = window.innerWidth < Breakpoints.TABLET;
	}

	init(): void {
		this.getReducedToken();
		this.billingService.isPayingEnergyDebt = false;
		this.paymentNavService.isPayingEnergyDebt = false;
		this.paymentNavService.partialEnergyDebtPayment = false;
		this.appService.showFullAppLoader = true;
	}

	checkEnergyDebt(): void {
		const siteId: string = this.storageService.userProfile.sites?.find(
			(site) => site.marketType === adaraValues.CLIENT_ADARA
		)?.id;
		if (siteId && this.translate.instant('v10.productsServices.adara.billing.pagoDeuda') === stringOfTrue) {
			this.debtService.getEnergyDebt(siteId).subscribe(
				(res: EnergyDebtResponse) => {
					if (res?.accountBalance[0]?.amount?.value > 0) {
						this.billingService.deptAmount = res.accountBalance[0].amount.value / 100;
						this.billingService.isPayingEnergyDebt = true;
						this.paymentNavService.isPayingEnergyDebt = true;
						this.paymentNavService.openPaymentComponent(PaymentPages.dept, PaymentJourny.postDeptTotal);
						this.appService.showFullAppLoader = false;
						this.setIsPayingEnergyDebtObservable();
					} else {
						this.init();
					}
				},
				(error) => {
					this.init();
				}
			);
		} else {
			this.init();
		}
	}

	checkEnergyStatus(versionPortalEP?: string): void {
		this.entryPointsService
			.getEntryPoints(
				entryPointsMVA10.energy,
				this.subscriptionService.customerData.customerAccountsId,
				adaraEntrypointRequestMaxNumber,
				false,
				this.subscriptionService.customerData.currentService.id,
				versionPortalEP
			)
			.subscribe(
				(res: EntryPointResponseModel) => {
					if (res.entryPoints.some((entrypoint) => entrypoint.code === adaraValues.adara)) {
						this.comesFromEP = true;
						this.init();
					} else if (
						this.storageService.userProfile.sites.some((site) => site.marketType === adaraValues.CLIENT_ADARA)
					) {
						this.storageService.userProfile.category === adaraValues.categoryAdara
							? (this.comesFromLogin = true)
							: (this.comesFromEP = false);
						this.checkEnergyDebt();
					} else {
						this.router.navigate([config.dashboard.route]);
					}
				},
				(error) => {
					this.router.navigate([config.dashboard.route]);
				}
			);
	}

	public initData(comesFromDeeplink?: boolean): void {
		if (comesFromDeeplink) {
			this.checkEnergyStatus(this.translate.instant('v10.commercial.versionPortalEP'));
		} else {
			this.comesFromEP ? this.init() : this.checkEnergyDebt();
		}
		this.exitBuyingFullScreenOverlay = new OverlayFullScreenModel();
		this.haveYouFinishedOverlay = new OverlayModel();
		this.translate.get('v10.productsServices.adara').subscribe((data) => {
			this.adaraCloseOverlayTitle = data.closeOverlay.title;
			this.adaraIframeUrlBase = data.urlBase;
			this.appService.appHeaderTitle = data.title;

			this.haveYouFinishedOverlay.title = this.comesFromEP ? null : data.title;
		});
		this.translate.get('v10.common.closeOverlay').subscribe((data) => {
			this.haveYouFinishedOverlay.primaryButton = this.comesFromEP ? data.btnContinue : data.buttonYes;
			this.haveYouFinishedOverlay.secondaryButton = this.comesFromEP ? data.btnExit : data.buttonNo;
			this.haveYouFinishedOverlay.isCenterTitle = false;
			this.haveYouFinishedOverlay.showBackButton = true;
			this.haveYouFinishedOverlay.hideHeader = !this.comesFromEP;
			this.haveYouFinishedOverlay.isFullScreen = this.comesFromEP;
		});
		this.translate.get('v10.productsServices.adara.closeOverlayPurchase').subscribe((data) => {
			this.doYouWantToLeaveTitle = data.title;
			this.adaraCloseOverlaySubtitle = data.subtitle;
			this.exitBuyingFullScreenOverlay.primaryBtnText = data.button1;
			this.exitBuyingFullScreenOverlay.stylesPrimaryBtn = null;
			this.exitBuyingFullScreenOverlay.secundaryBtnText = data.button2;
			this.exitBuyingFullScreenOverlay.stylesSecundaryBtn = 'mva10-c-button--tertiary';
			this.exitBuyingFullScreenOverlay.closeBtn = true;
			this.exitBuyingFullScreenOverlay.horizontalBtn = true;
			this.exitBuyingFullScreenOverlay.largeButtons = true;
		});
		this.translate.get('v10.common.literals').subscribe((data) => {
			this.errorModalTitle = data.msg.error.ups_seems;
			this.errorModalSubtitle = data.msg.error.could_not_getdata;
			this.errorModalButton = data.retry_C;
		});
	}

	public getReducedToken(): void {
		const WCSData: AdaraReducedTokenDataModel = new AdaraReducedTokenDataModel();
		WCSData.grantType = OnBehalOfTypes.grantType;
		WCSData.clientId = environment.CLIENT_ID;
		WCSData.subjectTokenType = OnBehalOfTypes.subjectTokenType;
		WCSData.actorTokenType = OnBehalOfTypes.actorTokenType;
		WCSData.chatbotScope = adaraParams.REDUCEDTOKENSCOPE;
		WCSData.actorToken = environment.ADARA_CLIENT_ID;
		this.appService.getChatBotToken(WCSData).subscribe(
			(data) => {
				this.reducedToken = data.access_token;
				this.getAdaraURL(this.reducedToken);
			},
			(error) => {
				this.showErrorMessage();
				this.appService.showFullAppLoader = false;
			}
		);
	}

	public getAdaraURL(reducedToken: string): string {
		const url: string =
			this.adaraIframeUrlBase +
			adaraUrlParams.redirectPrefix +
			adaraParams.AUTHORIZECLIENTIDVALUE +
			adaraUrlParams.redirectUri +
			(this.comesFromBills
				? adaraUrlParams.redirectUriBilling
				: this.comesFromEP
				? adaraUrlParams.redirectUriPurchase
				: adaraUrlParams.redirectUriSelfcare) +
			adaraUrlParams.redirectScope +
			reducedToken;
		this.openIframe(url, reducedToken);
		return url;
	}

	openIframe(url: string, webToken: string): void {
		this.appService.showFullAppLoader = false;
		this.webToken = webToken;
		this.urlIframe = this.sanitizer.bypassSecurityTrustResourceUrl(url);
		this.showIframe = true;
		this.handleReceivedMessageFromIframe = (event: MessageEvent) => {
			if (event?.data) {
				try {
					const receivedData: AdaraEventData = JSON.parse(event.data);
					this.handleEvents(receivedData);
				} catch (error) {
					// Catch handleReceivedMessageFromIframe error
				}
			}
		};
		window.addEventListener('message', this.handleReceivedMessageFromIframe);
		this.comesFromEP
			? this.bubbleHelpService.isAdaraSectionValue(false, true)
			: this.bubbleHelpService.isAdaraSectionValue(false, false);
	}

	public iframeLoadEventF(iframe: HTMLIFrameElement): void {
		this.adaraIframe = document.getElementById('AdaraIframe') as HTMLIFrameElement;
		this.adaraIframe.contentWindow.postMessage(this.webToken, '*');
		try {
			if (iframe.contentWindow.location.href.indexOf('/adaralogin') !== -1) {
				this.authenticateService.logout();
				this.router.navigate([config.adaralogin.name]);
				this.appService.showVodaFullLoader = false;
			} else {
				setTimeout(() => {
					this.appService.showFullAppLoader = false;
				}, 4500);
			}
		} catch (error) {
			this.appService.showFullAppLoader = false;
		}
	}
	handleEvents(receivedData: AdaraEventData): void {
		if (
			receivedData?.action === adaraValues.adaraEventCloseAction &&
			receivedData?.message === adaraValues.adaraEventCloseMessage
		) {
			this.exit();
		}
	}

	public showExitOverlay(): void {
		if (this.comesFromEP) {
			this.showDoYouWantToExitBuyingFullScreenOverlay = true;

			this.newTaggingHelperService
				.getJsonTagging(FOLDER_ADARA_WEBVIEW_CHECKOUT)
				.subscribe((data: NewTaggingJsonModel) => {
					this.newTaggingHelperService.state(data.page);
				});
		} else {
			this.showHaveYouFinishedOverlay = true;

			this.newTaggingHelperService.getJsonTagging(FOLDER_ADARA_WEBVIEW_EXIT).subscribe((data: NewTaggingJsonModel) => {
				this.newTaggingHelperService.state(data.page);
			});
		}
	}

	public showErrorMessage(): void {
		this.errorOverlay = new OverlayFullScreenModel();
		this.errorOverlay.primaryBtnText = this.errorModalButton;
		this.errorOverlay.stylesPrimaryBtn = null;
		this.errorOverlay.closeBtn = true;
		this.showErrorOverlay = true;
	}

	public exit(): void {
		this.appService.showFullAppLoader = true;
		if (this.comesFromEP && !this.comesFromShop) {
			this.router.navigate([config.dashboard.route]);
		} else if (this.comesFromShop) {
			this.router.navigate([config.tienda.route]);
		} else if (this.comesFromLogin) {
			this.authenticateService.logout();
			this.router.navigate([config.login.route]);
		} else if (this.comesFromPyS) {
			this.router.navigate([config.ProductsAndServices.landing.route], {
				queryParams: { pillSelected: config.energy.name },
			});
		} else {
			this.router.navigate([config.dashboard.route]);
		}
		this.isLeaving = true;
		this.adaraClickOnCtaTags(this.isLeaving);
		this.showHaveYouFinishedOverlay = false;
		this.showDoYouWantToExitBuyingFullScreenOverlay = false;
		this.showErrorOverlay = false;
		this.bubbleHelpService.isAdaraSectionValue(true);
		this.billingService.isPayingEnergyDebt = false;
		this.paymentNavService.isExitingPaymentEnergyDebt.next(false);
		this.isPayingEnergyDebtObservable?.unsubscribe();
		this.paymentNavService.isPayingEnergyDebt = false;
		this.paymentNavService.partialEnergyDebtPayment = false;
	}

	public stay(): void {
		this.isLeaving = false;
		this.adaraClickOnCtaTags(this.isLeaving);
		this.showHaveYouFinishedOverlay = false;
		this.showDoYouWantToExitBuyingFullScreenOverlay = false;
		this.showErrorOverlay = false;
	}

	public retry(): void {
		this.showErrorOverlay = false;
		this.exit();
	}

	public adaraClickOnCtaTags(isLeaving: boolean): void {
		this.newTaggingHelperService
			.getJsonTagging(this.comesFromEP ? FOLDER_ADARA_WEBVIEW_CHECKOUT : FOLDER_ADARA_WEBVIEW_EXIT)
			.subscribe((data: NewTaggingJsonModel) => {
				if (this.comesFromEP) {
					this.newTaggingHelperService.interaction(
						isLeaving
							? data.eventList[ADARA_TRACKING.INTERACTIONS.CLICK_LEAVE]
							: data.eventList[ADARA_TRACKING.INTERACTIONS.CLICK_CONTINUE],
						data.page
					);
				} else {
					this.newTaggingHelperService.interaction(
						isLeaving
							? data.eventList[ADARA_TRACKING.INTERACTIONS.CLICK_YES]
							: data.eventList[ADARA_TRACKING.INTERACTIONS.CLICK_NO],
						data.page
					);
				}
			});
	}

	setIsPayingEnergyDebtObservable(): void {
		this.isPayingEnergyDebtObservable = this.paymentNavService.isExitingPaymentEnergyDebtObservable.subscribe(
			(exit: boolean) => {
				if (exit) {
					this.paymentNavService.hasPayedEnergyDebt && !this.paymentNavService.partialEnergyDebtPayment
						? this.init()
						: this.exit();
					this.isPayingEnergyDebtObservable?.unsubscribe();
				}
			}
		);
	}

	ngOnDestroy(): void {
		this.appService.showTopBar = true;
		this.appService.hiddenHeader = false;
		this.leftMenuService.showLeftMenu = true;
		this.billingService.isPayingEnergyDebt = false;
		this.paymentNavService.isExitingPaymentEnergyDebt.next(false);
		this.isPayingEnergyDebtObservable?.unsubscribe();
	}
}
