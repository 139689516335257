import { HttpBackend, HttpClient, HttpErrorResponse, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router, RouterStateSnapshot } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { Guid } from 'guid-typescript';
import { Observable, Subject, of, throwError } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { environment } from '../../../environments/environment';
import { AppService } from '../../app.service';
import { BillingService } from '../../billing/billing.service';
import { CommonService } from '../../core/services/common.service';
import { ConfigurationService } from '../../core/services/configuration.service';
import { JWTHelper } from '../../core/services/jwt.helper';
import { NewTaggingHelperService } from '../../core/services/new-tagging.helper.service';
import { StorageService } from '../../core/services/storage.service';
import { SubscriptionService } from '../../core/services/subscription.service';
import { TaggingHelperService } from '../../core/services/tagging.helper.service';
import { TrayConfigurationService } from '../../mva10/dashboard/services/tray-configuration.service';
import {
	AppThemes,
	CONFIG,
	CSRFToken,
	FTEMapping,
	GRANT_TYPE,
	LOCAL_STORAGE_KEYS,
	transactionKey,
} from '../../shared/constants/defines';
import { API_URLS } from '../../shared/constants/routes-config';
import { CustomerType } from '../../shared/enums/customerType.enum';
import { Configurations } from '../../shared/models/configuration.model';
import { CustomerAccount } from '../../shared/models/customer-account.model';
import { LoginResponseModel, OtpResponseModel } from '../../shared/models/login.model';
import { NewTaggingJsonModel } from '../../shared/models/new-tagging-json-model';
import { Subscription } from '../../shared/models/subscription.model';
import { Tariff } from '../../shared/models/tariff.model';
import { UserProfile } from '../../shared/models/user-profile.model';
import { CompanyService } from '../../shared/services/company.service';
import { CustomerAccountService } from '../../shared/services/customer-account.service';
import { ProductService } from '../../shared/services/product.service';
import { RecommendationService } from '../../shared/services/recommendation.service';
import { SiteHandlingService } from '../../shared/services/site-handling.service';
import { TariffService } from '../../shared/services/tariff.service';
import { LoggerService } from '../../shared/utils/LoggerService';
import { CacheService } from '../../shared/utils/cache.service';
import { UtilitiesService } from '../../shared/utils/utilities.service';
import * as pagesConfig from './../../../config/pages-config';
import { SmapiService } from './smapi.service';

@Injectable()
export class AuthenticateService {
	appConfig: Configurations;
	isRefreshingToken: boolean = false;
	refreshTokenEmmiter: Subject<boolean> = new Subject<boolean>();
	refreshingTokenInProgress: boolean = false;
	private refreshTokenSubject: Subject<LoginResponseModel> = new Subject<LoginResponseModel>();
	constructor(
		private productService: ProductService,
		private tariffService: TariffService,
		private storageService: StorageService,
		private jwtHelper: JWTHelper,
		private http: HttpClient,
		private appTheme: AppService,
		private router: Router,
		private config: ConfigurationService,
		private subscriptionService: SubscriptionService,
		private customerAccountService: CustomerAccountService,
		private companyService: CompanyService,
		private siteHandlingService: SiteHandlingService,
		private tagging: TaggingHelperService,
		private utility: UtilitiesService,
		private logger: LoggerService,
		private recommendationService: RecommendationService,
		private billingService: BillingService,
		private translateService: TranslateService,
		private newTagging: NewTaggingHelperService,
		private common: CommonService,
		private smapiService: SmapiService,
		private trayConfigurationService: TrayConfigurationService,
		private httpBackend: HttpBackend,
		private storage: StorageService
	) {}

	/**
	 * Get shared items in two arrys
	 * @param arr1
	 * @param arr2
	 */
	private intersect(arr1: any, arr2: any): any[] {
		let temp: any;
		if (arr2.length > arr1.length) {
			(temp = arr2), (arr2 = arr1), (arr1 = temp); // indexOf to loop over shorter
		}
		arr1.map(function (x: string): any {
			return x.toLowerCase();
		});
		return arr1.filter(function (item: string): any {
			return arr2.indexOf(item.toLowerCase()) > -1;
		});
	}

	/**
	 * Check if the user already have tokens and userProfile
	 * @return boolean indicated if the user Authenticated or not
	 */
	public isAuthenticated(): boolean {
		return !!this.storageService.userProfile;
	}

	/**
	 * Check if user have permission or not
	 * @param role the permission/role user have
	 */
	hasRole(role: string): boolean {
		const currentUser = this.storageService.userProfile;
		let hasRole = false;
		if (currentUser) {
			const res = currentUser.permissions.filter((r) => r.toLocaleLowerCase() === role.toLocaleLowerCase());
			if (res.length > 0) {
				hasRole = true;
			}
		}
		return hasRole;
	}

	// postpaid prepaid fullprofile light
	hasRoles(roles: string[]): boolean {
		// Check if the user permission intercects with roles specified
		return (
			this.storageService.userProfile &&
			this.intersect(this.storageService.userProfile.permissions, roles).length === roles.length
		);
	}

	logout(): void {
		if (!this.isRefreshTokenExpired()) {
			let headers = new HttpHeaders();
			const body =
				'token=' +
				this.storageService.getLocalStorage(LOCAL_STORAGE_KEYS.REFRESH_TOKEN) +
				'&token_type_hint=refresh_token';
			headers = headers.append('Content-Type', 'application/x-www-form-urlencoded');
			headers = headers.append('Accept', 'application/json');
			headers = headers.append('Authorization', 'Basic ' + btoa(CONFIG.CLIENT_ID));
			const config = {
				headers: headers,
			};
			this.http.post(API_URLS.LogOut.SESSION_END, body, config).subscribe();
		}
		// clear the localStorage and global objects in the service
		this.forceClearUserStorageData();
	}

	forceClearUserStorageData(): void {
		this.storageService.removeFromLocalStorage(LOCAL_STORAGE_KEYS.ACCESS_TOKEN);
		this.storageService.removeFromLocalStorage(LOCAL_STORAGE_KEYS.ACCESS_TOKEN_EXP);
		this.storageService.removeFromLocalStorage(LOCAL_STORAGE_KEYS.ACCESS_TOKEN_ISSUED);
		this.storageService.removeFromLocalStorage(LOCAL_STORAGE_KEYS.JWT);
		this.storageService.remove(LOCAL_STORAGE_KEYS.HIDE_BILL_ALARMS_HINT);
		this.storageService.remove(LOCAL_STORAGE_KEYS.TAGGING_PAGE_ORIGINAL_REFERING);
		this.storageService.empty();
		sessionStorage.removeItem(FTEMapping.fteShown);
		this.subscriptionService.customerData = new Subscription();
		this.subscriptionService.postpaid = 0;
		this.subscriptionService.prepaid = 0;
		this.customerAccountService.customerAccount = new CustomerAccount();
		this.customerAccountService.customerAccounts = [];
		this.customerAccountService.customerAccountsPendingInstall = [];
		this.customerAccountService.selectDefault = null;
		this.subscriptionService.customerData.customerAccountsId = null;
		this.customerAccountService.updatedAccountNumber = '';
		this.companyService.CompanyList = [];
		this.companyService.selectedCompanyId = '';
		this.appTheme.theme = AppThemes.ThemeNewLogin;
		this.tariffService.Tariff = new Tariff();
		this.productService.products = [];
		this.appTheme.showBackButton = true;
		this.storageService.userProfile = undefined;
		this.siteHandlingService.currentSiteStatus = '';
		this.subscriptionService.loadedCompaneis = [];
		this.recommendationService.clean();
		this.billingService.selectedSiteId = '';
		this.storageService.isFirmaFinished = false;
		this.storageService.showFirmaEntryPoint = false;
		this.storageService.checkedRedirectionToSignProcess = false;
		CacheService.clearCache();
		this.tagging.resetCrossVariablesAfterLogout();
		this.smapiService.removeSmapiId();
		this.trayConfigurationService.hasMktpEntrypoint = null;
		this.appTheme.hiddenHeader = true;
		this.appTheme.showFullAppLoader = false;
		this.appTheme.showVodaFullLoader = false;
	}

	public isAccessTokenExist(): boolean {
		if (
			!this.storageService.getLocalStorage(LOCAL_STORAGE_KEYS.ACCESS_TOKEN) ||
			!this.storageService.getLocalStorage(LOCAL_STORAGE_KEYS.ACCESS_TOKEN_EXP)
		) {
			return false;
		}
		return true;
	}
	public isAccessTokenExpired(): boolean {
		if (!this.storageService.getLocalStorage(LOCAL_STORAGE_KEYS.ACCESS_TOKEN_EXP)) {
			return true;
		}
		const expirationDate: Date = new Date(this.storageService.getLocalStorage(LOCAL_STORAGE_KEYS.ACCESS_TOKEN_EXP));
		return expirationDate && expirationDate.getTime() < new Date().getTime();
	}

	public isAccessTokenAlmostExpired(timeBeforeExpire: number): boolean {
		if (!this.storageService.getLocalStorage(LOCAL_STORAGE_KEYS.ACCESS_TOKEN_EXP)) {
			return true;
		}
		const currentDate: Date = new Date();
		const expirationDate: Date = new Date(
			new Date(this.storageService.getLocalStorage(LOCAL_STORAGE_KEYS.ACCESS_TOKEN_EXP)).setMinutes(
				new Date(this.storageService.getLocalStorage(LOCAL_STORAGE_KEYS.ACCESS_TOKEN_EXP)).getMinutes() -
					timeBeforeExpire
			)
		);
		return expirationDate && expirationDate.getTime() < currentDate.getTime();
	}

	/** checks the issue date of access token againts timeAfterIssued param to know if the access token is recently issued
	 * @param {timeAfterIssued} number minutes after which the access token is not recently issued
	 */
	public isAccessTokenRecentlyIssued(timeAfterIssued: number): boolean {
		if (!this.storageService.getLocalStorage(LOCAL_STORAGE_KEYS.ACCESS_TOKEN_ISSUED)) {
			return false;
		}
		const currentDate: Date = new Date();
		const issueDate: Date = new Date(
			new Date(this.storageService.getLocalStorage(LOCAL_STORAGE_KEYS.ACCESS_TOKEN_ISSUED)).setMinutes(
				new Date(this.storageService.getLocalStorage(LOCAL_STORAGE_KEYS.ACCESS_TOKEN_ISSUED)).getMinutes() +
					timeAfterIssued
			)
		);
		return issueDate && issueDate.getTime() >= currentDate.getTime();
	}
	public isRefreshTokenExist(): boolean {
		return !!this.utility.getCookie('refresh-token');
	}
	public isRefreshTokenExpired(): boolean {
		if (
			!this.storageService.getLocalStorage(LOCAL_STORAGE_KEYS.REFRESH_TOKEN) ||
			!this.storageService.getLocalStorage(LOCAL_STORAGE_KEYS.REFRESH_TOKEN_EXP)
		) {
			return true;
		}
		const expirationDate = new Date(this.storageService.getLocalStorage(LOCAL_STORAGE_KEYS.REFRESH_TOKEN_EXP));
		return expirationDate.getTime() < new Date().getTime();
	}

	/**
	 * Create Request and handle the Token storage retrieved from server
	 * @param config the options passed to the request like headers
	 * @param body the request body passed to the service
	 * @param url the remote url
	 * @param saveToken
	 */
	public createRequest(
		config: Object,
		body: Object,
		url: string,
		saveToken: boolean = false
	): Observable<LoginResponseModel> {
		let backendClient: HttpClient;
		backendClient = new HttpClient(this.httpBackend);
		return backendClient.post(url, body, config).pipe(
			map((response: LoginResponseModel) => {
				if (saveToken) {
					if (url === API_URLS.Login.OTP_TOKEN) {
						this.getUserProfileWithOTP(response);
					} else {
						this.getUserProfileWithoutOTP(response);
					}
				}
				return response;
			})
		);
	}

	/**
	 * Save token in Local Storage. jwt-access-refresh with OTP
	 * @param data with login information
	 */
	getUserProfileWithOTP(data: OtpResponseModel): void {
		this.setStorageToken(data, true);
		this.getUserProfile(data.userInfo?.jws);
	}

	/**
	 * Save token in Local Storage. jwt-access-refresh without OTP
	 * @param data with login information
	 */
	getUserProfileWithoutOTP(data: LoginResponseModel): void {
		this.setStorageToken(data);
		this.getUserProfile(data.jws);
	}

	setStorageToken(data: OtpResponseModel, isOTPProfile?: boolean): void {
		const accessToken: string = data.access_token;
		const refreshToken: string = data.refresh_token;
		this.storageService.setLocalStorage(LOCAL_STORAGE_KEYS.JWT, [isOTPProfile ? data.userInfo?.jws : data.jws]);
		this.storageService.setLocalStorage(LOCAL_STORAGE_KEYS.ACCESS_TOKEN, accessToken);
		this.storageService.setLocalStorage(LOCAL_STORAGE_KEYS.REFRESH_TOKEN, refreshToken);
		// converting from seconds to milli seconds and storing it
		const accessTokenExpireDate: Date = new Date(+data.expires_at * 1000);
		const accessTokenIssuedDate: Date = new Date(+data.issued_at * 1000);
		const refreshTokenExpireDate: Date = new Date(+data.refresh_token_expires * 1000);
		this.storageService.accessToken = accessToken;
		this.storageService.refreshToken = refreshToken;
		if (accessTokenExpireDate) {
			this.storageService.accessExpirationTime = accessTokenExpireDate;
			this.storageService.setLocalStorage(LOCAL_STORAGE_KEYS.ACCESS_TOKEN_EXP, accessTokenExpireDate);
		}
		if (accessTokenIssuedDate) {
			this.storageService.accessIssuedTime = accessTokenIssuedDate;
			this.storageService.setLocalStorage(LOCAL_STORAGE_KEYS.ACCESS_TOKEN_ISSUED, accessTokenIssuedDate);
		}
		if (refreshTokenExpireDate) {
			this.storageService.refreshExpirationTime = refreshTokenExpireDate;
			this.storageService.setLocalStorage(LOCAL_STORAGE_KEYS.REFRESH_TOKEN_EXP, refreshTokenExpireDate);
		}
	}

	getUserProfile(jwt: string): UserProfile {
		const userProfile: UserProfile = this.jwtHelper.getUserProfileFromJWT(jwt);
		this.storageService.userProfile = userProfile;
		this.storageService.setLocalStorage('username', userProfile.username);
		return userProfile;
	}

	/**
	 * authenticate the user
	 * @param username
	 * @param password
	 * @param recaptchaRes
	 * @param saveToken
	 */
	authenticate(
		username: string,
		password: string,
		saveToken: boolean = false,
		recaptchaRes?: string,
		isAdaraLogin?: boolean
	) {
		let headers: HttpHeaders = new HttpHeaders();

		let body: any;
		if (environment.stubsFlag) {
			body = {
				username,
				password,
				client_id: CONFIG.CLIENT_ID,
				scope: CONFIG.SCOPES.join(' '),
				grant_type: GRANT_TYPE.PASSWORD,
			};
			headers = headers.append('Content-Type', 'application/json');
		} else {
			if (recaptchaRes) {
				body =
					'username=' +
					username +
					'&password=' +
					encodeURIComponent(password) +
					'&client_id=' +
					CONFIG.CLIENT_ID +
					'&scope=' +
					CONFIG.SCOPES.join(' ') +
					'&grant_type=' +
					GRANT_TYPE.PASSWORD +
					'&response=' +
					recaptchaRes;
			} else {
				body =
					'username=' +
					username +
					'&password=' +
					encodeURIComponent(password) +
					'&client_id=' +
					CONFIG.CLIENT_ID +
					'&scope=' +
					CONFIG.SCOPES.join(' ') +
					'&grant_type=' +
					GRANT_TYPE.PASSWORD;
			}
			headers = headers.append('Content-Type', 'application/x-www-form-urlencoded');
		}
		headers = headers.append('vf-country-code', CONFIG.COUNTRY_CODE);
		headers = headers.append('Accept', 'application/json');
		headers = headers.append('recaptcha_enterprise', this.getRecapchaEnterpriseHeaderValue());
		if (isAdaraLogin) {
			headers = headers.append('login-type', 'adara');
		}

		const transactionID: Guid = Guid.create()['value'];
		headers = headers.append(transactionKey, transactionID.toString());

		headers = headers.append('vf-target-environment', 'aws-' + environment.environmentName + '-es');

		const CSRFTokenValue: any = this.storage.getLocalStorage(LOCAL_STORAGE_KEYS.USER_CSRF_TOEKN);
		headers = headers.append(CSRFToken, CSRFTokenValue.toString());

		const config: Object = {
			headers: headers,
			withCredentials: true,
		};
		return this.createRequest(config, body, API_URLS.Login.SESSION_START, saveToken);
	}

	/**
	 * authenticate the user
	 * @param msisdn
	 */
	authenticate_network(msisdn: string): Observable<LoginResponseModel> {
		const headers: HttpHeaders = new HttpHeaders();
		headers.append('Content-Type', 'application/x-www-form-urlencoded');
		headers.append('vf-country-code', CONFIG.COUNTRY_CODE);
		headers.append('recaptcha_enterprise', this.getRecapchaEnterpriseHeaderValue());

		const config: Object = {
			headers: headers,
		};

		const body: FormData = new FormData();
		body.append('msisdn', msisdn);
		body.append('client_id', CONFIG.CLIENT_ID);
		body.append('scope', JSON.stringify(CONFIG.SCOPES));
		body.append('grant_type', GRANT_TYPE.PASSWORD);
		return this.createRequest(config, body, API_URLS.Login.SESSION_START);
	}

	getRecapchaEnterpriseHeaderValue(): string {
		let result = 'true';
		this.translateService.get('v10.login.recaptchaEnterprise').subscribe((data) => {
			if (data === 'v10.login.recaptchaEnterprise') {
				result = 'true';
			}
			result = data.toLowerCase() === 'false' ? 'false' : 'true';
		});
		return result;
	}
	/**
	 * refresh user tokens if the refresh token expired
	 */
	refreshTokens(forceRefreshToken: boolean = false): Observable<LoginResponseModel> {
		if (this.refreshingTokenInProgress) {
			return this.refreshTokenSubject.asObservable();
		} else if (this.isAccessTokenRecentlyIssued(1) && forceRefreshToken) {
			return of({} as LoginResponseModel);
		} else {
			this.refreshingTokenInProgress = true;
			return this.startRefreshTokens();
		}
	}
	startRefreshTokens(): Observable<LoginResponseModel> {
		this.newTagging.getJsonTagging('refresh-token').subscribe((data: NewTaggingJsonModel) => {
			this.newTagging.interaction(data.eventList['login_persistente_start'], data.page);
		});
		const saveToken = true;
		let headers: HttpHeaders = new HttpHeaders();
		headers = headers.append('vf-country-code', CONFIG.COUNTRY_CODE);
		headers = headers.append('Content-Type', 'application/x-www-form-urlencoded');
		headers = headers.append('recaptcha_enterprise', this.getRecapchaEnterpriseHeaderValue());

		const transactionID: Guid = Guid.create()['value'];
		headers = headers.append(transactionKey, transactionID.toString());

		headers = headers.append('vf-target-environment', 'aws-' + environment.environmentName + '-es');

		const CSRFTokenValue: any = this.storage.getLocalStorage(LOCAL_STORAGE_KEYS.USER_CSRF_TOEKN);
		headers = headers.append(CSRFToken, CSRFTokenValue.toString());
		const config: Object = {
			headers: headers,
			withCredentials: true,
		};
		const body: string =
			'client_id=' +
			CONFIG.CLIENT_ID +
			'&scope=' +
			CONFIG.SCOPES.join(' ') +
			'&refresh_token=' +
			this.storageService.getLocalStorage(LOCAL_STORAGE_KEYS.REFRESH_TOKEN) +
			'&grant_type=' +
			GRANT_TYPE.REFRESH_TOKEN;
		return this.createRequest(config, body, API_URLS.Login.SESSION_START, saveToken).pipe(
			map((result: LoginResponseModel) => {
				this.refreshTokenSubject.next(result);
				this.newTagging.getJsonTagging('refresh-token').subscribe((data: NewTaggingJsonModel) => {
					this.newTagging.interaction(data.eventList['login_persistente_ok'], data.page);
					this.refreshingTokenInProgress = false;
				});
				return result;
			}),
			catchError((error) => {
				this.refreshTokenSubject.next(null);
				this.newTagging.getJsonTagging('refresh-token').subscribe((data: NewTaggingJsonModel) => {
					this.newTagging.interaction(data.eventList['login_persistente_ko'], data.page);
				});
				this.refreshingTokenInProgress = false;
				return throwError(error);
			})
		);
	}

	/**
	 * Generate OTP
	 * @param accessToken
	 * @param responseType
	 * @param loginHint
	 */
	public generateOtp(accessToken: string, responseType: string, loginHint: string): Observable<OtpResponseModel> {
		const config: Object = {
			headers: this.getOtpHeaders(accessToken),
			withCredentials: true,
		};
		const body: Object = {
			response_type: responseType,
			login_hint: loginHint,
		};
		return this.createRequest(config, body, API_URLS.Login.OTP_AUTHORIZE);
	}

	/**
	 * Validate OTP
	 * @param accessToken
	 * @param code
	 */
	public validateOtp(accessToken: string, code: number): Observable<OtpResponseModel> {
		const saveToken = true;
		const config: Object = {
			headers: this.getOtpHeaders(accessToken),
		};
		const body: Object = {
			grant_type: 'authorization_code',
			code: code.toLocaleString(),
		};
		return this.createRequest(config, body, API_URLS.Login.OTP_TOKEN, saveToken);
	}

	public validateAuthentication(state?: RouterStateSnapshot): UserProfile | Observable<UserProfile> {
		this.appConfig = this.config.getConfiguration();
		if (this.isAccessTokenExist() && !this.isAccessTokenExpired()) {
			if (this.storageService.getLocalStorage(LOCAL_STORAGE_KEYS.JWT)) {
				const jwt: string = this.storageService.getLocalStorage(LOCAL_STORAGE_KEYS.JWT)[0];
				this.storageService.userProfile = this.getUserProfile(jwt);

				// const userProfile: UserProfile = this.getUserProfile(jwt);
				this.smapiService.setSmapiId(this.storageService.userProfile.username);
				return this.storageService.userProfile;
			}
		}
		// Check if refresh token is not expired
		else if (!this.isRefreshTokenExpired()) {
			if (window.location.href.includes(pagesConfig.config.adaralogin.name)) {
				this.logout();
				this.router.navigate([pagesConfig.config.adaralogin.name]);
				this.appTheme.showVodaFullLoader = true;
				return null;
			}
			return this.refreshTokens().pipe(
				map((response) => {
					if (this.storageService.userProfile) {
						return this.storageService.userProfile;
					}
					this.router.navigate([pagesConfig.config.login.name]);
					return null;
				}),
				catchError((error) => {
					if (error instanceof HttpErrorResponse && error.status >= 400 && error.status < 500) {
						this.logger.error('Error refreshing token:', error);
						this.storageService.empty();
						if (state && state.url) {
							this.router.navigate([pagesConfig.config.login.route], {
								queryParams: {
									targetUrl: state.url,
								},
							});
						} else {
							this.router.navigate([pagesConfig.config.login.name]);
						}
					}
					return throwError(error);
				})
			);
		}
		// when the Refresh token expire we fire the logout fnc
		else if (this.isRefreshTokenExpired()) {
			this.logout();
			this.appTheme.showBackButton = false;
			this.common.showMenuButton = false;
		}
		return null;
	}

	public getUserFromLocalJWT(): UserProfile | null {
		if (this.storageService.getLocalStorage(LOCAL_STORAGE_KEYS.JWT)) {
			const jwt: string = this.storageService.getLocalStorage(LOCAL_STORAGE_KEYS.JWT)[0];
			this.getUserProfile(jwt);
			return this.storageService.userProfile;
		}
		return null;
	}

	public isBusinessUser(): boolean {
		const userProfile: UserProfile | null = this.getUserFromLocalJWT();
		return (
			userProfile &&
			userProfile.customerType &&
			userProfile.customerType.toLowerCase() === CustomerType.Employee.toLowerCase()
		);
	}

	public getOtpHeaders(accessToken: string): HttpHeaders {
		let headers: HttpHeaders = new HttpHeaders();
		headers = headers.append('Content-Type', 'application/json');
		headers = headers.append('vf-country-code', CONFIG.COUNTRY_CODE);
		headers = headers.append('otpVersion', CONFIG.OTP_VERSION);
		headers = headers.append('Authorization', 'Bearer ' + accessToken);
		const transactionID: Guid = Guid.create()['value'];
		headers = headers.append(transactionKey, transactionID.toString());
		headers = headers.append('vf-target-environment', 'aws-' + environment.environmentName + '-es');
		const CSRFTokenValue: any = this.storage.getLocalStorage(LOCAL_STORAGE_KEYS.USER_CSRF_TOEKN);
		headers = headers.append(CSRFToken, CSRFTokenValue.toString());
		return headers;
	}
}
