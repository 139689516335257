import { Component, EventEmitter, Input, Output } from '@angular/core';
import { commercialMobile } from '../../../shared/constants/defines';
import { BundleStatus } from '../../enums/bundleStatus.enum';
import { CardSection } from '../../models/card-section.model';

@Component({
	selector: 'sp-toggle-card',
	templateUrl: './toggle-card.component.html',
	styleUrls: ['./toggle-card.component.scss'],
})
export class ToggleCardComponent {
	@Input() item: CardSection;
	@Input() isOnePlus: boolean;
	@Input() bottomSectionDate: boolean = false;
	@Output() clickButton = new EventEmitter();
	@Output() clickchevron = new EventEmitter();
	@Output() clickToggle = new EventEmitter();

	public bundleStatus = BundleStatus;
	public monthlyText = commercialMobile.coinMonth;

	buttonAction() {
		this.clickButton.emit();
	}
	chevronAction() {
		this.clickchevron.emit();
	}
}
