import { BillDetailsServiceUsage } from './bill-details-service-usage.model';
import { BillDetailsModel } from './bill-details.model';
import { BillstatusStatus } from './bill-status.model';
import { BillingAddress } from './billing-address.model';

export class BillingCycle {
	public billingDate?: string;
	public billingMonth?: string;
	public billingYear?: string;
	public endDate?: string;
	public startDate?: string;
}
export class Document {
	public name?: string;
	public description?: string;
	public type?: string;
	public href?: string;
	public method?: string;
	public links?: DocumentLinks;
}
export interface DocumentLinks {
	download: {
		href: string;
		method: string;
	};
}
export class BillingAccount {
	public id?: string;
	public alias?: string;
	public name?: string;
	public billingAddress?: BillingAddress;
}
export class Subscription {
	public id?: string;
	public desc?: string;
	public name?: string;
	public type?: string;
	public serviceUsages?: BillDetailsServiceUsage[];
}
export class Amount {
	public currency?: string;
	public grossAmountDue?: number;
	public remainingAmountDue?: number;
}
export class BillOverview {
	public numberOfAlarms?: number;
	public numberOfNotifications?: string;
	// Jsonpath refactor: 'amounts' response from GetBills service has type Amount[], not Amount
	public amounts?: Amount;
	public type?: string;
}
export class Billing {
	public id?: string;
	public billNo?: string;
	public type?: string;
	public creationDate?: string;
	public dueDate?: string;
	public paymentDate?: string;
	public status?: string;
	public billingCycle?: BillingCycle;
	public documents?: Array<Document>;
	public customerAccountId?: string;
	public billingAccount?: BillingAccount;
	public subscriptions?: Array<Subscription>;
	public billOverview?: BillOverview;
	public docHref?: string;
	public alarmIsViewed = false;
	public details?: BillDetailsModel;
	public billStatus?: BillstatusStatus;
	public paymentMethod?: string[];
	public dueDateTime?: Date;
	public isDateExpired?: boolean;
}
export class GroupedBillData {
	public key: string;
	public value: Array<Billing>;
}
export class GroupedBillDataForamtted {
	public key: string;
	public value: Array<GroupedBillDetails>;
}
export class GroupedBillDetails {
	public month: string;
	public amount: string;
	public totalAmount: string;
}
export class Alert {
	public name?: string;
	public description?: string;
	public price?: string;
	public currency?: string;
	public startDate?: string;
	public endDate?: string;
}
export class BillNotification {
	public id?: string;
	public type?: string;
	public alertType?: string;
	public description?: string;
	public alert: Array<Alert>;
}
export class BillConfiguration {
	public id?: string;
	public alias?: string;
	public language?: string;
	public eBilling?: EBilling;
}
export class EBilling {
	public attachPdf?: string;
	public status?: string;
	public email?: Email;
	public sms?: SMS;
}
export class Email {
	public status?: string;
	public emailAddress?: string;
}
export class SMS {
	public status?: string;
	public msisdn?: string;
}

export interface BillingListResponse {
	count: number;
	offset: number;
	totalCount: number;
	items: BillingResponseItem[];
}

export interface BillingResponseItem {
	id?: string;
	creationDate?: string;
	status: string;
	billingCycle: BillingCycle;
	documents: Document[];
	billingAccount: BillingAccount;
	subscriptions: Subscription[];
	billOverview?: BillOverview;
	billNo?: string;
	links?: BillingResponseLinks;
}

export interface BillingResponseLinks {
	self: {
		href: string;
	};
}
