<div
	id="sharedBubbleHelpBubble"
	class="bubble"
	(click)="clickBubble()"
	*ngIf="!bubbleAnimation && !appService.showNewDashboard && appService.showBubbleChat"
	#bubble
	(panstart)="onPanStart($event)"
	[style.bottom.px]="y"
	(panend)="onPanEnd($event)"
	(panmove)="onPan($event)"
	(mouseover)="tobiSrc = tobiHover"
	(mouseout)="tobiSrc = tobiDefault"
	(mousedown)="tobiSrc = tobiFocus"
	(mouseup)="tobiSrc = tobiDefault"
>
	<img class="bubble" [src]="bubbleHelpContentDisplay ? tobiMinimize : tobiSrc" />
</div>

<div
	class="bubble bubleDashboard"
	(click)="clickBubble()"
	*ngIf="!bubbleAnimation && appService.showNewDashboard"
	#bubble
	(panstart)="onPanStart($event)"
	[style.bottom.px]="y + 22"
	(panend)="onPanEnd($event)"
	(panmove)="onPan($event)"
	(mouseover)="tobiSrc = tobiHover"
	(mouseout)="tobiSrc = tobiDefault"
	(mousedown)="tobiSrc = tobiFocus"
	(mouseup)="tobiSrc = tobiDefault"
>
	<img class="bubble" [alt]="bubbleAltTxt" [src]="bubbleHelpContentDisplay ? tobiMinimize : tobiSrc" />
</div>

<!-- Bubble help content -->
<sp-bubble-help-content (xButtonClicked)="hideContent()" [url]="iframeUrl" *ngIf="bubbleHelpContentDisplay">
</sp-bubble-help-content>

<!-- white overlay-->
<div
	*ngIf="showBubbleOverlay"
	[ngClass]="{
		'overlay-bubble-container': bubbleAnimation && !whiteOverlayDisplay,
		'overlay-bubble-container-when-close': whiteOverlayDisplay
	}"
>
	<div class="white-overlay-bubble"></div>
</div>

<!-- lottie bomb animation -->
<div class="lottie-animation" [style.bottom.px]="y" *ngIf="bubbleAnimation && !lottieDispalyNone">
	<ng-lottie [options]="lottieConfig" [width]="100" [height]="100" (animationCreated)="handleAnimation($event)">
	</ng-lottie>
</div>
<!-- click sound -->
<audio #clickSound>
	<source [src]="src" type="audio/mp3" />
</audio>
