import { APP_BASE_HREF, DecimalPipe } from '@angular/common';
import { HTTP_INTERCEPTORS, HttpClient, HttpClientJsonpModule, HttpClientModule } from '@angular/common/http';
import { ErrorHandler, NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { RouterModule } from '@angular/router';
import {
	CoreModule,
	GenericModule,
	OverlayFullScreenModule,
	OverlayModule,
	SelectorBadgeModule,
} from '@mva10/mva10-angular';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { RECAPTCHA_BASE_URL, RECAPTCHA_V3_SITE_KEY, RecaptchaV3Module } from 'ng-recaptcha';
import { CookieModule, CookieService } from 'ngx-cookie';
import { DeviceDetectorService } from 'ngx-device-detector';
import { environment } from '../environments/environment';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { AppService } from './app.service';
import { CustomHttpInterceptor } from './core/interceptors/custom-http.interceptor';
import { DashboardResolve } from './dashboard/dashboard.resolver';
import { FaultManagementData } from './fault-management/fault-management.data';
import { FaultManagementService } from './fault-management/fault-management.service';
import { LegalModule } from './legal/legal.module';
import { LineServicesData } from './line-services/line-services.data';
import { LineServicesService } from './line-services/line-services.service';
import { LoginService } from './login/login.service';
import { BillingVf10Data } from './mva10/billing-vf10/billing-vf10-data';
import { BillingVf10Service } from './mva10/billing-vf10/billing-vf10.service';
import { LeftMenuWs10Service } from './mva10/shared/services/left-menu-ws10.service';
import { ResizeService } from './mva10/shared/services/resize.service';
import { MyAccountResolve } from './my-account/my-account.resolver';
import { MyAccountService } from './my-account/my-account.service';
import { PermissionsAndPreferencesResolver } from './my-account/premission-and-prefrences/permissions-and-perferences.resolver';
import { OpSetTaggingService } from './op-products/op-set-tagging.service';
import { ProductsAndServicesResolve } from './products-and-services/products-and-services.resolver';
import { ProductsAndServicesService } from './products-and-services/products-and-services.service';
import { PurchaseProductsData } from './purchase-products/purchase-products-data';
import { PurchaseProductsService } from './purchase-products/purchase-products.service';
import { ServiceSettingsData } from './service-settings/service-settings.data';
import { ServiceSettingsService } from './service-settings/service-settings.service';
import { BubbleHelpTaggingService } from './shared/components/bubble-help/bubble-help-tagging.service';
import { CampaignService } from './shared/components/campaign-overlay/campaign-overlay.service';
import { SpAccordionService } from './shared/components/sp-accordion/sp-accordion.service';
import { baseURL, googleEnterprise } from './shared/constants/defines';
import { MdlModule } from './shared/libs/@angular-mdl/core.module';
import { MdlDialogOutletService } from './shared/libs/@angular-mdl/dialog-outlet/mdl-dialog-outlet.module';
import { MdlSnackbarService } from './shared/libs/@angular-mdl/snackbar/mdl-snackbar.module';
import { CommercialTerminalKeywordsService } from './shared/services/CommercialTerminalKeywordsService.service';
import { AditionalLinesService } from './shared/services/aditional-lines.service';
import { AllowedServicesService } from './shared/services/allowed-services.service';
import { CanjeService } from './shared/services/canje.service';
import { CheckoutCommercialService } from './shared/services/checkout-commercial.service';
import { CommercialMobileDetailService } from './shared/services/commercial-mobile-detail.service';
import { CommercialMobileService } from './shared/services/commercial-mobile.service';
import { CommercialOffersService } from './shared/services/commercial-offers.service';
import { CompanyService } from './shared/services/company.service';
import { ContactUsService } from './shared/services/contact-us.service';
import { CreditBalanceService } from './shared/services/credit-balance.service';
import { CrossFunctionalityService } from './shared/services/cross-functionality.service';
import { CustomerAccountService } from './shared/services/customer-account.service';
import { CustomerBillService } from './shared/services/customer-bill.service';
import { DashboardService } from './shared/services/dashboard.service';
import { DeepLinkingService } from './shared/services/deep-linking.service';
import { DelightPromotionService } from './shared/services/delight-promotion.service';
import { DmpService } from './shared/services/dmp.service';
import { DocumentumService } from './shared/services/documentum.service';
import { DynamicTrayService } from './shared/services/dynamic-tray.service';
import { EntryPointsService } from './shared/services/entry-points.service';
import { FederationErrorService } from './shared/services/federation-error.service';
import { FteServiceService } from './shared/services/fte-service.service';
import { GrifoTimeService } from './shared/services/grifo-time.service';
import { ImpersonationService } from './shared/services/impersonation.service';
import { Mva10FullOverlayService } from './shared/services/mva10-full-overlay.service';
import { NotificationInboxService } from './shared/services/notification-inbox.service';
import { NotificationService } from './shared/services/notification.service';
import { OpProductsListService } from './shared/services/op-products-list.service';
import { ProductConfigurationService } from './shared/services/product-configuration.service';
import { ProductService } from './shared/services/product.service';
import { RecommendationService } from './shared/services/recommendation.service';
import { RetryService } from './shared/services/retry.service';
import { ServiceBalanceWs10Service } from './shared/services/service-balance-ws10.service';
import { SidemenuConfigService } from './shared/services/sidemenu-config.service';
import { SiteHandlingService } from './shared/services/site-handling.service';
import { SmartPayPaymentService } from './shared/services/smart-pay-payment.service';
import { SpMva10OverlayService } from './shared/services/sp-mva10-overlay.service';
import { SuperwifiService } from './shared/services/superwifi.service';
import { TariffService } from './shared/services/tariff.service';
import { TiendaV10Service } from './shared/services/tienda-v10.service';
import { TryAndBuyService } from './shared/services/try-and-buy.service';
import { UserSettingsService } from './shared/services/user-settings.service';
import { SharedModule } from './shared/shared.module';
import { CustomTranslateLoader } from './shared/utils/CustomTranslateLoader';
import { GeneralErrorHandler } from './shared/utils/GeneralErrorHandler';
import { LoggerService } from './shared/utils/LoggerService';
import { CacheInterceptor } from './shared/utils/cache-interceptor.service';
import { CookieHelper } from './shared/utils/cookie-helper';
import { TaggingInterceptor } from './shared/utils/tagging-interceptor.service';
import { UtilitiesService } from './shared/utils/utilities.service';
import { TealiumUtagService } from './tealium/utag.service';
import { Mva10TopupLandingWithPciComponent } from './top-up/mva10-topup-landing-with-pci/mva10-topup-landing-with-pci.component';
import { TopUpDataService } from './top-up/top-up-data.service';
import { TopUpNavigationService } from './top-up/top-up-navigation.service';
import { TopupPrepaidErrorOVerlayDataService } from './top-up/top-up-prepaid-error-overlay-data.service';
import { TopUpService } from './top-up/top-up.service';
import { TryAndBuySetTaggingService } from './try-and-buy/try-and-buy-set-tagging.service';
import { UpgradeProfileModule } from './upgrade-profile/upgrade-profile.module';

export function createTranslateLoader(http: HttpClient) {
	return new CustomTranslateLoader(http, 'https://assets-es-pprd.dxlpreprod.local.vodafone.es/i18n/', '.json');
}

// LIBRARY
import { RoamingHelper } from './Roaming/roaming-helper.service';
import { AdaraModule } from './adara/adara.module';
import { BuySimDataHistoryService } from './buy-sim/services/buy-sim-data-history.service';
import { BuySimDataService } from './buy-sim/services/buy-sim-data.service';
import { BuySimService } from './buy-sim/services/buy-sim.service';
import { NormalizeAddressService } from './buy-sim/services/normalize-address.service';
import { CoreMVAModule } from './core/core-mva.module';
import { TicketsboxService } from './fault-management/ticketsbox.service';
import { MyAccountDataResolver } from './my-account/my-account-data.resolver';
import { PaymentAuthenticationComponent } from './payment/payment-entry-points/anonymous-entry-points/payment-authentication/payment-authentication.component';
import { PaymentBillingComponent } from './payment/payment-entry-points/anonymous-entry-points/payment-billing/payment-billing.component';
import { PaymentDisclaimerComponent } from './payment/payment-entry-points/anonymous-entry-points/payment-disclaimer/payment-disclaimer.component';
import { PaymentOtpComponent } from './payment/payment-entry-points/anonymous-entry-points/payment-otp/payment-otp.component';
import { FinancedDevicesCancellationComponent } from './payment/payment-entry-points/financed-devices-cancellation/financed-devices-cancellation.component';
import { PaymentTopupLandingComponent } from './payment/payment-entry-points/payment-topup-landing/payment-topup-landing.component';
import { SpendNewBalanceComponent } from './payment/payment-entry-points/spend-new-balance/spend-new-balance.component';
import { TopUpCountDownComponent } from './payment/payment-entry-points/top-up-count-down/top-up-count-down.component';
import { DelegateCreationService } from './payment/services/delegate-creation.service';
import { PaymentCancelService } from './payment/services/payment-cancel.service';
import { PaymentErrorService } from './payment/services/payment-error.service';
import { PaymentIframeService } from './payment/services/payment-iframe.service';
import { PaymentNavigationService } from './payment/services/payment-navigation.service';
import { PaymentSuccessService } from './payment/services/payment-success.service';
import { PaymentTaggingService } from './payment/services/payment-tagging.service';
import { PaymentTrayConfigService } from './payment/services/payment-tray-config.service';
import { VProductsPurchaseService } from './products-and-services/v-products-purchase/v-products-purchase.service';
import { SmartpayLandingModule } from './shared/components/smartpay-landing/smartpay-landing.module';
import { CampaignPromotionsService } from './shared/services/campaign-promotions.service';
import { CommercialPaymentService } from './shared/services/commercial-payment.service';
import { MicroFlowsService } from './shared/services/micro-flows.service';
import { Mva10FullModslServiceService } from './shared/services/mva10-full-modsl-service.service';
import { Mva10SubHeaderService } from './shared/services/mva10-sub-header.service';
import { NexusService } from './shared/services/nexus.service';
import { NotificationBadgeService } from './shared/services/notification-badge.service';
import { PaymentDataService } from './shared/services/payment-data.service';
import { PaymentWalletService } from './shared/services/payment-wallet.service';
import { PurchasingService } from './shared/services/purchasing.service';
import { VerifyEmailService } from './shared/services/verify-email.service';
import { AppAvailabilityCheckInterceptor } from './shared/utils/app-availability-check-interceptor.service';
import { AppAvailabilityCheckService } from './shared/utils/app-availability-check.service';
import { SmartPayService } from './smart-pay/services/smart-pay.service';
import { SmartPayModule } from './smart-pay/smart-pay.module';
import { SpMiddlePageModule } from './sp-middle-page/sp-middle-page.module';

@NgModule({
	declarations: [
		AppComponent,
		PaymentAuthenticationComponent,
		PaymentBillingComponent,
		PaymentOtpComponent,
		PaymentDisclaimerComponent,
		FinancedDevicesCancellationComponent,
		TopUpCountDownComponent,
		SpendNewBalanceComponent,
	],
	imports: [
		BrowserModule,
		BrowserAnimationsModule,
		FormsModule,
		MdlModule,
		ReactiveFormsModule,
		HttpClientModule,
		CoreMVAModule,
		SharedModule,
		TranslateModule.forRoot({
			loader: {
				provide: TranslateLoader,
				useFactory: createTranslateLoader,
				deps: [HttpClient],
			},
		}),
		UpgradeProfileModule,
		CookieModule.forRoot(),
		AppRoutingModule,
		LegalModule,
		CoreModule,
		HttpClientJsonpModule,
		GenericModule,
		RecaptchaV3Module,
		OverlayFullScreenModule,
		OverlayModule,
		SelectorBadgeModule,
		RouterModule,
		SmartPayModule,
		SpMiddlePageModule,
		SmartpayLandingModule,
		AdaraModule,
	],
	providers: [
		DeviceDetectorService,
		{ provide: RECAPTCHA_V3_SITE_KEY, useValue: environment.googleToken },
		{
			provide: RECAPTCHA_BASE_URL,
			useValue: googleEnterprise,
		},
		CookieHelper,
		LoggerService,
		{ provide: ErrorHandler, useClass: GeneralErrorHandler, deps: [LoggerService] },
		HttpClient,
		{
			provide: APP_BASE_HREF,
			useValue: baseURL,
		},
		CustomerBillService,
		{
			provide: HTTP_INTERCEPTORS,
			useClass: CustomHttpInterceptor,
			multi: true,
		},
		{
			provide: HTTP_INTERCEPTORS,
			useClass: CacheInterceptor,
			multi: true,
		},
		{
			provide: HTTP_INTERCEPTORS,
			useClass: TaggingInterceptor,
			multi: true,
		},
		{
			provide: HTTP_INTERCEPTORS,
			useClass: AppAvailabilityCheckInterceptor,
			multi: true,
		},
		SiteHandlingService,
		TealiumUtagService,
		NotificationService,
		CustomerAccountService,
		SidemenuConfigService,

		DashboardResolve,
		CompanyService,
		SpAccordionService,
		ProductsAndServicesService,
		AppService,
		MyAccountService,
		ProductService,
		RecommendationService,
		TryAndBuyService,
		OpProductsListService,
		DocumentumService,
		CommercialOffersService,
		CheckoutCommercialService,
		TariffService,
		CrossFunctionalityService,
		MdlSnackbarService,
		MdlDialogOutletService,
		UtilitiesService,
		CookieService,
		ServiceSettingsService,
		ServiceSettingsData,
		LineServicesService,
		LineServicesData,
		FaultManagementService,
		FaultManagementData,
		NotificationInboxService,

		MyAccountResolve,
		MyAccountDataResolver,
		DeepLinkingService,

		DashboardService,
		CreditBalanceService,
		GrifoTimeService,
		TopUpService,
		TopUpDataService,
		PermissionsAndPreferencesResolver,

		RetryService,
		ProductsAndServicesResolve,

		ImpersonationService,
		LoginService,
		UserSettingsService,
		ContactUsService,

		CampaignService,

		ProductConfigurationService,
		CanjeService,
		DmpService,

		SpMva10OverlayService,

		PurchaseProductsService,
		PurchaseProductsData,
		BillingVf10Service,
		BillingVf10Data,

		Mva10FullOverlayService,
		ResizeService,
		BubbleHelpTaggingService,
		AditionalLinesService,
		TopUpNavigationService,

		SuperwifiService,
		BubbleHelpTaggingService,

		FteServiceService,
		CommercialMobileService,
		CommercialTerminalKeywordsService,
		CommercialMobileDetailService,
		TopupPrepaidErrorOVerlayDataService,
		TryAndBuySetTaggingService,
		OpSetTaggingService,
		Mva10TopupLandingWithPciComponent,

		AllowedServicesService,
		DelightPromotionService,
		EntryPointsService,
		TiendaV10Service,
		LeftMenuWs10Service,
		DynamicTrayService,
		Mva10SubHeaderService,
		DecimalPipe,
		ServiceBalanceWs10Service,
		FederationErrorService,
		VProductsPurchaseService,
		Mva10FullModslServiceService,

		AppAvailabilityCheckService,
		TicketsboxService,

		PurchasingService,
		BuySimService,
		BuySimDataService,
		NormalizeAddressService,
		VerifyEmailService,
		PaymentTrayConfigService,
		PaymentNavigationService,
		PaymentIframeService,
		PaymentTaggingService,
		PaymentDataService,
		PaymentErrorService,
		PaymentSuccessService,
		DelegateCreationService,
		PaymentCancelService,
		PaymentTopupLandingComponent,
		NexusService,
		RoamingHelper,
		MicroFlowsService,

		BuySimDataHistoryService,
		PaymentWalletService,
		CampaignPromotionsService,
		SmartPayPaymentService,
		NotificationBadgeService,

		CommercialPaymentService,
		SmartPayService,
	],
	bootstrap: [AppComponent],
})
export class AppModule {}
