import { Component, EventEmitter, OnDestroy, OnInit, Output, ViewChild } from '@angular/core';
import {
	AbstractControl,
	FormArray,
	FormControl,
	UntypedFormControl,
	UntypedFormGroup,
	ValidatorFn,
	Validators,
} from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { OverlayModel } from '@mva10/mva10-angular';
import { TranslateService } from '@ngx-translate/core';
import { AnimationOptions } from 'ngx-lottie';
import { Subject, Subscription } from 'rxjs';
import { StorageService } from 'src/app/core/services/storage.service';
import { UtilitiesService } from 'src/app/shared/utils/utilities.service';
import { config } from '../../../config/pages-config';
import { tagging } from '../../../config/tagging-config';
import { ConfigurationService } from '../../core/services/configuration.service';
import { TaggingHelperService } from '../../core/services/tagging.helper.service';
import { SpModalFullComponent } from '../../shared/components/sp-modal-full/sp-modal-full.component';
import {
	ChatText,
	ExitButtonText,
	FIJO,
	FaultManagementTemplateButtonClasses,
	Template9BtnColors,
	Validation,
	YouKnowConst,
	backButton,
	faultFromTobiTaskId,
	faultHyperCareInformation,
	formItemNames,
	home5G,
	internet,
	journeyButtonActionOpenChat,
	journeyStatuseEnum,
	keepWaitingKeys,
	stringOfFalse,
	stringOfTrue,
	taggingInitialStates,
	template10Text,
	template10Textarea,
	template11ButtonHide,
	template11ButtonVisible,
	templateFormIdTextTitle,
	ticketButtonActionSolved,
	tv,
} from '../../shared/constants/defines';
import { AnalyticsTypeString } from '../../shared/enums/analyticsTypeString.enum';
import { FaultManagmentServiceInterActionName } from '../../shared/enums/faultManagmentServiceInterActionName.enum';
import { InteractionsId } from '../../shared/enums/interactionsId.enum';
import { NeedBubbleHelpTabs } from '../../shared/enums/need-bubble-help-tabs.enum';
import { ServiceType } from '../../shared/enums/serviceType.enum';
import { TemplateTextMedia } from '../../shared/enums/templateTextMedia.enum';
import { TemplateType } from '../../shared/enums/templateType.enum';
import { TicketJourney, TicketStatus, TicketTemplateCode } from '../../shared/enums/ticket-status.enum';
import { template11ChatRecordedModel } from '../../shared/models/defines.model';
import { OpenNewTicket, TicketInput } from '../../shared/models/openTicket.model';
import { ServiceModel } from '../../shared/models/service.model';
import { FormItem, Template, TemplateCheckbox } from '../../shared/models/template.model';
import { ChatRecorded, TicketModel } from '../../shared/models/ticket.model';
import { SidemenuConfigService } from '../../shared/services/sidemenu-config.service';
import { UserSettingsService } from '../../shared/services/user-settings.service';
import { Mva10LandingPageComponent } from '../mva10-landing-page/mva10-landing-page.component';
import { AppService } from './../../app.service';
import { TemplateFormDeeplink, TemplateFormType, TemplateStatus } from './../../shared/enums/templateStatus.enum';
import { FaultManagementService } from './../fault-management.service';
import { SpecificTemplatesTaggingService } from './specific-tagging.service';
import { TemplatesService } from './templates.service';
import { templateTenCheckboxValidator } from './validators/templateTenCheckbox.validator';

@Component({
	selector: 'sp-templates',
	templateUrl: './templates.component.html',
	styleUrls: ['./templates.component.scss'],
})
export class TemplatesComponent implements OnInit, OnDestroy {
	static chatParti: boolean = false;
	private platformInfo: string;
	private typeConnection: string;
	public innerTextQuestion: string;
	public innerText: string;
	private isTipsActive: boolean;
	private tipsInterval: number;
	private openChatSubject: Subject<boolean> = new Subject<boolean>();
	private callOpenChatSubject: boolean = false;
	private keepWaiting: Subject<number> = new Subject<number>();
	keepWaitingProgresBarText: string;
	keepWaitingText: string;
	@ViewChild('fullModal') modal: SpModalFullComponent;
	templateNumber: string;
	@Output() showLoading = new EventEmitter<boolean>();
	newTicket: OpenNewTicket;
	openNewTicket: OpenNewTicket;
	inputs: TicketInput;
	template: Template;
	templateForm: UntypedFormGroup;
	showTemplateOne: boolean;
	showTemplateTwo: boolean;
	showTemplateThree: boolean;
	showTemplateFour: boolean;
	showTemplateFive: boolean;
	showTemplateSix: boolean;
	showTemplateSeven: boolean;
	showTemplateEight: boolean;
	showTemplateNine: boolean;
	showTemplateTen: boolean;
	showTemplateEleven: boolean;
	showTemplatePopup: boolean;
	templateTextWithId: boolean;
	loading: boolean;
	firstLoading: boolean;
	error: boolean;
	spinnerWithText: boolean;
	spinnerWithTips: boolean;
	customerAccountId: string = '';
	modalTitle: string = '';
	modalDesc: string = '';
	modalIcon: string = '';
	modalBtn1: string = '';
	modalBtn2: string = '';
	selectedService: ServiceModel;
	selectedServiceName: string;
	selectedServiceNameText: string;
	otherServiceName: string;
	MSISDNError: boolean;
	pattern: string;
	showErrorMsg: boolean;
	templateTextWithMedia: TemplateTextMedia[] = [];
	cellDateValidator: string = TemplateFormType.celldate.toLocaleLowerCase();
	templateTenTextarea: string = template10Textarea;
	templateTenText: string = template10Text;
	template9BtnColors: Record<string, string> = Template9BtnColors;
	faultManagementTemplateButtonClasses: Record<string, string> = FaultManagementTemplateButtonClasses;
	maxDate = new Date();
	minDate = new Date(new Date().getFullYear() - 3, new Date().getMonth(), new Date().getDate());
	lastText: { id: string; value: string; size: string };
	image = [];
	imageKey: string[];
	formInputs: any;
	isDateValidDate: boolean = false;
	inputTypeIsDate: boolean = false;
	actions: any;
	service: any;
	formItems: FormItem[] | null;
	@Output() back = new EventEmitter();
	serviveTypeTvOrInternet: boolean = false;
	lottieConfig: AnimationOptions;
	anim: any;
	headerExitBonitaData = new OverlayModel();
	exitBonitaOverlayUiData = {
		description: '',
		icon: '',
		buttonText: '',
		button2Text: '',
	};
	closeBubbleSubscription: Subscription;
	chatTabOpened: boolean = false;
	backButton: string = backButton;
	leftIcon: {
		iconRef: string;
		iconTitle: string;
	} = { iconRef: 'icon-arrow-left', iconTitle: backButton };
	rightIcon: {
		iconRef: string;
		iconTitle: string;
	} = { iconRef: 'icon-close', iconTitle: 'quit' };
	selectedServiceInteractionName: string;
	newTaggingObject: any;
	slides: any[] = [];
	positionIndex: number = 0;
	isTextRequired: boolean = false;
	// public carouselConfig: string =
	// '{"responsive":{"0":{"breakpoint":0,"elements":1}, "1":{"breakpoint":769,"elements":1}, "2":{"breakpoint":1025,"elements":1}},' +
	// '"build":{"arrows":false, "bullets":true, "clickable_bullets":true}}';
	// Ejemplo
	public carouselConfig: string =
		'{"responsive":{"0":{"breakpoint":0,"elements":1}, "1":{"breakpoint":769,"elements":1, "arrows":true}, "2":{"breakpoint":1025,"elements":1,' +
		'"arrows":true}}, "build":{"arrows":false, "bullets":true, "clickable_bullets":true}}';
	spinnerWithSteps: boolean;
	stepText: string;
	stepNumber: number = 0;
	startTimeHFC: number;
	startTimeReboot: number;
	responseTimeHFC: number;
	waitingProgressbarText: string;
	waitingText: string;
	isKeepWaitingHFC: boolean = false;
	isKeepWaitingReboot: boolean = false;
	isTipsWaitingHFC: boolean = false;
	newSteps: boolean = false;
	userName: string = '';
	readonly ServiceType = ServiceType;
	readonly InteractionId = InteractionsId;
	chatMessages: ChatRecorded[] = [];
	template11Ticket: TicketModel;
	t11ChatRecordedWCS: template11ChatRecordedModel;
	templateTicketId: string = '';
	t11BtnVisible: string = template11ButtonVisible;
	t11BtnHide: string = template11ButtonHide;
	formItemsNames: Record<string, string> = formItemNames;
	isWaitingSuperWifiCheck: boolean = false;
	hasSuperWifiProblems: boolean = false;
	constructor(
		public faultManagementService: FaultManagementService,
		private translate: TranslateService,
		public appService: AppService,
		private route: Router,
		private tagging: TaggingHelperService,
		private sidemenuConfigService: SidemenuConfigService,
		private userSettingsService: UserSettingsService,
		private specificTemplatesTaggingService: SpecificTemplatesTaggingService,
		private utilitiesService: UtilitiesService,
		private storageService: StorageService,
		public templatesService: TemplatesService,
		private activatedRoute: ActivatedRoute,
		private configurationService: ConfigurationService
	) {}

	ngOnInit() {
		this.isTipsActive = false;
		this.appService.openBubbleStopScroll = true;
		this.faultManagementService.showToast = false;
		this.selectedService = this.faultManagementService.selectedService;
		this.selectedServiceName = this.faultManagementService.selectedServiceName;
		this.firstLoading = true;
		this.customerAccountId = this.faultManagementService.selectedSiteId;
		this.error = false;
		this.userName = this.utilitiesService.capitalizeText(this.storageService.userProfile.firstName);
		this.setServiceName();
		this.serviveTypeTvOrInternet = this.templatesService.serviveTypeTvOrInternet(this.selectedService.type);

		tagging.faultManagement.createNewFault.start.data.journey_subcategory = this.selectedServiceName;
		this.tagging.track(
			tagging.faultManagement.createNewFault.start.eventName,
			tagging.faultManagement.createNewFault.start.data
		);
		this.lottieConfig = {
			path: 'https://assets-es-pprd.dxlpreprod.local.vodafone.es/assets/loader-med.json',
			autoplay: true,
			loop: true,
		};
		this.translate.get('faultManagement').subscribe((data) => {
			this.headerExitBonitaData.title = data.title;
			this.exitBonitaOverlayUiData.icon = this.appService.getImgFullPath(
				data.messagesList.leaveBonitaFlowMsg.leaveBonitaFlowMsg_icon.url
			);
			this.exitBonitaOverlayUiData.description = data.messagesList.leaveBonitaFlowMsg.leaveBonitaFlowMsg_description;
			this.exitBonitaOverlayUiData.buttonText = data.newTickets.messagesList.backOverlay.button1.text;
			this.exitBonitaOverlayUiData.button2Text = data.newTickets.messagesList.backOverlay.button2.text;
		});
		this.faultManagementService.startExitBonitaFlowSubject.subscribe(() => {
			this.tagging.view(tagging.exitBonitaFlow);
		});
		this.getPlatformInfo();
		this.typeConnection = this.templatesService.getConnectionType();
		if (this.templatesService.startTemplate) {
			this.firstLoading = false;
			this.showTemplateEleven = false;
			this.spinnerWithSteps = false;
			this.templateNumber = this.templatesService.startTemplate.form?.template;
			this.checkTemplateNum(this.templateNumber);
			this.template = this.templatesService.startTemplate;
			this.addTemplateTextAndMedia();
			this.setTemplateFormInputsAndActions();
		}
		if (!this.showTemplateEleven && !this.templatesService.startTemplate) {
			const newTicket: OpenNewTicket = this.templatesService.setBodyOfTemplateReq(
				0,
				this.selectedService.id,
				this.selectedServiceName,
				TemplateStatus.start.toLocaleLowerCase(),
				stringOfFalse,
				this.platformInfo,
				this.typeConnection,
				this.template,
				this.templateForm,
				this.templatesService.inputsOnStartTemplate
			);
			this.getNextTemplate(newTicket);
			this.newTicket = newTicket;
		} else {
			this.loading = false;
			this.firstLoading = false;
		}
		this.keepWaiting.subscribe((step: number) => {
			this.spinnerWithSteps = true;
			this.stepNumber = step;
			if (this.keepWaitingProgresBarText) {
				this.translate
					.get(`faultManagement.itemsList.loadingScreen.contentList.${this.keepWaitingProgresBarText}${step}.body`)
					.subscribe((data) => {
						this.waitingProgressbarText = data;
					});
			}
			this.translate
				.get(`faultManagement.itemsList.loadingScreen.contentList.${this.keepWaitingText}${step}.body`)
				.subscribe((data) => {
					this.waitingText = data;
				});
			if (this.isTipsWaitingHFC || this.isKeepWaitingReboot) {
				switch (step) {
					case 1:
					case 2:
						setTimeout(() => {
							this.keepWaiting.next(step + 1);
						}, this.startTimeReboot);
						break;
					case 3:
						setTimeout(() => {
							this.inputs = new TicketInput();
							this.inputs.name = this.template.form.actions[0].name;
							this.inputs.value = this.template.form.actions[0].values[0].value;
							this.newTicket.inputs[0] = this.inputs;
							this.newTicket.taskId = this.template.taskId.toString();
							this.getNextTemplate(this.newTicket);
							this.stepNumber = 0;
							this.waitingText = '';
							this.waitingProgressbarText = '';
							this.keepWaitingProgresBarText = '';
							this.keepWaitingText = '';
							this.isTipsWaitingHFC = false;
							this.isKeepWaitingReboot = false;
						}, this.startTimeReboot);
						break;
					default:
						break;
				}
			}
			this.keepWaitingStep(step);
		});
	}

	/**set initial value for templates  */
	initiateTemplate() {
		this.showTemplateOne = false;
		this.showTemplateTwo = false;
		this.showTemplateThree = false;
		this.showTemplateFour = false;
		this.showTemplateFive = false;
		this.showTemplateSix = false;
		this.showTemplateSeven = false;
		this.showTemplateEight = false;
		this.showTemplateNine = false;
		this.showTemplateTen = false;
		this.showTemplateEleven = false;
		this.showTemplatePopup = false;
	}

	setServiceName(): void {
		if (!this.selectedServiceName) {
			this.translate.get('faultManagement.contentList.faultMngmtCategories.categories').subscribe((item) => {
				switch (this.selectedService.type.toLocaleLowerCase()) {
					case ServiceType.Mobile.toLocaleLowerCase():
					case ServiceType.MbbPostpaid.toLocaleLowerCase():
					case ServiceType.MbbPrepaid.toLocaleLowerCase():
					case ServiceType.Postpaid.toLocaleLowerCase():
					case ServiceType.Prepaid.toLocaleLowerCase():
					case ServiceType.MBB_Holiday.toLowerCase():
						if (this.selectedService.tarrifCode === home5G.tariffCode) {
							this.selectedServiceName = item.mobile.mobile_label;
							this.selectedServiceNameText = item.internet.internet_label;
							this.selectedServiceInteractionName = FaultManagmentServiceInterActionName.Internet;
						} else {
							this.selectedServiceName = item.mobile.mobile_label;
							this.selectedServiceNameText = item.mobile.mobile_label;
							this.selectedServiceInteractionName = FaultManagmentServiceInterActionName.Mobile;
						}
						break;
					case ServiceType.Tv.toLocaleLowerCase():
					case ServiceType.TV_online.toLowerCase():
						this.selectedServiceName = item.tv.tv_label;
						this.selectedServiceNameText = item.tv.tv_label;
						this.selectedServiceInteractionName = FaultManagmentServiceInterActionName.TV;
						break;
					case ServiceType.Fixed.toLocaleLowerCase():
					case ServiceType.VodafoneEnTuCasa.toLocaleLowerCase():
					case ServiceType.Landline.toLocaleLowerCase():
						this.selectedServiceName = item.fixed.fixed_label;
						this.selectedServiceNameText = item.fixed.fixed_label;
						this.selectedServiceInteractionName = FaultManagmentServiceInterActionName.Fixed;
						break;
					case ServiceType.Internet.toLocaleLowerCase():
					case ServiceType.ADSL.toLocaleLowerCase():
					case ServiceType.Fibre.toLocaleLowerCase():
						this.selectedServiceName = item.internet.internet_label;
						this.selectedServiceNameText = item.internet.internet_label;
						this.selectedServiceInteractionName = FaultManagmentServiceInterActionName.Internet;
						break;
					case ServiceType.Others.toLocaleLowerCase():
						this.selectedServiceName = ServiceType.Others;
						this.selectedServiceNameText = ServiceType.Others;
						this.otherServiceName = this.translate.instant('v10.faultManagement.contentList.dynamicLanding.FE.body');
						this.selectedServiceInteractionName = FaultManagmentServiceInterActionName.Others;
						break;
					case ServiceType.chat_recorded.toLocaleLowerCase():
						this.selectedServiceName = ServiceType.chat_recorded;
						this.selectedServiceNameText = ServiceType.chat_recorded;
						this.showTemplateEleven = true;
						const ticketId: string = this.activatedRoute.snapshot.queryParams.ticketId;
						this.templateTicketId = ticketId;
						this.template11Ticket = this.faultManagementService.tickets.find((t) => t.ticketId === ticketId);
						if (this.template11Ticket.journey) {
							this.t11ChatRecordedWCS = this.translate.instant(
								`v10.faultManagement.messagesList.templates.t11chatRecorded.${this.template11Ticket.journey?.toLocaleLowerCase()}`
							);
						}
						this.chatMessages = this.template11Ticket.chatRecorded || [];
						break;
					default:
						break;
				}
			});
		}
	}

	keepWaitingStep(step: number): void {
		if (this.isKeepWaitingHFC) {
			switch (step) {
				case 1:
					setTimeout(() => {
						this.keepWaiting.next(step + 1);
					}, this.startTimeHFC);
					break;
				case 2:
					setTimeout(() => {
						this.getNextTemplate(this.newTicket);
					}, this.startTimeHFC);
					break;
				case 3:
					setTimeout(() => {
						this.keepWaiting.next(step + 1);
					}, this.responseTimeHFC || 2000);
					break;
				case 4:
					setTimeout(() => {
						this.getNextTemplate(this.newTicket);
						this.stepNumber = 0;
						this.waitingText = '';
						this.waitingProgressbarText = '';
						this.keepWaitingProgresBarText = '';
						this.keepWaitingText = '';
						this.isKeepWaitingHFC = false;
					}, this.responseTimeHFC || 2000);
					break;
				default:
					break;
			}
		}
	}

	checkTemplateNum(templateNumber: string): void {
		this.initiateTemplate();
		switch (templateNumber) {
			case TemplateType.one:
				this.showTemplateOne = true;
				break;
			case TemplateType.two:
				this.showTemplateTwo = true;
				break;
			case TemplateType.three:
				this.setFormInputs();
				this.showTemplateThree = true;
				break;
			case TemplateType.four:
				this.showTemplateFour = true;
				break;
			case TemplateType.five:
				this.showTemplateFive = true;
				this.sidemenuConfigService.bubbleHelpConfigSubject.next();
				break;
			case TemplateType.six:
				this.showTemplateSix = true;
				break;
			case TemplateType.seven:
				this.showTemplateSeven = true;
				break;
			case TemplateType.eight:
				this.showTemplateEight = false;
				break;
			case TemplateType.nine:
				this.showTemplateNine = true;
				break;
			case TemplateType.ten:
				this.showTemplateTen = true;
				this.setFormItemInputs();
				break;
			case TemplateType.eleven:
				this.showTemplateEleven = true;
				break;
			case TemplateType.popup:
				this.showTemplatePopup = true;
				break;
			default:
				this.showTemplateOne = true;
		}
		this.loading = false;
		this.error = false;
	}

	/**
	 * @param label is currently being used as string and as FormGroup..¿?
	 */
	confirmBtn(label: any, value?: string): void {
		/**show overlay with spinner if not template 4 */
		this.loading = true;
		if (value) {
			this.spinnerWithText = +this.template.form.template === 4 && value !== ExitButtonText.exit ? true : false;
			this.spinnerWithTips = true;
		}
		this.getNextTemplate(
			(this.newTicket = this.templatesService.setBodyOfTemplateReq(
				label,
				this.selectedService.id,
				this.selectedServiceName,
				this.template.taskId.toString(),
				'true',
				this.platformInfo,
				this.typeConnection,
				this.template,
				this.templateForm
			))
		);
	}

	getNavBtnTemplate10NextTemplate(id: string, label: string, value: string): void {
		this.loading = true;
		this.templateForm.patchValue({ [id]: value });
		const newTicket: OpenNewTicket = this.templatesService.setBodyOfTemplateReq(
			label,
			this.selectedService.id,
			this.selectedServiceName,
			this.template.taskId.toString(),
			'true',
			this.platformInfo,
			this.typeConnection,
			this.template,
			this.templateForm
		);
		this.newTicket = newTicket;
		this.getNextTemplate(this.newTicket);
	}

	confirmBtnTempFive(index: number, label: string, value?: string): void {
		this.loading = true;
		this.callOpenChatSubject = true;
		if (this.template.form.chatParti) {
			index ? (index = 0) : (index = 1);
		}
		if (value) {
			this.spinnerWithText = +this.template.form.template === 5 && value === ChatText.chat.toUpperCase() ? true : false;
			this.innerTextQuestion = '';
			this.innerText = '';
			this.spinnerWithTips = false;
		}
		if (index === 0) {
			const analytics: string = this.template.form.analytics;
			const averia: string = this.template.form.averia;
			this.openChatSubject.subscribe(() => {
				this.taggingInitialState(taggingInitialStates.chat, taggingInitialStates.chat);
				this.openChat(analytics, averia);
				this.taggingInitialState(taggingInitialStates.chatTicket, taggingInitialStates.chatTicket);
			});
			this.getNextTemplate(
				(this.newTicket = this.templatesService.setBodyOfTemplateReq(
					label,
					this.selectedService.id,
					this.selectedServiceName,
					this.template.taskId.toString(),
					'true',
					this.platformInfo,
					this.typeConnection,
					this.template,
					this.templateForm
				))
			);
		} else if (index === 1) {
			this.taggingInitialState(taggingInitialStates.call, taggingInitialStates.call);
			this.confirmBtn(label);
		}
	}

	getTipText(product: string): void {
		let tipsArr: string[] = [];
		let tipsArrBackup: string[] = [];
		let index: number;
		this.translate.get(`faultManagement.itemsList.loadingScreen.contentList.${product}`).subscribe((data: string[]) => {
			data.forEach((phrase: string) => {
				tipsArr.push(phrase.substring(21));
			});
			tipsArrBackup = [...tipsArr];
			index = Math.floor(Math.random() * tipsArr.length);
			this.innerText = tipsArr[index];
			this.innerTextQuestion = YouKnowConst.youKnow;
			this.tipsInterval = window.setInterval(() => {
				tipsArr.splice(index, 1);
				if (tipsArr.length < 1) {
					tipsArr = [...tipsArrBackup];
				}
				this.innerText = tipsArr[Math.floor(Math.random() * tipsArr.length)];
			}, 5000);
		});
		setTimeout(() => {
			clearInterval(this.tipsInterval);
		}, 70000);
	}

	nextCallAfterContinue(setNewTicketValues?: boolean): void {
		if (setNewTicketValues && this.template?.form?.actions[0]) {
			this.inputs = new TicketInput();
			this.inputs.name = this.template.form.actions[0].name;
			this.inputs.value = this.template.form.actions[0].values[0]?.value;
			this.newTicket.inputs[0] = this.inputs;
			this.newTicket.taskId = this.template.taskId?.toString();
		}
		setTimeout(() => {
			this.isTipsActive = true;
			this.getNextTemplate(this.newTicket);
		}, 10000);
	}

	openChat(analytics: string, averia: string): void {
		const data = tagging.faultManagement.openChatBtn.data;
		const templateName: string = analytics;
		const pageName: string = tagging.staticVariables.pageNameTemplate.replace('<temp-name>', templateName);
		const event_name: string = tagging.faultManagement.openChatBtn.eventName.replace('<pagename>', pageName);
		tagging.openNewTicketTemplate.page_name = pageName;
		tagging.openNewTicketTemplate.page_subcategory_level_1 = templateName;
		tagging.openNewTicketTemplate.page_screen = templateName;
		this.tagging.track(event_name, data, tagging.openNewTicketTemplate);
		this.userSettingsService.averia = averia;
		this.userSettingsService.ticketId = this.template.ticketId;
		this.userSettingsService.journey = this.template.form.journey;
		this.userSettingsService.clientIdAveria = this.customerAccountId;
		this.userSettingsService.whatsAppPermissions = this.template.form.whatsAppPermissions;
		TemplatesComponent.chatParti = this.template.form.chatParti;
		this.chatTabOpened = true;
		this.sidemenuConfigService.openBubble(NeedBubbleHelpTabs.fault);
	}

	/**get next template  */
	getNextTemplate(ticket: OpenNewTicket) {
		this.formInputs = undefined;
		this.formItems = undefined;
		this.actions = undefined;
		this.templatesService.inputsOnStartTemplate = undefined;
		this.templatesService.startTemplate = undefined;
		this.faultManagementService.getTicketTemplate(this.customerAccountId, ticket).subscribe(
			(res: Template) => {
				this.template = res;
				let templateCheckbox: TemplateCheckbox[] | undefined = this.template.form?.checkbox;
				if (templateCheckbox) {
					templateCheckbox = templateCheckbox.map((t) => ({ ...t, checkboxValue: false }));
				}
				this.backButton = this.template?.form?.backButton || null;
				this.setTaggingState(res);
				if (this.newTicket?.inputs[0] && this.newTicket.inputs[0].value === ExitButtonText.exit) {
					this.faultManagementService.passBonitaFlow = true;
					this.faultManagementService.showOverlay = false;
					this.faultManagementService.resetSelectedSite_service();
					this.route.navigate([config.dashboard.route]);
					return;
				}
				if (this.template && this.template.form) {
					this.faultManagementService.faultManagementOpenNewTicketLoading = true;
					this.faultManagementService.templateName = this.template.form.analytics;
					const pageName: string = tagging.staticVariables.pageNameTemplate.replace(
						'<temp-name>',
						this.faultManagementService.templateName
					);
					tagging.openNewTicketTemplate.page_name = pageName;
					tagging.openNewTicketTemplate.page_subcategory_level_1 = this.faultManagementService.templateName;
					tagging.openNewTicketTemplate.page_screen = this.faultManagementService.templateName;
					this.tagging.view(tagging.openNewTicketTemplate);
					/**add text and media in one array for view and the first 2 index used for title and subtitle */
					this.addTemplateTextAndMedia();

					this.setTemplateFormInputsAndActions();
				}

				this.firstLoading = false;
				this.userSettingsService.clientIdAveria = this.customerAccountId;
				this.userSettingsService.whatsAppPermissions = this.template.form.whatsAppPermissions;

				/**check template status if OK or KO or Finished */
				if (this.template.status) {
					switch (this.template.status.toLocaleLowerCase()) {
						case TemplateStatus.continue.toLocaleLowerCase():
							this.setProductWhenTemplateStatusContinue();
							this.setKeepWaitingAndText();
							this.isWaitingSuperWifiCheck = this.template?.form?.analytics?.includes(
								AnalyticsTypeString.mensajeEspera_SW
							);
							break;

						case TemplateStatus.ok.toLocaleLowerCase():
							this.spinnerWithSteps = false;
							this.isWaitingSuperWifiCheck = false;
							this.templateNumber = this.template.form.template;
							this.checkTemplateNum(this.templateNumber);
							this.faultManagementService.previousTemplateAnalytics = this.template.form.analytics;

							break;

						case TemplateStatus.ko.toLocaleLowerCase():
							this.spinnerWithSteps = false;
							this.loading = false;
							this.error = true;
							this.setFailureText();
							this.tagging.view(tagging.bonitaFlowError);
							tagging.faultManagement.createNewFault.error.data.journey_options = this.template.form.analytics;
							tagging.faultManagement.createNewFault.error.data.journey_error_code = '';
							tagging.faultManagement.createNewFault.error.data.journey_error_category =
								this.tagging.setErrorTypeValue();
							tagging.faultManagement.createNewFault.error.data.journey_subcategory = ticket.serviceType;
							this.tagging.track(
								tagging.faultManagement.createNewFault.error.eventName,
								tagging.faultManagement.createNewFault.error.data
							);

							break;

						case TemplateStatus.finished.toLocaleLowerCase():
							this.spinnerWithSteps = false;
							this.faultManagementService.showToast = true;
							tagging.faultManagement.createNewFault.success.data.journey_options =
								this.faultManagementService.previousTemplateAnalytics;
							tagging.faultManagement.createNewFault.success.data.journey_subcategory = ticket.serviceType;
							this.tagging.track(
								tagging.faultManagement.createNewFault.success.eventName,
								tagging.faultManagement.createNewFault.success.data
							);
							this.faultManagementService.showOverlay = false;
							this.faultManagementService.passBonitaFlow = true;
							this.route.navigate([config.FaultManagement.route]);
							break;
					}
				}
				if (this.callOpenChatSubject) {
					this.openChatSubject.next(true);
					this.callOpenChatSubject = false;
				}
			},
			(error) => {
				this.template = { ...this.template, ...error };
				this.taggingInitialState(taggingInitialStates.error, taggingInitialStates.state);
				this.loading = false;
				this.firstLoading = false;
				this.error = true;
				this.setFailureText();
				this.tagging.view(tagging.bonitaFlowError);
				tagging.faultManagement.createNewFault.error.data.journey_options =
					this.template && this.template.form && this.template.form.analytics ? this.template.form.analytics : '';
				tagging.faultManagement.createNewFault.error.data.journey_error_code =
					error.error && error.error.ecode ? error.error.ecode : '';
				tagging.faultManagement.createNewFault.error.data.journey_error_description =
					error.error && error.error.description ? error.error.description : '';
				tagging.faultManagement.createNewFault.error.data.journey_error_category = this.tagging.setErrorTypeValue();
				tagging.faultManagement.createNewFault.error.data.journey_subcategory = ticket.serviceType;
				this.tagging.track(
					tagging.faultManagement.createNewFault.error.eventName,
					tagging.faultManagement.createNewFault.error.data
				);
			}
		);
	}

	setTaggingState(res: Template): void {
		if (this.newTicket?.taskId === journeyStatuseEnum.start) {
			this.taggingInitialState(taggingInitialStates.diagnostic, taggingInitialStates.state);
		} else if (res?.form?.analytics?.toLowerCase().includes(AnalyticsTypeString.interaccion)) {
			this.taggingInitialState(taggingInitialStates.solved, taggingInitialStates.state);
		} else if (
			res.form &&
			res.form.analytics &&
			res.form.analytics.toLowerCase().includes(AnalyticsTypeString.averia) &&
			res.form.analytics.toLowerCase().includes(AnalyticsTypeString.ok)
		) {
			this.taggingInitialState(taggingInitialStates.ticket, taggingInitialStates.state);
		} else {
			this.taggingInitialState(taggingInitialStates.steps, taggingInitialStates.state);
		}
	}

	addTemplateTextAndMedia(): void {
		if (this.template.form.texts && this.template.form.media) {
			this.template.form.texts.forEach((text) => {
				if (text.id === templateFormIdTextTitle) {
					this.templateTextWithId = true;
				}
			});
			const templateTextWithMediaAndLastText: [TemplateTextMedia[], { id: string; value: string; size: string }] =
				this.templatesService.templateTextWithMediaAndLastText(this.template.form);
			this.templateTextWithMedia = templateTextWithMediaAndLastText[0];
			this.lastText = templateTextWithMediaAndLastText[1];
			setTimeout(() => {
				this.addStyles();
			});
		} else {
			this.templateTextWithMedia = [];
		}
	}

	addStyles(): void {
		this.templateTextWithMedia.forEach((tempText: TemplateTextMedia, index: number) => {
			const elem: HTMLParagraphElement = document.querySelector(
				`.add-margin-bottom #templateComponentTemplateOneAndFourCardContentParagraph${index} p`
			);
			if (elem && tempText.size) elem.style.fontSize = `${tempText.size}px`;
		});
	}

	setTemplateFormInputsAndActions(): void {
		if (this.template.form.inputs) {
			this.formInputs = this.template.form.inputs;
		}

		if (this.template.form.actions && this.template.form.actions?.length) {
			this.actions = this.template.form.actions[0].values;
		}

		if (this.template.form.items) {
			this.formItems = this.template.form.items;
		}

		/** set content in slide array  */
		if (this.template.form.slide) {
			this.slides = [];
			this.template.form.slide.forEach((element) => {
				this.slides.push({
					valuesText1: element.valuesText[0]?.value,
					valuesText2: element.valuesText[1]?.value,
					valuesText3: element.valuesText[2]?.value,
					valuesText4: element.valuesText[3]?.value,
					valueImage1: element.valueImage1[0]?.reference.slice(0, -4),
					valueImage2: element.valueImage2[0]?.reference.slice(0, -4),
				});
			});
		}
	}

	setKeepWaitingAndText(): void {
		if (this.template?.form?.analytics?.includes(AnalyticsTypeString.esperareboot)) {
			this.spinnerWithText = false;
			this.isKeepWaitingReboot = true;
			this.startTimeReboot = Number(this.template.form.actions[1]?.values[0]?.value) * 1000;
			this.keepWaitingProgresBarText = keepWaitingKeys.rebootSolved_progressbar_text;
			this.keepWaitingText = keepWaitingKeys.rebootSolved_text;
			this.keepWaiting.next(1);
		} else if (this.template?.form?.analytics?.includes(AnalyticsTypeString.mensajeEspera_SCH)) {
			this.isKeepWaitingHFC = true;
			this.setWaitingHFC();
		} else if (this.template?.form?.analytics?.includes(AnalyticsTypeString.ticketsrepe)) {
			this.spinnerWithText = false;
			this.isTipsWaitingHFC = true;
			this.startTimeReboot = Number(this.template.form.actions[1]?.values[0]?.value) * 1000 || 10000;
			this.keepWaitingText = keepWaitingKeys.hfc_text;
			this.keepWaiting.next(1);
		} else if (this.template?.form?.analytics?.includes(AnalyticsTypeString.mensajeEspera_SW)) {
			this.isWaitingSuperWifiCheck = true;
			this.waitingText = this.translate.instant(
				'faultManagement.itemsList.loadingScreen.contentList.superWifi_text1.body'
			);
			this.nextCallAfterContinue(true);
		} else {
			this.nextCallAfterContinue();
		}
	}

	setProductWhenTemplateStatusContinue(): void {
		if (!this.isTipsActive) {
			let product: string;
			this.translate.get('faultManagement.contentList.faultMngmtCategories.categories').subscribe((item) => {
				switch (this.selectedServiceName) {
					case item.internet.internet_label:
						product = internet;
						break;
					case item.tv.tv_label:
						product = tv;
						break;
					case item.fixed.fixed_label:
						product = FIJO;
						break;
					default:
						break;
				}
				if (this.spinnerWithTips) {
					this.getTipText(product);
				}
			});
		}
	}

	setWaitingHFC(): void {
		if (this.template.form.analytics.includes(AnalyticsTypeString.MVL)) {
			this.keepWaitingProgresBarText = keepWaitingKeys.mvl_progressbar_text;
			this.keepWaitingText = keepWaitingKeys.mvl_text;
		} else {
			this.keepWaitingProgresBarText = keepWaitingKeys.hfc_progressbar_text;
			this.keepWaitingText = keepWaitingKeys.hfc_text;
		}
		if (this.template.form.actions[1]?.name === keepWaitingKeys.startTime) {
			this.spinnerWithText = false;
			this.startTimeHFC = Number(this.template.form.actions[1]?.values[0]?.value) * 1000;
			this.keepWaiting.next(1);
		}
		if (this.template.form.actions[1]?.name === keepWaitingKeys.responseTime) {
			this.responseTimeHFC = Number(this.template.form.actions[1]?.values[0]?.value) * 1000;
			this.keepWaiting.next(3);
		}
	}

	/**get WCS data in failure model  */
	setFailureText() {
		this.translate.get('faultManagement.messagesList.newFaultGeneralErrorMsg').subscribe((data) => {
			this.modalTitle = data.newFaultGeneralErrorMsg_title;
			this.modalDesc = data.newFaultGeneralErrorMsg_description;
			this.modalBtn1 = data.newFaultGeneralErrorMsg_button1.text;
			this.modalIcon = this.appService.getImgFullPath(data.newFaultGeneralErrorMsg_icon.url);
			tagging.faultManagement.createNewFault.error.data.journey_error_description =
				data.newFaultGeneralErrorMsg_description;
		});
		this.error = true;
	}

	failurConfirmBtn() {
		this.loading = false;
		this.error = false;
		this.faultManagementService.passBonitaFlow = true;
		history.back();
	}
	failureConfirmClose() {
		this.failurConfirmBtn();
		this.tagging.track(
			tagging.faultManagement.bonitaFlowErrorBtn.eventName,
			tagging.faultManagement.bonitaFlowErrorBtn.data,
			tagging.bonitaFlowError
		);
	}

	customBack(): void {
		this.faultManagementService.setShowSecondOverlay(false);
		this.faultManagementService.setShowThirdOverlay(false);
		if (this.error) {
			this.failurConfirmBtn();
		} else if (this.template.form.analytics.includes(AnalyticsTypeString.reboot1)) {
			this.taggingInitialState(taggingInitialStates.exit, taggingInitialStates.state);
			this.faultManagementService.setShowThirdOverlay(true);
			this.templatesService.setBooleansAndsetInteractionForMonitoring(
				InteractionsId.reboot,
				this.template.form.analytics,
				this.customerAccountId,
				this.selectedServiceInteractionName,
				this.selectedService.name,
				this.template.backend
			);
		} else if (
			this.template.form.analytics.includes(AnalyticsTypeString.reboot2) ||
			this.template.form.analytics.includes(AnalyticsTypeString.esperareboot)
		) {
			this.exitFlowWithoutPopup(InteractionsId.rebootWait, true);
		} else if (
			this.template.form.noPopup ||
			this.template.form.analytics.includes(AnalyticsTypeString.mensajeEspera_SCH)
		) {
			if (this.template.form.transformersOK) {
				this.taggingInitialState(taggingInitialStates.exit, taggingInitialStates.state);
				this.faultManagementService.showOverlay = false;
				this.faultManagementService.setShowSecondOverlay(true);
			}
			if (!this.template.form.transformersOK) {
				this.faultManagementService.showOverlay = false;
				this.faultManagementService.setShowSecondOverlay(false);
				this.exitFlowWithoutPopup(InteractionsId.keepWaiting, true);
			}
		} else {
			this.taggingInitialState(taggingInitialStates.exit, taggingInitialStates.state);
			this.faultManagementService.showOverlay = true;
			this.faultManagementService.setShowSecondOverlay(false);
		}
	}

	closeTemplateSixNavigate(): void {
		this.firstLoading = true;
		this.faultManagementService.showOverlay = false;
		this.faultManagementService.passBonitaFlow = true;
		if (this.template.form.analytics.toLowerCase().includes(AnalyticsTypeString.crearaveria)) {
			history.back();
		} else if (this.template.form.actions[0].values[0]?.deeplink === TemplateFormDeeplink.chatCasesIot) {
			this.translate.get(`v10.faultManagement.urls.${TemplateFormDeeplink.chatCasesIot}`).subscribe((data) => {
				window.open(data);
				this.route.navigate([config.dashboard.route]);
			});
		} else {
			this.exitFlowWithoutPopup(InteractionsId.keepWaiting, false);
		}
	}

	handleAnimation(anim: any) {
		this.anim = anim;
	}

	exitBonitaFlowWithProblemSolved() {
		this.taggingInitialState(taggingInitialStates.yes, taggingInitialStates.yes);
		const interactionObj: Record<string, InteractionsId> = {
			[ServiceType.Fibre.toLocaleLowerCase()]: InteractionsId.Fibre_Solved,
			[ServiceType.ADSL.toLocaleLowerCase()]: InteractionsId.Fibre_Solved,
			[ServiceType.Internet.toLocaleLowerCase()]: InteractionsId.Fibre_Solved,
			[ServiceType.InternetFibra.toLocaleLowerCase()]: InteractionsId.Fibre_Solved,
			[ServiceType.Tv.toLocaleLowerCase()]: InteractionsId.TV_Solved,
			[ServiceType.TvOnline.toLocaleLowerCase()]: InteractionsId.TV_Solved,
			[ServiceType.Fixed.toLocaleLowerCase()]: InteractionsId.Fijo_Solved,
			[ServiceType.VodafoneEnTuCasa.toLocaleLowerCase()]: InteractionsId.Fijo_Solved,
			[ServiceType.Landline.toLocaleLowerCase()]: InteractionsId.Fijo_Solved,
		};

		const templateName = this.template.form.analytics;
		const pageName: string = tagging.staticVariables.pageNameTemplate.replace('<temp-name>', templateName);
		tagging.openNewTicketTemplate.page_name = pageName;
		tagging.openNewTicketTemplate.page_subcategory_level_1 = templateName;
		tagging.openNewTicketTemplate.page_screen = templateName;
		this.tagging.track(
			tagging.faultManagement.leavingBonitaProblemSolved.eventName.replace('<pagename>', pageName),
			tagging.faultManagement.leavingBonitaProblemSolved.data,
			tagging.openNewTicketTemplate
		);
		this.route.navigate([config.dashboard.route]);
		this.firstLoading = true;
		let interactionId: InteractionsId = InteractionsId.solved;
		const templateTaskId: string = this.template?.taskId.toString();
		if (
			templateTaskId?.toLocaleLowerCase() === faultHyperCareInformation.toLocaleLowerCase() ||
			templateTaskId?.toLocaleLowerCase() === faultFromTobiTaskId.toLocaleLowerCase()
		) {
			if (this.selectedService.tarrifCode === home5G.tariffCode) {
				interactionId = InteractionsId.Hogar5G_Solved;
			} else {
				if (interactionObj[this.selectedService.type.toLocaleLowerCase()]) {
					interactionId = interactionObj[this.selectedService.type.toLocaleLowerCase()];
				}
			}
		}
		this.templatesService.setBooleansAndsetInteractionForMonitoring(
			interactionId,
			this.template.form.analytics,
			this.customerAccountId,
			this.selectedServiceInteractionName,
			this.selectedService.name,
			this.template.backend
		);
		this.faultManagementService.resetSelectedSite_service();
	}

	checkTransformersOK(): void {
		const interactionObj: Record<string, InteractionsId> = {
			[ServiceType.Fibre.toLocaleLowerCase()]: InteractionsId.Fibre_Not_Solved,
			[ServiceType.ADSL.toLocaleLowerCase()]: InteractionsId.Fibre_Not_Solved,
			[ServiceType.Internet.toLocaleLowerCase()]: InteractionsId.Fibre_Not_Solved,
			[ServiceType.InternetFibra.toLocaleLowerCase()]: InteractionsId.Fibre_Not_Solved,
			[ServiceType.Tv.toLocaleLowerCase()]: InteractionsId.TV_Not_Solved,
			[ServiceType.TvOnline.toLocaleLowerCase()]: InteractionsId.TV_Not_Solved,
			[ServiceType.Fixed.toLocaleLowerCase()]: InteractionsId.Fijo_Not_Solved,
			[ServiceType.Landline.toLocaleLowerCase()]: InteractionsId.Fijo_Not_Solved,
			[ServiceType.VodafoneEnTuCasa.toLocaleLowerCase()]: InteractionsId.Fijo_Not_Solved,
		};

		let interactionId: InteractionsId = InteractionsId.notSolved;
		const templateTaskId: string = this.template?.taskId.toString();
		if (
			templateTaskId?.toLocaleLowerCase() === faultHyperCareInformation.toLocaleLowerCase() ||
			templateTaskId?.toLocaleLowerCase() === faultFromTobiTaskId.toLocaleLowerCase()
		) {
			if (this.selectedService.tarrifCode === home5G.tariffCode) {
				interactionId = InteractionsId.Hogar5G_Not_Solved;
			} else {
				if (interactionObj[this.selectedService.type.toLocaleLowerCase()]) {
					interactionId = interactionObj[this.selectedService.type.toLocaleLowerCase()];
				}
			}
		}

		this.exitBonitaFlowWithProblemNotSolved(interactionId);

		if (this.template.form.transformersOK) {
			this.faultManagementService.setShowSecondOverlay(true);
		}
	}

	exitBonitaFlowWithProblemNotSolved(interactionId: InteractionsId) {
		this.taggingInitialState(taggingInitialStates.no, taggingInitialStates.no);
		const templateName = this.template.form.analytics;
		const pageName: string = tagging.staticVariables.pageNameTemplate.replace('<temp-name>', templateName);
		tagging.openNewTicketTemplate.page_name = pageName;
		tagging.openNewTicketTemplate.page_subcategory_level_1 = templateName;
		tagging.openNewTicketTemplate.page_screen = templateName;
		this.tagging.track(
			tagging.faultManagement.leavingBonitaProblemNotSolved.eventName.replace('<pagename>', pageName),
			tagging.faultManagement.leavingBonitaProblemNotSolved.data,
			tagging.openNewTicketTemplate,
			tagging.staticVariables.leavingBonitaNotSolved
		);
		this.faultManagementService.setShowSecondOverlay(false);
		this.faultManagementService.setShowThirdOverlay(false);
		history.back();
		this.firstLoading = true;
		this.templatesService.setBooleansAndsetInteractionForMonitoring(
			interactionId || InteractionsId.notSolved,
			this.template.form.analytics,
			this.customerAccountId,
			this.selectedServiceInteractionName,
			this.selectedService.name,
			this.template.backend
		);
	}

	getPlatformInfo() {
		return (this.platformInfo = navigator.platform);
	}

	topBarButtonsBehaviour(event: string): void {
		this.taggingInitialState(taggingInitialStates.backButton, taggingInitialStates.backButton);
		if (event === backButton) {
			this.confirmBtn(event);
			return;
		}
		!this.showTemplateSix ? this.customBack() : this.closeTemplateSixNavigate();
	}

	taggingInitialState(state: string, type: string): void {
		this.specificTemplatesTaggingService.startTaggingConfig(state, type, this.newTicket, this.template);
	}
	exitFlowWithoutPopup(interactionId: InteractionsId, sendInteraction: boolean): void {
		if (this.showTemplateSix) {
			this.route.navigate([config.dashboard.route]);
		} else {
			this.route.navigate([config.FaultManagement.LandingPage.route]);
		}
		if (sendInteraction) {
			this.firstLoading = true;
			this.templatesService.setBooleansAndsetInteractionForMonitoring(
				interactionId,
				this.template.form.analytics,
				this.customerAccountId,
				this.selectedServiceInteractionName,
				this.selectedService.name,
				this.template.backend
			);
		}
		this.faultManagementService.resetSelectedSite_service();
	}

	ngOnDestroy(): void {
		this.appService.openBubbleStopScroll = false;
		this.appService.closeNeedHelpBubble.next();
		if (this.closeBubbleSubscription) {
			this.closeBubbleSubscription.unsubscribe();
		}
		this.appService.openBubbleStopScroll = false;
		this.keepWaiting.next();
		this.keepWaiting.complete();
	}

	showVal(event: any): void {
		event.position === 0 ? (this.positionIndex = 1) : (this.positionIndex = 0);
	}

	dateChange(inputType: Template['form']['inputs'][0], date: Date): void {
		this.templateForm?.patchValue({
			[inputType.name]: date.toLocaleDateString('en-GB'),
		});
		this.dateValidation(inputType);
	}

	dateValidation(inputType: Template['form']['inputs'][0]): void {
		if (inputType.validator?.toLocaleLowerCase() === TemplateFormType.celldate.toLocaleLowerCase()) {
			this.inputTypeIsDate = true;
			this.isDateValidDate = Validation.dateRexp.test(this.templateForm.value.comentario);
		} else {
			this.inputTypeIsDate = false;
		}
	}

	setFormInputs(): void {
		this.isTextRequired = false;
		const tempForm: UntypedFormGroup = new UntypedFormGroup({});
		if (this.formInputs) {
			let control: UntypedFormControl;
			for (const field of this.formInputs) {
				control = new UntypedFormControl(
					this.formInputs[field],
					field.required === stringOfTrue ? Validators.required : null
				);
				tempForm.addControl(field.name, control);
				this.templateForm = tempForm;
				if (field.required === stringOfTrue) {
					this.isTextRequired = true;
				}
				this.templatesService.setValidators(field);
				this.addPatternValidatorToInput(field.validator, control);
				this.addRequiredToInput(field.required, control);
			}
		}
	}

	/**
	 * Adds a pattern validator to the given form control based on the specified pattern name.
	 *
	 * @param {string} patternName - The name of the pattern to use for validation.
	 * If no patternName is provided, the method will return without adding any validators.
	 * @param {UntypedFormControl} control - The form control to which the validator will be applied.
	 *
	 * @example
	 * // Adds a date pattern validator to the form control if the patternName is "celldate".
	 * addPatternValidatorToInput('celldate', myFormControl);
	 *
	 * @remarks
	 * The method currently only supports a "celldate" pattern, which adds a validator for a date
	 * format specified by `Validation.dateRexp`. More patterns can be added to the switch statement
	 * as needed.
	 */
	addPatternValidatorToInput(patternName: string, control: UntypedFormControl) {
		if (patternName === TemplateFormType.celldate.toLocaleLowerCase()) {
			control.setValidators([Validators.pattern(Validation.dateRexp)]);
		}
	}

	/**
	 * Adds a "required" validator to the given form control based on the specified string boolean value.
	 *
	 * @param {string} stringBoolean - A string representing a boolean value.
	 * If the value matches `stringOfTrue`, the "required" validator will be added to the form control.
	 * @param {UntypedFormControl} control - The form control to which the "required" validator will be applied.
	 *
	 * @example
	 * // Adds a required validator to the form control if stringBoolean equals stringOfTrue.
	 * addRequiredToInput('true', myFormControl);
	 *
	 * @remarks
	 * This method assumes that `stringOfTrue` is a predefined constant in the current scope
	 * representing the string value of `true`. If `stringBoolean` does not match `stringOfTrue`,
	 * the method exits without modifying the form control.
	 */
	addRequiredToInput(stringBoolean: string, control: UntypedFormControl) {
		if (stringBoolean !== stringOfTrue) return;

		control.addValidators([Validators.required]);
	}

	/**
	 * Detects changes from an input event and updates the corresponding form control with the new value.
	 *
	 * @param {Event} target - The event object, specifically the target element from which the value is extracted.
	 * It is expected that `target` contains a `value` property, which holds the current value of the input element.
	 * @param {string} controlName - The name of the form control in `templateForm` that will be updated with the new value.
	 *
	 * @example
	 * // Updates the form control named 'email' with the new value from an input event.
	 * detectInputChangesFromFormControl(event.target, 'email');
	 *
	 * @remarks
	 * This method assumes that `templateForm` is a form group already defined in the component and that it contains
	 * a form control with the name provided by `controlName`. The form control's value is updated directly using
	 * the `setValue` method.
	 */
	detectInputChangesFromFormControl({ target }, controlName) {
		this.templateForm.get(controlName).setValue(target.value);
	}

	setFormItemInputs(): void {
		const tempForm: UntypedFormGroup = new UntypedFormGroup({});

		if (this.template.form && this.template.form.items) {
			for (const formitem of this.template.form.items) {
				let control: AbstractControl;
				if (formitem.name === formItemNames.CheckButton || formitem.name === formItemNames.ToggleButton) {
					control = new FormArray([]);
					tempForm.addControl(formitem.id, control);
					tempForm.addValidators(templateTenCheckboxValidator(formitem.id));
				} else if (formitem.name === formItemNames.RadioButton || formitem.name === formItemNames.NavButton) {
					control = new UntypedFormControl(formitem.id, Validators.required);
					control.setValue(null);
					tempForm.addControl(formitem.id, control);
				} else if (formitem.name === formItemNames.Form) {
					const validators: ValidatorFn[] = [];
					formitem.component?.required && validators.push(Validators.required);
					formitem.component?.minsize && validators.push(Validators.minLength(+formitem.component.minsize));
					formitem.component?.maxsize && validators.push(Validators.maxLength(+formitem.component.maxsize));
					control = new UntypedFormControl(formitem.component.name, validators);
					control.setValue(null);
					tempForm.addControl(formitem.component.name, control);
				}
			}
			this.hasSuperWifiProblems = this.template.form.items.some((item) => item.name === formItemNames.IconTextRow);
			this.templateForm = tempForm;
		}
	}

	get template10ActionBtnEnabled(): boolean {
		if (!this.template.form.checkbox || !this.template.form.checkbox.length) return true;
		return this.template.form.checkbox
			.filter((checkitem: TemplateCheckbox) => checkitem.mandatory)
			.every((ch: TemplateCheckbox) => ch.checkboxValue);
	}

	checkboxTemplate10Change(formitemId: string, checkboxItemValue: string, checked: boolean): void {
		const checkArray: FormArray = this.templateForm.get(formitemId) as FormArray;
		if (checked) {
			checkArray.push(new FormControl(checkboxItemValue));
		} else {
			let i: number = 0;
			checkArray.controls.forEach((item: FormControl) => {
				if (item.value === checkboxItemValue) {
					checkArray.removeAt(i);
					return;
				}
				i++;
			});
		}
	}

	callFMServiceInteraction(interactionId: string): void {
		this.faultManagementService
			.setInteractionForMonitoringSLA(
				this.customerAccountId,
				interactionId,
				this.template11Ticket.ticketId,
				this.template11Ticket.ticketName,
				this.template11Ticket.creationDate,
				Mva10LandingPageComponent.someUserSites
			)
			.subscribe();
	}

	private get ticketJourneysToSendInteractions(): string[] {
		return [
			TicketJourney.Reabrir_averia_garantia,
			TicketJourney.Reabrir_averia_garantia1,
			TicketJourney.Reabrir_averia_garantia2,
			TicketJourney.Reabrir_averia_garantia3,
			TicketJourney.Reabrir_averia_garantia4,
		];
	}

	ticketBtnTemplate11OpenChat(): void {
		this.userSettingsService.averia = this.template11Ticket.averia;
		this.userSettingsService.ticketId = this.template11Ticket.ticketId;
		this.userSettingsService.journey = this.template11Ticket.journey;
		this.userSettingsService.clientIdAveria = this.customerAccountId;
		this.configurationService.configuration.chatEnabled = true;
		this.sidemenuConfigService.openBubble(NeedBubbleHelpTabs.fault);
		this.appService.closeNeedHelpBubble.subscribe(() => {
			this.configurationService.configuration.chatEnabled = false;
		});
	}

	template11RedBtnClickHandler(): void {
		if (this.t11ChatRecordedWCS.redButton.action === journeyButtonActionOpenChat) {
			this.ticketBtnTemplate11OpenChat();
			const caseInBEHistoricJourneysToIds: Record<string, string> = {
				[TicketJourney.Modificar_cita_averia_campo]: InteractionsId.SLA_CANCEL,
				[TicketJourney.Notificar_averia_solucionada]: InteractionsId.SLA_SOLVED,
				[TicketJourney.Averia_fuera_de_SLA]: InteractionsId.SLA_OUTSIDE,
			};

			if (
				this.ticketJourneysToSendInteractions.includes(this.template11Ticket.journey) &&
				this.template11Ticket.template.templateCode === TicketTemplateCode.GARANTIA_INTERACCION
			) {
				this.callFMServiceInteraction(InteractionsId.ChatGarantia);
			} else if (caseInBEHistoricJourneysToIds[this.template11Ticket.journey]) {
				this.callFMServiceInteraction(caseInBEHistoricJourneysToIds[this.template11Ticket.journey]);
			} else {
				this.callFMServiceInteraction(InteractionsId.chatFault);
			}
		} else if (this.t11ChatRecordedWCS.redButton.action === ticketButtonActionSolved) {
			this.loading = true;
			const newTicket: OpenNewTicket = this.templatesService.setBodyOfTemplateReq(
				0,
				this.selectedService.id,
				this.selectedServiceName,
				TemplateStatus.start.toLocaleLowerCase(),
				stringOfFalse,
				this.platformInfo,
				this.typeConnection,
				this.template,
				this.templateForm,
				[],
				this.templateTicketId
			);
			this.getNextTemplate(newTicket);
			this.newTicket = newTicket;
			if (
				this.template11Ticket.status !== TicketStatus.Pending &&
				this.ticketJourneysToSendInteractions.includes(this.template11Ticket.journey)
			) {
				this.callFMServiceInteraction(InteractionsId.SolvedGarantia);
			}
		}
	}

	template11WhiteBtnClickHandler(): void {
		const journeysTemplatesToInteractionIds: Record<string, string> = {
			[`${TicketJourney.Reabrir_averia_garantia1}:${TicketTemplateCode.GARANTIA1}`]: InteractionsId.ChatGarantia1,
			[`${TicketJourney.Reabrir_averia_garantia2}:${TicketTemplateCode.GARANTIA2}`]: InteractionsId.ChatGarantia2,
			[`${TicketJourney.Reabrir_averia_garantia3}:${TicketTemplateCode.GARANTIA3}`]: InteractionsId.ChatGarantia3,
			[`${TicketJourney.Reabrir_averia_garantia4}:${TicketTemplateCode.GARANTIA4}`]: InteractionsId.ChatGarantia4,
		};

		if (this.t11ChatRecordedWCS.whiteButton.action === journeyButtonActionOpenChat) {
			this.ticketBtnTemplate11OpenChat();
			if (
				this.template11Ticket.status !== TicketStatus.Pending &&
				this.ticketJourneysToSendInteractions.includes(this.template11Ticket.journey)
			) {
				this.callFMServiceInteraction(
					journeysTemplatesToInteractionIds[
						`${this.template11Ticket.journey}:${this.template11Ticket.template.templateCode}`
					]
				);
			} else {
				this.callFMServiceInteraction(InteractionsId.chatFault);
			}
		}
	}
}
