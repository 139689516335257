import { Component, OnDestroy, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Breakpoints } from '@mva10/mva10-angular';
import { TranslateService } from '@ngx-translate/core';
import { AppService } from 'src/app/app.service';
import { ResizeService } from 'src/app/mva10/shared/services/resize.service';
import { OverLayContent } from 'src/app/shared/models/overlayContent.model';
import { SmartPayService } from 'src/app/smart-pay/services/smart-pay.service';
import { config } from 'src/config/pages-config';
import { AppThemes, HintLabelBackground, smartphoneRaffleCTAWidth } from '../../constants/defines';
import { UtilitiesService } from '../../utils/utilities.service';

@Component({
	selector: 'sp-smartphone-raffle',
	templateUrl: './smartphone-raffle.component.html',
	styleUrls: ['./smartphone-raffle.component.scss'],
})
export class SmartphoneRaffleComponent implements OnInit, OnDestroy {
	isMobile: boolean;
	overlayContent: OverLayContent;
	previousTheme: string;
	labelText: string;
	pageContent: string;
	constructor(
		private router: Router,
		private resizeService: ResizeService,
		public translate: TranslateService,
		private appService: AppService,
		private utiltiesService: UtilitiesService,
		private smartpayService: SmartPayService
	) {}

	ngOnInit(): void {
		this.appService.hiddenHeader = true;
		this.previousTheme = this.appService.theme;
		this.isMobile = this.resizeService.breakpoint < Breakpoints.TABLET;
		this.appService.theme = this.isMobile ? AppThemes.ThemeBkgWhite + ' ' + AppThemes.Theme2 : AppThemes.ThemeGrayBG;
		this.setUIContent();
	}

	setUIContent(): void {
		this.translate.get('v10.dashboard.smartPhoneRaffle').subscribe((data) => {
			this.overlayContent = {
				pageTitle: data.title,
				pageSubtitle: data.subTitle,
				btnConfigs: {
					btnName: data.button,
					btnWidth: smartphoneRaffleCTAWidth,
				},
				hintLabel: {
					showLabel: true,
					labelText: this.getRemainingDays(data.expiryDate),
					labelClass: this.setLabelClass(),
				},
				imgUrl: this.isMobile
					? this.appService.getImgFullPath(data.phoneImage)
					: this.appService.getImgFullPath(data.phoneImage_rsp),
			};
		});
		this.getPageContent();
	}

	getPageContent(): void {
		const startDate: string = this.translate.instant('v10.dashboard.smartPhoneRaffle.startDate');
		const expiryDate: string = this.translate.instant('v10.dashboard.smartPhoneRaffle.expiryDate');
		const pageContent: string = this.translate.instant('v10.dashboard.smartPhoneRaffle.pageContent');
		if (this.overlayContent) {
			this.overlayContent.subtitleContentSafeHTML = pageContent?.replace('{0}', startDate).replace('{1}', expiryDate);
		}
	}

	ngOnDestroy() {
		this.appService.theme = this.previousTheme;
		this.appService.hiddenHeader = false;
		this.overlayContent.hintLabel.showLabel = false;
	}

	navigateToPagoFacil(): void {
		this.smartpayService.navigateToSmartpayPage();
	}

	navigateToDashboard(): void {
		this.router.navigate([config.dashboard.route]);
	}

	getRemainingDays(expiryDate: string): string {
		const diffDays: number = this.utiltiesService.getremainingDays(expiryDate);
		this.translate.get('v10.dashboard.smartPhoneRaffle').subscribe((data) => {
			if (diffDays > 1) {
				this.labelText = data.remainingDaysLabel.replace('{0}', diffDays);
			} else {
				this.labelText = data.noRemainingDaysLabel;
			}
		});
		return this.labelText;
	}

	setLabelClass(): string {
		let labelClass: string;
		if (this.labelText === this.translate.instant('v10.dashboard.smartPhoneRaffle.noRemainingDaysLabel')) {
			labelClass = HintLabelBackground.orangeBackground;
		} else {
			labelClass = HintLabelBackground.whiteBackground;
		}
		return labelClass;
	}
}
