import { Component, EventEmitter, Input, OnChanges, Output, SimpleChanges } from '@angular/core';

@Component({
	selector: 'sp-toggle-button',
	templateUrl: './sp-toggle-button.component.html',
	styleUrls: ['./sp-toggle-button.component.scss'],
})
export class SpToggleButtonComponent implements OnChanges {
	/**event fired when click on check box */
	@Output()
	option: EventEmitter<boolean> = new EventEmitter();
	/**label */
	@Input()
	label: string = '';
	/**id for checkbox */
	@Input()
	id: string = '';
	/**name for checkbox */
	@Input()
	name: string;
	/**flag show if check box checked or not */
	@Input()
	check: boolean;
	@Input()
	disable: boolean;
	@Input()
	stopToggleAnimation: boolean;
	/**
	 * Toggle/Switch size. Defaults to 2 (big), can be 1 (medium) and 0 (small)
	 */
	@Input()
	size: number = 2;
	/**
	 * If true, toggle will turn to light gray color when disable === true
	 */
	@Input()
	changeColorIfDisabled: boolean;

	/**
	 * toggles the check that represents the toggle button current value
	 * then emits the value
	 */
	toggle(): void {
		this.check = !this.check;
		this.option?.emit(this.check);
	}
	ngOnChanges(changes: SimpleChanges): void {
		if (changes['check']) {
			this.check = changes['check'].currentValue;
		}
	}
}
