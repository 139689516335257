import { Component, ElementRef, EventEmitter, OnDestroy, OnInit, Output, ViewChild } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { AnimationOptions } from 'ngx-lottie';
import { Subscription } from 'rxjs';
import { AdaraHelpButtonOptions, HelpOptions } from 'src/app/shared/models/HelpOptions.model';
import { AppService } from '../../../app.service';
import { tobiImageFiles } from '../../constants/defines';
import { BubbleHelpService } from './bubble-help.service';

@Component({
	selector: 'sp-bubble-help',
	templateUrl: './bubble-help.component.html',
	styleUrls: ['./bubble-help.component.scss'],
})
export class BubbleHelpComponent implements OnInit, OnDestroy {
	@ViewChild('bubble') bubble: ElementRef;
	@ViewChild('clickSound', { static: true }) clickSound: ElementRef;
	@Output() bubbleClickEvent = new EventEmitter<any>();
	width: number;
	x: number;
	y: number = 40;
	whiteOverlayDisplay = false;
	bubbleHelpContentDisplay = false;
	lottieConfig: AnimationOptions;
	startY: number = 0;
	bubbleAnimation = false;
	lottieDispalyNone = false;
	anim: any;
	showBubbleOverlay: boolean = false;
	src = 'https://assets-es-pprd.dxlpreprod.local.vodafone.es/assets/vfg_need_help_pop.mp3';
	tobiHover;
	tobiDefault;
	tobiFocus;
	tobiSrc;
	tobiMinimize;
	noAnimationDelay: number = 50;
	iframeUrl: string;
	isBubbleOpened: boolean;
	bubbleAltTxt: string;
	isEnergySection: AdaraHelpButtonOptions;
	adaraSubscription: Subscription;

	constructor(
		public appService: AppService,
		private helpService: BubbleHelpService,
		public translate: TranslateService
	) {}

	ngOnInit() {
		this.lottieConfig = {
			path: 'https://assets-es-pprd.dxlpreprod.local.vodafone.es/assets/pinjump.json',
			autoplay: true,
			loop: true,
		};
		this.adaraSubscription = this.helpService.isAdaraSectionObservable.subscribe((data: AdaraHelpButtonOptions) => {
			this.isEnergySection = data;
			this.handleTobiIcon(data.isEnergySection);
		});
		this.appService.openBubble.subscribe(() => this.clickBubble());
		this.appService.closeNeedHelpBubble.subscribe(() => this.hideContent());
		this.helpService.isBubbleOpened.subscribe((opened: boolean) => {
			this.isBubbleOpened = opened;
		});
	}
	ngOnDestroy() {
		this.appService.openBubbleStopScroll = false;
		this.adaraSubscription?.unsubscribe();
	}
	onPanStart = (event: any) => {
		event.preventDefault();
		this.startY = this.y;
	};
	onPan = (event: any) => {
		event.preventDefault();
		this.y = this.startY - event.deltaY;
	};
	onPanEnd = (event: any) => {
		if (this.y > window.innerHeight - this.bubble?.nativeElement?.offsetHeight - 20) {
			this.y = window.innerHeight - this.bubble?.nativeElement?.offsetHeight - 20;
		} else if (this.y < 40) {
			this.y = 40;
		}
	};

	clickBubble = () => {
		this.isBubbleOpened = true;
		this.bubbleClickEvent.emit();
		this.helpService.showHelpIframe({
			openSound: true,
			openAnimation: true,
		});
	};
	handleAnimation(anim: any) {
		this.anim = anim;
	}
	hideContent() {
		this.helpService.hideHelpIframe({
			closeSound: true,
			closeAnimation: true,
		});
		this.isBubbleOpened = false;
	}

	handleTobiIcon(isEnergySection: boolean): void {
		this.tobiHover = isEnergySection ? tobiImageFiles.hoverAdara : tobiImageFiles.hover;
		this.tobiDefault = isEnergySection ? tobiImageFiles.defaultAdara : tobiImageFiles.default;
		this.tobiFocus = isEnergySection ? tobiImageFiles.defaultAdara : tobiImageFiles.focus;
		this.tobiMinimize = isEnergySection ? tobiImageFiles.defaultAdara : tobiImageFiles.minimize;
		this.tobiSrc = this.tobiDefault;
		this.initIframeSubject();
	}

	/**
	 * when tobi is clicked will check first if content
	 * is displayed or not beacuse clicking tobi will toggle closing content
	 * if it is in desktop/tablet view tobi icon will be changed to minimize icon
	 * in desktop/tablet view tobi will always be show (bubbleAnimation = false)
	 */
	initIframeSubject(): void {
		this.helpService.iframeHelpStatus().subscribe((options) => {
			// Show content
			if (options.showIframe) {
				this.showBubbleContentLogic(options);
			}
			// Hide content
			else if (!options.showIframe && this.isBubbleOpened) {
				this.hideBubbleContentLogic(options);
			}
		});
	}

	showBubbleContentLogic(options: HelpOptions): void {
		if (options.showIframe) {
			if (this.bubbleHelpContentDisplay) {
				this.bubbleHelpContentDisplay = false;
			} else {
				this.iframeUrl = options.url;
				this.bubbleAnimation = this.appService.isResponsiveView ? false : options.openAnimation;
				this.tobiSrc = this.appService.isResponsiveView ? this.tobiMinimize : this.tobiDefault;
				this.bubbleClickEvent.emit();
				this.showBubbleOverlay = options.openAnimation;
				if (options.openSound && !this.helpService.soundIsPlaying) {
					this.soundStarts(1800);
					this.clickSound.nativeElement.play();
				}
				setTimeout(
					() => {
						this.lottieDispalyNone = true;
						setTimeout(
							() => {
								this.bubbleHelpContentDisplay = true;
							},
							options.openAnimation ? 700 : this.noAnimationDelay
						);
						setTimeout(
							() => {
								this.whiteOverlayDisplay = false;
								this.showBubbleOverlay = false;
								this.appService.openBubbleStopScroll = true;
							},
							options.openAnimation ? 1800 : this.noAnimationDelay
						);
					},
					options.openAnimation ? 500 : this.noAnimationDelay
				);
			}
		}
	}

	hideBubbleContentLogic(options: HelpOptions): void {
		this.whiteOverlayDisplay = true;
		this.showBubbleOverlay = options.closeAnimation;
		this.bubbleAnimation = options.closeAnimation;
		if (options.closeSound && !this.helpService.soundIsPlaying) {
			this.soundStarts(300);
			this.clickSound.nativeElement.play();
		}
		this.tobiSrc = this.tobiDefault;
		setTimeout(
			() => {
				this.bubbleHelpContentDisplay = false;
				setTimeout(
					() => {
						this.bubbleAnimation = false;
						this.showBubbleOverlay = false;
						this.lottieDispalyNone = false;
						this.whiteOverlayDisplay = false;
						this.appService.helpBubbleItemClicked = false;
					},
					options.closeAnimation ? 300 : this.noAnimationDelay
				);
			},
			options.closeAnimation ? 300 : this.noAnimationDelay
		);
	}

	/**
	 * Method for setting soundIsPlaying flag's value
	 * @param milliseconds waiting time to reset the flag
	 */
	soundStarts(milliseconds: number): void {
		this.helpService.soundIsPlaying = true;
		setTimeout(() => (this.helpService.soundIsPlaying = false), milliseconds);
	}
}
