export enum BundleType {
	Voice = 'voice',
	Data = 'data',
	PrepaidData = 'prepaid data',
	SMS = 'sms',
	Fixed = 'fixed',
	International = 'international',
	national = 'national',
	ADSL = 'adsl',
	Fibre = 'fibre',
	TV = 'tv',
	RoamingVoice = 'roaming voice',
	RoamingData = 'roaming data',
	RoamingSMS = 'roaming sms',
	Entertainment = 'entertainment',
	Credit = 'credit',
	Benefits = 'benefits',
	LlamadasInt = 'llamadas internacionales',
	LlamadasIntFijo = 'llamadas internacionales fijo',
	Mercury = 'mercury',
	Contenidos = 'Contenidos',
	Sms = 'SMS',
	Landline = 'landline',
}

export enum BundleTypeMap {
	Voice = 'Minutos',
	Data = 'Datos',
	Sms = 'Mensajes',
	TV = 'Televisión',
}

export enum BundleTypeIconId {
	Voice = 'icon-call-log',
	Data = 'icon-data',
	Sms = 'icon-sms',
	TV = 'icon-tv',
}

export enum BundleTypeCurrentConsumptionCards {
	NonZero = 'expand-non-zero',
	OneOobTypeOnly = 'expand-if-only-one-oob',
}

export enum RoamersBundleType {
	CUDAT = BundleType.Data,
	CUPRE = BundleType.PrepaidData,
	CUVOZ = BundleType.Voice,
	CUSMS = BundleType.SMS,
}
