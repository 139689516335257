import { HttpClient, HttpHeaders, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import moment from 'moment';
import { BehaviorSubject, Observable, combineLatest, of } from 'rxjs';
import { map } from 'rxjs/operators';
import { DataPortabilidadModel } from 'src/app/shared/models/dataPortabilidad.model';
import { StorageService } from '../../../../core/services/storage.service';
import { LeftMenuWs10Service } from '../../../../mva10/shared/services/left-menu-ws10.service';
import * as defines from '../../../constants/defines';
import {
	MyOrdersFlows,
	autoiMigration,
	exceptionCodes,
	iteractionDxl,
	messageTemplateNumber,
	monthMoment,
	returnDefines,
	setOB,
	tabsMyOrders,
} from '../../../constants/defines';
import { API_URLS } from '../../../constants/routes-config';
import { InteractionType } from '../../../enums/interaction-type.enum';
import { OrderFlow } from '../../../enums/order-flow.enum';
import { StatePedido, orderTypes, orderTypesKeys } from '../../../enums/order-type.enum';
import { Address } from '../../../models/Address.model';
import { BodyActivarRouter } from '../../../models/activarRouterBody.model';
import { AppointmentChange } from '../../../models/appointmentChange.model';
import { AppointmentResponse, AvailableTimeSlot } from '../../../models/appointmentResponse.model';
import { BodyAvanzarOrden } from '../../../models/avanzarOrdenBody.model';
import { BodyCambioCita } from '../../../models/cambioCitaOTBody.model';
import { BodyCancelarCanje } from '../../../models/cancelarCanjeBody.model';
import { CitasDisponiblesResponse, FechasCita } from '../../../models/citasDisponiblesOT.model';
import { constants } from '../../../models/constants';
import {
	DetallePedidoExtended,
	DetallePedidoResponse,
	DevolucionEquipos,
	DocumentStatus,
	Paso,
} from '../../../models/detalleSeguimientoPedido.model';
import { DigitalSign } from '../../../models/digitalSign.model';
import { BodyGetCitasDisponibles } from '../../../models/getCitasDisponiblesBody.model';
import { Characteristic, GetOrder, GetOrders, ProductOrderItem } from '../../../models/getOrder.model';
import { GetTypePortability } from '../../../models/getTypePortability.model';
import {
	GeneralInteraccionModel,
	GeneralPartyInteractionBody,
	InsertInteraction,
} from '../../../models/iteraction-dxl.model';
import { ObtenerPedidosCliente, OrdenObtenerPedidos } from '../../../models/obtenerPedidos.model';
import { DataShippingOrders } from '../../../models/pedidoHistoricoOL.model';
import { PedidoHistoricoOLResponseNew } from '../../../models/pedidoHistoricoOLnew.model';
import { PortabilityRejected } from '../../../models/portabilityRejected.model';
import {
	BodyRelanzarPortabilidadPack,
	IndividualIdentification,
} from '../../../models/postRelanzarPortabilidadPack.model';
import { FormatDatePipe } from '../../../pipes/format-date.pipe';
import { CustomerAccountService } from '../../../services/customer-account.service';
import { MyOrdersTaggingService } from '../../../services/my-orders-tagging.service';
import { SlidService } from './slid.service';
import { UtilsService } from './utils.service';

@Injectable({
	providedIn: 'root',
})
export class OrdersService {
	public orderData: OrdenObtenerPedidos[];
	/**
	 * Tab to where the current expanded order belongs
	 */
	public selectedOrderType: orderTypes;
	/**
	 * Data from order list for current expanded order
	 */
	public selectedOrderData: OrdenObtenerPedidos;
	/**
	 * Detailed data for current expanded order
	 */
	public detailOrderData: DetallePedidoExtended;
	public detailOrderData$: BehaviorSubject<DetallePedidoExtended | undefined> = new BehaviorSubject(undefined);
	public direccion: string;
	public firmaDigitalRes: DigitalSign;
	/**
	 * WCS config: determines which order flows are enabled to show AUTOI help card
	 */
	public autoiFlows: OrderFlow[];
	/**
	 * WCS config: determines which order flows are enabled to show AUTEC appointment change button
	 */
	public canChangeToAutecFlows: OrderFlow[];
	public idAppointment: string;
	public showMessage: boolean;
	public superiorMessage: string;
	public portabilityRejected: PortabilityRejected;
	public orders: GetOrders;
	public productOrders: GetOrders;
	public tipoLinea: string;
	public productOrder: Record<string, GetOrder> = {};
	public subOrder: Record<string, ProductOrderItem[]> = {};
	public mensajeEEScoring: string;
	public isOB: boolean;
	public tokenOB: string;
	public urlOB: string;
	public dateRequest: number = 0;
	public flow: string;
	public availableTime: AvailableTimeSlot[];
	public noMatchingAppointments: boolean = true;
	public dataShippingOrder: Record<string, DataShippingOrders> = {};
	public pasoFlujo: string;
	public showNewCardAutoi: boolean;
	public logisticState: string;
	public hasSubOrders: boolean;
	public flow33: string = OrderFlow.FLUJO_33;
	public exceptionCode: string = exceptionCodes.y1;

	constructor(
		private http: HttpClient,
		public utilsService: UtilsService,
		private storageService: StorageService,
		public customerAccountService: CustomerAccountService,
		private slidService: SlidService,
		private translate: TranslateService,
		private datePipe: FormatDatePipe,
		private tagging: MyOrdersTaggingService,
		public leftMenuService: LeftMenuWs10Service
	) {}

	public setUrlOB(): void {
		this.urlOB = this.translate.instant('v10.myOrder.detail.urlOB').replace('{0}', this.tokenOB);
	}

	public setTokenOB(characteristics: Characteristic[]): void {
		this.tokenOB = characteristics?.find(
			(characteristic) => characteristic.name.toLowerCase() === defines.setOB.ordenOb.toLowerCase()
		).value;
	}

	public obtenerPedidosCliente(idCliente: string): Observable<ObtenerPedidosCliente> {
		const address: string = this.leftMenuService.addressSelector?.find((res) => res.value === idCliente).name;
		return this.getOrders(idCliente).pipe(
			map((res: GetOrders) => {
				// Logica
				this.detailOrderData = null;
				this.detailOrderData$.next(null);
				let Order: ObtenerPedidosCliente;
				const Orders: OrdenObtenerPedidos[] = [];
				res.forEach((item) => {
					const Orderitem: OrdenObtenerPedidos = {
						nombreComercial: '',
						descripcion: '',
						fechaSolicitud: '',
						fechaAlta: '',
						fechaCancelacion: '',
						fechaFin: '',
						mnsjExcepcion: '',
						numeroPedido: '',
						idPedidoTol: '',
						tipo: null,
						statePedido: null,
						nombreTienda: '',
						pedidoTol: null,
						infoSim: null,
						mnsjFijo: null,
						nombreFlujo: null,
						elementosCesta: null,
						tipoServicio: null,
						returnStatus: '',
						moneyReturns: '',
						delivered: false,
						exceptionCode: '',
					};
					this.errorTryCatchObtenerPedidosCliente(item, Orderitem);
					Orders.push(Orderitem);
				});
				Order = {
					errorCode: 100,
					error: '',
					direccion: address ?? this.getFormattedAddress(this.customerAccountService.customerAccount.address),
					ordenes: Orders,
				};
				this.autoiFlows = this.getEnabledFlows('v10.myOrder.helpAutoiModal.autoiEnabledFlows', [
					OrderFlow.FLUJO_1,
					OrderFlow.FLUJO_185,
					// Flow 33 is incorrectly included in this array in WCS, but it's needed.
					// Current logic will not show OL info if removed and displays both OL + AUTOI card when included
					OrderFlow.FLUJO_33,
				]);
				this.canChangeToAutecFlows = this.getEnabledFlows('v10.myOrder.detail.canChangeFromAutoiToAutecEnabledFlows', [
					OrderFlow.FLUJO_7,
					// MVACOM-266: Including flow 33 in this new array, OL info will show but AUTOI card (new and old) should not
					OrderFlow.FLUJO_33,
				]);
				this.direccion = Order.direccion;
				this.orderData = Orders;
				return Order;
			})
		);
	}

	getEnabledFlows<T>(wcsKey: string, defaultValue: T[]): T[] {
		let enabledFlows = null;
		const wcsValue = this.translate.instant(wcsKey);
		if (wcsValue?.trim().length && wcsValue !== wcsKey) {
			enabledFlows = this.translate
				.instant(wcsKey)
				.toUpperCase()
				.split(';')
				.map((flow) => flow.trim())
				.filter((flow) => !!flow);
		}
		return enabledFlows ?? defaultValue;
	}

	getFormattedAddress(address: Address): string {
		return `${address.street ? address.street + ', ' : ''}${address.buildingNo ? address.buildingNo + ', ' : ''}${
			address.level ? address.level + ', ' : ''
		}${address.postcode ? address.postcode + ', ' : ''}${address.town || ''}`.trim();
	}

	errorTryCatchObtenerPedidosCliente(item: GetOrder, Orderitem: OrdenObtenerPedidos): void {
		this.setReturn(item, Orderitem);
		this.checkDelivered(item, Orderitem);
		this.setExceptionCode(item, Orderitem);
		Orderitem.nombreComercial = item?.name || '';
		Orderitem.descripcion = item?.description || '';
		Orderitem.fechaSolicitud = new Date(item?.requestedStartDate).getTime().toString() || '';
		Orderitem.fechaAlta = new Date(item?.orderDate).getTime().toString() || '';
		Orderitem.fechaCancelacion = new Date(item?.cancellationDate).getTime().toString() || '';
		Orderitem.fechaFin = new Date(item?.completionDate).getTime().toString() || '';
		Orderitem.mnsjExcepcion = item?.subState || '';
		this.errorTryCatchObtenerPedidosClienteRefactInfoSIM(item, Orderitem);
		this.errorTryCatchObtenerPedidosClienteRefact(item, Orderitem);
	}

	public setReturn(item: GetOrder, Orderitem: OrdenObtenerPedidos): void {
		if (
			item &&
			this.translate.instant('v10.myOrder.tabs.categoryReturns').includes(this.productOrder[item.id]?.category)
		) {
			Orderitem.returnStatus = this.productOrder[item.id]?.note?.find(
				(el) => el.id?.toLowerCase() === defines.subOrders.logisticStatus.toLowerCase()
			)?.text;
			this.productOrder[item.id]?.productOrderItem?.forEach((el) => {
				if (el.product?.name === defines.subOrders.MEGTT) {
					Orderitem.moneyReturns = el.itemPrice
						?.find((el) => el.priceType?.toLowerCase() === defines.returnDefines.oneOffPrice.toLowerCase())
						?.price?.taxIncludedAmount?.value?.toFixed(2)
						.toString();
					Orderitem.nameDevice = el?.characteristic?.find(
						(terminal) => terminal?.name?.toUpperCase() === defines.subOrders.terminalDetails.toUpperCase()
					)?.value;
				}
			});
		}
	}

	errorTryCatchObtenerPedidosClienteRefactInfoSIM(itemOrder: GetOrder, Orderitem: OrdenObtenerPedidos): void {
		itemOrder?.productOrderItem.map((item) => {
			if (item?.characteristic?.find((itemChar) => itemChar.name === defines.infoSimMSISDN.msisdn)) {
				const sim: string = this.mapCharacteristicInfoSim(item, defines.infoSimMSISDN.iccid);
				const pin: string = this.mapCharacteristicInfoSim(item, defines.infoSimMSISDN.pin1);
				const puk: string = this.mapCharacteristicInfoSim(item, defines.infoSimMSISDN.puk1);
				if (sim && pin && puk) {
					Orderitem.infoSim = {
						sim: sim,
						pin: pin,
						puk: puk,
					};
				}
			}
		});
	}

	mapCharacteristicInfoSim(item: ProductOrderItem, name: string): string {
		return item.product.productCharacteristic?.find((productChar) => productChar.name === name)?.value || '';
	}

	errorTryCatchObtenerPedidosClienteRefact(item: GetOrder, Orderitem: OrdenObtenerPedidos): void {
		Orderitem.numeroPedido = item?.id || '';
		Orderitem.idPedidoTol = item?.externalIdentifier?.[0]?.id;
		Orderitem.tipo = +item?.orderCategory?.filter((item) => item.description === 'TIPO_PEDIDO')?.[0]?.name || null;

		const ternario: string = this.setTernario(item);
		Orderitem.statePedido = this.getEnumValue(ternario, StatePedido) || null;

		Orderitem.nombreTienda = item?.deliveryInstruction?.geographicSite?.name || '';
		if (Orderitem.idPedidoTol && Orderitem.idPedidoTol !== Orderitem.numeroPedido) {
			Orderitem.pedidoTol = 2;
		} else if (Orderitem.idPedidoTol && Orderitem.idPedidoTol === Orderitem.numeroPedido) {
			Orderitem.pedidoTol = 1;
		} else {
			Orderitem.pedidoTol = 0;
		}
		Orderitem.nombreFlujo = this.getEnumValue(item?.processFlow?.processFlowSpecificationRef?.name, OrderFlow) || null;
	}

	public setTernario(item: GetOrder): string {
		const categoryReturns: string[] = this.translate.instant('v10.myOrder.tabs.categoryReturns');
		const category: string = this.setCategory(item);
		if (
			(item?.state === orderTypesKeys.inProgress || item?.state === orderTypesKeys.completed) &&
			!categoryReturns.includes(category)
		) {
			return item?.state === orderTypesKeys.inProgress ? defines.tabsMyOrders.three : defines.tabsMyOrders.two;
		} else {
			return (item?.state === orderTypesKeys.inProgress || item?.state === orderTypesKeys.completed) &&
				categoryReturns.includes(category)
				? defines.tabsMyOrders.four
				: defines.tabsMyOrders.one;
		}
	}

	public setCategory(item: GetOrder): string {
		return item?.id && this.productOrder ? this.productOrder[item.id]?.category : '';
	}

	getEnumValue<T extends Record<string, string>>(str: string, enumType: T): T[keyof T] | undefined {
		const keys = Object.keys(enumType) as Array<keyof T>;
		const match = keys.find((key) => enumType[key] === str);
		return match ? enumType[match] : undefined;
	}

	public getOrders(idCliente: string): Observable<GetOrders> {
		const date: string[] = this.setDateGet();
		const apiUrl: string = API_URLS.Orders.getOrders
			.replace('{idClient}', idCliente)
			.replace('{fromdate}', date[0])
			.replace('{todate}', date[1]);

		let headers: HttpHeaders = new HttpHeaders();
		headers = headers.append('Accept', 'application/json');
		headers = headers.append('Content-Type', 'application/json');
		headers = headers.append(
			'Authorization',
			'Bearer ' + this.storageService.getLocalStorage(defines.LOCAL_STORAGE_KEYS.ACCESS_TOKEN)
		);
		const options: { headers: HttpHeaders; observe: 'body' } = {
			headers: headers,
			observe: 'response' as 'body',
		};
		return this.http.get(apiUrl, options).pipe(
			map((response: HttpResponse<GetOrders>) => {
				this.orders = response.body;
				const responseHeaders: string = response.headers.get('Date');
				this.setDateRequest(responseHeaders);
				return response.body;
			})
		);
	}

	public getDetalle(numeroPedido: string): DetallePedidoExtended {
		let detalle: DetallePedidoExtended = new DetallePedidoExtended();
		if (this.orders) {
			const order = this.orders.findIndex((item) => item.id === numeroPedido);
			if (order >= 0 && numeroPedido) {
				this.errorTryCatchGetDetalle(this.orders[order], detalle);
				this.pasoFlujo = detalle.paso;
				if (
					this.orders[order].orderCategory?.some((res) =>
						[defines.ordersPortaType.typeMobileReject, defines.ordersPortaType.typePhoneReject].includes(
							res.description
						)
					)
				) {
					this.getTipoPortabilidad(this.orders[order]).subscribe({
						next: (resp: GetTypePortability[]) => {
							this.mappingFieldsRejectPortability(resp, this.orders[order]);
						},
						error: () => {
							// TODO develop error
						},
					});
				} else if (
					this.orders[order].orderCategory?.some((res) =>
						[defines.ordersPortaType.typeMobile, defines.ordersPortaType.typePhone].includes(res.description)
					)
				) {
					this.getTipoPortabilidad(this.orders[order]).subscribe({
						next: (resp: GetTypePortability[]) => {
							this.showTopMessage(resp);
						},
						error: () => {
							// TODO develop error
						},
					});
				}
			}
			this.detailOrderData = null;
			this.detailOrderData$.next(null);
			this.selectedOrderData = this.orderData?.find((order) => order.numeroPedido === numeroPedido);
			const orderFlow: OrderFlow = this.selectedOrderData?.nombreFlujo;
			const statePedido: StatePedido = this.selectedOrderData?.statePedido;

			this.detailOrderData = {
				...this.completeServiceData(detalle, true),
				...({
					otsHijas: this.getOtsHijas(detalle),
					numeroPedido: numeroPedido,
					orderFlow,
					statePedido,
					hasAutoInstallationCard: statePedido === StatePedido.OPEN && this.autoiFlows.includes(orderFlow),
					canChangeFromAutoiToAutecAppointment: this.canChangeToAutecFlows?.includes(orderFlow),
				} as DetallePedidoExtended),
			};
			this.detailOrderData$.next(this.detailOrderData);
			detalle = this.detailOrderData;
			this.showNewCardAutoi =
				(this.detailOrderData?.orderFlow === MyOrdersFlows.flow22 && this.pasoFlujo === messageTemplateNumber.temp5) ||
				(this.detailOrderData?.orderFlow === MyOrdersFlows.flow3 && this.pasoFlujo === messageTemplateNumber.temp41);
		}
		return detalle;
	}

	public setScoring(item: GetOrder, detalle: DetallePedidoExtended): void {
		if (this.orders && detalle.scoring) {
			if (item.orderTotalPrice) {
				detalle.scoring.importeFianza = item.orderTotalPrice[0].price.taxRate?.toString();
			}
			item.document.forEach((document) => {
				detalle.scoring.documentos.push(document.name);
			});
		}
	}

	getTipoPortabilidad(item: GetOrder): Observable<GetTypePortability[]> {
		const typePorta: string = item?.orderCategory.some((res) =>
			res.description.includes(defines.ordersPortaType.typeMobile)
		)
			? defines.ordersPortaType.mobile.toLowerCase()
			: defines.ordersPortaType.phone.toLowerCase();
		this.tipoLinea = typePorta.toLowerCase();
		const apiUrl: string = API_URLS.TypeOrderPortability.typePorta
			.replace('{orderId}', item?.id)
			.replace('{tipoPortabilidad}', typePorta);
		const httpOptions: { [key: string]: HttpHeaders } = {
			headers: new HttpHeaders({
				'Content-Type': 'application/json',
				'Accept': 'application/json',
			}),
		};
		httpOptions.headers.append(
			'Authorization',
			'Bearer ' + this.storageService.getLocalStorage(defines.LOCAL_STORAGE_KEYS.ACCESS_TOKEN)
		);
		return this.http.get(apiUrl, httpOptions).pipe(
			map((res: GetTypePortability[]) => {
				return res;
			})
		);
	}

	public showTopMessage(portability: GetTypePortability[]): void {
		if (portability) {
			const urlVPT: string = portability[0].orderItem
				.find((res) => res.resource.name === defines.typePortability.phoneNumber)
				?.resource.resourceCharacteristic.find((resp) => resp.name === defines.typePortability.urlVpt)?.value;
			if (urlVPT !== null && urlVPT !== undefined) {
				this.detailOrderData.mostrarMensajes = true;
				this.detailOrderData.urlVPT = urlVPT;
				this.detailOrderData.mensajeEE = null;
				this.detailOrderData.mensaje = null;
				this.showMessage = true;
				this.superiorMessage = defines.superiorMessage;
			}
		}
	}

	public mappingFieldsRejectPortability(portability: GetTypePortability[], item: GetOrder): void {
		const individualIdentification: IndividualIdentification[] =
			portability[0].relatedParty[0]?.individualIdentification;
		const tipoDoc: string = individualIdentification ? individualIdentification[0].identificationType : undefined;
		this.detailOrderData.portabilidadRechazada = {
			tipoRechazoPortabilidad: parseInt(item?.note.find((res) => res.id === 'tipoRechazoPortabilidad')?.text, 10),
			tipoDoc: tipoDoc,
			numDoc: individualIdentification ? individualIdentification[0].identificationId : undefined,
			numPortar: portability[0].orderItem.find((res) => res.resource.name === defines.typePortability.phoneNumber)
				.resource.id,
			nombre: portability[0].relatedParty[0].givenName,
			apellido1: portability[0].relatedParty[0].familyName.split(',')[0],
			apellido2: portability[0].relatedParty[0].familyName.split(',')[1],
			nationality: portability[0]?.relatedParty[0]?.nationality,
			type: portability[0]?.relatedParty[0]['@type'],
			iccid: portability[0].orderItem.find((res) => res.resource.name === defines.typePortability.ICCID)?.resource.id,
			tipoLinea: this.tipoLinea,
		};
		this.detailOrderData$.next(this.detailOrderData);
	}

	errorTryCatchGetDetalle(item: GetOrder, detalle: DetallePedidoExtended): void {
		detalle.tipo = +item?.orderCategory?.filter((item) => item.description === 'TIPO_PEDIDO')?.[0]?.name;
		detalle.descripcion = item?.description || '';
		detalle.mensajeFijo = item?.note?.filter((item) => item?.id === 'mensajeFijo')?.[0]?.text;
		detalle.mensajeEE = item?.subState;
		detalle.paso =
			item?.processFlow?.taskFlow?.filter(
				(item) => item.state === 'active' && item.characteristic?.[0]?.name === 'orden'
			)?.[0]?.characteristic?.[0]?.value || '';
		item?.processFlow?.taskFlow?.forEach((item) => {
			const pasos: Paso = {
				pasoNombre: item.taskFlowSpecificationRef?.name,
				pasoOrden: item.characteristic?.[0]?.name === 'orden' ? item.characteristic?.[0]?.value : undefined,
			};
			detalle.pasos.push(pasos);
		});
		detalle.mensaje =
			item?.processFlow?.taskFlow
				.filter((item) => item.characteristic?.find((c) => c?.name === 'mensaje') && item.state === 'active')?.[0]
				?.characteristic?.find((c) => c?.name === 'mensaje')?.value || '';
		detalle.mensajeOL =
			item?.processFlow?.taskFlow
				.filter((item) => item.characteristic?.find((c) => c?.name === 'mensajeOL') && item.state === 'active')?.[0]
				?.characteristic?.find((c) => c?.name === 'mensajeOL')?.value || '';
		detalle.fechaPrimeraHorquilla =
			item?.processFlow?.taskFlow
				.filter(
					(item) =>
						item.characteristic?.find((c) => c?.name === defines.estimatedDateFormat.fechaPrimeraHorquilla) &&
						item.state === 'active'
				)?.[0]
				?.characteristic?.find((c) => c?.name === defines.estimatedDateFormat.fechaPrimeraHorquilla)?.value || '';
		detalle.fechaSegundaHorquilla =
			item?.processFlow?.taskFlow
				.filter(
					(item) =>
						item.characteristic?.find((c) => c?.name === defines.estimatedDateFormat.fechaSegundaHorquilla) &&
						item.state === 'active'
				)?.[0]
				?.characteristic?.find((c) => c?.name === defines.estimatedDateFormat.fechaSegundaHorquilla)?.value || '';
		this.errorTryCatchGetDetalleRefact(item, detalle);
	}

	errorTryCatchGetDetalleRefact(item: GetOrder, detalle: DetallePedidoExtended): void {
		detalle.mensajePF =
			item?.processFlow?.taskFlow
				.filter((item) => item.characteristic?.find((c) => c?.name === 'mensajePF') && item.state === 'active')?.[0]
				?.characteristic?.find((c) => c?.name === 'mensajePF')?.value || '';
		detalle.mensajePM =
			item?.processFlow?.taskFlow
				.filter((item) => item.characteristic?.find((c) => c?.name === 'mensajePM') && item.state === 'active')?.[0]
				?.characteristic?.find((c) => c?.name === 'mensajePM')?.value || '';
		detalle.formulario =
			item?.processFlow?.taskFlow
				.filter((item) => item.characteristic?.find((c) => c?.name === 'formulario') && item.state === 'active')?.[0]
				?.characteristic?.find((c) => c?.name === 'formulario')?.value || '';
		detalle.incluidoPedido = [];
		item?.productOrderItem
			.filter((item) => item.productOffering)
			?.forEach((item) => {
				detalle.incluidoPedido.push(item.productOffering?.name);
			});
		detalle.permanencias = [];
		item?.productOrderItem
			.filter(
				(item) =>
					item.productOffering &&
					item.product &&
					item.product.productCharacteristic &&
					item.product.productCharacteristic.length > 0 &&
					item.product.productCharacteristic.some((element) => {
						return element.name === 'Permanence';
					})
			)
			?.forEach((itema) => {
				detalle.permanencias.push(itema.productOffering.name);
			});
		this.errorTryCatchGetDetalleRefactTwo(item, detalle);
	}

	errorTryCatchGetDetalleRefactTwo(item: GetOrder, detalle: DetallePedidoExtended): void {
		detalle.activarRouter = +item?.note?.filter((item) => item.id === 'activarRouter')?.[0]?.text || 0;
		detalle.scoring = item?.note?.filter((item) => item.id === 'necesitaDocumentosScoring')?.[0]?.text
			? detalle.scoring
			: null;
		if (detalle.scoring) {
			this.setScoring(item, detalle);
			detalle.scoring.necesitaDocumentosScoring =
				+item?.note?.filter((item) => item.id === 'necesitaDocumentosScoring')?.[0]?.text || null;
		}
		detalle.devolucionesEquipos = [];
		const tipo_pedido: number =
			+item?.orderCategory?.filter((item) => item.description === 'TIPO_PEDIDO')?.[0]?.name || 0;
		if (tipo_pedido === 6) {
			item?.productOrderItem?.forEach((element) => {
				const devolucionEquipo: DevolucionEquipos = {
					numEquipo: element?.product?.id,
					estado: element?.characteristic?.filter((item) => item.name === 'NASStatusOT')?.[0]?.value || '',
					codDevolucion: +element?.productOffering?.id,
					textoDescripcion: item?.deliveryInstruction?.geographicSite?.name || '',
					fechaLimite: +item?.note?.filter((item) => item.id === 'fechaLimiteDevEquipo')?.[0]?.text,
				};
				detalle.devolucionesEquipos.push(devolucionEquipo);
			});
		}
		this.errorTryCatchGetDetalleRefactThree(item, detalle);
	}

	errorTryCatchGetDetalleRefactThree(item: GetOrder, detalle: DetallePedidoExtended): void {
		detalle.instalarEquipos = +item?.note?.filter((item) => item.id === 'instalarEquipos')?.[0]?.text || 0;
		detalle.guiaOneConecta = +item?.note?.filter((item) => item.id === 'guiaOneConecta')?.[0]?.text || 0;
		detalle.cambiarEquipos = +item?.note?.filter((item) => item.id === 'cambiarEquipos')?.[0]?.text || 0;
		detalle.cambioCita = +item?.note?.filter((item) => item.id === 'cambioCita')?.[0]?.text || 0;
		detalle.fechaPlanificacion = +new Date(item?.expectedCompletionDate);
		detalle.citaPlanificada = detalle.fechaPlanificacion ? true : false;
		detalle.guiaAutoiNeba = +item?.note?.filter((item) => item.id === 'guiaAutoiNeba')?.[0]?.text || 0;
		detalle.guiaAutoiNebaTv = +item?.note?.filter((item) => item.id === 'guiaAutoiNebaTv')?.[0]?.text || 0;
		detalle.esOrdenNeba = item?.orderCategory?.some((item) => item.description === defines.TIPO_ORDEN_NEBA);
		detalle.fijoTemporal = item?.note?.filter((item) => item.id === 'fijoTemporal')?.[0]?.text || null;
		detalle.estadool = item?.note?.filter((item) => item.id === 'estadool')?.[0]?.text || null;
		detalle.cancelarCanje = item?.orderCategory?.find((item) => item.description === 'TIPO_CANCELABLE') ? 1 : 0;
	}

	completeServiceData(orderData: DetallePedidoResponse, isPrimary: boolean): DetallePedidoExtended {
		const mensajeEE: string = this.setExceptionMessage(isPrimary, orderData);
		return Object.assign({}, orderData, {
			isDetail: true,
			mensaje:
				(orderData.mensajePF || orderData.mensajePM || mensajeEE) && !orderData.citaPlanificada
					? null
					: orderData.mensaje,
			mensajeOL: orderData.mensajeOL,
			mensajePF: mensajeEE ? null : orderData.mensajePF,
			mensajePM: mensajeEE ? null : orderData.mensajePM,
			mensajeEE: !mensajeEE ? null : mensajeEE,
			mostrarMensajes:
				(orderData.mensajeOL ||
					orderData.mensajePF ||
					orderData.mensajePM ||
					orderData.mensajeSuperior ||
					orderData.mensaje) &&
				!orderData.citaPlanificada,
			pasos: orderData.pasos
				.filter((paso: Paso, _index: number, self: Paso[]) =>
					this.utilsService.noDups(self, paso, orderData.paso, this.selectedOrderData)
				)
				.map((paso: Paso): Paso => {
					return Object.assign({}, paso, {
						pasoOrden: +paso.pasoOrden / 10 > 1 ? paso.pasoOrden[0] : paso.pasoOrden,
					});
				}),
			paso: (+orderData.paso / 10 > 1 ? Math.floor(+orderData.paso / 10) : orderData.paso) || '1',
		} as DetallePedidoExtended);
	}

	setExceptionMessage(isPrimary: boolean, orderData: DetallePedidoExtended): string {
		const isException: boolean = !!this.selectedOrderData?.mnsjExcepcion;
		let mensajeEE: string = isPrimary && isException ? this.selectedOrderData.mnsjExcepcion : orderData.mensajeEE;
		if (orderData.portabilidadRechazada) {
			mensajeEE = !orderData.mensajePF && !orderData.mensajePM ? mensajeEE : null;
			orderData.portabilidadRechazada.codigoRechazo =
				constants.tipoRechazoMap[orderData.portabilidadRechazada.tipoRechazoPortabilidad] ||
				orderData.portabilidadRechazada.codigoRechazo;
		}

		if (mensajeEE && `${this.selectedOrderData?.nombreFlujo}`.toUpperCase().includes(constants.autoi_flow_name)) {
			// Move message to new variable to allow logic even with an exception
			orderData.warningMessage = mensajeEE;
		}

		mensajeEE = orderData.mensajeSuperior || orderData.warningMessage ? null : mensajeEE;
		return mensajeEE;
	}

	getOtsHijas(orderData: DetallePedidoResponse): DetallePedidoExtended[] {
		return orderData.otsHijas.map((ot) => this.completeServiceData(ot, false));
	}

	public getCitasDisponiblesOT(
		idCliente: string,
		idOrden: string,
		cambioCita: number,
		oldAppointment: string
	): Observable<CitasDisponiblesResponse> {
		return this.getCitasDisponiblesOTResponse(idCliente, idOrden, cambioCita, oldAppointment).pipe(
			map((res) => {
				return this.mapToCitasDisponiblesResponse(res);
			})
		);
	}

	mapToCitasDisponiblesResponse(res: AppointmentResponse): CitasDisponiblesResponse {
		this.availableTime = res.availableTimeSlot;
		const fechascita: FechasCita[] = [];
		this.idAppointment = res.id;
		let fechasdisponibles: string[] = [];
		res.availableTimeSlot.forEach((item) => {
			const fecha: string = this.datePipe.transform(item.validFor.startDateTime, 'YYYY-MM-DD', 'YYYY-MM-DDTHH:mm:ssZ');
			fechasdisponibles.push(fecha);
		});
		fechasdisponibles = Array.from(new Set(fechasdisponibles));
		fechasdisponibles.forEach((item) => {
			const franjasDisponibles: string[] = [];
			let availableTimeSlot: AvailableTimeSlot[] = [];
			availableTimeSlot = res.availableTimeSlot.filter((element) => {
				return this.datePipe.transform(element.validFor.startDateTime, 'YYYY-MM-DD', 'YYYY-MM-DDTHH:mm:ssZ') === item;
			});
			availableTimeSlot.forEach((object) => {
				const hourStart: string = this.formatDate(new Date(object.validFor.startDateTime));
				const hourend: string = this.formatDate(new Date(object.validFor.endDateTime));
				const result: string = hourStart + ' A ' + hourend;
				franjasDisponibles.push(result);
			});
			const objetosFranjasHorarias = franjasDisponibles.map((franja) => {
				const [inicio, fin] = franja.split(' A ');
				return { inicio, fin };
			});
			objetosFranjasHorarias.sort((a, b) => {
				const [aInicioHora, aInicioMinutos] = a.inicio.split(':');
				const [bInicioHora, bInicioMinutos] = b.inicio.split(':');
				return Number(bInicioHora) - Number(aInicioHora) || Number(bInicioMinutos) - Number(aInicioMinutos);
			});
			const franjasHorariasOrdenadas = objetosFranjasHorarias.map((franja) => `${franja.inicio} A ${franja.fin}`);
			const object: FechasCita = {
				fecha: new Date(item).getTime(),
				franjasDisponibles: franjasHorariasOrdenadas,
			};
			fechascita.push(object);
		});
		const citasDisponiblesResponse: CitasDisponiblesResponse = {
			errorCode: 100,
			error: '',
			fechasCita: fechascita,
		};
		return citasDisponiblesResponse;
	}

	public formatDate(date: Date): string {
		return `${date.getUTCHours().toString().padStart(2, '0')}:${date.getUTCMinutes().toString().padStart(2, '0')}`;
	}

	public getCitasDisponiblesOTResponse(
		idCliente: string,
		idOrden: string,
		cambioCita: number,
		fechaPlanificacion: string
	): Observable<AppointmentResponse> {
		const apiUrl: string = API_URLS.Appointment.getCitasDisponiblesOT;
		const httpOptions: { [key: string]: HttpHeaders } = {
			headers: new HttpHeaders({
				'Content-Type': 'application/json',
				'Accept': 'application/json',
			}),
		};
		httpOptions.headers.append(
			'Authorization',
			'Bearer ' + this.storageService.getLocalStorage(defines.LOCAL_STORAGE_KEYS.ACCESS_TOKEN)
		);
		const DateTime: Date = new Date();
		let startDateTime: string;
		if (this.hasCurrentOrderAutec()) {
			startDateTime = this.resolveDateAUTECFlow(cambioCita, DateTime);
		} else {
			startDateTime = this.resolveDateTechnitial(cambioCita, DateTime, fechaPlanificacion);
		}

		const data: BodyGetCitasDisponibles = {
			relatedEntity: [
				{
					'id': idCliente,
					'@referredType': 'BillingAccount',
					'role': 'Customer',
				},
				{
					'id': idOrden,
					'@referredType': 'ProductOrder',
					'role': 'ProductOrder',
				},
			],
			requestedTimeSlot: [
				{
					validFor: {
						startDateTime: startDateTime,
					},
				},
			],
		};
		return this.http.post<AppointmentResponse>(apiUrl, data, httpOptions);
	}

	private resolveDateAUTECFlow(cambioCita: number, DateTime: Date): string {
		if (cambioCita === 2) {
			return `${this.datePipe.transform(
				DateTime.setDate(DateTime.getDate() + 1).toString(),
				'YYYY-MM-DDTHH:mm:ss',
				'x'
			)}Z`;
		} else if (cambioCita === 3) {
			return `${this.datePipe.transform(
				DateTime.setDate(DateTime.getDate() + 3).toString(),
				'YYYY-MM-DDTHH:mm:ss',
				'x'
			)}Z`;
		}
	}

	private resolveDateTechnitial(cambioCita: number, DateTime: Date, fechaPlanificacion: string): string {
		if (cambioCita === 1 || cambioCita === 4) {
			return `${this.datePipe.transform(
				DateTime.setDate(DateTime.getDate() + 1).toString(),
				'YYYY-MM-DDTHH:mm:ss',
				'x'
			)}Z`;
		} else if (cambioCita === 2 || cambioCita === 5) {
			return `${this.datePipe.transform(
				DateTime.setDate(DateTime.getDate() + 2).toString(),
				'YYYY-MM-DDTHH:mm:ss',
				'x'
			)}Z`;
		} else if (cambioCita === 6) {
			return `${this.datePipe.transform(
				DateTime.setDate(DateTime.getDate() + 3).toString(),
				'YYYY-MM-DDTHH:mm:ss',
				'x'
			)}Z`;
		} else {
			fechaPlanificacion = (+fechaPlanificacion + 86400000).toString();
			return `${this.datePipe.transform(fechaPlanificacion, 'YYYY-MM-DDTHH:mm:ss', 'x')}Z`;
		}
	}

	public cambioCitaOT(idCliente: string, idOrden: string, fecha: string): Observable<AppointmentChange> {
		const formatDate = this.datePipe.transform(fecha, 'YYYY-MM-DDTHH:mm:ss', 'YYYY-MM-DD HH:mm') + 'Z';
		const sameDate: boolean = this.availableTime.some((res) => {
			const dateValidBack: string = res.validFor.startDateTime.replace('Z', '');
			const dateValidFront: string = formatDate.replace(':00Z', '');
			return dateValidBack === dateValidFront;
		});
		if (sameDate) {
			this.noMatchingAppointments = false;
			const apiUrl: string = API_URLS.Appointment.GestionCitasOT;
			const httpOptions = {
				headers: new HttpHeaders({
					'Content-Type': 'application/json',
					'Accept': 'application/json',
				}),
			};
			httpOptions.headers.append(
				'Authorization',
				'Bearer ' + this.storageService.getLocalStorage(defines.LOCAL_STORAGE_KEYS.ACCESS_TOKEN)
			);
			const data: BodyCambioCita = {
				relatedEntity: [
					{
						'id': idCliente,
						'@referredType': 'BillingAccount',
						'role': 'Customer',
					},
					{
						'id': idOrden,
						'@referredType': 'ProductOrder',
						'role': 'ProductOrder',
					},
				],
				validFor: { startDateTime: formatDate },
			};
			return this.http.post<AppointmentChange>(apiUrl, data, httpOptions).pipe(
				map((res) => {
					return res;
				})
			);
		} else {
			this.noMatchingAppointments = true;
			this.tagging.sendPage(['error verificación cita']);
			this.tagging.sendInteraction({
				link_type: formatDate,
			});
			return of();
		}
	}

	public activarRouter(idCliente: string, idOrden: string): Observable<unknown> {
		const apiUrl: string = API_URLS.faultManagement.getTicketTemplate.replace('{CustomerAccountId}', idCliente);
		const httpOptions: Object = {
			headers: new HttpHeaders({
				'Content-Type': 'application/json',
				'Accept': 'application/json',
			}),
		};
		const data: BodyActivarRouter = {
			inputs: [
				{
					name: 'ordenId',
					value: idOrden,
				},
			],
			serviceType: 'EquipmentActivation',
		};
		return this.http.post<unknown>(apiUrl, data, httpOptions).pipe(
			map((res) => {
				return res;
			})
		);
	}

	public postDocumentosScoring(
		siteId: string,
		idOrden: string,
		documentsStatus: DocumentStatus[]
	): Observable<boolean> {
		const observables: Observable<string>[] = [];
		documentsStatus.forEach((doc) => {
			const observable: Observable<string> = this.generateDocument(doc, idOrden, siteId);
			observables.push(observable);
		});
		return combineLatest(observables).pipe(
			map((respuestas) => {
				this.avanzarOrden(siteId, idOrden, '').subscribe();
				this.slidService.postInsertInteractionSLID(siteId, InteractionType.SCORING, idOrden).subscribe();
				return respuestas.length === documentsStatus.length;
			})
		);
	}
	generateDocument(doc: DocumentStatus, idOrden: string, siteId: string): Observable<string> {
		const dni: string = this.storageService.userProfile.document.id;
		const url: string = API_URLS.Documentum.post;
		const httpOptions: { [key: string]: HttpHeaders } = {
			headers: new HttpHeaders({
				'Content-Type': 'application/json',
				'Accept': 'application/json',
			}),
		};
		httpOptions.headers.append(
			'Authorization',
			'Bearer ' + this.storageService.getLocalStorage(defines.LOCAL_STORAGE_KEYS.ACCESS_TOKEN)
		);
		let body: {
			type: string;
			description: String | ArrayBuffer;
			characteristic: Array<{ name: string; value: string }>;
		};
		body = {
			type: defines.GenerateDocumentBodyType,
			description: doc.b64,
			characteristic: [
				{
					name: defines.Migration.Documentun.characteristic.siteId,
					value: siteId,
				},
				{
					name: defines.Migration.Documentun.characteristic.documentum_object_name,
					value: doc.selectedFile?.[0].name,
				},
				{
					name: defines.Migration.Documentun.characteristic.extension_of_doc,
					value: (doc.selectedFile?.[0]?.name || '').split('.').pop(),
				},
				{
					name: defines.Migration.Documentun.characteristic.fiscalNum,
					value: dni,
				},
				{
					name: defines.Migration.Documentun.characteristic.subtipe,
					value: this.getSubtypeDoc(doc),
				},
				{
					name: defines.Migration.Documentun.characteristic.idOrden,
					value: idOrden,
				},
			],
		};
		return this.http.post<string>(url, body, httpOptions).pipe(
			map((res) => {
				return res;
			})
		);
	}

	getSubtypeDoc(documento: DocumentStatus): string {
		let subtypeDoc: string = '';
		switch (documento.docType.toUpperCase()) {
			case defines.SubtypeDoc.deposit:
				subtypeDoc = '0';
				break;
			case defines.SubtypeDoc.P:
				subtypeDoc = '1';
				break;
			case defines.SubtypeDoc.B:
				subtypeDoc = '2';
				break;
			case defines.SubtypeDoc.C:
				subtypeDoc = '3';
				break;
			case defines.SubtypeDoc.A:
				subtypeDoc = '4';
				break;
		}
		return subtypeDoc;
	}

	public postRelanzarPortabilidadPack(dataPortabilidad: DataPortabilidadModel): Observable<unknown> {
		const url: string = API_URLS.TypeOrderPortability.avanzarPortabilidad.replace(
			'{idOrden}',
			dataPortabilidad.numeroPedido
		);
		const httpOptions: { [key: string]: HttpHeaders } = {
			headers: new HttpHeaders({
				'Content-Type': 'application/json',
				'Accept': 'application/json',
				'X-VF-API-Process': 'Avanzar_portabilidad',
			}),
		};
		const data: BodyRelanzarPortabilidadPack = {
			orderType: dataPortabilidad.tipoLinea,
			relatedParty: [
				{
					givenName: dataPortabilidad.nombre || '',
					familyName: (dataPortabilidad.apellido1 || '') + ',' + (dataPortabilidad.apellido2 || ''),
					id: dataPortabilidad.numDoc,
					role: dataPortabilidad.docType.toLowerCase() === 'cif' ? 'Organization' : 'Individual',
					nationality: dataPortabilidad.nationality,
					individualIdentification: [
						{ identificationId: dataPortabilidad.numDoc, identificationType: dataPortabilidad.docType },
					],
				},
			],
			orderItem: [
				{
					resource: {
						id: dataPortabilidad.numPortar,
						name: 'PhoneNumber',
						resourceCharacteristic: [
							{
								name: 'ContractType',
								value: 'Postpago',
							},
						],
					},
				},
				{
					resource: {
						id: dataPortabilidad.iccid || '',
						name: 'ICCD',
					},
				},
			],
		};
		if (dataPortabilidad.type) data.relatedParty[0]['@type'] = dataPortabilidad.type;
		return this.http.patch<unknown>(url, data, httpOptions).pipe(
			map((res) => {
				return res;
			})
		);
	}

	public getFirmaDigital(): Observable<DigitalSign> {
		const digitalSignObject: DigitalSign = {
			errorCode: 100,
			error: '',
			pendienteFirma: false,
			url: [],
		};
		return this.getEstadoFirmaDigital().pipe(
			map((res: GetOrders) => {
				res?.map((item) => {
					this.productOrder[item.id] = item;
					this.setSubOrder(item);
					item.agreement?.map((agreement) => {
						if (agreement.name === 'FirmaDigital') {
							digitalSignObject.pendienteFirma = true;
							digitalSignObject.url.push(agreement.href);
						}
					});
				});
				this.firmaDigitalRes = digitalSignObject;
				return digitalSignObject;
			})
		);
	}

	public setSubOrder(item: GetOrder): void {
		this.subOrder[item.id] = [];
		item.productOrderItem.forEach((el) => {
			if (el.product.name === defines.subOrders.MEGTT && this.isSapValid(el)) {
				this.subOrder[item.id].push(el);
				this.hasSubOrders = true;
			}
		});
		if (this.hasSubOrders) {
			this.setLogisticState(item);
			if (this.isDelivered(this.logisticState) || this.isStatusReturn(this.logisticState)) {
				this.setDataShippingOrders(item?.id);
				this.setIdFatherSubOrder(item);
				this.setDelivery(item);
			}
		}
	}

	public setLogisticState(item: GetOrder): void {
		this.logisticState = item.note?.find(
			(el) => el.id?.toLowerCase() === defines.subOrders.logisticStatus.toLowerCase()
		)?.text;

		this.subOrder[item.id]?.forEach((elem) => {
			elem.logisticState = this.logisticState;
		});
	}

	public setIdFatherSubOrder(item: GetOrder): void {
		this.subOrder[item.id]?.forEach((elem) => {
			elem.idFather = item.id;
		});
	}

	public setDelivery(item: GetOrder): void {
		const delivery: string = item?.note?.find(
			(el) => el.id?.toLowerCase() === tabsMyOrders.deliveryType.toLowerCase()
		)?.text;
		const costsDelivery: number = item?.productOrderItem?.find(
			(elem) => elem?.product?.name?.toLowerCase() === defines.subOrders.CDTME.toLowerCase()
		)?.itemPrice[0]?.price?.taxIncludedAmount?.value;

		this.subOrder[item.id]?.forEach((elem) => {
			elem.deliveryType = delivery;
			elem.priceDelivery = costsDelivery ?? 0;
			elem.dateOrderInit = new Date(item?.orderDate)?.getTime()?.toString();
			elem.priceDevice = elem?.itemPrice?.find(
				(result) => result?.priceType?.toLowerCase() === returnDefines.oneOffPrice.toLowerCase()
			)?.price?.taxIncludedAmount?.value;
			elem.stateOrderCompleted = item?.state?.toLowerCase() === orderTypesKeys.completed.toLowerCase();
		});
	}

	public isSapValid(productOrderItem: ProductOrderItem): boolean {
		return productOrderItem.characteristic.some(
			(el) =>
				el.name.toUpperCase() === defines.subOrders.sap.toUpperCase() &&
				!this.translate.instant('v10.myOrder.subOrders.sapsNotAllowed').includes(el.value)
		);
	}

	public getEstadoFirmaDigital(): Observable<GetOrders> {
		const date: string[] = this.setDateGet();
		const url: string = API_URLS.ProductOrderingManagement.ProductOrderDigitalSignature.replace(
			'{dni}',
			this.storageService?.userProfile?.document?.id
		)
			.replace('{fromdate}', date[0])
			.replace('{todate}', date[1]);
		const headers: HttpHeaders = new HttpHeaders({
			'Content-Type': 'application/json',
			'X-VF-API-Process': 'QueryVisibleOT',
		});
		return this.http.get(url, { headers }).pipe(
			map((res: GetOrders) => {
				this.productOrders = res;
				return res;
			})
		);
	}

	public setDateGet(): string[] {
		const now: Date = new Date();
		const date: moment.Moment = moment();
		const fromData: string = date.subtract(1, monthMoment).toISOString();
		const toDate: string = now.toISOString();
		return [fromData, toDate];
	}

	public cancelarCanje(idCliente: string, idOrden: string, estadoEE: string): Observable<Object> {
		const url: string = API_URLS.ProductOrderingManagement.ProductOrderUpdate.replace('{id}', idOrden);
		let headers: HttpHeaders = new HttpHeaders();
		headers = headers.append('Content-Type', 'application/json');
		const options: Object = {
			headers: headers,
		};
		const body: BodyCancelarCanje = {
			subState: estadoEE,
			productOrderItem: [
				{
					id: '01',
					action: 'add',
					state: 'InProgress',
					name: '',
				},
			],
			relatedParty: [
				{
					'@referredType': 'Customer',
					'role': 'Customer',
					'id': idCliente,
				},
			],
		};
		return this.http.patch(url, body, options).pipe(
			map((res) => {
				return res;
			})
		);
	}

	public avanzarOrden(idCliente: string, idOrden: string, estadoEE: string): Observable<unknown> {
		const url: string = API_URLS.ProductOrderingManagement.ProductOrderUpdate.replace('{id}', idOrden);
		let headers: HttpHeaders = new HttpHeaders();
		headers = headers.append('Content-Type', 'application/json');
		const options: Object = {
			headers: headers,
		};
		const body: BodyAvanzarOrden = {
			subState: estadoEE,
			productOrderItem: [
				{
					id: '01',
					action: 'add',
					state: 'InProgress',
					name: '',
				},
			],
			relatedParty: [
				{
					'role': 'Customer',
					'id': idCliente,
					'@referredType': 'Customer',
				},
			],
		};
		return this.http.patch(url, body, options).pipe(
			map((res) => {
				return res;
			})
		);
	}

	public setDateRequest(date: string): void {
		if (date) {
			const formatDate: string = new Date(date).toDateString();
			this.dateRequest = new Date(formatDate).getTime();
		}
	}

	public isDelivered(logisticStatus: string): boolean {
		return this.translate.instant('v10.myOrder.OLStatuses.delivered').includes(logisticStatus);
	}

	public getShippingOrder(idOrden: string): Observable<PedidoHistoricoOLResponseNew[]> {
		const url: string = API_URLS.HistoryOl.getOrderHistoryOL.replace('{id}', idOrden);
		const httpOptions: { [key: string]: HttpHeaders } = {
			headers: new HttpHeaders({
				'Content-Type': 'application/json',
				'Accept': 'application/json',
			}),
		};
		httpOptions.headers.append(
			'Authorization',
			'Bearer ' + this.storageService.getLocalStorage(defines.LOCAL_STORAGE_KEYS.ACCESS_TOKEN)
		);
		return this.http.get(url, httpOptions).pipe(
			map((res: PedidoHistoricoOLResponseNew[]) => {
				return res;
			})
		);
	}
	public formatDateDeliverd(resp: PedidoHistoricoOLResponseNew[]): string {
		return new Date(
			resp?.[0].lastUpdateDate?.monthValue +
				tabsMyOrders.forWardSlash +
				resp?.[0].lastUpdateDate?.dayOfMonth +
				tabsMyOrders.forWardSlash +
				resp?.[0].lastUpdateDate?.year +
				setOB.spacer +
				resp?.[0].lastUpdateDate?.hour +
				defines.defines.textDoublePoint +
				resp?.[0].lastUpdateDate?.minute
		)
			.getTime()
			.toString();
	}

	public setDataShippingOrders(idOrden: string): void {
		const itemShipping: DataShippingOrders = new DataShippingOrders();
		this.dataShippingOrder[idOrden] = {};
		this.getShippingOrder(idOrden).subscribe((result: PedidoHistoricoOLResponseNew[]) => {
			itemShipping.idOrder = idOrden;
			itemShipping.address = result[0]?.relatedParty[0]?.contactMedium[0]?.characteristic?.street1;
			itemShipping.trackOrder = result[0]?.shippingOrderItem[0]?.shipment?.shipmentTracking?.id;
			itemShipping.dateDelivery = this.formatDateDeliverd(result);
			this.dataShippingOrder[idOrden] = itemShipping;
		});
	}
	hasCurrentOrderAutec(): boolean {
		const currentOrder: GetOrder = this.productOrders?.find((order) => order.id === this.detailOrderData?.numeroPedido);

		return (
			currentOrder?.productOrderItem.some(
				(orderItem) => orderItem.product?.name?.toLocaleLowerCase() === defines.AUTEC.toLocaleLowerCase()
			) || false
		);
	}

	public isStatusReturn(logisticStatus: string): boolean {
		return this.translate.instant('v10.myOrder.OLStatuses.return').includes(logisticStatus);
	}
	public checkDelivered(item: GetOrder, Orderitem: OrdenObtenerPedidos): void {
		const status: string = this.productOrder[item?.id]?.note?.find(
			(el) => el.id?.toLowerCase() === defines.subOrders.logisticStatus.toLowerCase()
		)?.text;
		if (this.isDelivered(status) && this.subOrder[item.id]?.length > 0) {
			Orderitem.delivered = true;
		}
	}

	public insertInteraction(
		idCliente: string,
		tipoInteraccion: InteractionType,
		idOrden?: string
	): Observable<InsertInteraction> {
		const interaccion: GeneralInteraccionModel = this.translate.instant(
			`v10.myOrder.tiposInteracciones.${tipoInteraccion.toLowerCase()}`
		);
		let headers: HttpHeaders = new HttpHeaders();
		headers = headers.append(iteractionDxl.headersName, iteractionDxl.headersValue);
		const options: { [key: string]: HttpHeaders } = {
			headers: headers,
		};
		const url: string = API_URLS.Iteraction.getInteraction;
		const body: GeneralPartyInteractionBody = {
			description: interaccion.comentario,
			status: interaccion.accion,
			channel: [
				{
					id: interaccion.tipo,
				},
			],
			relatedParty: [
				{
					id: interaccion.grupoTrabajo,
					role: iteractionDxl.roleWorkgroup,
				},
			],
			interactionItem: [
				{
					id: interaccion.codigoApertura,
					resolution: interaccion.resultado,
					item: {
						id: idCliente,
						role: iteractionDxl.roleCustomer,
					},
					note: [
						{
							author: iteractionDxl.note1,
							text: interaccion.razon1,
						},
						{
							author: iteractionDxl.note2,
							text: interaccion.razon2,
						},
						{
							author: iteractionDxl.note3,
							text: idOrden || interaccion.razon3,
						},
					],
				},
			],
		};
		return this.http.post(url, body, options).pipe(
			map((res: InsertInteraction) => {
				return res;
			})
		);
	}

	public setExceptionCode(item: GetOrder, Orderitem: OrdenObtenerPedidos): void {
		Orderitem.exceptionCode = this.productOrder[item?.id]?.note?.find(
			(el) => el.id?.toLowerCase() === autoiMigration.exceptionCode.toLowerCase()
		)?.text;
	}

	public goToChat(numeroPedido: string, flow: string, step: string, motive: string): void {
		this.utilsService.openAutoiChat(numeroPedido, flow, step, motive);
	}
}
