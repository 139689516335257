import { Component, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { tagging } from '../../../../../../config/tagging-config';
import { AppService } from '../../../../../app.service';
import { TaggingHelperService } from '../../../../../core/services/tagging.helper.service';
import { UtilitiesService } from '../../../../../shared/utils/utilities.service';
import { AsessorTag, AsessorTagEvent } from '../../../../models/asessor-order.model';
import { DocumentumModel } from '../../../../models/documentum.model';
import { CompanyService } from '../../../../services/company.service';
import { DocumentumService } from '../../../../services/documentum.service';

@Component({
	selector: 'sp-documentum',
	templateUrl: './documentum.component.html',
	styleUrls: ['./documentum.component.scss'],
})
export class DocumentumComponent implements OnInit {
	showSkeleton: boolean = true;
	showSpinner: boolean = false;
	showError: boolean = false;
	contenido: boolean = true;
	donwloandError: boolean = false;
	listDocuments: DocumentumModel[];
	listTypes: Object;
	taggingVariabels: AsessorTag;

	constructor(
		private documentumService: DocumentumService,
		public readonly appService: AppService,
		private translate: TranslateService,
		private utilitiesService: UtilitiesService,
		private taggingHelper: TaggingHelperService,
		private companyService: CompanyService
	) {}

	ngOnInit() {
		this.getTypes();
		this.getListDocuments();
		this.taggingVariabels = { ...tagging.asessorDigitalScreen };
	}

	getTypes(): void {
		this.translate.get('v10').subscribe((v10) => {
			this.listTypes = v10.dashboard.overlay_assessor.document_manager.type;
		});
	}

	getListDocuments(): void {
		const cif: string = this.companyService.selectedCompanyId;
		this.documentumService.getDocumentsMicro(cif).subscribe(
			(data) => {
				if (data.length > 0) {
					this.listDocuments = data;
				} else {
					this.contenido = false;
				}
				this.showSkeleton = false;
			},
			(error) => {
				this.showSkeleton = false;
				this.showError = true;
			}
		);
	}

	downloadDocument(document: DocumentumModel): void {
		this.showSpinner = true;
		this.documentumService.downloadDocumentAsBlob(document).subscribe(
			(data) => {
				this.showSpinner = false;
			},
			(error) => {
				this.showSpinner = false;
				this.showError = true;
				this.donwloandError = true;
			}
		);
	}

	errorBack(): void {
		this.showError = false;
		this.donwloandError = false;
	}

	getDocumentType(document: DocumentumModel): string {
		const number: string = document.characteristic[5].value;
		return this.listTypes[number];
	}

	getDocumentDateFormat(document: DocumentumModel): string {
		return this.utilitiesService.getDateWithMonth(document.characteristic[7].value, true, true);
	}

	trackAction(option: AsessorTagEvent): void {
		const taggingVariabels2: AsessorTag = { ...tagging.asessorDigitalScreen };
		this.taggingHelper.track(option.data.event_name, option.data, taggingVariabels2.view);
	}
}
