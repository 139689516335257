import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { Mva10SelectFieldModel, OverlayModel } from '@mva10/mva10-angular';
import { TranslateService } from '@ngx-translate/core';
import { TaggingHelperService } from 'src/app/core/services/tagging.helper.service';
import { NewTaggingJsonModel } from 'src/app/shared/models/new-tagging-json-model';
import { NewTaggingStateListModel } from 'src/app/shared/models/new-tagging-state-list.model';
import { tagging } from '../../../../config/tagging-config';
import { AppService } from '../../../app.service';
import { NewTaggingHelperService } from '../../../core/services/new-tagging.helper.service';
import { MyAccountService } from '../../../my-account/my-account.service';
import { FOLDER_MY_PAYMENT_METHODS, PAYMENTS_TRACKING } from '../../constants/archetype/paymentsTracking';
import { BIZTALKERROR, Validation, documentOptions, pathEBillConfigPath, paymentMethod } from '../../constants/defines';
import { PaymentJourneyProcess } from '../../enums/payment-journey-process.enum';
import { EditBankAccountInput, UpdateBankAccountInfo } from '../../models/account-edit-details.model';
import { CrossVariables } from '../../models/tagging.model';
import { CustomerAccountService } from '../../services/customer-account.service';
import { ibanValidator } from '../../utils/iban.validator';

@Component({
	selector: 'sp-edit-bank-account-overlay',
	templateUrl: './edit-bank-account-overlay.component.html',
	styleUrls: ['./edit-bank-account-overlay.component.scss'],
})
export class EditBankAccountOverlayComponent implements OnInit {
	overlayData: OverlayModel = {
		isFullScreen: true,
		showButtons: true,
		showBackButton: false,
	};
	showEditOverlay: boolean;
	successUpdate: boolean = false;
	pageOverlay: number = 1;
	loadOverlayPages: boolean;
	operation: string;
	pagesTagging: { [key: string]: Function } = {};
	documentOptions: Mva10SelectFieldModel[] = [];
	editBankForm: UntypedFormGroup;
	formData: Record<string, EditBankAccountInput>;
	okPaymentStatusMessage: string;
	okPaymentStatusTitle: string;

	@Output() okayButtonClicked: EventEmitter<void> = new EventEmitter<void>();
	@Output() closed: EventEmitter<void> = new EventEmitter<void>();

	constructor(
		private myAccountService: MyAccountService,
		private translate: TranslateService,
		private fb: UntypedFormBuilder,
		private customerAccountService: CustomerAccountService,
		public appService: AppService,
		private newTagging: NewTaggingHelperService,
		private taggingHelper: TaggingHelperService
	) {}

	ngOnInit(): void {
		this.initForm();

		this.setOperation();
		this.setOverlayTitle();
		this.setPagesTagging();

		this.showEditOverlay = true;
		setTimeout(() => (this.loadOverlayPages = true), 500);
		this.sendPageTagging();
	}

	/** Method for setting operation either 'add' for new account or 'replace' for editing */
	setOperation(): void {
		if (this.myAccountService.isEditBankInfo) {
			this.operation = paymentMethod.Operations.replace;
		} else {
			this.operation = paymentMethod.Operations.add;
		}
	}

	/** Method for setting tagging function for each page */
	setPagesTagging(): void {
		this.pagesTagging = {
			page1: this.getTaggingOfPage(PAYMENTS_TRACKING.MY_PAYMENT_METHODS.STATES.EDIT_BANK_DATA),

			page2: this.getTaggingOfPage(PAYMENTS_TRACKING.MY_PAYMENT_METHODS.STATES.EDIT_BANK_DATA_VERIFY_ACCOUNT),

			page3: this.getTaggingOfPage(PAYMENTS_TRACKING.MY_PAYMENT_METHODS.STATES.EDIT_BANK_DATA_SMS_CONFIRMATION),

			okPage: this.getTaggingOfPage(PAYMENTS_TRACKING.MY_PAYMENT_METHODS.STATES.EDIT_BANK_DATA_OK),
			okOldAccountPage: this.getTaggingOfPage(
				PAYMENTS_TRACKING.MY_PAYMENT_METHODS.STATES.EDIT_BANK_DATA_OK_OLD_ACCOUNT
			),
			okNewAccountPage: this.getTaggingOfPage(
				PAYMENTS_TRACKING.MY_PAYMENT_METHODS.STATES.EDIT_BANK_DATA_OK_NEW_ACCOUNT
			),
			okUnknownAccountPage: this.getTaggingOfPage(
				PAYMENTS_TRACKING.MY_PAYMENT_METHODS.STATES.EDIT_BANK_DATA_OK_UNKNOWN_ACCOUNT
			),
			koPage: (error?: { code: string; description: string }) =>
				this.getTaggingOfPage(PAYMENTS_TRACKING.MY_PAYMENT_METHODS.STATES.EDIT_BANK_DATA_KO, error),
		};
	}
	/**
	 * Method that return a tagging function to set tagging for each page
	 * @param stateName {string} state name
	 * @param error error object (ecode, description) to use in error_list variable in error page tagging
	 * @returns {Function} return tagging function to set tagging for each page
	 */
	getTaggingOfPage(stateName: string, error?: { code: string; description: string }): Function {
		return this.taggingForEditBankAccountScreens.bind(this, stateName, error);
	}

	/**
	 * Initializes the form group
	 */
	initForm(): void {
		const nameValidators: Validators[] = [
			Validators.maxLength(20),
			Validators.pattern(Validation.spanishName),
			Validators.pattern(/[\S]/), // Do not allow blank spaces only
		];

		this.editBankForm = this.fb.group({
			iban: ['', [Validators.required, ibanValidator()]],
			sameUser: false,
			name: ['', [Validators.required, ...nameValidators]],
			surname: ['', [Validators.required, ...nameValidators]],
			secondSurname: ['', nameValidators],
			documentType: ['', [Validators.required]],
			document: ['', [Validators.required, Validators.pattern(Validation.NIFNIE)]],
		});

		this.formData = {
			iban: {
				label: this.translate.instant('v10.myAccount.bankAccount.addEditBankAccount.bankAccountTitle'),
				invalidErrorText: this.translate.instant('v10.myAccount.bankAccount.addEditBankAccount.editableBankError'),
				successText: this.translate.instant('v10.myAccount.bankAccount.addEditBankAccount.editableBankSuccess'),
			},
			name: {
				label: this.translate.instant('v10.myAccount.bankAccount.addEditBankAccount.nameTitle'),
				emptyErrorText: this.translate.instant('v10.myAccount.bankAccount.addEditBankAccount.editableNameError'),
				invalidErrorText: this.translate.instant(
					'v10.myAccount.bankAccount.addEditBankAccount.editableNameFormatError'
				),
			},
			surname: {
				label: this.translate.instant('v10.myAccount.bankAccount.addEditBankAccount.surnameTitle'),
				emptyErrorText: this.translate.instant('v10.myAccount.bankAccount.addEditBankAccount.editableSurnameError'),
				invalidErrorText: this.translate.instant(
					'v10.myAccount.bankAccount.addEditBankAccount.editableSurnameFormatError'
				),
			},
			secondSurname: {
				label: this.translate.instant('v10.myAccount.bankAccount.addEditBankAccount.secondSurnameTitle'),
				invalidErrorText: this.translate.instant(
					'v10.myAccount.bankAccount.addEditBankAccount.editableSurnameFormatError'
				),
			},
			documentType: {
				label: this.translate.instant('v10.myAccount.bankAccount.addEditBankAccount.documentTypeTitle'),
			},
			document: {
				label: this.translate.instant('v10.myAccount.bankAccount.addEditBankAccount.documentTitle'),
				emptyErrorText: this.translate.instant('v10.myAccount.bankAccount.addEditBankAccount.editableDocumentError'),
				invalidErrorText: this.translate.instant(
					'v10.myAccount.bankAccount.addEditBankAccount.editableDocumentFormatError'
				),
			},
		};

		const options: string[] = this.translate.instant('v10.myAccount.bankAccount.addEditBankAccount.documentOptions');

		options.forEach((option) => {
			this.documentOptions.push({
				name: option,
				value: option,
			});
		});

		this.editBankForm.controls['documentType'].setValue(this.documentOptions[0].value);
	}

	/**
	 * Method for adding space between each 4 digits
	 * @param iban should be IBAN field all the time
	 */
	formatIBAN(iban: string): void {
		this.editBankForm.controls['iban'].patchValue(
			iban
				?.toUpperCase()
				.replace(/\s/g, '')
				?.match(/.{1,4}/g)
				?.join(' ')
		);
	}

	/** Method for reset all flags of showing the overlays */
	closeOverlay(): void {
		this.showEditOverlay = false;
		setTimeout(() => this.closed.emit(), 500);
	}

	/**
	 * Method for changing overlay page number and change its title
	 * @param pageNumber the page number of overlay pages
	 */
	changePage(pageNumber: number): void {
		this.pageOverlay = pageNumber;
		this.setOverlayTitle();
		this.sendPageTagging();
	}

	saveEditBankAccount(): void {
		if (this.editBankForm.valid) {
			this.changePage(2);
			this.updateBankAccount();
		} else {
			this.editBankForm.markAllAsTouched();
		}
	}

	/** Method for sending tagging of a page first 3 pages */
	sendPageTagging(): void {
		this.pagesTagging[`page${this.pageOverlay}`]();
	}

	/** Set overlay title after each navigation between pages */
	setOverlayTitle(): void {
		switch (this.pageOverlay) {
			case 1:
				this.overlayData.title = this.translate.instant('v10.myAccount.bankAccount.addEditBankAccount.pageHeaderTitle');
				this.overlayData.hideXButton = false;
				break;
			case 2:
				this.overlayData.title = '';
				this.overlayData.hideXButton = true;
				break;
		}
	}

	/** Method for setting updatedAccountNumber data to refresh billing account screen when needed */
	updateBankAccount(): void {
		const documentPrefix: string = this.getDocumentPrefix();

		const bankAccountChangeInfo: UpdateBankAccountInfo = {
			holderId: `${documentPrefix}${this.editBankForm.controls['document'].value}`,
			firstName: this.editBankForm.controls['name'].value,
			middleName: this.editBankForm.controls['surname'].value,
			familyName: this.editBankForm.controls['secondSurname'].value,
			iban: this.editBankForm.controls['iban'].value.toUpperCase().replace(/\s/g, ''),
		};

		this.patchNewBankAccountData(bankAccountChangeInfo);
	}

	/** Method for sending a request with new data to edit bank account data */
	patchNewBankAccountData(bankAccountChangeInfo: UpdateBankAccountInfo): void {
		this.toggleVodafoneSpinner(true);
		this.customerAccountService
			.patchPaymentMethod(bankAccountChangeInfo, pathEBillConfigPath.editBankAccount, this.operation)
			.subscribe(
				(response) => {
					if (this.operation === paymentMethod.Operations.add) {
						this.okPaymentStatusTitle = this.translate.instant(
							'v10.myAccount.bankAccout.cashUser.otp.OKConfirmationOverlay.title'
						);
						this.okPaymentStatusMessage = this.translate.instant(
							'v10.myAccount.bankAccout.cashUser.otp.OKConfirmationOverlay.subTitle'
						);
					} else {
						this.okPaymentStatusMessage = this.translate.instant(
							`v10.myAccount.bankAccount.addEditBankAccount.${response.paymentMethod.paymentStatusCode}`
						);
						this.okPaymentStatusTitle = this.translate.instant(
							response.paymentMethod.paymentStatusCode === '1'
								? 'v10.myAccount.bankAccount.addEditBankAccount.changeNexBillTitle'
								: 'v10.myAccount.bankAccout.otp.OKConfirmationOverlay.title'
						);
					}
					this.toggleVodafoneSpinner(false);
					/** Open OK overlay */
					this.openResultOverlay(true);
				},
				(error) => {
					this.toggleVodafoneSpinner(false);
					if (error.error && error.error.ecode && error.error.ecode.toString() === BIZTALKERROR) {
						this.appService.NudgeBiztalkError();
					} else {
						/** Open KO overlay */
						this.openResultOverlay(false, { code: error.error?.ecode, description: error.error?.description });
					}
				}
			);
	}

	/**
	 * Method for opening success/failure overlay
	 * @param isSuccess if true will open success overlay, false will open failure overlay
	 */
	openResultOverlay(isSuccess: boolean, error?: { code: string; description: string }): void {
		this.successUpdate = isSuccess;

		if (isSuccess) {
			this.pagesTagging.okPage();
		} else {
			this.pagesTagging.koPage(error)();
		}
	}

	/**
	 * Method for show/hide Vodafone spinner
	 * @param show if true then show spinner, otherwise hide spinner
	 */
	toggleVodafoneSpinner(show: boolean): void {
		this.appService.showVodaFullLoader = show;
	}

	/** Method for emitting the output and close the tray */
	okayButtonClick(): void {
		this.okayButtonClicked.emit();
		this.closeOverlay();
	}

	/**
	 * Tagging for edit bank account screens
	 * @param stateName {string} state name
	 * @param error for KO page
	 */
	taggingForEditBankAccountScreens(stateName: string, error?: { code: string; description: string }): void {
		this.newTagging.getJsonTagging(FOLDER_MY_PAYMENT_METHODS).subscribe((data: NewTaggingJsonModel) => {
			const state: NewTaggingStateListModel = data.page.stateList[stateName];
			if (this.customerAccountService.hasBankAccount) {
				state.journey.journey_process = PaymentJourneyProcess.bankAccountJourneyProcess;
			} else {
				state.journey.journey_process = PaymentJourneyProcess.cashDeskJourneyProcess;
			}
			if (state.journey.journey_category) {
				state.journey.journey_category = CrossVariables.client_typology;
			}
			if (error) {
				this.taggingHelper.error_list = tagging.staticVariables.editBankAccountErrorList
					.replace('<error_code>', error.code)
					.replace('<error_desc>', error.description);
			}
			this.newTagging.state(state);
		});
	}

	onDocumentTypeChange(): void {
		this.editBankForm.controls['document'].clearValidators();
		switch (this.editBankForm.controls['documentType'].value.toLocaleLowerCase()) {
			case documentOptions.NIFNIE.toLocaleLowerCase():
				this.editBankForm.controls['document'].addValidators([
					Validators.pattern(Validation.NIFNIE),
					Validators.required,
				]);
				this.editBankForm.controls['document'].updateValueAndValidity();
				break;

			case documentOptions.CIF.toLocaleLowerCase():
				this.editBankForm.controls['document'].addValidators([Validators.pattern(Validation.CIF), Validators.required]);
				this.editBankForm.controls['document'].updateValueAndValidity();
				break;

			case documentOptions.Passport.toLocaleLowerCase():
				this.editBankForm.controls['document'].addValidators([
					Validators.pattern(Validation.Passport),
					Validators.required,
				]);
				this.editBankForm.controls['document'].updateValueAndValidity();
				break;
		}

		this.editBankForm.controls['document'].markAsTouched();
	}

	getDocumentPrefix(): string {
		switch (this.editBankForm.controls['documentType'].value.toLocaleLowerCase()) {
			case documentOptions.NIFNIE.toLocaleLowerCase():
				if (this.editBankForm.controls['document'].value.match(/^[XYZ]\d{7,8}[A-Z]$/)) {
					return `${documentOptions.NIE}-`;
				} else {
					return `${documentOptions.NIF}-`;
				}

			case documentOptions.CIF.toLocaleLowerCase():
				return `${documentOptions.CIF}-`;

			case documentOptions.Passport.toLocaleLowerCase():
				return `${documentOptions.Passport}-`;
		}
	}
}
