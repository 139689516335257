import { HttpClient, HttpHeaders, HttpParams, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import * as mime from 'mime-types';
import { Observable, throwError as observableThrowError } from 'rxjs';
import { map } from 'rxjs/operators';
import { Migration } from '../constants/defines';
import { API_URLS } from '../constants/routes-config';
import { DocumentumCharacteristicName, DocumentumType, DocumentumTypeMicro } from '../enums/documentum-type.enum';
import { CharacteristicFilter, DocumentumBinaryModel, DocumentumModel } from '../models/documentum.model';
import { ParamsEncoder } from '../utils/params-encoder.service';
import { UtilitiesService } from '../utils/utilities.service';

@Injectable()
export class DocumentumService {
	private documentumTxt: { [key: string]: any };

	constructor(private http: HttpClient, private translate: TranslateService, private utils: UtilitiesService) {}

	GenerateDocument(
		siteId: string,
		dni: string,
		ticket: { [key: string]: any },
		object_name?: string
	): Observable<Object> {
		const url: string = API_URLS.Documentum.post;
		let body: { type: string; description: string; characteristic: Array<{ name: string; value: string }> };
		this.translate.get('migration.documentum.itemList').subscribe((msg) => {
			this.documentumTxt = msg;
			body = {
				type: this.documentumTxt.documentum_type.body,
				description: ticket.description,
				characteristic: [
					{
						name: Migration.Documentun.characteristic.siteId,
						value: siteId,
					},
					{
						name: Migration.Documentun.characteristic.documentum_object_name,
						value: object_name
							? object_name.replace('{0}', dni)
							: this.documentumTxt.documentum_object_name.body.replace('{0}', dni),
					},
					{
						name: Migration.Documentun.characteristic.extension_of_doc,
						value: this.documentumTxt.extension_of_doc.body,
					},
					{
						name: Migration.Documentun.characteristic.fiscalNum,
						value: dni,
					},
					{
						name: Migration.Documentun.characteristic.subtipe,
						value: this.documentumTxt.documentum_subtipo.body,
					},
				],
			};
		});

		let headers: HttpHeaders = new HttpHeaders();
		headers = headers.append('Content-Type', 'application/json');

		return this.http.post(url, body, { headers }).pipe(
			map((res) => {
				return res;
			})
		);
	}

	getScoringDocumentsByNIF(clientNIF: string): Observable<DocumentumModel[]> {
		return this.getDocuments(DocumentumType.scoring, clientNIF, null);
	}

	downloadDocumentAsBinary(document: DocumentumModel): Observable<DocumentumBinaryModel> {
		const url: string = API_URLS.Documentum.getDocumentBinary.replace('{id}', document.id);
		const headers: HttpHeaders = new HttpHeaders({
			'Content-Type': 'application/json',
			'X-VF-API-DocumentFormat': document.characteristic.find(
				(prop) => prop.name === DocumentumCharacteristicName.a_content_type
			).value,
		});
		return this.http.get(url, { headers }).pipe(
			map((res: DocumentumBinaryModel) => {
				try {
					this.downloadDocumentFromBase64String(res);
					return res;
				} catch (error) {
					throw observableThrowError(error);
				}
			})
		);
	}

	downloadDocumentAsBlob(doc: DocumentumModel): Observable<Blob> {
		const url: string = API_URLS.Documentum.getDocumentBinary.replace('{id}', doc.id);
		const headers: HttpHeaders = new HttpHeaders({
			'Content-Type': 'application/json',
			'Accept': '*/*',
		});
		return this.http.get(url, { headers, responseType: 'blob', observe: 'response' }).pipe(
			map((res: HttpResponse<Blob>) => {
				try {
					/**
					 * Contains file name like 'attachment; filename="image-name.jpeg"'
					 */
					const contentDisposition: string = res.headers.get('Content-Disposition');
					const fileName: string = contentDisposition
						? contentDisposition.split('"')[1].trim()
						: doc.name + '.' + mime.extension(res.body.type);

					this.utils.downloadBlob(res.body, fileName);
					return res.body;
				} catch (error) {
					throw observableThrowError(error);
				}
			})
		);
	}

	downloadDocumentFromBase64String(documentDetail: DocumentumBinaryModel): void {
		let base64String: string = documentDetail.binaryAttachment[0].content;
		let fileName: string = documentDetail.name || 'download';
		const fileExtension: string =
			documentDetail.characteristic.find((prop) => prop.name === DocumentumCharacteristicName.Format).value || 'pdf';

		if (fileName.split('.').length === 1) {
			fileName += `.${fileExtension}`;
		}

		const b64: string[] = base64String.split('base64,');
		if (b64.length > 0 && b64[1]) {
			base64String = b64[1].trim();
		}
		const binary: string = atob(base64String.replace(/\s/g, ''));
		const len: number = binary.length;
		const buffer: ArrayBuffer = new ArrayBuffer(len);
		const view: Uint8Array = new Uint8Array(buffer);
		for (let i: number = 0; i < len; i++) {
			view[i] = binary.charCodeAt(i);
		}

		const contentType: string = this.getMimeTypeFromFileExtension(fileExtension);
		const blob: Blob = new Blob([view], { type: contentType });

		this.utils.downloadBlob(blob, fileName);
	}

	private getMimeTypeFromFileExtension(fileExtension: string): string {
		const cleanExtension: string = fileExtension.replace(/\./g, '');
		const foundContentType: string | false = mime.contentType(cleanExtension);
		return foundContentType ? foundContentType : 'application/octet-stream';
	}

	private getDocuments(
		documentType: DocumentumType,
		clientNIF?: string,
		siteId?: string,
		searchFilter: CharacteristicFilter = {}
	): Observable<DocumentumModel[]> {
		const url: string = API_URLS.Documentum.getDocuments;
		const headers: HttpHeaders = new HttpHeaders({
			'Content-Type': 'application/json',
		});
		let filter: string = '';
		for (const key in searchFilter) {
			if (Object.prototype.hasOwnProperty.call(searchFilter, key)) {
				filter += `@.${key}=='${searchFilter[key]}'&&`;
			}
		}
		const params: {
			[param: string]: string | string[];
		} = {
			documentType,
			[`relatedEntity[(@.role='CustomerAccount')].id`]: siteId,
			[`relatedParty[(@.role='Individual')].id`]: clientNIF,
			filter: filter ? `$.characteristic[?(${filter.slice(0, -2)})]` : null,
		};
		for (const paramKey in params) {
			if (!params[paramKey]) {
				delete params[paramKey];
			}
		}
		return this.http
			.get(url, { headers, params: new HttpParams({ encoder: new ParamsEncoder(), fromObject: params }) })
			.pipe(map((res: DocumentumModel[]) => res));
	}

	getDocumentsMicro(clientCIF: string): Observable<DocumentumModel[]> {
		const documentType: string = DocumentumTypeMicro.scoring;
		const url: string = API_URLS.Documentum.getDocuments;
		const headers: HttpHeaders = new HttpHeaders({
			'Content-Type': 'application/json',
		});
		const params: {
			[param: string]: string | string[];
		} = {
			documentType,
			[`relatedParty[(@.role='Organization')].id`]: clientCIF,
		};
		return this.http
			.get(url, { headers, params: new HttpParams({ encoder: new ParamsEncoder(), fromObject: params }) })
			.pipe(map((res: DocumentumModel[]) => res));
	}
}
