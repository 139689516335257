import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { GenericModule } from '@mva10/mva10-angular';
import { SharedModule } from '../shared/shared.module';
import { LegalRoutingModule } from './legal-routing.module';
import { LegalComponent } from './legal.component';
import { OpenSourceLibrariesComponent } from './open-source-libraries/open-source-libraries.component';
import { PrivacyPolicyComponent } from './privacy-policy/privacy-policy.component';
import { ShopPolicyComponent } from './shop-policy/shop-policy.component';
import { TermsAndConditionComponent } from './terms-and-condition/terms-and-condition.component';

@NgModule({
	declarations: [
		LegalComponent,
		PrivacyPolicyComponent,
		TermsAndConditionComponent,
		OpenSourceLibrariesComponent,
		ShopPolicyComponent,
	],
	imports: [LegalRoutingModule, CommonModule, SharedModule, GenericModule],
	exports: [LegalComponent, PrivacyPolicyComponent, TermsAndConditionComponent, OpenSourceLibrariesComponent],
})
export class LegalModule {}
