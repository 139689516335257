import { ServiceType } from '../enums/serviceType.enum';

export class ServiceBalanceSubscription {
	public id: string;
	public name: string;
	public desc: string;
	public dataSharingType: string;
	public uploadSpeedUnit: string;
	public type: ServiceType;
	public category: Array<string>[];
}
