import { animate, group, state, style, transition, trigger } from '@angular/animations';
import {
	ChangeDetectorRef,
	Component,
	ComponentFactoryResolver,
	OnInit,
	ViewChild,
	ViewContainerRef,
} from '@angular/core';
import { SPMVA10FullOverlayClasses } from '../../constants/defines';
import { Mva10FullOverlayService } from '../../services/mva10-full-overlay.service';

@Component({
	selector: 'sp-mva10-full-overlay',
	templateUrl: './mva10-full-overlay.component.html',
	styleUrls: ['./mva10-full-overlay.component.scss'],
	animations: [
		trigger('openClose', [
			state(
				'open',
				style({
					transform: 'translate(0 , 0)',
					display: 'flex',
				})
			),
			state(
				'closed',
				style({
					transform: 'translate(0 , 100%)',
					display: 'none',
				})
			),
			transition('* => closed', [
				group([
					animate('500ms 100ms ease-in-out', style({ transform: 'translate(0 , 100%)' })),
					animate('100ms 600ms ease-in-out', style({ display: 'none' })),
				]),
			]),
			transition('* => open', [
				group([
					animate('500ms ease-in-out', style({ transform: 'translate(0 , 0)' })),
					animate('500ms ease-in-out', style({ display: 'flex' })),
				]),
			]),
		]),
		trigger('openClosefromRight', [
			state(
				'open',
				style({
					transform: 'translate(0 , 0)',
					display: 'flex',
				})
			),
			state(
				'closed',
				style({
					transform: 'translate(100% , 0)',
					display: 'none',
				})
			),
			transition('* => closed', [
				group([
					animate('500ms 100ms ease-in-out', style({ transform: 'translate(100% , 0)' })),
					animate('100ms 600ms ease-in-out', style({ display: 'none' })),
				]),
			]),
			transition('* => open', [
				group([
					animate('500ms ease-in-out', style({ transform: 'translate(0 , 0)' })),
					animate('500ms ease-in-out', style({ display: 'flex' })),
				]),
			]),
		]),
	],
})
export class Mva10FullOverlayComponent implements OnInit {
	@ViewChild('dynamicCompRef_1', { read: ViewContainerRef, static: true }) dynamicCompRef_1: ViewContainerRef;
	@ViewChild('dynamicCompRef_2', { read: ViewContainerRef, static: true }) dynamicCompRef_2: ViewContainerRef;
	slideClassName1: string = SPMVA10FullOverlayClasses.flush;
	slideClassName2: string = SPMVA10FullOverlayClasses.flush;
	docHeight: string;
	slideClassName2Animation: string = SPMVA10FullOverlayClasses.rightLeft;
	slideClassName1Animation: string = SPMVA10FullOverlayClasses.leftRight;
	constructor(
		public OverlayService: Mva10FullOverlayService,
		private resolver: ComponentFactoryResolver,
		private cd: ChangeDetectorRef
	) {}

	ngOnInit() {
		// this.docHeight = (document.documentElement.clientHeight + 56) + 'px';
		this.waitForDynamicComponent();
		this.OverlayService.closeSpMVA10FullOverLaySubject.subscribe(() => {
			this.closeEventF();
		});
	}
	waitForDynamicComponent() {
		this.OverlayService.openSpMVA10FullOverLaySubject.subscribe((comp_Config) => {
			this.dynamicCompChange(comp_Config);
		});
	}
	dynamicCompChange(comp_Config) {
		if (
			this.slideClassName1 === SPMVA10FullOverlayClasses.flush &&
			this.slideClassName2 === SPMVA10FullOverlayClasses.flush
		) {
			this.showComponent(this.dynamicCompRef_1, comp_Config.comp, this.dynamicCompRef_2);
			this.slideClassName1 = '';
			this.slideClassName2 = SPMVA10FullOverlayClasses.flush;
		} else if (
			this.slideClassName1 !== SPMVA10FullOverlayClasses.flush &&
			this.slideClassName2 === SPMVA10FullOverlayClasses.flush
		) {
			this.showComponent(this.dynamicCompRef_2, comp_Config.comp, this.dynamicCompRef_1);
			this.slideClassName2 = comp_Config.navigation
				? SPMVA10FullOverlayClasses.slideLeft
				: SPMVA10FullOverlayClasses.SlideRight;
			this.slideClassName1 = SPMVA10FullOverlayClasses.flush;
		} else if (
			this.slideClassName1 === SPMVA10FullOverlayClasses.flush &&
			this.slideClassName2 !== SPMVA10FullOverlayClasses.flush
		) {
			this.showComponent(this.dynamicCompRef_1, comp_Config.comp, this.dynamicCompRef_2);
			this.slideClassName1 = comp_Config.navigation
				? SPMVA10FullOverlayClasses.slideLeft
				: SPMVA10FullOverlayClasses.SlideRight;
			this.slideClassName2 = SPMVA10FullOverlayClasses.flush;
		}
		this.animation();
	}
	showComponent(compRef, DynamicComp, dynamcicRefTOclear) {
		const compFactory = this.resolver.resolveComponentFactory(DynamicComp);
		compRef.clear();
		compRef.createComponent(compFactory);
		this.cd.detectChanges();
		this.clearDynamicRef(dynamcicRefTOclear);
	}
	clearDynamicRef(compRef) {
		setTimeout(() => {
			compRef.clear();
		}, 500);
	}
	closeEventF() {
		this.slideClassName1Animation = '';
		this.slideClassName2Animation = '';
		this.clearDynamicRef(this.dynamicCompRef_1);
		this.clearDynamicRef(this.dynamicCompRef_2);
		this.slideClassName2 = SPMVA10FullOverlayClasses.flush;
		this.slideClassName1 = SPMVA10FullOverlayClasses.flush;
	}
	animation() {
		if (
			(this.slideClassName1Animation === SPMVA10FullOverlayClasses.leftRight &&
				this.slideClassName2Animation === SPMVA10FullOverlayClasses.rightLeft) ||
			(this.slideClassName1Animation === '' && this.slideClassName2Animation === '')
		) {
			this.slideClassName1Animation = '';
			this.slideClassName2Animation = SPMVA10FullOverlayClasses.rightLeft;
		} else if (
			this.slideClassName1Animation !== SPMVA10FullOverlayClasses.leftRight &&
			this.slideClassName2Animation === SPMVA10FullOverlayClasses.rightLeft
		) {
			this.slideClassName1Animation = SPMVA10FullOverlayClasses.leftRight;
			this.slideClassName2Animation = '';
		} else if (
			this.slideClassName1Animation === SPMVA10FullOverlayClasses.leftRight &&
			this.slideClassName2Animation !== SPMVA10FullOverlayClasses.rightLeft
		) {
			this.slideClassName2Animation = SPMVA10FullOverlayClasses.rightLeft;
			this.slideClassName1Animation = '';
		}
	}
}
