import { Component } from '@angular/core';
import { LandingComponent } from '../../../products-and-services/landing/landing.component';
import { Product } from '../../models/product.model';

@Component({
	selector: 'sp-landing-sms-bundle',
	templateUrl: './landing-sms-bundle.component.html',
	styleUrls: ['./landing-sms-bundle.component.scss'],
})
export class LandingSmsBundleComponent {
	public parent: LandingComponent;
	public Bundle: Product;
	public isUnlimited: boolean = false;
	valueText = " ";
	hasBar = false;

	constructor() {

	}
	ngOnInit(): void {
		this.valueText = `${this.Bundle.TotalConsumption}/${this.Bundle.TotalConsumption - this.Bundle.ConsumedConsumption} ${this.Bundle.title}`
	}
}
