import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { AppService } from '../app.service';
import { SubscriptionService } from '../core/services/subscription.service';
import { CustomerAccountNewModel } from '../shared/models/customer-account.model';
import { CustomerAccountService } from '../shared/services/customer-account.service';

@Injectable()
export class MyAccountDataResolver {
	constructor(
		private customerAccount: CustomerAccountService,
		private subscription: SubscriptionService,
		private appService: AppService
	) {}
	resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Promise<CustomerAccountNewModel> {
		if (this.subscription.customerData.currentService) {
			return this.customerAccount
				.getMyData(this.subscription.customerData.currentService.siteId)
				.toPromise()
				.then((newAccountData) => {
					if (newAccountData && newAccountData.name) {
						this.appService.initGretting(newAccountData.name);
						sessionStorage.setItem('mva10-LocalStore.key.firstName', newAccountData.name);
					} else {
						return newAccountData;
					}
				})
				.catch(() => null);
		} else {
			return null;
		}
	}
}
