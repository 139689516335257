import { HttpClient, HttpErrorResponse, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { AbstractControl, UntypedFormGroup } from '@angular/forms';
import { DomSanitizer } from '@angular/platform-browser';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { DeviceDetectorService } from 'ngx-device-detector';
import { Observable, throwError } from 'rxjs';
import { catchError, map, switchMap } from 'rxjs/operators';
import { config } from '../../../config/pages-config';
import { tagging } from '../../../config/tagging-config';
import { NewTaggingHelperService } from '../../core/services/new-tagging.helper.service';
import { TaggingHelperService } from '../../core/services/tagging.helper.service';
import {
	BUY_SIM_JOURNEY,
	CommercialJourneys,
	IdentificationType,
	NIEOrNIFLength,
	SHOPTYPE,
	Validation,
	buySimDeliveryMethods,
	isVodafoneClientTagging,
	legalTermsPageName,
	macPlatform,
	notAvailable,
	ontouchstart,
	saveOfferReqBody,
	submitOrderReqBody,
	tabConsumoTagging,
	typeBlob,
} from '../../shared/constants/defines';
import { API_URLS } from '../../shared/constants/routes-config';
import { BuySimSteps } from '../../shared/enums/buy-sim-steps.enum';
import { ModalidadesEntrega } from '../../shared/models/ModalidadesEntrega.models';
import { AddressVerticalInfoModel } from '../../shared/models/buy-sim-address-vertical-info.model';
import { BuySimErrorModel } from '../../shared/models/buy-sim-error-model';
import { IdValidationModel } from '../../shared/models/buy-sim-id-validation.model';
import { LegalTermsItemPostModel } from '../../shared/models/buy-sim-legal-terms-item-post.model';
import { LegalTermsItemModel } from '../../shared/models/buy-sim-legal-terms-item.model';
import { OperatorModel } from '../../shared/models/buy-sim-operator-model';
import { PersonalModel } from '../../shared/models/buy-sim-personal-data.model';
import { PortabilityOwnerModel } from '../../shared/models/buy-sim-portability-owner-model';
import { BuySimPromotion } from '../../shared/models/buy-sim-promotion-model';
import { CartItem, Data, SaveOfferResponse, Service } from '../../shared/models/buy-sim-saveOffer.model';
import { DeliveryOffice } from '../../shared/models/delivery-office.model';
import { IsVodafoneClientModel } from '../../shared/models/isVodafoneClientResponse';
import { ModalidadesEntregaResponse } from '../../shared/models/modalidadesEntregaResponse.model';
import { NewTaggingJsonModel } from '../../shared/models/new-tagging-json-model';
import { ParamsDeliveryAddress, SaveBillingAddress } from '../../shared/models/paramsPostDeliveryAddress';
import { ResPersonalData } from '../../shared/models/res-personal-data.model';
import { ResSaveBillingAddress } from '../../shared/models/res-save-billing-address.model';
import { ShoppingCartModel } from '../../shared/models/shopping-cart.model';
import { StoreCoordinates } from '../../shared/models/store-coordinates.model';
import { DestinosCostesEntrega, TiposEntrega, TiposEntregaResponse } from '../../shared/models/tiposEntrega.model';
import { UtilitiesService } from '../../shared/utils/utilities.service';
import { BuySimDataService } from './buy-sim-data.service';

@Injectable({
	providedIn: 'root',
})
export class BuySimService {
	/** shows module full loader */
	showFullLoader = false;

	/** buy sim steps data */
	steps = [];

	/** tariff to buy
	 * sets from query param
	 */
	tariffName = '';

	/** mark current step in steps component */
	currentStep: BuySimSteps = BuySimSteps.stepZeroPersonalInfo;

	/** prevent router outlet div from scorlling */
	preventScroll = false;

	/** shopping cart obj contains tariff name , tariff price , order id */
	shoppingCart: ShoppingCartModel;

	/** legal terms for personal info page from ntol system */
	legalTerms = new Array<LegalTermsItemModel>();

	/** list of nationalities from ntol */
	nationalityDataList = [];
	/**this list handled to send in saveLigalTerms request in paymentTicket case; */
	AcceptListInResponse: LegalTermsItemModel[];
	AcceptListInRequest: LegalTermsItemPostModel[] = new Array<LegalTermsItemPostModel>();
	/**this is the list in save legal terms request */
	legalTermsPostList: LegalTermsItemPostModel[];
	provinceDataList = [];
	/**Flag for order confirmation page */
	hideStepAndOrderSectionsFlag = false;
	/**this parameter for saveLegalTerms in payment */
	requestOrderId: string;
	/**email in personel info form */
	personelInfoEmail: string;

	/**detects device is desktop to show ticke summary  */
	isDesktopView = false;
	/**detects device is mobile to handle ticket summary */
	isMobileView = false;
	/** shows and hide ticket summary */
	hideTicketDetails = false;
	/**tariff name for tagging */
	tariffNameForTagging: string;
	/**check if we open payment screen in tray */
	isPaymentFullScreen = false;
	/** html data from create shopping api in payment section */
	HTMLDataFromAPI: any;
	/**id ntol request which will display as order id */
	idNtolRequest: string;
	/**List of operators */
	operatorsDataList: any;
	operatorsList: any[];
	/**radio boxes in portability screen */
	newNumberChecked: boolean = true;
	portabilityChecked: boolean = false;

	/**flag to know if the user of buy sim the same of portability owner or not */
	changePortabilityOwner: boolean = false;
	/**promotions Array */
	promotionsArray: BuySimPromotion[] = new Array<BuySimPromotion>();
	documentIdType: number;
	personalInfo: PersonalModel = new PersonalModel();
	portabilityFullName: string;
	/**portability owner object to sending it from edit portability tray to portability screen */
	portabilityOwner: PortabilityOwnerModel = new PortabilityOwnerModel();
	/**list of document type */
	documentIdTypesList: Array<any> = BUY_SIM_JOURNEY.documentIdTypes;
	idFieldPlaceholder: string;
	documentIdMaxLength: number = 9;
	idValidationPattern: string = Validation.NIF;
	showIdBackEndError: boolean = false;
	/**fir prevent background scrolling when overlay is opened */
	preventBackgroundScrolling: boolean = false;
	/**Title and hint for each step*/
	screenTitle: string;
	screenHint: string;
	/**Flag for hideHintText in the last 2 steps in the journey*/
	hideHintText: boolean = false;

	/** payment terms and conditions checkbox value */
	paymentTermsAndConditionFlag: boolean;

	/** saldo initial amount in caseof portability displayed in ticket details */
	portabilityInitialAmount: string = BUY_SIM_JOURNEY.portabilityInitialAmount + ' ' + CommercialJourneys.euros;

	/**flag for selecting new number or portability radio button used in safe offer to update tarif price*/
	newNumberOrPortabilityChecked: boolean = false;

	/**true when submit order at order confirmation screen and order cookies expires */
	journeyEndsFlag: boolean;
	/** flag to show payment time text boolean */
	showPaymentRechargeTime: boolean;
	openAddAddressForm: boolean;
	openManualAddressForm: boolean;
	showMoreAddressDetails: boolean;
	addressText: string;
	isEditManualAddressForm: boolean;
	showStreetNumberFormField: boolean;
	selectedAddressValueFromHistory: boolean;
	showMoreAddressInfo: boolean;
	disableNumberField: boolean;
	isHomeDelivery: boolean = false;
	isCorreosDelivery: boolean = true;
	deliveryDates: TiposEntrega;
	cdTipoDeliveryCorreos: DestinosCostesEntrega;
	postalCode: string;
	selectedAddress: AddressVerticalInfoModel;
	useVerticalInfo: boolean;
	tiposEntrega: TiposEntrega;
	objectUrl: string;
	blob: Blob;
	isVodafoneClientResponse: IsVodafoneClientModel;
	newAddressNormalizedSelected: boolean = false;
	addressVertInfo: AddressVerticalInfoModel;
	dataSaved: boolean = false;
	constructor(
		private translate: TranslateService,
		private buySimDataService: BuySimDataService,
		private router: Router,
		private tagging: TaggingHelperService,
		private deviceService: DeviceDetectorService,
		private readonly sanitizer: DomSanitizer,
		public utilitiesService: UtilitiesService,
		public http: HttpClient,
		private newTagging: NewTaggingHelperService
	) {
		this.translate.get('v10.buySim.itemsList').subscribe((data) => {
			this.steps = [
				data.setpOneLabel.body,
				data.portabilityStepLabel.body,
				data.setpTwoLabel.body,
				data.setpThreeLabel.body,
			];
			if (data.passportBlacklistValues && data.passportBlacklistValues.length > 0) {
				BUY_SIM_JOURNEY.passportBlackListValues = data.passportBlacklistValues;
			}
		});
		this.getDeviceInfo();
	}

	getTiposEntrega(): Observable<TiposEntregaResponse> {
		const url: string = API_URLS.Ntol.getTiposEntrega;
		const headers: HttpHeaders = new HttpHeaders({
			'Content-Type': 'application/json',
		});
		const options: { headers: HttpHeaders; withCredentials: boolean } = {
			headers: headers,
			withCredentials: true,
		};
		return this.http.get(url, options).pipe(
			map((res: TiposEntregaResponse) => {
				return res;
			}),
			catchError((err) => {
				return throwError(err);
			})
		);
	}

	getDeliveryDates(modalidadesEntregaObj: ModalidadesEntrega): Observable<ModalidadesEntregaResponse> {
		const url: string = API_URLS.Ntol.getDeliveryOfficeDates;
		const headers: HttpHeaders = new HttpHeaders({ 'Content-Type': 'application/json' });
		const options: { headers: HttpHeaders; withCredentials: boolean } = {
			headers: headers,
			withCredentials: true,
		};

		return this.http.post(url, modalidadesEntregaObj, options).pipe(
			map((res: ModalidadesEntregaResponse) => {
				return res;
			}),
			catchError((err) => {
				return throwError(err);
			})
		);
	}

	getDeliveryOfficeDates(selcetedDeliveryOffice: DeliveryOffice, modalidadesEntregaObj: ModalidadesEntrega): void {
		this.showFullLoader = true;
		this.getDeliveryDates(modalidadesEntregaObj).subscribe(
			(data: ModalidadesEntregaResponse) => {
				this.postDeliveryData(selcetedDeliveryOffice, data).subscribe(
					(data) => {
						this.preventScroll = false;
						this.isPaymentFullScreen = true;
						this.hideTicketDetails = true;
						this.currentStep = BuySimSteps.stepThreePayment;
						this.postSaveBillingAddress(selcetedDeliveryOffice.codProvincia).subscribe(
							(savedData) => {
								this.showFullLoader = false;
								this.router.navigate([config.buySim.payment.route]);
							},
							(err) => {
								this.showFullLoader = false;
								this.handleGeneralError(err);
							}
						);
					},
					(error) => {
						this.showFullLoader = false;
						this.handleGeneralError(error);
					}
				);
			},
			(error) => {
				this.showFullLoader = false;
				this.handleGeneralError(error);
			}
		);
	}

	postDeliveryData(
		address: DeliveryOffice,
		modalidadesEntregaResponse: ModalidadesEntregaResponse
	): Observable<ResPersonalData> {
		const url: string = API_URLS.Ntol.postDeliveryDataSecure;
		const headers: HttpHeaders = new HttpHeaders({
			'Content-Type': 'application/json',
		});
		const options: { headers: HttpHeaders; withCredentials: boolean } = {
			headers: headers,
			withCredentials: true,
		};
		const params: ParamsDeliveryAddress = {
			xCosteEur: 0,
			flagPrepaid: true,
			cdTipoDeliveryType: this.deliveryDates?.cdTipoDeliveryType,
			isinstallationaddress: '',
			fcEntregaPedido: '2023-12-31T23:00:00Z',
			address: {
				name: address?.direccion,
				postcode: address?.codigoPostal,
				province: Number(address?.codProvincia),
				town: address?.localidad,
				longitude: address?.longitud,
				latitude: address?.latitud,
				type: notAvailable,
				number: notAvailable,
				stairway: notAvailable,
				floor: notAvailable,
				door: notAvailable,
				num: notAvailable,
				duplicate: notAvailable,
				block: notAvailable,
				identificador: notAvailable,
				letter: notAvailable,
				hand1: notAvailable,
				hand2: notAvailable,
				descriptionNumber: notAvailable,
				descriptionStair: notAvailable,
				descriptionFloor: notAvailable,
				descriptionDoor: notAvailable,
				otherdoor: notAvailable,
				verticalId: notAvailable,
				postCodeDelivery: address?.codigoPostal,
				rateType: 2,
			},
			xPerfil: this.deliveryDates?.xPerfil,
			xCanal: this.deliveryDates?.xCanal,
			xTipoCliente: this.deliveryDates?.xTipoCliente,
			xTipoProceso: this.deliveryDates?.xTipoProceso?.cdTipoProceso,
			cdTipoProceso: this.deliveryDates?.xTipoProceso?.nmPrioridadProceso.toString(),
			xDestEntrega: this.deliveryDates?.cdTipoDeliveryType,
			xCostePromoEur: 0,
			dsCosteOperador: null,
			xDsOpcEntrega: modalidadesEntregaResponse?.data?.horasEntrega[0]?.dsOpcEntrega,
			dsOpcEntrega: modalidadesEntregaResponse?.data?.horasEntrega[0]?.dsOpcEntrega,
			xOpcEntrega: this.deliveryDates?.xOpcEntrega[0],
			xIdentificacion: modalidadesEntregaResponse?.data?.horasEntrega[0]?.xIdentificacion,
			xPrioridadEntrega: modalidadesEntregaResponse?.data?.horasEntrega[0]?.xPrioridadEntrega,
			idModalidadEntrega: modalidadesEntregaResponse?.data?.horasEntrega[0]?.idModalidadEntrega,
			idTipoEntrega: this.deliveryDates?.idTipoEntrega,
			cdCatalogOfferCharge: null,
			cdCatalogOfferDiscount: null,
			dsCatalogOfferChargeName: null,
			dsCatalogOfferDiscountName: null,
			euCatalogOfferCharge: null,
			euCatalogOfferDiscount: null,
			textHtmlDescuentoTicket: null,
			textHtmlDescuentoEmailConfirmacion: null,
			textHtmlDescuentoPaginaConfirmacion: null,
			textHtmlUrlModalidadesEmailConfirmacion: null,
			textHtmlUrlModalidadesPaginaConfirmacion: null,
			itDeliveryModalityNoNext: false,
			flagTieneError: false,
			fcPortabilidad: null,
			itPortabilidad: this.newNumberChecked ? 0 : 1,
			xDiaEntrega: null,
			xHoraLiberacion: null,
			xSlaFraude: null,
			correosDireccion: address.direccion,
			correosLocalidad: address.localidad,
			correosProvincia: address.provincia,
			correosCodPostal: address.codigoPostal,
			correosNombreUnidad: address.nombreUnidad,
			correosCodired: address.codired,
		};
		return this.http.post(url, params, options).pipe(
			map((res: ResPersonalData) => {
				return res;
			}),
			catchError((err) => {
				return throwError(err);
			})
		);
	}

	postSaveBillingAddress(provinceCode: string): Observable<ResSaveBillingAddress> {
		const url: string = API_URLS.Ntol.saveBillingAddress;
		const headers: HttpHeaders = new HttpHeaders({
			'Content-Type': 'application/json',
		});
		const options: { headers: HttpHeaders; withCredentials: boolean } = {
			headers: headers,
			withCredentials: true,
		};
		let params: SaveBillingAddress;
		if (!this.newAddressNormalizedSelected) {
			params = {
				name: this.addressVertInfo?.street,
				number: this.selectedAddress?.number,
				postcode: this.selectedAddress?.postalCode,
				postCodeDelivery: this.selectedAddress?.postalCode,
				stairway: this.selectedAddress?.stair,
				town: this.addressVertInfo?.city,
				door: this.selectedAddress?.door,
				province: provinceCode,
				otherdoor: this.selectedAddress?.door,
				floor: this.selectedAddress?.floor,
				rateType: 2,
				descriptionStair: this.selectedAddress?.stair,
				descriptionFloor: this.selectedAddress?.floor,
				descriptionNumber: this.selectedAddress?.number,
				descriptionDoor: this.selectedAddress?.door,
				verticalId: this.addressVertInfo?.verticalId,
				type: this.addressVertInfo?.viaType,
			};
		} else {
			params = {
				name: this.selectedAddress?.name,
				number: this.selectedAddress?.number,
				postcode: this.selectedAddress?.postalCode,
				postCodeDelivery: this.selectedAddress?.postalCode,
				stairway: this.selectedAddress?.stair,
				town: this.selectedAddress?.city,
				door: this.selectedAddress?.door,
				province: this.getProvinceSelectedCode(this.selectedAddress?.provinceName),
				otherdoor: this.selectedAddress?.door,
				additionalinfo: this.selectedAddress?.additionalInfo,
				floor: this.selectedAddress?.floor,
				rateType: 2,
				descriptionStair: this.selectedAddress?.stair,
				descriptionFloor: this.selectedAddress?.floor,
				descriptionNumber: this.selectedAddress?.number,
				descriptionDoor: this.selectedAddress?.door,
			};
		}

		return this.http.post(url, params, options).pipe(
			map((res: ResSaveBillingAddress) => {
				return res;
			}),
			catchError((err) => {
				return throwError(err);
			})
		);
	}
	/**check if device is desktop or mobile */
	getDeviceInfo(): void {
		if (!this.deviceService.isMobile()) {
			this.isMobileView = false;
			if (
				navigator.platform.toLowerCase() === macPlatform.toLowerCase() &&
				navigator.maxTouchPoints >= 1 &&
				ontouchstart in window
			) {
				this.isDesktopView = false;
			} else {
				this.isDesktopView = this.deviceService.isDesktop();
			}
		} else {
			this.isDesktopView = false;
			this.isMobileView = true;
		}
	}

	/** creates a shopping cart in ntol system
	 */
	createShoppingCart(registerType: string): void {
		this.showFullLoader = true;
		this.buySimDataService.createShoppingCart(this.tariffName, registerType).subscribe(
			(data: SaveOfferResponse) => {
				const status: number = data.result?.status;
				// jsonpath refactor: params read as item['xxx'] are missing in model, check SaveOfferResponse.Data
				this.showPaymentRechargeTime =
					(data.data?.['shoppingcartResponse'] as Data)?.cartItems?.[0]?.services?.[0]?.price
						?.devicesRechargeAmountTaxes !== 0;
				if (status === BUY_SIM_JOURNEY.statusOk) {
					if (!data) {
						this.handleGeneralError(data);
						return;
					}
					this.shoppingCart = this.mapShoppingCartResponse(data);
					this.getLegalTerms(legalTermsPageName.personelInfo);
					this.HTMLDataFromAPI = this.sanitizer.bypassSecurityTrustHtml(this.shoppingCart.PaymentCardData);
					this.journeyEndsFlag = false;
				} else {
					this.handleGeneralError(data);
				}
			},
			(error) => {
				this.handleGeneralError(error);
			}
		);
	}

	/** gets shopping cart data */
	getShoppingCart(): void {
		this.showFullLoader = true;
		this.buySimDataService.getShoppingCart().subscribe(
			(data) => {
				const status: number = data.result?.status;
				if (status === BUY_SIM_JOURNEY.statusOk) {
					if (!data) {
						this.handleGeneralError(data);
						return;
					}
					this.updateShoppingCartPrices(data);
					this.showFullLoader = false;
				} else {
					this.handleGeneralError(data);
				}
			},
			(error) => {
				this.handleGeneralError(error);
			}
		);
	}
	/**Save offer */
	saveOffer(offerReqBody: typeof saveOfferReqBody, navigatetodeliveryAddress: boolean = true): void {
		this.buySimDataService.saveOffer(offerReqBody).subscribe(
			(data: SaveOfferResponse) => {
				const status: number = data.result?.status;
				if (status === BUY_SIM_JOURNEY.statusOk) {
					this.updateShoppingCartPrices(data);
					this.updatePromotions(data);
					this.showFullLoader = false;
					if (navigatetodeliveryAddress) {
						this.router.navigate([config.buySim.deliveryAddress.route]);
					}
				} else {
					this.handleGeneralError(data);
				}
			},
			(error) => {
				this.handleGeneralError(error);
			}
		);
	}
	/** updates tariff price and initial balance amount after saveoffer call and create shopping cart */
	updateShoppingCartPrices(data: SaveOfferResponse): void {
		this.shoppingCart.price = data.data?.price?.installmentPromoTaxes + ' ' + CommercialJourneys.euros;
		this.shoppingCart.initialBalance = data.data?.price?.devicesUpfrontTaxesPromo + ' ' + CommercialJourneys.euros;
	}

	/** updates promotions in ticket */
	updatePromotions(data: SaveOfferResponse): void {
		if (!data.data?.cartItems || data.data?.cartItems?.length === 0) {
			return;
		}
		const tariffService: Service = data.data.cartItems[0].services.find((item) => {
			return item.catalogElementType?.toLowerCase() === CommercialJourneys.catalogElementTypePackage.toLowerCase();
		});
		this.getPromotions(tariffService);
	}

	/** map shopping cart data to model object
	 *  @param data shopping cart data from api
	 */
	mapShoppingCartResponse(data: SaveOfferResponse): ShoppingCartModel {
		const shoppingCart = new ShoppingCartModel();
		// jsonpath refactor: params read as item['xxx'] are missing in model
		const cartItmes: CartItem[] = data.data?.['shoppingcartResponse']?.cartItems;
		if (!cartItmes || cartItmes.length === 0) {
			return null;
		}
		const tariffItemServices = cartItmes[0]?.services;
		const tariffService = tariffItemServices.find((item) => {
			const type = item.catalogElementType;
			return type && type.toLowerCase() === CommercialJourneys.catalogElementTypePackage.toLowerCase();
		});
		const deviceService = tariffItemServices.find((item) => {
			const type: string = item.catalogElementType;
			return type && type.toLowerCase() === CommercialJourneys.catalogElementTypeDevice.toLowerCase();
		});
		this.getPromotions(tariffService);
		shoppingCart.tariffName = tariffService?.detail?.name;
		shoppingCart.price = tariffService?.price?.installmentPromoTaxes + ' ' + CommercialJourneys.euros;
		shoppingCart.initialBalance = deviceService?.price?.devicesUpfrontTaxesPromo + ' ' + CommercialJourneys.euros;
		shoppingCart.rateCode = tariffService?.code;
		shoppingCart.PaymentCardData = tariffService?.detail?.packageText?.htmlTextInfo;
		shoppingCart.nameSEO = data.data?.['dataRateInit']?.nameSEO;
		return shoppingCart;
	}

	/** submits form data to ntol system
	 * @param infoForm {FormGroup}
	 */
	submitPersonalData(infoForm?: UntypedFormGroup): void {
		this.personalInfo = new PersonalModel();
		/**this value for order confirmation screen*/
		this.personelInfoEmail = infoForm.controls['email'].value;
		this.mapPersonalInfoToRequestObj(this.personalInfo, infoForm);
		if (!this.portabilityFullName) {
			this.getUserFullName(infoForm);
		}
		this.buySimDataService.savePersonalData(this.personalInfo).subscribe(
			(data) => {
				const status: number = data.result?.status;
				if (status === BUY_SIM_JOURNEY.statusOk) {
					this.shoppingCart.orderId = data.data?.order_id;
					this.saveLegalTems();
				} else {
					this.handleGeneralError(data);
				}
			},
			(error) => {
				this.handleGeneralError(error);
			}
		);
	}

	/** submits edit personal data from portability page */
	submitEditPersonalData(): void {
		/** as required in PREP-620  flagTitularMovil wil always false*/
		this.personalInfo.flagTitularMovil = false;
		if (this.newNumberOrPortabilityChecked) {
			this.showFullLoader = false;
			this.newNumberOrPortabilityChecked = false;
		} else {
			this.buySimDataService.savePersonalData(this.personalInfo)?.subscribe(
				(data) => {
					const status: number = data.result?.status;
					if (status === BUY_SIM_JOURNEY.statusOk) {
						this.router.navigate([config.buySim.deliveryAddress.route]);
					} else {
						this.handleGeneralError(data);
					}
				},
				(error) => {
					this.handleGeneralError(error);
				}
			);
		}
	}

	/**Get user full name to be displayed in portability screen */
	getUserFullName(infoForm?: UntypedFormGroup): void {
		this.portabilityFullName =
			infoForm.value.name +
			' ' +
			infoForm.value.surName +
			(infoForm.value.secondSurName ? ' ' + infoForm.value.secondSurName : '');
	}

	/**handle accept list in payment screen to send it in save Legal terms request  */
	handleAcceptListonPaymentTicketScreen(): LegalTermsItemPostModel[] {
		this.AcceptListInRequest = [];
		this.AcceptListInResponse?.forEach((term) => {
			const legalTerm: LegalTermsItemPostModel = new LegalTermsItemPostModel();
			legalTerm.checked = true;
			legalTerm.id_legalterms = term.id;
			legalTerm.internalDescription = term.internalDescription;
			legalTerm.name = term.name;
			legalTerm.type = term.type;
			this.AcceptListInRequest.push(legalTerm);
		});
		return this.AcceptListInRequest;
	}

	/** save user selection of legal terms */
	saveLegalTems(): void {
		this.legalTermsPostList = [];
		this.legalTerms.forEach((term) => {
			const legalTerm = new LegalTermsItemPostModel();
			legalTerm.checked = Boolean(term.checked);
			legalTerm.id_legalterms = term.id;
			legalTerm.internalDescription = term.internalDescription;
			legalTerm.name = term.name;
			legalTerm.type = term.type;
			this.legalTermsPostList.push(legalTerm);
		});
		if (this.buySimDataService.pageName === legalTermsPageName.payment) {
			this.legalTermsPostList = [...this.legalTermsPostList, ...this.handleAcceptListonPaymentTicketScreen()];
		}

		this.buySimDataService.saveLegalTerms(this.legalTermsPostList, this.requestOrderId).subscribe(
			(data) => {
				const status = data.result?.status;
				if (status === BUY_SIM_JOURNEY.statusOk) {
					if (this.buySimDataService.pageName === legalTermsPageName.personelInfo) {
						this.requestOrderId = data.data?.order_id;
						this.checkIsVodafoneClient();
						return;
					} else {
						this.submitOrder();
					}
				} else {
					this.handleGeneralError(data);
				}
			},
			(error) => {
				this.handleGeneralError(error);
			}
		);
	}

	checkIsVodafoneClient(): void {
		this.showFullLoader = true;
		this.buySimDataService.getIsVodafoneClient().subscribe(
			(res) => {
				this.isVodafoneClientResponse = res;
				if (this.isVodafoneClientResponse.isClientVodafone) {
					this.newTagging
						.getJsonTagging(isVodafoneClientTagging.vodafoneClientPage)
						.subscribe((data: NewTaggingJsonModel) => {
							const state: NewTaggingJsonModel = data;
							state.page.stateList[isVodafoneClientTagging.vodafoneClient].custom_map = {
								journey_prepaid_tariff: this.tariffNameForTagging,
								asset_name: tabConsumoTagging.assetName,
							};
							this.newTagging.state(data.page.stateList[isVodafoneClientTagging.vodafoneClient]);
						});
				}
				this.router.navigate([config.buySim.portability.route]);
			},
			(error) => {
				this.showFullLoader = false;
				this.router.navigate([config.buySim.portability.route]);
			}
		);
	}

	/** maps personal data to api model  */
	mapPersonalInfoToRequestObj(personalInfo: PersonalModel, infoForm: UntypedFormGroup): void {
		personalInfo.name = infoForm.controls['name'].value.trim();
		personalInfo.firstsurname = infoForm.controls['surName'].value.trim();
		personalInfo.secondsurname = infoForm.controls['secondSurName'].value
			? infoForm.controls['secondSurName'].value.trim()
			: '';
		personalInfo.email = infoForm.controls['email'].value;
		personalInfo.phonenumber = infoForm.controls['contactNumber'].value;
		personalInfo.birthdateday = this.utilitiesService
			.parseDateReversedDate(infoForm.controls['dateOfBirth'].value, '/')
			.getDate()
			.toString();
		personalInfo.birthdatemonth = (
			this.utilitiesService.parseDateReversedDate(infoForm.controls['dateOfBirth'].value, '/').getMonth() + 1
		).toString();
		personalInfo.birthdateyear = this.utilitiesService
			.parseDateReversedDate(infoForm.controls['dateOfBirth'].value, '/')
			.getFullYear()
			.toString();
		personalInfo.flagTitularFijo = false;
		personalInfo.flagTitularMovil = false;
		personalInfo.identificationNumber = infoForm.controls['id'].value;
		personalInfo.identificationType = this.documentIdType;
		personalInfo.nationality = this.setNationality(infoForm.controls['nationality'].value);
	}

	/** returns nationality id for selected nationality */
	setNationality(nationality) {
		if (!nationality) {
			return BUY_SIM_JOURNEY.spainNationalityId;
		}
		const selectedNationality = this.nationalityDataList.find((item) => {
			return item.name.toLowerCase() === nationality.toLowerCase();
		});
		return selectedNationality?.id;
	}

	/**get Promotions for payment screen*/
	getPromotions(cartItems: Service): void {
		this.promotionsArray = new Array<BuySimPromotion>();
		const promotionsListData = cartItems.detail?.promotions || [];
		promotionsListData.forEach((promotionItem) => {
			const newPromotion: BuySimPromotion = new BuySimPromotion();
			newPromotion.shortDesc = promotionItem.longTextFicha;
			newPromotion.longDesc = promotionItem.detailTextFicha || '';
			this.promotionsArray.push(newPromotion);
		});
	}

	/** get lis of legal terms to be displayed on personal data page and payment from ntol api */
	getLegalTerms(pageName: string): void {
		this.legalTerms = new Array<LegalTermsItemModel>();
		this.buySimDataService.getLegalTerms(pageName).subscribe(
			(data) => {
				const status = data.result?.status;
				if (status === BUY_SIM_JOURNEY.statusOk) {
					let legalDataArray: LegalTermsItemModel[];
					if (pageName === legalTermsPageName.personelInfo) {
						legalDataArray = data.data?.listAceptos || [];
						this.sendPageTagging(tagging.buySIMJourney.personelInfoLoadedjourney);
					} else {
						// jsonpath refactor: params read as item['xxx'] are missing in model
						legalDataArray = data.data?.['listCdus'] || [];
						/**ACEPTOS List handled to send it in save legal Terms request */
						this.AcceptListInResponse = data.data?.listAceptos || [];
					}
					legalDataArray.forEach((legalItem) => {
						const newLegalTerm = new LegalTermsItemModel();
						newLegalTerm.id = legalItem.id;
						newLegalTerm.name = legalItem.name;
						newLegalTerm.type = legalItem.type;
						newLegalTerm.order = legalItem.orden;
						newLegalTerm.internalDescription = legalItem.internalDescription;
						newLegalTerm.shortText = legalItem.shortText;
						newLegalTerm.htmlText = legalItem.htmlText;

						this.legalTerms.push(newLegalTerm);
					});
					this.legalTerms.sort((item1, item2) => item1.order - item2.order);
					this.showFullLoader = false;
				} else {
					this.handleGeneralError(data);
				}
			},
			(error) => {
				this.handleGeneralError(error);
			}
		);
	}

	/** validates id from ntol api
	 * @param id {string} entered id
	 * @param identificationType {string} NIF OR NIE
	 */
	validateId(id: string, identificationType: string) {
		return this.buySimDataService.validateId(id, identificationType).pipe(
			map(
				(data) => {
					const status = data.result?.status;
					if (status === BUY_SIM_JOURNEY.statusOk) {
						const result = new IdValidationModel();
						result.message = data.data?.message;
						result.resultValidation = data.data?.resultValidation;
						return result;
					} else {
						this.handleGeneralError(data);
					}
				},
				(error) => {
					this.handleGeneralError(error);
				}
			)
		);
	}

	/** gets list of nationalities form ntol api */
	getNationalityList() {
		return this.buySimDataService.getNationalityList().pipe(
			map(
				(data: any) => {
					const status = data.result?.status;
					if (status === BUY_SIM_JOURNEY.statusOk) {
						this.nationalityDataList = data.data.Country;
						return this.nationalityDataList.map((nationality) => nationality.name);
					} else {
						this.handleGeneralError(data);
					}
				},
				(error) => {
					this.handleGeneralError(error);
				}
			)
		);
	}

	getProvinceList() {
		return this.buySimDataService.getProvincList().pipe(
			map(
				(data: any) => {
					const status = data.result?.status;
					if (status === BUY_SIM_JOURNEY.statusOk) {
						this.provinceDataList = data.data.ProvinceDD;
						return this.provinceDataList.map((province) => province.name);
					} else {
						this.handleGeneralError(data);
					}
				},
				(error) => {
					this.handleGeneralError(error);
				}
			)
		);
	}

	getProvinceSelectedCode(provinceName: string): string {
		return this.provinceDataList.find((province) => province?.name === provinceName)?.code;
	}

	/**submit the buysim order */
	submitOrder(): void {
		const orderReqBody = submitOrderReqBody;
		orderReqBody.idPedido = this.requestOrderId ? Number(this.requestOrderId) : undefined;
		this.buySimDataService.submitOrder(orderReqBody).subscribe(
			(data: any) => {
				const status = data.result?.status;
				if (status === BUY_SIM_JOURNEY.statusOk) {
					this.sendOKTagging();
					this.showFullLoader = false;
					this.journeyEndsFlag = true;
				} else {
					this.handleGeneralError(data);
				}
			},
			(error) => {
				this.handleGeneralError(error);
			}
		);
	}
	/** incase of any api failure */
	handleGeneralError(errorResponse?: any): void {
		const errorsList: BuySimErrorModel[] = this.mapErrorData(errorResponse);
		errorsList.forEach((error) => {
			this.tagging.setKOJourneyErrorListVariable(
				tagging.buySIMJourney.kojourney.journey_name,
				error.errorCode,
				error.errorMessage
			);
		});
		this.sendKOTagging();
		this.showFullLoader = false;
		this.router.navigate([config.buySim.generalError.route]);
	}

	/** maps api error response */
	mapErrorData(errorData: any): BuySimErrorModel[] {
		if (!errorData) {
			return [];
		}
		const errorResultList: BuySimErrorModel[] = new Array<BuySimErrorModel>();
		const errorList: [] = errorData.error?.result?.listMessages;
		if (errorList && errorList.length > 0) {
			errorList.forEach((error: any) => {
				const errorItem: BuySimErrorModel = new BuySimErrorModel();
				errorItem.errorCode = error.code;
				errorItem.errorMessage = error.description;
				errorResultList.push(errorItem);
			});
		} else {
			const httpError: HttpErrorResponse = errorData as HttpErrorResponse;
			const errorItem: BuySimErrorModel = new BuySimErrorModel();
			if (httpError) {
				errorItem.errorCode = httpError.status.toString();
				errorItem.errorMessage = httpError.message;
				errorResultList.push(errorItem);
			}
		}
		return errorResultList;
	}

	/**send tagging when the api success*/
	sendOKTagging(): void {
		this.tariffNameForTagging = this.shoppingCart.nameSEO.split('-').join(' ');
		tagging.buySIMJourney.okjourney.journey_options = this.tariffNameForTagging;
		tagging.buySIMJourney.okjourney.transaction_shipping_type = this.isHomeDelivery
			? buySimDeliveryMethods.homeDelivery
			: buySimDeliveryMethods.correosDelivery;
		tagging.buySIMJourney.okjourney.product_process_type = this.newNumberChecked
			? tagging.staticVariables.buySimJourneyProcessType.newNumber
			: tagging.staticVariables.buySimJourneyProcessType.portability;
		tagging.buySIMJourney.okjourney['&&products'] = tagging.buySIMJourney.okjourney['&&products'].replace(
			'<product name>',
			this.tariffNameForTagging
		);
		tagging.buySIMJourney.okjourney.purchaseID = this.requestOrderId;
		tagging.buySIMJourney.okjourney.journey_prepaid_tariff = this.tariffNameForTagging;
		this.tagging.viewReplace(false, {}, tagging.buySIMJourney.okjourney);
	}
	/**send tagging when the api fail*/
	sendKOTagging(): void {
		this.tariffNameForTagging = this.tariffName
			? this.tariffName.split('-').join(' ')
			: this.shoppingCart.nameSEO.split('-').join(' ');
		tagging.buySIMJourney.kojourney.journey_options = this.tariffNameForTagging;
		tagging.buySIMJourney.kojourney.transaction_shipping_type = this.isHomeDelivery
			? buySimDeliveryMethods.homeDelivery
			: buySimDeliveryMethods.correosDelivery;
		tagging.buySIMJourney.kojourney.product_process_type = this.newNumberChecked
			? tagging.staticVariables.buySimJourneyProcessType.newNumber
			: tagging.staticVariables.buySimJourneyProcessType.portability;
		tagging.buySIMJourney.kojourney['&&products'] = tagging.buySIMJourney.kojourney['&&products'].replace(
			'<product name>',
			this.tariffNameForTagging
		);
		tagging.buySIMJourney.kojourney.error_list = this.tagging.getKOJourneyErrorListVariable(
			tagging.buySIMJourney.kojourney.journey_name
		);
		tagging.buySIMJourney.kojourney.journey_prepaid_tariff = this.tariffNameForTagging;
		this.tagging.viewReplace(false, {}, tagging.buySIMJourney.kojourney);
	}

	/** this api to get NtolRequest id to be displayed as order id in order confirmation screen*/
	findByToken(): void {
		this.buySimDataService.findByToken().subscribe(
			(data: any) => {
				const status = data.result?.status;
				if (status === BUY_SIM_JOURNEY.statusOk) {
					this.idNtolRequest = data.data?.idNtolRequest;
					this.getLegalTerms(legalTermsPageName.payment);
				} else {
					this.handleGeneralError(data);
				}
			},
			(error) => {
				this.handleGeneralError(error);
			}
		);
	}
	/**sends page view for each page in the buy sim journey
	 * @param taggingObj tagging obj for each page
	 */
	sendPageTagging(taggingObj: any): void {
		this.tariffNameForTagging = this.shoppingCart.nameSEO.split('-').join(' ');
		if (taggingObj.journey_options !== undefined) {
			taggingObj.journey_options = this.tariffNameForTagging;
		}
		if (taggingObj['&&products']) {
			taggingObj['&&products'] = taggingObj['&&products'].replace('<product name>', this.tariffNameForTagging);
		}
		if (taggingObj.journey_process_type !== undefined) {
			taggingObj.journey_process_type = this.newNumberChecked
				? tagging.staticVariables.buySimJourneyProcessType.newNumber
				: tagging.staticVariables.buySimJourneyProcessType.portability;
		}
		if (!taggingObj.journey_prepaid_tariff) {
			taggingObj.journey_prepaid_tariff = this.tariffName
				? this.tariffName.split('-').join(' ')
				: this.shoppingCart.nameSEO.split('-').join(' ');
		}
		if (!taggingObj.product_process_type) {
			taggingObj.product_process_type = this.newNumberChecked
				? tagging.staticVariables.buySimJourneyProcessType.newNumber
				: tagging.staticVariables.buySimJourneyProcessType.portability;
		}
		this.tagging.viewReplace(false, {}, taggingObj);
	}

	/** send tagging interactions of continuar button click
	 * @param pageObj tagging page object of page contains continuar button
	 */
	sendClickOnContinuarTaggingInteraction(pageObj: any): void {
		const taggingObj: any = Object.assign({}, tagging.buySIMInteractions.clickOnContinuar);
		taggingObj.eventName = taggingObj.eventName.replace('<page_name>', pageObj.page_name);
		this.tagging.track(taggingObj.eventName, taggingObj.data, pageObj);
	}

	/**send field error interaction
	 * @param fieldName name of field that has the error
	 * @param pageObj tagging page obj for page that contains field
	 */
	sendErrorFieldInteraction(fieldName: string, pageObj: any): void {
		const taggingObj: any = Object.assign({}, tagging.buySIMInteractions.errorInField);
		const eventName: string = taggingObj.eventName
			.replace('<page_name>', pageObj.page_name)
			.replace('<field name>', fieldName);
		const data: any = {
			event_name: taggingObj.data.event_name.replace('<field name>', fieldName),
		};
		this.tagging.track(eventName, data, pageObj);
	}

	/**send field error interaction after a form submition
	 * @param form data form
	 * @param disableFields contains disable fields if any to neglect them from tagging
	 */
	sendErrorFieldTaggingInteractionAfterSubmit(form: UntypedFormGroup, disableFields?: string[]): void {
		Object.keys(form.controls).forEach((key) => {
			const fieldTaggingName: string = tagging.staticVariables.buySimFieldsName[key];
			if (fieldTaggingName && (!disableFields || disableFields.indexOf(fieldTaggingName) !== -1)) {
				this.sendErrorFieldTaggingInteraction(key, form);
			}
		});
	}

	/**sends tagging error interaction for each form field
	 * @param fieldName name of field that has the error
	 * @param form data form
	 */
	sendErrorFieldTaggingInteraction(fieldName: string, form: UntypedFormGroup): void {
		const control: AbstractControl = form.get(fieldName);
		if (control.invalid && !control.disabled) {
			this.sendErrorFieldInteraction(
				tagging.staticVariables.buySimFieldsName[fieldName],
				tagging.buySimPages.personalData
			);
		}
	}

	/**get operators list to portability screen */
	getOperatorsList() {
		return this.buySimDataService.getOperatorsList().pipe(
			map((data) => {
				const status = data.result?.status;
				if (status === BUY_SIM_JOURNEY.statusOk) {
					this.operatorsList = new Array<OperatorModel>();
					this.operatorsDataList = data.data.OperatorMobile;
					return this.operatorsDataList.map((item) => {
						this.operatorsList.push(item.name);
					});
				} else {
					this.handleGeneralError(data);
				}
			}),
			catchError((error) => {
				this.handleGeneralError(error);
				return throwError(null);
			})
		);
	}

	/**sets id field validation when new document type selected
	 * @param {documentType} string selected document type [DNI, NIE, passport]
	 */
	onSelectDocumentIdType(documentType: string): void {
		switch (documentType) {
			case this.documentIdTypesList[0].name:
				this.setIdFieldValidations(
					NIEOrNIFLength,
					Validation.NIF,
					IdentificationType.NIF,
					'v10.buySim.itemsList.idFieldDniLabel'
				);
				break;
			case this.documentIdTypesList[1].name:
				this.setIdFieldValidations(
					NIEOrNIFLength,
					Validation.NIE,
					IdentificationType.NIE,
					'v10.buySim.itemsList.idFieldNieLabel'
				);
				break;
			case this.documentIdTypesList[2].name:
				this.setIdFieldValidations(
					12,
					Validation.buySimPassport,
					IdentificationType.PASSPORT,
					'v10.buySim.itemsList.idFieldPassportLabel'
				);
				break;
		}
	}
	/** sets id field validations and label when a new document type is selected
	 * @param {maxLength} number field max number
	 * @param {validationPattern} string filed validation regex
	 * @param {placeHolderKey} string wcs key for field place holder
	 * @param {erroMessageKey} string wcs key for validation error message
	 */
	setIdFieldValidations(
		maxLength: number,
		validationPattern: string,
		documentIdType: IdentificationType,
		placeHolderKey: string
	): void {
		this.documentIdMaxLength = maxLength;
		this.idValidationPattern = validationPattern;
		this.documentIdType = documentIdType;
		this.translate.get(placeHolderKey).subscribe((data) => {
			this.idFieldPlaceholder = data;
		});
	}

	/** sets default label for id field and identification type */
	setIdFieldDefaultValues(): void {
		this.documentIdType = IdentificationType.NIF;
		this.translate.get('v10.buySim.itemsList.idFieldDniLabel').subscribe((data) => {
			this.idFieldPlaceholder = data;
		});
	}

	/** backend validation of id  */
	idBackendValidation(id: string): void {
		const nieRegex: RegExp = new RegExp(Validation.NIE);
		const nifRegex: RegExp = new RegExp(Validation.NIF);
		const identificationTypeValue: string = nieRegex.test(id)
			? BUY_SIM_JOURNEY.IdentificationTypeNIE
			: nifRegex.test(id)
			? BUY_SIM_JOURNEY.IdentificationTypeNIF
			: '';
		if (identificationTypeValue) {
			this.showFullLoader = true;
			this.validateId(id.toUpperCase(), identificationTypeValue).subscribe(
				(data) => {
					this.showIdBackEndError = !data.resultValidation;
					this.showFullLoader = false;
				},
				(error) => {
					this.handleGeneralError(error);
				}
			);
		}
	}

	getCoordinatesLogic(response: StoreCoordinates): StoreCoordinates {
		const coords: StoreCoordinates = new StoreCoordinates();
		coords.city = response.city;
		coords.country = response.country;
		coords.latitude = response.latitude || 0;
		coords.longitude = response.longitude || 0;
		coords.province = response.province;
		coords.region = response.region;
		return coords;
	}

	getOficinasCorreosEntregaLogic(response: { oficinasCorreos: DeliveryOffice[] }): DeliveryOffice[] {
		const offices: DeliveryOffice[] = response.oficinasCorreos || [];
		let arrayOffices: DeliveryOffice[] = new Array<DeliveryOffice>();
		arrayOffices = offices?.map((office) => {
			const deliveryOffice: DeliveryOffice = new DeliveryOffice();
			deliveryOffice.acceso = office.acceso;
			deliveryOffice.ccaa = office.ccaa;
			deliveryOffice.codHorario = office.codHorario;
			deliveryOffice.codProvincia = office.codProvincia;
			deliveryOffice.codigoPostal = office.codigoPostal;
			deliveryOffice.codired = office.codired;
			deliveryOffice.coordXETRS89 = office.coordXETRS89;
			deliveryOffice.coordXWGS84 = office.coordXWGS84;
			deliveryOffice.coordYETRS89 = office.coordYETRS89;
			deliveryOffice.coordYWGS84 = office.coordYWGS84;
			deliveryOffice.direccion = office.direccion;
			deliveryOffice.distanciaPuntoRef = office.distanciaPuntoRef;
			deliveryOffice.horario = office.horario;
			deliveryOffice.id = office.id;
			deliveryOffice.latitud = office.latitud;
			deliveryOffice.latitudETRS89 = office.latitudETRS89;
			deliveryOffice.latitudWGS84 = office.latitudWGS84;
			deliveryOffice.localidad = office.localidad;
			deliveryOffice.longitud = office.longitud;
			deliveryOffice.longitudETRS89 = office.longitudETRS89;
			deliveryOffice.longitudWGS84 = office.longitudWGS84;
			deliveryOffice.nombreUnidad = office.nombreUnidad;
			deliveryOffice.provincia = office.provincia;
			deliveryOffice.provinciaEs = office.provinciaEs;
			deliveryOffice.telefono = office.telefono;
			deliveryOffice.checked = false;
			return deliveryOffice;
		});
		return arrayOffices;
	}
	getOficinasCorreosEntrega(storeCoordinates: StoreCoordinates, postalCode: string): Observable<DeliveryOffice[]> {
		let url: string = API_URLS.Checkout.getOficinasCorreosEntrega;
		url = url.replace('{postalCode}', postalCode);
		url = url.replace('{latitude}', storeCoordinates.latitude?.toString());
		url = url.replace('{longitude}', storeCoordinates.longitude?.toString());
		url = url.replace('{shopType}', SHOPTYPE);
		const headers: HttpHeaders = new HttpHeaders();

		const options: { headers: HttpHeaders; withCredentials: boolean } = {
			headers: headers,
			withCredentials: true,
		};
		return this.http.get(url, options).pipe(
			map((res: any) => {
				// jsonpath refactor: added 'any' type due to missing model
				return this.getOficinasCorreosEntregaLogic(res);
			}),
			catchError((err) => {
				return throwError(err);
			})
		);
	}
	getCoordinates(zipCode: string): Observable<DeliveryOffice[]> {
		const url: string = API_URLS.Checkout.getCoordinates.replace('{zipCode}', zipCode);
		const headers: HttpHeaders = new HttpHeaders();
		const options: { headers: HttpHeaders; withCredentials: boolean } = {
			headers: headers,
			withCredentials: true,
		};
		return this.http.get(url, options).pipe(
			map((res: StoreCoordinates) => {
				return this.getCoordinatesLogic(res);
			}),
			switchMap((storeCoordinates: StoreCoordinates) => {
				return this.getOficinasCorreosEntrega(storeCoordinates, zipCode);
			}),
			catchError((err) => {
				return throwError(err);
			})
		);
	}
	public downloadPDF(): void {
		this.showFullLoader = true;
		this.buySimDataService.postContractSummary(this.idNtolRequest).subscribe(
			(res) => {
				this.blob = new Blob([res], { type: typeBlob });
				this.objectUrl = URL.createObjectURL(this.blob);
				this.showFullLoader = false;
			},
			(error) => {
				this.handleGeneralError(error);
			}
		);
	}
}
