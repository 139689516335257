import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree } from '@angular/router';
import { Observable } from 'rxjs';
import { CanComponentDeactivate } from '../../fault-management/guards/openNewTicketDeactivate.guard';
import { MicroFlowsService } from '../../shared/services/micro-flows.service';

@Injectable({
	providedIn: 'root',
})
export class MicroFlowDeactivateGuard {
	constructor(private microflowsService: MicroFlowsService) {}
	canDeactivate(
		component: CanComponentDeactivate,
		route: ActivatedRouteSnapshot,
		state: RouterStateSnapshot
	): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
		this.microflowsService.cleanMf();
		return true;
	}
}
