import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';

@Component({
	selector: 'sp-full-overlay-iframe',
	templateUrl: './full-overlay-iframe.component.html',
	styleUrls: ['./full-overlay-iframe.component.scss'],
})
export class FullOverlayIframeComponent implements OnInit, OnDestroy {
	@Input() iframeSrc: string;
	@Input() messageEventHandler: (message: MessageEvent) => any;

	@Output() closeEvent: EventEmitter<void> = new EventEmitter<void>();

	iframeSrcSanitized: SafeResourceUrl;
	showModal: boolean;

	constructor(private sanitizer: DomSanitizer) {}

	ngOnInit(): void {
		this.showModal = true;
		this.iframeSrcSanitized = this.sanitizer.bypassSecurityTrustResourceUrl(this.iframeSrc);
		if (this.messageEventHandler) {
			window.addEventListener('message', this.messageEventHandler);
		}
	}

	onCloseModal(): void {
		this.showModal = false;
		this.closeEvent.emit();
	}

	ngOnDestroy(): void {
		if (this.messageEventHandler) {
			window.removeEventListener('message', this.messageEventHandler);
		}
	}
}
