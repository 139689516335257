import { tagging } from '../../../../config/tagging-config';
import { BillingService } from '../../../billing/billing.service';
import { CrossVariables } from '../../../shared/models/tagging.model';
import { TaggingHelperService } from '../../../core/services/tagging.helper.service';
import { PaymentNavigationService } from '../payment-navigation.service';
import { PaymentTaggingDefault, PaymentTaggingInterface } from './payment-tagging-interface';
import { PaymentJourneyPaymentType } from 'src/app/shared/enums/payment-journey-payment-type.enum';
import { PaymentTaggingPageName, TaggingJourney, TrackActionsObject } from 'src/app/shared/models/payment';
import { NewTaggingHelperService } from '../../../core/services/new-tagging.helper.service';
import { FOLDER_FINANCED_DEVICES, PAYMENTS_TRACKING } from 'src/app/shared/constants/archetype/paymentsTracking';
import { NewTaggingJsonModel } from 'src/app/shared/models/new-tagging-json-model';
import { NewTaggingStateListModel } from 'src/app/shared/models/new-tagging-state-list.model';

/** Use PaymentTaggingPostPaid as a default journey for dept payment */
export class PaymentTaggingFinancedDevicesCancellation
	extends PaymentTaggingDefault
	implements PaymentTaggingInterface
{
	constructor(
		public billService: BillingService,
		public paymentNavigationService: PaymentNavigationService,
		public tagging: TaggingHelperService,
		public newTagging: NewTaggingHelperService
	) {
		super(tagging, billService, paymentNavigationService);
	}
	/**
	 * Tagging for download button in ticket screen for postpaid journey dept
	 */
	tagDownLoadTicket(): void {
		const paymentPageName: PaymentTaggingPageName = Object.assign({}, tagging.paymentDetailsFinancedDevicesPageName);
		const paymentJourney: TaggingJourney = Object.assign({}, tagging.paymentDetailsJourney);
		paymentJourney.journey_name = tagging.paymentDetailsFinancedDevicesPageName.page_subcategory_level_2;
		paymentJourney.journey_payment_type = PaymentJourneyPaymentType.total;
		paymentJourney.journey_payment_method = tagging.staticVariables.paymentMethod;
		paymentJourney.journey_category = CrossVariables.client_typology;
		paymentJourney.journey_subcategory = tagging.staticVariables.paymentSubCategory;
		paymentJourney.journey_type = tagging.staticVariables.paymentJourneyType;
		paymentJourney['journey_card_selected'] = tagging.staticVariables.newCreditCard;
		paymentJourney.journey_step =
			tagging.paymentDetailsFinancedDevicesPageName.page_subcategory_level_2 +
			':' +
			tagging.paymentDetailsFinancedDevicesPageName.page_subcategory_level_4;
		paymentJourney['payment_id'] = this.paymentNavigationService.thirdPartyURLParams.transactionId;
		this.tagging.track(
			tagging.downloadTicketFinancedDevices.eventName,
			tagging.downloadTicketFinancedDevices.data,
			paymentPageName,
			paymentJourney
		);
	}
	/**
	 * Tagging for ticket screen for postpaid journey dept
	 */
	tagOpenPaymentTicketScreen(): void {
		this.newTagging.getJsonTagging(FOLDER_FINANCED_DEVICES).subscribe((data: NewTaggingJsonModel) => {
			const state: NewTaggingStateListModel =
				data.page.stateList[
					PAYMENTS_TRACKING.FINANCED_DEVICES.STATES.ACTIVE_FINANCING_PAYMENT_WITH_CREDIT_CARD_PAYMENT_TICKET
				];
			state.journey.journey_category = CrossVariables.client_typology;
			state.custom_map['product_brand'] = this.billService.selectedFundedDevice?.brand;
			state.custom_map['product_model'] = this.billService.selectedFundedDevice?.model;
			state.custom_map['journey_payment_type'] = PaymentJourneyPaymentType.total;
			state.custom_map['journey_payment_method'] = tagging.staticVariables.paymentMethod;
			this.newTagging.state(state);
		});
	}

	/** Tagging cerrar btn Success paymet overlay screen */
	successpaymentCerrarBtnClick(): void {
		const saveCreditCardJourney: TrackActionsObject = Object.assign({}, tagging.cerrarBtnClickSuccessPaymentOverlay);
		const saveCreditCardPageJourney: PaymentTaggingPageName = Object.assign(
			{},
			tagging.cerrarBtnClickSuccessPaymentOverlay.pageJourney
		);
		let pageName: string = '';
		pageName = tagging.staticVariables.paymentDeptPageName;
		saveCreditCardJourney['event_name'] = saveCreditCardJourney['event_name'].replace('<page_name>', pageName);
		saveCreditCardPageJourney.page_name = saveCreditCardPageJourney.page_name.replace('<page_name>', pageName);
		saveCreditCardPageJourney.page_subcategory_level_2 = pageName;
		this.tagging.track(saveCreditCardJourney['event_name'], saveCreditCardJourney.data, saveCreditCardPageJourney);
	}
	/** Tagging ok btn Success paymet overlay screen */
	successpaymentEntendidoBtnClick(): void {
		const saveCreditCardJourney: TrackActionsObject = Object.assign({}, tagging.entendidoBtnClickSuccessPaymentOverlay);
		const saveCreditCardPageJourney: PaymentTaggingPageName = Object.assign(
			{},
			tagging.entendidoBtnClickSuccessPaymentOverlay.pageJourney
		);
		let pageName: string = '';
		pageName = tagging.staticVariables.paymentDeptPageName;
		saveCreditCardJourney['event_name'] = saveCreditCardJourney['event_name'].replace('<page_name>', pageName);
		saveCreditCardPageJourney.page_name = saveCreditCardPageJourney.page_name.replace('<page_name>', pageName);
		saveCreditCardPageJourney.page_subcategory_level_2 = pageName;
		this.tagging.track(saveCreditCardJourney['event_name'], saveCreditCardJourney.data, saveCreditCardPageJourney);
	}
}
