import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { map } from 'rxjs/operators';
import { cachingKeys } from '../shared/constants/defines';
import { API_URLS } from '../shared/constants/routes-config';
import { evict } from '../shared/decorators/evict.decorator';
import { TopupRrequest } from '../shared/models/topup-request.model';
import { SubscriptionService } from './../core/services/subscription.service';

@Injectable()
export class TopUpDataService {
	constructor(private http: HttpClient, private subscriptionService: SubscriptionService) {}
	getTopUpHistory(id: string, startDate, endDate, subscriptionId) {
		let url = API_URLS.TopUp.GetTopUpHistory.replace('{id}', id);
		url += `?subscriptionId=${subscriptionId}&startDate=${startDate}&endDate=${endDate}`;
		let headers = new HttpHeaders();
		headers = headers.append('Content-Type', 'application/json');
		const options = {
			headers: headers,
		};
		return this.http.get(url, options).pipe(
			map((res) => {
				return res;
			})
		);
	}

	getTopUpServicesPendingTransactions(id: string) {
		let url = API_URLS.TopUp.GetTopUpHistory.replace('{id}', id);
		url += `?status=pending`;
		let headers = new HttpHeaders();
		headers = headers.append('Content-Type', 'application/json');
		const options = {
			headers: headers,
		};
		return this.http.get(url, options).pipe(
			map((res) => {
				return res;
			})
		);
	}
	getTopUpCurrentSpendings(id: string, subscriptionId, startDate, endDate, type) {
		let url = API_URLS.TopUp.GetTopUpCurrentSpendings.replace('{customerAccountId}', id).replace(
			'{subscriptionId}',
			subscriptionId
		);
		url += `?startDate=${startDate}&endDate=${endDate}&type=${type}&sortType=desc&sortField=startDate`;
		let headers = new HttpHeaders();
		headers = headers.append('Content-Type', 'application/json');
		const options = {
			headers: headers,
		};
		return this.http.get(url, options).pipe(
			map((res) => {
				return res;
			})
		);
	}
	@evict(cachingKeys.Tariff)
	addTopup(reqBody: TopupRrequest) {
		const body = {
			msisdn: reqBody.msisdn,
			amount: reqBody.amount,
			creditCard: {
				type: reqBody.type,
				number: reqBody.holderNumber,
				holderName: reqBody.holderName,
				expiryDate: reqBody.expiryDate,
				cvv: reqBody.cvv,
			},
		};
		const url = API_URLS.TopUp.PostTopup.replace('{id}', this.subscriptionService.customerData.currentService.siteId);
		let headers = new HttpHeaders();
		headers = headers.append('Content-Type', 'application/json');
		const options = {
			headers: headers,
		};
		return this.http.post(url, body, options).pipe(map((res) => res));
	}
}
