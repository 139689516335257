import { SharingTypeEnum } from '../enums/sharing-type.enum';
import { UnitCode } from '../enums/unitCode.enum';
import { ServiceBalanceSubscription } from './service-balance-subscription.model';

export class Dashboard {
	id: number;
	code: string;
	type: string;
	isShared: boolean;
	category: string;
	expirationDate: Date;
	activationDate?: Date;
	available_allowance = 0;
	available_allowance_unit_code: UnitCode;
	used_allowance = 0;
	used_allowance_unit_code: UnitCode;
	isLimited: boolean;
	isSameLimitOfLeader: boolean;
	remaining_allawonce: number;
	remaining_allowance_unit_code: UnitCode;
	group: string;
	dataSharingType: string;
	uploadSpeed?: number;
	downloadSpeed?: number;
	percentage?: number;
	shortDescription?: string;
	precentageForMember?: number;
	precentageForUsedMember?: number;
	MaxpointPrecentageStop?: number;
	name: string;
	amount: number;
	currency: string;
	services: [ServiceBalanceSubscription];
	// story 399
	currentStatus?: string;
	targetLimit?: string;
	basicEnjoyMore?: boolean;
	// mves-1926
	sharingType?: SharingTypeEnum;
	isEligibleForSharing?: boolean;
	productType?: string;
}

export interface DashboardError {
	error?: {
		title: string;
	};
	title?: string;
}

export interface ConsumptionData {
	type: string;
	name?: string;
	allowance: Allowance;
	group?: string;
}

interface Allowance {
	availableAllowance: string;
	usedAllowance: string;
	availableAllowanceUnitCode: string;
	usedAllowanceUnitCode: string;
	unlimited: boolean;
	remainingAllowance: string;
	remainingAllowanceUnitCode: string;
}

export type MappedBundleType = Partial<ConsumptionData> & {
	name: string;
	iconId: string;
	limitedValueText: string;
};
