import { TranslateService } from '@ngx-translate/core';
import { CustomStyle, PaymentFullTrayModel } from '../../../shared/models/payment';
import {
	overlayBoldTitleStyle,
	overlayMainsectionGrayBackGroundOnMobile,
	overlayMainsectionSelector,
	overlayTitleSelector,
	removePaddingCustomStyle,
	removePaddingSelector,
} from '../../../shared/constants/defines';
import { PaymentPages } from '../../../shared/enums/payment-pages.enum';
import { PaymentWalletService } from '../../../shared/services/payment-wallet.service';
import { DelegateCreationService } from '../delegate-creation.service';
import { PaymentNavigationService } from '../payment-navigation.service';
import { PaymentTrayConfigInterface } from './payment-tray-config-interface';
import { PaymentTrayConfigPostPaid } from './payment-tray-config-postPaid';

export class PaymentTrayConfigStandAloneWalletManagement
	extends PaymentTrayConfigPostPaid
	implements PaymentTrayConfigInterface
{
	// this component is treated like an ovrelay we remove tem from history after navigating away from them
	// not to show them to the user accidentaly when he navigate back
	compnentToBeRemovedFromHistoryAfternavigation: PaymentPages[] = [
		PaymentPages.cancel,
		PaymentPages.loading,
		PaymentPages.deleteConfiramtion,
	];
	/** variable to be used for getting a tray config method based on component name */
	trayConfigurations: any = {
		[PaymentPages.ko]: this.getKoCompTrayConfig.bind(this),
		[PaymentPages.wallet]: this.getWalletCompTrayConfig.bind(this),
		[PaymentPages.loading]: this.getLoadingCompTrayConfigForStandAloneWallet.bind(this),
		[PaymentPages.deleteConfiramtion]: this.getDeleteCardCompTrayConfig.bind(this),
	};
	constructor(
		public paymentNavService: PaymentNavigationService,
		public delegateService: DelegateCreationService,
		public translate: TranslateService,
		public paymentWalletService: PaymentWalletService
	) {
		super(paymentNavService, delegateService, translate, paymentWalletService);
	}
	/**
	 * @param compName the page we need it's tray configuration
	 * function should return PaymentFullTrayModel containing
	 * the proper tray configuration
	 */
	getCurrentTrayConfig(compName: PaymentPages): PaymentFullTrayModel {
		let config: PaymentFullTrayModel;
		/** if compName is exist in trayConfigurations then invoke its method to get tray configuration */
		if (this.trayConfigurations[compName]) {
			config = this.trayConfigurations[compName]();
		} else {
			config = this.getIframeCompTrayConfig();
		}
		return config;
	}
	/**
	 * function return PaymentFullTrayModel
	 * the configration of the wallet screen configration
	 */
	getWalletCompTrayConfig(): PaymentFullTrayModel {
		const paymentFullTrayModel: PaymentFullTrayModel = this.getInitalConfigModel();
		paymentFullTrayModel.MVA10Config.showBackButton = true;
		paymentFullTrayModel.paymentCustomConfig.forceShowBackBtn = true;
		paymentFullTrayModel.paymentCustomConfig.initComponantOnBack = false;
		this.translate.get(this.paymentNavService.getWcsPath(PaymentPages.wallet)).subscribe((data) => {
			paymentFullTrayModel.MVA10Config.title = data.title;
		});
		paymentFullTrayModel.paymentCustomConfig.customStyles =
			paymentFullTrayModel.paymentCustomConfig.customStyles.concat(
				new CustomStyle(overlayTitleSelector, overlayBoldTitleStyle),
				new CustomStyle(removePaddingSelector, removePaddingCustomStyle)
			);
		return paymentFullTrayModel;
	}
	/**
	 * function return PaymentFullTrayModel
	 * the configration of the ko screen configration
	 */
	getKoCompTrayConfig(): PaymentFullTrayModel {
		const paymentFullTrayModel: PaymentFullTrayModel = this.getInitalConfigModel();
		paymentFullTrayModel.MVA10Config.title = this.delegateService.paymentErrorConfig?.currentErrorTitle;
		paymentFullTrayModel.paymentCustomConfig.forceShowBackBtn =
			this.delegateService.paymentErrorConfig?.forceShowBackBtn;
		paymentFullTrayModel.paymentCustomConfig.customStyles =
			paymentFullTrayModel.paymentCustomConfig.customStyles.concat(
				new CustomStyle(overlayTitleSelector, overlayBoldTitleStyle),
				new CustomStyle(overlayMainsectionSelector, overlayMainsectionGrayBackGroundOnMobile)
			);
		return paymentFullTrayModel;
	}

	/**
	 * function return PaymentFullTrayModel
	 * the configration of the loading screen configration
	 */
	getLoadingCompTrayConfigForStandAloneWallet(): PaymentFullTrayModel {
		const paymentFullTrayModel: PaymentFullTrayModel = this.getLoadingCompTrayConfig();
		paymentFullTrayModel.MVA10Config.hideXButton = true;
		return paymentFullTrayModel;
	}
}
