<div class="site-container">
	<p class="site-container-description selected-site">
		{{ 'v10.payment.messagesList.siteSelector.billingAddressesTray.currentBillingAddress.text' | translate }}
	</p>
	<hr class="bottom-line" />
	<div class="nav-container">
		<div class="MV10-item-container">
			<div class="site-container__element_mid">
				<p class="site-container__address">{{ selectedSite.address?.formattedAddress }}</p>
			</div>
			<img src="{{ appService.getImgFullPath('https://assets-es-pprd.dxlpreprod.local.vodafone.es/assets/images/ic-tick-solution-shakefinder-green.png') }}" />
		</div>
		<p class="site-container__id">
			{{ 'v10.payment.itemsList.siteSelector.accountNumber.text' | translate }} {{ selectedSite.id }}
		</p>
		<hr class="bottom-line" />
	</div>
	<p class="site-container-description">
		{{ 'v10.payment.messagesList.siteSelector.billingAddressesTray.otherAddress.text' | translate }}
	</p>
	<hr class="bottom-line" />
	<div (click)="changeSite(site)" class="nav-container" *ngFor="let site of otherSitesList">
		<div class="MV10-item-container nav-pointer">
			<div class="site-container__element_mid">
				<p class="site-container__address">{{ site.address?.formattedAddress }}</p>
			</div>
			<div class="icon-chevron">
				<svg>
					<use xlink:href="#icon-chevron-right" />
				</svg>
			</div>
		</div>
		<p class="site-container__id nav-pointer">
			{{ 'v10.payment.itemsList.siteSelector.accountNumber.text' | translate }} {{ site.id }}
		</p>
		<hr class="bottom-line" />
	</div>
	<div class="site-container__cancel-btn-container">
		<button (click)="closeTray()" class="site-container__cancel-btn">
			{{ 'v10.payment.messagesList.siteSelector.billingAddressesTray.button1.text' | translate }}
		</button>
	</div>
</div>
