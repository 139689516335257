import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, throwError } from 'rxjs';
import { map } from 'rxjs/operators';
import { CONFIG } from '../../shared/constants/defines';
import { API_URLS } from '../../shared/constants/routes-config';
import { URLConfig, URLConfigResponse } from '../../shared/models/url.model';

@Injectable()
export class RoutesConfigService {
	constructor(private http: HttpClient) {}
	getUrl(sourceUrl: string): Observable<URLConfig> {
		if (!sourceUrl) {
			return throwError(null);
		}
		const url = API_URLS.url.getUrl.replace('{sourceUrl}', sourceUrl);
		let headers = new HttpHeaders();
		headers = headers.append('Authorization', 'Basic ' + btoa(CONFIG.CLIENT_ID));
		const config = {
			headers: headers,
		};

		return this.http.get<URLConfig>(url, config).pipe(
			map((response: URLConfigResponse) => {
				const items = response.items;
				const url: URLConfig = new URLConfig();
				if (items) {
					url.id = items[0]?.id || '';
					url.name = items[0]?.name || '';
					url.source = items[0]?.sourceLink || '';
					url.destination = items[0]?.destination || '';
					url.urlType = items[0]?.urlType || '';
					url.messageCode = response?.messageCode;
				}
				return url;
			})
		);
	}
}
