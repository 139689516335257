import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { tagging } from '../../../config/tagging-config';
import { TaggingHelperService } from '../../core/services/tagging.helper.service';
import * as constants from '../../shared/constants/defines';
import { Engagement } from '../enums/engagement.enum';
import { EntryPointModel, EntryPointResponseModel } from '../models/entry-points.model';
import { TaggingViewModel } from '../models/tagging.model';

@Injectable({
	providedIn: 'root',
})
export class DispacherCaptaService {
	public entryPointCache: EntryPointModel;
	public journeyName: string;

	constructor(private translateService: TranslateService, private taggingHelperService: TaggingHelperService) {}

	/** Check EPs BACKROOM KO*/
	public trastiendaKO(response: EntryPointResponseModel): boolean {
		const _foundNTOLKO: EntryPointModel = response.entryPoints.find(
			(item: EntryPointModel) => item.code.toLocaleLowerCase() === constants.entryPointsMVA10.NTOLKO.toLocaleLowerCase()
		);
		return !!_foundNTOLKO;
	}

	private getVisitorIDs(_externalURL: string): string {
		let destinationURLWithVisitorIDs: string = _externalURL;
		if ((window as any).Visitor) {
			const visitor: any = (window as any).Visitor.getInstance(Engagement.visitorInstance);
			destinationURLWithVisitorIDs = visitor.appendVisitorIDsTo(_externalURL);
		}
		return destinationURLWithVisitorIDs;
	}
	private dispacherCaptaTagging(_externalURL: string): void {
		const data: TaggingViewModel = { ...tagging.dispacherCapta.data };
		data.page_subcategory_level_2 = this.journeyName;
		data.navigation_level_3 = this.journeyName;
		data.page_name = `${data.page_section}:${data.navigation_level_2}:${data.navigation_level_3}:${data.navigation_level_4}`;
		const event: TaggingViewModel = { ...tagging.dispacherCapta.event };
		event.url_origin = event.url_origin.replace('{0}', data.page_name);
		event.url_destination = event.url_destination.replace('{0}', _externalURL);
		event.event_context = event.event_context.replace('{0}', this.journeyName);
		const customLink: string = `${data.page_name}:${event.event_name}`,
			contextData: TaggingViewModel = { ...data, ...event };
		this.taggingHelperService.track(customLink, contextData);
	}
	/** Dispacher EXTERNAL NAVIGATE*/
	public dispacherCapta(): void {
		let externalURL: string = constants.vodafonees_url;
		if (this.entryPointCache) {
			const wcsEPLinkWeb: string = this.translateService.instant(
				`v10.flows.customization.${this.entryPointCache.code}`
			)?.link_ko;
			externalURL = wcsEPLinkWeb ?? externalURL;
		}
		this.dispacherCaptaTagging(externalURL);
		externalURL = this.getVisitorIDs(externalURL);
		window.open(externalURL, '_self');
	}
}
