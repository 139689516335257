import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { AppService } from '../../../app.service';
import { CommercialNewLinesPurchaseService } from '../../../commercial-new-lines-purchase/services/commercial-new-lines-purchase.service';
import { euroPerMonth, laCintilloColor, month, recurrenceDays } from '../../constants/defines';
import { PLPCardRevamp } from '../../models/PLP-card-revamp.model';
import { PriceModel } from '../../models/priceModel.model';

@Component({
	selector: 'sp-plp-revamp-card',
	templateUrl: './plp-revamp-card.component.html',
	styleUrls: ['./plp-revamp-card.component.scss'],
})
export class PLPRevampCardComponent implements OnInit {
	@Input() cardResp: PLPCardRevamp;
	@Input() showIva: boolean;
	@Input() isMandatory: boolean = false;
	@Output() blueClick: EventEmitter<{ openBackdrop: boolean; text: string; code: string; textTooltip: string }> =
		new EventEmitter<{ openBackdrop: boolean; text: string; code: string; textTooltip: string }>();
	@Output() continue: EventEmitter<void> = new EventEmitter<void>();
	@Output() info: EventEmitter<{ isPega: boolean; code: string; cintilloText: string }> = new EventEmitter<{
		isPega: boolean;
		code: string;
		cintilloText: string;
	}>();
	public existsDescription1: boolean;
	public existsDescription2: boolean;
	public existsTopIcon: boolean;
	public existsTopIconText: boolean;
	public coinMonth: string;
	public textBlue: string[] = [];
	public controlPrice: boolean;
	public isPega: boolean;
	public showCintillo: boolean;
	public cintilloColor: string;
	public cintilloBorderColor: string = laCintilloColor.colorStyle;
	public textColor: string;
	days: string = recurrenceDays;
	public priceConvertedWithTaxes: number;
	public priceConverted: number;
	public priceModel: PriceModel = new PriceModel();
	public month: string = month;

	constructor(
		public appService: AppService,
		public commercialNewLinesPurchaseService: CommercialNewLinesPurchaseService
	) {}

	ngOnInit(): void {
		this.showCintillo = !!this.cardResp?.headBandText;
		this.cintilloColor = this.cardResp?.bgColor;
		this.textColor = this.cardResp?.textColor;
		this.cintilloBorderColor = this.cintilloBorderColor.replace('<color>', this.cintilloColor);
		this.cardResp.topIcon = this.appService.getImgFullPath(this.cardResp.topIcon);
		this.existsDescription1 = !!(this.cardResp.description1 !== '' || undefined);
		this.existsDescription2 = !!(this.cardResp.description2 !== '' || undefined);
		this.existsTopIcon = !!(this.cardResp.topIcon !== '' || undefined);
		this.existsTopIconText = !!(this.cardResp.topIconText !== '' || undefined);
		this.isPega = this.cardResp.isPega;
		this.controlPrice = parseInt(this.cardResp.textPrice, 10) <= parseInt(this.cardResp.textPriceWithDiscount, 10);
		if (!this.cardResp?.isPrepaid) {
			this.refactorPrice(
				this.cardResp.textPrice,
				this.cardResp.textPriceWithDiscount,
				this.cardResp.textPriceRoundedInstallment,
				this.cardResp.textPriceRoundedInstallmentPromo
			);
		}
		this.priceConverted = +this.cardResp?.textPriceWithDiscount;

		if (this.cardResp?.textDescriptionBelow) {
			this.textBlue = this.cardResp.textDescriptionBelow.split('strong>');
			this.textBlue[0] = this.textBlue[0].replace('<', '');
			if (this.textBlue[1]) {
				this.textBlue[1] = this.textBlue[1].replace('</', '');
			}
		}
	}

	clickContinue(): void {
		this.continue.emit();
	}

	clickInfo(): void {
		this.info.emit({ isPega: this.isPega, code: this.cardResp?.code, cintilloText: this.cardResp?.headBandText });
	}
	refactorPrice(
		_textPrice: string,
		_textPriceWithDiscount: string,
		_textPriceRoundedInstallment: string,
		_textPriceRoundedInstallmentPromo: string
	): void {
		let extractNumber: string[] = _textPrice.split(euroPerMonth);
		this.priceModel.priceTaxes = extractNumber[0];
		extractNumber = _textPriceWithDiscount.split(euroPerMonth);
		this.priceModel.priceDiscountTaxes = extractNumber[0];
		extractNumber = _textPriceRoundedInstallmentPromo.split(euroPerMonth);
		this.priceModel.priceDiscount = extractNumber[0];
		extractNumber = _textPriceRoundedInstallment.split(euroPerMonth);
		this.priceModel.price = extractNumber[0];
	}
}
