import { Injectable } from '@angular/core';
import { NewTaggingHelperService } from '../../core/services/new-tagging.helper.service';
import { StorageService } from '../../core/services/storage.service';
import { MyOrdersService } from '../components/my-orders/services/my-orders.service';
import { PlatformType } from '../enums/platform-type.enum';
import { InteractionTaggingModel } from '../models/new-tagging-Interaction.model';
import { PageTaggingModel } from '../models/new-tagging-page.model';
import { UtagDataTaggingModel } from '../models/tagging.model';

@Injectable({
	providedIn: 'root',
})
export class MyOrdersTaggingService {
	utagData: UtagDataTaggingModel;
	lastMappedPage: PageTaggingModel;
	lastMappedInteraction: InteractionTaggingModel;

	constructor(
		private myOrdersService: MyOrdersService,
		private newTagging: NewTaggingHelperService,
		public storage: StorageService
	) {}

	/**
	 * Send old window.sendView tagging (pages)
	 */
	sendPage(pageNameToExtend?: string[]): void {
		if (pageNameToExtend) {
			this.extendUtagPageName(pageNameToExtend);
		}
		this.newTagging.state(this.stateMapper(this.utagData));
	}

	private stateMapper(oldModel: UtagDataTaggingModel): PageTaggingModel {
		const wvPlatform: string = this.storage.getLocalStorage('wvPlatform')?.toLowerCase() || null;
		const platform: string[] = [PlatformType.Android.toString(), PlatformType.IOS.toString()];
		const splittedPageName: string[] = oldModel.page_name?.split(':') || [''];
		this.lastMappedPage = {
			site_section: splittedPageName[0],
			page_detail: splittedPageName[splittedPageName.length - 1],
			page_subcategory_level: [...splittedPageName].splice(1),
			custom_map: {
				asset_name: platform.includes(wvPlatform) ? 'mivoapp' : 'mivoweb',
				page_platform: platform.includes(wvPlatform) ? wvPlatform : 'web',
				monitoring_purchase_id: oldModel.monitoring_purchase_id,
				monitoring_purchase_number: oldModel.monitoring_purchase_number,
				monitoring_purchase_status: oldModel.monitoring_purchase_status,
				monitoring_purchase_substatus: oldModel.monitoring_purchase_substatus,
				monitoring_purchase_type: oldModel.monitoring_purchase_type,
				monitoring_purchase_code: oldModel.monitoring_purchase_code,
				monitoring_purchase_technology: oldModel.monitoring_purchase_technology,
				monitoring_purchase_flux: oldModel.monitoring_purchase_flux,
				monitoring_purchase_step: oldModel.monitoring_purchase_step,
			},
		};
		return this.lastMappedPage;
	}

	/**
	 * Send old window.sendLink tagging (clicks and events)
	 */
	sendInteraction(send_link_data: { link_type: string }): void {
		this.newTagging.interaction(
			this.interactionMapper(send_link_data),
			this.lastMappedPage,
			this.lastMappedPage.custom_map
		);
	}

	private interactionMapper(oldModel: UtagDataTaggingModel): InteractionTaggingModel {
		this.lastMappedInteraction = {
			event: {
				event_context: '',
				event_category: undefined,
				event_label: oldModel.link_type,
			},
		};
		return this.lastMappedInteraction;
	}

	_extend(extObj: UtagDataTaggingModel): void {
		this.utagData = Object.assign({}, this.utagData, extObj, {
			id_cliente: this.myOrdersService.MYORDERS_DATA.idCliente,
		});
	}

	addPageListItems(items: string[], replace: boolean): string {
		const currentPageName: string[] = this.utagData.page_name.split(':');
		const oldItems: string[] = replace
			? currentPageName.filter((_item, index, arr) => index + 1 !== arr.length)
			: currentPageName;
		return [].concat(oldItems, items).join(':');
	}

	removePageListItems(items: string[], pop?: boolean): string {
		const page: string = this.utagData.page_name;
		return page
			.split(':')
			.filter((item) => item?.trim())
			.filter((pageListItem, index, arr) => (pop ? index + 1 !== arr.length : !items.includes(pageListItem)))
			.join(':');
	}

	extendUtagPageName(pageName: string[], add?: boolean, remove?: boolean): void {
		this.utagData = Object.assign({}, this.utagData, {
			link_type: '',
		});
		this._extend({
			page_name: remove ? this.removePageListItems(pageName) : this.addPageListItems(pageName, add),
		});
		this.utagData.page_name = this.removeTaggingDuplicates(this.utagData.page_name);
	}

	private removeTaggingDuplicates(pageName: string): string {
		return [...new Set(pageName?.split(':'))].join(':');
	}

	resetDetailVariables(): void {
		this.utagData = Object.assign({}, this.utagData, {
			monitoring_purchase_id: '',
			monitoring_purchase_status: '',
			monitoring_purchase_type: '',
			monitoring_purchase_step: '',
		});
	}

	normalize(stringChain: string): string {
		return stringChain
			.split(':')
			.map((substr: string) =>
				substr
					.toLowerCase()
					.normalize('NFD')
					.replace(/[\u0300-\u036f]/g, '')
					.replace(/[`~!@#€$%^&*()_|+\-=?;'",.<>\{\}\[\]\\\/]/gi, '')
					.replace(/\s{2}/, ' ')
			)
			.join(':');
	}
}
