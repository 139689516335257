import { SiteType } from '../enums/siteType.enum';
import { ServiceModel } from './service.model';

export class Subscription {
	public customerAccountsId: string;
	public currentService: ServiceModel;
	public services: ServiceModel[];
	public SiteType: SiteType;
}

export class SubscriptionModel {
	public id?: number;
	public getDate?: Date;
	public count?: number;
	public offset?: number;
	public totalItems?: number;
	public items?: ServiceModel[];
	public subscriptionsAmount?: number;
	public subscriptionDecimalAmount?: number;
	public discounts?: Discount[];
	public subscriptionDecimalAmountNoTax?: number;
	public subscriptionsAmountNoTax?: number;
	public hasOneProfesionalItems?: boolean;
	public properties?: string[];
	public isExtras?: boolean;
	public holdId?: string;
	public siteId?: string;
	public querySiteId?: string;
	public queryHolderId?: string;
	public queryTargetMsisdn?: string;
	public subscriptionWithoutDiscountAmountNoTax?: number;
}

export class Discount {
	public text?: string;
	public endDate?: string;
}

export class SubscriptionsItemsParts {
	public customerAccounts?: SubscriptionCustomerAccounts[];
	public productOffers?: SubscriptionProductOffers[];
}

export class SubscriptionCustomerAccounts {
	public id?: string;
	public clientType?: string;
	public type?: string;
	public status?: string;
	public segment?: string;
	public overdue?: boolean;
	public marketSegment?: string;
	public digital?: boolean;
	public smartPay?: boolean;
	public onePlus?: boolean;
	public onePlusMicro?: boolean;
	public superOffer?: string;
}

export class SubscriptionProductOffers {
	public code?: string;
	public type?: string;
	public name?: string;
	public title?: string;
	public subtitle?: string;
	public additionalLinesDes?: string;
	public startDate?: string;
}
