import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Breakpoints } from '@mva10/mva10-angular';
import { TranslateService } from '@ngx-translate/core';
import { AppService } from 'src/app/app.service';
import { ResizeService } from 'src/app/mva10/shared/services/resize.service';
import { EntryPointResponseModel } from 'src/app/shared/models/entry-points.model';
import { OverLayContent } from 'src/app/shared/models/overlayContent.model';
import { config } from 'src/config/pages-config';
import { tagging } from 'src/config/tagging-config';
import { TaggingHelperService } from '../../../core/services/tagging.helper.service';
import { LeftMenuWs10Service } from '../../../mva10/shared/services/left-menu-ws10.service';
import { TariffChangeService } from '../../../products-and-services/tariff-change/services/tariff-change.service';
import { AppThemes, NBAOverlayImage, changeTariffNoPromoBtnWidths } from '../../../shared/constants/defines';
import { CaptureResponseService } from '../../services/capture-response.service';
import { EntryPointsService } from '../../services/entry-points.service';

@Component({
	selector: 'sp-nba-trade-up-tarifa',
	templateUrl: './nba-trade-up-tarifa.component.html',
	styleUrls: ['./nba-trade-up-tarifa.component.scss'],
})
export class NbaTradeUpTarifaComponent implements OnInit, OnDestroy {
	overlayContent: OverLayContent;
	isMobile: boolean;
	previousTheme: string;
	tariffName: string;
	tariffCode: string;
	constructor(
		public translate: TranslateService,
		private router: Router,
		private resizeService: ResizeService,
		public appService: AppService,
		private activatedRoute: ActivatedRoute,
		private tagging: TaggingHelperService,
		private captureResponseService: CaptureResponseService,
		private entryPointService: EntryPointsService,
		private tariffChangeService: TariffChangeService,
		private leftMenuService: LeftMenuWs10Service
	) {}
	ngOnDestroy(): void {
		this.appService.hiddenHeader = false;
		this.appService.theme = this.previousTheme;
	}

	ngOnInit(): void {
		this.tagging.track(tagging.nbaCardTile.eventName, tagging.nbaCardTile.data, tagging.navigateToNbaTrade);
		this.appService.hiddenHeader = true;
		this.leftMenuService.showLeftMenu = true;
		this.previousTheme = this.appService.theme;
		this.isMobile = this.resizeService.breakpoint < Breakpoints.TABLET;
		this.appService.theme = this.isMobile ? `${AppThemes.ThemeBkgWhite} ${AppThemes.Theme2}` : AppThemes.ThemeGrayBG;
		this.checkIfUserEligibleForPromo() ? this.getPromoContent() : this.getContentNoPromo();
		this.sendAcceptCaptureResponse();
	}

	sendAcceptCaptureResponse(): void {
		this.captureResponseService.entryPoint = this.entryPointService.getEntryPointByCode(
			this.entryPointService.entryCode
		);
		this.captureResponseService.isPega = !!this.captureResponseService.entryPoint.offerVFDescriptor;
		const pegaEntryPoints: EntryPointResponseModel = {
			entryPoints: [this.captureResponseService.entryPoint],
		};
		this.captureResponseService.callCaptureResponsePega(pegaEntryPoints);
	}

	closeTray(): void {
		this.router.navigate([config.dashboard.route]);
	}
	btnAction(): void {
		this.tagging.track(tagging.MasInfoNBA.eventName, tagging.MasInfoNBA.data, tagging.navigateFromNbaTrade);
		this.activatedRoute.queryParams.subscribe((params) => {
			if (params && params.tariffName) {
				this.tariffChangeService.selectedTariffCode = this.tariffCode;
				this.router.navigate([config.TariffChange.TariffDetailsPage.route]);
			}
		});
	}
	getContent(): string {
		const WCSValue: string = this.translate.instant('v10.dashboard.tariffTradeUp.paragraph');
		return WCSValue.replace('{0}', this.tariffName);
	}

	getPromoContent(): void {
		this.activatedRoute.queryParams.subscribe((params) => {
			if (params && params.tariffName) {
				this.getTradeUpTariffWCSContent(params.tariffName);
			}
		});
	}

	checkIfUserEligibleForPromo(): boolean {
		let res: boolean;
		this.activatedRoute.queryParams.subscribe((params) => {
			if (params && params.tariffName) {
				this.translate.get('v10.dashboard.tariffTradeUp').subscribe((data) => {
					res = data[params?.tariffName] ? true : false;
				});
			}
		});
		return res;
	}

	getContentNoPromo(): void {
		this.overlayContent = {
			pageTitle: this.translate.instant('v10.dashboard.tariffTradeUp.title'),
			pageSubtitle: this.translate.instant('v10.dashboard.tariffTradeUp.subTtile'),
			btnConfigs: {
				btnName: this.translate.instant('v10.dashboard.tariffTradeUp.button'),
				btnWidth: this.isMobile ? changeTariffNoPromoBtnWidths.mobile : changeTariffNoPromoBtnWidths.desktop,
			},
			imgUrl: this.isMobile ? NBAOverlayImage.mobile : NBAOverlayImage.tabletsAndDesktop,
			subtitleContentSafeHTML: this.getContent(),
		};
	}

	getTradeUpTariffWCSContent(tariffName: string): void {
		this.translate.get(`v10.dashboard.tariffTradeUp.${tariffName}`).subscribe((data) => {
			this.tariffCode = data?.code;
			this.tariffName = data?.name;
			this.overlayContent = {
				pageTitle: data.title,
				pageSubtitle: data.subTtile,
				subtitleContent: data.paragraph,
				btnConfigs: {
					btnName: data.button,
					btnWidth: this.isMobile ? changeTariffNoPromoBtnWidths.mobile : changeTariffNoPromoBtnWidths.desktopPromo,
				},
				imgUrl: this.isMobile ? data.coverPhoto : data.coverPhotoResp,
			};
		});
	}
}
