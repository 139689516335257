import { TranslateService } from '@ngx-translate/core';
import { PaymentPages } from 'src/app/shared/enums/payment-pages.enum';
import { CustomStyle, PaymentFullTrayModel } from '../../../shared/models/payment';
import { overlayTitlecustomStyle, overlayTitleSelector } from '../../../shared/constants/defines';
import { PaymentWalletService } from '../../../shared/services/payment-wallet.service';
import { DelegateCreationService } from '../delegate-creation.service';
import { PaymentNavigationService } from '../payment-navigation.service';
import { PaymentTrayConfigDefault } from './payment-tray-config-default';
import { PaymentTrayConfigInterface } from './payment-tray-config-interface';

export class PaymentTrayConfigCardPayment extends PaymentTrayConfigDefault implements PaymentTrayConfigInterface {
	// this component is treated like an ovrelay we remove tem from history after navigating away from them
	// not to show them to the user accidentaly when he navigate back
	compnentToBeRemovedFromHistoryAfternavigation: PaymentPages[] = [
		PaymentPages.cancel,
		PaymentPages.loading,
		PaymentPages.deleteConfiramtion,
	];
	/** variable to be used for getting a tray config method based on component name */
	trayConfigurations: any = {
		[PaymentPages.thirdPartyContainer]: this.getIframeCompTrayConfig.bind(this),
		[PaymentPages.ok]: this.getOkCompTrayConfig.bind(this),
		[PaymentPages.ko]: this.getKoCompTrayConfig.bind(this),
		[PaymentPages.cancel]: this.getCancelCompTrayConfig.bind(this),
		[PaymentPages.wallet]: this.getWalletCompTrayConfig.bind(this),
		[PaymentPages.cardPaymentCommercial]: this.getCreditCardCompConfig.bind(this),
	};
	constructor(
		public paymentNavService: PaymentNavigationService,
		public delegateService: DelegateCreationService,
		public translate: TranslateService,
		public paymentWalletService: PaymentWalletService
	) {
		super(paymentNavService, delegateService, translate, paymentWalletService);
	}
	/**
	 * function return PaymentFullTrayModel
	 * the configuration of the card payment start screen configuration
	 */
	getCreditCardCompConfig(): PaymentFullTrayModel {
		const paymentFullTrayModel: PaymentFullTrayModel = this.getInitalConfigModel();
		this.translate.get(this.paymentNavService.getWcsPath(PaymentPages.cardPaymentCommercial)).subscribe((data) => {
			paymentFullTrayModel.MVA10Config.title = data.title;
		});
		paymentFullTrayModel.paymentCustomConfig.closeBtnAction = () => {
			this.paymentNavService.showCancelPayment();
			this.delegateService.paymentTaggingConfig.tagCloseBtnClick();
			this.paymentNavService.showCancelPayment();
		};
		paymentFullTrayModel.paymentCustomConfig.initComponantOnBack = false;
		paymentFullTrayModel.paymentCustomConfig.customStyles.push(
			new CustomStyle(overlayTitleSelector, overlayTitlecustomStyle)
		);
		return paymentFullTrayModel;
	}
}
