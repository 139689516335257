import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { tagging } from '../../../config/tagging-config';
import { StorageService } from '../../core/services/storage.service';
import { TaggingHelperService } from '../../core/services/tagging.helper.service';
import * as constants from '../../shared/constants/defines';
import {
	CommercialJourneys,
	click,
	indefinidedType,
	legalTerms,
	link,
	permanence,
	priceSize,
	view,
	x,
} from '../../shared/constants/defines';
import { ClientTypology } from '../../shared/enums/clientTopology.enum';
import { PdpData } from '../../shared/models/PdpData.model';
import { CommercialOffer } from '../../shared/models/commercial-offer.model';
import { EntryPointModel, EntryPointResponseModel } from '../../shared/models/entry-points.model';
import { PromoCommercial } from '../../shared/models/promo-commercial.model';
import { TaggingViewModel } from '../../shared/models/tagging.model';
import { MicroFlowsService } from '../../shared/services/micro-flows.service';
import { UtilitiesService } from '../../shared/utils/utilities.service';

@Injectable({
	providedIn: 'root',
})
export class CommercialNewLinesPurchaseService {
	private journeyCategory: string;
	private journeySubcategory: string;
	private journeyIsMainFlow: string;
	public PDPdata: PdpData;
	public offer: CommercialOffer;
	public promotions: PromoCommercial[];
	public taxType: string;
	public taxValue: number;
	public includedText: string;
	public sticky: string;
	public textButton: string;
	public controlPrice: boolean;
	public isPega: boolean;
	public pegaText: string;
	public priceSmall: string = priceSize.small;
	public priceMedium: string = priceSize.medium;
	public priceLarge: string = priceSize.large;
	public typeClient: string;
	public clientType: string;
	public journeyProcess: string;
	public mainFlow: string;
	public productsTag: string;
	public legalTermsURL: string;
	public legalTermsText: string;
	public permanenceText: string;
	public bindingPenalty: string;
	public isOpenAccordeon: boolean;
	public havePermanence: boolean;
	public isMandatory: boolean;
	public entryPointList: EntryPointResponseModel;
	public readCaptureResponse: boolean;
	public permanenceNumber: string;
	public MaxPermanenceNumber: string;
	public MinPermanenceNumber: string;
	public priceDiscountPromo: string;
	public promoDurationInfinite: string;
	public promoWithDuration: string;

	constructor(
		public taggingHelperService: TaggingHelperService,
		private utilitiesService: UtilitiesService,
		private storageService: StorageService,
		private microFlowsService: MicroFlowsService,
		public translate: TranslateService
	) {
		this.journeyCategory = this.taggingHelperService.getUserType(
			this.storageService.userProfile.customerType,
			ClientTypology
		);
		this.journeySubcategory = this.utilitiesService.isPurePrepaid()
			? constants.journeyTags.prepaid
			: constants.journeyTags.pospaid;
		this.journeyIsMainFlow = this.microFlowsService.isMainFlow ? constants.Commons.tagYes : constants.Commons.no;
		this.initLocaleStorage();
	}

	private initLocaleStorage(): void {
		this.PDPdata = this.storageService.getLocalStorage(constants.LOCAL_STORAGE_KEYS.PDPDATA);
		this.productsTag = this.storageService.getLocalStorage(constants.LOCAL_STORAGE_KEYS.PRODUCTSTAG);
		this.isPega = this.storageService.getLocalStorage(constants.LOCAL_STORAGE_KEYS.ISPEGA);
		this.pegaText = this.storageService.getLocalStorage(constants.LOCAL_STORAGE_KEYS.PEGATEXT);
		this.offer = this.storageService.getLocalStorage(constants.LOCAL_STORAGE_KEYS.OFFER);
		this.includedText = this.storageService.getLocalStorage(constants.LOCAL_STORAGE_KEYS.INCLUDEDTEXT);
		this.textButton = this.storageService.getLocalStorage(constants.LOCAL_STORAGE_KEYS.TEXTBUTTON);
		this.bindingPenalty = this.storageService.getLocalStorage(constants.LOCAL_STORAGE_KEYS.BINDINGPENALTY);
		this.permanenceText = this.storageService.getLocalStorage(constants.LOCAL_STORAGE_KEYS.PERMANENCETEXT);
		this.legalTermsURL = this.storageService.getLocalStorage(constants.LOCAL_STORAGE_KEYS.LEGALTERMSURL);
		this.legalTermsText = this.storageService.getLocalStorage(constants.LOCAL_STORAGE_KEYS.LEGALTERMSTEXT);
		this.taxType = this.storageService.getLocalStorage(constants.LOCAL_STORAGE_KEYS.TAXTYPE);
		this.taxValue = this.storageService.getLocalStorage(constants.LOCAL_STORAGE_KEYS.TAXVALUE);
		this.journeyProcess = this.storageService.getLocalStorage(constants.LOCAL_STORAGE_KEYS.JOURNEYPROCESS);
		this.promotions = this.storageService.getLocalStorage(constants.LOCAL_STORAGE_KEYS.PROMOTIONS);
	}

	public setTaggingBannerIPC(_optionSelected: string, _isImpresion: boolean): void {
		const journeyProcess: string =
			_optionSelected?.toLocaleLowerCase() === constants.CommercialJourneys.portabilidad
				? constants.CommercialJourneys.portabilidad
				: constants.CommercialJourneys.altaNueva;
		const eventLabel: string = _isImpresion
			? constants.CommercialJourneys.Tagg.nexusDMP.eventType.impresion
			: constants.CommercialJourneys.Tagg.nexusDMP.eventType.close;
		const page: TaggingViewModel = { ...tagging.newLinesPurchase.bannerIPC.page };
		page.page_name = `${page.page_section}:${page.page_subcategory_level_1}:${page.page_subcategory_level_2}:${page.page_screen}`;
		const event: TaggingViewModel = { ...tagging.newLinesPurchase.bannerIPC.event };
		event.event_label = event.event_label.replace('{0}', eventLabel);
		const journey: TaggingViewModel = { ...tagging.newLinesPurchase.bannerIPC.journey };
		journey.journey_category = this.journeyCategory;
		journey.journey_subcategory = this.journeySubcategory;
		journey.is_main_flow = this.journeyIsMainFlow;
		journey.journey_process = journeyProcess.toLocaleLowerCase();
		const customLink: string = `${page.page_name}:${event.event_label}`,
			contextData: TaggingViewModel = { ...page, ...event, ...journey };
		this.taggingHelperService.track(customLink, contextData);
	}

	public setContinueTagging(): void {
		const tag: TaggingViewModel = { ...tagging.newLinesPurchase.continueToCheckout };
		tag.journey_category = this.typeClient;
		tag.journey_subcategory = this.clientType;
		tag.journey_process = this.journeyProcess;
		tag.is_main_flow = this.mainFlow;
		tag['&&products'] = this.productsTag;
		const action: string = `${tag.page_name}:${tag.event_name}`;
		this.taggingHelperService.track(action, tag, false);
	}
	public setBackdropDLTaggingLogic(
		_optionSelected: string,
		_isPegaDL: boolean,
		_isImpresion: boolean,
		_txtEventLabel?: string
	): void {
		const journeyProcess: string =
			_optionSelected.toLocaleLowerCase() === constants.CommercialJourneys.portabilidad
				? constants.CommercialJourneys.portabilidad
				: constants.CommercialJourneys.altaNueva;
		let eventLabel: string = _isPegaDL
			? constants.bundleupsell.offerType.pega
			: constants.bundleupsell.offerType.public;
		eventLabel = _isImpresion ? eventLabel : _txtEventLabel;
		const page: TaggingViewModel = { ...tagging.newLinesPurchase.backdropDL.page };
		page.page_name = `${page.page_section}:${page.page_subcategory_level_1}:${page.page_subcategory_level_2}:${page.page_screen}`;
		const event: TaggingViewModel = _isImpresion
			? { ...tagging.newLinesPurchase.backdropDL.eventImp }
			: { ...tagging.newLinesPurchase.backdropDL.eventClick };
		event.event_label = event.event_label.replace('{0}', eventLabel);
		const journey: TaggingViewModel = { ...tagging.newLinesPurchase.backdropDL.journey };
		journey.journey_category = this.journeyCategory;
		journey.journey_subcategory = this.journeySubcategory;
		journey.is_main_flow = this.journeyIsMainFlow;
		journey.journey_process = journeyProcess.toLocaleLowerCase();
		const customLink: string = `${page.page_name}:${event.event_label}`,
			contextData: TaggingViewModel = { ...page, ...event, ...journey };
		this.taggingHelperService.track(customLink, contextData);
	}

	public setPDPClickDropdown(): void {
		const page: TaggingViewModel = { ...tagging.newLinesPurchase.viewPDPClickDropdown.page };
		page.page_name = `${page.navigation_level_1}:${page.navigation_level_2}:${page.navigation_level_3}:${page.navigation_level_4}`;
		const event: TaggingViewModel = { ...tagging.newLinesPurchase.viewPDPClickDropdown.eventClick };
		const journey: TaggingViewModel = { ...tagging.newLinesPurchase.viewPDPClickDropdown.journey };
		journey.journey_category = this.journeyCategory;
		journey.journey_subcategory = this.journeySubcategory;
		journey.is_main_flow = this.journeyIsMainFlow;
		journey.journey_process = this.journeyProcess.toLocaleLowerCase();
		journey['&&products'] = this.productsTag;
		const customLink: string = `${page.page_name}:${event.event_label}`,
			contextData: TaggingViewModel = { ...page, ...event, ...journey };
		this.taggingHelperService.track(customLink, contextData);
	}

	public setPermanence(): void {
		let matchPermanence: boolean = false;
		this.promoWithDuration = '';
		this.promoDurationInfinite = '';
		const totalPermanencesNumber: number[] = [];
		this.promotions?.map((promotion) => {
			const numberText: number = +promotion.stayPromotionText;
			if (
				this.offer.promotions?.includes(promotion.id) &&
				!isNaN(numberText) &&
				numberText > 0 &&
				promotion.discountType.includes(CommercialJourneys.discountTypeSubstract)
			) {
				totalPermanencesNumber.push(numberText);
				this.permanenceText = this.translate
					.instant('v10.commercial.additionalLines.PDP.moreInfo.WithPerm')
					.replace('{0}', Math.max(...totalPermanencesNumber));
				if (promotion.discountDuration !== null && promotion.discountDuration !== indefinidedType) {
					this.promoWithDuration = this.translate
						.instant('v10.commercial.additionalLines.PDP.permaBDP.dtoText')
						.replace('{0}', (+promotion.discountAmount + (+promotion.discountAmount * this.taxValue) / 100).toFixed(2))
						.replace('{1}', promotion.discountDuration);
				} else if (promotion.discountDuration === indefinidedType) {
					this.promoDurationInfinite = this.translate
						.instant('v10.commercial.additionalLines.PDP.permaBDP.dtoTextIndefinite')
						.replace('{0}', (+promotion.discountAmount + (+promotion.discountAmount * this.taxValue) / 100).toFixed(2))
						.replace('{1}', promotion.discountDuration);
				}
				this.havePermanence = true;
				matchPermanence = true;
			}
			this.storageService.setLocalStorage(constants.LOCAL_STORAGE_KEYS.PERMANENCETEXT, this.permanenceText);
		});
		if (matchPermanence) {
			this.setNumberOfPermanences(totalPermanencesNumber);
		} else {
			this.permanenceText = this.translate.instant('v10.commercial.additionalLines.PDP.moreInfo.WithoutPerm');
			this.havePermanence = false;
		}
	}

	public setNumberOfPermanences(totalPermanencesNumber: number[]): void {
		if (totalPermanencesNumber.length > 1) {
			this.MaxPermanenceNumber = Math.max(...totalPermanencesNumber).toString();
			this.MinPermanenceNumber = Math.min(...totalPermanencesNumber).toString();
			this.storageService.setLocalStorage(constants.LOCAL_STORAGE_KEYS.MAXPERMANENCE, this.MaxPermanenceNumber);
			this.storageService.setLocalStorage(constants.LOCAL_STORAGE_KEYS.MINPERMANENCE, this.MinPermanenceNumber);
			this.permanenceNumber = undefined;
		} else if (totalPermanencesNumber.length === 1) {
			this.permanenceNumber = totalPermanencesNumber[0].toString();
			this.storageService.setLocalStorage(constants.LOCAL_STORAGE_KEYS.PERMANENCENUMBER, this.permanenceNumber);
			this.MaxPermanenceNumber = undefined;
			this.MinPermanenceNumber = undefined;
		}
	}

	public removeLocaleStorage(): void {
		this.storageService.removeFromLocalStorage(constants.LOCAL_STORAGE_KEYS.PDPDATA);
		this.storageService.removeFromLocalStorage(constants.LOCAL_STORAGE_KEYS.PRODUCTSTAG);
		this.storageService.removeFromLocalStorage(constants.LOCAL_STORAGE_KEYS.ISPEGA);
		this.storageService.removeFromLocalStorage(constants.LOCAL_STORAGE_KEYS.PEGATEXT);
		this.storageService.removeFromLocalStorage(constants.LOCAL_STORAGE_KEYS.OFFER);
		this.storageService.removeFromLocalStorage(constants.LOCAL_STORAGE_KEYS.INCLUDEDTEXT);
		this.storageService.removeFromLocalStorage(constants.LOCAL_STORAGE_KEYS.TEXTBUTTON);
		this.storageService.removeFromLocalStorage(constants.LOCAL_STORAGE_KEYS.BINDINGLIFE);
		this.storageService.removeFromLocalStorage(constants.LOCAL_STORAGE_KEYS.PENALTYNOTE);
		this.storageService.removeFromLocalStorage(constants.LOCAL_STORAGE_KEYS.BINDINGPENALTY);
		this.storageService.removeFromLocalStorage(constants.LOCAL_STORAGE_KEYS.PERMANENCETEXT);
		this.storageService.removeFromLocalStorage(constants.LOCAL_STORAGE_KEYS.LEGALTERMSURL);
		this.storageService.removeFromLocalStorage(constants.LOCAL_STORAGE_KEYS.LEGALTERMSTEXT);
		this.storageService.removeFromLocalStorage(constants.LOCAL_STORAGE_KEYS.TAXTYPE);
		this.storageService.removeFromLocalStorage(constants.LOCAL_STORAGE_KEYS.TAXVALUE);
		this.storageService.removeFromLocalStorage(constants.LOCAL_STORAGE_KEYS.PROMOTIONS);
		this.storageService.removeFromLocalStorage(constants.LOCAL_STORAGE_KEYS.PERMANENCENUMBER);
		this.storageService.removeFromLocalStorage(constants.LOCAL_STORAGE_KEYS.MAXPERMANENCE);
		this.storageService.removeFromLocalStorage(constants.LOCAL_STORAGE_KEYS.MINPERMANENCE);
	}

	public findEntrypointList(_offer: CommercialOffer): EntryPointModel {
		return this.entryPointList.entryPoints.find(
			(item: EntryPointModel) => item.interactionID === _offer.rate.interactionID && item.rank === _offer.rate.rank
		);
	}
	public setEntryPointSelected(_entrypointSelected: EntryPointModel): void {
		this.microFlowsService.laEntryPoint = _entrypointSelected;
	}

	public setTaggingPDP(type: string): void {
		const tag: TaggingViewModel = { ...tagging.newLinesPurchase.pdpGeneric };
		tag.journey_category = this.typeClient;
		tag.journey_subcategory = this.clientType;
		tag.journey_process = this.journeyProcess;
		tag.is_main_flow = this.mainFlow;
		tag['&&products'] = this.productsTag;
		if (type === view) {
			tag.event_category = '';
			tag.event_context = '';
			tag.event_label = '';
			tag['&&events'] = '';
			this.taggingHelperService.viewReplace(false, tag.page_name, tag);
		} else {
			if (type === permanence) {
				tag.event_label = tag.event_label.replace('{0}', click + permanence);
			} else if (type === legalTerms) {
				tag.event_label = tag.event_label.replace('{0}', click + legalTerms);
			} else if (type === x) {
				tag.event_label = tag.event_label.replace('{0}', click + x);
			}
			tag.event_category = tag.event_category.replace('{0}', link);
			const action: string = `${tag.page_name}:${tag.event_name}`;
			this.taggingHelperService.track(action, tag, false);
		}
	}
}
