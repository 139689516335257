import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import * as routes from '../../../../../../config/pages-config';
import { tagging } from '../../../../../../config/tagging-config';
import { AppService } from '../../../../../app.service';
import { CommonService } from '../../../../../core/services/common.service';
import { SubscriptionService } from '../../../../../core/services/subscription.service';
import { TaggingHelperService } from '../../../../../core/services/tagging.helper.service';
import { ServiceGrifo } from '../../../../enums/serviceGrifo.enum';
import { AsessorOrder } from '../../../../models/asessor-order.model';
import { CompanyService } from '../../../../services/company.service';
import { CustomerAccountService } from '../../../../services/customer-account.service';
import { GrifoTimeService } from '../../../../services/grifo-time.service';

@Component({
	selector: 'sp-asessor-digital',
	templateUrl: './asessor-digital.component.html',
	styleUrls: ['./asessor-digital.component.scss'],
})
export class AsessorDigitalComponent implements OnInit {
	@Output() messageEvent = new EventEmitter<string>();
	isXhrOk: boolean = true;
	isInTime: boolean = true;
	isChatActive: boolean = true;
	isEmailActive: boolean = true;
	isPhoneActive: boolean = true;
	isAssessorRateusActive: boolean = true;
	isWhatsappActive: boolean = true;
	isGrifoTimeActive: boolean = true;
	isGrifoEmailActive: boolean = false;
	isDocumentumActive: boolean = true;
	isFormActive: boolean = false;
	isTlfActive: boolean = false;
	grifoEmail: boolean = false;
	grifosActivados: any;
	userInGrifo: any;
	enlEncuesta: any;
	styleAssessorRateus: object;
	chatSegment: boolean;
	taggingVariabels;
	segmentActiveChat: boolean = false;
	showLoading: boolean;
	elementsOrderWCS: Array<string>;
	asessorOrder: AsessorOrder;
	constructor(
		public commonService: CommonService,
		public appService: AppService,
		private GrifoTimeService: GrifoTimeService,
		private taggingHelper: TaggingHelperService,
		private translate: TranslateService,
		private subscription: SubscriptionService,
		private CustomerAccount: CustomerAccountService,
		private router: Router,
		public companyService: CompanyService
	) {}
	sendMessage(tab) {
		this.messageEvent.emit(tab);
	}

	lanzaEncuesta() {
		this.router.navigate([routes.config.pqni.route]);
	}
	/**
	 * If you want Define a new "Grifo" update serviceGrifo.enum.ts and check the content.json and subscriptions.json in the mocks
	 * The condition for the ngIf="(isXXXXXActive && ((!grifosActivados.XXXXX) || (grifosActivados.XXXXX && userInGrifo.XXXXX)))"
	 */
	ngOnInit() {
		this.showLoading = true;
		this.getBackgroundAssessorRateus();
		this.checkChatSegment();
		this.getActiveTags();
		this.getOrder();
		this.getGrifoTimeData();
		this.getActiveGrifos();
		this.getGrifosByUser();
		this.taggingVariabels = { ...tagging.asessorDigitalScreen };
		this.taggingHelper.view(this.taggingVariabels.view);
	}

	trackAction(option) {
		const taggingVariabels2 = { ...tagging.asessorDigitalScreen };
		this.taggingHelper.track(option.data.event_name, option.data, taggingVariabels2.view);
	}

	/**
	 * Recover the list of "Grifos" in serviceGrifo.enum.ts and check if they are actives in the content (WCS/MOCK)
	 * @Store this.grifosActivados
	 */
	getActiveGrifos() {
		this.grifosActivados = new Object();
		this.translate.get('v10').subscribe((v10) => {
			const grifosActivos = v10.common.settings.taps.active;
			for (const grifo of Object.keys(ServiceGrifo)) {
				this.grifosActivados[grifo] = false;
				if (grifosActivos) {
					grifosActivos.forEach((grifoActivo) => {
						if (grifoActivo === ServiceGrifo[grifo]) {
							this.grifosActivados[grifo] = true;
						}
					});
				}
			}
		});
	}

	getGrifoTimeData() {
		if (this.isGrifoTimeActive) {
			return this.GrifoTimeService.getChatGrifo().subscribe(
				(data) => {
					this.isInTime = data.isactive;
				},
				(error) => {
					this.isXhrOk = false;
				}
			);
		} else {
			this.isInTime = true;
		}
	}

	/**
	 * Recover the Array with "Grifos" by selectedCompanyId and store it in the userInGrifo property
	 * @Store this.userInGrifo
	 */
	getGrifosByUser() {
		this.userInGrifo = new Object();
		this.subscription.GetGrifoService(this.companyService.selectedCompanyId).subscribe(
			(data) => {
				if (data.length > 0) {
					for (const grifo of Object.keys(ServiceGrifo)) {
						this.userInGrifo[grifo] = false;
					}
					data.forEach((element) => {
						this.userInGrifo[element.split('_')[1]] = true;
					});
				}
			},
			(error) => {
				this.isXhrOk = false;
			}
		);
	}
	getActiveTags() {
		this.translate.get('v10').subscribe((v10) => {
			if (v10.common.settings.assessor.web.enable_assessor_chat === 'false') {
				this.isChatActive = false;
			}
			if (v10.common.settings.assessor.web.enable_assessor_email === 'false') {
				this.isEmailActive = false;
			}
			if (v10.common.settings.assessor.web.enable_assessor_tap_schedule === 'false') {
				this.isGrifoTimeActive = false;
			}
			if (v10.common.settings.assessor.web.enable_assessor_micro === 'false') {
				this.isPhoneActive = false;
			}
			if (v10.common.settings.assessor.web.enable_assessor_whatsapp === 'false') {
				this.isWhatsappActive = false;
			}
			if (v10.common.settings.assessor.web.enable_assessor_rateus === 'false') {
				this.isAssessorRateusActive = false;
			}
			if (v10.common.settings.assessor.web.enable_manager_document === 'false') {
				this.isDocumentumActive = false;
			}
			if (v10.common.settings.assessor.web.enable_assessor_form === 'false') {
				this.isFormActive = false;
			}
			if (v10.common.settings.assessor.web.enable_assessor_tlf === 'false') {
				this.isTlfActive = false;
			}
		});
	}

	getBackgroundAssessorRateus() {
		this.translate.get('v10').subscribe((v10) => {
			this.styleAssessorRateus = {
				background: 'url("' + this.appService.getImgFullPath(v10.dashboard.images.rateus_bg) + '")',
			};
		});
	}

	checkChatSegment() {
		this.CustomerAccount.GetCustomerAccounts(this.companyService.selectedCompanyId).subscribe(
			(response) => {
				const res: any = response;
				this.chatSegment = res.chatSegment;
				this.translate.get('v10').subscribe((v10) => {
					const activeSegment = v10.common.settings.chat.active;
					if (activeSegment !== undefined) {
						if (activeSegment.includes(this.chatSegment)) {
							this.segmentActiveChat = true;
						} else {
							this.segmentActiveChat = false;
						}
					} else {
						this.isXhrOk = false;
					}
				});
				this.showLoading = false;
			},
			(error) => {
				this.isXhrOk = false;
				this.showLoading = false;
			}
		);
	}

	getOrder(): void {
		this.asessorOrder = new AsessorOrder();
		this.translate.get('v10').subscribe((v10) => {
			this.elementsOrderWCS = v10.dashboard.overlay_assessor.itemOrder;
			this.elementsOrderWCS.forEach((element) => {
				this.asessorOrder[element] = this.elementsOrderWCS.indexOf(element);
			});
		});
	}
}
