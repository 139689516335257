import { Component } from '@angular/core';
import { LandingComponent } from '../../../products-and-services/landing/landing.component';
import { Product } from '../../models/product.model';

@Component({
	selector: 'sp-landing-enjoymore-bundle',
	templateUrl: './landing-enjoymore-bundle.component.html',
	styleUrls: ['./landing-enjoymore-bundle.component.scss'],
})
export class LandingEnjoymoreBundleComponent {
	public parent: LandingComponent;
	public enjoyMoreBundles: Product[];
	public isUnlimited: boolean = false;
}
