<div class="current-comsuption">
	<div class="current-comsuption__selector-type" *ngIf="showSelector">
		<vfac-selector
			[yuAppearance]="this.typeUser === 1 || this.typeUser === 3"
			overlay
			*ngIf="selectorData && selectorStartAtIndex >= 0"
			(cardClickEvent)="selectorCardServiceClicked($event, selectorData)"
			[selectorData]="selectorData"
			[listLoaded]="true"
			[startAtIndex]="selectorStartAtIndex"
		>
		</vfac-selector>
	</div>

	<div *ngIf="loadComsuption; else skeletonComsuption">
		<div *ngIf="!errorComsuption; else errorComsuptionTemplate">
			<div *ngIf="showCycle">
				<label class="current-comsuption__label-cycle mva10-u-heading--3">
					{{ labelMonth }}&nbsp;{{ labelCycle }}
				</label>
			</div>
			<label *ngIf="listComsuption.length > 0" class="current-comsuption__subtitle">
				{{ 'v10.billing.without_comsuption.labelInfo' | translate }}
			</label>
			<mva10-c-card *ngIf="roamersBanner" [gradient]="'red'">
				<h5 class="bold-font mva10-u--mb3x">{{ roamersBanner.title }}</h5>
				<p class="mva10-u--footnote mva10-u--mb3x" [innerHTML]="roamersBanner.text"></p>
				<mva10-c-button
					[type]="'mva10-c-button--tertiary-2 mva10-c-button--block'"
					(click)="goToRoaming()"
					id="sharedCurrentConsumptionRoamersButton"
				>
					{{ 'v10.billing.roamers.button' | translate }}
				</mva10-c-button>
			</mva10-c-card>
			<div *ngIf="!badgeViewBol">
				<div #cardVoice class="current-comsuption__card" *ngIf="checkCardsConsumption(bundleTypeEnum.Voice)">
					<mva10-c-card
						[styleClass]="'current-comsuption__expandable'"
						[responsiveSize]="20"
						id="currentConsuptionCardVoice"
					>
						<div class="current-comsuption__desc_item">
							<span>
								<svg class="icon" id="currentConsuptionCardVoiceIcon">
									<title>Calls</title>
									<use xlink:href="#icon-call-log" />
								</svg>
								<label class="titleCaseCapitalize mva10-u--ml4x" id="currentConsuptionCardLabelIcon">
									{{ labelCalls }}
								</label>
							</span>
							<label
								id="currentConsuptionCardVoiceLabel"
								class="current-comsuption--font-family-rg-bold"
								[ngClass]="{ 'current-comsuption--red': totalComsuptionTypeCalls > 0 }"
							>
								{{ totalComsuptionTypeCalls | currency : 'EUR' : 'symbol' | sliceCurrencyRoundZero }}€</label
							>
						</div>

						<div class="current-comsuption--hidden" id="cardExpandCallsId">
							<hr />
							<ng-container
								id="currentConsuptionCardVoicesectionTypeComsuptionTemplate"
								[ngTemplateOutlet]="sectionTypeComsuptionTemplate"
								[ngTemplateOutletContext]="{
									type: bundleTypeEnum.Voice,
									select: typeComsuptionSelectCalls,
									listTotalComsuptionType: listTotalComsuptionTypeCalls
								}"
							>
							</ng-container>
						</div>

						<hr />
						<div
							id="currentConsuptionCardlabelcardExpandableValueCalls"
							class="current-comsuption__expandable__label"
							(click)="clickExpandCardConsumption(!cardExpandableValueCalls, bundleTypeEnum.Voice)"
						>
							<label id="currentConsuptionCardlabelcardExpandableValueCallsLabel">
								{{ labelcardExpandableValueCalls }}
							</label>
						</div>
					</mva10-c-card>
				</div>

				<div #cardData class="current-comsuption__card" *ngIf="checkCardsConsumption(bundleTypeEnum.Data)">
					<mva10-c-card
						[styleClass]="'current-comsuption__expandable'"
						[responsiveSize]="20"
						id="currentConsuptionCardData"
					>
						<div class="current-comsuption__desc_item">
							<span>
								<svg class="icon" id="currentConsuptionCardDataIcon">
									<title>Wifi</title>
									<use xlink:href="#icon-broadband-or-wifi" />
								</svg>
								<label class="titleCaseCapitalize mva10-u--ml4x" id="currentConsuptionCardDataLabelIcon">
									{{ labelData }}
								</label>
							</span>
							<label
								id="currentConsuptionCardDataLabel"
								class="current-comsuption--font-family-rg-bold"
								[ngClass]="{ 'current-comsuption--red': totalComsuptionTypeInternet > 0 }"
							>
								{{ totalComsuptionTypeInternet | currency : 'EUR' : 'symbol' | sliceCurrencyRoundZero }}€</label
							>
						</div>
						<div class="current-comsuption--hidden" id="cardExpandInternetId">
							<ng-container
								[ngTemplateOutlet]="totalComsuptionTemplate"
								[ngTemplateOutletContext]="{ totalizedConsumption: totalizedInternetSumByType }"
							>
							</ng-container>
							<hr />
							<ng-container
								id="currentConsuptionCardDatasectionTypeComsuptionTemplate"
								[ngTemplateOutlet]="sectionTypeComsuptionTemplate"
								[ngTemplateOutletContext]="{
									type: bundleTypeEnum.Data,
									select: typeComsuptionSelectInternet,
									listTotalComsuptionType: listTotalComsuptionTypeInternet
								}"
							>
							</ng-container>
						</div>

						<hr />
						<div
							class="current-comsuption__expandable__label"
							(click)="clickExpandCardConsumption(!cardExpandableValueInternet, bundleTypeEnum.Data)"
							id="currentConsuptionCardDatalabelcardExpandableValueInternet"
						>
							<label id="currentConsuptionCardDatalabelcardExpandableValueInternetLabel">
								{{ labelcardExpandableValueInternet }}
							</label>
						</div>
					</mva10-c-card>
				</div>

				<div #cardSMS class="current-comsuption__card" *ngIf="checkCardsConsumption(bundleTypeEnum.SMS)">
					<mva10-c-card
						[styleClass]="'current-comsuption__expandable'"
						[responsiveSize]="20"
						id="currentConsuptionCardSMS"
					>
						<div class="current-comsuption__desc_item">
							<span>
								<svg class="icon" id="currentConsuptionCardSMSIcon">
									<title>SMS</title>
									<use xlink:href="#icon-sms" />
								</svg>
								<label class="titleCaseCapitalize mva10-u--ml4x" id="currentConsuptionCardSMSLabelIcon">
									{{ labelMessages }}
								</label>
							</span>
							<label
								id="currentConsuptionCardSMSLabel"
								class="current-comsuption--font-family-rg-bold"
								[ngClass]="{ 'current-comsuption--red': totalComsuptionTypeMessages > 0 }"
							>
								{{ totalComsuptionTypeMessages | currency : 'EUR' : 'symbol' | sliceCurrencyRoundZero }}€</label
							>
						</div>
						<div class="current-comsuption--hidden" id="cardExpandMessagesId">
							<hr />
							<ng-container
								id="currentConsuptionCardSMSsectionTypeComsuptionTemplate"
								[ngTemplateOutlet]="sectionTypeComsuptionTemplate"
								[ngTemplateOutletContext]="{
									type: bundleTypeEnum.SMS,
									select: typeComsuptionSelectMessages,
									listTotalComsuptionType: listTotalComsuptionTypeMessages
								}"
							>
							</ng-container>
						</div>

						<hr />
						<div
							id="currentConsuptionCardSMSlabelcardExpandableValueMessages"
							class="current-comsuption__expandable__label"
							(click)="clickExpandCardConsumption(!cardExpandableValueMessages, bundleTypeEnum.SMS)"
						>
							<label id="currentConsuptionCardSMSlabelcardExpandableValueMessagesLabel">
								{{ labelcardExpandableValueMessages }}
							</label>
						</div>
					</mva10-c-card>
				</div>

				<div #cardTV class="current-comsuption__card" *ngIf="checkCardsConsumption(bundleTypeEnum.TV)">
					<mva10-c-card
						[styleClass]="'current-comsuption__expandable'"
						[responsiveSize]="20"
						id="currentConsuptionCardTV"
					>
						<div class="current-comsuption__desc_item">
							<span>
								<svg class="icon" id="currentConsuptionCardTVIcon">
									<title>Tv</title>
									<use xlink:href="#icon-tv" />
								</svg>
								<label class="titleCaseCapitalize mva10-u--ml4x" id="currentConsuptionCardTVIconLabel">
									{{ labelTv }}
								</label>
							</span>
							<label
								id="currentConsuptionCardTVLabel"
								class="current-comsuption--font-family-rg-bold"
								[ngClass]="{ 'current-comsuption--red': totalComsuptionTypeTv > 0 }"
							>
								{{ totalComsuptionTypeTv | currency : 'EUR' : 'symbol' | sliceCurrencyRoundZero }}€</label
							>
						</div>
						<div class="current-comsuption--hidden" id="cardExpandTvId">
							<hr />
							<div *ngIf="listTotalComsuptionTypeTv.length > 0; else emptyComsuptionType">
								<ng-container
									id="currentConsuptionCardTVsectionListComsuptionTemplate"
									[ngTemplateOutlet]="sectionListComsuptionTemplate"
									[ngTemplateOutletContext]="{ type: bundleTypeEnum.TV }"
								>
								</ng-container>
							</div>
							<ng-template #emptyComsuptionType>
								<ng-container
									id="currentConsuptionCardTVemptyComsuptionTypeTemplate"
									[ngTemplateOutlet]="emptyComsuptionTypeTemplate"
									[ngTemplateOutletContext]="{ type: bundleTypeEnum.TV }"
								>
								</ng-container>
							</ng-template>
						</div>

						<hr />
						<div
							id="currentConsuptionCardTVclickExpandCardConsumption"
							class="current-comsuption__expandable__label"
							(click)="clickExpandCardConsumption(!cardExpandableValueTv, bundleTypeEnum.TV)"
						>
							<label id="currentConsuptionCardTVclickExpandCardConsumptionLabel">
								{{ labelcardExpandableValueTv }}
							</label>
						</div>
					</mva10-c-card>
				</div>
			</div>
			<div *ngIf="badgeViewBol" class="badgeView" id="badgeView">
				<ng-container
					id="currentConsuptionCardContainerBadgeView"
					[ngTemplateOutlet]="sectionTypeComsuptionTemplateNewBadgeView"
					[ngTemplateOutletContext]="{
						type: typeTabsComsuption.todo,
						select: bundleTypeEnum.Voice,
						listTotalComsuptionType: listTotalComsuptionTypeCalls
					}"
				>
				</ng-container>
				<ng-template
					id="currentConsuptionCardContainerBadgeTemplate"
					#sectionTypeComsuptionTemplateNewBadgeView
					let-type="type"
					let-select="select"
					let-listTotalComsuptionType="listTotalComsuptionType"
				>
					<div class="current-comsuption--display-flex">
						<span
							*ngIf="checkCardsConsumption(bundleTypeEnum.Data)"
							class="current-comsuption__badge"
							[ngClass]="
								selectedProduct === bundleTypeEnum.Data
									? 'current-comsuption__badge--blue'
									: 'current-comsuption__badge--grey'
							"
							(click)="changeSelectProduct(bundleTypeEnum.Data, data)"
							id="currentConsuptionCardContainerBadgeViewData"
						>
							<img
								class="budget-icon"
								src="{{
									selectedProduct === bundleTypeEnum.Data
										? ('v10.billing.updated_consumption.consumptionDetail.filters.data_icon_selected' | imgFullPath)
										: ('v10.billing.updated_consumption.consumptionDetail.filters.data_icon_notSelected' | imgFullPath)
								}}"
								id="currentConsuptionCardContainerBadgeViewDataImg"
								alt="{{ 'v10.billing.updated_consumption.consumptionDetail.filters.data_label' | translate }}"
							/>
							<label class="consuptionLabel" id="currentConsuptionCardContainerBadgeViewDataLabel">
								{{ 'v10.billing.updated_consumption.consumptionDetail.filters.data_label' | translate }}
							</label>
						</span>
						<span
							*ngIf="checkCardsConsumption(bundleTypeEnum.Voice)"
							class="current-comsuption__badge"
							[ngClass]="
								selectedProduct === bundleTypeEnum.Voice
									? 'current-comsuption__badge--blue'
									: 'current-comsuption__badge--grey'
							"
							(click)="changeSelectProduct(bundleTypeEnum.Voice, mintutes)"
							id="currentConsuptionCardContainerBadgeViewVoice"
						>
							<img
								class="budget-icon"
								src="{{
									selectedProduct === bundleTypeEnum.Voice
										? ('v10.billing.updated_consumption.consumptionDetail.filters.voice_icon_selected' | imgFullPath)
										: ('v10.billing.updated_consumption.consumptionDetail.filters.voice_icon_notSelected' | imgFullPath)
								}}"
								id="currentConsuptionCardContainerBadgeViewVoiceIcon"
								alt="{{ 'v10.billing.updated_consumption.consumptionDetail.filters.voice_label' | translate }}"
							/>
							<label class="consuptionLabel" id="currentConsuptionCardContainerBadgeViewVoiceLabel">
								{{ 'v10.billing.updated_consumption.consumptionDetail.filters.voice_label' | translate }}
							</label>
						</span>
						<span
							*ngIf="checkCardsConsumption(bundleTypeEnum.SMS)"
							class="current-comsuption__badge"
							[ngClass]="
								selectedProduct === bundleTypeEnum.SMS
									? 'current-comsuption__badge--blue'
									: 'current-comsuption__badge--grey'
							"
							(click)="changeSelectProduct(bundleTypeEnum.SMS, sms)"
							id="currentConsuptionCardContainerBadgeViewSMS"
						>
							<img
								class="budget-icon"
								src="{{
									selectedProduct === bundleTypeEnum.SMS
										? ('v10.billing.updated_consumption.consumptionDetail.filters.SMS_icon_selected' | imgFullPath)
										: ('v10.billing.updated_consumption.consumptionDetail.filters.SMS_icon_notSelected' | imgFullPath)
								}}"
								id="currentConsuptionCardContainerBadgeViewSMSImg"
								alt="{{ 'v10.billing.updated_consumption.consumptionDetail.filters.SMS_label' | translate }}"
							/>
							<label class="consuptionLabel" id="currentConsuptionCardContainerBadgeViewSMSLabel">
								{{ 'v10.billing.updated_consumption.consumptionDetail.filters.SMS_label' | translate }}
							</label>
						</span>
					</div>
					<div
						class="current-comsuption--badgeView"
						[ngClass]="
							selectedProduct === bundleTypeEnum.Data ? 'current-comsuption--visible' : 'current-comsuption--hidden'
						"
						id="cardExpandInternetIdNew"
					>
						<ng-container
							id="currentConsuptionCardContainerBadgeViewDataContainer"
							[ngTemplateOutlet]="sectionTypeComsuptionTemplateNew"
							[ngTemplateOutletContext]="{
								type: bundleTypeEnum.Data,
								select: typeComsuptionSelectInternet,
								listTotalComsuptionType: listTotalComsuptionTypeInternet
							}"
						>
						</ng-container>
					</div>
					<div
						class="current-comsuption--badgeView"
						[ngClass]="
							selectedProduct === bundleTypeEnum.Voice ? 'current-comsuption--visible' : 'current-comsuption--hidden'
						"
						id="cardExpandCallsIdNew"
					>
						<ng-container
							id="currentConsuptionCardContainerBadgeViewVoiceContainer"
							[ngTemplateOutlet]="sectionTypeComsuptionTemplateNew"
							[ngTemplateOutletContext]="{
								type: bundleTypeEnum.Voice,
								select: typeComsuptionSelectCalls,
								listTotalComsuptionType: listTotalComsuptionTypeCalls
							}"
						>
						</ng-container>
					</div>
					<div
						class="current-comsuption--badgeView"
						[ngClass]="
							selectedProduct === bundleTypeEnum.SMS ? 'current-comsuption--visible' : 'current-comsuption--hidden'
						"
						id="cardExpandMessagesIdNew"
					>
						<ng-container
							id="currentConsuptionCardContainerBadgeViewSMSContainer"
							[ngTemplateOutlet]="sectionTypeComsuptionTemplateNew"
							[ngTemplateOutletContext]="{
								type: bundleTypeEnum.SMS,
								select: typeComsuptionSelectMessages,
								listTotalComsuptionType: listTotalComsuptionTypeMessages
							}"
						>
						</ng-container>
					</div>
					<div
						id="cardExpandTvId"
						class="current-comsuption--badgeView"
						[ngClass]="
							checkCardsConsumption(bundleTypeEnum.TV) ? 'current-comsuption--visible' : 'current-comsuption--hidden'
						"
					>
						<div *ngIf="listTotalComsuptionTypeTv.length > 0; else emptyComsuptionType">
							<ng-container
								id="currentConsuptionCardTVsectionListComsuptionTemplateN"
								[ngTemplateOutlet]="sectionListComsuptionTemplate"
								[ngTemplateOutletContext]="{ type: bundleTypeEnum.TV }"
							>
							</ng-container>
						</div>
						<ng-template #emptyComsuptionType>
							<ng-container
								id="currentConsuptionCardTVemptyComsuptionTypeTemplate"
								[ngTemplateOutlet]="emptyComsuptionTypeTemplate"
								[ngTemplateOutletContext]="{ type: bundleTypeEnum.TV }"
							>
							</ng-container>
						</ng-template>
					</div>
				</ng-template>
			</div>
		</div>
		<ng-template #errorComsuptionTemplate>
			<mva10-c-card [responsiveSize]="20">
				<div class="current-comsuption__empty">
					<ng-lottie [options]="lottieConfig" [width]="100" [height]="100" (animationCreated)="handleAnimation($event)">
					</ng-lottie>
					<span>{{ 'v10.billing.comsuption.titleError' | translate }} </span>
					<span> {{ 'v10.billing.comsuption.descError' | translate }} </span>
				</div>
			</mva10-c-card>
		</ng-template>
	</div>
	<ng-template #skeletonComsuption>
		<ng-container [ngTemplateOutlet]="billSkeleton"> </ng-container>
	</ng-template>
</div>

<ng-template #totalComsuptionTemplate let-totalizedConsumption="totalizedConsumption">
	<div *ngIf="totalizedConsumption && totalizedConsumption.length > 0">
		<hr />
		<div
			class="current-comsuption--space-between current-comsuption--margin-bottom"
			*ngFor="let listItem of totalizedConsumption"
		>
			<span> {{ listItem.description }} </span>
			<span> {{ listItem.formattedTotal }} </span>
		</div>
	</div>
</ng-template>

<ng-template
	#sectionTypeComsuptionTemplate
	let-type="type"
	let-select="select"
	let-listTotalComsuptionType="listTotalComsuptionType"
>
	<div *ngIf="listTotalComsuptionType.length > 0; else emptyComsuptionType">
		<div class="current-comsuption--display-flex">
			<span
				class="current-comsuption__badge"
				[ngClass]="
					select === typeTabsComsuption.todo ? 'current-comsuption__badge--blue' : 'current-comsuption__badge--grey'
				"
				(click)="changeSelectComsuption(typeTabsComsuption.todo, type)"
			>
				<label> {{ 'v10.billing.comsuption.labelAll' | translate }} </label>
			</span>
			<span
				class="current-comsuption__badge"
				[ngClass]="
					select === typeTabsComsuption.incluido ? 'current-comsuption__badge--blue' : 'current-comsuption__badge--grey'
				"
				(click)="changeSelectComsuption(typeTabsComsuption.incluido, type)"
			>
				<label> {{ 'v10.billing.comsuption.labelInclude' | translate }} </label>
			</span>
			<span
				class="current-comsuption__badge"
				[ngClass]="
					select === typeTabsComsuption.fuera ? 'current-comsuption__badge--blue' : 'current-comsuption__badge--grey'
				"
				(click)="changeSelectComsuption(typeTabsComsuption.fuera, type)"
			>
				<label> {{ 'v10.billing.comsuption.labelExtra' | translate }} </label>
			</span>
		</div>

		<div
			*ngIf="
				selectedListConsumption && selectedListConsumption[type] && selectedListConsumption[type].length > 0;
				else emptyComsuptionSelect
			"
		>
			<ng-container [ngTemplateOutlet]="sectionListComsuptionTemplate" [ngTemplateOutletContext]="{ type: type }">
			</ng-container>
		</div>
		<ng-template #emptyComsuptionSelect>
			<ng-container [ngTemplateOutlet]="emptyComsuptionSelectTemplate" [ngTemplateOutletContext]="{ select: select }">
			</ng-container>
		</ng-template>
	</div>
	<ng-template #emptyComsuptionType>
		<ng-container [ngTemplateOutlet]="emptyComsuptionTypeTemplate" [ngTemplateOutletContext]="{ type: type }">
		</ng-container>
	</ng-template>
</ng-template>
<ng-template
	#sectionTypeComsuptionTemplateNew
	let-type="type"
	let-select="select"
	let-listTotalComsuptionType="listTotalComsuptionType"
>
	<div *ngIf="listTotalComsuptionType.length > 0; else emptyComsuptionType">
		<div class="preconfirmation" id="changeSelectComsuptionToggleContainer" *ngIf="showSelectConsumption">
			<mva10-c-inputs
				[type]="2"
				[inputId]="'changeSelectComsuptionToggle'"
				[textLabel]="'v10.billing.updated_consumption.consumptionDetail.outOfTariffToggle' | translate"
				[iconToggle]="false"
				[checked]="isToogleChecked"
				[inverse]="false"
				[disabled]="false"
				[iconToggle]="isToogleChecked"
				(mva10Change)="changeSelectConsuptionButton($event, type)"
			>
			</mva10-c-inputs>
		</div>
		<p class="consumptionWarning" id="consumptionWarning">
			{{ 'v10.billing.updated_consumption.consumptionDetail.consumptionWarning' | translate }}
		</p>
		<div
			*ngIf="
				selectedListConsumption && selectedListConsumption[type] && selectedListConsumption[type].length > 0;
				else emptyComsuptionSelect
			"
			id="contentSectionListComsuptionTemplate"
		>
			<ng-container [ngTemplateOutlet]="sectionListComsuptionTemplate" [ngTemplateOutletContext]="{ type: type }">
			</ng-container>
		</div>
		<ng-template #emptyComsuptionSelect>
			<ng-container [ngTemplateOutlet]="emptyComsuptionSelectTemplate" [ngTemplateOutletContext]="{ select: select }">
			</ng-container>
		</ng-template>
	</div>
	<ng-template #emptyComsuptionType>
		<ng-container [ngTemplateOutlet]="emptyComsuptionTypeTemplate" [ngTemplateOutletContext]="{ type: type }">
		</ng-container>
	</ng-template>
</ng-template>
<ng-template #sectionListComsuptionTemplate let-type="type">
	<div class="current-comsuption__section" *ngFor="let comsuption of selectedListConsumption[type]; let i = index">
		<div
			class="current-comsuption__item current-comsuption__item--separator-day"
			*ngIf="separatorDay(comsuption, selectedListConsumption[type][i - 1])"
		>
			<label> {{ separatorDayText(comsuption.timePeriod.startDate) }} </label>
		</div>
		<div class="current-comsuption__item">
			<label> {{ getDestination(comsuption, type) }} </label>
			<label [ngClass]="{ 'current-comsuption--red': comsuption.amount > 0 }">
				{{ comsuption.amount | currency : 'EUR' : 'symbol' | sliceCurrencyRoundZero }}€</label
			>
		</div>
		<div class="current-comsuption__item-time">
			<label> {{ getDateComsuption(comsuption.timePeriod.startDate) }} </label>
			<ng-container *ngIf="type === bundleTypeEnum.Data; else nonDataTemplate">
				<label *ngIf="!isNaN(comsuption.quantity)"> {{ getDataConsumption(comsuption) }} </label>
			</ng-container>
			<ng-template #nonDataTemplate>
				<label *ngIf="comsuption.timePeriod.duration"> {{ getDuration(comsuption.timePeriod.duration) }} </label>
			</ng-template>
		</div>
	</div>
</ng-template>

<ng-template #emptyComsuptionSelectTemplate let-select="select">
	<div class="current-comsuption__empty">
		<img src="{{ appTheme.getImgFullPath('v10.billing.common.cards.empty.image' | translate) }}" alt="card" />
		<span>{{ getLabelsComsuptionEmpty(select)[0] }} </span>
		<span> {{ getLabelsComsuptionEmpty(select)[1] }} </span>
	</div>
</ng-template>

<ng-template #emptyComsuptionTypeTemplate let-type="type">
	<div class="current-comsuption__empty" *ngIf="!badgeViewBol">
		<img id="currentConsumptionEmpyImg" src="{{ 'v10.billing.common.cards.empty.image' | imgFullPath }}" alt="card" />
		<span id="currentConsumptionEmpyTxt">{{ type }} {{ 'v10.billing.comsuption.titleEmpty' | translate }} </span>
		<span id="currentConsumptionEmpyDescripton">{{ getLabelEmptyComsuptionType(type) }} </span>
	</div>
	<div class="current-comsuption__empty" *ngIf="badgeViewBol">
		<img
			class="emptyBadgeView"
			id="currentConsumptionEmpyBadgeImgVoice"
			src="{{
				serviceSelectComsuption.type === bundleTypeEnum.Landline
					? ('v10.billing.updated_consumption.emptylandline.image' | imgFullPath)
					: selectedProduct === bundleTypeEnum.Data
					? ('v10.billing.updated_consumption.emptydata.image' | imgFullPath)
					: selectedProduct === bundleTypeEnum.Voice
					? ('v10.billing.updated_consumption.emptyvoice.image' | imgFullPath)
					: selectedProduct === bundleTypeEnum.SMS
					? ('v10.billing.updated_consumption.emptysms.image' | imgFullPath)
					: ('v10.billing.updated_consumption.emptytelevision.image' | imgFullPath)
			}}"
			alt="card"
		/>
		<span id="currentConsumptionEmpyBadgeTxtVoice">
			{{
				selectedProduct === bundleTypeEnum.Data
					? ('v10.billing.updated_consumption.emptydata.text' | translate)
					: selectedProduct === bundleTypeEnum.Voice
					? ('v10.billing.updated_consumption.emptyvoice.text' | translate)
					: selectedProduct === bundleTypeEnum.SMS
					? ('v10.billing.updated_consumption.emptysms.text' | translate)
					: ('v10.billing.updated_consumption.emptytelevision.text' | translate)
			}}
		</span>

		<span id="currentConsumptionEmpyBadgeDescription"
			>{{ 'v10.billing.updated_consumption.consumptionDetail.consumptionWarning' | translate }}
		</span>
	</div>
</ng-template>

<ng-template #billSkeleton>
	<div class="skeleton-current-comsuption">
		<div class="vfes-box-shadow skeleton-wrapper-section skeleton-current-comsuption__card">
			<div class="skeleton"></div>
			<div class="skeleton"></div>
			<div class="skeleton"></div>
		</div>
	</div>
</ng-template>

<div class="redirect-to-billing">
	<div class="redirect-to-billing__container">
		<mva10-c-icon [iconId]="'icon-charge-to-bill'" [size]="3" [title]="'bill'"> </mva10-c-icon>
		<p id="sharedCurrentConsumptionRedirectToMisFacturasTitle" class="redirect-to-billing__desc">
			{{ 'v10.billing.comsuption.invoicesCard.title' | translate }}
		</p>
	</div>
	<button
		id="sharedCurrentConsumptionRedirectToMisFacturasButton"
		(click)="navigateToMisFactorasPage()"
		class="redirect-to-billing__button"
	>
		{{ 'v10.billing.comsuption.invoicesCard.button.text' | translate }}
	</button>
</div>
