import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { map } from 'rxjs/operators';
import { AppService } from '../../app.service';
import { TopupPrepaidErrorOVerlayDataService } from '../../top-up/top-up-prepaid-error-overlay-data.service';
import { PaymentCardModel, PaymentCardPatchModel } from '../models/payment-card.model';
import { PaymentCardsDataService } from './payment-card-data.service';
import { SpMva10OverlayService } from './sp-mva10-overlay.service';

@Injectable()
export class PaymentCardsService {
	cardsList = new Array<PaymentCardModel>();
	showPopupMsg: boolean = false;
	msgDesc: string;
	constructor(
		private paymentCardDataService: PaymentCardsDataService,
		public topupErrorService: TopupPrepaidErrorOVerlayDataService,
		public trayService: SpMva10OverlayService,
		private translate: TranslateService,
		private appservice: AppService
	) {}

	getPaymentCardsByDocumentId(documentId: string) {
		return this.paymentCardDataService.getPaymentCardsByDocumentId(documentId).pipe(
			map((res: any) => {
				this.cardsList = new Array<PaymentCardModel>();
				if (res.items && res.items.length > 0) {
					res.items.forEach((card) => {
						this.cardsList.push(this.mapCardData(card));
					});
				}
			})
		);
	}

	mapCardData(cardData): PaymentCardModel {
		const card = new PaymentCardModel();

		card.uuid = cardData.uuid || '';
		card.token = cardData.token || '';
		card.savedDate = cardData.savedDate || '';
		card.principle = cardData.principle || false;
		card.lastUsedDate = cardData.lastUsedDate || '';
		card.journey = cardData.journey || '';
		card.expiryDate = cardData.expiryDate || '';
		card.documentType = cardData.documentType || '';
		card.documentID = cardData.documentID || '';
		card.cardMask = cardData.cardMask || '';
		card.pagoRecurrente = cardData.pagoRecurrente || '';
		card.txID_COF = cardData.txId_COF || '';

		return card;
	}

	savePaymentCard(documentId: string, body: PaymentCardPatchModel) {
		return this.paymentCardDataService.patchPaymentCard(documentId, body).pipe(map((res) => res));
	}

	deletePaymentCard(documentId: string, uuid: string) {
		return this.paymentCardDataService.deletePaymentCard(documentId, uuid);
	}

	setDeleteTopupUiData(lastFourNumbers: string) {
		this.topupErrorService.sendTaggingClickOnXInteractionFlag = false;
		this.topupErrorService.resetConfigurations();
		this.trayService.showForwardTransition = true;
		this.trayService.showBackwardTransition = false;
		this.topupErrorService.showXButton = true;
		this.topupErrorService.showLottie = false;
		this.topupErrorService.showTwoCtas = true;
		this.translate.get('payment.itemsList.wallet').subscribe((data) => {
			this.topupErrorService.trayTitle = data.body;
		});
		this.translate.get('payment.messagesList.walletMsg').subscribe((data) => {
			/** Hide message description in case of postpaid payment */
			this.msgDesc = '';
			this.topupErrorService.setErrorDescription(this.msgDesc);
			this.topupErrorService.confirmationButtonText = data.button1.text;
			this.topupErrorService.cancelationButttonText = data.button2.text;
			this.topupErrorService.iconUrl = this.appservice.getImgFullPath(data.icon.url);
		});
	}
}
