import { ThirdPartyId } from '../enums/third-party-id.enum';

export class ThirdPartyLandingModel {
	name: ThirdPartyId;
	isChargeToBill: boolean;
	parentId?: string;
	title?: string;
	shortDescription?: string;
	longDescription?: string;
	img: string;
	img2: string;
}
export class ServicesContainerModel {
	title?: string;
	shortDescription?: string;
	longDescription?: string;
	childs?: ThirdPartyLandingModel[];
	img: string;
	img2: string;
}
