import { RestrictionsStatus } from '../enums/restrictions-status.enum';
import { CallRestrictions } from './call-restrictions.model';
import { LineRestrictionsVisibilityStatusModel } from './line-restrictions-visibilty-status.model';

export class LineRestrictions {
	lineStatus: RestrictionsStatus;
	callRestrictions: CallRestrictions;
	lineRestrictionsVisibilityStatusCards: LineRestrictionsVisibilityStatusModel;
}

export class LineRestrictionsResponse {
	lineStatus?: RestrictionsStatus;
	callSettings?: CallRestrictions;
	lineServicesCard?: LineRestrictionsVisibilityStatusModel;
}
