import { Injectable } from '@angular/core';
import { SharedServicesPegaReplacerReplaceOptions } from '../entity/shared-services-pega-replacer.entity';
import { sharedServicesPegaReplacerReplaceFromSource } from '../util';

@Injectable({ providedIn: 'root' })
export class SharedServicesPegaReplacerService {
	public replace(options: SharedServicesPegaReplacerReplaceOptions): string {
		return sharedServicesPegaReplacerReplaceFromSource(options);
	}
}
