import { HttpClient } from '@angular/common/http';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { WORDING_FILES } from '../constants/defines';
import * as Routes from '../constants/routes-config';

export class CustomTranslateLoader extends TranslateHttpLoader {
	private customHttp: HttpClient;
	constructor(http: HttpClient, prefix?: string, suffix?: string) {
		super(http, prefix, suffix);
		this.customHttp = http;
	}

	getTranslation(lang?: string): Observable<Object> {
		if (lang === WORDING_FILES.defaultWording) {
			return this.customHttp.get(this.prefix + 'sp' + this.suffix).pipe(map((res) => this.replaceCharacters(res)));
		} else if (lang === WORDING_FILES.wcs) {
			return this.customHttp.get(Routes.API_URLS.CMS.CMS_GET).pipe(map((res) => this.replaceCharacters(res)));
		}
	}

	private replaceCharacters(jsonResponse: Object = {}): Object {
		return JSON.parse(JSON.stringify(jsonResponse).replace(/(?:&nbsp;|\s)+/gi, ' '));
	}
}
