import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { Observable, of } from 'rxjs';
import { environment } from '../../../environments/environment';
import { API_URLS } from '../constants/routes-config';
import { constants } from '../models/constants';
import { EncryptRequestModel } from '../models/encrypt-request.model';

@Injectable({
	providedIn: 'root',
})
export class EncryptService {
	constructor(private http: HttpClient, private translate: TranslateService) {}

	encrypt(dataToEncrypt: string[]): Observable<string[]> {
		if (!dataToEncrypt || dataToEncrypt.length === 0) {
			return of([]);
		}
		const headers: HttpHeaders = new HttpHeaders({
			'Authorization': environment.CLIENT_ID,
			'Content-Type': 'application/json',
		});
		const body: EncryptRequestModel = {
			msisdn: dataToEncrypt,
			action: 'encrypt',
			country_code: '34',
			third_party: 'analytics',
		};
		const url: string = constants.trueLiteral.includes(this.translate.instant('v10.app.useOldEncryption'))
			? API_URLS.cypher.aes
			: environment.vfencryptUrl;
		return this.http.post<string[]>(url, body, { headers });
	}
}
