import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { TranslateModule } from '@ngx-translate/core';
import { SpRadioButtonAdvancedComponent } from './sp-radio-button-advanced.component';

@NgModule({
	declarations: [SpRadioButtonAdvancedComponent],
	exports: [SpRadioButtonAdvancedComponent],
	imports: [CommonModule, FormsModule, ReactiveFormsModule, TranslateModule],
})
export class SpRadioButtonAdvancedModule {}
