import { Component, EventEmitter, Input, Output } from '@angular/core';
import { OfferNoAvaibleModel } from '../../models/offer-no-available.model';

@Component({
	selector: 'sp-backdrop-offer-no-available',
	templateUrl: './backdrop-offer-no-available.component.html',
	styleUrls: ['./backdrop-offer-no-available.component.scss'],
})
export class BackdropOfferNoAvailableComponent {
	@Input() showBackdrop: boolean;
	@Input() data: OfferNoAvaibleModel;
	@Output() clickCTA: EventEmitter<boolean> = new EventEmitter<boolean>();

	public close(_event: boolean): void {
		this.showBackdrop = false;
		this.clickCTA.emit(_event);
	}
}
