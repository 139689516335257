export enum OrderFlow {
	FLUJO_1 = 'INSTALACION_CABLE_CON_AUTO_INSTALACION',
	FLUJO_2 = 'INSTALACION_CABLE_GENERICO_CON_TECNICO_SIN_PORTABILIDAD',
	FLUJO_3 = 'INSTALACION_CABLE_GENERICO_CON_TECNICO_CON_PORTABILIDAD',
	FLUJO_4 = 'CAMBIO_CM_BANDA_ANCHA_AUTO_INSTALACION',
	FLUJO_5 = 'PORTABILIDADES_FIJO',
	FLUJO_6 = 'RECOGIDAS_DE_CM_TV',
	FLUJO_7 = 'MIGRACIONES_TV_CON_AUTO_INSTALACION',
	FLUJO_8 = 'MIGRACIONES_TV_CON_TECNICO',
	FLUJO_9 = 'MODIFICACION_SERVICIOS',
	FLUJO_12 = 'INSTALACION_PAR_ACTIVO_CON_TECNICO',
	FLUJO_15 = 'INSTALACION_PAR_VACANTE_CON_TECNICO',
	FLUJO_21 = 'FLUJO_GENERICO',
	FLUJO_28 = 'INSTALACION_PAR_ACTIVO_CON_AUTO_INSTALACION_NEBA',
	FLUJO_33 = 'CAMBIOS_EQUIPO_INTERNET_HFC',
	FLUJO_101 = 'ALTA_MOVIL_SIN_PORTABILIDAD',
	FLUJO_102 = 'ALTA_MOVIL_CON_PORTABILIDAD',
	FLUJO_103 = 'CANJE_TERMINAL',
	FLUJO_104 = 'INSTALACION_PAR_VACANTE_CON_AUTO_INSTALACION',
	FLUJO_105 = 'INSTALACION_PAR_ACTIVO_CON_AUTO_INSTALACION',
	FLUJO_121 = 'PORTABILIDAD_FIJO_AUTO_XDSL_NEBA',
	FLUJO_126 = 'ALTA_MOVIL_BIT',
	FLUJO_127 = 'ALTA_MOVIL_BIT_PORTABILIDAD',
	FLUJO_128 = 'ALTA_FIJO_BIT',
	FLUJO_129 = 'ALTA_FIJO_BIT_PORTABILIDAD',
	FLUJO_145 = 'INSTALACION_PAR_VACANTE_CON_TECNICO_NEBA',
	FLUJO_165 = 'ONE_CONECTA_ALTA_MOVIL_SIN_PORTABILIDAD',
	FLUJO_166 = 'ONE_CONECTA_ALTA_MOVIL_CON_PORTABILIDAD',
	FLUJO_167 = 'ONE_CONECTA_ALTA_MOVIL_CON_PORTABILIDAD_FIJO',
	FLUJO_185 = 'INSTALACION_TV_CON_AUTO_INSTALACION',
	FLUJO_186 = 'INSTALACION_TV_CON_TECNICO',
	FLUJO_205 = 'CAMBIO_AVERIA_CM_BANDA_ANCHA_AUTO_INSTALACION',
	FLUJO_206 = 'CAMBIO_AVERIA_TV_AUTO_INSTALACION',
	FLUJO_225 = 'ALTA_MOVIL_CON_PORTABILIDAD_SECUENCIAL',
	FLUJO_245 = 'ENVIO_TERMINAL',
	FLUJO_500 = 'INSTALACION_PAR_ACTIVO_CON_AUTO_INSTALACION_NEBA',
	FLUJO_501 = 'INSTALACION_PAR_ACTIVO_CON_AUTO_INSTALACION_SIN_PORTABILIDAD',
	FLUJO_502 = 'INSTALACION_PAR_ACTIVO_CON_TECNICO_SIN_PORTABILIDAD',
}
