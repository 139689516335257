import { Component, EventEmitter, Input, Output } from '@angular/core';
import { FailureModel } from '../../models/failure.Model';

@Component({
	selector: 'sp-failure',
	templateUrl: './failure.component.html',
	styleUrls: ['./failure.component.scss'],
})
export class FailureComponent {
	@Input() showFailureComponent: boolean;
	@Input() failureObject: FailureModel;
	@Input() id: string = '';
	@Output() linkAction: EventEmitter<any> = new EventEmitter<any>();
	@Input() responsiveFailure: boolean = false;

	/** method  to excute link click */
	actionLink(): void {
		this.linkAction.emit();
	}
}
