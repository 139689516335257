import { Component, OnDestroy, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { tagging } from '../../config/tagging-config';
import { AppService } from '../app.service';
import { CommonService } from '../core/services/common.service';
import { TaggingHelperService } from '../core/services/tagging.helper.service';
import { AppThemes, legalKeys } from '../shared/constants/defines';
import { IDynamicTray } from '../shared/models/dynamic-tray.model';
import { GenereicNavigatorModel } from '../shared/models/generic-navigator.mode';
import { DynamicTrayService } from '../shared/services/dynamic-tray.service';
import { OpenSourceLibrariesComponent } from './open-source-libraries/open-source-libraries.component';
import { PrivacyPolicyComponent } from './privacy-policy/privacy-policy.component';
import { ShopPolicyComponent } from './shop-policy/shop-policy.component';
import { TermsAndConditionComponent } from './terms-and-condition/terms-and-condition.component';

@Component({
	selector: 'sp-legal',
	templateUrl: './legal.component.html',
	styleUrls: ['./legal.component.scss'],
})
export class LegalComponent implements OnInit, OnDestroy {
	legalList: GenereicNavigatorModel[];
	trayData: IDynamicTray;

	constructor(
		public commonService: CommonService,
		private translate: TranslateService,
		private appService: AppService,
		private tagging: TaggingHelperService,
		public dynamicTrayService: DynamicTrayService
	) {}

	ngOnInit(): void {
		this.appService.showFullAppLoader = false;
		this.appService.theme = AppThemes.Theme2;
		this.commonService.showMenuButton = true;
		this.translate.get('v10.legal.name').subscribe((data) => {
			this.appService.appHeaderTitle = data;
		});
		this.legalList = [];
		this.translate.get('v10.legal.itemsList.privacyPolicy').subscribe((list) => {
			this.legalList.push({
				imgURL: this.appService.getImgFullPath(list.icon),
				title: list.name,
				extraKeys: {
					key: legalKeys.privacypolicy,
					component: PrivacyPolicyComponent,
				},
			});
		});
		this.translate.get('v10.legal.itemsList.termsConditions').subscribe((list) => {
			this.legalList.push({
				imgURL: this.appService.getImgFullPath(list.icon),
				title: list.name,
				extraKeys: {
					key: legalKeys.termsconditions,
					component: TermsAndConditionComponent,
				},
			});
		});

		this.translate.get('v10.legal.itemsList.libraries').subscribe((list) => {
			this.legalList.push({
				imgURL: this.appService.getImgFullPath(list.icon),
				title: list.name,
				extraKeys: {
					key: legalKeys.libraries,
					component: OpenSourceLibrariesComponent,
				},
			});
		});
		this.translate.get('v10.legal.itemsList.shopPolicy').subscribe((list) => {
			this.legalList.push({
				imgURL: this.appService.getImgFullPath(list.icon),
				title: list.name,
				extraKeys: {
					key: legalKeys.shopPolicy,
					component: ShopPolicyComponent,
				},
			});
		});
	}

	openOverlay(component: Component, key: string): void {
		this.tagging.view(tagging[key.toLowerCase()]);
		let librariesTitle: string;
		let privacyPolicyTitle: string;
		let termsConditionsTitle: string;
		let shopPolicyTitle: string;
		this.translate.get('v10.legal').subscribe((data) => {
			librariesTitle = data.libraries.title;
			privacyPolicyTitle = data.privacyPolicy.title;
			termsConditionsTitle = data.termsConditions.title;
			shopPolicyTitle = data.shopPolicy.title;
		});
		switch (component) {
			case OpenSourceLibrariesComponent:
				this.trayData = {
					basicTrayData: {
						isScrollableTray: true,
						isCenterTitle: true,
						title: librariesTitle,
					},
				};
				this.dynamicTrayService.open(this.trayData, component);
				break;
			case TermsAndConditionComponent:
				this.trayData = {
					basicTrayData: {
						isScrollableTray: true,
						isCenterTitle: true,
						title: termsConditionsTitle,
					},
				};
				this.dynamicTrayService.open(this.trayData, component);
				break;
			case PrivacyPolicyComponent:
				this.trayData = {
					basicTrayData: {
						isScrollableTray: true,
						isCenterTitle: true,
						title: privacyPolicyTitle,
					},
				};
				this.dynamicTrayService.open(this.trayData, component);
				break;
			case ShopPolicyComponent:
				this.trayData = {
					basicTrayData: {
						isScrollableTray: true,
						isCenterTitle: true,
						title: shopPolicyTitle,
					},
				};
				this.dynamicTrayService.open(this.trayData, component);
				break;
		}
	}

	ngOnDestroy(): void {
		this.trayData = {};
		this.commonService.overrideBackHandling = false;
	}
}
