import { Injectable } from '@angular/core';
import { TaggingViewModel } from 'src/app/shared/models/tagging.model';
import { tagging } from 'src/config/tagging-config';
import { config } from '../../../config/pages-config';
import { StorageService } from '../../core/services/storage.service';
import { TaggingHelperService } from '../../core/services/tagging.helper.service';
import { undefinedType } from '../../shared/constants/defines';
import { tagEventValues } from '../constants/defines';
import { ClientTypology } from '../enums/clientTopology.enum';
import { Route } from '../models/Route.model';

@Injectable()
export class DeepLinkingService {
	/* after using isDeepLink you need to set it false in ondestory */
	isDeepLink: boolean;
	navigationId: number;
	isError: boolean;
	LinkUrl: string;
	linkParams: {};
	upgradeTvPackageDeepLink: boolean;
	/**sets to true when appUrlsConfiguration is called to get resolved url*/
	deeplinkStartFlag: boolean;

	constructor(private tagginghelperservice: TaggingHelperService, private storageService: StorageService) {}

	getParams(url: string): {} {
		const regex: RegExp = /[?&]([^=#]+)=([^&#]*)/g;
		const params: {} = {};
		let match: RegExpExecArray;
		while ((match = regex.exec(url))) {
			params[match[1]] = match[2];
		}
		return params;
	}

	public setTrackTaggingLogic(offerVFDescriptor: string, offer: string, journey_process: string): void {
		const page: TaggingViewModel = JSON.parse(JSON.stringify(tagging.newLinesPurchase.page));
		const deeplink: string = 'deeplink';
		const deeplinkEntrypoint: string = 'deeplink entrypoint';
		page.page_subcategory_level_3 = deeplink;
		page.page_name =
			page.page_section + ':' + page.page_subcategory_level_1 + ':' + page.page_subcategory_level_2 + ':' + deeplink;
		page.asset_name = '';
		page.navigation_level_1 = page.page_section;
		page.navigation_level_2 = page.page_subcategory_level_1;
		page.navigation_level_3 = page.page_subcategory_level_2;
		page.navigation_level_4 = deeplink;
		page.event_name = deeplinkEntrypoint;
		page.event_category = deeplink;
		page.event_context = deeplinkEntrypoint;
		page['&&events'] = tagEventValues.event70;
		page.entrypoint_section = deeplink;
		page.entrypoint_location = undefinedType;
		page.entrypoint_position = '0';
		page.entrypoint_title = offer || ' ';
		page.entrypoint_type = offerVFDescriptor ? 'pega' : '';
		page.journey_detail = page.journey_detail + ':' + deeplink;
		page.state_flow = '';
		page.journey_name = page.page_subcategory_level_1;
		page.journey_category = this.storageService.userProfile?.customerType
			? this.tagginghelperservice.getUserType(this.storageService.userProfile.customerType, ClientTypology)
			: ClientTypology.notLogin;
		page.journey_type = page.page_section;
		page.journey_detail = page.page_screen + ':' + deeplink;
		page.journey_step = page.page_subcategory_level_1 + ':' + page.page_screen + ':' + deeplink;
		page.journey_process = journey_process;
		const contextData: TaggingViewModel = Object.assign(page);
		this.tagginghelperservice.view(page, contextData);
	}
	public routeDeepLink(url: string): boolean {
		let route: Route = null;
		const conf: any = config;
		this.LinkUrl = url.split('?')[0];
		this.linkParams = this.getParams(url);

		// REFACTOR: use jsonPath to query the path instead of nested looping
		Object.keys(conf).forEach((moduleKey) => {
			if (this.LinkUrl.match('^' + conf[moduleKey].route + '$')) {
				route = conf[moduleKey];
			} else {
				Object.keys(conf[moduleKey]).forEach((stepKey) => {
					if (this.LinkUrl.match('^' + conf[moduleKey][stepKey].route + '$')) {
						route = conf[moduleKey][stepKey];
					} else {
						Object.keys(conf[moduleKey][stepKey]).forEach((stepKeyLevel2) => {
							if (this.LinkUrl.match('^' + conf[moduleKey][stepKey][stepKeyLevel2].route + '$')) {
								route = conf[moduleKey][stepKey][stepKeyLevel2];
							}
						});
					}
				});
			}
		});

		if (route && route.deeplink) {
			this.isDeepLink = true;
			if (
				url === conf.ProductsAndServices.PlanDetails.UpgradePackageSelector.route ||
				url === conf.ProductsAndServices.PlanDetails.Extra.route ||
				url === conf.ProductsAndServices.PlanDetails.Total.route ||
				url === conf.ProductsAndServices.newPackageDetails.route
			) {
				this.upgradeTvPackageDeepLink = true;
			}
			this.isError = false;
		} else {
			this.isDeepLink = false;
		}

		return this.isDeepLink;
	}
}
