import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { CoreModule, GenericModule, OverlayFullScreenModule, OverlayModule } from '@mva10/mva10-angular';
import { TranslateModule } from '@ngx-translate/core';
import { SharedModule } from '../shared/shared.module';
import { AdaraMainComponent } from './adara-main/adara-main.component';
import { AdaraRoutingModule } from './adara-routing.module';

@NgModule({
	declarations: [AdaraMainComponent],
	imports: [
		CommonModule,
		AdaraRoutingModule,
		TranslateModule,
		SharedModule,
		GenericModule,
		CoreModule,
		OverlayFullScreenModule,
		OverlayModule,
	],
	exports: [AdaraMainComponent],
})
export class AdaraModule {}
