import { Component, OnDestroy } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { AppService } from '../../../app.service';
import { Mva10FullModslServiceService } from '../../services/mva10-full-modsl-service.service';

@Component({
	selector: 'sp-mva10-full-modal-error',
	templateUrl: './mva10-full-modal-error.component.html',
	styleUrls: ['./mva10-full-modal-error.component.scss'],
})
export class Mva10FullModalErrorComponent implements OnDestroy {
	constructor(
		public appService: AppService,
		public translate: TranslateService,
		public fullModslServiceService: Mva10FullModslServiceService
	) {}
	close(emitCloseSubject: boolean = true): void {
		if (emitCloseSubject) {
			this.fullModslServiceService.closeOverlaySubject.next();
		}
		if (this.fullModslServiceService.closeAction) {
			this.fullModslServiceService.closeAction();
		} else {
			this.appService.showErrorFullModal = false;
		}

		this.fullModslServiceService.increase_z_index = false;
	}

	/**
	 * to keep backward compatibility which is close overlay when click on button one
	 * the new is if there is a button1Action so it should be invoked instead of close method
	 */
	button1Action() {
		this.fullModslServiceService.errorButton1Subject.next();
		if (this.fullModslServiceService.errorButton1Action) {
			this.fullModslServiceService.errorButton1Action();
		} else {
			this.close(false);
		}
	}
	/**
	 * check if there is a button2Action so it should be invoked
	 */
	button2Action() {
		if (this.fullModslServiceService.errorButton2Action) {
			this.fullModslServiceService.errorButton2Action();
		}
	}
	/**
	 * check if there is a retryBtnAction so it should be invoked
	 */
	retryButtonAction(): void {
		if (this.fullModslServiceService.retryBtnAction) {
			this.fullModslServiceService.retryBtnAction();
		}
	}

	ngOnDestroy(): void {
		this.fullModslServiceService.resetProperties();
	}
}
