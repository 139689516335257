<div
	class="generic-success-error-rows"
	[ngStyle]="{
		'min-height': dynamicTrayService?.genericSuccessErrorTrayData?.minimumVhHeight + 'vh',
		'margin-bottom': dynamicTrayService?.genericSuccessErrorTrayData?.trayMarginbottom
	}"
	[ngClass]="{ 'edit-ui': dynamicTrayService?.genericSuccessErrorTrayData?.editUi }"
>
	<div
		*ngIf="
			!dynamicTrayService?.genericSuccessErrorTrayData?.isLoading &&
			!dynamicTrayService?.genericSuccessErrorTrayData?.buttonsOnly
		"
		class="error-container"
		[ngStyle]="{
			'padding-bottom': dynamicTrayService?.genericSuccessErrorTrayData?.errorContainerPaddingBottom + 'px'
		}"
	>
		<div class="icon-container">
			<sp-lottie-loader
				*ngIf="
					dynamicTrayService?.genericSuccessErrorTrayData?.useLottie &&
					!dynamicTrayService?.genericSuccessErrorTrayData?.staticLottiePath
				"
				[lottieImageUrl]="
					dynamicTrayService?.genericSuccessErrorTrayData?.isSuccess ? 'https://assets-es-pprd.dxlpreprod.local.vodafone.es/assets/tick.json' : 'https://assets-es-pprd.dxlpreprod.local.vodafone.es/assets/warning.json'
				"
				[height]="dynamicTrayService?.genericSuccessErrorTrayData?.lottieImgHeight || 150"
				[width]="dynamicTrayService?.genericSuccessErrorTrayData?.lottieImgWidth || 150"
				[loop]="false"
			>
			</sp-lottie-loader>
			<sp-lottie-loader
				*ngIf="
					dynamicTrayService?.genericSuccessErrorTrayData?.useLottie &&
					dynamicTrayService?.genericSuccessErrorTrayData?.staticLottiePath
				"
				[lottieImageUrl]="dynamicTrayService?.genericSuccessErrorTrayData?.staticLottiePath"
				[height]="dynamicTrayService?.genericSuccessErrorTrayData?.lottieImgHeight || 150"
				[width]="dynamicTrayService?.genericSuccessErrorTrayData?.lottieImgWidth || 150"
				[loop]="dynamicTrayService?.genericSuccessErrorTrayData?.loop"
			>
			</sp-lottie-loader>
			<img
				*ngIf="
					!dynamicTrayService?.genericSuccessErrorTrayData?.useLottie &&
					!dynamicTrayService?.genericSuccessErrorTrayData?.staticImagePath
				"
				[src]="
					dynamicTrayService?.genericSuccessErrorTrayData?.isSuccess
						? 'https://assets-es-pprd.dxlpreprod.local.vodafone.es/assets/images/ic-tick-hifi-light-theme@2x.png'
						: 'https://assets-es-pprd.dxlpreprod.local.vodafone.es/assets/images/warning-for-cancel-overlay.png'
				"
				[ngStyle]="{
					'margin-top': dynamicTrayService?.genericSuccessErrorTrayData?.imgMarginTop + 'px',
					'margin-bottom': dynamicTrayService?.genericSuccessErrorTrayData?.imgMarginBottom + 'px'
				}"
			/>
			<img
				*ngIf="
					!dynamicTrayService?.genericSuccessErrorTrayData?.useLottie &&
					dynamicTrayService?.genericSuccessErrorTrayData?.staticImagePath
				"
				[src]="dynamicTrayService?.genericSuccessErrorTrayData?.staticImagePath"
				[ngClass]="{ 'no-padding': dynamicTrayService?.genericSuccessErrorTrayData?.staticImageNoPadding }"
				[ngStyle]="{
					'margin-top': dynamicTrayService?.genericSuccessErrorTrayData?.imgMarginTop + 'px',
					'margin-bottom': dynamicTrayService?.genericSuccessErrorTrayData?.imgMarginBottom + 'px'
				}"
			/>
		</div>
		<div class="error-title" [innerHTML]="dynamicTrayService?.genericSuccessErrorTrayData?.boldCenterTitle"></div>
		<div
			class="error-desc"
			[ngClass]="{ NotcenterDescription: dynamicTrayService?.genericSuccessErrorTrayData?.NotcenterDescription }"
		>
			<p
				*ngIf="dynamicTrayService?.genericSuccessErrorTrayData?.firstDescription"
				[innerHTML]="dynamicTrayService?.genericSuccessErrorTrayData?.firstDescription"
			></p>
			<p
				*ngIf="dynamicTrayService?.genericSuccessErrorTrayData?.secondDescription"
				[innerHTML]="dynamicTrayService?.genericSuccessErrorTrayData?.secondDescription"
			></p>
		</div>
	</div>
	<div *ngIf="dynamicTrayService?.genericSuccessErrorTrayData?.isLoading" class="skeleton-wrapper-section">
		<div class="img-skeleton"></div>
		<section class="content-container-skeleton">
			<div class="content-skeleton first-content"></div>
			<div class="content-skeleton"></div>
			<div class="content-skeleton"></div>
		</section>
		<div class="button-skeleton"></div>
	</div>
	<div *ngIf="dynamicTrayService?.showVodaSpinner">
		<div class="voda-full-loader">
			<mva10-c-speechmark-spinner> </mva10-c-speechmark-spinner>
		</div>
	</div>
	<div class="error-buttons-container">
		<div
			class="mva10-o-equal-cols custom-col"
			[ngClass]="{ 'mva10-o-equal-cols reverseee': appService.isResponsiveView }"
		>
			<mva10-c-button
				*ngIf="dynamicTrayService?.genericSuccessErrorTrayData?.primaryButton?.primaryButtonText"
				(click)="dynamicTrayService?.genericSuccessErrorTrayData?.primaryButton?.primaryButtonAction()"
				[body]="dynamicTrayService?.genericSuccessErrorTrayData?.primaryButton?.primaryButtonText"
				[styleClass]="'mva10-c-button--primary'"
			>
			</mva10-c-button>
			<mva10-c-button
				*ngIf="dynamicTrayService?.genericSuccessErrorTrayData?.secondaryButton?.secondaryButtonText"
				(click)="dynamicTrayService?.genericSuccessErrorTrayData?.secondaryButton?.secondaryButtonAction()"
				[body]="dynamicTrayService?.genericSuccessErrorTrayData?.secondaryButton?.secondaryButtonText"
				[styleClass]="'mva10-c-button--tertiary'"
			>
			</mva10-c-button>
		</div>
	</div>
</div>
