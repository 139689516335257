import { inject, Pipe, PipeTransform } from '@angular/core';
import { ValidationErrors } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';

@Pipe({
	name: 'errorMessage',
})
export class ErrorMessagePipe implements PipeTransform {
	private translatePipe = inject(TranslateService);

	transform(error: ValidationErrors | null): string {
		if (!error) return '';

		let translatedValue: string;

		if (error.pattern) {
			this.translatePipe.get('v10.commercial.checkout.errors.cp_not_valid').subscribe((errorMessage: string) => {
				translatedValue = errorMessage;
			});
		} else if (error.required) {
			this.translatePipe.get('v10.commercial.checkout.errors.required').subscribe((errorMessage: string) => {
				translatedValue = errorMessage;
			});
		}

		return translatedValue ?? '';
	}
}
