import { DecimalPipe } from '@angular/common';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { MatLegacySlideToggleChange as MatSlideToggleChange } from '@angular/material/legacy-slide-toggle';
import { TranslateService } from '@ngx-translate/core';
import { CommercialJourneys, commercialMobile } from '../../constants/defines';
import { TicketLineType } from '../../enums/ticketLineType.enum';
import { CommercialCartItem } from '../../models/commercial-cart-item.model';
import { CommercialService } from '../../models/commercial-service.model';
import { CommercialShoppingCart } from '../../models/commercial-shopping-cart.model';
import { TicketItem } from '../../models/commercial-ticket-item.model';
import { PriceToShowPipe } from '../../pipes/price-to-show/price-to-show.pipe';

@Component({
	selector: 'sp-generic-ticket',
	templateUrl: './sp-generic-ticket.component.html',
	styleUrls: ['./sp-generic-ticket.component.scss'],
})
export class SpGenericTicketComponent implements OnInit {
	public overlay: boolean = false;
	public isScrolled: boolean = false;
	public lines: TicketItem[];
	public coinMonth: string = this.translate.instant('v10.commercial.common.payment.monthly').replace('{0}', '');
	public loadedLines: boolean = false;
	public isBundelFibre: boolean = false;

	@Input() shoppingCart: CommercialShoppingCart;
	@Input() showSwitch?: boolean;
	@Input() switchState?: boolean;
	@Input() isME: boolean;
	@Output() switchTaxesChecked?: EventEmitter<MatSlideToggleChange> = new EventEmitter();
	@Input() switchText?: string;
	@Input() showTitle?: boolean = true;

	constructor(private translate: TranslateService, private decimalPipe: DecimalPipe) {}
	ngOnInit() {
		if (!this.switchState) {
			this.switchState = true;
		}
		this.loadLines(this.shoppingCart);
	}

	public checkDecimals(_strDecimals: string): string {
		return _strDecimals?.indexOf(',00') !== -1 ? _strDecimals?.slice(0, -3) : _strDecimals;
	}
	private checkJourney(_cart: CommercialShoppingCart): boolean {
		let _isBundelFibre: boolean = false;
		_cart.cartItems.forEach((service: CommercialCartItem) => {
			if (!_isBundelFibre) {
				const _found: number = service.services.findIndex((item: CommercialService) => {
					return (
						item.catalogElementType.toLocaleLowerCase() === commercialMobile.package.toLocaleLowerCase() &&
						(item.detail.serviceItemType === CommercialJourneys.serviceItemTypeUpsell ||
							item.detail.serviceItemType === CommercialJourneys.serviceItemTypeUpsellTV ||
							item.detail.serviceItemType === CommercialJourneys.serviceItemTypeOnePlus ||
							item.detail.serviceItemType === CommercialJourneys.serviceItemTypeConecta ||
							item.detail.serviceItemType === CommercialJourneys.serviceItemTypeConectaTelevision) &&
						(item.detail.serviceItemTypeName.toLocaleLowerCase() === commercialMobile.onepackage ||
							item.detail.serviceItemTypeName.toLocaleLowerCase() === commercialMobile.onetelevisionpackage ||
							item.detail.serviceItemTypeName.toLocaleLowerCase() === commercialMobile.onepluspackage ||
							item.detail.serviceItemTypeName.toLocaleLowerCase() === commercialMobile.conectapackage ||
							item.detail.serviceItemTypeName.toLocaleLowerCase() === commercialMobile.conectatelevisionpackage) &&
						(item.detail.registerType === CommercialJourneys.registerTypeNew ||
							item.detail.registerType === CommercialJourneys.registerTypeNewUpsell)
					);
				});
				_isBundelFibre = _found !== -1;
			}
		});
		return _isBundelFibre;
	}

	public loadLines(cart: CommercialShoppingCart): void {
		this.isBundelFibre = this.checkJourney(cart);

		let titleMicro: string;
		this.translate.get('v10.commercial.common.tax_rate.switch').subscribe((data) => {
			this.switchText = data.replace('{0}', cart.taxType.toUpperCase()).replace('{1}', cart.taxType.toUpperCase());
		});
		this.translate.get('v10.commercial.checkout.summary.common.included').subscribe((data) => {
			titleMicro = data.replace('{0}', cart.taxType.toUpperCase());
		});
		this.lines = new Array<TicketItem>();
		let line1: TicketItem;
		if (this.isME) {
			line1 = {
				text: this.showTitle
					? `${this.translate.instant('v10.productsServices.smartPay.pdf.title')} ${titleMicro}`
					: null,
				style: TicketLineType.title,
			};
		} else {
			line1 = {
				text: this.showTitle ? this.translate.instant('v10.commercial.checkout.nexus.your_order') : null,
				style: TicketLineType.title,
			};
		}
		this.lines.push(line1, this.loadLine2(cart));
		this.loadLine3(cart);
		this.loadedLines = true;
	}

	private loadLine2(cart: CommercialShoppingCart): TicketItem {
		let line2: TicketItem;
		if (!this.isBundelFibre) {
			if (cart.price.installmentPromoTaxes !== 0) {
				if (this.switchState) {
					line2 = {
						text: PriceToShowPipe.prototype.transform(cart.price.installmentPromoTaxes),
						span: this.coinMonth,
						style: TicketLineType.fee,
					};
				} else {
					line2 = {
						text: PriceToShowPipe.prototype.transform(cart.price.installmentPromo),
						span: this.coinMonth,
						style: TicketLineType.fee,
					};
				}
			} else {
				if (this.switchState) {
					line2 = {
						text: cart.price.singlePaymentTaxesPromo
							? PriceToShowPipe.prototype.transform(cart.price.singlePaymentTaxesPromo)
							: PriceToShowPipe.prototype.transform(cart.price.upfrontTaxesPromo + cart.price.devicesUpfrontTaxesPromo),
						style: TicketLineType.fee,
					};
				} else {
					line2 = {
						text: cart.price.singlePaymentPromo
							? PriceToShowPipe.prototype.transform(cart.price.singlePaymentPromo)
							: PriceToShowPipe.prototype.transform(cart.price.upfrontPromo + cart.price.devicesUpfrontPromo),
						style: TicketLineType.fee,
					};
				}
			}
		} else {
			let feePromoTaxes: string = this.decimalPipe
				.transform(cart.price.feeIncrease.feePromoTaxes, '1.2-2')
				.replace('.', ',');
			feePromoTaxes = this.checkDecimals(feePromoTaxes);
			line2 = {
				text: this.translate.instant('v10.commercial.common.payment.fee_euro').replace('{0}', feePromoTaxes),
				span:
					cart.price.feeIncrease.feePromoTaxes >= 0
						? this.translate.instant('v10.commercial.common.payment.monthly_more')
						: this.translate.instant('v10.commercial.common.payment.monthly_less'),
				style: TicketLineType.fee,
			};
		}
		return line2;
	}
	public setInitialPayment(cart: CommercialShoppingCart): string {
		let initialPrice: string;
		let textInitialPayment: string;
		if (this.switchState && cart.price.installmentPromoTaxes !== 0) {
			cart.price.devicesUpfrontTaxesPromo = cart.price.devicesUpfrontTaxesPromo
				? cart.price.devicesUpfrontTaxesPromo
				: 0;
			initialPrice = cart.price.singlePaymentTaxesPromo
				? PriceToShowPipe.prototype.transform(cart.price.singlePaymentTaxesPromo)
				: PriceToShowPipe.prototype.transform(cart.price.upfrontTaxesPromo + cart.price.devicesUpfrontTaxesPromo);
			textInitialPayment = `${this.translate
				.instant('v10.commercial.common.payment.initial')
				.replace('{0}', initialPrice)}`;
		} else if (cart.price.installmentPromoTaxes !== 0) {
			cart.price.devicesUpfrontPromo = cart.price.devicesUpfrontPromo ? cart.price.devicesUpfrontPromo : 0;

			initialPrice = cart.price.singlePaymentPromo
				? PriceToShowPipe.prototype.transform(cart.price.singlePaymentPromo)
				: PriceToShowPipe.prototype.transform(cart.price.upfrontPromo + cart.price.devicesUpfrontPromo);
			textInitialPayment = `${this.translate
				.instant('v10.commercial.common.payment.initial')
				.replace('{0}', initialPrice)}`;
		}
		return textInitialPayment;
	}
	public setFinalPayment(cart: CommercialShoppingCart): string {
		let finalPrice: string;
		let textFinalPayment: string;
		if (this.switchState && cart.price.deviceFinalPaymentTaxes) {
			finalPrice = PriceToShowPipe.prototype.transform(cart.price.deviceFinalPaymentTaxes);
			textFinalPayment = `${this.translate.instant('v10.commercial.common.payment.final').replace('{0}', finalPrice)}`;
		} else if (cart.price.deviceFinalPayment) {
			finalPrice = PriceToShowPipe.prototype.transform(cart.price.deviceFinalPayment);
			textFinalPayment = `${this.translate.instant('v10.commercial.common.payment.final').replace('{0}', finalPrice)}`;
		}
		return textFinalPayment;
	}
	public loadLine3(cart: CommercialShoppingCart): void {
		let initialPrice: string = this.setInitialPayment(cart);
		let finalPrice: string = this.setFinalPayment(cart);
		let textInitialFinalPayment: string;

		if (initialPrice && finalPrice) {
			textInitialFinalPayment = `${initialPrice} - ${finalPrice}`;
		} else if (initialPrice) {
			textInitialFinalPayment = initialPrice;
		} else if (finalPrice) {
			textInitialFinalPayment = finalPrice;
		}
		textInitialFinalPayment &&
			this.lines.push(this.setDataTicketComponent(textInitialFinalPayment, '', TicketLineType.initialFinalFee));
	}

	public setDataTicketComponent(text: string, textSpan: string, styleText: TicketLineType): TicketItem {
		return {
			text: text,
			span: textSpan,
			style: styleText,
		};
	}
	changeSwitch(event: MatSlideToggleChange): void {
		this.switchState = event.checked;
		this.loadedLines = false;
		this.loadLines(this.shoppingCart);
		this.switchTaxesChecked.emit(event);
	}

	public onScroll($event: any): void {
		const scrollable: HTMLElement = $event.target.children.scrollableContent;
		const unScrollable: HTMLElement = $event.target.children.unScrollableContent;

		if (
			Math.round(scrollable.getBoundingClientRect().top - 38 - unScrollable.offsetHeight) !==
			Math.round($event.target.getBoundingClientRect().top)
		) {
			this.isScrolled = true;
		} else {
			this.isScrolled = false;
		}
	}
}
