import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
	selector: 'sp-jumbotron-navigator',
	templateUrl: './sp-jumbotron-navigator.component.html',
	styleUrls: ['./sp-jumbotron-navigator.component.scss'],
})
export class SpJumbotronNavigatorComponent {
	/**title of the Jumbotron-navigator  */
	@Input() public title: string;
	/**color of the icon of Jumbotron-navigator  */
	@Input() public isBlack: boolean;
	/**description of the Jumbotron-navigator  */
	@Input() public description: string;
	/** function of Jumbotron-navigator */
	@Output() public navigateTo: EventEmitter<any> = new EventEmitter<any>();

	/**
	 * get the navigation step if item is locked then it has to upgrade otherwise it will navigate to
	 * url
	 */
	navigate() {
		this.navigateTo.emit();
	}
}
