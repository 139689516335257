import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';
import { GreetingWarningModel } from '../../models/greetingWarning.model';

@Component({
	selector: 'sp-everything-is-ok-warning',
	templateUrl: './everything-is-ok-warning.component.html',
	styleUrls: ['./everything-is-ok-warning.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class EverythingIsOkWarningComponent {
	@Input() icon: string;
	@Input() iconColor: string;
	@Input() eioWarningData: GreetingWarningModel;
	@Output() buttonClicked: EventEmitter<GreetingWarningModel> = new EventEmitter<GreetingWarningModel>();
}
