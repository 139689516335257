import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { map } from 'rxjs/operators';
import { API_URLS } from '../shared/constants/routes-config';
import { CacheService } from '../shared/utils/cache.service';
import { UpgradeProfile } from './UpgradeProfile.enum';

@Injectable()
export class UpgradeProfileService {
	/**
	 * step define the steps of the UpgradeScenario current user can access
	 */
	public step: UpgradeProfile = UpgradeProfile.stepZero;
	/**
	 * startScenario flag to define if we shall start upgrade scenario or not
	 */
	public startScenario = false;
	public targetRoute: string;
	public showModal: Function;
	constructor(private http: HttpClient) {}

	upgradeProfile(docType: string, docId: string) {
		CacheService.clearCache();
		const url = API_URLS.Profile.UPGRADE.replace('{docId}', docId).replace('{docType}', docType);
		let headers = new HttpHeaders();
		headers = headers.append('Content-Type', 'application/merge-patch+json');
		const config = { headers: headers };
		const body = {
			status: 'lite',
		};
		return this.http.patch(url, body, config).pipe(
			map((res) => {
				return res;
			})
		);
	}
}
