<div *ngIf="eioWarningData" class="eio-warning">
	<div class="eio-warning__text-wrapper">
		<div class="eio-warning__title">
			<mva10-c-icon
				[iconId]="icon || 'icon-notification-warn'"
				id="eioWarningIcon"
				size="24"
				[ngClass]="'mva10-u-color--' + (iconColor || 'primary')"
			>
			</mva10-c-icon>
			<label class="mva10-u--ml2x"> {{ eioWarningData.title }} </label>
		</div>
		<div *ngIf="eioWarningData.subtitle" class="eio-warning__subtitle">
			<label> {{ eioWarningData.subtitle }} </label>
		</div>
	</div>
	<div class="eio-warning__button-wrapper">
		<mva10-c-button
			(mva10OnClick)="buttonClicked.emit(eioWarningData)"
			[styleClass]="'mva10-c-button--tertiary mva10-c-button--small'"
			[body]="'v10.dashboard.everything.button' | translate"
		></mva10-c-button>
	</div>
</div>
